// import { Modal } from 'bootstrap';
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useLocation } from "react-router-dom";
import PrivacyPolicy from "../../component/PrivacyPolicy";
import PrivacyPolicySpanish from "../../component/PrivacyPolicySpanish";
import { Helmet } from "react-helmet";

export default function Privacy(params) {
  let language = localStorage.getItem("primaryLanguage");
  const search = useLocation().search;
  const key = new URLSearchParams(search).get("lang");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="p-30 pt-50">
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      {key === null ? (
        language === "english" ? (
          <PrivacyPolicy />
        ) : (
          <PrivacyPolicySpanish />
        )
      ) : key === "english" ? (
        <PrivacyPolicy />
      ) : (
        <PrivacyPolicySpanish />
      )}
    </div>
  );
}
