import { useState, useEffect } from "react";
import { useStoreItem } from "./Store";

const EnglishLabels = {
  minute: "minute",
  Minute: "minute",
  minutes: "minutes",
  Minutes: "minutes",
  day: "day",
  days: "days",
  hour: "hour",
  hours: "hours",
  week: "week",
  weeks: "weeks",
  receiveInitialInformation: "Receive Initial Information",
  receiveFullInformationAndDocumentation:
    "Receive Full Information And Documentation",
  managementOfTheService: "Management Of The Service",
  finalizationOfManagementOfService: "Finalization Of Management Of Service",
  serviceDelivered: "Service Delivered",
  aboutUs: "About Us",
  Newsletter: "Newsletter",
  Gestoria: "Agency",
  requestForWork: "Request For Work with Us",
  personalInformation: "Personal Info",
  accountInformation: "Account Information",
  LegalTerms: "Legal Terms",
  service: "Service",
  Cookies: "Cookies",
  FollowUs: "Follow Us",
  facebook: "Facebook",
  states: "States",
  twitter: "Twitter",
  instagram: "Instagram",
  linkedIn: "LinkedIn",
  mobileApp: "Mobile App",
  personal: "Personal",
  stepPersonal: "Personal",
  stepServiceProvider: "Service provider",
  stepAdditionalInfo: "Additional info",
  provider: " Service Provider",
  footerService: "SERVICES",
  additionalInfo: "Additional Info",
  validateField: "Please fill the field",
  emailValidateField: "Invalid email",
  loading: "Loading...",
  ourApp: "Our App",
  emails: "Email",
  ourMission: "Our Mission",
  dispute: "Dispute",
  disputeConfirmation: "Are you sure to dispute this order",
  ourPartner: "Our Partners",
  addNewProfile: "Add new profile",
  noDocuments: "no documents",
  emailValidation: "Email is invalid",
  noSavedProfile: "No Saved profile",
  noSavedOrder: "No Saved order",
  noProviderExist: "No service provider exist.",
  q1: "Respuesta",
  q2: "Por favor, responda en este bloque de texto",
  location: "Location",
  checkout: "Checkout",
  addpaymentcard: "Add another card",
  addNewAccount: "Add New Account",
  selectCard: "Please select card",
  deleteCard: "Are you sure you want to delete this card?",
  orderCreated: "Order created",
  filterOrder: "Filter Orders",
  filterByDate: "Filter by date",
  filterByStatus: "Filter By Status",
  ourstory: "Our Story",
  writeRequet: "Write your request",
  alphabetically: "Alphabetically",
  relevance: "All services",
  relevanceForBusiness: "Relevance for Business",
  relevanceForSelf: "Relevance for Self Employed",
  descriptionNotes: "Description Notes",
  sendRequest: "Send Request",
  myDocuments: "My Documents",
  inProgress: "In Progress",
  cancelled: "Cancelled",
  pleaseRegisterFacebookAccount:
    "Please register your email with your facebook account",
  wantToCancelOrder: "Are you sure you want to cancel this order?",
  ContinueGoogle: "Continue With Google",
  Continuefacebook: "Continue With facebook",
  loginCancelled: "Login Cancelled",
  enterNewPass: "Enter new password",
  enterYourwellSend:
    "Enter your email address & we'll send \nyour a confirmation code to \nreset the password.",
  SignIn: "Sign In ",
  exitOrder: "Do you really want to exit the order?",
  no: "No",
  or: "or ",
  Or: " Or ",
  orSignWith: " Or Sign in With ",
  orSignUpWith: " Or you can sign up with ",
  SignUp: "Sign Up",
  Login: "Log In",
  Email: "Email",
  providerDocuments: "Provider documents",
  Loginexisting: "Log in to your existing account",
  Password: "Password",
  ForgotPassword: "Forgot Password ?",
  ForgotPasswordQuestionMark: "Forgot Password",
  Donthaveac: "Don't have an account ?  ",
  DonthaveacSignIn: "Don't have an account ? !",
  done: "Done",
  IhAcc: "Already have an account? ",
  FirstName: "First Name",
  LastName: "Last Name",
  estimatedTime: "Estimated time",
  ph: "Contact Number",
  welcomeScreenText: "Lawyers, Advisors and Managers",
  inAFewClicks: "In a few clicks",
  dot: ".",
  dotBig: "•",
  whyChooseUs: "Why Choose Us",
  atTheBetPrice: "The best price",
  theBestPrice: "The best price",
  atTheBetPriceDetails: "Free Budget estimates and lowest prices",
  withTheMostQualifiedPartners: "With quality and guarantee",
  next: "Next",
  free: "Free",
  whatDoYouNeed: "What do you need?",
  howDoesDocudocsWork: "How does DocDoc work?",
  homeTopHeading: "Select Beneficiary of Service",
  particular: "Particular",
  selfEmployed: "Self Employed",
  requestedChange: "Requested for change",
  business: "Business",
  businessOrSelfEmployed: "Business or self employed",
  others: "Others",
  allServices: "All Services",
  assessmentBranch: "Assessment Areas",
  asessment: "Fiscal",
  selectAssessmentArea: "Select Assessment Area you need",
  selectLawyerAssessmentGestor: " Select Lawyer, Asesor or Gestor ",
  selectLawyerAssessmentGestorDetails:
    "Select the Lawyer, Asesor or Gestor that you like the most near you.",
  willRefund: " We will refund your money",
  willRefundDetails:
    "If you do not receive the service you contracted, we guarantee a complete or partial refund of the part of the service that has not been provided.",
  specialPrices: "Special Prices",
  contactNumber: "Contact Number",
  billingInformation: "Billing Information",
  save: "Save",
  selectLegalService: "Select Legal Service",
  user: "User",
  WorkWithUs: "Work With Us",
  companyName: "Company Name",
  enterCIF: "Enter CIF",
  enterAddress: " Enter Address",
  businessEmployed: "Business or Self Employed",
  weUseData:
    "We use data of the client to enhance the user \n experience of our service a$d show \n related promotions.",
  termConditionCapital: "Terms And Conditions",
  pleaseAccept: "Please accept the ",
  privacyTerms: "Privacy,Terms and Conditions \n Data Policies",
  andd: " and ",
  ViewFinalPriceDetails:
    "Te mostramos el precio final y el tiempo de entrega del servicio de forma transparente al lado de cada servicio.",
  cookiesPolicies: "Cookies Policies",
  andContinue: "and to continue.",
  CreateAnAccount: "Create an account to ",
  Doc: "DocuDoc ",
  ToGet: "to get all features",
  Continue: "Continue",
  continue: "Continue",

  otherBeneficiaries: "Other Beneficiaries",
  lifeInsurance: "Life Insurance",
  orderId: "Order ID",
  Initial: "Initial Info and Docs received",
  serviceAreas: "Service Areas",
  serviceProvider: "Select Service Provider",
  selectService: "Select Service Area",
  foundation: "Foundation",
  association: "Association",
  lawyer: "Lawyer",
  assessment: "Assessor",
  document: "Document",
  additionalBudgetDetails: "Budget details",
  specialLawyers: "Special Lawyers",
  selectLawyer: "Select Lawyer Areas",
  orders: "Orders",
  order: "Order",
  Clear: "Clear",
  reOrder: "Reorder",
  howDoes: "How does it works?",
  theCode: "The codes will apply to your \n next order.",
  youOnly: "You only can use one promotion \n code per payment.",
  theFirst: "The first code will be the one \n used for your next order.",
  back: "Back",
  admin: "Admin",
  helps: "Helps",
  helpp: "Help",
  howCanWeHelp: "How can we help?",
  relatedTo: "Related to the Service order?",
  notRealtedTo: "Not related to the services ",
  specialPrice: "Special Prices",
  costBreak: " Cost Breakdowns",
  paymentViz: "• Payment Via",
  xxx: "XXXX XXXX XXXX ",
  promotionCode: "Promotion Code",
  apply: "Apply",
  del: "Delete",
  share: "Share",
  noRecordsFound: "No Records Found",
  getPromo: "GET 30% for each\nfriend you bring",
  sharePromo: "Share your promotion code",
  promotionText:
    "On your next order of over 50 EUR\nyou will get a discount of 10 EUR.",
  promoBtmTxt:
    "Hi! Download DocuDoc App and use the code\n10,00$ of credit to spent on services",
  changePassword: "Change Password",
  billinInfo: "Billing Info",
  changeLanguage: "Change Language",
  paymentMethods: "Payment Methods",
  submit: "Submit",
  checkOutSubmit: "Submit",
  writeSomething: "   Write Something",
  whatCan: "  What can be improved?",
  serviceName: "Service Name",
  refund: "• Refunded Amount",
  continueProceed: "Continue to proceed",
  inAFewClicksDetails:
    "In a few clicks you will get exactly the services you are looking for.",
  ViewFinalPrice: "View Final Price ",
  orderID: "Order ID : ",
  costOfManag: "Cost of management: ",
  costOfServices: "Cost of Services :",
  afterPromoCostOfService: "Cost Of Service After Promo",
  addOtherServices: "Add Other Services",
  addNewAddress: "Add New Address",
  addPayment: "Add Payment Method",
  timeOfDelivey: "Time of Delivery: ",
  product: "Products",
  deliveryCharges: "VAT",
  vat: "VAT",
  Total: "Total",
  promo: "PromoCode",
  grandTot: "Grand Total",
  grandTotal: "Grand Total",
  euro: "  €",
  euroWithoutSpace: "€",
  percent: "%",
  myOrder: "My Orders",
  mySavedOrder: "My Saved Orders",
  noDraftOrders: "No Draft Orders",
  myInformation: "My Information",
  mySavedProfile: "My saved profile",
  information: "Information",
  cardNumber: "Card Number",
  expirationDate: "Expiration Date",
  cvc: "Card CVC",
  shareWin: "Share & Win",
  promotionCode: " Promotion Code",
  help: "Help",
  exit: "Logout",
  welocome: "Welcome!",
  welcome: "Welcome!",
  explore: "Explore",
  skip: "Skip",
  necessaryInformation: 'Necessary Information for\n"Assessment"',
  address: "Address",
  selectRegion: "Select Region",
  province: "Province",
  city: "City",
  selectNearestPartner: "Select Nearest DocuDoc Partner",
  DocDocPartners: "DocDoc Partners",
  addNewMessage: "Add New Message",
  send: "Send",
  attachDocument: "Attach Document",
  myMessage: "My Messages",
  promoCode: "PromoCode",
  writeMessage: "Contact Service Provider",
  orderConfirmed: "Order Confirmed",
  despatched: "Despatched",
  Ontheway: "On the way",
  delivered: "Delivered",
  dots: "------------------------------------------------------------------",
  dots1: "-------",
  dotss: "- ",
  one: "01",
  two: "02",
  three: "03",
  four: "04",
  serviceStatus: "Service status",
  cityLocation: "City of Location",
  sustainable: "Secure Payments",
  sustainableDetails:
    " If you pay through the platform a full refund is guaranteed if the service has not been delivered",
  medrid: "Medrid",
  beneficiay: "Beneficiary",
  particular: "Particular",
  pleaseadd: "Please add the following necessary data to carry \nout services:",
  name: "Name",
  surName: "SurName",
  Email: "Email",
  idNumber: "ID Number",
  orderServices: "Order Service",
  addCreditCard: "Add Card",
  ok: "Ok",
  orderNotification: "Order Notification",
  description: "Description",
  cancel: "Cancel",
  payNow: "Pay now",
  requestChange: "Request for Change",
  cancelOrder: "Cancel Order",
  cancelOrderMessage: `Cancel Order message displayed in the popup box when clicking on Cancel:
  “Are you sure you want to cancel your order? In that case, you lose the possibility of claiming all or part of your money. To claim all or part of your money, press “Cancel” and then press “Reclaim amount”. If your service is a subscription to a MONTHLY PACK and you want to cancel your subscription, press “Ok”.`,
  doYouHaveCopies: "Do you have the copies of the following \ndocuments ?",
  doHaveOriginalDoc: "Do you have the following Original \ndocuments ?",
  doYouHaveInformation: "Do you have the Information ?",
  doYouHaveDescription: "Detailed description of activity",
  uploadFiles: "Upload Files",
  uploadFile: "Upload File",

  doYouHaveIBAN: "IBAN for Social Security Payments",
  ifYouInformation:
    "if you Information and documentation are incorrect or incomplete. the time of delivery and the price of the service may vary.",
  copyNIE: "Copy of NIE",
  yes: "Yes",
  no: "No",
  porderX: "Porder X",
  estiDelivery: " Estimated time of delivery",
  priceSerive: "Price Of Service",
  authorize: " Authorize us to carry out this service",
  acceptData: " Accept data use and privacy policies",
  legalServices: "Legal Services",
  haveAnAccount: "You already have an account?",
  disputeReason: "Enter the reason, why you want to dispute this order.",
  disputeAmount: "Enter the amount to be reimbursed",
  codeSent:
    "The code was sent to your Email\nPlease enter the code to\ncreate Password.",
  enterVerificationCode: "Enter Verification Code",
  createNewPassword: "Create New Password",
  createANewPassword: "Create a New Password",
  oldPass: "Old password",
  newPassword: "New Password",
  confirmPassword: "Confirm password",
  confirm: "Confirm",
  rateReview: "Rate & Review",
  reviews: "Reviews",
  Profileinfo: "Profile Info",
  builts: "•",
  fieldRequired: "Please fill the field",
  deleteProfile: "Delete Profile",
  areYouSure: "Are You Sure?",
  areYouSureWantDeleteProfile: "Are you sure you want to delete this profile?",
  title: "Title",
  profileTitle: "Name of profile",
  enterfirstName: "Enter First Name",
  enterlastName: "Enter Last Name",
  emailAddress: "Email Address",
  yourEmailAddress: "Your Email Address",
  enterCity: "Enter City",
  selectUserType: "Select User Type",
  delete: "Delete",
  yourCompanyName: "Your Company Name",
  cif: "CIF",
  allFieldsAreRequired: "All fields are required.",
  codeNo: "+34",
  exampleNo: "Contact No",
  pleaseFormDataThanks: "please fill all form data thanks",
  allInformation:
    "All Information place  here All Information place  here All Information place  here ",
  fileIsDownloading: "file is downloading",
  fileDownloadedSuccessfully: "file downloaded successfully",
  storageAccess: "Storage access",
  message: "TeleDoc need to access storage to save file.",
  SelectAppointment: "Select Date For Appointment  ",
  noRecordsFound: "No Records Found",
  noMessageFound: "No Message Found",
  RequestSentSuccessfully: "Request Sent Successfully",
  rating: "Rating",
  notavailable: "N/A",
  position: "Position",
  coloumn: ":",
  experience: "Experience",
  year: " year",
  years: " years",
  supplementaryFees: "Supplementary fees",
  serviceCharges: "Service Charges",
  precioDelServicio: "Service Charges",
  downloadBills: "Download Bills",
  bilAttachedByProvider: " Bill is not attached by provider",
  confirmationPassword: "Confirm Password",
  newPasswordConfirmationNotMatch:
    "New password and confirmation password not match",
  password: "password",
  myInformation: "My Information",
  fullName: "Full Name",
  phoneNumber: "Phone Number",
  fAQ: "FAQs",
  answer: "Ans",
  q: "Q",
  enterConfirmPassword: "Please enter confirm password",
  confirmationPasswordValidation:
    "New password and confirmation password not match",

  completed: "Completed",
  editDraft: "Edit Draft",
  edit: "Edit",
  saveDraft: "Save to Draft",
  hrs: "hrs",
  uploadedDoc: "Document Uploaded ",
  notificationNotAvailable: "Notification not available.",
  //validation labels

  firstName: "FirstName is Invalid",
  lastName: "LastName is Invalid",
  email: "Email is invalid",
  emailAndMobileNo: "Invalid email or mobile no",
  phoneNoIsInvalid: "Mobile no is invalid",
  pleaseCanBeImproved: "Please write can be improved",
  pleaseWriteMessage: "Please write message",
  pleaseRating: "Please give rating",
  pleaseFillNewPassword: "Please enter new password",
  pleaseFillConfirmPassword: "Please enter confirm password",
  passwordRequired: "Password is required",
  mostEffient: "Fast Procedure  ",
  mostEffientDetails:
    "In a few clicks you will receive the service without displacement",
  // password is equal to confirm_password
  errorMessage: "Password is not match",
  selCCard: "Select Credit Cards",
  confirmation: "Confirmation",
  confMg: "Are you sure delete this card ?",
  cookiesText: "This website uses cookies to enhance the user experience.",
  confMgOrder: "Are you sure delete this order ?",
  orderSucc: " Order has been successfully placed.",
  editProfile: "Edit Profile",
  update: "Update",
  promoApplied: "Promo Code is Applied ",
  none: "None",
  saveProfile: "My Saved Profiles",
  profileSave: "Save profile",
  newProfile: "New Profile",
  origionalPrice: "Original Price",
  delieveryTimePeriod: "Delivery Time Period",
  discountPrice: "Discount Price",
  discount: "Discount",
  included: "Included",
  noAdditionalBudget: "No additional budget",
  noOrdersCancelled: "No Orders Cancelled",
  noOrdersCompleted: "No Orders Completed",
  noOrdersPending: "No Orders Pending",
  budgetConfirmatiom: "Are you sure you want to accept this budget?",
  me: "Me",
  number: "Number",
  areYouSureWant: "Are you sure you want to cancel this order?",
  paymentMethod: "Payment Method",
  pleaseadddescription: "Please add description",
  PleaseEnterValideMobile: "Please enter valid mobile no",
  pleaseAddCard: "please first add card",
  pleaseSelectCard: "please select card",
  yourAccountDisabled: "Your account was disabled by admin, please try again",
  notAnyAttach: "Form not available",
  documentAttached: "Document Attached",
  branches: " Branches",
  services: "Services",
  checkout: "Checkout",
  promoCode: "Promo Code",
  invalidPhoneNumber: "Invalid phone number",
  yourNotValid: "Your info is not valid",
  addPaymentCard: "Add Payment Card",
  digitNo: "000 00000 0000",
  search: "search",
  topQualityAndSatisfaction: "Top quality professionals",
  topQualityAndSatisfactionDetails:
    "Only select 5 star rated professionals close to you",
  subject: "Subject",
  messageOnly: "Message",
  professional: "Professional",
  adminDocuments: "Admin Documents",
  description: "Description",
  additionalBudget: "Additional Quote",
  privacy: "Privacy",
  amount: "Amount",
  workWithUsToastMessage: "Thanks for messaging us we will contact you soon",
  experience: "Experience",
  yourAreDisabled: "Your account was disabled by admin, please try again",
  networkFailed: "Please check your network ?",
  date: "Date",
  price: "Price",
  relatedToService: "Related to service",
  unRelatedToService: "Unrelated to service",
  subjectIsRequired: "Subject is required",
  messageIsRequired: "Message is required",
  stillHaveSomeQuery: "Still have some have some queries?",
  popText:
    "All Information place  here All Information place  here All Information place  here",
  contact: "Contact",
  resetPassword: "Reset password",
  passwordValidationText:
    "Password must be eight characters length,at least one uppercase letter, one lowercase letter, one number and one special character.",

  ///FILE TYPES DOWNLOAD
  xlsFile: "application/vnd.ms-excel",
  docFile:
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  docxFile: "application/msword",
  pdfFile: "application/pdf",
  imageFile: "image/png",
  imagePngFile: "image/jpeg",
  reason: "Reason",
  reasonIsRequired: "reason is required.",
  monthly: "monthly",
  fixedPrice: "Fixed price",
  years: "years",
  legalAdvisor: "Legal Advisor",
  founder: "Founder",
  enterPromoCodeHere: "Enter Promo Code Here",
  saleTax: "Sale Tax",
  noDocumentFound: "no document found",
  pleaseAcceptThe: "Please accept the ",
  termsAndConditions: "Terms and Conditions",
  privacyAndDataPolicies: " Privacy and Data Policies",
  cookiesPolicies: "Cookies Policies",
  toContinue: "to Continue.",
  and: "and",
  areYouSureYouWantToClaimPartOrAllOfTheAmountPaidForThisService: [
    "Are you sure you want to claim part or",
    "all of the amount paid for this service?",
  ],
  claimAmount: "Dispute",
  otpRequired: "OTP required",
  orderPlaced: "Order Placed",
  orderCanceled: "Order Cancelled",
  orderCompleted: "Order Completed",
  requestForFurtherDocumentation: "Request for further documentation",
  additionalBudgetRequest: "Additional budget request",
  copy: "copy",
  accepted: "Accepted",
  alerts: "Alerts",
  myProfiles: "My Profiles",
  addProfile: "Add profile",
  myProfile: "My profile",
  saveDataInNewProfile: `Save data in New Profile`,
  nameOfProfileWillBeSavedInMyInformationMyProfiles:
    "Name of Profile (Will be saved in My information > Mi profiles)",
  myBudgets: "My Quotes",
  orderDetailAdditionalBudget: "Additional Quote",
  promoCodeIsApplied: "Promo Code is Applied",
  idOrder: "ID Order",
  downloadBill: "download Bill",
  download: "download",
  downloadDocudocBill: "download docudoc bill",
  selectUserTypeBillingInformation: "Select User Type (Billing Information)",
  additionalBudgetAmount: "Amount",
  baseImponible: "Service Charges (Without Tax)",
  accept: "Accept",
  faqMessage: "TeleDoc need to access storage to save file.",
  orderDetailOrderID: "Order ID: ",
  chatServiceProvider: "Service provider",
  bill: "Bill",
  doYouNeedABill: "Do you need a bill?",
  whatManagementDoYouNeed: "What management do you need?",
  orderBill: "order bill",
  serviceBill: "Service Bill",
  supplementsBill: "Supplements Bill",
  supplementaryBill: "supplementary bill",
  additionalBudgetBill: "Additional Budget Bill",
  download: "download",
  budgetCompleted: "Budget Completed",
  fastSolutionToYourRequestsAndInquiries:
    "Fast solution to your requests and inquiries",
  weUseCookies: "We use cookies",
  cookieBannerDescription:
    "Click “Accept” to enable DocuDoc to use cookies to personalize this site, and to deliver ads and measure their effectiveness on other apps and websites, including social media. Customize your preferences in your Cookie Settings or click “Reject” if you do not want us to use cookies for this purpose. Learn more in our Cookie Policies.",
  essential: "Essential",
  essentialDescription:
    "Essential cookies are necessary for features which are essential to your use of our site or services, such as account login, authentication, and site security.",
  targetedAdvertising: "Targeted advertising",
  targetedDescription:
    "Targeted advertising cookies allow DocuDoc to share your data with advertising partners, including social media companies, to send you more relevant ads on other apps and websites, and for purposes determined by those partners.",
  analytics: "Analytics",
  analyticsDescription:
    "Analytics cookies allow DocuDoc to analyze your visits and actions on our and third-party apps and websites to understand your interests and be able to offer you more relevant ads on other apps and websites.",
  cookieSettings: "Cookie Settings",
  decline: "Decline",
  acceptAll: "Accept All",
  alwaysOn: "Always on",
  messageToAvoidSharingEmailAndPhoneNumber:
    "Never get paid or pay someone outside of DocuDoc. You may open yourself up to scams or fraud, and lose DocuDoc Payment Protection.",
  downloadServiceBill: "Download Service Bill",
  downloadSupplementsBill: "Download Supplements Bill",
  pleaseAddPaymentMethod: "Please add payment method",
  freeServicePaymentMessage: `*If the service selected is free, you will also need to add or select a payment method although you will not be charged for it.`,
  deleteAccount: "Delete Account",
  deleteAccountText: "Are you sure you want to delete your account?",
  docuDocMobileApp: "DocuDoc Mobile App",
  downloadMobileAppModalText:
    "for more better experience download our DocuDoc mobile app",
  fillInTheRequiredFields: "Fill in the required fields.",
  // Motivo y especialización
  reasonAndSpecialization: "Description",
  enterTheCodeReceivedByEmail: "Enter the code received by email.",
  ifYouDontReceiveItInYourInboxItMayBeInYourSpamFolder:
    "If you don't receive it in your inbox, it may be in your spam folder.",
};

const SpanishLabels = {
  orderPlaced: "Pedido confirmado",
  orderCanceled: "Pedido cancelado",
  orderCompleted: "Pedido entregado",
  requestForFurtherDocumentation: "Solicitud de información",
  additionalBudgetRequest: "Presupuesto adicional recibido",
  minute: "minuto",
  minutes: "minutos",
  Minute: "minuto",
  Minutes: "minutos",
  day: "día",
  days: "días",
  Day: "día",
  Days: "días",
  hour: "hora",
  hours: "horas",
  Hour: "hora",
  Hours: "horas",
  week: "semana",
  weeks: "semanas",
  Week: "semana",
  Weeks: "semanas",
  receiveInitialInformation: "Información inicial recibida",
  receiveFullInformationAndDocumentation: "Información necesaria recibida",
  managementOfTheService: "Prestación del servicio iniciada",
  finalizationOfManagementOfService: "Prestación del servicio finalizada",
  serviceDelivered: "Servicio entregado",
  service: "Servicio",
  subjectIsRequired: "El asunto es obligatorio",
  messageIsRequired: "El mensaje es obligatorio",
  subject: "Asunto",
  messageOnly: "Pregunta",
  date: "fecha",
  free: "Gratis",
  professional: "Profesional",
  sharePromo: "Comparte tu código de promoción",
  disputeReason: "Introduzca el motivo por el que desea disputar este pedido.",
  disputeAmount: "Introduce el importe a reembolsar",
  ContinueApple: "Continuar con Apple",
  ContinueGoogle: "Continuar con Google",
  Continuefacebook: "Continuar con facebook",
  SignIn: "Iniciar sesión ",
  passwordValidationText:
    "La contraseña debe tener ocho caracteres, al menos una letra mayúscula, una letra minúscula, un número y un carácter especial",
  discount: "Descuento",
  emailValidateField: "Email inválido",
  or: "o ",
  Or: " O ",
  SignUp: "Regístrate",
  enterfirstName: "Nombre",
  enterlastName: "Apellidos",
  Login: "Iniciar sesión",
  Email: "Correo electrónico",
  Loginexisting: "Inicie sesión en su cuenta existente",
  Password: "Contraseña",
  ForgotPassword: "Has olvidado tu contraseña ?",
  Donthaveac: "No tienes cuenta con nosotros?  ",
  FirstName: "Nombre",
  mySavedProfile: "Borradores guardados",
  LastName: "Apellido",
  selectUserType: "Tipo de Usuario ",
  welcomeScreenText: "Abogados, Asesores y Gestores",
  inAFewClicks: "En pocos clics",
  dot: ".",
  done: "Ok",
  position: "Posición",
  atTheBetPrice: ["Al mejor", <br />, "precio"],
  theBestPrice: "Al mejor precio",

  atTheBetPriceDetails:
    "Los precios más bajos del mercado + Presupuestos gratuitos",
  withTheMostQualifiedPartners: "Con garantía de calidad",
  next: "próximo",
  whatDoYouNeed: "¿Qué necesitas?",
  homeTopHeading: "Seleccionar beneficiario del servicio",
  particular: "Especial",
  selfEmployed: "Autónomo",
  business: "Empresa",
  others: "Otros",
  noOrdersCancelled: "No se cancelan pedidos",
  noOrdersCompleted: "No se completaron pedidos",
  noOrdersPending: "No hay pedidos pendientes",
  mySavedOrder: "Borradores guardados",
  mySavedProfile: "Mis perfiles",
  allServices: "Todos los servicios",
  assessmentBranch: "Especialidades de evaluación",
  selectAssessmentArea: "Seleccione el área de evaluación que necesita",
  specialPrices: "Precios especiales",
  branches: "Especialidades",
  contactNumber: "Número de contacto",
  contact: "Contacto",
  billingInformation: "Información de facturación",
  save: "Guardar",
  companyName: "Nombre",
  enterCIF: "NIF, DNI o NIE",
  enterAddress: " Ingresa la direccion",
  businessEmployed: "Negocio o autónomo",
  weUseData:
    "Usamos los datos del cliente para mejorar la experiencia  n del usuario de nuestro servicio y $ d mostrar  n promociones relacionadas.",
  termConditionCapital: "Condiciones de uso",
  pleaseAccept: "Por favor acepte el ",
  privacyTerms: "Privacidad, términos y condiciones  n Políticas de datos",
  privacy: "Política de privacidad",
  andd: " y ",
  completed: "Entregados",
  noRecordsFound: "No se encontraron registros",
  cookiesPolicies: "Política de cookies",
  andContinue: "y continuar.",
  CreateAnAccount: "Crea una cuenta para ",
  Doc: "DocuDoc ",
  ToGet: "para obtener todas las funciones",
  Continue: "Continuar",
  continue: "Continuar",

  otherBeneficiaries: "Otros beneficiarios",
  lifeInsurance: "Seguro de vida",
  orderID: "Solicitar ID: ",
  orderDetailOrderID: "ID Pedido: ",
  Initial: "Información inicial y documentos recibidos",
  serviceAreas: "Servicios ",
  selectService: "Seleccionar área de servicio",
  foundation: "Fundación",
  association: "Asociación",
  lawyer: "Abogado",
  assessment: "Asesor",
  document: "Documento",
  additionalBudgetDetails: "Detalles del presupuesto",
  specialLawyers: "Abogados especiales",
  selectLawyer: "Seleccionar áreas de abogados",
  orders: "Pedidos",
  order: "Pedido",
  WorkWithUs: "Trabaja con nosotros",
  reOrder: "ReorVolver a comprar ",
  howDoes: "Cómo funciona?",
  theCode: "Los códigos se aplicarán a su  n próximo pedido.",
  youOnly: "Solo puede usar un código  n de promoción por pago.",
  theFirst: "El primer código será el  n utilizado para su próximo pedido.",
  experience: "Experiencia",
  back: "Atrás",
  helps: "Ayuda",
  helpp: "Ayudar",
  howCanWeA: "Cómo podemos ayudar?",
  relatedTo: "Relacionado con la orden de servicio?",
  notRealtedTo: "No relacionado con los servicios ",
  specialPrice: "Precios especiales",
  costBreak: " Resumen ",
  paymentViz: "• Pago a través de",
  xxx: "XXXX XXXX XXXX ",
  promo: "Código promocional",
  apply: "Aplicar",
  share: "Cuota",
  getPromo: "Consigue un 30% de descuento por cada amigo que nos traigas.",
  noRecordsFound: "No se encontraron registros",
  sharePromo: "Comparte tu código de promoción",
  promotionText:
    "En su próximo pedido de más de 50 EUR  ny obtendrá un descuento de 10 EUR.",
  promoBtmTxt:
    "¡Hola! Descargue la aplicación DocuDoc y use el código  n10,00 $ de crédito para gastar en servicios",
  changePassword: "Cambiar la contraseña",
  billinInfo: "Información de Billin",
  changeLanguage: "Cambiar idioma",
  paymentMethods: " Métodos de pago",
  submit: "Enviar",
  checkOutSubmit: "Pagar",
  writeSomething: "Escribir una opinión",
  whatCan: "  Que se puede mejorar?",
  serviceName: "Nombre del Servicio",
  refund: "• Monto reembolsado",
  continueProceed: "Continuar",
  orderID: "Solicitar ID:",
  costOfManag: "Costo de manejo: ",
  costOfServices: "Costo de servicios: ",
  addOtherServices: "Agregar otros servicios",
  addNewAddress: "Agregar nueva dirección",
  addNewProfile: "añadir nuevo perfil",
  addPayment: "Añadir método de pago",
  timeOfDelivey: "Hora de entrega: ",
  product: "Productos",
  deliveryCharges: "Los gastos de envío ",
  Total: "Total",
  grandTotal: "Total a pagar",
  euro: "  €",
  myOrder: " Mis pedidos",
  myInformation: " Mi informacion",
  myMessage: " Mis mensajes",
  shareWin: "Comparte y gana",
  specialPrices: " Precios especiales",
  promotionCode: " El código de promoción",
  fAQ: " FAQs",
  help: "Ayuda",
  none: "Particular",
  saveProfile: "Mis perfiles guardados",
  profileSave: "Guardar perfil",
  exit: "Cerrar sesión",
  welocome: "Bienvenido!",
  welcome: "Bienvenido!",
  explore: "Explorar",
  rateReview: "Evualuar el servicio",
  reviews: "Evualuaciones",
  skip: "Saltar",
  necessaryInformation:
    "Información necesaria o  n Nombre de los servicios seleccionados ",
  address: "Dirección",
  selectRegion: "Comunidad Autónoma",
  province: "Provincia",
  city: "Ciudad",
  selectNearestPartner: "Seleccione el socio de DocuDoc más cercano",
  DocDocPartners: "DocDoc Socios",
  allServices: "Todos los servicios",
  legalServices: "Servicios Jurídicos",
  services: "Servicios",
  serviceCharges: "Precio del Servicio",
  precioDelServicio: "Precio del Servicio",
  included: "incluído",
  information: "Información",
  orSignWith: "O iniciar sesión con ",
  emailAddress: "Dirección de correo electrónico",
  orSignUpWith: "O puedes registrarte con ",
  IhAcc: "Ya tienes una cuenta? ",
  aboutUs: "Nosotros",
  Newsletter: "Newsletter",
  Gestoria: "Gestor",
  requestForWork: "Solicitud Trabajar con nosotros ",
  LegalTerms: "TÉRMINOS",
  Coookies: "Cookies",
  FollowUs: " SÍGUENOS",
  facebook: "Facebook",
  twitter: "Twitter",
  instagram: "Instagram",
  linkedIn: "LinkedIn",
  mobileApp: "App móvil",
  personal: "Personal",
  stepPersonal: "Datos cliente",
  stepServiceProvider: "Seleccione profesional",
  stepAdditionalInfo: "Info complementaria",
  provider: " Partner",
  additionalInfo: "Cargando",
  validateField: "Campo obligatorio",
  loading: "Cargando...",
  description: "descripción",
  emails: "Correo electrónico",
  emailValidation: "El correo electrónico no es válido",
  noSavedProfile: "No hay perfil guardado",
  profileTitle: "Nombre del perfil",
  allFieldsAreRequired: "Todos los campos son obligatorios",
  Years: "años",
  noProviderExist:
    "Lamentamos que en estos momentos no exista un partner que preste el servicio seleccionado en la provincia seleccionada.",
  ifYouInformation:
    "Si su información o documentación adjunta es incorrecta o incompleta, el tiempo de entrega de este servicio puede variar.",
  checkout: "Tu pedido",
  q1: "Please Answer Question 1 in this short text field (Text Input)",
  q2: "Please Answer in this Text Block",
  selectCard: "Seleccione una tarjeta",
  confirmation: "Confirmar",
  addpaymentcard: "Añadir tarjeta de pago ",
  deleteCard: "¿Estás seguro de que quieres eliminar esta tarjeta?",
  cancel: "Cancelar",
  supplementaryFees: "Suplidos",
  orderCreated: "Pedido creado",
  filterOrder: "Filtrar pedidos",
  filterByDate: "Filtrar por fecha",
  filterByStatus: "Filtrar por estado",
  price: "precio",
  orderId: "ID Pedido",
  rating: "Evaluación",
  wantToCancelOrder: "¿Está seguro de que desea cancelar este pedido?",
  cancelOrder: "Cancelar pedido",
  cancelOrderMessage: `“¿Está seguro que desea cancelar su pedido? En ese caso pierde la posibilidad de reclamar la totalidad o parte de su dinero. Para reclamar la totalidad o parte de su dinero, presione “Cancelar” y posteriormente presione “Reclamar importe”. Si su servicio es una suscripción a un PACK MENSUAL y desea cancelar su suscripción, presione “Ok”.
  `,
  serviceStatus: "Estado del servicio",
  additionalBudget: "Pagar",
  noAdditionalBudget: "No hay presupuesto adicional",
  writeMessage: "ESCRIBA UN MENSAJE",
  whatCan: "¿Qué podemos mejorar?",
  serviceCharges: "Precio del Servicio",
  billNotAttached: "Factura no disponible",
  downloadBills: "Descargar factura",
  writeRequet: "Comentario de solicitud de cambio",
  descriptionNotes: "Notas de la descripción",
  ok: "Ok",
  exitOrder:
    "Recuerda guardar el formulario antes de salir. Se guarda en 'Borradores guardados' de tu perfil. Si ya lo ha guardado, presione “Si”, en caso contrario presione “Cancelar”.",
  ViewDescription: "View Description",
  amount: "Cantidad",
  payNow: "Pagar ahora",
  requestChange: "Solicitud de cambio",
  requestedChange: "Solicitud de cambio",
  reOrder: "Volver a comprar",
  addPaymentCard: "Añadir tarjeta de pago ",
  personalInformation: "Personal",
  phoneNumber: "Número de teléfono",
  businessOrSelfEmployed: "Empresa o Autónomo",
  accountInformation: "Información de cuenta",
  editProfile: "Editar perfil",
  update: "Actualizar",
  myDocuments: "mis documentos",
  providerDocuments: "Provider documents",
  noDocuments: "Sin documentos",
  orderNotification: "Notificación del pedido",
  billingInformation: "Datos de facturación",
  createNewPassword: "Crear Nueva Contraseña",
  oldPass: "Contraseña anterior",
  enterNewPass: "Por favor ingrese una contraseña nueva",
  confirmPassword: "Confirmar contraseña",
  enterConfirmPassword: "Por favor confirme la contraseña",
  confirmationPasswordValidation:
    "Nueva contraseña y contraseña de confirmación no coinciden",
  enterVerificationCode: "Introduzca el código de verificación",
  password: "contraseña",
  resetPassword: "restablecer la contraseña",
  send: "Enviar",
  sendRequest: "Enviar petición",
  addCreditCard: "Añadir nueva tarjeta",
  cardNumber: "Número de tarjeta",
  expirationDate: "Fecha de caducidad",
  cvc: "Número CVC",
  addNewAccount: "Agregar nueva cuenta",
  no: "No",
  deleteProfile: "Borrar perfil",
  areYouSure: "¿Está seguro?",
  states: "Seleccione Comunidad Autónoma",
  cif: "CIF",
  yes: "Sí",
  ourstory: "Nuestra historia",
  ourMission: "Nuestra misión",
  ourPartner: "Nuestros partners",
  ourApp: "Our App",
  whyChooseUs: "POR QUÉ ELEGIRNOS",
  cookiesText:
    "Este sitio web utiliza cookies para mejorar la experiencia del usuario",
  mostEffient: ["Procedimiento", <br />, "rápido"],
  mostEffientDetails:
    "En pocos clics encontrarás y recibirás el servicio sin desplazamiento",
  sustainable: ["Pagos", <br />, "seguros"],
  sustainableDetails:
    " Si paga a través de la plataforma, se garantiza un reembolso de la parte del servicio que no se ha recibido",
  howDoesDocudocsWork: "¿Cómo funciona DocuDoc?",
  selectLegalService: "Selecciona Servicio Jurídico",
  inAFewClicksDetails:
    "En pocos clicks conseguirás exactamente el servicio que estás buscando. ",
  ViewFinalPrice: "Visualiza Precio Final",
  ViewFinalPriceDetails:
    "Te mostramos el precio final y el tiempo de entrega del servicio de forma transparente al lado de cada servicio. ",
  selectLawyerAssessmentGestor: "Selecciona Abogado, Asesoría o Gestoría ",
  selectLawyerAssessmentGestorDetails:
    "Selecciona el Abogado, Asesor o Gestor que más te guste cerca de ti.",
  willRefund: "Te devolvemos tu dinero",
  willRefundDetails:
    "Si no recibes el servicio contratado, te garantizamos una devolución completa o parcial de la parte del servicio que no se ha prestado.",
  topQualityAndSatisfaction: "Profesionales de primera calidad",
  topQualityAndSatisfactionDetails:
    "Solo trabajamos con profesionales calificados con 5 estrellas",
  alphabetically: "Orden alfabético",
  relevance: "Todos los servicios",
  invalidPhoneNumber: "Numero de telefono invalido",
  relevanceForBusiness: "Servicios para empresas",
  relevanceForSelf: "Servicios para autónomos",
  inProgress: "En curso",
  cancelled: "Cancelados",
  dispute: "Reclamados",
  saveDraft: "Guardar borrador",
  disputeConfirmation: "Are you sure to dispute this order",
  adminDocuments: "Admin Documents",
  user: "User",
  admin: "Admin",
  workWithUsToastMessage:
    "Gracias por enviarnos un mensaje, nos pondremos en contacto con usted pronto.",
  footerService: "SERVICIOS",
  noSavedOrder: "Sin borradores guardados",
  message: "mensaje",
  fagMessage: "Pregunta",
  stillHaveSomeQuery: "¿Todavía tienes dudas?",
  delete: "Borrar",
  orderServices: "Confirmar pedido",
  estimatedTime: "Entrega estimada",
  relatedToService: "Ayuda con un pedido",
  unRelatedToService: "Ayuda sin relación con un pedido",
  reason: "Razón",
  reasonIsRequired: "se requiere motivo.",
  monthly: "mensual",
  fixedPrice: "precio fijo",
  years: "años",
  legalAdvisor: "Consejero legal",
  founder: "Fundador",
  enterPromoCodeHere: "Ingrese el código de promoción aquí",
  saleTax: "Impuesto de venta",
  noDocumentFound: "no se encontró ningún documento",
  termsAndConditions: "Términos y condiciones",
  pleaseAcceptThe: "Por favor, acepta nuestros ",
  privacyAndDataPolicies: "Política de privacidad de datos",
  cookiesPolicies: "Política de cookies",
  toContinue: "para continuar.",
  and: "y",
  areYouSureYouWantToClaimPartOrAllOfTheAmountPaidForThisService: [
    "¿Está seguro que quiere reclamar",
    <br />,
    "parte o la totalidad del importe",
    <br />,
    "pagado por este servicio?",
  ],
  claimAmount: "Reclamar importe",
  otpRequired: "Requiere OTP",
  copy: "Copiar",
  uploadFile: "Subir archivo",
  accepted: "Pagado",
  alerts: "Alertas",
  myProfile: "Mis perfiles guardados",
  myProfiles: "Mis perfiles",

  addProfile: "Añadir perfil",
  saveDataInNewProfile: `Guardar datos en “Mis perfiles”`,
  title: "Título",
  nameOfProfileWillBeSavedInMyInformationMyProfiles:
    "Nombre del Perfil (Se guardará en “Mi información > Mis perfiles)”",
  myBudgets: "Mis presupuestos",
  orderDetailAdditionalBudget: "Presupuestos",
  promoCodeIsApplied: "Código de promoción aplicado",
  vat: "IVA",
  promoCode: "Código promocional",
  idOrder: "ID Pedido",
  downloadBill: "descargar factura",
  download: "descargar",
  downloadDocudocBill: "descargar factura docudoc",
  selectUserTypeBillingInformation: "Tipo de usuario (Datos de facturación)",
  additionalBudgetAmount: "Precio",
  baseImponible: "Base imponible",
  accept: "Aceptar",
  faqMessage: "Pregunta",
  chatServiceProvider: "Proveedor de servicio",
  bill: "Factura",
  editDraft: "Editar borrador",
  edit: "Editar",
  doYouNeedABill: "¿Necesita factura?",
  whatManagementDoYouNeed: "¿Qué gestión necesitas?",
  orderBill: "orden de factura",
  serviceBill: "Factura del servicio",
  supplementsBill: "Factura de suplidos",
  supplementaryBill: "factura suplementaria",
  additionalBudgetBill: "Proyecto de presupuesto adicional",
  download: "descargar",
  budgetCompleted: "Presupuesto finalizado",
  fastSolutionToYourRequestsAndInquiries:
    "Solución rápida a tus gestiones y consultas",
  weUseCookies: "Utilizamos cookies",
  cookieBannerDescription:
    "Haga clic en “Aceptar” para permitir que DocuDoc utilice cookies para personalizar este sitio, mostrar anuncios y medir su eficacia en otras apps y sitios web, incluidas las redes sociales. Personalice sus preferencias en Configuración de cookies o haga clic en “Rechazar” si no quiere que utilicemos cookies para este fin. Obtenga más información a continuación: Política de cookies.",
  essential: "Esencial",
  essentialDescription:
    "Las cookies esenciales son necesarias para funciones básicas relacionadas con el uso que haces de nuestro sitio web o servicios, como la seguridad del sitio web, la autenticación y el inicio de sesión en la cuenta.",
  targetedAdvertising: "Publicitaria",
  targetedDescription:
    "Las cookies de publicidad dirigida permiten a DocuDoc compartir tus datos con socios publicitarios, como empresas de redes sociales, para enviarte anuncios más relevantes en otras apps y sitios web, así como para los fines que determinen estos socios.",
  analytics: "Análisis",
  analyticsDescription:
    "Las cookies analíticas permiten a DocuDoc analizar tus visitas y acciones en nuestras apps y sitios web y en las de terceros para comprender tus intereses y poder ofrecerte anuncios más relevantes en otras apps o sitios web.",
  cookieSettings: "Configuración de cookies",
  decline: "Rechazar",
  acceptAll: "Aceptar",
  alwaysOn: "Siempre encendido",
  messageToAvoidSharingEmailAndPhoneNumber:
    "Perderá la garantía de reembolso, si paga servicios fuera de DocuDoc.",
  downloadServiceBill: "Descargar Factura Servicio",
  downloadSupplementsBill: "Descargar Factura Suplidos",
  pleaseAddPaymentMethod: "Añada un método de pago",
  freeServicePaymentMessage: `*Si el servicio seleccionado es gratuito, también será necesario añadir o seleccionar un método de pago, aunque no se le realice ningún cargo.`,
  deleteAccount: "Borrar Cuenta",
  deleteAccountText:
    "No será posible recuperar su cuenta y sus datos una vez eliminados. ¿Está seguro de que quiere eliminar su cuenta con todos sus datos?",
  docuDocMobileApp: "Aplicación móvil DocuDoc",
  downloadMobileAppModalText:
    "DocuDoc es una App móvil, por lo que recomendamos la descarga de nuestra App para visualizar el contenido de forma correcta.",
  fillInTheRequiredFields: "Rellene los campos obligatorios.",
  reasonAndSpecialization: "Motivo y especialización",
  enterTheCodeReceivedByEmail: "Introduzca el código recibido por email.",
  ifYouDontReceiveItInYourInboxItMayBeInYourSpamFolder:
    "Si no lo recibe en su bandeja de entrada, puede que se encuentre en su carpeta de correo no deseado.",
};

let language = localStorage.getItem("primaryLanguage");
const Labels = () => {
  const { primaryLanguage, updateLanguage } = useStoreItem();
  const [labels, setLabels] = useState({});
  useEffect(() => {
    setLabels(
      primaryLanguage === "spanish"
        ? SpanishLabels
        : primaryLanguage === "english"
        ? EnglishLabels
        : SpanishLabels
    );
  }, [primaryLanguage]);

  return <></>;
};
let labels = language == "english" ? EnglishLabels : SpanishLabels;
export default labels;
