import React from "react";

function NataliaIntroSpanish() {
  return (
    <div>
      {" "}
      <p>Abogada.&nbsp;</p>
      <p>
        Doble licenciada en Derecho y Administración y dirección de Empresas por
        la Universidad Complutense de Madrid. Mientras trabajaba en “ESTUDIO
        JURÍDICO BUSTAMANTE, S.L.P.”, hizo un máster en “Asesoría jurídica de
        empresas”.
      </p>
      <p>
        Desde septiembre 2012 hasta la actualidad es abogada en “GRUPO
        GRANSOLAR, S.L.”.
      </p>
      <p>
        Entre otros lleva la gestión y operaciones societarias, así como
        llevanza de la secretaría del consejo de las sociedades integrantes del
        grupo, y de las más de 250 sociedades titulares de plantas cuya gestión
        es responsabilidad de Gransolar, incluyendo: elaboración de actas de
        formulación y aprobación de cuentas anuales, nombramiento y remoción de
        miembros de los órganos de administración, otorgamiento de poderes,
        llevanza al día de libros societarios, constitución de sociedades,
        aumentos y reducciones de capital, disolución y liquidación de
        sociedades, coordinación con notarías para la elevación a público de
        diferentes actos jurídicos.
      </p>
    </div>
  );
}

export default NataliaIntroSpanish;
