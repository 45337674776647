import React from "react";
import { Modal } from "react-bootstrap";
import labels from "../config/Labels";
import InformationContainer from "./informationContainer";

function MyModal(props) {

  
  return (
    <Modal target={props.target} show={props.show} onHide={props.onHide} animation={true}
       {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter rounded"
     
    
    
      >
      <Modal.Header closeButton className="d-flex justify-content-center border-0">
        <Modal.Title id="contained-modal-title-vcenter " >
        {labels.information}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body 
       style={{width:"500px",height:"300px",overflowY: "auto"}}
      >
       <InformationContainer info={props.info == null ? "": props.info}/>

      </Modal.Body>
      <Modal.Footer className="border-0">
        {/* <Button onClick={props.onHide}>Ok</Button> */}
      </Modal.Footer>
    </Modal>
  );
}
export default MyModal;