import React, { useState, useEffect } from "react";
import { OrderItem } from "../../component";
import DraftOrderItem from "../../component/draftOrderItem";
import { Tab, Tabs } from "react-bootstrap";
import { Images } from "../../constant/images";
import {
  Get_Orders_Method,
  Get_Draft_Order,
  Delete_Draft_Order,
} from "../../config/api";
import ToastComponent from "../../component/toast";
import moment from "moment";
import labels from "../../config/Labels";

export default function MySavedOrder(props) {
  const [pendingOrders, setPendingOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [draftOrder, setDraftOrder] = useState([]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const language = localStorage.getItem("primaryLanguage") === "english";

  useEffect(() => {
    window.scroll(0, 0);
    Get_Draft_Order(
      (value) => {
        setShowSpinner(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
        setDraftOrder(value);
      }
    );
  }, []);

  const updateDraftList = (value) => {
    setDraftOrder(value);
  };
  const closeToast = () => {
    setShowToast(false);
  };

  return (
    <section className="pb-150 ls" id="services" data-animation="fadeInUp">
      <div className="wrapper-main padd60 pb-5">
        <div className="container">
          <div className="dashboard-right">
            <div className="row">
              <div className="col-md-12">
                <div className="main-title-tab"></div>
              </div>
              <div className="col-lg-12 col-md-12 tabs-order-component">
                <div className="product-sort ml-auto"></div>

                <div className="billing-info-container">
                  {draftOrder?.length ? (
                    draftOrder?.map((item, index) => {
                      const {
                        data = [
                          { serviceName: "", id: "", avatar: "", price: "" },
                        ],
                      } = item;
                      return (
                        <DraftOrderItem
                          key={index}
                          path={"/documentaion"}
                          image={
                            (data && data[0]?.avatar || item?.data?.[0]?.selected_serviceItem?.avatar) || Images.draft.default
                          }
                          name={(data && data[0]?.serviceItem?.name || item?.data?.[0]?.selected_serviceItem?.[language?"name":"name_sp"]) || ""}
                          id={item?.draft_order_id}
                          des={moment(item?.created_at).format("YYYY-MM-DD")}
                          time={moment(item?.created_at).format("hh:mm A")}
                          item={item}
                          serviceId={(data && data[0]?.id) || ""}
                          price={(data && data[0]?.price) || ""}
                          draftList={updateDraftList}
                        />
                      );
                    })
                  ) : (
                    <p className="pb-10 textAlignCenter">
                      {labels?.noSavedOrder}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
