export const End_Points_Of_Api = {
  User_Register: "/register",
  Check_Email: "/check_email",
  User_Login: "/login",
  Get_Beneficiaries: "/get_beneficiaries",
  Google_Login: "/google_login",
  Facebook_Login: "/facebook_login",
  Get_Service_Areas: "/get_service_areas",
  Get_Branches_By_Service_Area_Id: "/get_branches_by_service_area_id",
  Get_Service_Areas_By_Beneficiary_id: "/get_service_areas_by_beneficiary_id",
  Get_Services_By_Branch_Id: "/get_services_by_branch_id",
  Search_Providers: "/search_providers",
  Get_Service_Form_By_Id: "/get_service_form_by_id",
  Upload_File: "/upload_file",
  Add_Card: "/add_card",
  Get_Credit_Cards: "/get_cards",
  Add_Order: "/order",
  Get_Orders: "/get_orders",
  Delete_Card: "/delete_card",
  Edit_Profile: "/edit_profile",
  Change_Password: "/change_password",
  Get_Profile: "/get_profile",
  Send_Order_Message: "/send_order_message",
  Get_Order_Message: "/get_messages",
  Check_Promo_Code: "/check_promo_code",
  Get_Branches_By_Service_Area_And_Beneficiary_Id:
    "/get_branches_by_service_area_and_beneficiary_id",
  Get_Order: "get_order",
  Get_Branches: "/get_branches",
  Add_Profile: "/add_profile",
  Get_Profiles: "/get_profiles",
  Delete_Profile: "/delete_profile_data",
  Edit_Profiles: "/edit_profile_data",
  Add_Draft: "/add_draft_order",
  Get_Draft_Order: "/get_draft_orders",
  Edit_Draft_Order: "/edit_draft_order",
  Delete_Draft_Order: "/delete_draft_order",
  Work_With_Us: "/work_us",
  Help_Message: "/help_message",
  Get_Faqs: "/get_faqs",
  Get_Services_by_branchId_And_Services_Area_Id:
    "get_services_by_branch_id_and_service_area_id",
  Get_Services_by_Branch_id_And_Service_Area_id_and_Beneficiary_id:
    "get_services_by_branch_id_and_service_area_id_and_beneficiary_id",
  Add_Primary_Card: "/primary_card",
  Additional_Budget_By_Order_Id: "/get_additional_budgets",
  Additional_Budget_Decline_Accept: "/budget_response",
  Add_Review: "/add_review",
  Edit_Review: "/edit_review",
  Forgot_Password: "/forgot_password",
  Verify_Code: "/verify_code",
  Reset_password: "/reset_password",
  Order_Cancel: "/order_cancel",
  Get_Special_Services: "/get_special_services",
  Search_Services_and_branches: "/search_branches_and_services",
  Get_Notifications: "/get_notifications",
  Get_Notifications_By_Order_Id: "get_notifications_by_order_id",
  Order_Dispute: "/order_dispute",
  Disputed_Order_Message: "/dispute_order_message",
  Get_Dispute_Order_Messages: "/get_dispute_messages",
  Add_Bill_Log: "add_bill_log",
  Read_Notification: "read_notification",
  Additional_Budget_Read: "additional_budget_read",
  Get_Additional_Budgets_Orders: "get_additional_budgets_orders",
  Budget_Dispute: "budget_dispute",
  Delete_Account: "delete_account",
};
