import React from "react";
import {
  Button,
  Accordion,
  Card,
} from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";

const FaqSpanish=()=>{
    return (
        <section
          className="pt-150 pb-150 ls faq-section section-b-space"
          data-animation="fadeInUp"
        >
          <div className="container">
            <h4 className="mb-20 text-center">Preguntas frecuentes</h4>
            <div className="row">
              <div className="col-12 col-md-12">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <Accordion
                    defaultActiveKey="0"
                    className="accordion theme-accordion"
                  >
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        ¿Cómo puedo contactar al prestador de mi servicio?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <p>
                          Si deseas contactar al prestador del servicio contratado,
                           deberás ir a tu perfil > Mis pedidos > seleccionar pedido y hacer clic en "Contactar". Podrás hablar e intercambiar información con el prestador del servicio en los horarios laborales indicados en el botón (i) azúl en la parte derecha del botón del prestador del servicio.

                          </p>

                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        ¿Cómo puedo contactar a DocuDoc acerca de un asunto no relacionado con un servicio contratado?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <p>Si deseas contactar con DocuDoc acerca de un asunto no relacionado con un servicio contratado, puedes hacerlo a través del siguiente correo electrónico: <a href="./info@docudocapp.com">info@docudocapp.com</a>.</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        ¿Cómo garantizamos pagos seguros?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <p>
                          Sólo si pagas a través de la plataforma, te garantizamos que recibas el servicio de forma correcta.
                           Si no recibes parte o la totalidad del servicio, te garantizamos un reembolso por la parte del servicio no recibido. 

                          </p>
                           <br/>
                          
                          <p>
                          No te recomendamos que contrates servicios con ningún prestadores de servicio fuera de la plataforma,
                           para que sigas disfrutando de esta garantía de pago seguro y reembolso en caso de no recibir parte o la totalidad del servicio.

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="3">
                        ¿Cómo puedo reclamar parte o la totalidad del dinero pagado por el servicio no recibido?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          <p>
                          Si deseas reclamar parte o la totalidad del dinero pagado por el servicio no recibido,
                           deberás ir a Tu perfil > Mis pedidos > seleccionar pedido y hacer clic en "Reclamar dinero".
                            Una vez se compruebe que efectivamente no has recibido parte o la totalidad del servicio contratado, se te reembolsará la parte correspondiente. 

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="4">
                        ¿Cuándo puedo descargar mi factura?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="4">
                        <Card.Body>
                          <p>
                          La factura del servicio contratado se podrá descargar una vez pasado 24h después de la finalización y entrega del servicio contratado en Tu perfil > Mis pedidos > seleccionar pedido y hacer clic en "Descargar Factura".

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="5">
                        ¿Cómo puedo acceder a la información de cada servicio, rama de servicio, área de servicio o prestador de servicio?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="5">
                        <Card.Body>
                          <p>
                          La información completa de cada servicio, rama de servicio,
                         área de servicio o prestador de servicio se encuentra  en el botón (i) azúl en la parte derecha de cada botón de cada servicio,
                         rama de servicio, área de servicio o prestador de servicio.


                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="6">
                        ¿Quiénes son los prestadores de servicios?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="6">
                        <Card.Body>
                          <p>
                          Los prestadores de servicios son profesionales independientes conectados a nuestra plataforma ofreciendo precios especiales para llevar a cabo los servicios legales que te hagan falta. Tratamos de conseguir el mejor precio calidad del mercado con nuestros prestadores de servicios, que te ayudarán a resolver tus gestiones del modo más rápido y eficaz.
                          ¿Quieres ser prestadores de servicio? ¡Genial! Solo tienes que escribirnos al siguiente correo electrónico
                          <a href="info@docudocapp.com">info@docudocapp.com!</a></p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="7">
                        ¿Cómo hago un pedido?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="7">
                        <Card.Body>
                          <p>
                          Pedir tu primer DocuDoc es muy fácil. Simplemente sigue estos pasos:
:
                          </p>
                          <ol>
                            <li>Ve a la pantalla principal y selecciona el área de servicios, rama de servicio y servicio final.</li>
                            <li> Introduce los datos necesarios para llevar a cabo el servicio.</li>
                            <li> Selecciona el prestador del servicio que llevará a cabo el servicio.</li>
                          </ol>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="8">
                        ¿Llega DocuDoc a mi barrio?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="8">
                        <Card.Body>
                          <p>
                          DocuDoc, de momento, sólo está presente en la Comunidad de Madrid.

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="9">
                        ¿Cuándo está abierto DocuDoc?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="9">
                        <Card.Body>
                          <p>
                          DocuDoc ofrecen un servicio 24/7. No obstante, cada prestadores de servicio tiene su propio horario. Para guiarte, los horarios de los prestadores de servicios se encuentran en el botón (i) azúl en la parte derecha del botón del prestador del servicio. 
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="10">
                        ¿Cómo puedo cambiar los detalles de mi pedido?


                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="10">
                        <Card.Body>
                          <p>
                          Si deseas cambiar los detalles de tu pedido, deberás ir a tu perfil > Mis pedidos > seleccionar pedido y hacer clic en "Contactar".
                           Podrás hablar e intercambiar información con el prestador del servicio. 

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="11">
                        ¿Cómo puedo cancelar mi pedido?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="11">
                        <Card.Body>
                          <p>
                          Si deseas cambiar los detalles de tu pedido o simplemente deseas cancelarlo,
                           deberás ir a tu perfil > Mis pedidos > seleccionar pedido y hacer clic en "Cancelar pedido".
                           Se te cobrarán los gastos incurridos por el prestador del servicio. 

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                   
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="12">
                        ¿Qué puedo pedir?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="12">
                        <Card.Body>
                          <p>
                          Utiliza la app de DocuDoc para pedir cualquier servicio legal disponible.


                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="13">
                        ¿Qué es DocuDoc?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="13">
                        <Card.Body>
                          <p>
                          DocuDoc es la app que te permite pedir todo tipo de servicio legal en minutos.
                           Una gestión fiscal, un asesor o un abogado especializado.
                            Todo que esté relacionado con una Gestoría, Asesoría o un Abogado.


                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="14">
                        ¿Cómo puedo encontrar lo que busco?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="14">
                        <Card.Body>
                          <p>
                          En la parte superior de la pantalla, 
                          hay un buscador que te permitirá encontrar cualquier servicio o rama de servicios que ofrecemos.


                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="15">
                        ¿Cómo puedo cambiar mi número de teléfono?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="15">
                        <Card.Body>
                          <p>
                          Puedes cambiar tu número de contacto en tu perfil.
                         Una vez realizado el cambio, recibirás un código de verificación. El número de teléfono se actualizará de inmediato después de la confirmación. 


                          
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="16">
                        ¿Cómo puedo cambiar mi dirección de e-mail?


                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="16">
                        <Card.Body>
                          <p>
                          Puedes cambiar la dirección de e-mail asociada a tu cuenta en la información de tu perfil. 
                          Una vez actualizada, recibirás una alerta de verificación. Tras confirmar, se cambiara tu e-mail.


                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="17">
                        ¿Cómo puedo cambiar mi contraseña?


                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="17">
                        <Card.Body>
                          <p>
                          Puedes cambiar la contraseña asociada a tu cuenta en la información de tu perfil. 
                          Una vez actualizada, recibirás una notificación. Tras confirmar, se cambiara tu contraseña.


                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="18">
                        ¿Cómo puedo cambiar mi método de pago?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="18">
                        <Card.Body>
                          <p>
                          Puedes cambiar tu método de pago preferido en la información de tu perfil,
                          donde puedes añadir o eliminar tarjetas. Una vez actualizado, se cambiará de inmediato para que puedas continuar pidiendo servicios en DocuDoc.

                            </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="19">
                        ¿Qué tipo de tarjetas aceptáis?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="19">
                        <Card.Body>
                          <p>
                          Aceptamos tarjetas Visa, MasterCard y American Express.


                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="20">
                        ¿Cuáles son los métodos de pago disponibles?


                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="20">
                        <Card.Body>
                          <p>
                          DocuDoc acepta tarjetas de crédito o débito, como Visa, MasterCard o American Express.
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="21">
                        ¿Es seguro añadir mi tarjeta de crédito?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="21">
                        <Card.Body>
                          <p>
                        Nuestro sistema de pago es totalmente seguro.
                        Con el fin de garantizar tu seguridad evitamos guardar cualquier dato bancario. 
                        Utilizamos la misma plataforma de pago que empresas como Twitter, Shopify o Instacart.


                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="22">
                        ¿Por qué se me ha pedido que verifique mi identidad?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="22">
                        <Card.Body>
                          <p>
                          Tenemos el firme propósito de garantizar que nuestra plataforma sea fiable y segura para ti y todos los miembros de DocuDoc. Por este motivo,
                           nuestros agentes pueden pedirte alguna prueba de identidad si se detecta una acción inusual. Si esto sucede, deberás adjuntar los documentos que solicitamos para seguir utilizando tu cuenta de DocuDoc.

                          <br>
                          </br>
                           <br>
                          </br>
                          La seguridad es crucial para nosotros,
                           por lo que te pedimos que no compartas tu cuenta ni los detalles de pago con nadie que no sea un colaborador interno de DocuDoc.
                         También nos gustaría recordarte la importancia de tener contraseñas distintas para cada una de las plataformas que utilizas.


                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                   
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="23">
                        ¿Cómo puedo canjear mi código promocional?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="23">
                        <Card.Body>
                          <p>
                          Antes de pagar el servicio,
                          en la pestaña del Checkout, 
                          tienes que introducir el código para activarlo y podrás disfrutar del descuento.

                            <br />
                            <br />
                            *La validez y caducidad de los mismos puede estar sujeta a cambios sin previo aviso por parte de DocuDoc según exigencias de las campañas promocionales.

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>
      );

}
export default FaqSpanish;