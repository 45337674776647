import { onValue, ref } from "firebase/database";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import validator from "validator";
import { WorkWithUsModalContext } from "../App";
import { Get_Orders_Method, Work_With_Us } from "../config/api";
import { database } from "../config/Firebase";
import labels from "../config/Labels";
import { useStoreItem } from "../config/Store";
import { Utility } from "../config/Utility";
import { Images } from "../constant/images";
import { GetAllOrders } from "../store/actions/orders";

export default function SideMenu(props) {
  const [emailError, setEmailError] = useState("");

  const workWithUsModalVisible = useContext(WorkWithUsModalContext);
  const { modalState, modalDispatch } = workWithUsModalVisible;

  const validateEmail = (e) => {
    var email = e.target.value;
    if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Enter valid Email!");
    }
  };
  const { push } = useHistory();
  const { addToast } = useToasts();

  const { updateUser } = useStoreItem();

  const userLogout = () => {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("branches");
    localStorage.removeItem("branchName");
    localStorage.removeItem("menukey");
    localStorage.removeItem("branchId");
    localStorage.removeItem("emailAddress");
    localStorage.removeItem("phone_number");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    updateUser("");
    push("/");
  };

  const [show, setShow] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [number, setnumber] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [showToast, setShowToast] = React.useState("");
  const [validate, setValidate] = React.useState(false);
  const [value, setValue] = useState("");
  const [contactNo, setContactNo] = useState("");

  const [orderMessages, setOrderMessages] = useState([]);
  const [alertCount, setAlertCount] = useState(0);
  const [additionalBudgetCount, setAdditionalBudgetCount] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);

  const [orders, setOrders] = useState({});
  const dispatch = useDispatch();
  const ordersListing = useSelector((state) => state?.orders?.orders);
  const getOrderMessages = () => {
    Get_Orders_Method(
      (value) => {
        setShowSpinner(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
        let orderUnreadCount = 0;

        let ordersArray = [
          [
            ...(value?.pending_orders || []),
            ...(value?.disputed_orders || []),
            ...(value?.dispute_orders || []),
            ...(value?.completed_orders || []),
            ...(value?.cancelled_orders || []),
          ]
            .filter(
              (opt) =>
                opt.order_chat !== null && opt.order_chat.messages.length !== 0
            )
            ?.map((item, index) => {
              const getData = ref(
                database,
                "orders/" + item?.order_id + "/userUnread"
              );
              const unReadCount = onValue(getData, (snapshot) => {
                if (snapshot.val() > 0) {
                  setOrderMessages((prev) => [...prev, item]);
                }
              });
              return {
                // ...item,
                userUnreadCount: orderUnreadCount,
              };
            }),
        ];
        // setOrderMessages(
        //   ordersArray?.filter((item) => item?.userUnreadCount > 0)
        // );
        // setAlertCount([
        //   ...(value?.pending_orders || []),
        //   ...(value?.disputed_orders || []),
        //   ...(value?.dispute_orders || []),
        //   ...(value?.completed_orders || []),
        //   ...(value?.cancelled_orders || []),
        // ]);
        setAlertCount(
          [
            ...(value?.pending_orders || []),
            ...(value?.disputed_orders || []),
            ...(value?.dispute_orders || []),
            ...(value?.completed_orders || []),
            ...(value?.cancelled_orders || []),
          ].reduce(
            (accumulator, currentValue) =>
              accumulator + Number(currentValue?.notifications_count),
            0
          )
        );
      }
    );
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // React.useEffect(() => {
  // setValidate(false)

  // }, [email,number,name,description])
  // React.useEffect(() => {
  //   dispatch(
  //     GetAllOrders((failure) => {
  //       setMessage(failure);
  //       setSuccess(false);
  //       setShowToast(true);
  //     })
  //   );
  // }, []);
  useEffect(() => {
    let orderUnreadCount = 0;

    let ordersArray = [
      ...(ordersListing?.pending_orders || []),
      ...(ordersListing?.disputed_orders || []),
      ...(ordersListing?.dispute_orders || []),
      ...(ordersListing?.completed_orders || []),
      ...(ordersListing?.cancelled_orders || []),
    ];
    let messageOrdersArray = [
      [
        ...(ordersListing?.pending_orders || []),
        ...(ordersListing?.disputed_orders || []),
        ...(ordersListing?.dispute_orders || []),
        ...(ordersListing?.completed_orders || []),
        ...(ordersListing?.cancelled_orders || []),
      ]
        .filter(
          (opt) =>
            opt.order_chat !== null && opt.order_chat.messages.length !== 0
        )
        ?.map((item, index) => {
          const getData = ref(
            database,
            "orders/" + item?.order_id + "/userUnread"
          );
          const unReadCount = onValue(getData, (snapshot) => {
            if (snapshot.val() > 0) {
              setOrderMessages((prev) => [...prev, item]);
            }
          });
          return {
            // ...item,
            userUnreadCount: orderUnreadCount,
          };
        }),
    ];

    setAlertCount(
      ordersArray.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue?.notifications_count),
        0
      )
    );
    setAdditionalBudgetCount(
      ordersArray.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue?.additional_budgets_count),
        0
      )
    );
    setOrders(ordersArray);
  }, [ordersListing]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton className="d-flex justify-content-center">
          <Modal.Title className="d-flex justify-content-around" id="">
            {labels.WorkWithUs}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form.Control
              type="text"
              placeholder={labels?.enterfirstName}
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
            {/* {email.length > 0 && setValidate(false)} */}
            {!name.length && validate && (
              <p className="text-danger">{labels.validateField}</p>
            )}
            <br />
            <Form.Control
              type="email"
              placeholder={labels.Email}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value.trim(""));
              }}
            />

            {validate && !email.length ? (
              <p style={{ color: "red" }}>{labels.validateField}</p>
            ) : (
              email?.trim()?.length !== 0 &&
              !Utility?.validateEmail(email) && (
                <p style={{ color: "red" }}>{labels.emailValidateField}</p>
              )
            )}
            <br />
            <input
              className="form-control"
              maxLength={20}
              autoComplete={false}
              type="tel"
              placeholder={labels.phoneNumber}
              value={value}
              onChange={(e) => {
                const re = /^[0-9-+\b]+$/;

                // if value is not blank, then test the regex

                if (e.target.value === "" || re.test(e.target.value)) {
                  setValue(e.target.value);
                }
              }}
            />
            {/* <PhoneInput
              style={{ width: "100%", marginRight: "5px" }}
              international
              countryCallingCodeEditable={false}
              defaultCountry="ES"
              value={value}
              maxLength={20}
              onChange={setValue}
            /> */}
            {validate && !value?.trim().length ? (
              <p className="text-justify" style={{ color: "red" }}>
                {labels.validateField}
              </p>
            ) : (
              validate &&
              !Utility?.validatePhone(value) && (
                <p className="text-justify" style={{ color: "red" }}>
                  {labels.invalidPhoneNumber}{" "}
                </p>
              )
            )}

            <br />
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={labels.description}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              required
            />
            {!description.length && validate && (
              <p className="text-danger">{labels.validateField}</p>
            )}
          </>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around">
          <Button
            variant="primary"
            onClick={() => {
              if (
                name !== "" &&
                email !== "" &&
                value?.length >= 7 &&
                description !== ""
              ) {
                setValidate(false);
                Work_With_Us(
                  {
                    name: name,
                    email: email,
                    number: value,
                    description: description,
                  },
                  (value) => {
                    setSuccess(value);
                    setName("");
                    setEmail("");
                    setValue("");
                    setDescription("");
                    setValidate(false);
                  },
                  (value) => {
                    setMessage(value);
                    addToast(value, {
                      placement: "top-center",
                      autoDismiss: 1000,
                      appearance: labels.workWithUsToastMessage
                        ? "success"
                        : "error",
                    });
                    // if (
                    //   value ===
                    //   labels.workWithUsToastMessage
                    // ) {
                    //   setTimeout(() => {
                    //     setShow(false);
                    //   }, 2000);
                    // }
                    setShow(false);
                  },
                  (value) => {
                    setShowToast(value);
                  }
                );

                setDescription("");
                setEmail("");
                setName("");
                setnumber("");
              } else {
                setValidate(true);
              }
            }}
          >
            {showToast ? labels.loading : labels.sendRequest}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="left-side-tabs mt-0 mb-25">
        <div className="dashboard-left-links">
          <Link
            to="/1/myaccount"
            className={`${
              props.path == "/1/myaccount" && !modalState ? "active" : ""
            } user-item `}
          >
            <img
              src={Images.myInformation.default}
              style={{
                filter:
                  props.path == "/1/myaccount" && !modalState
                    ? "brightness(10)"
                    : "inherit",
              }}
            />
            {labels.myInformation}
          </Link>
          <Link
            onClick={() => {
              localStorage.setItem("orderCurrentActiveTab", "home");
            }}
            to={{ pathname: "/1/myorder", state: { label: "" } }}
            className={`${
              props.path == "/1/myorder" && !modalState ? "active" : ""
            } user-item `}
          >
            <img src={Images.myorders.default} /> {labels.myOrder}
          </Link>
          {/* <Link
            to={{ pathname: "/1/mybudget", state: { label: "" } }}
            className={`${
              props.path == "/1/mybudget" && !modalState ? "active" : ""
            } user-item `}
          >
            <img src={Images.additionalBudgetIcon.default} /> {labels.myBudgets}
          </Link> */}
          <Link
            to={{ pathname: "/1/mybudget", state: { taber: "menu" } }}
            className={`${
              props.path == "/1/mybudget" && !modalState ? "active" : ""
            } user-item `}
          >
            <div
              className="d-flex justify-content-between align-items-center w-100
            "
            >
              <div>
                <img
                  src={
                    Images?.[
                      props.path == "/1/mybudget"
                        ? "additionalBudgetActiveIcon"
                        : "additionalBudgetIcon"
                    ].default
                  }
                />{" "}
                {labels.myBudgets}
              </div>
              <>
                {additionalBudgetCount ? (
                  <div
                    className="d-flex justify-content-center align-items-center rounded-circle  text-white "
                    style={{
                      fontSize: "10px",
                      width: "25px",
                      height: "25px",
                      background: "#ff6464ff",
                    }}
                  >
                    {additionalBudgetCount}
                  </div>
                ) : null}
              </>
            </div>
          </Link>
          <Link
            to={{ pathname: "/1/mysavedorder", state: { label: "" } }}
            className={`${
              props.path == "/1/mysavedorder" && !modalState ? "active" : ""
            } user-item `}
          >
            {/* saveDraftACtiveIcon, */}
            <img
              src={
                Images?.[
                  props.path == "/1/mysavedorder"
                    ? "saveDraftACtiveIcon"
                    : "saveDraftIcon"
                ].default
              }
            />{" "}
            {labels.mySavedOrder}
          </Link>
          <Link
            to="/1/mymessages"
            className={`${
              props.path == "/1/mymessages" && !modalState ? "active" : ""
            } user-item `}
          >
            <div
              className="d-flex justify-content-between align-items-center w-100
            "
            >
              <div>
                <img
                  src={
                    Images?.[
                      props.path == "/1/mymessages"
                        ? "messagesActiveIcon"
                        : "messages"
                    ].default
                  }
                />{" "}
                {labels.myMessage}
              </div>
              <>
                {orderMessages?.length ? (
                  <div
                    className="d-flex justify-content-center align-items-center rounded-circle  text-white"
                    style={{
                      fontSize: "10px",
                      width: "25px",
                      height: "25px",
                      background: "#ff6464ff",
                    }}
                  >
                    {orderMessages?.length}
                  </div>
                ) : null}
              </>
            </div>
          </Link>
          <Link
            to="/1/alerts"
            className={`${
              props.path == "/1/alerts" && !modalState ? "active" : ""
            } user-item `}
          >
            <div
              className="d-flex justify-content-between align-items-center w-100
            "
            >
              <div>
                <img
                  src={
                    Images?.[
                      `${
                        props.path == "/1/alerts"
                          ? "alertActiveBellIcon"
                          : "alertBellIcon"
                      }`
                    ].default
                  }
                />{" "}
                {labels.alerts}
              </div>
              <>
                {alertCount ? (
                  <div
                    className="d-flex justify-content-center align-items-center rounded-circle  text-white "
                    style={{
                      fontSize: "10px",
                      width: "25px",
                      height: "25px",
                      background: "#ff6464ff",
                    }}
                  >
                    {alertCount}
                  </div>
                ) : null}
              </>
            </div>
          </Link>
          <Link
            to="/1/share-win"
            className={`${
              props.path == "/1/share-win" && !modalState ? "active" : ""
            } user-item `}
          >
            <img src={Images.promoGift.default} /> {labels.shareWin}
          </Link>
          <Link
            to="/1/SpecialPriceServices"
            className={`${
              props.path == "/1/SpecialPriceServices" && !modalState
                ? "active"
                : ""
            } user-item `}
          >
            <img src={Images.shareWin.default} />
            {labels.specialPrices}
          </Link>
          <Link
            to="/1/help"
            className={`${
              props.path == "/1/help" && !modalState ? "active" : ""
            } user-item `}
          >
            <img
              src={Images.help.default}
              style={{
                filter:
                  props.path == "/1/help" && !modalState
                    ? "brightness(10)"
                    : "inherit",
              }}
            />{" "}
            {labels.help}
          </Link>
          <Link
            onClick={() => modalDispatch("open")}
            className={`${modalState ? "active" : ""} user-item `}
          >
            <img
              src={
                modalState
                  ? Images.workWithUsActiveIcon.default
                  : Images.workWithUs.default
              }
            />
            {labels.WorkWithUs}
          </Link>
          <Link
            href="/"
            onClick={userLogout}
            className={`${
              props.path == "/1/" && !modalState ? "active" : ""
            } user-item `}
          >
            <img src={Images.logout.default} /> {labels.exit}
          </Link>
        </div>
      </div>
    </>
  );
}
