import React from "react";

function OurAppSpanish() {
  return (
    <div className="ins-float-icon-box-content">
      <h4 className=" mt-0 ">DocuDoc App&nbsp;-&nbsp;Servicios Legales</h4>
      <div className="last-paragraph-no-margin">
        <p className="ins-float-icon-box-text  default ">
          DocuDoc es la primera App para contratar gestiones y servicios legales
          prestados por abogados, asesorías y gestorías al mejor precio, de
          forma rápida, transparente y segura. Si no recibes el servicio
          jurídico contratado te devolvemos tu dinero.
        </p>

        <p className="ins-float-icon-box-text  default ">
          Descarga la App DocuDoc y contrata todo tipo de servicios jurídicos de
          abogados, asesores y gestores especializados y mejor valorados cerca
          de ti en pocos clicks, sin pérdida de tiempo ni desplazamientos. Solo
          encontrarás profesionales que garantizan la mejor calidad del
          servicio.
        </p>
        <p className="ins-float-icon-box-text  default ">
          DocuDoc ofrece servicios a particulares, autónomos y empresas y nos
          especializamos en las ramas legal, fiscal, administrativas, de
          extranjería y de tráfico entre otras.
        </p>
      </div>

      <div className="last-paragraph-no-margin"></div>
    </div>
  );
}
export default OurAppSpanish;
