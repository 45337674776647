import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { Button, Col, Modal, ModalBody, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  Additional_Budget_Decline_Accept,
  Add_Primary_Card,
  Delete_Credit_Card,
  Get_Credit_Cards,
  Additional_Budget_Dispute,
} from "../config/api/index";
import labels from "../config/Labels";
import {
  date_difference_checker,
  functionToDownloadFile,
  get_url_extension,
  languageTranslationFunctionForTheTime,
  replaceDecimalWithComma,
} from "../config/Utility";
import { Images } from "../constant/images";
import CreditCardScreen from "../screen/creditCard/creditCard";
import MyModal from "./MyModal";
import ToastComponent from "./toast";
import moment from "moment";
import {
  child,
  onValue,
  push as fbPush,
  ref,
  serverTimestamp,
  update,
} from "firebase/database";

import { useToasts } from "react-toast-notifications";
import { database } from "../config/Firebase";
const stripePromise = loadStripe(process.env.REACT_APP_API_KEY);
let firstName = localStorage.getItem("firstName");
let lastName = localStorage.getItem("lastName");
const { trash } = Images;
export default function AdditionalOrderDetails({
  id,
  name,
  des,
  price,
  discount,
  path,
  image,
  promo,
  serviceId = "",
  ammount = "",
  notes = "",
  total = "",
  supplementoryCharges = "",
  additionalBudgetId = "",
  status = "",
  files = "",
  updateStateFunction,
  item,
  orderObject,
}) {
  const { push } = useHistory();
  const { addToast } = useToasts();

  const AdditionalOrderDetails = {
    id,
    name,
    des,
    price,
    discount,
    image,
    promo,
    item,
    ammount,
    notes,
    total,
    supplementoryCharges,
    additionalBudgetId,
    status,
    files,
  };
  const [supplementry, setSupplementry] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [isPaymentModal, setIsPaymentModal] = useState(false);
  const [toatlAmountService, setTotalAmountService] = useState(0);

  const VAT = 21;
  const vat = 1.21;

  const [creditCards, setCreditCards] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSpinner1, setShowSpinner1] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [cardId, setCardId] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [code, setCode] = useState("");
  const [isPromo, setIsPromo] = useState(false);
  const [promoDiscount, setPromoDiscount] = useState(0);
  const [notesModal, setNotesModal] = useState(false);
  const [changeRequest, setChangeRequest] = useState("");
  const [fieldValidate, setFieldValidate] = useState(false);
  const [formvisible, setFormVisible] = useState(false);
  const [primaryCards, setPrimaryCards] = useState([]);
  const [totalTax, setTotalTax] = useState(null);
  const [disputeModal, setShowDisputeModal] = useState(false);
  const [state, setState] = useState({ reason: "", amount: "" });
  const [disputeReason, setDisputeReason] = useState("");
  const [spinner, setSpinner] = useState(false);

  const [loop, setLoop] = useState();
  const spainTax = "21";
  React.useEffect(() => {
    getCards();

    let taxAmount = (Number(ammount) / vat) * 0.21;
    setTotalTax(taxAmount);
    setTotalAmountService(ammount);
    // Total with VAT 21%
    // let totalAmount = (ammount - taxAmount);
  }, []);

  React.useEffect(() => {
    getCards();
  }, [isPaymentModal]);
  const accept_decline_budger = (data) => {
    let obj = {
      additional_budget_id: additionalBudgetId,
      status: data,
      reason: changeRequest,
    };
    Additional_Budget_Decline_Accept(
      obj,
      (value) => {
        updateStateFunction(value);
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setSuccess(value);
        setChangeRequest("");
      }
    );
  };

  const closeToast = () => {
    setShowToast(false);
  };

  const closeToast1 = () => {
    setShowToast1(false);
  };

  const getCards = () => {
    Get_Credit_Cards(
      (value) => {
        setShowSpinner(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },

      (value) => {
        let mappedCrads = value?.map((card, i) => {
          if (card.is_default === 1) {
            setCustomerId(card.customer_id);
            return {
              ...card,
              checked: true,
            };
          } else {
            return {
              ...card,
              checked: false,
            };
          }
        });

        setCreditCards(mappedCrads);
      }
    );
  };

  const primaryCard = (card_id) => {
    Add_Primary_Card(
      { card_id: card_id },

      (value) => {
        setSuccess(value);

        setPrimaryCards(value);
        getCards();
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast1(value);
      }
    );
  };

  const onClickItem = (cardItem, index) => {
    const updatedArray = creditCards.map((c, i) => {
      if (i === index) {
        return {
          ...c,
          checked: !c.checked,
        };
      } else {
        return {
          ...c,
          checked: false,
        };
      }
    });
    setCustomerId(cardItem.customer_id);
    setCreditCards(updatedArray);
  };

  const deleteCreditCardMethod = () => {
    Delete_Credit_Card(
      cardId,
      (value) => {
        setShowSpinner1(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast1(value);
      },
      (value) => {
        setCreditCards(value);
      },
      () => {
        setIsConfirmationModal(false);
        getCards();
      },
      addToast
    );
  };

  let currentCard = creditCards?.filter((item) => item?.is_default == 1);
  const downloadBillFunction = (id) => {
    functionToDownloadFile(
      `https://api.docudocapp.com/api/download_additional_budget_bill/${id}`,
      `${labels.additionalBudget}.pdf`
    );
  };
  const handleCloseDispute = () => {
    setShowDisputeModal(false);
    setState({ reason: "", amount: "" });
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
    // setState((prev) => ({ ...prev, prev?.name: value }))
  };

  //orderDispute
  const disputeAdditionalBudget = () => {
    let obj = {
      order_id: item?.order_id,
      additional_budget_id: item?.additional_budget_id,
    };

    Additional_Budget_Dispute(
      obj,

      (value) => {
        setSuccess(value);
        if (value) {
          setTimeout(() => {
            // localStorage.setItem("orderCurrentActiveTab", "menu3");
            push({
              pathname: `/specficordermessage/${item?.order_id}`,
              state: {
                label: "dispute",
                amount: state?.amount,
                reason: state?.reason,
              },
            });
          }, 4000);
        }
      },
      (value) => {
        setMessage(value);
        addToast(value, { appearance: "info", autoDismiss: 1000 });
      },
      (value) => {
        setSpinner(value);
      }
    );

    const postData = {
      is_provider_message: "0",
      service_provider_id: orderObject.service_provider_id,
      service_provider: orderObject.service_provider,
      admin_id: null,
      admin: null,
      is_admin_message: "0",
      is_dispute: "1",
      user_id: orderObject.user_id,
      user: { first_name: firstName, last_name: lastName },
      message: [
        {
          message: disputeReason,
          documents: null,
        },
      ],
      time: serverTimestamp(),
    };
    const newPostKey = fbPush(child(ref(database), "posts")).key;
    // // Write the new post's data simultaneously in the posts list and the user's post list.
    const updates = {};

    updates["/orders/" + item?.order_id + "/messages/" + newPostKey] = postData;

    return update(ref(database), updates);
  };
  return (
    <div className="pdpt-bg w-100 mb-2 p-2">
      <>
        <Modal
          className="modelBackground"
          show={isPaymentModal}
          onHide={() => setIsPaymentModal(false)}
          size="lg"
        >
          <ModalBody className="bg-white p-0 border-radius-10 alignItemsCenter textAlignCenter m-auto  my-20 ">
            <button
              type="button"
              className="close absolute"
              data-dismiss="modal"
              onClick={() => setIsPaymentModal(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <div
              style={{
                backgroundColor: "white",
                width: "500px",
                height: "auto",

                paddingTop: "30px",
              }}
            >
              {creditCards.length == "0" ? (
                <>
                  <h4>{labels.addPaymentCard}</h4>

                  <Elements stripe={stripePromise}>
                    <CreditCardScreen
                      modalClose={() => setIsPaymentModal(false)}
                      getCreditCards={getCards}
                    />
                    <div className="mx-4 pb-3 ">
                      <p
                        className="fontSize13px font-weight-normal"
                        style={{ lineHeight: "normal" }}
                      >
                        {labels?.freeServicePaymentMessage}
                      </p>
                    </div>
                  </Elements>
                </>
              ) : (
                <div className="">
                  <h5 className="textAlignCenter">{labels.selectCard}</h5>

                  {showSpinner
                    ? labels.loading
                    : creditCards.map((cardDetail, index) => {
                        return (
                          <>
                            <div className="card-item-container">
                              <div className="card-item" key={index}>
                                <span className="card-no">{`*** *** *** ${cardDetail.last_4_digit}`}</span>
                                {cardDetail.checked === true ? (
                                  <div className="radio-upper">
                                    <div className="radio-inner"></div>
                                  </div>
                                ) : (
                                  <div
                                    className="radio-upper"
                                    onClick={() => {
                                      onClickItem(cardDetail, index);
                                      primaryCard(cardDetail?.card_id);
                                    }}
                                  ></div>
                                )}
                              </div>

                              <img
                                src={trash.default}
                                onClick={() => {
                                  setIsConfirmationModal(true);
                                  setCardId(cardDetail.card_id);
                                }}
                                style={{ cursor: "pointer" }}
                              />
                              <br />
                            </div>
                          </>
                        );
                      })}
                  <button
                    className="mt-2"
                    onClick={() => {
                      accept_decline_budger("accept");
                      setTimeout(() => {
                        setIsPaymentModal(false);
                      }, 3000);
                    }}
                  >
                    {labels.payNow}
                  </button>
                  {showToast === true ? (
                    <ToastComponent
                      message={message}
                      toastClose={closeToast}
                      success={success}
                    />
                  ) : (
                    ""
                  )}
                  {!formvisible && (
                    <div
                      className="cursorPointer"
                      onClick={() => {
                        setFormVisible(true);
                      }}
                      style={{ width: "100%" }}
                    >
                      <div className="p-3">
                        <strong>{labels.addpaymentcard}</strong>
                      </div>
                    </div>
                  )}

                  {formvisible && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      fill="currentColor"
                      className="bi bi-dash cursorPointer"
                      viewBox="0 0 16 16"
                      onClick={() => {
                        setFormVisible(false);
                      }}
                    >
                      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                    </svg>
                  )}

                  {formvisible && (
                    <Elements stripe={stripePromise}>
                      <CreditCardScreen
                        modalClose={() => setIsPaymentModal(false)}
                        getCreditCards={getCards}
                      />
                      <div className="mx-4 pb-3 ">
                        <p
                          className="fontSize13px font-weight-normal"
                          style={{ lineHeight: "normal" }}
                        >
                          {labels?.freeServicePaymentMessage}
                        </p>
                      </div>
                    </Elements>
                  )}
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header
            closeButton
            onClick={() => {
              setFieldValidate(false);
            }}
          >
            <Modal.Title>{labels.writeRequet}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea
              className="w-100 p-1"
              onChange={(e) => {
                setChangeRequest(e?.target?.value);
              }}
            />
            {fieldValidate && changeRequest.length <= 0 && (
              <p style={{ color: "red" }}>{labels.validateField}</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              {labels.cancel}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (changeRequest.length) {
                  accept_decline_budger("decline");
                  setTimeout(() => {
                    setShow(false);
                  }, 3000);
                } else {
                  setFieldValidate(true);
                }
              }}
            >
              {labels.ok}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          className="modelBackground"
          show={isConfirmationModal}
          onHide={() => setIsConfirmationModal(false)}
          size="lg"
        >
          <ModalBody className="bg-white border-radius-10 alignItemsCenter textAlignCenter m-auto height_71 my-20">
            <button
              type="button"
              className="close absolute"
              data-dismiss="modal"
              onClick={() => setIsConfirmationModal(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            {showToast1 === true ? (
              <ToastComponent
                message={message}
                toastClose={closeToast1}
                success={success}
              />
            ) : (
              ""
            )}
            <div className="p-3">
              <h5 className="textAlignCenter">{labels.confirmation}</h5>
              <p className="mx-30">{labels.deleteCard}</p>
              <div className="d-flex justify-content-around align-content-center">
                <div className="w-50 pr-1">
                  <button
                    onClick={() => setIsConfirmationModal(false)}
                    className="w-100"
                    style={{ backgroundColor: "red", borderColor: "red" }}
                  >
                    {labels.cancel}
                  </button>{" "}
                </div>
                <div className="w-50 pr-1">
                  <button
                    onClick={() => deleteCreditCardMethod()}
                    className="w-100"
                  >
                    {showSpinner1 ? labels.loading : labels.ok}
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <MyModal
          info={notes}
          show={notesModal}
          onHide={() => setNotesModal(false)}
        />
      </>
      <div className="p-2">
        <h4 className="">{labels.additionalBudgetDetails}</h4>
      </div>
      <Row>
        <Col sm={6}>
          <div className="order-body10 mb-2">
            <div className="order-dtsll">
              <div>
                <div className="order-dt47 pt-3 m-0">
                  <div className="product-details">
                    <div className="d-flex flex-column flex-wrap">
                      <h4 className="">{labels.document}</h4>
                      <div className="">
                        <span className="d-flex flex-wrap">
                          {JSON?.parse(files).length
                            ? JSON?.parse(files)?.map((item) => {
                                return (
                                  <span
                                    className="alignItemsCenter cursorPointer d-flex"
                                    onClick={() =>
                                      functionToDownloadFile(
                                        item?.url,
                                        item?.name
                                      )
                                    }
                                  >
                                    <img
                                      className="documentImageSize"
                                      src={
                                        item !== null &&
                                        Images?.document?.default
                                      }
                                    />
                                    {item?.length !== null && (
                                      <span>{item?.name}</span>
                                    )}
                                  </span>
                                );
                              })
                            : labels.noDocumentFound}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={6}>
          <div className="order-body10 mb-2">
            <div className="order-dtsll">
              <div>
                <div className="order-dt47 pt-3 m-0">
                  <div className="product-details">
                    <div className="d-flex flex-column">
                      <h4 className="">{labels.additionalBudgetAmount}</h4>
                      <div className=" d-flex justify-content-between ">
                        <span>
                          <span>{labels.baseImponible}</span>
                        </span>{" "}
                        <span>
                          <strong className="text-success">
                            {" € "}{" "}
                            {replaceDecimalWithComma(
                              Number(toatlAmountService) / 1.21
                            )}
                          </strong>
                        </span>
                      </div>
                      <div className=" d-flex justify-content-between ">
                        <span>
                          {labels.vat} {21}%
                        </span>{" "}
                        <span>
                          <strong className="text-success">
                            {" € "}
                            {`${replaceDecimalWithComma(Number(totalTax))}`}
                          </strong>
                        </span>
                      </div>
                      <div className=" d-flex justify-content-between ">
                        <span>{labels.supplementaryFees}</span>
                        <strong className="text-success">
                          {" € "}
                          {replaceDecimalWithComma(
                            Number(supplementoryCharges || 0)
                          )}{" "}
                        </strong>
                      </div>

                      <div>
                        {total?.map((item) => {
                          return (
                            <div className=" d-flex justify-content-between text-secondary pl-3">
                              <small>{item?.name}</small>
                              <small>
                                {" € "}
                                {replaceDecimalWithComma(item?.price)}
                              </small>
                            </div>
                          );
                        })}
                      </div>
                      {/* <div className=" d-flex justify-content-between ">
                        <span>
                          <strong>
                            {labels.vat} {21}%
                          </strong>
                        </span>{" "}
                        <span>
                          <strong className="text-success">
                            {" € "}
                            {replaceDecimalWithComma(Number(totalTax || 0))}
                          </strong>
                        </span>
                      </div> */}
                      <div className=" d-flex justify-content-between ">
                        <span>
                          <strong>{labels.Total}</strong>
                        </span>{" "}
                        <span>
                          <strong className="text-success">
                            {" € "}
                            {replaceDecimalWithComma(
                              Number(supplementoryCharges || 0) +
                                Number(ammount || 0)
                            )}{" "}
                          </strong>
                        </span>
                      </div>

                      <div className=" d-flex justify-content-between ">
                        <span>
                          <strong>{labels.estimatedTime}</strong>
                        </span>{" "}
                        <span>
                          <strong className="text-success">
                            {item.time}{" "}
                            {languageTranslationFunctionForTheTime(
                              item.time,
                              item?.time_type
                            )}
                          </strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={6}>
          <div className="order-body10 mb-2">
            <div className="order-dtsll">
              <div>
                <div className="order-dt47 pt-3 m-0">
                  <div className="product-details">
                    <div className="d-flex flex-column">
                      <h4 className="">{labels.descriptionNotes}</h4>
                      <div className="row d-flex">
                        <Button
                          className="mt-20"
                          onClick={() => {
                            setNotesModal(true);
                          }}
                        >
                          {labels?.descriptionNotes}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        {["completed"]?.includes(status) ? (
          <>
            <Col sm={12}>
              <div className="order-body10 mb-2">
                <div className="order-dtsll">
                  <div>
                    <div className="order-dt47 pt-3 m-0">
                      <div className="product-details">
                        <div className="d-flex w-100 justify-content-between">
                          <div className="d-flex flex-column">
                            <h4 className="">{labels.downloadBills}</h4>
                            {item?.budget_bill === null &&
                              item?.supplementary_bill === null && (
                                <>
                                  <span>N/A</span>
                                </>
                              )}
                            <div className=" d-flex flex-column gap-2">
                              {item?.budget_bill !== null ? (
                                <>
                                  <Button
                                    onClick={() =>
                                      functionToDownloadFile(
                                        item?.budget_bill,
                                        `${
                                          labels.downloadServiceBill
                                        }.${get_url_extension(
                                          item?.budget_bill
                                        )}`
                                      )
                                    }
                                    variant="primary"
                                  >
                                    <svg
                                      stroke="#ffff"
                                      fill="#ffff"
                                      strokeWidth={0}
                                      viewBox="0 0 1024 1024"
                                      color="#ffff"
                                      className="cursor-pointer"
                                      height={20}
                                      width={20}
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ color: "rgb(70, 188, 255)" }}
                                    >
                                      <path d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z" />
                                    </svg>
                                    <span>{labels.downloadServiceBill}</span>
                                  </Button>
                                </>
                              ) : null}

                              {item?.supplementary_bill !== null ? (
                                <>
                                  <Button
                                    onClick={() =>
                                      functionToDownloadFile(
                                        item?.supplementary_bill,
                                        `${
                                          labels.downloadSupplementsBill
                                        }.${get_url_extension(
                                          item?.supplementary_bill
                                        )}`
                                      )
                                    }
                                    variant="primary"
                                  >
                                    <svg
                                      stroke="#ffff"
                                      fill="#ffff"
                                      strokeWidth={0}
                                      viewBox="0 0 1024 1024"
                                      color="#ffff"
                                      className="cursor-pointer"
                                      height={20}
                                      width={20}
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{ color: "rgb(70, 188, 255)" }}
                                    >
                                      <path d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z" />
                                    </svg>
                                    <span>
                                      {labels.downloadSupplementsBill}
                                    </span>
                                  </Button>
                                </>
                              ) : null}
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <div className="row d-flex">
                              {date_difference_checker(
                                moment(item?.budget_complete_date),
                                moment(),
                                "days"
                              ) < 14 ? (
                                <>
                                  {["completed", "pending"]?.includes(
                                    item?.status
                                  ) && (
                                    <button
                                      className="mr-1 w-10"
                                      onClick={() => {
                                        setShowDisputeModal(true);
                                      }}
                                    >
                                      {labels.claimAmount}
                                    </button>
                                  )}
                                </>
                              ) : null}
                              {/* {["completed"]?.includes(item?.status) && (
                                <button
                                  className="mr-1 w-10"
                                  onClick={() => {
                                    setShowDisputeModal(true);
                                  }}
                                >
                                  {labels.claimAmount}
                                </button>
                              )} */}

                              {/* {date_difference_checker(
                                moment(item?.budget_complete_date),
                                moment(),
                                "days"
                              ) < 14 ? (
                                <>
                                  {["completed"]?.includes(item?.status) && (
                                    <button
                                      className="mr-1 w-10"
                                      onClick={() => {
                                        // setShowDisputeModal(true);
                                      }}
                                    >
                                      {labels.claimAmount}
                                    </button>
                                  )}
                                </>
                              ) : null} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </>
        ) : null}
        {["dispute"]?.includes(status) ? (
          <>
            {/* <Col sm={12}>
              <div className="d-flex justify-content-end">
                <button
                  className="mr-1 w-10"
                  onClick={() => {
                    setShowDisputeModal(true);
                  }}
                >
                  {labels.claimAmount}
                </button>
              </div>
            </Col> */}
          </>
        ) : null}
      </Row>

      {/* <div className="row col-12">
        <div className="col-6">
          <div className="order-body10 mb-2">
            <ul className="order-dtsll">
              <li>
                <div className="order-dt47 pt-3">
                  <div className="product-details">
                    <div className="row d-flex flex-column">
                      <h4 className="">{labels.document}</h4>
                      <div className="row">
                        <span className="mr-1 px-30">
                          {JSON?.parse(files).length
                            ? JSON?.parse(files)?.map((item) => {
                                return (
                                  <span
                                    className="row alignItemsCenter cursorPointer"
                                    onClick={() =>
                                      functionToDownloadFile(
                                        item?.url,
                                        item?.name
                                      )
                                    }
                                  >
                                    <img
                                      className="documentImageSize"
                                      src={
                                        item !== null &&
                                        Images?.document?.default
                                      }
                                    />
                                    {item?.length !== null && (
                                      <h6>{item?.name}</h6>
                                    )}
                                  </span>
                                );
                              })
                            : labels.noDocumentFound}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-6">
          <div className="order-body10 mb-2">
            <ul className="order-dtsll">
              <li>
                <div className="order-dt47 pt-3">
                  <div className="product-details">
                    <div className="row">
                      <h4>{labels.additionalBudgetAmount}</h4>
                    </div>

                    <div className="row d-flex justify-content-between ">
                      <span>
                        <span>{labels.baseImponible}</span>
                      </span>{" "}
                      <span>
                        <strong className="text-success">
                          {" € "}{" "}
                          {replaceDecimalWithComma(
                            Number(toatlAmountService || 0)
                          )}
                        </strong>
                      </span>
                    </div>
                    <div className="row d-flex justify-content-between ">
                      <span>{labels.supplementaryFees}</span>
                      <strong className="text-success">
                        {" € "}
                        {replaceDecimalWithComma(
                          Number(supplementoryCharges || 0)
                        )}{" "}
                      </strong>
                    </div>

                    <div>
                      {total?.map((item) => {
                        return (
                          <div className="row d-flex justify-content-between text-secondary pl-3">
                            <small>{item?.name}</small>
                            <small>
                              {" € "}
                              {replaceDecimalWithComma(item?.price)}
                            </small>
                          </div>
                        );
                      })}
                    </div>
                    <div className="row d-flex justify-content-between ">
                      <span>
                        <strong>
                          {labels.vat} {21}%
                        </strong>
                      </span>{" "}
                      <span>
                        <strong className="text-success">
                          {" € "}
                          {replaceDecimalWithComma(Number(totalTax || 0))}
                        </strong>
                      </span>
                    </div>
                    <div className="row d-flex justify-content-between ">
                      <span>
                        <strong>{labels.Total}</strong>
                      </span>{" "}
                      <span>
                        <strong className="text-success">
                          {" € "}
                          {replaceDecimalWithComma(
                            Number(supplementoryCharges || 0) +
                              Number(ammount || 0)
                          )}{" "}
                        </strong>
                      </span>
                    </div>

                    <div className="row d-flex justify-content-between ">
                      <span>
                        <strong>{labels.estimatedTime}</strong>
                      </span>{" "}
                      <span>
                        <strong className="text-success">
                          {item.time}{" "}
                          {languageTranslationFunctionForTheTime(
                            item.time,
                            item?.time_type
                          )}
                        </strong>
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12">
          <div className="order-body10 mb-2">
            <ul className="order-dtsll">
              <li>
                <div className="order-dt47 pt-3">
                  <div className="product-details">
                    <div className="row">
                      <h4>{labels.descriptionNotes}</h4>
                    </div>

                    <div className="row d-flex">
                      <Button
                        className="mt-20"
                        onClick={() => {
                          setNotesModal(true);
                        }}
                      >
                        {labels?.descriptionNotes}
                      </Button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
      <div>
        {status == "pending" && (
          <Row>
            <Col lg={7} sm={6} xs={12}></Col>
            <Col lg={5} sm={6} xs={12}>
              <div className="mb-1 d-flex justify-content-end gap-2 flex-sm-row flex-column">
                <Button
                  variant="primary"
                  onClick={() => {
                    setIsPaymentModal(true);
                    setFormVisible(false);
                  }}
                  className="w-100 "
                >
                  {labels.payNow}
                </Button>
                <Button
                  onClick={handleShow}
                  variant=""
                  className="w-100 mx-1 text-dark border-0"
                  style={{ backgroundColor: "#E8E8E8" }}
                >
                  {labels.requestChange}
                </Button>
              </div>
            </Col>
          </Row>
          // <div className="row">
          //   <div className="col-7"></div>
          //   <div className="col-5 mb-1 d-flex justify-content-end ">
          //     <Button
          //       variant="primary"
          //       onClick={() => {
          //         setIsPaymentModal(true);
          //         setFormVisible(false);
          //       }}
          //       className="w-50 "
          //     >
          //       {labels.payNow}
          //     </Button>
          //     <Button
          //       onClick={handleShow}
          //       variant=""
          //       className="w-75 mx-1 text-dark border-0"
          //       style={{ backgroundColor: "#E8E8E8" }}
          //     >
          //       {labels.requestChange}
          //     </Button>
          //   </div>
          // </div>
        )}
        {status == "declined" && (
          <div className="row">
            <div className="col-8"></div>
            <div className="col-4 mb-1 ">
              <Button variant="danger disabled" className="w-100">
                {labels.requestedChange}
              </Button>
            </div>
          </div>
        )}
        {status == "accepted" && (
          <div className="w-full d-flex justify-content-end">
            <div className="mb-1 w-25">
              <Button variant="primary disabled" className="w-100">
                {labels.accepted}
              </Button>
            </div>
            {/* <div className="d-flex align-items-center ml-1">
              <Button
                onClick={() => downloadBillFunction(additionalBudgetId)}
                variant="primary"
              >
                <svg
                  stroke="#ffff"
                  fill="#ffff"
                  strokeWidth={0}
                  viewBox="0 0 1024 1024"
                  color="#ffff"
                  className="cursor-pointer"
                  height={20}
                  width={20}
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ color: "rgb(70, 188, 255)" }}
                >
                  <path d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z" />
                </svg>
                <span>{labels.downloadDocudocBill}</span>
              </Button>
            </div> */}
          </div>
          // <div className="row">
          //   <div className="col-8">
          //     <div className="flex justify-end w-full items-center space-x-2">
          //       <div>icon</div>
          //       <div>{"download Bill"}</div>
          //     </div>
          //   </div>
          //   <div className="col-4 mb-1 ">
          //     <Button variant="primary disabled" className="w-100">
          //       {labels.accepted}
          //     </Button>
          //   </div>
          // </div>
        )}
      </div>
      <Modal
        className="modelBackground"
        show={disputeModal}
        onHide={handleCloseDispute}
        size="lg"
      >
        <ModalBody className="bg-white border-radius-10 alignItemsCenter textAlignCenter m-auto height_71 my-20">
          <button
            type="button"
            className="close absolute"
            data-dismiss="modal"
            onClick={() => handleCloseDispute()}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <div className="p-3">
            <h5 className="textAlignCenter">{labels.reason}</h5>
            <p className="text-black">
              {
                labels.areYouSureYouWantToClaimPartOrAllOfTheAmountPaidForThisService
              }
            </p>
            <div>
              <textarea
                rows="4"
                cols="50"
                name="reason"
                value={disputeReason}
                placeholder={labels.disputeReason}
                onChange={(e) => setDisputeReason(e?.target?.value)}
              ></textarea>
            </div>

            <div className="d-flex justify-content-around align-content-center">
              <div className="w-50 pr-1">
                <button
                  onClick={() => handleCloseDispute()}
                  className="w-100"
                  style={{ backgroundColor: "red", borderColor: "red" }}
                >
                  {labels.cancel}
                </button>{" "}
              </div>
              <div className="w-50 pr-1">
                <Button
                  disabled={spinner}
                  variant="primary"
                  className="w-100"
                  onClick={() => {
                    if (disputeReason?.trim()?.length !== 0) {
                      disputeAdditionalBudget();
                    } else {
                      addToast(labels.reasonIsRequired, {
                        appearance: "info",
                        autoDismiss: 1000,
                      });
                    }
                  }}
                >
                  {spinner ? labels.loading : labels.yes}
                </Button>
                {/* <button
                          onClick={() => {
                            Cancel_Order();
                            setTimeout(() => {
                              handleCloseDispute();
                              push({
                                pathname: `/1/myorder`,
                                state: { label: "cancel" },
                              });
                            }, 4000);
                          }}
                          className="w-100"
                        >
                          {spinner ? labels.loading : labels.ok}
                        </button> */}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
