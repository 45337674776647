import { GET_ORDERS, LOADING } from "../../constants";

const initialState = {
  orders: [],
  loading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...initialState,
        orders: action.payload,
        loading: action.loading,
      };

    default:
      return state;
  }
};
