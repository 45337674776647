import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Get_Faqs, Get_Orders_Method, Help_Message } from "../../config/api";

import { Accordion, Button, Card, Form, Tab, Tabs } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { OrderItem } from "../../component";
import ToastComponent from "../../component/toast";
import labels from "../../config/Labels";

export default function Help() {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [title, setTitle] = useState("");
  const [is_service_related, setIsServiceRelated] = useState(0);
  const [service_id, setServiceId] = useState("");
  const [help_Message, setHelpMessage] = useState("");
  const [pendingOrders, setPendingOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [validate, setValidate] = useState(false);
  const { push } = useHistory();
  const [tab, setTab] = useState("home");
  const { addToast } = useToasts();
  let lang = localStorage.getItem("primaryLanguage");
  React.useEffect(() => {
    window.scrollTo(0, 0);

    Get_Orders_Method(
      (value) => {
        setShowSpinner(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
        setPendingOrders(value.pending_orders);
        setCancelledOrders(value.cancelled_orders);
        setCompletedOrders(value.completed_orders);
      }
    );

    Get_Faqs(
      (value) => setMessage(value),
      (value) => setShowToast(value),
      (value) => setSuccess(value),
      (value) => {
        setFaqs(value);
      }
    );
  }, []);

  const closeToast = () => {
    setShowToast(false);
  };
  return (
    <div className="SetTabs">
      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="nav nav-tabs myOrder mb-3 d-flex justify-content-center"
      >
        <Tab eventKey="home" title={labels.relatedToService}>
          <section
            className="pb-150 ls"
            id="services"
            data-animation="fadeInUp"
          >
            <div className="wrapper-main padd60 pb-5">
              <div className="container text-center">
                {pendingOrders?.length == 0 ? (
                  <span>{labels.noRecordsFound}</span>
                ) : (
                  <div className="dashboard-right">
                    {pendingOrders?.length !== 0 &&
                      pendingOrders?.map((item, index) => {
                        return (
                          <OrderItem
                            path={"specficordermessage"}
                            key={index}
                            image={item?.service?.avatar}
                            name={
                              item?.service?.[
                                lang === "english" ? "name" : "name_sp"
                              ]
                            }
                            id={item?.order_id}
                            des={item?.service?.info}
                            price={item?.service?.original_price}
                            discount={item.service?.reduced_price}
                            promo={item?.is_promo}
                            servicePak={item?.service?.service_pack_type}
                            info={
                              item?.service?.[
                                lang === "english" ? "info" : "info_sp"
                              ]
                            }
                            status={item?.status}
                            supplementory={item?.supplementary_fees}
                            help={"123"}
                            item={item}
                            orderCard={true}
                          />
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </section>
        </Tab>
        <Tab eventKey="profile" title={labels.unRelatedToService}>
          <h1 className="text-center" style={{ color: "#46bcff" }}>
            FAQ
          </h1>
          <div
            className=" ls faq-section section-b-space"
            data-animation="fadeInUp"
          >
            <>
              <div className="row">
                <div className="col-12 col-md-12">
                  <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                    {faqs?.map((item, index) => {
                      return (
                        <Accordion
                          key={index}
                          defaultActiveKey="0"
                          className="accordion theme-accordion mb-3"
                        >
                          <Card className="card">
                            <Card.Header>
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey="1"
                              >
                                {lang === "english"
                                  ? item?.question.length > 65
                                    ? item?.question.substring(0, 65) + "..."
                                    : item?.question
                                  : item?.question_sp.length > 65
                                  ? item?.question_sp.substring(0, 65) + "..."
                                  : item?.question_sp}
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body>
                                {item?.question.length > 65 ? (
                                  <strong>
                                    {lang === "english"
                                      ? item?.question
                                      : item?.question_sp}
                                  </strong>
                                ) : (
                                  ""
                                )}

                                <p>
                                  {lang === "english"
                                    ? item?.answer
                                    : item?.answer_sp}
                                </p>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      );
                    })}

                    <Accordion
                      defaultActiveKey="1"
                      className="accordion theme-accordion mb-3 collapse show"
                    >
                      <Card className="card">
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="1"
                          >
                            {labels.stillHaveSomeQuery}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <Form.Control
                              type="text"
                              placeholder={labels.subject}
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                              }}
                              required
                            />
                            {!title.length && validate && (
                              <p className="text-danger">
                                {labels.subjectIsRequired}
                              </p>
                            )}
                            <br />
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={help_Message}
                              placeholder={labels.faqMessage}
                              onChange={(e) => {
                                setHelpMessage(e.target.value);
                              }}
                              required
                            />
                            {!help_Message.length && validate && (
                              <p className="text-danger">
                                {labels.messageIsRequired}
                              </p>
                            )}
                            <br />
                            <div className="d-flex justify-content-end">
                              {" "}
                              <span>
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    if (title !== "" && help_Message !== "") {
                                      //  setValidate(false)
                                      Help_Message(
                                        {
                                          title: title,
                                          message: help_Message,
                                          is_service_related:
                                            is_service_related,
                                          service_id: service_id,
                                        },
                                        (value) => {
                                          setSuccess(value);
                                        },
                                        (value) => {
                                          addToast(value, {
                                            placement: "top-center",
                                            autoDismiss: 1000,
                                            appearance:
                                              value ==
                                              "Thanks for contacting us we will contact you soon"
                                                ? "success"
                                                : "error",
                                          });

                                          setMessage(value);
                                        },
                                        (value) => {
                                          setHelpMessage("");
                                          setTitle("");
                                          setShowToast(value);

                                          setValidate(false);
                                        },
                                        (value) => {
                                          setShowToast(value);
                                        }
                                      );
                                    } else {
                                      setValidate(true);
                                    }
                                  }}
                                >
                                  {labels.send}
                                </Button>
                              </span>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </ScrollAnimation>
                </div>
              </div>
            </>
          </div>
        </Tab>
      </Tabs>
      {showToast && (
        <ToastComponent
          show={setShowToast(showToast)}
          message={message}
          success={success}
          toastClose={setShowToast(false)}
        ></ToastComponent>
      )}
    </div>
  );
}
