import { End_Points_Of_Api } from "../../../config/endpoints";
import Api from "../../../config/api/apiConfig";
import { GET_ORDERS } from "../../constants";

export const GetAllOrders = (error) => {
  return async (dispatch) => {
    await Api._get(
      `${End_Points_Of_Api.Get_Orders}`,
      (success) => {
        const orderResponse = success?.data?.response?.detail;
        const orders = orderResponse;
        dispatch({
          type: GET_ORDERS,
          payload: orders,
          loading: false,
        });
      },
      (errorMessage) => {
        error(errorMessage);
      }
    );
  };
};
