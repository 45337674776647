import { End_Points_Of_Api } from "../../../config/endpoints";
import Api from "../../../config/api/apiConfig";
import { GET_ADDITIONAL_BUDGETS_ORDERS } from "../../constants";

export const GetAllAdditionalBudgetOrders = (error) => {
  return async (dispatch) => {
    await Api._get(
      `${End_Points_Of_Api.Get_Additional_Budgets_Orders}`,
      (success) => {
        const additionalBudgetsOrderResponse = success?.data;
        const orders = additionalBudgetsOrderResponse;
        dispatch({
          type: GET_ADDITIONAL_BUDGETS_ORDERS,
          payload: orders,
          loading: false,
        });
      },
      (errorMessage) => {
        error(errorMessage);
      }
    );
  };
};
