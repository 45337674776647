// import { Modal } from 'bootstrap';
import {
  child,
  push as fbPush,
  onValue,
  ref,
  serverTimestamp,
  update,
} from "firebase/database";

import React, { useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, Row, Tab, Tabs } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import AdditionalOrderDetails from "../../component/additionalOrderDetail";
import InformationContainer from "../../component/informationContainer";
import ProviderInfoModal from "../../component/providerInfoModal";
import ToastComponent from "../../component/toast";
import {
  Add_Review,
  Additional_Budget_By_Order_Id,
  Edit_Review,
  Get_Order_By_Id,
  Order_Cancel,
  Order_Dispute,
  Read_Additional_Budget,
} from "../../config/api";

import moment from "moment";
import { database } from "../../config/Firebase";
import labels from "../../config/Labels";
import {
  date_difference_checker,
  functionToConvertStringToCamelCase,
  functionToDownloadFile,
  get_url_extension,
  ratingStars,
  replaceDecimalWithComma,
} from "../../config/Utility";
import { Images } from "../../constant/images";
import { GetAllOrders } from "../../store/actions/orders";
import { Helmet } from "react-helmet";
export default function OrderDetail(props) {
  let firstName = localStorage.getItem("firstName");
  let lastName = localStorage.getItem("lastName");

  const { goBack, push } = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [writeMessageModal, setWriteMessageModal] = React.useState(false);
  const [success, setSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState("");
  const [order, setOrder] = useState("");
  const [orderStatus, setOrderStatus] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [stepindex, setStepIndex] = useState(0);
  const [budget, additionalBudget] = useState([]);
  const [tab, setTab] = useState(
    props?.location?.state?.taber == " further" ? "menu" : "home"
  );
  const [orderObject, setOrderObject] = useState([]);
  const [rating, setRating] = useState(5);
  const [review, setReview] = useState("");
  const [improvement, setImprovement] = useState("");
  const [showImprovementTextBox, setShowImprovementTextBox] = useState(false);
  const [isEditReview, setIsEditReview] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [showCancelOrder, setShowCancelOrder] = useState(false);
  const [serviceClicked, setServiceClicked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disputeModal, setShowDisputeModal] = useState(false);
  const [disputeRequest, setShowDisputeRequest] = useState("");
  const [fieldValidate, setFieldValidate] = useState(false);
  const [state, setState] = useState({ reason: "" });
  const [disputeReason, setDisputeReason] = useState("");
  const [count, setCount] = useState();
  const [isOrderLoading, setIsOrderLoading] = useState(true);
  let lang = localStorage.getItem("primaryLanguage");

  useEffect(() => {
    if (props?.location?.state?.taber === "menu") {
      setTab("menu");
    }
  }, [props?.location?.state?.taber]);
  const getMessagesCountListerner = () => {
    const getCount = ref(
      database,
      "orders/" + props.match.params.id + "/unreadCount"
    );
    onValue(getCount, (snapshot) => {
      if (snapshot.val() !== null) {
        setCount(snapshot?.val());
      }
    });
  };
  useEffect(() => {
    getMessagesCountListerner();
  }, []);

  const ReadOrderAdditionalBudget = (id) => {
    const obj = {
      order_id: id,
    };
    Read_Additional_Budget(obj, null, (success) => {
      if (success) {
        dispatch(
          GetAllOrders((failure) => {
            setMessage(failure);
            setSuccess(false);
            setShowToast(true);
          })
        );
      }
    });
  };
  useEffect(() => {
    if (tab === "menu") {
      ReadOrderAdditionalBudget(props.match.params.id);
    }
  }, [tab]);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState({ ...state, [name]: value });
    // setState((prev) => ({ ...prev, prev?.name: value }))
  };

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  const updateState = (data) => {
    additionalBudget(data);
  };
  const getAdditionalBudget = (id) => {
    Additional_Budget_By_Order_Id(
      id,
      (value) => {
        additionalBudget(value);
      },
      (value) => {
        setMessage(value);
        addToast(value, { appearance: "info", autoDismiss: 1000 });
      },
      (value) => {
        setSuccess(value);
      }
    );
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getOrderStatusById(props.match.params.id);
    getAdditionalBudget(props.match.params.id);
  }, []);

  React.useEffect(() => {
    getOrderStatusById(props.match.params.id);

    const interval = setInterval(() => {
      getOrderStatusById(props.match.params.id);
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  const getOrderStatusById = (id) => {
    Get_Order_By_Id(
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
        setSuccess(value);
      },
      id,
      (value) => {
        setOrderObject(value);

        let filtered = value?.service?.service_statuses.filter(
          (item) => item?.description !== ""
        );
        let mappedOut = filtered?.map((item, index) => {
          const slicedArray = filtered
            ?.slice(index)
            ?.some((item) => item?.order_status !== null)
            ? {
                id: item.id,
                info: "completed",
                order_id: props.match.params.id,
                service_status_id: item.id,
              }
            : null;

          return {
            order: item?.status,
            count: index + 1,
            order_status:
              item?.order_status !== null ? item?.order_status : slicedArray,
          };
        });
        let filteredByOrderStatus = mappedOut?.filter(
          (item) => item?.order_status !== null
        );
        setStatusMessage(
          mappedOut?.[filteredByOrderStatus?.length - 1]?.order_status?.info
        );
        setStepIndex(filteredByOrderStatus?.length - 1);
        setOrderStatus(mappedOut);
        setOrder(value);
        setIsOrderLoading(false);
      }
    );
  };

  const Add_Reviews = () => {
    const obj = {
      order_id: props.match.params.id,
      rate: rating,
      review: review,
      improved: showImprovementTextBox ? improvement : "",
    };
    if (
      rating !== null &&
      review.trim()?.length !== 0 &&
      (showImprovementTextBox ? improvement.trim()?.length !== 0 : true)
    ) {
      Add_Review(
        obj,
        (value) => {
          setSuccess(value);
          getOrderStatusById(props.match.params.id);
          if (value == true) {
            setWriteMessageModal(false);
            // addToast(value, { appearance: "info", autoDismiss: 1000 });
          }
        },
        (value) => {
          setMessage(value);
          addToast(value, { appearance: "info", autoDismiss: 1000 });
        }
      );
    } else {
      addToast(labels.allFieldsAreRequired);
    }
    // Add_Review(
    //   obj,
    //   (value) => {
    //     setSuccess(value);
    //     if (value == true) {
    //       setWriteMessageModal(false);
    //       // addToast(value, { appearance: "info", autoDismiss: 1000 });
    //     }
    //   },
    //   (value) => {
    //     setMessage(value);
    //     addToast(value, { appearance: "info", autoDismiss: 1000 });
    //   }
    // );
  };

  const Edit_Reviews = () => {
    const obj = {
      order_id: props.match.params.id,
      rate: rating,
      review: review,
      improved: showImprovementTextBox ? improvement : "",
    };
    if (
      rating !== null &&
      review.trim()?.length !== 0 &&
      (showImprovementTextBox ? improvement.trim()?.length !== 0 : true)
    ) {
      Edit_Review(
        obj,
        (value) => {
          setSuccess(value);
          if (value == true) {
            getOrderStatusById(props.match.params.id);
            setWriteMessageModal(false);
            // addToast(value, { appearance: "info", autoDismiss: 1000 });
          }
        },
        (value) => {
          setMessage(value);
          addToast(value, { appearance: "info", autoDismiss: 1000 });
        }
      );
    } else {
      addToast(labels.allFieldsAreRequired);
    }
  };

  const Cancel_Order = () => {
    let obj = {
      order_id: props?.match?.params?.id,
    };
    Order_Cancel(
      obj,

      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
        addToast(value, { appearance: "info", autoDismiss: 1000 });
      },
      (value) => {
        setSpinner(value);
      }
    );
  };
  //orderDispute
  const DisputeOrder = () => {
    setSpinner(true);
    let obj = {
      order_id: props.match.params.id,
    };

    Order_Dispute(
      obj,

      (value) => {
        setSuccess(value);

        if (value) {
          setTimeout(() => {
            localStorage.setItem("orderCurrentActiveTab", "menu3");
            push({
              pathname: `/1/myorder`,
              state: {
                label: "dispute",
                amount: state?.amount,
                reason: state?.reason,
              },
            });
          }, 4000);
        }
      },
      (value) => {
        setMessage(value);
        addToast(value, { appearance: "info", autoDismiss: 1000 });
      },
      (value) => {
        setSpinner(value);
      }
    );

    const postData = {
      is_provider_message: "0",
      service_provider_id: orderObject.service_provider_id,
      service_provider: orderObject.service_provider,
      admin_id: null,
      admin: null,
      is_admin_message: "0",
      is_dispute: "1",
      user_id: orderObject.user_id,
      user: { first_name: firstName, last_name: lastName },
      message: [
        {
          message: disputeReason,
          documents: null,
        },
      ],
      time: serverTimestamp(),
    };
    const newPostKey = fbPush(child(ref(database), "posts")).key;
    // // Write the new post's data simultaneously in the posts list and the user's post list.
    const updates = {};

    updates["/orders/" + props.match.params.id + "/messages/" + newPostKey] =
      postData;

    return update(ref(database), updates);
  };

  const closeToast = () => {
    setShowToast(false);
  };

  const fetchStatusOfOrder = (status, index) => {
    setStatusMessage(status);
    setStepIndex(index);
  };
  const [show, setShow] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [show]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseDispute = () => {
    setShowDisputeModal(false);
    state.amount = "";
    state.reason = "";
  };
  const handleShowDispute = () => setShowDisputeModal(true);
  const downloadOrderInvoice = (order) => {
    functionToDownloadFile(
      `https://api.docudocapp.com/api/download_bill/${order?.order_id}`,
      `${labels.order} ${order?.order_id}.pdf`
    );
  };

  const handleClickEditReview = () => {
    setIsEditReview(true);
    setWriteMessageModal(true);
    setRating(order?.order_review?.rate);
    setImprovement(order?.order_review?.improved);
    setReview(order?.order_review?.review);

    order?.order_review?.improved?.trim()?.length > 0 &&
      setShowImprovementTextBox(true);

    // order_id: props.match.params.id,
    // rate: rating,
    // review: review,
    // improved: showImprovementTextBox ? improvement : "",
  };
  return (
    <>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <section
        className="pt-150 pb-150 ls "
        id="services"
        data-animation="fadeInUp"
      >
        <div className="d-flex mx-20 mb-20">
          <img
            src={Images.backArrow.default}
            style={{ width: "50px", height: "50px" }}
            className="cursorPointer"
            onClick={() => {
              goBack();
            }}
          />
        </div>
        {isOrderLoading ? (
          <div className="container px-0">
            <div className="row">
              <div className="col-12">
                <div style={{ minHeight: "500px" }}>{labels.loading}</div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {" "}
            <div className="container px-0">
              <div className="row">
                <div className="col-sm-6 px-0">
                  {" "}
                  <article className="shop-summary-item element-emphasis-weak border-radius-10 h-100 position-relative mt-10">
                    <img
                      src={order?.service?.avatar}
                      alt="avatar"
                      style={{
                        borderRadius: 10,
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                    <header className="item-info-name-features-price mb-13">
                      <h3 className="fontSize16px mb-0">
                        <strong>
                          {lang === "english"
                            ? order?.service?.name
                            : order?.service?.name_sp}
                          <br />
                          <span className="text-black-50">
                            {labels.orderDetailOrderID}
                          </span>
                          <span className="text-black-50">
                            {props?.match?.params?.id}
                          </span>

                          <br />

                          <p className="col-sm-8 d-flex py-0 m-0 px-0">
                            <span
                              className="features col-sm-4 px-0"
                              style={{ color: "green", fontSize: 18 }}
                            >
                              {Number(order?.service?.original_price || 0) +
                                Number(
                                  order?.service?.supplementary_fees || 0
                                ) !==
                              0
                                ? "€" +
                                  replaceDecimalWithComma(
                                    Number(
                                      order?.service?.original_price || 0
                                    ) +
                                      Number(
                                        order?.service?.supplementary_fees || 0
                                      )
                                  )
                                : `€${
                                    replaceDecimalWithComma(
                                      order?.service?.original_price || 0
                                    ) === "0"
                                  }`
                                ? labels.free
                                : replaceDecimalWithComma(
                                    order?.service?.original_price || 0
                                  )}{" "}
                            </span>
                            <strong>
                              <span
                                className="col-sm-4"
                                style={{ fontSize: 15, color: "green" }}
                              >
                                {order?.service?.service_pack_type !==
                                "fixed price"
                                  ? labels?.[
                                      functionToConvertStringToCamelCase(
                                        order?.service?.service_pack_type
                                      )
                                    ]?.toUpperCase()
                                  : null}
                              </span>
                            </strong>
                          </p>
                          {/* <span style={{ color: "green" }}>
                      {" € "}

                      {replaceDecimalWithComma(
                        Number(order?.service?.original_price || 0 || 0)
                      )}
                      <span className="mx-50">
                        {labels?.[functionToConvertStringToCamelCase(order?.service?.service_pack_type)]?.toUpperCase()}{" "}
                      </span>
                    </span> */}
                        </strong>
                      </h3>
                      {(((Number(order?.service?.original_price || 0) - Number(order?.service?.reduced_price)) / Number(order?.service?.original_price || 0)) * 100)?.toFixed(0) > 0  ? (
                        <>
                          <p className="col-sm-8 d-flex py-0 m-0 px-0">
                            <span
                              className="features col-sm-4 px-0 text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              {"€"}
                              {replaceDecimalWithComma(
                                Number(order?.service?.reduced_price || 0) +
                                  Number(
                                    order?.service?.supplementary_fees || 0 || 0
                                  )
                              )}{" "}
                            </span>

                            <span
                              className="col text-danger"
                              style={{ fontSize: "18px" }}
                            >
                              {(
                                ((Number(order?.service?.original_price || 0) -
                                  Number(order?.service?.reduced_price)) /
                                  Number(order?.service?.original_price || 0)) *
                                100
                              ).toFixed(0) === "NaN"
                                ? "0"
                                : (
                                    ((Number(
                                      order?.service?.original_price || 0
                                    ) -
                                      Number(order?.service?.reduced_price)) /
                                      Number(
                                        order?.service?.original_price || 0
                                      )) *
                                    100
                                  ).toFixed(0)}
                              % {labels.discount}
                            </span>
                          </p>
                        </>
                      ) : null}
                    </header>
                    <img
                      onClick={() => {
                        setShow(true);
                        setServiceClicked(true);
                      }}
                      src={Images.infoo.default}
                      className="position-absolute"
                      style={{ width: 20, height: 20, top: 55, right: 0 }}
                    />
                  </article>
                </div>
                <div className="col-sm-6">
                  <article className="shop-summary-item element-emphasis-weak border-radius-10 position-relative h-100 mt-10">
                    <img
                      src={order?.service_provider?.avatar}
                      alt="Shop item in cart"
                      style={{
                        borderRadius: 10,
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                    <header className="item-info-name-features-price mb-13">
                      <h3 className="fontSize16px mb-0 ">
                        <strong>
                          {order?.service_provider?.name}

                          <br />

                          <span className=" " style={{ color: "green" }}>
                            {labels.experience}{" "}
                            {order?.service_provider?.experience} {labels.years}
                          </span>
                          <br />
                          <span>
                            {labels.position}:{" "}
                            {/* {(order?.service_provider?.position === null || order?.service_provider?.position === "null" ? "N/A" : order?.service_provider?.position).substring(0,35)} */}
                            {(order?.service_provider?.[lang === "english" ? "position" : "position_sp"] || "N/A")?.substring(0,30)}
                            {(order?.service_provider?.[lang === "english" ? "position" : "position_sp"] || "N/A")?.length > 30 ? "... " : ""}
                          </span>
                        </strong>
                      </h3>

                      <span
                        className="d-flex fw-600"
                        style={{ color: "black", position: "top" }}
                      >
                        {labels.rating + ": "}
                        <strong className="mr-0">
                          {order?.service_provider?.fake_rating != null
                            ? Number(
                                order?.service_provider?.fake_rating
                              ).toFixed(1)
                            : Number(
                                !order?.service_provider?.average_rating?.length
                                  ? "0"
                                  : order?.service_provider?.average_rating
                              ).toFixed(1)}
                        </strong>
                        <span>
                          {" "}
                          <img
                            className=""
                            style={{ width: 20, height: 20 }}
                            src={Images.shareWin.default}
                            alt="info"
                          />
                        </span>
                      </span>
                    </header>
                    <img
                      onClick={() => {
                        setShowModal(true);
                      }}
                      src={Images.infoo.default}
                      className="position-absolute"
                      style={{ width: 20, height: 20, top: 55, right: 0 }}
                    />
                  </article>
                  <Modal show={show} onHide={handleClose} animation={true}>
                    <Modal.Header
                      className="d-flex justify-content-center modal-header border-0"
                      closeButton
                    >
                      <div>
                        <Modal.Title>{labels.information}</Modal.Title>
                      </div>
                    </Modal.Header>
                    <Modal.Body
                      // scrollable={true}
                      style={{
                        width: "500px",
                        height: "300px",
                        overflowY: "auto",
                      }}
                    >
                      <br />
                      <InformationContainer
                        info={
                          order?.service?.[
                            lang === "english" ? "info" : "info_sp"
                          ] == null
                            ? ""
                            : order?.service?.[
                                lang === "english" ? "info" : "info_sp"
                              ]
                        }
                      />
                    </Modal.Body>
                    <Modal.Footer className=" border-0"></Modal.Footer>
                  </Modal>
                  <>
                    {/* <Modal show={disputeModal} onHide={handleCloseDispute}>
        <Modal.Header
          closeButton
          onClick={() => {
            setFieldValidate(false);
          }}
        >
          <Modal.Title>{labels.confirmation}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <InputGroup className="mb-3">

            <Form.Control
              name="amount"
              value={state?.amount}
              placeholder={labels.disputeAmount}
             
              type="number"
              onChange={handleChange}

            />
          </InputGroup>
          <InputGroup className="">
            <textarea 
             rows="4" 
             cols="50"
              name="reason"
              value={state?.reason}
              placeholder={labels.disputeReason}
              onChange={handleChange}>
              </textarea>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
         
          <Button
            variant="primary"
            onClick={() => {
              if (state?.amount !== "" && state?.reason !== "") {
                DisputeOrder();
              } else {
                addToast("Both Fields are required.", { appearance: "info", autoDismiss: 1000 });
              }

            }}
          >
            {spinner ? labels.loading : labels.send}
          </Button>
        </Modal.Footer>
      </Modal> */}
                    <Modal
                      className="modelBackground"
                      show={showCancelOrder}
                      onHide={() => setShowCancelOrder(false)}
                      size="lg"
                    >
                      <ModalBody className="bg-white border-radius-10 alignItemsCenter textAlignCenter m-auto height_71 my-20">
                        <button
                          type="button"
                          className="close absolute"
                          data-dismiss="modal"
                          onClick={() => setShowCancelOrder(false)}
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>

                        <div className="p-3">
                          <h5 className="textAlignCenter">
                            {labels.confirmation}
                          </h5>
                          <p className="mx-30">{labels.cancelOrderMessage}</p>
                          <div className="d-flex justify-content-around align-content-center">
                            <div className="w-50 pr-1">
                              <button
                                onClick={() => setShowCancelOrder(false)}
                                className="w-100"
                                style={{
                                  backgroundColor: "red",
                                  borderColor: "red",
                                }}
                              >
                                {labels.cancel}
                              </button>{" "}
                            </div>
                            <div className="w-50 pr-1">
                              <button
                                onClick={() => {
                                  Cancel_Order();
                                  setTimeout(() => {
                                    setShowCancelOrder(false);
                                    localStorage.setItem(
                                      "orderCurrentActiveTab",
                                      "menu2"
                                    );
                                    push({
                                      pathname: `/1/myorder`,
                                      state: { label: "cancel" },
                                    });
                                  }, 4000);
                                }}
                                className="w-100"
                              >
                                {spinner ? labels.loading : labels.ok}
                              </button>
                            </div>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>
                    <Modal
                      className="modelBackground"
                      show={disputeModal}
                      onHide={handleCloseDispute}
                      size="lg"
                    >
                      <ModalBody className="bg-white border-radius-10 alignItemsCenter textAlignCenter m-auto height_71 my-20">
                        <button
                          type="button"
                          className="close absolute"
                          data-dismiss="modal"
                          onClick={() => handleCloseDispute()}
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>

                        <div className="p-3">
                          <h5 className="textAlignCenter">{labels.reason}</h5>
                          <p className="text-black">
                            {
                              labels.areYouSureYouWantToClaimPartOrAllOfTheAmountPaidForThisService
                            }
                          </p>
                          <div>
                            <textarea
                              rows="4"
                              cols="50"
                              name="reason"
                              value={disputeReason}
                              placeholder={labels.disputeReason}
                              onChange={(e) =>
                                setDisputeReason(e?.target?.value)
                              }
                            ></textarea>
                          </div>

                          <div className="d-flex justify-content-around align-content-center">
                            <div className="w-50 pr-1">
                              <button
                                onClick={() => handleCloseDispute()}
                                className="w-100"
                                style={{
                                  backgroundColor: "red",
                                  borderColor: "red",
                                }}
                              >
                                {labels.cancel}
                              </button>{" "}
                            </div>
                            <div className="w-50 pr-1">
                              <Button
                                disabled={spinner}
                                variant="primary"
                                className="w-100"
                                onClick={() => {
                                  if (disputeReason?.trim()?.length !== 0) {
                                    DisputeOrder();
                                  } else {
                                    addToast(labels.reasonIsRequired, {
                                      appearance: "info",
                                      autoDismiss: 1000,
                                    });
                                  }
                                }}
                              >
                                {spinner ? labels.loading : labels.yes}
                              </Button>
                              {/* <button
                          onClick={() => {
                            Cancel_Order();
                            setTimeout(() => {
                              handleCloseDispute();
                              push({
                                pathname: `/1/myorder`,
                                state: { label: "cancel" },
                              });
                            }, 4000);
                          }}
                          className="w-100"
                        >
                          {spinner ? labels.loading : labels.ok}
                        </button> */}
                            </div>
                          </div>
                        </div>
                      </ModalBody>
                    </Modal>
                  </>
                </div>

                <ProviderInfoModal
                  info={order?.service_provider}
                  show={showModal}
                  onHide={() => setShowModal(false)}
                />
              </div>

              <div className="row">
                <div className="col-md-12 px-0">
                  <div className="product-details mt-4 pt-3">
                    <div
                      style={{ color: "red", marginTop: 10 }}
                      className={
                        orderObject?.status !== "completed"
                          ? "align-items-center d-flex flex-column justfy-content-center mr-5 order-detail-button pl-1"
                          : "d-flex justify-content-center align-items-center order-detail-button pl-1 mt-1"
                      }
                    >
                      <button
                        onClick={() =>
                          push({
                            pathname: `/specficordermessage/${props.match.params.id}`,
                            state: {
                              dispute: false,
                              allInfo: props?.location?.state?.allInfo,
                            },
                          })
                        }
                        className="btn btn-primary mb-5"
                      >
                        <p className="fw-600">{labels.writeMessage}</p>
                      </button>
                    </div>

                    <Tabs
                      defaultActiveKey="home"
                      activeKey={tab}
                      onSelect={(e) => setTab(e)}
                      id="uncontrolled-tab-example"
                      className="nav nav-tabs customtab mb-10 d-flex justify-content-center  px-0"
                    >
                      <Tab
                        className="px-0"
                        eventKey="home"
                        title={labels.serviceStatus}
                      >
                        <div className="track-order pt-0">
                          {/* {orderObject?.status !== "completed" &&
                      orderObject?.status !== "cancelled" && (
                        <div className="d-flex justify-content-end mr-1">
                          <button
                            className="mr-1 w-10"
                            onClick={() => {
                              setShowDisputeModal(true);
                            }}
                          >
                            {labels.dispute}
                          </button>
                          <button
                            className="w-10"
                            onClick={() => {
                              setShowCancelOrder(true);
                              setFieldValidate(true);
                            }}
                          >
                            {labels.cancelOrder}
                          </button>
                        </div>
                      )} */}

                          <div className="bs-wizard">
                            {orderStatus?.map((status, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={
                                    status?.order_status !== null
                                      ? () =>
                                          fetchStatusOfOrder(
                                            status?.order_status?.info,
                                            index
                                          )
                                      : () => {}
                                  }
                                  className={
                                    status?.order_status !== null
                                      ? "bs-wizard-step complete "
                                      : "bs-wizard-step"
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="progress">
                                    <div
                                      className="progress-bar "
                                      style={
                                        status?.order_status === null
                                          ? { width: "0px !important" }
                                          : { width: "100px !important" }
                                      }
                                    ></div>
                                  </div>

                                  <div
                                    className={
                                      status?.order_status !== null
                                        ? "bs-wizard-dot"
                                        : "bs-wizard-dot1"
                                    }
                                  >
                                    <span>{index + 1}</span>
                                  </div>

                                  <div className="text-center bs-wizard-stepnum mt-50 d-flex flex-wrap justify-content-center">
                                    {
                                      labels[
                                        functionToConvertStringToCamelCase(
                                          status?.order
                                        )
                                      ]
                                    }
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="d-flex align-items-center flex-column">
                            <p>
                              {stepindex === 0
                                ? labels[
                                    functionToConvertStringToCamelCase(
                                      statusMessage
                                    )
                                  ]
                                : statusMessage}
                            </p>
                          </div>

                          {stepindex === 4 && (
                            <div className="">
                              <h4 className="ml-0 mt-5">
                                {labels.downloadBills}
                              </h4>
                              <div className="gap-2 d-flex">
                                {orderObject?.order_bill === null &&
                                  orderObject?.supplementary_bill === null && (
                                    <>
                                      <span>N/A</span>
                                    </>
                                  )}
                                {orderObject?.order_bill !== null ? (
                                  <>
                                    <Button
                                      onClick={() =>
                                        functionToDownloadFile(
                                          orderObject?.order_bill,
                                          `${
                                            labels.downloadServiceBill
                                          }.${get_url_extension(
                                            orderObject?.order_bill
                                          )}`
                                        )
                                      }
                                      variant="primary"
                                    >
                                      <svg
                                        stroke="#ffff"
                                        fill="#ffff"
                                        strokeWidth={0}
                                        viewBox="0 0 1024 1024"
                                        color="#ffff"
                                        className="cursor-pointer"
                                        height={20}
                                        width={20}
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{ color: "rgb(70, 188, 255)" }}
                                      >
                                        <path d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z" />
                                      </svg>
                                      <span>{labels.downloadServiceBill}</span>
                                    </Button>
                                  </>
                                ) : null}

                                {orderObject?.supplementary_bill !== null ? (
                                  <>
                                    <Button
                                      onClick={() =>
                                        functionToDownloadFile(
                                          orderObject?.supplementary_bill,
                                          `${
                                            labels.downloadSupplementsBill
                                          }.${get_url_extension(
                                            orderObject?.supplementary_bill
                                          )}`
                                        )
                                      }
                                      variant="primary"
                                    >
                                      <svg
                                        stroke="#ffff"
                                        fill="#ffff"
                                        strokeWidth={0}
                                        viewBox="0 0 1024 1024"
                                        color="#ffff"
                                        className="cursor-pointer"
                                        height={20}
                                        width={20}
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{ color: "rgb(70, 188, 255)" }}
                                      >
                                        <path d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z" />
                                      </svg>
                                      <span>{labels.downloadSupplementsBill}</span>
                                    </Button>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          )}
                          {stepindex === 4 && (
                            <>
                              <div className="">
                                <h4 className="ml-0 mt-5">
                                  {labels.costBreak}
                                </h4>
                                <p className="row">
                                  <span className="col-4 ">
                                    <strong>{labels.serviceCharges} :</strong>
                                  </span>
                                  <span>
                                    {replaceDecimalWithComma(
                                      Number(order?.service?.reduced_price)
                                    )}
                                    {" € "}
                                  </span>
                                </p>
                                <p className="row">
                                  <span className="col-4">
                                    <strong>
                                      {labels.supplementaryFees} :
                                    </strong>{" "}
                                  </span>
                                  <span>
                                    {" "}
                                    {replaceDecimalWithComma(
                                      Number(order?.supplementary_fees)
                                    )}{" "}
                                    {" € "}
                                  </span>
                                </p>
                                <p className="row">
                                  <span className="col-4">
                                    <strong>Total :</strong>{" "}
                                  </span>
                                  <span>
                                    {replaceDecimalWithComma(
                                      Number(order?.service?.reduced_price) +
                                        Number(
                                          order?.service?.supplementary_fees ||
                                            0
                                        )
                                    )}
                                    {" € "}
                                  </span>
                                </p>
                              </div>
                              {/* <div className="d-flex justify-content-end w-full">
                          <div className="d-flex flex-column align-items-center">
                            <Button
                              onClick={() => downloadOrderInvoice(orderObject)}
                              variant="primary"
                            >
                              <svg
                                stroke="#ffff"
                                fill="#ffff"
                                strokeWidth={0}
                                viewBox="0 0 1024 1024"
                                color="#ffff"
                                className="cursor-pointer"
                                height={20}
                                width={20}
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ color: "rgb(70, 188, 255)" }}
                              >
                                <path d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z" />
                              </svg>
                              <span>{labels.downloadDocudocBill}</span>
                            </Button>
                          </div>
                        </div> */}
                            </>
                          )}
                        </div>

                        {order?.order_review == null && (
                          <div className="Justify-content-end d-flex">
                            {" "}
                            {stepindex === 4 && (
                              <Button
                                onClick={() => {
                                  setWriteMessageModal(true);
                                }}
                              >
                                {labels.rateReview}
                              </Button>
                            )}
                          </div>
                        )}
                        {order?.order_review && (
                          <div>
                            <div className="d-flex gap-1">
                              <h5>{labels.rateReview}</h5>{" "}
                              <Button
                                className="btn btn-primary"
                                style={{
                                  height: "30px",
                                  lineHeight: 0,
                                }}
                                onClick={() => handleClickEditReview()}
                              >
                                <small>{labels.edit}</small>
                              </Button>
                            </div>
                            <div>
                              <div>
                                <b>{labels.provider}</b>
                              </div>
                              <div className="d-flex gap-1 align-items-center">
                                <div>
                                  <img
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      objectFit: "cover",
                                      borderRadius: "100%",
                                    }}
                                    src={orderObject?.service_provider?.avatar}
                                  />
                                </div>
                                <div>{orderObject?.service_provider?.name}</div>
                              </div>
                              <div className="d-flex gap-1 align-items-center">
                                <div>
                                  <b>{labels.rating}</b>
                                </div>
                                <div>
                                  <span style={{ color: "#FED42D" }}>
                                    {ratingStars(
                                      String(order?.order_review?.rate)
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="gap-1">
                                <div>
                                  <b>Reviews</b>
                                </div>
                                <div>
                                  <p>{order?.order_review?.review}</p>
                                </div>
                              </div>
                              <div className="gap-1">
                                <div>
                                  <b>What can be improved ?</b>
                                </div>
                                <div>
                                  <p>{order?.order_review?.improved}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Tab>

                      <Tab
                        className="px-0"
                        eventKey="menu"
                        title={labels.orderDetailAdditionalBudget}
                      >
                        {budget?.map((item, index) => {
                          //  alert(JSON.stringify(budget))
                          return (
                            <AdditionalOrderDetails
                              item={item}
                              key={index}
                              updateStateFunction={updateState}
                              notes={item?.notes}
                              ammount={item?.amount}
                              supplementoryCharges={item?.supplementary_fees}
                              total={item?.supplementaries_fees}
                              additionalBudgetId={item?.additional_budget_id}
                              status={item?.status}
                              files={item?.files}
                              orderObject={orderObject}
                            ></AdditionalOrderDetails>
                          );
                        })}
                        {!budget?.length && (
                          <div className="text-center">
                            <span>{labels.noAdditionalBudget}</span>
                          </div>
                        )}
                      </Tab>
                    </Tabs>
                  </div>
                </div>

                <div className="col-md-12 mt-4 pt-2">
                  {/* {stepindex === 4 && (
              <div className="d-flex justify-content-end align-items-center order-detail-button">
                <button
                  onClick={() => {
                    localStorage.setItem("orderCurrentActiveTab", "menu2");
                    push({ pathname: `/1/myorder`, state: { label: "menu1" } });
                  }}
                  className="btn btn-primary mb-5"
                >
                  {orderObject?.status !== "completed" && <p>{labels.ok}</p>}
                  <p>{labels.back}</p>
                </button>
              </div>
            )} */}
                  {/* <div
              className={
                orderObject?.status !== "completed"
                  ? "d-flex justify-content-end align-items-center order-detail-button pl-1 mr-5"
                  : "d-flex justify-content-end align-items-center order-detail-button pl-1 mt-1"
              }
            >
              <button
                onClick={() =>
                  push({
                    pathname: `/specficordermessage/${props.match.params.id}`,
                    state: { dispute: false },
                  })
                }
                className="btn btn-primary mb-5"
              >
                <p>{labels.writeMessage}</p>
              </button>
            </div> */}
                </div>
              </div>
              {tab === "home" ? (
                <>
                  {" "}
                  <Row>
                    <Col>
                      <div className="track-order pt-0">
                        <div>
                          <div className="d-flex justify-content-end gap-1">
                            {![
                              "completed",
                              "cancelled",
                              "dispute",
                              "disputed",
                            ]?.includes(orderObject?.status) && (
                              <button
                                className="w-10"
                                onClick={() => {
                                  setShowCancelOrder(true);
                                  setFieldValidate(true);
                                }}
                              >
                                {labels.cancelOrder}
                              </button>
                            )}
                            {date_difference_checker(
                              moment(orderObject?.order_complete_date),
                              moment(),
                              "days"
                            ) < 14 ? (
                              <>
                                {["completed"]?.includes(
                                  orderObject?.status
                                ) && (
                                  <button
                                    className="mr-1 w-10"
                                    onClick={() => {
                                      setShowDisputeModal(true);
                                    }}
                                  >
                                    {labels.claimAmount}
                                  </button>
                                )}
                              </>
                            ) : null}
                            {["pending"]?.includes(orderObject?.status) && (
                              <button
                                className="mr-1 w-10"
                                onClick={() => {
                                  setShowDisputeModal(true);
                                }}
                              >
                                {labels.claimAmount}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : null}
            </div>
            <Modal
              show={writeMessageModal}
              onHide={() => setWriteMessageModal(false)}
              size="md"
            >
              <ModalBody className="bg-white p-0 border-radius-10 alignItemsCenter">
                <button
                  type="button"
                  className="close  absolute"
                  data-dismiss="modal"
                  onClick={() => setWriteMessageModal(false)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="p-30 " style={{ textAlign: "center" }}>
                  <h5 className="text-lg-center">{labels.rateReview}</h5>

                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                    src={orderObject?.service_provider?.avatar}
                  />
                  <h6 className="text-lg-center">
                    {orderObject?.service_provider?.name}
                  </h6>
                  <div className="d-flex justify-content-center">
                    <ReactStars
                      count={5}
                      onChange={ratingChanged}
                      value={rating}
                      size={30}
                      edit={true}
                      activeColor="#ffd700"
                      half={true}
                    />
                  </div>
                  <div className="form-group text-left">
                    <label className="text-lg-left">
                      {labels.writeSomething}
                    </label>
                    <textarea
                      rows={5}
                      value={review}
                      className="form-control backgroundColorGray"
                      type="text"
                      name="name"
                      onChange={(e) =>
                        setReview(e.target.value.trimStart().slice(0, 250))
                      }
                      placeholder=""
                      style={{
                        minHeight: "120px",
                        maxHeight: "140px",
                      }}
                    />
                    <div className="d-flex justify-content-end">
                      <small
                        className={"textarea-counter-value float-end"}
                      >{`${review?.length}/250`}</small>
                    </div>
                  </div>
                  <div className="d-flex gap-1 align-items-center">
                    <div>
                      <input
                        type="checkbox"
                        value={showImprovementTextBox}
                        onChange={(e) =>
                          setShowImprovementTextBox(!showImprovementTextBox)
                        }
                      />
                    </div>
                    <div>
                      <label>{labels.whatCan}</label>
                    </div>
                  </div>
                  {showImprovementTextBox && (
                    <div className="form-group text-left">
                      <textarea
                        value={improvement}
                        className="form-control backgroundColorGray"
                        type="text"
                        name="name"
                        onChange={(e) =>
                          setImprovement(
                            e.target.value.trimStart().slice(0, 200)
                          )
                        }
                        placeholder=""
                      />
                      <div className="d-flex justify-content-end">
                        <small
                          className={"textarea-counter-value float-end"}
                        >{`${improvement?.length}/200`}</small>
                      </div>
                    </div>
                  )}

                  <div className="textAlignCenter mt-20 mb-20">
                    <button
                      className="btn btn-primary onSelectionBackground"
                      data-toggle="modal"
                      onClick={() =>
                        isEditReview ? Edit_Reviews() : Add_Reviews()
                      }
                    >
                      {labels.submit}
                    </button>
                  </div>
                </div>
              </ModalBody>
            </Modal>
            {showToast === true ? (
              <ToastComponent
                message={message}
                toastClose={closeToast}
                success={success}
              />
            ) : (
              ""
            )}
          </>
        )}
      </section>
    </>
  );
}
