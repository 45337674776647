// import { Modal } from 'bootstrap';
import React from "react";
import { Helmet } from "react-helmet";

export default function PersonalProfile(params) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <section
        className="pt-150 pb-150 ls"
        id="services"
        data-animation="fadeInUp"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <h4 className="mb-20 text-center">Profile Set Up</h4>
              <div className="d-none d-lg-block divider-20"></div>
              <form>
                <div className="card">
                  <div className="card-body">
                    <h6>Billing Information</h6>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>First Name</label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input className="form-control" type="text" />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Contact Number</label>
                      <input className="form-control" type="text" />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input className="form-control" type="email" />
                    </div>
                    <div className="form-group">
                      <label>Business or Self Employed &nbsp;&nbsp;</label>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div className="form-group">
                      <label>Company Name</label>
                      <input className="form-control" type="text" />
                    </div>
                    <div className="form-group">
                      <label>Enter CIF</label>
                      <input className="form-control" type="text" />
                    </div>
                    <div className="form-group">
                      <label>Enter Address</label>
                      <input className="form-control" type="text" />
                    </div>
                    <div className="form-group text-center">
                      <button type="btn btn-primary">Save</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
