// import { Modal } from 'bootstrap';
import React, { useEffect, useState } from "react";
import { Dropdown, Modal, ModalBody } from "react-bootstrap";
import { RiArrowRightSLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import EditProfileModal from "../../component/editProfileModal";
import { useStoreItem } from "../../config/Store";

import { useToasts } from "react-toast-notifications";
import ToastComponent from "../../component/toast";
import labels from "../../config/Labels";
import { Utility, validateText } from "../../config/Utility";
import {
  Edit_Profile_Method,
  Get_Profile_Method,
  Get_Profiles_Method,
} from "../../config/api";
import Change_Password_Page from "../session/changePassword";
import DeleteAccount from "../session/deleteAccount";

export default function MyAccount({}) {
  const [check, setCheck] = useState(false);
  const {
    user,
    updateUser,
    // firstName,
    // lastName,
    phoneNumber,
    companyName,
    CIFaddress,
  } = useStoreItem();
  const [isEditModal, setIsEditModal] = useState(false);
  const [firstNameInfo, setFirstNameInfo] = useState("");
  const [lastNameInfo, setlastNameInfo] = useState("");
  const [emailInfo, setEmailInfo] = useState("");
  const [cif, setCifInfo] = useState("");
  const [phoneNumberInfo, setPhoneNumberInfo] = useState("");
  const [addressInfo, setAddressInfo] = useState("");
  const [companyInfo, setCompanyInfo] = useState("");

  const [isPasswordModal, setIsPasswordModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [fieldIsRequired, setIsFieldRequired] = useState(false);
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const [isDeleteAccountModal, setIsDeleteAccountModal] = useState(false);
  const { addToast } = useToasts();
  const [fetchData, setFetchDate] = useState([]);
  let first = localStorage.getItem("firstName");
  let last = localStorage.getItem("lastName");
  let email = localStorage.getItem("emailAddress");
  let Phone = localStorage.getItem("phone_number");
  let company = localStorage.getItem("company");
  let CIF = localStorage.getItem("Cif");
  let address = localStorage.getItem("address");
  const loginInUserAccount = JSON.parse(
    localStorage.getItem("loginInUserAccount")
  );
  const { push } = useHistory();
  const history = useHistory();
  const handleChange = (checked) => {
    setCheck(checked);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    checkToken();
    Get_Profiles();
  }, [show]);

  const closeToast = () => {
    setShowToast(false);
  };

  const getDataForUpdate = (detail) => {
    setFirstName(first);
    setLastName(last);
    setValue(Phone);
    if (detail?.phone_number) {
      let breakContactNumber = detail?.phone_number?.split(" ");
      setCountryCode(breakContactNumber[0]);
      setValue(breakContactNumber[1]);
    }
  };

  const checkToken = () => {
    let token = localStorage.getItem("usertoken");

    if (token !== null) {
      Get_Profile_Method(
        (value) => {
          setMessage(value);
        },
        (value) => {
          setShowToast(value);
        },
        (value) => {
          setSuccess(value);
        },
        (value) => {
          updateUser(value);
        }
      );
    } else {
      push("/");
    }
  };

  const Get_Profiles = () => {
    Get_Profiles_Method(
      (value) => {
        setMessage(value);
        addToast(value, {
          autoDismiss: 1000,
        });
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        updateUser(value);
        setUserDetails(value);
        let defaultInfo = value.filter((item) => item?.is_default == "1");
        setFirstNameInfo(defaultInfo?.data[0]?.firstName);
        setlastNameInfo(defaultInfo?.data[0]?.lastName);
        setEmailInfo(defaultInfo?.data[0]?.emailAddress);
        setCifInfo(defaultInfo?.data[0]?.CIF);
        setPhoneNumberInfo(defaultInfo?.data[0]?.phone_number);
        setAddressInfo(defaultInfo?.data[0]?.address);
        setCompanyInfo(defaultInfo?.data[0]?.companyName);
        // setDefaultProfile(defaultInfo?.data[0]?.profileTitle)
      }
    );
  };

  // const fetchedData = userDetails.map(item=>item?.data.map(item=>item))
  const fetchedData = userDetails?.map((item) => {
    return {
      id: item?.profile_id,
      profileTitle: item?.data[0]?.profileTitle,
      data: item?.data,
    };
  });

  const udateProfileMethod = () => {
    let obj = {
      first_name: firstName,
      last_name: lastName,
      phone_number: value,
      // phone_number: countryCode + " " + phoneNo,
    };
    if (
      value?.trim()?.length >= 7 &&
      value?.trim()?.length <= 15 &&
      firstName?.trim().length > 0 &&
      lastName?.trim().length > 0
    ) {
      Edit_Profile_Method(
        obj,
        (value) => {
          setShowSpinner(value);
        },
        (value) => {
          setSuccess(value);
        },
        (value) => {
          setMessage(value);
          addToast(value, {
            autoDismiss: 1000,
          });
        },
        (value) => {
          // setShowToast(value);
        },
        (value) => {
          setIsEditModal(false);
        },
        () => {
          Get_Profile_Method(
            (value) => {
              setMessage(value);
            },
            (value) => {
              // setShowToast(value);
            },
            (value) => {
              setSuccess(value);
            },
            (value) => {
              updateUser(value);
              localStorage.setItem("firstName", value?.first_name);
              localStorage.setItem("lastName", value?.last_name);
              localStorage.setItem("emailAddress", value?.email);
              // localStorage.setItem("CIF",success?.data?.response?.detail?.user?.CIF);
              localStorage.setItem("phone_number", value?.phone_number);
            }
          );
        }
      );
    } else {
      setIsFieldRequired(true);
    }
  };

  // const changeLanguage1 = () => {
  //   const lang = localStorage.getItem("primaryLanguage");
  //   if (lang == "spanish") {
  //     localStorage.setItem("primaryLanguage", "english");
  //     history.go(0);
  //   }

  //   // window.reload();
  //   history.go(0);
  // };
  // const changeLanguage2 = () => {
  //   const lang = localStorage.getItem("primaryLanguage");
  //   if (lang == "english") {
  //     localStorage.setItem("primaryLanguage", "spanish");
  //     history.go(0);
  //   }

  //   // window.reload();
  //   history.go(0);
  // };

  return (
    <section className="pb-150 ls" id="services" data-animation="fadeInUp">
      <div className="container myAccount-centainer">
        <div className="row">
          <div className="col-lg-12 col-md-12 p-0">
            <div className="row">
              {showToast === true ? (
                <ToastComponent
                  message={message}
                  toastClose={closeToast}
                  success={success}
                />
              ) : (
                ""
              )}
              <div className="col-lg-12 col-md-12 col-11 my-account-style">
                <div className="pdpt-bg">
                  <div className="pdpt-title">
                    <h4>
                      {labels.personalInformation}{" "}
                      {/* <span
                        className="float-right"
                        onClick={() => {
                          setShow(true);
                        }}
                      >
                        <Link
                          href="text-blue"
                          data-toggle="modal"
                          data-target="#email_model"
                        >
                          <FaPen />
                        </Link>
                      </span> */}
                    </h4>
                  </div>
                  <div className="çontainer">
                    <br />
                    <div className="row ddsh-body">
                      <div className="col-lg-6 col-md-12">
                        <p className="text-black text-capitalize">
                          {labels.welcome}{" "}
                          {`${loginInUserAccount?.first_name || ""} ${
                            loginInUserAccount?.last_name || ""
                          }`}
                        </p>
                      </div>
                      {/* <div className="col-lg-6 col-md-12">
                        <h6 className="mb-0">{labels.FirstName}</h6>
                        <h6 className="dummyText m-0">
                          {firstNameInfo}
                        </h6>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <h6 className="mb-0">{labels.LastName}</h6>
                        <h6 className="dummyText m-0">
                          {lastNameInfo}
                        </h6>
                      </div> */}
                      {/* <div className="col-lg-6 col-md-12">
                        <h6 className="mb-0">{labels.phoneNumber}:</h6>
                        <h6 className="dummyText m-0">
                          {Phone ?? fetchedData[0]?.data[0]?.phone_number}
                        </h6>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="ml-3 d-flex justifyContentSB pr-3 height30px">
                    <h6 className=" ml-1 font-weight-bold">
                      {labels.businessOrSelfEmployed}
                    </h6>
                    <Switch
                      checked={check}
                      onChange={handleChange}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={10}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={15}
                      width={38}
                      className="react-switch"
                      id="material-switch"
                    />
                  </div> */}
                  <div className="çontainer">
                    <div className="row ddsh-body ">
                      {/* <div className="col-lg-6 col-md-12">
                        <h6 className="mb-0">{labels.companyName}</h6>
                        <h6 className="dummyText m-0">{company || ""}</h6>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <h6 className="mb-0">{labels.cif}</h6>
                        <h6 className="dummyText m-0">{CIF || CIF}</h6>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <h6 className="mb-0">{labels.address}</h6>
                        <h6 className="dummyText m-0 mb-2">{address || ""}</h6>
                      </div> */}

                      <Dropdown className="col-md-12 info-style pl-0 w-100">
                        <Dropdown.Toggle
                          as="h6"
                          className="mb-1 dummyText rounded "
                          id="dropdown-basic "
                          variant="transparent"
                          style={{
                            marginLeft: "11px",
                          }}
                        >
                          {/* {fetchedData[0]?.profileTitle} */}
                          {labels.myProfiles}
                        </Dropdown.Toggle>
                        <RiArrowRightSLine size={25} />{" "}
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              push({
                                pathname: "/newaccount",
                              });
                            }}
                          >
                            {labels.addProfile}
                          </Dropdown.Item>

                          {fetchedData?.map((item, index) => {
                            return (
                              <Dropdown.Item
                                onClick={() => {
                                  push({
                                    pathname: "/newaccount",
                                    state: { id: item?.id, data: item?.data },
                                  });
                                }}
                              >
                                {item?.profileTitle}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                      {/* <div
                        className="col-md-12 info-style"
                        onClick={() =>
                          push({
                            pathname: "/1/myorder",
                            state: { label: "billing" },
                          })
                        }
                      >
                        <h6 className="mb-0  dummyText">{labels.billinInfo}</h6>
                        <RiArrowRightSLine size={25} />{" "}
                      </div> */}

                      <div
                        className="col-md-12 info-style"
                        onClick={() => setIsPasswordModal(true)}
                      >
                        <h6 className="mb-0  dummyText">
                          {labels.changePassword}
                        </h6>
                        <RiArrowRightSLine size={25} />{" "}
                      </div>

                      <Dropdown className="col-md-12 info-style pl-0">
                        <Dropdown.Toggle
                          as="h6"
                          className="mb-1 dummyText rounded"
                          id="dropdown-basic "
                          variant="transparent"
                          style={{
                            marginLeft: "11px",
                          }}
                        >
                          {labels.changeLanguage}
                        </Dropdown.Toggle>
                        <RiArrowRightSLine size={25} />{" "}
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              localStorage.setItem(
                                "primaryLanguage",
                                "english"
                              );
                              history.go(0);
                            }}
                          >
                            English
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => {
                              localStorage.setItem(
                                "primaryLanguage",
                                "spanish"
                              );
                              history.go(0);
                            }}
                          >
                            Spanish
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <div
                        className="col-md-12 info-style"
                        onClick={() =>
                          push({
                            pathname: "/payment",
                          })
                        }
                      >
                        <h6 className="mb-0  dummyText">
                          {labels.paymentMethods}
                        </h6>
                        <RiArrowRightSLine size={25} />{" "}
                      </div>
                      <div
                        className="col-md-12 info-style"
                        onClick={() => setIsDeleteAccountModal(true)}
                      >
                        <h6 className="mb-0  dummyText">
                          {labels.deleteAccount}
                        </h6>
                        <RiArrowRightSLine size={25} />{" "}
                      </div>
                      <EditProfileModal
                        show={show}
                        onHide={() => setShow(false)}
                        data={fetchedData}
                        firstName={fetchedData
                          ?.map((item) => item?.data)
                          .map((item) => item[0]?.firstName)}
                        id={fetchedData?.map((item) => item?.id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-12  col-11 my-account-style">
                <div className="pdpt-bg">
                  <div className="pdpt-title">
                    <h4>
                      {labels.accountInformation}{" "}
                      <span className="float-right">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setIsEditModal(true);
                            getDataForUpdate(user);
                          }}
                        >
                          <FaPen />
                        </div>
                      </span>
                    </h4>
                  </div>
                  <br />
                  <div className="ddsh-body">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="mb-0">{labels.FirstName}</h6>
                        <h6 className=" dummyText m-0">{first}</h6>
                      </div>
                      <div className="col-md-12">
                        <h6 className="mb-0">{labels.LastName}</h6>
                        <h6 className=" dummyText m-0">{last}</h6>
                      </div>
                      <div className="col-md-12">
                        <h6 className="mb-0">{labels.Email}</h6>
                        <h6 className=" dummyText m-0">
                          {email || fetchedData[0]?.data[0]?.emailAddress}
                        </h6>
                      </div>
                      <div className="col-md-12">
                        <h6 className="mb-0">{labels.phoneNumber}</h6>
                        <h6 className=" dummyText m-0">
                          {Phone || fetchedData[0]?.data[0]?.contactNo}
                        </h6>
                      </div>
                      <div className="col-md-12" style={{ height: "5px" }} />

                      <div
                        className="col-md-12 info-style"
                        onClick={() => setIsPasswordModal(true)}
                      >
                        <h6 className="mb-0  dummyText">
                          {labels.changePassword}
                        </h6>
                        <RiArrowRightSLine size={25} />{" "}
                      </div>

                      <Dropdown className="col-md-12 info-style pl-0">
                        <Dropdown.Toggle
                          as="h"
                          className="mb-1 mx-3 dummyText rounded"
                          id="dropdown-basic "
                          variant="transparent"
                        >
                          {labels.changeLanguage}
                        </Dropdown.Toggle>
                        <RiArrowRightSLine size={25} />{" "}
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              localStorage.setItem(
                                "primaryLanguage",
                                "english"
                              );
                              history.go(0);
                            }}
                          >
                            English
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => {
                              localStorage.setItem(
                                "primaryLanguage",
                                "spanish"
                              );
                              history.go(0);
                            }}
                          >
                            Spanish
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <div
                        className="col-md-12 info-style"
                        onClick={() =>
                          push({
                            pathname: "/payment",
                          })
                        }
                      >
                        <h6 className="mb-0  dummyText">
                          {labels.paymentMethods}
                        </h6>
                        <RiArrowRightSLine size={25} />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <Modal
          className="modelBackground"
          show={isEditModal}
          onHide={() => setIsEditModal(false)}
          size="lg"
        >
          <ModalBody className="bg-white p-0 border-radius-10  textAlignCenter m-auto height_71 my-20 w-100 ">
            <button
              type="button"
              className="close absolute"
              data-dismiss="modal"
              onClick={() => setIsEditModal(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <div className="px-5 py-3 w-100 ">
              {showToast === true ? (
                <ToastComponent
                  message={message}
                  toastClose={closeToast}
                  success={success}
                />
              ) : (
                ""
              )}
              <h4 className="textAlignCenter">{labels.editProfile}</h4>
              <div className="form-group">
                <label className="d-block" style={{ textAlign: "left" }}>
                  {labels.FirstName}
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="firstName"
                  placeholder={labels.enterfirstName}
                  value={firstName}
                  onChange={(e) => setFirstName(validateText(e.target.value))}
                />
                {fieldIsRequired === true && !firstName?.trim().length ? (
                  <p className="text-justify" style={{ color: "red" }}>
                    {labels.validateField}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group">
                <label className="d-block " style={{ textAlign: "left" }}>
                  {labels.LastName}
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="lastName"
                  placeholder={labels.enterlastName}
                  value={lastName}
                  onChange={(e) => setLastName(validateText(e.target.value))}
                />
                {fieldIsRequired === true && !lastName?.trim().length ? (
                  <p className="text-justify" style={{ color: "red" }}>
                    {labels.validateField}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group">
                <label className="d-block" style={{ textAlign: "left" }}>
                  {labels.phoneNumber}
                </label>
                <div className="d-flex justify-content-around">
                  <input
                    className="form-control"
                    maxLength={20}
                    autoComplete={false}
                    type="tel"
                    placeholder={labels.phoneNumber}
                    value={value}
                    onChange={(e) => {
                      const re = /^[0-9-+\b]+$/;

                      // if value is not blank, then test the regex

                      if (e.target.value === "" || re.test(e.target.value)) {
                        setValue(e.target.value);
                      }
                    }}
                  />
                  {/* <PhoneInput
                    style={{ width: "100%", marginRight: "5px" }}
                    international
                    countryCallingCodeEditable={false}
                    value={value}
                    defaultCountry="ES"
                    maxLength={20}
                    onChange={setValue}
                  /> */}
                </div>
                {fieldIsRequired === true && !value?.trim().length ? (
                  <p className="text-justify" style={{ color: "red" }}>
                    {labels.validateField}
                  </p>
                ) : (
                  fieldIsRequired === true &&
                  !Utility?.validatePhone(value) && (
                    <p className="text-justify" style={{ color: "red" }}>
                      Invalid phone number{" "}
                    </p>
                  )
                )}
              </div>
              <div className="conf-modal-footer1 ">
                <button
                  onClick={() => udateProfileMethod()}
                  className="mb-1 mt-3 ml-1"
                >
                  {showSpinner ? labels.loading : labels.update}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {isDeleteAccountModal ? (
          <>
            <DeleteAccount
              isDeleteAccountModal={isDeleteAccountModal}
              setIsDeleteAccountModal={setIsDeleteAccountModal}
            />
          </>
        ) : null}
        <Modal
          className="modelBackground"
          show={isPasswordModal}
          onHide={() => setIsPasswordModal(false)}
          size="lg"
        >
          <ModalBody className="bg-white p-0 border-radius-10  textAlignCenter m-auto height_71 my-20 w-100 ">
            <button
              type="button"
              className="close  absolute"
              data-dismiss="modal"
              onClick={() => setIsPasswordModal(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <div className="px-5 py-3 w-100 ">
              {showToast === true ? (
                <ToastComponent
                  message={message}
                  toastClose={closeToast}
                  success={success}
                />
              ) : (
                ""
              )}
              <Change_Password_Page
                passwordModal={() => setIsPasswordModal(false)}
              />
            </div>
          </ModalBody>
        </Modal>
      </div>
    </section>
  );
}
