import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./style/style.css";
import "./assets/newcss/animations.css";
import "./assets/newcss/bootstrap.min.css";
import "./assets/newcss/font-awesome.css";
import "./assets/newcss/main.css";
import "./style/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/fonts/Proxima-Nova-Font/ProximaNova-Regular.otf";
import "./assets/fonts/Proxima-Nova-Font/Proxima Nova Bold.otf";
import "./assets/fonts/Proxima-Nova-Font/FontsFree-Net-Proxima-Nova-Sbold.otf";
import { Provider } from "react-redux";
import store from "./store";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
