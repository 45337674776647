import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { Images } from "../constant/images";
import MyModal from "./MyModal";
import CustomTooltip from "./tooltip";

import { Col, Modal, ModalBody } from "react-bootstrap";
import labels from "../config/Labels";
import {
  languageTranslationFunctionForTheTime,
  replaceDecimalWithComma,
} from "../config/Utility";
import User_Login from "../screen/session/login";
import User_Registration from "../screen/session/signup";
let language = localStorage.getItem("primaryLanguage");

export default function SpecificServiceButton({
  image,
  title,
  desc,
  assessment,
  price = false,
  discount = false,
  path,
  states,
  isPartner = false,
  country,
  ratings,
  branch_id = "",
  activeFilter = "",
  servicePrice = "",
  avatar = false,
  serviceAreaId = "",
  benificiaryId = "",
  item = "",
  servicePak = "",
  supplementory = "",
  supplementaryFees = "",
  branches = "",
  branchId = "",
  level2 = "",
  serviceCard = false,
  selected_serviceItem,
  className = "",
}) {
  const { addToast } = useToasts();
  const { push } = useHistory();
  const target = useRef(null);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [register, setRegister] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  let token = localStorage.getItem("usertoken");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalShow]);

  const handleClose = () => setShowModal(false);

  const [showModal, setShowModal] = useState(false);
  const changeForm = () => {
    setIsLogin(!isLogin);
  };
  return (
    <Col
      // className="onCardHover datalist d-flex align-items-center my-15 mx-20"
      lg={serviceCard === true ? 6 : 4}
      xs={12}
      sm={12}
      md={12}
    >
      <div
        style={{
          height: 200,
          padding: 10,
          borderWidth: "1px",
          borderRadius: 10,
          borderColor: "#46bcff",
          borderStyle: "solid",
          minHeight: serviceCard ? "140px" : "auto",
        }}
        className={`onCardHover d-flex justify-content-between align-items-center my-15 h-auto bg-white ${serviceCard === true ? "" : "shadow"}`}
      >
        <div
          className={`d-flex ${serviceCard ? "" : "align-items-center"}  w-100`}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (token !== null || path == "service") {
              localStorage.setItem("branchId", branch_id);
              localStorage.setItem("serviceAreaId", serviceAreaId);
              push({
                pathname: `/${path}/${
                  path !== "documentaion" ? branch_id : ""
                }`,
                state: {
                  acticeFilter: activeFilter,
                  price: price,
                  avatar: avatar,
                  name: title,
                  serviceAreaId: serviceAreaId,
                  benificiaryId: benificiaryId,
                  branch_id: branch_id,
                  item: item,
                  supplementory: supplementory,
                  supplementaryFees: supplementaryFees,
                  branches: branches,
                  level: level2,
                  selected_serviceItem: selected_serviceItem,
                },
              });
            } else {
              setIsLogin(false);
              setShowModal(true);
            }
          }}
        >
          {image ? (
            <div>
              <img
                src={image ? image : ""}
                alt="Image"
                style={{
                  // borderRadius: 10,
                  width: serviceCard ? "70px" : "60px",
                  height: serviceCard ? "70px" : "60px",
                  objectFit: "contain",
                  // background: "rgba(229, 229, 229, 0.29)",
                  // border: "1px solid rgb(229, 229, 229)",
                }}
              />
            </div>
          ) : null}
          <div>
            <div>
              <h4 className="tdr-paragraph custom-font1 ml-2 text-left">
                <span className="">
                  {title}
                  {/* {title.length > 20 ? title.substring(0, 26) + "..." : title} */}
                </span>
              </h4>
            </div>
            <h6 className="text-success mb-0 mt-0 mx-10 custom-font2">
              {price !== "" && path == "documentaion" && (
                <b>
                  {Number(price) + Number(supplementaryFees) !== 0
                    ? "€" +
                      replaceDecimalWithComma(
                        Number(price) + Number(supplementaryFees)
                      )
                    : `€${replaceDecimalWithComma(price) === "0"}`
                    ? labels.free
                    : replaceDecimalWithComma(price)}{" "}
                </b>
              )}
            </h6>
            {Number(price) + Number(supplementaryFees) !== 0 ? (
              <p className="crossTextSpecialService mx-10 mb-0 custom-font2">
                {price !== "" &&
                  path == "documentaion" &&
                  ((price - discount) / price) * 100 !== 0 && (
                    <span className="text-danger ">
                      {"€"}
                      {replaceDecimalWithComma(
                        Number(discount || 0) + Number(supplementaryFees || 0)
                      )}{" "}
                      {(((price - discount) / price) * 100)?.toFixed(0)}%{" "}
                      {labels.discount}
                    </span>
                  )}
              </p>
            ) : null}
            {path == "documentaion" && (
              <h6 className="text-success mb-10 mt-0 mx-10 custom-font2">
                {price !== "" && path == "documentaion" && (
                  <b>
                    {servicePak === "monthly" ? (
                      labels?.monthly
                    ) : (
                      <>
                        {labels.estimatedTime} {item?.time}{" "}
                        {languageTranslationFunctionForTheTime(
                          item?.time,
                          item?.time_type
                        )}
                      </>
                    )}
                  </b>
                )}
              </h6>
            )}
          </div>
        </div>
        <div
          className="partnersRightContainer"
          style={{ width: 32, height: 32 }}
        >
          <img
            className="partnerStarIconInfo"
            style={{ width: 20, height: 20, color: "blue", cursor: "pointer" }}
            src={Images.infoo.default}
            onClick={() => setModalShow(true)}
            onMouseLeave={() => setShow(false)}
            ref={target}
            alt="info"
          />
          <CustomTooltip target={target.current} show={show} info={desc} />
          <MyModal
            target={target.current}
            show={modalShow}
            info={desc}
            onHide={() => setModalShow(false)}
          />
        </div>
      </div>
      <Modal onHide={() => {}} show={showModal} size="auto">
        <ModalBody className="bg-white p-0 border-radius-10">
          {!isLogin ? (
            <User_Login change={changeForm} hideModal={handleClose} />
          ) : (
            <User_Registration change={changeForm} hideModal={handleClose} />
          )}
        </ModalBody>
      </Modal>
    </Col>
  );
}
