// import { Modal } from 'bootstrap';
import React, { useState } from "react";
import { Redirect } from "react-router";
import ScrollAnimation from "react-animate-on-scroll";
import { Helmet } from "react-helmet";

export default function SelectRegion(params) {
  const [shouldNavigate, setShouldNavigate] = useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const saveRegion = () => {
    setShouldNavigate(true);
  };
  if (shouldNavigate) {
    return <Redirect to={"/partners"} />;
  }
  return (
    <>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <div className="row mtb50">
        <div className="col-5 col-lg-5 mx-auto text-left">
          <h3 className="mb-4 textAlignCenter mt60">Select Region</h3>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <div className="selectRegionFormBorder">
              <form id="registerForm">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="fullName">SELECT REGION</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        required=""
                        placeholder="Enter Your Region"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="fullName">PROVINCE</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        required=""
                        placeholder="Enter Your Province"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="fullName">CITY</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        required=""
                        placeholder="Enter Your City"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="fullName">ADDRESS</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        required=""
                        placeholder="Enter Your Address"
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-success btn-block my-4 alignSelfCenter width20 marginAuto"
                  onClick={() => saveRegion()}
                >
                  Save
                </button>
              </form>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </>
  );
}
