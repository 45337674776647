import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { SpecficServiceComponent } from "../component";
import {
  default as CustomTooltip,
  default as ModalComponent,
} from "../component/tooltip";
import { Get_Service_Areas_By_Beneficiary_Id } from "../config/api";
import { Images as Icon } from "../constant/images";
import ToastComponent from "./toast";

export default function ServiceItem({ name, image, beneId, info }) {
  const [serviceModel, setServiceModel] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [serviceAreas, setServiceAreas] = useState([]);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [show, setShow] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    Get_Service_Areas_By_Beneficiary_Id(
      beneId,
      (value) => setMessage(value),
      (value) => setShowToast(value),
      (value) => setSuccess(value),
      (value) => {
        setServiceAreas(value);
      }
    );
  }, [beneId]);

  const closeToast = () => {
    setShowToast(false);
  };

  return (
    <>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <div
        className="ss-features mb-15 text-center bg-white position-relative"
        data-animation="fadeInLeft"
      >
        <a
          data-modal="test"
          onClick={() => {
            if (localStorage.getItem("token") === "") {
              setShowAlert(true);
            } else {
              setServiceModel(true);
            }
          }}
          data-action="open"
          className="button button--circular d-block"
          type="button"
        >
          <div className="ssf-icon">
            <img src={image} />
          </div>
          {name}
        </a>
        <a href="#" className="absoluteicon">
          <img
            src={Icon.infoo.default}
            style={{ cursor: "pointer" }}
            alt="info"
            ref={target}
            onClick={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            width={20}
          />
        </a>
        <CustomTooltip target={target.current} show={show} info={info} />
        <ModalComponent
          show={tooltipOpen}
          info={info}
          showModal={() => setTooltipOpen(false)}
        />
        <Modal show={serviceModel} onHide={() => setServiceModel(false)}>
          <ModalBody className="p-0 border-radius-10">
            {showToast === true ? (
              <ToastComponent
                message={message}
                toastClose={closeToast}
                success={success}
              />
            ) : (
              ""
            )}
            <div className="row containerCenter">
              <div className="features-wrap">
                <div className="text-center mt-15">
                  <h5 className="whiteText">Select Service Area</h5>
                </div>
                <div className="row align-items-center mx-0">
                  {serviceAreas.map((item, index) => {
                    return (
                      <SpecficServiceComponent
                        serviceArea={item}
                        bId={beneId}
                        index={index}
                        info={item.info}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal show={showAlert} onHide={() => setShowAlert(false)}>
          <ModalBody className="p-0 border-radius-10">
            <div className="row containerCenter bg-info">
              Please First Login Then you Will Access Our Services
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}
