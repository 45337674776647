import React from "react";

export default function TermsAndConditionEng() {
  return (
    <div
      className="container"
      style={{ marginBottom: "160px", marginTop: "160px" }}
    >
      <div>
        <h2 style={{ textAlign: "center", lineHeight: "1em" }}>
          General Terms and Conditions
        </h2>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            <em>Last update: July 04, 2024</em>
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            These general conditions of use and legal information (hereinafter,
            General Conditions) apply to the DocuDoc website, whose domain
            is&nbsp;
          </span>
          <a target="_blank" href="https://www.docudocapp.com/">
            <span style={{ color: "rgb(17, 85, 204)" }}>
              https://www.docudocapp.com/
            </span>
          </a>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            and to the corresponding mobile application, as well as to all its
            related or linked sites from
          </span>
          <a target="_blank" href="https://www.docudocapp.com/">
            <span style={{ color: "rgb(17, 85, 204)" }}>
              https://www.docudocapp.com/
            </span>
          </a>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            by DocuDoc (collectively, the Site). The site is property of DocuDoc
            App SL. By using the site, you show your agreement and express
            acceptance of these conditions of use. If you do not agree, please
            refrain from using it.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Through these General Conditions, DocuDoc App SL makes the DocuDoc
            website and mobile application (hereinafter jointly referred to as
            the Platform) available to users (hereinafter User or Users).
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In compliance with the provisions of the regulatory regulations, the
            following identifying data of the owner of the site is displayed:
          </span>
        </p>
        <ul>
          <li>Company name: DocuDoc App SL</li>
          <li>
            Registered office: C/ Ramón y Cajal 5, 28100 Alcobendas,
            Madrid&nbsp;
          </li>
          <li>Tax Identification Number (NIF): B19738905</li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>1. Object</span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc App SL is a technology company, whose main activity is the
            development and management of a technological platform through
            which, through a mobile application or a website (hereinafter, the
            APP) allows certain service providers in different territories to
            offer its services through it, and, where appropriate, if the Users
            of the APP and consumers of the aforementioned service providers
            request it through the APP, in an accessory, intermediate way in the
            immediate or planned delivery of the services. .
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc App SL has developed a Platform through which different
            service providers, with whom DocuDoc can maintain a commercial
            agreement for the use of the platform, offer a series of services.
            The User has the possibility of requesting the acquisition of
            services from these service providers by placing an order with the
            service provider through the Platform, in which cases DocuDoc App SL
            and its Platform acts as a mere intermediary and therefore, It
            cannot and does not assume any responsibility for the quality of the
            services or the correct provision of the services offered directly
            by the service providers or by said third parties.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Likewise, DocuDoc is a multi-category Technological Platform for
            intermediation in the telematic contracting of “on demand” services.
            It aims to facilitate those people who need help with their errands
            and/or services (hereinafter, “Users”), to make their errands
            through and delegating to said service providers willing to
            voluntarily carry out the mandate given to them by the Users.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The service providers, therefore, are a network of independent
            professionals who collaborate with DocuDoc. When they are interested
            in providing their services, they connect to the DocuDoc Platform
            and within a certain time they commit to providing the service they
            require. entrusted by the User through the aforementioned command.
            As a consequence, DocuDoc cannot assume responsibility for delivery
            times as they depend on the good work of the service providers, as
            well as the Public Administration associated with the service, as
            well as the information provided by the Users themselves when
            carrying out the order or its availability and response at the time
            of delivery. For these purposes, the delivery times indicated
            through the Site are merely indicative.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The requirements of the services offered vary in relation to the
            circumstances of each User, so the same deadlines or requirements
            cannot be guaranteed for each particular User. The indicated
            deadlines are the minimum to establish the first contact with the
            client or the delivery of basic services. The details of the
            deadlines indicated for each service are detailed in the information
            of each of the services. It is recommended to hire advice prior to
            hiring other services, to know the deadlines and particular
            requirements.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In any case, DocuDoc's role is that of a mere intermediary and,
            therefore, its only responsibility and task is to manage the APP
            that facilitates contact between the Client and the Service
            Provider. DocuDoc is not responsible in any case and in any way for
            the products and/or services offered through the APP, or for the
            performance of any errand or delivery requested through the APP.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The service provider exempts DocuDoc App SL from any liability that
            may arise during the entire process of providing the service, the
            service itself and its consequences. The service provider will be
            liable for any damages that the client or DocuDoc App SL may suffer
            as a result of the service not being provided adequately or
            completely. The service provider is solely responsible for providing
            all the necessary information regarding the details of the services
            included in the App. If said information is incomplete or incorrect,
            so that the correct provision of the service is not possible, it
            will also be the responsibility of the service provider. service
            provider. The service provider is responsible for any damage caused
            by malpractice, bad advice or poor management of the Service
            contracted through the Platform and/or the processing of personal
            data of clients corresponding to said services.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Throughout these General Conditions of Use we will refer to Clients
            and service providers as Users of the Platform.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>2. Terms of Use</span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Access to the Platform, and the voluntary creation of a profile by
            the User, implies knowledge and express and unequivocal acceptance
            of these General Conditions of Use and Contracting and the Privacy
            Policy and the Cookies Policy by everyone. the users.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            3. Access and Registration for Users
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In order to be a User of the Platform, it is essential that the
            following requirements be met:
          </span>
        </p>
        <ul>
          <li>Have reached or been over 18 years of age.</li>
          <li>
            Truly complete the mandatory fields of the registration form, which
            request personal data such as User name, email, telephone number and
            bank card number, among others and depending on the service.
          </li>
          <li>Accept these Conditions of Use and Contract.</li>
          <li>Accept the Privacy and Data Protection Policy.</li>
          <li>Accept the Cookies Policy.</li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User guarantees that all data regarding their identity and
            legitimacy provided to DocuDoc in their Platform registration forms
            are true, accurate and complete. Likewise, you agree to keep your
            data updated.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In the event that the User provides any false, inaccurate or
            incomplete data or if DocuDoc considers that there are justified
            reasons to doubt its veracity, accuracy and integrity, DocuDoc may
            deny him the access and present or future use of the Platform or any
            of its contents and/or services.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            When registering on the Platform, the User will enter their email
            (username) and an access code (password). These data are strictly
            confidential, personal and non-transferable. DocuDoc recommends not
            using the same access credentials as on other platforms to increase
            account security. In the event that the User uses the same access
            credentials as on other platforms, DocuDoc will not be able to
            guarantee the security of the account or ensure that the User is the
            only one who accesses their profile.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User agrees not to disclose the data related to his or her
            account or make it accessible to third parties. The User will be
            solely responsible in the event of use of said data or the services
            of the Site by third parties, including the statements and/or
            content posted on the Platform, or any other action carried out
            through the use of the username and/or or password.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User will be the sole owner of the content posted by them on the
            Platform. Likewise, through registration on the Platform and
            acceptance of these Conditions, the User grants DocuDoc, in relation
            to the content it may provide, a worldwide, irrevocable,
            transferable, free license, with the right to sublicense, use, copy
            , modification, creation of derivative works, distribution, public
            communication as well as its exploitation in any way that DocuDoc
            considers appropriate, with or without subsequent communication to
            the User and without the need to settle any amount for the
            aforementioned uses.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc cannot guarantee the identity of Registered Users,
            therefore, it will not be responsible for the use of a Registered
            User's identity by third parties. Users are obliged to immediately
            inform DocuDoc of the theft, disclosure or loss of their
            credentials, communicating through the communication channels
            offered by DocuDoc.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.1
            Profile
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In order to complete the registration on the Platform, the User must
            provide some information such as: User's names and surnames, email
            address, telephone number, bank card details, etc. Once registration
            is completed, all Users will be able to access their profile and
            complete it and edit it as they deem appropriate. DocuDoc does not
            store user payment data, which will be processed and stored by
            payment platforms as described in these Conditions and the Privacy
            Policy.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.2.
            Credit card theft or theft
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            While DocuDoc cannot guarantee the identity of registered Users,
            Users will have the obligation to inform DocuDoc in those cases in
            which they are aware that the credit card associated with their
            DocuDoc profile had been stolen, and/or was being used. by a third
            party fraudulently. Therefore, since DocuDoc and its payment
            platform proactively ensure the protection of Users with the
            corresponding security measures, in the event that the User does not
            inform DocuDoc of said theft, DocuDoc will not be responsible for
            the fraudulent use that third parties may access the User's account.
            DocuDoc invites Users, in the event of theft, theft or suspicion of
            misuse of their credit card, to make the corresponding report to the
            police authority.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc undertakes the commitment to assist the User and collaborate
            with the competent authorities, if necessary, to facilitate reliable
            proof of the improper charge. In the event of fraud, DocuDoc
            reserves the right to take any action that may be necessary when it
            is harmed by improper use of the Site.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            4. Operation of the service. Conditions of the Mandate.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            When a User registered as a User needs help with an errand, they
            must go to the web platform or the corresponding DocuDoc application
            and request the service through said telematic means. The basic
            delivery service consists of contracting a service and its
            subsequent delivery as long as it is located exclusively within the
            territory of operation of the service provider. Likewise, the User
            may request the service provider to contract documentation services
            on their behalf, collect them and deliver them to the designated
            address. Through a mandate, the service provider undertakes to
            acquire the services entrusted by the User on their behalf. , and
            according to the indications and specifications provided by the
            latter.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User is solely responsible for the correct recording of the data
            on the Platform, and therefore exempts DocuDoc and the service
            provider from any negligence or error in the provision of the
            service derived from the incorrect recording of the indicated data.
            As a consequence, it will be the User who must assume the cost
            derived from the incorrect recording of the data indicated on the
            Platform.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User must provide all the information, as detailed as possible,
            regarding the service that is the subject of the message, and, where
            applicable, in relation to the service requested from the service
            provider. To do this, you may enter comments that you consider
            useful during the contracting process, as well as, where
            appropriate, share a photograph with the service provider to
            identify the order. The User will have constant communication with
            the service provider, being able to direct at all times to the
            service provider so that it executes the mandate conferred according
            to the User's own instructions.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In order to facilitate direct communication with the service
            provider and in the event that there is any incident in the
            processing of the order and/or to communicate any change in it,
            DocuDoc makes available to Users an internal chat that will allow
            contact direct and immediate between User and service provider
            during the execution of the order. The chat will cease to be active
            at the time the User has received the service or it has been
            canceled for any of the reasons provided. In the event that the User
            wants to communicate with the service provider or with DocuDoc after
            the completion of the order, they must use the contact form present
            on the Platform and contact the User service.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User exempts DocuDoc and the service provider from any
            negligence or error in the instructions provided for the purchase of
            the service requested. As a consequence, it will be the User who
            must assume the cost derived from the incorrect recording of the
            service indications on the Platform (i.e. incorrect address,
            incorrect service, other information necessary to carry out the
            incorrect service).
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In the case of services in which the price of the service appears on
            the Platform, the prices recorded on the Platform may be indicative,
            as established in clause 7 of these General Terms and Conditions of
            Use of the Platform.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            If the service and/or the service provider is not available, the
            service provider must call the User to explain the options. In the
            event that the User does not agree with any of the options set forth
            by the service provider and, consequently, is not interested in
            alternative options, they must assume the cancellation policy set
            forth in these General Conditions of Use. (in section nine). If the
            User does not respond to the calls or is not at the place designated
            for the delivery of the services, the service provider will wait 10
            minutes before canceling the service. After this time, the service
            provider will proceed to refund the amount paid by the User,
            subtracting all expenses incurred by the service provider related to
            the contracted service.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In the event that the service cannot be completed for reasons
            attributable to the User, this party must assume the total cost
            (taxes included) of the rate of the services purchased, plus any
            surcharges.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In addition, the User must bear 100% of the cost of the service
            price and must pay another service to receive the undelivered
            services. Neither DocuDoc nor the service provider will in any case
            be responsible for the deterioration or expiration of the object of
            the order.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Users accept that according to the services contracted through the
            DocuDoc platform, they may receive invoices corresponding to the
            service from the service providers.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Users may evaluate the delivery services provided by the service
            provider. The User will rate the service provider accurately and
            truthfully, taking into account exclusively the provision of the
            services, and refraining from including considerations that are not
            relevant to the assessment of the delivery services provided. The
            ratings sent by users will affect the Rating system of the service
            providers.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            For the purposes of the contracted services, the User requests
            DocuDoc that the service provider's service offer responds to the
            best relationship between price and quality available in the APP in
            relation to a specific order.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Once the order has been completed and the service has been provided,
            if a paid service has been requested, the User may download the
            receipt through the Site in the “My Orders” section.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In the event that the User receives confirmation of the completion
            of the message without having ratified the mandate themselves or
            through a recipient authorized by them, they must notify DocuDoc
            immediately so that the Platform can take the appropriate measures.
            DocuDoc reserves the right to check the communications made by the
            User for the purposes of verification and control.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            5.1 Return of services&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In the event that the User wishes to proceed with the return of a
            service or wishes to make a claim about the performance of a
            service, without prejudice to the fact that DocuDoc, as an
            intermediary, assumes the management of the same, the final person
            responsible will always be the provider. of service with which the
            purchase was made. In the event of a refund of all or part of the
            purchase amount to the User, it will be the service providers who
            will have the power to decide the amount of refund for the service
            not provided, regardless of whether it is DocuDoc that on behalf of
            the providers. local service to proceed with the return. Therefore,
            the return of a service acquired by the service provider in
            execution of the mandate granted by the User will be subject in all
            cases to the return policy of the service provider.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Certain establishments may not have spaces open to the public, so
            the User may not be able to access them for the purposes of
            processing the return or claim. In these cases, the User must
            contact the DocuDoc User Service, through the channels present on
            the Platform, in order to obtain the necessary help and support.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In the event that the User wants to process the return of any
            service because it does not comply with what has been requested
            through the platform, the User must provide a list of the incorrect
            services or those that have not been provided, as well as other
            evidence that demonstrates the non-adequacy of the requested
            service.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User must check the services that the service provider delivers
            and ratify the mandate within a maximum period of 4 days after
            receiving confirmation of service delivery by email. If a claim is
            not made within those 4 days following notification of service
            delivery, the User confirms and ratifies receipt of the service.
            Likewise, the User states that a third party may ratify the mandate
            on their behalf, for example in those cases in which the User is not
            at the final delivery address or has designated a third party for
            collection and signature, if applicable. Thus, the responsibility
            falls on the User and/or said third party to verify the adequacy of
            the service, as well as, where appropriate, to collect sufficient
            evidence to justify the opposite.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In any case, it will be the power of the service provider to
            determine in each case the origin of the return, so, in the event of
            a dispute, the User must inform DocuDoc through the means provided
            by DocuDoc for this purpose.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>5.2 Refund policy</span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            To be eligible for a return, the customer must request a refund
            through the Platform (My profile &gt; My orders &gt; Select order
            &gt; Claim amount). A dispute will be opened between the Client,
            DocuDoc and the Provider to try to resolve any doubt or disagreement
            regarding the service, that part or all of the service has not been
            provided for reasons attributable to the Provider, a refund will be
            made. what is considered, according to the alignment of these return
            policies, which are set out below:
          </span>
        </p>
        <ul>
          <li>
            The Supplier will make a breakdown of the activities carried out at
            the time of the request and will deduct the following from the
            refund, if applicable:
          </li>
          <ul>
            <li>
              Any expenses incurred by the Supplier for the proper processing of
              the service requested by the client, as well as transfers.
            </li>
            <li>
              Costs of meetings and consulting, whether in person,
              electronically, through messages within the Platform, by email, or
              of any type that have been carried out together with the
              client.&nbsp;
            </li>
            <li>
              Expenses for bank or digital transfers from electronic platforms.
            </li>
          </ul>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Once the refund has been processed, it will be made to the original
            payment method within a certain number of days. It should be taken
            into account that the refund accreditation times may vary from the
            date of refund notification depending on the times of the service
            banks busy for the transfer. Please note that the bank may deduct
            banking fees beyond the Supplier's control.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            If you consider that you have not received the return or consider
            that it is lost, you can proceed as follows:
          </span>
        </p>
        <ul>
          <li>Check your bank account after two business days.</li>
          <li>
            Contact the credit card company, bank, online payment system or
            remittance, remember that such action may take some time before the
            refund is officially reflected.
          </li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Follow all these steps, the return has not yet been received,
            contact us at info@docudocapp.com
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The Supplier will not make a refund in the following cases:
          </span>
        </p>
        <ul>
          <li>
            Those in which the result of the already completed procedure is
            unfavorable or negative for the client, if these were carried out
            with the due diligence inherent to the service to be provided.
          </li>
          <li>
            Those in which the result of the already completed procedure is
            unfavorable or negative for the client, if this negative result has
            been the cause of false, wrong or incomplete information on the part
            of the client.
          </li>
          <li>
            Those in which, due to lack of communication, delivery of documents,
            or funds necessary to carry out a procedure, has caused the sudden
            termination of the service provided, as long as the client has been
            notified about the importance of said elements. and he has not
            delivered them.
          </li>
          <li>
            Those in which the client considers that the service has not been
            correctly provided, if it was done within the stipulated period, and
            must complain directly to the Supplier.
          </li>
          <li>
            Those referring to the case feasibility study testing service.
          </li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The Provider, at its own expense, will proceed to refund the amount
            paid in the event that the request is impossible to attend to,
            prohibited, incomprehensible, or any other cause it considers,
            reserving the right to cancel the service, without the need to prove
            , for any reason, obligating to return the amount paid within 72
            hours from the moment of requesting the service.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The client will have the right to a refund of the amount paid if the
            Supplier has not communicated to the Client about the requested
            service within a period of two weeks.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In the event that, to proceed with the provision of the service, due
            to its complexity, more documentation, more data, successive
            contacts are required, the owner of the website will be obliged to
            provide the service within a stipulated period, and this must be
            stated to the Customer.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            <strong>
              Exchange of emails between the Supplier and the client:
            </strong>
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            As a general rule, messages exchanged by WhatsApp or email are not
            charged.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>Special case:</span>
        </p>
        <ul>
          <li>
            When a client purchases a service, and in the case of a return,
            WhatsApp sent to the client will not be charged, but TEN (10) euros
            will be deducted for each email sent to the client from the moment
            the service was contracted; Likewise, all 15-minute video calls made
            with the client that were made during the time that the immigration
            processing and accompaniment process lasted will be deducted. In the
            event that the budget paid by the client exceeds the total result of
            the emails counted, the client will not be charged an extra amount,
            taking into account that there will be no amount to be returned.
          </li>
          <li>
            In the event that the client demands a refund of the money because
            what was explained in the advice, the client considers erroneous,
            the refund will only proceed when what was said by the Provider is
            not found in the current Law. In the event that what is said by the
            Supplier is in the Law, but the client does not understand it, no
            amount will be refunded.
          </li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            6. Service rates and billing
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Invoices will be sent to the User to the email address indicated
            when the profile was created once the provision of the service has
            been completed. This invoice can also be downloaded in “My orders”
            once the service has been provided.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Registration and use of the Platform for Users has no associated
            cost, and these can be reviewed by DocuDoc at any time. Certain
            services on the Platform may have a cost for the User, as indicated
            at the time of contracting.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User must only pay for each service requested through the
            Platform for its use to request services and communication through
            the Platform.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Additionally, in those services that include the purchase of a
            service, the User must pay the price of said service. The User, by
            registering through the Platform and providing the required banking
            information, expressly authorizes DocuDoc to send the receipts
            corresponding to the payment of the requested services, including
            the price and supplements of the requested services.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In accordance with these conditions, the User will have the right to
            know the approximate rate of their service before contracting it and
            formalizing the payment, except when the User does not specify the
            pickup address. The shipping service rate may vary when
            circumstances of force majeure occur that are beyond the control of
            DocuDoc and that lead to an increase in said rate.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc reserves the right to modify prices on the platform at any
            time. The changes made will take effect immediately upon
            publication. The User expressly authorizes DocuDoc to send the
            receipt of the contracted services by electronic means, to the email
            address provided by the User during the registration process. If an
            invoice with tax data is required, the User must add the tax data to
            the platform before placing the order.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The subsequent cancellation of the service by the User, when there
            have already been expenses related to the order by the service
            providers and the same has been communicated to the User, will
            entitle DocuDoc to charge the User the rate that, if applicable,
            corresponds. All this without prejudice to the User being able to
            request a new service. The User may exercise their right of
            withdrawal before the service provider who has sold them the
            services within the period established by law from.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Users select the Service they wish to contract and the service
            provider of said service through the Platform. At the end of said
            selection, Users pay through the Platform the price indicated
            therein for said service, which could be called a provision. of
            funds, thus contracting the service to the selected service
            provider. The entire amount paid by the User will remain in the
            payment platform until the contracted service is delivered.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            At any time after contracting the service, the service provider may
            request from the Client a provision of funds for the estimated
            amount of the supplies corresponding to the contracted service. The
            amount of said provision will be paid directly by the Client to the
            service provider.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span style={{ color: "rgb(74, 74, 74)" }} />
          <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
          <span style={{ color: "rgb(74, 74, 74)" }} />
          <span style={{ color: "rgb(74, 74, 74)" }}>
            6.1. Payment platforms
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Payment for the services offered through the Platform and delivered
            to Users once the service has been carried out is temporarily made
            to DocuDoc and is transmitted to the service providers with whom it
            has a commercial agreement. The associated service providers
            authorize DocuDoc to accept payment on their behalf, so payment of
            the price of any service correctly carried out in favor of DocuDoc
            will download the User of the obligations to pay said price to the
            associated service provider.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Payment for services and/or services made by Users is received in
            DocuDoc accounts through an Electronic Money Entity. Electronic
            Money Entities are authorized to provide, directly or indirectly,
            regulated payment services in all territories in which DocuDoc
            operates and comply with current regulations regarding payment
            services for Platforms such as DocuDoc*.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            *If these entities are not authorized, DocuDoc is exempt from all
            responsibility for said lack of authorization or license, being the
            sole and exclusive responsibility of the Electronic Money
            Entities.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc, through the payment provider that it has contracted for
            this purpose and with the sole purpose of verifying the means of
            payment provided, reserves the right, as an anti-fraud measure, to
            request pre-authorization of charging for the services ordered
            through the platform. The aforementioned pre-authorization will not
            imply full payment of the order to the service provider. This will
            be carried out solely and exclusively after the services are made
            available to the User or, for the reasons described in these terms
            and conditions.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In the event of a dispute, DocuDoc will provide the first line of
            support and will reimburse the User for the portion of the service
            deemed appropriate.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            If the User has any problem with the processing of their order, they
            may contact the DocuDoc User Service through the means made
            available to them on the Platform.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            7. Price of the services and/or services recorded on the Platform
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            All prices indicated on the Platform include taxes that may be
            applicable depending on the territory from which the User operates
            and in any case will be expressed in the currency in force depending
            on the territory from which the User operates.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In accordance with Clause 6 above, the prices applicable to each
            service will be those published on the Platform subject to the
            particularities set forth and applied automatically in the
            contracting process in the last phase thereof.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            However, the prices of sales services from service providers shown
            on the DocuDoc Platform may be indicative. In any case, these prices
            correspond to sales services from service providers and are
            exclusively set by them.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The Price exclusively includes the cost of the corresponding service
            for the correct and complete provision of the service other than
            those corresponding to the Supplies. The costs, fees and other
            supplies necessary to carry out the service will be paid by the User
            directly to the service provider in the manner indicated by the
            service provider (For example, by transfer or Bizum), so Users will
            not They pay it at the time of contracting the service.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The supplies will be settled by the service provider and are
            invoiced in the name of the client. These supply invoices are
            attached to the invoice issued by the service provider to the User,
            thus guaranteeing a single invoice.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User assumes that in any case the economic valuation of some of
            the supplies related to the service may vary in real time depending
            on the establishment associated with the supply. For any information
            about the requested order, the User may contact DocuDoc.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In accordance with the above, the User, by requesting a purchase
            through the Platform, grants the service provider a mandate to
            provide the service for the price set by the service providers. The
            User may be in direct contact with the service provider while
            placing the order.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            8. Promotional codes and/or other offers or discounts
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc may provide, at any time and unilaterally, credits to be
            used within the Platform or free services to certain Users. The User
            acknowledges and accepts that the free credits and services must be
            used within the established period available to the User on the
            Platform.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The promotional codes and/or other offers or discounts offered
            through the Platform must be correctly entered in the application
            prior to placing the order, otherwise their use will not be
            effective for the User and they will not be able to enjoy
            them.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Whenever a cancellation is requested by DocuDoc in the terms
            described, the User will maintain the validity of the promotional
            code and/or other offers or discounts for future use.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc reserves the right to cancel promotional codes and/or other
            offers or discounts offered unilaterally and reserves the right to
            apply sanctions to Users for the amount of defrauded use of DocuDoc.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc does not assume any responsibility if, due to force majeure
            or events beyond its control or whose necessity is justified, it is
            forced to cancel, shorten, extend or modify the conditions of the
            promotions.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In particular, DocuDoc assumes no responsibility if the website is
            not available at any time during the promotions or if the automated
            promotion system malfunctions.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Promotional codes are not cumulative. The user can only benefit from
            1 (one) promotional code per order. The app will automatically
            select the promotional code to apply.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In turn, promotional codes cannot be used in promotions offered in
            the APP.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            It is prohibited for the same user to register multiple times to
            obtain illicit benefits that are not in good faith, malicious or
            that contradict our terms and conditions.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            9. Right of withdrawal and cancellation of orders
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In accordance with the very nature of the service offered by
            DocuDoc, the User is aware that once an order has been placed, the
            execution of the purchase order is considered to have begun and
            therefore the User may not have the right to withdraw from the
            order. request the service for free.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Without prejudice to the above, cancellation costs may depend on the
            following factors;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            - If the service provider has started working on the order, the
            amount of the services will be charged. The service provider
            indicates the beginning of the provision of the service by means of
            a message to the User or by updating the status of the order through
            the Platform and/or by sending an email or a telephone call to the
            data registered by the User.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            - If the User cancels the order once the service provider has
            started working on the service, they will be charged the amount
            considered by the service provider, in accordance with the work
            incurred up to the time of cancellation, plus a possible
            cancellation fee to the User. The order cancellation fee may
            represent 3% of the total price of the service contracted through
            the platform. The service provider will inform the User of the cost
            of cancellation. In the event that the order is canceled by DocuDoc,
            the agents will contact the User to inform them of the cancellation
            cost, if any.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In the case of services, at the time of cancellation of the service,
            if the service provider had already performed the service, the User
            must pay the total cost of acquiring the services and the delivery
            costs.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In the event that the User has incorrectly indicated the data to be
            able to carry out the services, the order will be cancelled, and the
            User must assume the costs generated as established in this clause.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc reserves the right to cancel an order without giving just
            cause.&nbsp; In the event of cancellation requested by DocuDoc
            without just cause, the User will have the right to a refund of the
            amount paid.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc has official complaint forms available to the User, for the
            purpose of the service offered by DocuDoc, in the official languages
            ​​of the countries where DocuDoc operates. The aforementioned claim
            forms may be requested by the User through the DocuDoc User Service.
            The User must specify in the email the exact location from which the
            request is made, which must coincide with the place where the
            service is performed. In case of doubt, the latter will be the place
            where the claim must be placed.&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            10. Special conditions of the order service for purchasing services
            through DocuDoc
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User has the option of requesting through the Platform the
            in-person acquisition by the service provider of a series of
            services that are offered by establishments with which DocuDoc may
            or may not maintain a commercial agreement. The User can select,
            through a drop-down menu within the Platform, a series of options
            where the characteristics, price, as well as other characteristics
            of the service can be indicated as a guide.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Once the User selects one of the options, they may also complete the
            order using a free text space to include more detailed information
            or instructions for the service provider in charge of carrying out
            said order.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User is aware and accepts that the descriptions and, where
            applicable, the prices or photographs of the services offered on the
            Platform are made based on the information and documentation
            provided by the establishments, so DocuDoc cannot offer guarantees
            against possible inaccuracies in said descriptions, prices and/or
            photographs.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Likewise, the User accepts that all the services reflected on the
            DocuDoc platform are subject to their availability and in this sense
            assumes the possibility that while placing the order they encounter
            the non-availability of the service and/or service in the
            establishment . Additionally, the price of the service may vary
            slightly due to changes at the point of sale. DocuDoc reserves the
            right to proceed with the purchase in the event of price variations
            of up to 30%. If it exceeds the aforementioned 30%, the service
            provider will contact the User to inform them of the situation.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc reserves the right to withdraw any service from its
            platform, as well as to modify the content of its file at any time,
            without any type of responsibility being attributed to it.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.1
            Deliveries&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc provides the User with a delivery service for the services
            acquired by the service provider in certain cities. The order can be
            placed as long as the following conditions are met at the time of
            payment of the order:
          </span>
        </p>
        <ul>
          <li>The service is operational for the chosen time slot.</li>
          <li>
            The service to be delivered must be located within one of the cities
            and within the authorized space in which DocuDoc operates.
          </li>
          <li>
            The destination must be located in the same city where the service
            is located.
          </li>
          <li>
            The public authorities necessary to carry out the service can
            operate within the established period
          </li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.2
            Price and payment method
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The price of the service and/or service will be that indicated
            through the Platform. However, the User is aware that in any case
            the economic valuation of some of the services may vary in real
            time, due to possible supplements from third parties.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Any change or variation will be communicated by the service provider
            for the User's approval. If they wish to make any assessment
            regarding the order placed, the User will always have the
            possibility of contacting directly the service provider that
            executes the mandate granted.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User may pay for the services through their credit card. For
            payment by card, the User must provide their details through the
            platform as a payment method associated with their account. DocuDoc
            does not store the card number on its servers, and will only be able
            to view the last four digits of the card. Complete information will
            be stored on the servers of the payment service provider that makes
            payments on behalf of DocuDoc. Payment by credit card will not
            entail any extra cost for the User. All of this in accordance with
            the terms of the payment platform set out below.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            When the payment of the service to the User cannot be carried out
            for any reason, the User's account will be blocked until the debt is
            regularized and paid.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>11. User Obligations</span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Users are completely responsible for the access and correct use of
            their profile and other content of the Platform subject to current
            legislation, whether national or international, of the Country from
            which they use the Platform, as well as the principles of good
            faith, to morality, good customs and public order. And specifically,
            you undertake to diligently observe these General Conditions of Use.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Users are responsible for correctly recording individual User names
            and passwords, and other data for the correct provision of the
            non-transferable and sufficiently complex service, as well as not
            using the same User name and password as on other platforms, all
            with the purpose of protecting your account from fraudulent use by
            third parties outside the Platform.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Users will refrain from using their profile and the rest of the
            contents of the Platform for illicit purposes or effects that are
            harmful to the rights and interests of third parties, or that in any
            way may damage, disable, affect or deteriorate the Platform, its
            content and its services. Likewise, it is prohibited to prevent the
            normal use or enjoyment of the Platform by other Users.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc cannot be considered editorially responsible, and expressly
            declares that it does not identify with any of the opinions that may
            be expressed by the Users of the Platform, for the consequences of
            which the issuer is entirely responsible.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Those who fail to comply with such obligations will be liable for
            any harm or damage they cause. DocuDoc will not be liable for any
            consequence, damage or harm that may arise from said access or
            illicit use by third parties.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            In general, Users undertake, by way of example and not limitation,
            to:
          </span>
        </p>
        <ul>
          <li>
            Not alter or modify, totally or partially, the Platform,
            circumventing, deactivating or otherwise manipulating its functions
            or services;
          </li>
          <li>
            Not infringe industrial and intellectual property rights or the
            regulations governing the protection of personal data;
          </li>
          <li>
            Not use the Platform to insult, defame, intimidate, violate one's
            own image or harass other Users; - Do not access the email accounts
            of other Users;
          </li>
          <li>
            Do not introduce computer viruses, defective files, or any other
            computer program that may cause damage or alterations to the
            contents or systems of DocuDoc or third parties;
          </li>
          <li>
            Do not send mass and/or repetitive emails to a plurality of people,
            nor send email addresses of third parties without their consent;
          </li>
          <li>
            Do not carry out advertising actions for goods or services without
            the prior consent of DocuDoc.
          </li>
          <li>Do not contract services with false identities</li>
          <li>Do not commit fraudulent acts</li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Any User may report another User when they consider that they are
            breaching these General Conditions of Use. Likewise, all Users can
            inform DocuDoc of any abuse or violation of these conditions,
            through the Contact Form. DocuDoc will verify this report, as soon
            as possible, and will adopt the measures it deems appropriate,
            reserving the right to withdraw and/or suspend any User from the
            Platform for non-compliance with these General Conditions of Use.
            Likewise, DocuDoc reserves the right to right to withdraw and/or
            suspend any message with illegal or offensive content, without prior
            notice or subsequent notification.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            19. User Unsubscription
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User may unsubscribe from the Platform through their profile and
            by clicking on “Delete my profile”.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>20. Responsibilities</span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User is responsible for having the services and equipment
            necessary for browsing the Internet and accessing the Platform. In
            the event of any incident or difficulty in accessing the Platform,
            the User can inform DocuDoc through the contact channels made
            available to the User, which will proceed to analyze the incident
            and give instructions to the User about how to resolve it within the
            deadline. briefest possible.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc does not control nor is it responsible for the content
            posted by Users through the Platform, and Users are solely
            responsible for the legal adequacy of said content.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc will not be liable in the event of service interruptions,
            connection errors, lack of availability or deficiencies in the
            Internet access service, nor for interruptions of the Internet
            network or for any other reason beyond its control.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc is not responsible for any security errors that may occur or
            for any damage that may be caused to the User's computer system
            (hardware and software), to the files or documents stored therein,
            as a result of:
          </span>
        </p>
        <ul>
          <li>
            The presence of a virus in the User's computer system or mobile
            terminal that is used to connect to the services and contents of the
            Platform;
          </li>
          <li>A browser malfunction;</li>
          <li>From the use of non-updated versions of it.</li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc is not responsible for damages caused by malpractice, bad
            advice or poor management of contracted service providers. In the
            event that malpractice, bad advice or poor management by a service
            provider contracted through DocuDoc causes any damage, said damage
            will be passed on exclusively to the responsible service provider
            and in no case to DocuDoc. .
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            21. Responsibility for content
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc has no obligation to control and does not control the use
            that Users make of the Platform and, consequently, does not
            guarantee that Users use the Platform in accordance with the
            provisions of these General Conditions of Use, nor that they use it
            diligently. and/or prudent thereof. DocuDoc has no obligation to
            verify and does not verify the identity of the Users, nor the
            veracity, validity, completeness and/or authenticity of the data
            they provide.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc will not verify the services that may be collected or
            shipped through orders placed through the Platform. For this reason,
            both the User and the service provider exempt DocuDoc from any
            responsibility that may arise from the provision and/or
            transportation of services that require specific permits or
            licenses, as well as services prohibited by any applicable
            regulations, described , without limitation, in clause 15 above. In
            the event that DocuDoc becomes aware that the User and/or the
            service provider uses the Platform to transport prohibited services,
            DocuDoc may initiate an internal investigation to clarify the facts,
            take the necessary legal measures and/or block the User or
            corresponding service provider until the completion of the possible
            investigation. Among the measures available to DocuDoc is making the
            data and information of Users and service providers available to the
            authorities.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc excludes any liability for damages of any kind that may be
            due to the illicit use of the Platform by the Users or that may be
            due to the lack of veracity, validity, completeness and/or
            authenticity of the information that the Users provide to other
            Users about themselves and, in particular, although not exclusively,
            for damages of any kind that may be due to the impersonation of a
            third party carried out by a User in any type of communication made
            to through the Platform.&nbsp; In particular, DocuDoc is not
            responsible for the use of the Platform or for any orders that a
            third party may place from the User's account.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Without prejudice to the foregoing, DocuDoc reserves the right to
            limit, totally or partially, access to the Platform to certain
            Users, as well as to cancel, suspend, block or eliminate certain
            types of content, through the use of technological instruments
            suitable for the effect, if there is effective knowledge or there
            are indications that the activity or stored information is illegal
            or that it damages the property or rights of a third party. In this
            sense, DocuDoc may establish the necessary filters in order to
            prevent illicit or harmful content from being dumped onto the
            network through the service. The making available by Users of
            content through the Platform will imply the transfer in favor of
            DocuDoc of all exploitation rights derived from the content provided
            on the Platform.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            22. "No guarantee" clause in messages or payments
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc does not offer any guarantee regarding authenticity,
            accuracy, novelty, reliability, legality or non-violation of third
            party rights by the service providers. In this sense, Users state
            that they understand that DocuDoc is a technological platform, whose
            main activity is intermediation, that is, it brings Users and
            service providers into contact, so it does not assume any
            responsibility derived from the information provided by the
            providers. of services or for any damages or losses that they may
            eventually suffer due to a breach of these General Conditions of
            Use. When placing the order through the Platform and its acceptance
            by the service provider at the beginning of the provision of the
            service, it will be established a direct relationship between the
            User and the service provider, with DocuDoc being unrelated to it,
            providing only the Platform so that the Parties can communicate and
            place as many orders as necessary. For this reason, DocuDoc will
            never be responsible for the availability of the service providers
            or for the adequate and satisfactory performance of the services by
            them.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc does not make any confirmation, nor does it validate any
            service provider or their identity or background. Without prejudice
            to the foregoing, DocuDoc may occasionally carry out additional
            checks and implement procedures intended to help verify or verify
            the identities of service providers. In this sense, when a User
            reaches the status of service provider, it simply indicates that
            said User has completed the corresponding registration process and
            has accepted these General Conditions of Use and any other
            particular conditions that may be applicable. It does not imply
            certification or endorsement of its reliability, suitability and/or
            security by DocuDoc.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            For this reason, DocuDoc recommends that Users always use common
            sense and all their attention when delegating a message to a
            specific service provider.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            By being members of the Platform, Users accept that any legal
            responsibility that they intend to exercise as a consequence of
            actions or omissions of other Users of the Platform or third parties
            will be limited to said Users or third parties, and no liability
            action will be taken against DocuDoc.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            23. Update and Modification of the Platform
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc reserves the right to modify, at any time and without prior
            notice, these General Conditions of Use, the Privacy Policy and the
            Cookies Policy. Users must carefully read these General Conditions
            when accessing the Platform. In any case, acceptance of the General
            Conditions will be a prior and essential step to access the services
            and content available through the DocuDoc Platform.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Likewise, DocuDoc reserves the right to carry out, at any time and
            without prior notice, updates, modifications or elimination of
            information contained in its Platform in the configuration and
            presentation of it and the conditions of access, without assuming
            any responsibility for it. DocuDoc does not guarantee the absence of
            interruptions or errors in access to the Platform or its content,
            nor that it is always updated, however, DocuDoc will carry it out,
            provided that there are no causes that make it impossible or
            difficult to execute, and as soon as you are aware of errors,
            disconnections or lack of updating of the content, all those tasks
            aimed at correcting the errors, reestablishing communication and
            updating the content.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            24. Intellectual Property
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc is the owner or licensee of all intellectual and industrial
            property rights included in the Platform as well as the content
            accessible through it. The intellectual property rights of the
            Platform, as well as: texts, images, graphic design, navigation
            structure, information and content collected therein, are the
            property of DocuDoc, except those that are legally acquired from the
            public through the Internet.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Even as described above, DocuDoc may not be the owner or licensee of
            content such as names or images, among others, of companies with
            which DocuDoc has no commercial relationship. In these cases,
            DocuDoc acquires the content from publicly accessible sources and in
            no case will it be understood that DocuDoc has a relationship with
            any right owned by DocuDoc.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Authorization to the User to access the Platform does not imply
            waiver, transmission, license or total or partial transfer of
            intellectual or industrial property rights by DocuDoc. It is not
            permitted to delete, circumvent or manipulate in any way the
            contents of the DocuDoc Platform. Likewise, it is prohibited to
            modify, copy, reuse, exploit, reproduce, publicly communicate, make
            second or subsequent publications, upload files, send by mail,
            transmit, use, process or distribute in any way all or part of the
            contents included on the Platform. of DocuDoc for public or
            commercial purposes, if there is no express written authorization
            from DocuDoc or, where applicable, the owner of the corresponding
            rights.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The User who proceeds to share any type of content through the
            Platform ensures that he or she has the necessary rights to do so,
            exempting DocuDoc from any responsibility for the content and
            legality of the information offered. The provision of content by
            Users through the Platform will imply the transfer to DocuDoc, free
            of charge, and to the maximum extent permitted by current
            legislation, of the exploitation rights of intellectual or
            industrial property derived from such content.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            25. Independence of Clauses
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            If any of the clauses of these General Conditions are null and void
            or voidable, it will be considered non-existent. Said declaration of
            nullity will not invalidate the rest of the clauses, which will
            maintain their validity and effectiveness between the Parties.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            26. Applicable Legislation
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            The relationship between DocuDoc and the User will be governed and
            interpreted in accordance with the General Conditions, which in
            terms of interpretation, validity and execution will be governed by
            Spanish legislation; and any controversy will be submitted to the
            Courts of Madrid.
          </span>
        </p>
      </div>
    </div>
  );
}
