import React from "react";

export default function CookiesPolicySpanish() {
  return (
    <div
      className="container"
      style={{ marginBottom: "160px", marginTop: "160px" }}
    >
      <h2 style={{ textAlign: "center" }}>Política de Cookies</h2>
      <p style={{ textAlign: "center" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc informa acerca del uso de las Cookies en su plataforma online.
        </span>
      </p>
      <p style={{ textAlign: "center" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>Última actualización: 20 de junio de 2024</em>
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Bienvenida/o a la POLÍTICA DE COOKIES de la página web de la entidad
          DOCUDOC APP S.L., provista de NIF B19738905, donde te explicaremos en
          un lenguaje claro y sencillo todas las cuestiones necesarias para que
          puedas tener el control sobre ellas en base a tus decisiones
          personales.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 32, 96)" }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          1 -QUÉ SON LAS COOKIES Y PARA QUÉ LAS USAMOS?
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Una cookie o galleta informática es un pequeño archivo de información
          que se guarda en tu ordenador, “smartphone” o tableta cada vez que
          visitas nuestra página web.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          En principio, una cookie es inofensiva: no contiene virus, troyanos,
          gusanos, etc. que puedan dañar tu terminal, pero sí tiene cierto
          impacto sobre tu derecho a la protección de tus datos personales, pues
          recoge determinada información concerniente a tu persona (hábitos de
          navegación, identidad, preferencias, etc.).
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Es por ello que, en base a lo establecido en la normativa aplicable
          (LSSI y normativa vigente de protección de datos personales), la
          activación de determinados tipos de cookies necesitará de tu
          autorización previa.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Antes de ello, te daremos alguna información adicional que te ayudará
          a una mejor toma de decisiones al respecto:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          1.&nbsp; &nbsp; &nbsp; Las cookies pueden ser de varios tipos en
          función de su finalidad:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Las cookies técnicas son necesarias para
          que nuestra página web pueda funcionar, no necesitan de tu
          autorización y son las únicas que tenemos activadas por defecto.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; El resto de cookies sirven para mejorar
          nuestra página, para personalizarla en base a tus preferencias, o para
          poder mostrarte publicidad ajustada a tus búsquedas, gustos e
          intereses personales. Puedes aceptar todas estas cookies pulsando el
          botón ACEPTAR, rechazarlas pulsando el botón RECHAZAR o configurarlas
          clicando en el apartado CONFIGURACIÓN DE COOKIES.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          2.&nbsp; &nbsp; &nbsp; Algunas cookies son nuestras (las denominaremos
          cookies propias) y otras pertenecen a empresas externas que prestan
          servicios para nuestra página web (las denominaremos cookies de
          terceros: un ejemplo podrían ser las cookies de proveedores externos
          como Google). En este sentido, es importante que sepas que algunos de
          dichos proveedores externos pueden estar ubicados fuera de España.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          A nivel mundial, no todos los países tienen un mismo nivel de
          protección de datos, existiendo algunos países más seguros que otros
          (por ejemplo, la Unión Europea es un entorno seguro para tus datos).
          Nuestra política es recurrir a proveedores confiables que, con
          independencia de que se encuentren o no ubicados en la Unión Europea,
          hayan adoptado las garantías adecuadas para la protección de tu
          información personal. No obstante, en el apartado denominado ¿QUÉ TIPO
          DE COOKIES SE UTILIZAN ACTUALMENTE EN NUESTRA PÁGINA WEB?, puedes
          consultar las distintas Políticas de privacidad y protección de datos
          de cada uno de los referidos proveedores externos, a fin de tomar una
          decisión consciente sobre la activación o no de las cookies de
          terceros que utiliza nuestra página web.&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.&nbsp; &nbsp; &nbsp; Finalmente, indicarte que, en función del plazo
          de tiempo que permanecen activas, las cookies pueden ser de dos tipos:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Cookies de sesión: expiran
          automáticamente cuando terminas la sesión en tu ordenador,
          “smartphone” o tableta. Suelen emplearse para conservar la información
          necesaria mientras se te presta un servicio en una sola ocasión.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Cookies persistentes: permanecen
          almacenadas en tu ordenador, “smartphone” o tableta durante un periodo
          determinado, que puede variar desde unos minutos hasta varios años.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 32, 96)" }}>· &nbsp; &nbsp; &nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          ¿QUÉ TIPO DE COOKIES PODEMOS UTILIZAR EN NUESTRA PÁGINA WEB?
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Cookies Técnicas:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Las cookies técnicas son estrictamente necesarias para que nuestra
          página web funcione y puedas navegar por la misma. Este tipo de
          cookies son las que, por ejemplo, nos permiten identificarte, darte
          acceso a determinadas partes restringidas de la página si fuese
          necesario, o recordar diferentes opciones o servicios ya seleccionados
          por ti, como tus preferencias de privacidad. Por ello, están activadas
          por defecto, no siendo necesaria tu autorización al respecto.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          A través de la configuración de tu navegador, puedes bloquear o
          alertar de la presencia de este tipo de cookies, si bien dicho bloqueo
          afectará al correcto funcionamiento de las distintas funcionalidades
          de nuestra página web.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Cookies de Análisis:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Las cookies de análisis nos permiten estudiar la navegación de los
          usuarios de nuestra página web en general (por ejemplo, qué secciones
          de la página son las más visitadas, qué servicios se usan más y si
          funcionan correctamente, etc.).
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          A partir de la información estadística sobre la navegación en nuestra
          página web, podemos mejorar tanto el propio funcionamiento de la
          página como los distintos servicios que ofrece. Por tanto, estas
          cookies no tienen una finalidad publicitaria, sino que únicamente
          sirven para que nuestra página web funcione mejor, adaptándose a
          nuestros usuarios en general. Activándolas contribuirás a dicha mejora
          continua.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Puedes activar o desactivar estas cookies marcando la casilla
          correspondiente, estando desactivadas por defecto.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Cookies de Funcionalidad y
          Personalización:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Las cookies de funcionalidad nos permiten recordar tus preferencias,
          para personalizar a tu medida determinadas características y opciones
          generales de nuestra página web, cada vez que accedas a la misma (por
          ejemplo, el idioma en que se te presenta la información, las secciones
          marcadas como favoritas, tu tipo de navegador, etc.).
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Por tanto, este tipo de cookies no tienen una finalidad publicitaria,
          sino que activándolas mejorarás la funcionalidad de la página web (por
          ejemplo, adaptándose a tu tipo de navegador) y la personalización de
          la misma en base a tus preferencias (por ejemplo, presentando la
          información en el idioma que hayas escogido en anteriores ocasiones),
          lo cual contribuirá a la facilidad, usabilidad y comodidad de nuestra
          página durante tu navegación.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Puedes activar o desactivar estas cookies marcando la casilla
          correspondiente, estando desactivadas por defecto.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Cookies de Publicidad:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Las cookies de publicidad nos permiten la gestión de los espacios
          publicitarios incluidos en nuestra página web en base a criterios como
          el contenido mostrado o la frecuencia en la que se muestran los
          anuncios.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Así por ejemplo, si se te ha mostrado varias veces un mismo anuncio en
          nuestra página web, y no has mostrado un interés personal haciendo
          clic sobre él, este no volverá a aparecer. En resumen, activando este
          tipo de cookies, la publicidad mostrada en nuestra página web será más
          útil y diversa, y menos repetitiva.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Puedes activar o desactivar estas cookies marcando la casilla
          correspondiente, estando desactivadas por defecto.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Cookies de Publicidad Comportamental:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Las cookies de publicidad comportamental nos permiten obtener
          información basada en la observación de tus hábitos y comportamientos
          de navegación en la web, a fin de poder mostrarte contenidos
          publicitarios que se ajusten mejor a tus gustos e intereses
          personales.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Para que lo entiendas de manera muy sencilla, te pondremos un ejemplo
          ficticio: si tus últimas búsquedas en la web estuviesen relacionadas
          con literatura de suspense, te mostraríamos publicidad sobre libros de
          suspense.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Por tanto, activando este tipo de cookies, la publicidad que te
          mostremos en nuestra página web no será genérica, sino que estará
          orientada a tus búsquedas, gustos e intereses, ajustándose por tanto
          exclusivamente a ti.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Puedes activar o desactivar estas cookies marcando la casilla
          correspondiente, estando desactivadas por defecto.
        </span>
      </p>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <span style={{ color: "rgb(74, 74, 74)" }}>
                  2.- REGULACIÓN DE COOKIES
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          De acuerdo con los requisitos de GDPR, con las diferentes regulaciones
          locales vigentes, con los requisitos de las Resoluciones del Tribunal
          de Justicia de las Comunidades Europeas y con las diferentes
          Resoluciones de las autoridades de Protección de Datos:
        </span>
      </p>
      <ol>
        <li>
          El usuario debe tener acceso a una información clara y detallada sobre
          las actividades de procesamiento de datos realizadas mediante el uso
          de cookies&nbsp;
        </li>
        <li>
          El usuario debe tener la posibilidad de i) rechazar todas las cookies,
          ii) habilitarlas o rechazarlas caso por caso mediante una acción
          expresa.
        </li>
      </ol>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Por lo tanto, DocuDoc le proporciona, como usuario de nuestro sitio
          web y/o aplicación, la siguiente información:
        </span>
      </p>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <span style={{ color: "rgb(74, 74, 74)" }}>
                  3.- CONOZCA LAS ACTIVIDADES DE NUESTRAS COOKIES
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          1. Identidad de nuestro controlador de datos y cookies, así como
          nuestro Delegado de Proteccion de Datos (DPD)
        </span>
      </p>
      <ul>
        <li>DocuDoc por cuenta propia (en adelante “DocuDoc”)</li>
        <li>Responsable: DPO de DocuDoc, info@docudocapp.com</li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          2. Cookies que usamos y propósitos
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc puede usar diferentes tipos de cookies, que el usuario puede
          verificar y administrar libremente al acceder a la Web. Las cookies se
          describen a continuación:
        </span>
      </p>
      <ul>
        <li>
          Cookies propias: las cookies propias son aquellas gestionadas por
          DocuDoc.
        </li>
      </ul>
      <ul>
        <li>
          Cookies de terceros<em>:</em>
          aquellas cookies que son gestionadas por un equipo o dominio no
          gestionado por DocuDoc.
        </li>
      </ul>
      <ul>
        <li>
          Cookies de sesión: Son un tipo de
          <em>cookies diseñadas para</em>recabar y almacenar datos mientras el
          usuario accede a una página Web. Estas cookies no se almacenan en el
          terminal del Usuario cuando expira la sesión o se cierra el navegador.
        </li>
      </ul>
      <ul>
        <li>
          Cookies funcionales: Son un tipo de
          <em>cookies</em>en el que los datos siguen almacenados en el terminal
          y pueden ser accedidos y tratados durante un periodo definido por el
          responsable de la cookie, y que puede ir de unos minutos a varios
          años. Las cookies funcionales permiten que el sitio web funcione
          correctamente y, por lo tanto, son necesarias y no opcionales. Sin
          embargo, el usuario puede eliminarlos en cualquier momento. Además,
          limitar sus funciones dependerá del navegador utilizado por el
          usuario.&nbsp;
        </li>
      </ul>
      <ul>
        <li>
          Cookies técnicas: Son aquéllas que permiten al usuario la navegación a
          través de una página Web, plataforma o aplicación y la utilización de
          las diferentes opciones o servicios que en ella existan como, por
          ejemplo, controlar el tráfico y la comunicación de datos, identificar
          la sesión, acceder a partes de acceso restringido, recordar los
          elementos que integran un pedido, realizar el proceso de compra de un
          pedido, realizar la solicitud de inscripción o participación en un
          evento, utilizar elementos de seguridad durante la navegación,
          almacenar contenidos para la difusión de videos o sonido o compartir
          contenidos a través de redes sociales.
        </li>
      </ul>
      <ul>
        <li>
          Cookies de personalización: Son aquéllas que permiten al usuario
          acceder al servicio con algunas características de carácter general
          predefinidas en función de una serie de criterios en el terminal del
          usuario como por ejemplo serian el idioma, el tipo de navegador a
          través del cual accede al servicio, la configuración regional desde
          donde accede al servicio, etc.
        </li>
      </ul>
      <ul>
        <li>
          Cookies de análisis: Son aquéllas que permiten al responsable de las
          mismas, el seguimiento y análisis del comportamiento de los usuarios
          de los sitios Web a los que están vinculadas. La información recogida
          mediante este tipo de cookies se utiliza en la medición de la
          actividad de los sitios Web, aplicación o plataforma y para la
          elaboración de perfiles de navegación de los usuarios de dichos
          sitios, aplicaciones y plataformas, con el fin de introducir mejoras
          en función del análisis de los datos de uso que hacen los usuarios del
          servicio.
        </li>
      </ul>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <span style={{ color: "rgb(74, 74, 74)" }}>
                  4.- GESTIONA TUS COOKIES
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Al navegar por este sitio web por primera vez, se mostrará un banner
          de cookies, que permitirá al Usuario elegir qué cookies se guardan en
          su navegador:
        </span>
      </p>
      <ul>
        <li>
          Para aceptarlos todos y seguir navegando: el banner de cookies no se
          volverá a mostrar, y se supondrá que el Usuario acepta la presente
          Política de Cookies.
        </li>
      </ul>
      <ul>
        <li>
          Deshabilitar categorías seleccionadas de cookies (excepto aquellas que
          son esenciales para que funcione el sitio web).
        </li>
      </ul>
      <ul>
        <li>
          Para modificar la configuración siguiendo las instrucciones a
          continuación y aceptando deshabilitar algunas de las cookies o
          simplemente obtener más información sobre las cookies y consultar nues
          <span style={{ color: "rgb(0, 0, 0)" }}>
            tra Política de Cookies.
          </span>
        </li>
      </ul>
      <ul>
        <li>
          En cualquier momento, podrá acceder y administrar el consentimiento de
          las cookies reales y verificar las cookies reales utilizadas por
          DocuDoc. Se podrá acceder a la información en nuestra Privacidad de
          Cookies.
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Asimismo, la reproducción de contenido que puede redirigir al usuario
          a través de los enlaces insertados en diferentes formatos en el sitio
          web o los medios de propiedad de DocuDoc puede contener cookies de los
          propietarios de dicho contenido redirigido. En este caso, DocuDoc no
          se hace responsible de la información ni de las políticas
          proporcionadas al usuario ni de las cookies utilizadas por terceros.
        </span>
      </p>
      <ul>
        <li>
          En caso de que el usuario no consienta el procesamiento de dicha
          información, puede modificar la configuración de su navegador,
          siguiendo a tal efecto la información y los consejos incluidos en los
          enlaces a continuación, en relación con la configuración de cookies:
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Para sitios webs, siga los enlaces:&nbsp;
        </span>
      </p>
      <ul>
        <li>
          <a
            target="_blank"
            href="https://support.google.com/chrome/answer/95647?hl=es"
          >
            <span style={{ color: "rgb(17, 85, 204)" }}>Chrome</span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.apple.com/kb/ph21411?locale=es_ES"
          >
            <span style={{ color: "rgb(17, 85, 204)" }}>Safari</span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="http://help.opera.com/Windows/12.10/es-ES/cookies.html"
          >
            <span style={{ color: "rgb(17, 85, 204)" }}>Opera</span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
          >
            <span style={{ color: "rgb(17, 85, 204)" }}>Explorer</span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.mozilla.org/es/kb/configuracion-privacidad-historial-navegacion-funcion-no-quiero-ser-rastreado?redirectlocale=es&as=u&redirectslug=configuracion-de-la-privacidad-el-historial-de-nav"
          >
            <span style={{ color: "rgb(17, 85, 204)" }}>Firefox</span>
          </a>
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Para teléfonos móviles, siga los enlaces:&nbsp;
        </span>
      </p>
      <ul>
        <li>
          Para Android: Menu &gt; More &gt; Settings &gt; Privacy Settings
        </li>
        <li>
          Para
          <a target="_blank" href="https://support.apple.com/es-es/HT201265">
            <span style={{ color: "rgb(17, 85, 204)" }}>Safari (IOS).</span>
          </a>
        </li>
        <li>
          Para&nbsp;
          <a
            target="_blank"
            href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"
          >
            <span style={{ color: "rgb(17, 85, 204)" }}>Windows</span>
          </a>
        </li>
        <li>
          Si el usuario está utilizando otro navegador, puede obtener más
          información sobre cómo configurar la instalación de Cookies a través
          de la sección de ayuda o asistencia de ese navegador. En caso de que
          el Usuario necesite ayuda para llevar a cabo la configuración de
          cookies con el navegador.
        </li>
      </ul>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <span style={{ color: "rgb(74, 74, 74)" }}>
                  5.- OTRA INFORMACIÓN RELEVANTE
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc no se hace responsable del uso y uso de cookies que terceros
          realicen fuera de los servicios ofrecidos por los canales y medios de
          propiedad de DocuDoc.
        </span>
      </p>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <span style={{ color: "rgb(74, 74, 74)" }}>
                  6.- PARA OBTENER MÁS INFORMACIÓN
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Los usuarios pueden contactar a DocuDoc a la siguiente dirección de
          correo electrónico: info@DocuDocapp.com para obtener más información
          sobre las Cookies.
        </span>
      </p>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <span style={{ color: "rgb(74, 74, 74)" }}>
                  7.- PARA CAMBIAR LA CONFIGURACIÓN DE COOKIES
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Para cambiar la configuración de las cookies seleccionadas, acceda al
          siguiente enlace:&nbsp;
        </span>
      </p>
    </div>
  );
}
