import React from "react";
import labels from "../config/Labels";

function OurMissionSpanish() {
  return (
    <div className="ins-float-icon-box-content">
      <h4 className=" mt-0 ">{labels.ourMission}</h4>
      <div className="last-paragraph-no-margin">
        <p className="ins-float-icon-box-text  default ">
          <p className="ins-float-icon-box-text  default ">
            DocuDoc es la primera app móvil que ofrece gestiones y servicios
            legales prestados por abogados, asesorías y gestorías de forma
            sencilla, rápida y transparente al mejor precio.
          </p>
        </p>

        <p className="ins-float-icon-box-text  default ">
          En pocos clicks conseguirás exactamente el servicio legal que estás
          buscando con precios y tiempos transparentes y podrás contratar los
          abogados, asesores y gestores mejor valorados de España.
        </p>

        <p className="ins-float-icon-box-text  default ">
          DocuDoc ofrece servicios a particulares, autónomos y empresas en las
          ramas legal, fiscal, administrativas, de extranjería y de tráfico
          entre otras.
        </p>
      </div>
      <div className="last-paragraph-no-margin"></div>
    </div>
  );
}
export default OurMissionSpanish;
