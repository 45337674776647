// import { Modal } from 'bootstrap';
import React from "react";
import { Button, Accordion, Card } from "react-bootstrap";
import FagEnglish from "./Components/FaqEnglish";
import FaqSpanish from "./Components/FaqSpanish";
import ScrollAnimation from "react-animate-on-scroll";
import { Helmet } from "react-helmet";

export default function FAQ({}) {
  const lang = localStorage.getItem("primaryLanguage");
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      {lang === "english" ? <FagEnglish /> : <FaqSpanish />}
    </>
  );
}
