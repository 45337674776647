import React, { useState, useEffect, createContext } from "react";
import {
  Modal,
  ModalBody,
  Dropdown,
  Col,
  Row,
  Container,
} from "react-bootstrap";
import { Images } from "../../constant/images";
import { SpecificServiceButton } from "../../component";
import {
  Get_Services_By_Branch_Id,
  Get_Branches,
  Get_Services_by_branchId_And_Services_Area_Id,
  Get_Services_by_Branch_id_And_Service_Area_id_and_Beneficiary_id,
} from "../../config/api";
import { useHistory } from "react-router-dom";
import ToastComponent from "../../component/toast";
import { map } from "jquery";
import { useParams } from "react-router";
import ServiceAreasMenu from "../../component/serviceAreasMenu";
import BranchesMenu from "../../component/branchesMenu";
import ScrollAnimation from "react-animate-on-scroll";
// import "./singleServiceStyle.css";
import labels from "../../config/Labels";
import { Helmet } from "react-helmet";
export default function SecondLevelServices(props) {
  let brancheId = localStorage.getItem("branchId");
  let serviceAreaId =
    localStorage.getItem("serviceAreaId") ||
    "bd4b5a26-521d-4904-9236-e1772ba63034";
  let benId = localStorage.getItem("benId");
  let lang = localStorage.getItem("primaryLanguage");
  const history = useHistory();

  const [showMessagePopup, setShowMessagePopup] = React.useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [services, setServices] = useState([]);
  const [dropdownName, setDropDownName] = useState(
    props?.location?.state?.acticeFilter || "relevance"
  );
  const [OrignalBranches, setOrignalBranches] = useState([]);
  const [branches, setBranches] = useState([]);
  const [activeFilter, setActiveFilter] = useState({
    id: "70ead172-1c3e-424d-b250-061e4f2decd5",
    name: "relevance",
    type: "priority",
  });
  const [name, setName] = useState(branches?.map((item) => item?.name));
  const [image, setimage] = useState(branches?.map((item) => item?.name));
  const [isLoading, setIsLoading] = useState(false);
  const [activeBranchId, setActiveBranchId] = useState(
    props?.location?.state?.branch_id
  );
  const [activeServiceId, setActiveServviceId] = useState(
    props?.location?.state?.serviceAreaId
  );
  const [activeBeneId, setActiveBeneId] = useState(
    props?.location?.state?.benificiaryId
  );
  // for 2nd level branch
  const [secondLevel, setSecondLevel] = useState(props?.location?.state?.level);

  const [branchName, setBranchName] = useState();

  const params = useParams();

  let branchesBack = localStorage.getItem("branches");

  branchesBack = JSON.parse(branchesBack);
  const dropdown = [
    {
      id: "70ead172-1c3e-424d-b250-061e4f2decd5",
      name: "relevance",
      type: "priority",
    },
    {
      id: 1,
      name: "alphabetically",
      type: "alphabet",
    },

    {
      id: "65e5f162-4af8-4442-a55a-e8cbbf09bb70",
      name: "relevanceForSelf",
      type: "priority",
    },
    {
      id: "0e6e1528-78bc-45cb-a222-278243f10810",
      name: "relevanceForBusiness",
      type: "priority",
    },
  ];

  useEffect(() => {
    localStorage.setItem("benId", activeFilter?.id);
    if (activeFilter?.name == "alphabetically") {
      getServicesByBranchId(
        brancheId || props?.match?.params?.id,
        serviceAreaId
      );
    } else {
      getServicebranchIdServiceIdBeneficiaryId(
        activeBranchId,
        serviceAreaId,
        activeFilter?.id
      );
    }
  }, [activeFilter, activeBranchId]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (activeBeneId !== "") {
      Get_Services_by_Branch_id_And_Service_Area_id_and_Beneficiary_id(
        activeBranchId,
        serviceAreaId,
        activeBeneId,
        (value) => setMessage(value),
        (value) => setShowToast(value),
        (value) => setSuccess(value),
        (value) => {
          setServices(value);
        },
        (value) => setIsLoading(value)
      );
    } else {
      Get_Services_by_branchId_And_Services_Area_Id(
        activeBranchId,
        serviceAreaId,
        (value) => setMessage(value),
        (value) => setShowToast(value),
        (value) => setSuccess(value),
        (value) => {
          setServices(value);
        },
        (value) => setIsLoading(value)
      );
    }
    setDropDownName(props?.location?.state?.acticeFilter);
  }, [activeBeneId]);

  const BranchName = (value) => {
    setBranchName(value);
  };

  const getServicebranchIdServiceIdBeneficiaryId = (
    Branch_id,
    Service_area_id,
    beneId
  ) => {
    Get_Services_by_Branch_id_And_Service_Area_id_and_Beneficiary_id(
      Branch_id,
      Service_area_id,
      beneId,
      (value) => setMessage(value),
      (value) => setShowToast(value),
      (value) => setSuccess(value),
      (value) => {
        setServices(value);
      },
      (value) => setIsLoading(value)
    );
  };

  const getServicesByBranchId = (Branch_id, Service_area_id) => {
    Get_Services_by_branchId_And_Services_Area_Id(
      Branch_id,
      Service_area_id,
      (value) => setMessage(value),
      (value) => setShowToast(value),
      (value) => setSuccess(value),
      (value) => {
        let currentLanguage = localStorage.getItem("primaryLanguage");
        const sortedServices = value?.sort(function (a, b) {
          return a?.[
            currentLanguage == "english" ? "name" : "name_sp"
          ].localeCompare(
            b?.[currentLanguage == "english" ? "name" : "name_sp"]
          );
        });

        setServices(sortedServices || []);
      },
      (value) => setIsLoading(value)
    );
  };

  const getSecondLevelBranchServices = (Branch_id, Service_area_id) => {
    Get_Services_by_branchId_And_Services_Area_Id(
      Branch_id,
      Service_area_id,
      (value) => setMessage(value),
      (value) => setShowToast(value),
      (value) => setSuccess(value),
      (value) => {
        setServices(value);
      },
      (value) => setIsLoading(value)
    );
  };

  const closeToast = () => {
    setShowToast(false);
  };

  // const filterBranchesByBeneficiary = (value) => {
  //   let filteredBranches = [...services]?.filter(
  //     (item) => item?.beneficiary?.name === value
  //   );

  //   setBranches(filteredBranches);
  //   setDropDownName(value);
  // };
  // const filterBranchesByBeneficiaryAlphabetically = (value) => {
  //   let filteredBranches = [...services]?.filter((item) => item?.name);

  //   setBranches(filteredBranches);
  //   setDropDownName(value);
  // };

  return (
    <>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <Row
        className="s-pt-30 s-pb-3 service-item2 ls  pt-60 row "
        style={{ minHeight: "100vh" }}
        data-animation="fadeInUp"
      >
        <Col lg={1} xs={12} sm={12} md={8}></Col>

        <Col sm={12} lg={2} xs={12} md={8} className=" pt-60">
          <h4 className="ml-0 mt-4 custom-font">{labels.branches}</h4>
          <div className=" d-lg-block divider-20 p-10"></div>

          {typeof props?.location?.state?.branches != "undefined" &&
            localStorage.setItem(
              "branches",
              JSON.stringify(props?.location?.state?.branches)
            )}

          <BranchesMenu
            branches={
              props?.location?.state?.branches?.length
                ? props?.location?.state?.branches
                : typeof props?.location?.state?.branches != "undefined"
                ? props?.location?.state?.branches
                    ?.filter((item) => item?.level != "2")
                    ?.map((item) => item)
                : branchesBack
                    ?.filter((item) => item?.level != "2")
                    ?.map((item) => item)
            }
            GetServicesByBranchId={(value, abc) =>
              getServicesByBranchId(value, abc)
            }
            GetServicebranchIdServiceIdBeneficiaryId={() =>
              getServicebranchIdServiceIdBeneficiaryId(
                activeBranchId,
                serviceAreaId,
                activeFilter?.id
              )
            }
            setActiveBranchId={setActiveBranchId}
            currentActiveFilter={activeFilter}
            BranchName={BranchName}
          />
          {/* <BranchesMenu
            branches={
              props?.location?.state?.branches?.length
                ? props?.location?.state?.branches
                : typeof props?.location?.state?.branches != "undefined"
                ? props?.location?.state?.branches
                    ?.filter(
                      (item) =>
                        item?.level == "2" &&
                        props?.location?.state?.branch_id == item?.branch_id
                    )
                    ?.map((item) => item)
                : branchesBack
                    ?.filter((item) => item?.level == "2")
                    ?.map((item) => item)
            }
            GetServicesByBranchId={(value, abc) =>
              getServicesByBranchId(value, abc)
            }
            BranchName={BranchName}
          /> */}
        </Col>

        <Col sm={12} lg={8} xs={12} md={12} className=" p-60">
          <div className=" d-flex justify-content-between">
            <div className="d-flex justify-content-between align-items-center gap-3 mt-15">
              <div>
                <img
                  src={Images.backArrow.default}
                  style={{ width: "40px", height: "40px" }}
                  className="cursorPointer"
                  onClick={() => {
                    history?.goBack();
                  }}
                />
              </div>
              <div>
                <h4 className="custom-font">{labels.services}</h4>
              </div>
            </div>
            <div className="align-items-center d-flex justify-content-end singleServices">
              <p className="mx-2 mt-10" style={{ color: "#46bcff" }}>
                {labels?.[dropdownName]}
              </p>
              <Dropdown>
                <Dropdown.Toggle></Dropdown.Toggle>

                <Dropdown.Menu>
                  {dropdown?.map((item) => (
                    <Dropdown.Item
                      onClick={() => {
                        setActiveFilter(item);
                        setDropDownName(item?.name);
                        // localStorage.setItem("benId", item?.id);
                        // if (item.type == dropdown[0].type) {
                        //   getServicesByBranchId(brancheId, serviceAreaId);
                        // } else {
                        //   getServicebranchIdServiceIdBeneficiaryId(
                        //     brancheId,
                        //     serviceAreaId,
                        //     item.id
                        //   );
                        // }
                      }}
                    >
                      {labels?.[item?.name]}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className=" d-lg-block divider-20"></div>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
            <div className=" mb-40">
              <div className="tab-pane active show" id="tabs-1" role="tabpanel">
                <div className="row">
                  {showToast === true ? (
                    <ToastComponent
                      message={message}
                      toastClose={closeToast}
                      success={success}
                    />
                  ) : (
                    ""
                  )}

                  {activeFilter?.type == "alphabet" &&
                    services?.map((item, index) => {
                      return (
                        <SpecificServiceButton
                          key={index}
                          selected_serviceItem={item}
                          path={`documentaion`}
                          states={item}
                          image={item?.avatar}
                          title={lang == "english" ? item?.name : item?.name_sp}
                          desc={lang == "english" ? item?.info : item?.info_sp}
                          assessment={true}
                          price={item?.original_price}
                          discount={item?.reduced_price}
                          activeFilter={item?.service_id}
                          avatar={item?.avatar}
                          item={item}
                          servicePak={item?.service_pack_type}
                          supplementory={item?.service_supplementary_fees}
                          supplementaryFees={item?.supplementary_fees}
                          branch_id={item?.branch?.branch_id}
                          serviceAreaId={item?.service_area_id}
                          branches={props?.location?.state?.branches || []}
                          serviceCard={true}
                        />
                      );
                    })}

                  {activeFilter?.type !== "alphabet" &&
                    services
                      ?.sort(function (a, b) {
                        return (
                          a?.service_beneficiaries[
                            lang == "spanish"
                              ? dropdownName == "Relevancia"
                                ? 0
                                : dropdownName == "Relevancia para autónomos"
                                ? 1
                                : dropdownName == "Relevancia para negocios"
                                ? 2
                                : lang == "english"
                                ? dropdownName == "Relevance"
                                  ? 0
                                  : dropdownName ==
                                    "Relevance for Self Employed"
                                : 1
                              : 2
                          ]?.priority -
                          b?.service_beneficiaries[
                            lang == "spanish"
                              ? dropdownName == "Relevancia"
                                ? 0
                                : dropdownName == "Relevancia para autónomos"
                                ? 1
                                : dropdownName == "Relevancia para negocios"
                                ? 2
                                : lang == "english"
                                ? dropdownName == "Relevance"
                                  ? 0
                                  : dropdownName ==
                                    "Relevance for Self Employed"
                                : 1
                              : 2
                          ]?.priority
                        );
                      })
                      ?.map((item) => {
                        return (
                          <SpecificServiceButton
                            selected_serviceItem={item}
                            key={item.index}
                            path={`documentaion`}
                            states={item.item}
                            image={item?.avatar}
                            title={
                              lang == "english" ? item?.name : item?.name_sp
                            }
                            desc={
                              lang == "english" ? item?.info : item?.info_sp
                            }
                            assessment={true}
                            price={item?.original_price}
                            discount={item?.reduced_price}
                            activeFilter={item?.service_id}
                            name={item?.name}
                            item={item}
                            servicePak={item?.service_pack_type}
                            supplementory={
                              item?.service_supplementary_fees[0]
                                ?.supplementary_fees
                            }
                            supplementaryFees={item.supplementary_fees}
                            branch_id={item?.branch?.branch_id}
                            branches={props?.location?.state?.branches}
                            serviceCard={true}
                            serviceAreaId={item?.service_area_id}
                          />
                        );
                      })}

                  {secondLevel
                    ?.sort(function (a, b) {
                      return a.priority - b.priority;
                    })
                    .map((t) => {
                      return (
                        <SpecificServiceButton
                          selected_serviceItem={t}
                          path={"service"}
                          image={t.avatar}
                          title={lang === "spanish" ? t.name_sp : t?.name}
                          desc={lang === "spanish" ? t.info_sp : t?.info}
                          branch_id={t.branch_id}
                          // selectedTab={serviceAreaTabs}
                          activeFilter={dropdownName}
                          // serviceAreaId={activeServiceAreaId}
                          // benificiaryId={activeBeneficiaryId}
                          branches={branches}
                          level2={branches
                            ?.filter(
                              (branches) =>
                                branches.level === "2" &&
                                t.branch_id === branches?.parent_branch_id
                            )
                            .map((item) => item)}
                          serviceCard={true}
                        />
                      );
                    })}
                </div>
                {!services.length && (
                  <h4 className="text-center  text-secondary mt-60 ">
                    {isLoading ? labels.loading : ""}
                  </h4>
                )}
              </div>
            </div>
            <Modal
              show={showMessagePopup}
              onHide={() => setShowMessagePopup(false)}
              size="lg"
            >
              <ModalBody className="bg-white p-0 border-radius-10 alignItemsCenter">
                <button
                  type="button"
                  className="close  absolute"
                  data-dismiss="modal"
                  onClick={() => setShowMessagePopup(false)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="richTextContainer">
                  <div className="textAlignCenter mt-20 mb-20">
                    <a
                      className="btn btn-primary"
                      data-toggle="modal"
                      onClick={() => {}}
                    >
                      Submit
                    </a>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </ScrollAnimation>
        </Col>
        <div className=" d-lg-block divider-120"></div>
      </Row>
    </>
  );
}
