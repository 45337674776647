export default function CookiesPolicy() {
  return (
    <div
      className="container"
      style={{ marginBottom: "160px", marginTop: "160px" }}
    >
      <h2 style={{ textAlign: "center" }}>Cookies policy</h2>
      <p style={{ textAlign: "center" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc informs about the use of Cookies on its online platform.
        </span>
      </p>
      <p style={{ textAlign: "center" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>Last update: June 20, 2024</em>
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Welcome to the COOKIES POLICY of the website of the entity DOCUDOC APP
          S.L., provided with NIF B19738905, where we will explain to you in
          clear and simple language all the necessary issues so that you can
          have control over them based on your personal decisions.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 32, 96)" }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          1 -WHAT ARE COOKIES AND WHAT DO WE USE THEM FOR?
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          A cookie or computer cookie is a small information file that is saved
          on your computer, smartphone or tablet every time you visit our
          website.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          In principle, a cookie is harmless: it does not contain viruses,
          trojans, worms, etc. that may damage your terminal, but it does have a
          certain impact on your right to the protection of your personal data,
          since it collects certain information concerning you (browsing habits,
          identity, preferences, etc.).
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          That is why, based on the provisions of the applicable regulations
          (LSSI and current personal data protection regulations), the
          activation of certain types of cookies will require your prior
          authorization.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Before doing so, we will give you some additional information that
          will help you make better decisions in this regard:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          1.&nbsp; &nbsp; &nbsp; Cookies can be of various types depending on
          their purpose:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Technical cookies are necessary for our
          website to function, they do not require your authorization and they
          are the only ones we have activated by default.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; The rest of the cookies serve to improve
          our page, to personalize it based on your preferences, or to show you
          advertising tailored to your searches, tastes and personal interests.
          You can accept all these cookies by pressing the ACCEPT button, reject
          them by pressing the REJECT button or configure them by clicking on
          the COOKIE SETTINGS section.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          2.&nbsp; &nbsp; &nbsp; Some cookies are ours (we will call them our
          own cookies) and others belong to external companies that provide
          services for our website (we will call them third-party cookies: an
          example could be cookies from external providers such as Google). In
          this sense, it is important that you know that some of these external
          providers may be located outside of Spain.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Globally, not all countries have the same level of data protection,
          with some countries being safer than others (for example, the European
          Union is a safe environment for your data). Our policy is to use
          reliable providers who, regardless of whether or not they are located
          in the European Union, have adopted appropriate safeguards for the
          protection of your personal information. However, in the section
          called WHAT TYPE OF COOKIES ARE CURRENTLY USED ON OUR WEBSITE?, you
          can consult the different privacy and data protection policies of each
          of the aforementioned external providers, in order to make a conscious
          decision about whether or not to activate the third-party cookies used
          by our website.&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.&nbsp; &nbsp; &nbsp; Finally, we would like to inform you that,
          depending on the period of time they remain active, cookies can be of
          two types:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Session cookies: they expire
          automatically when you end the session on your computer, smartphone or
          tablet. They are usually used to retain necessary information while
          providing a service to you on a single occasion.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Persistent cookies: they remain stored on
          your computer, smartphone or tablet for a certain period, which can
          vary from a few minutes to several years.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(0, 32, 96)" }}>· &nbsp; &nbsp; &nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          WHAT TYPE OF COOKIES CAN WE USE ON OUR WEBSITE?
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Technical Cookies:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Technical cookies are strictly necessary for our website to function
          and for you to navigate through it. These types of cookies are those
          that, for example, allow us to identify you, give you access to
          certain restricted parts of the page if necessary, or remember
          different options or services already selected by you, such as your
          privacy preferences. Therefore, they are activated by default, and
          your authorization is not necessary in this regard.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Through your browser settings, you can block or alert you to the
          presence of this type of cookies, although said blocking will affect
          the correct functioning of the different functionalities of our
          website.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Analysis Cookies:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Analysis cookies allow us to study user navigation of our website in
          general (for example, which sections of the page are the most visited,
          which services are used the most and if they work correctly, etc.).
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Using statistical information about navigation on our website, we can
          improve both the operation of the website and the different services
          it offers. Therefore, these cookies do not have advertising purposes,
          but only serve to make our website work better, adapting to our users
          in general. By activating them you will contribute to said continuous
          improvement.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          You can activate or deactivate these cookies by checking the
          corresponding box, being deactivated by default.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Functionality and Personalization
          Cookies:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Functionality cookies allow us to remember your preferences, to
          personalize certain general features and options of our website to
          your needs, each time you access it (for example, the language in
          which the information is presented to you, the sections marked as
          favorites, your type of browser, etc.).
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Therefore, these types of cookies do not have advertising purposes,
          but by activating them you will improve the functionality of the
          website (for example, adapting to your type of browser) and
          personalizing it based on your preferences (for example, presenting
          the information in the language you have chosen on previous
          occasions), which will contribute to the ease, usability and comfort
          of our page during your navigation.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          You can activate or deactivate these cookies by checking the
          corresponding box, being deactivated by default.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Advertising Cookies:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Advertising cookies allow us to manage the advertising spaces included
          on our website based on criteria such as the content displayed or the
          frequency with which the ads are displayed.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          For example, if you have been shown the same advertisement several
          times on our website, and you have not shown personal interest by
          clicking on it, it will not appear again. In summary, by activating
          this type of cookies, the advertising displayed on our website will be
          more useful and diverse, and less repetitive.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          You can activate or deactivate these cookies by checking the
          corresponding box, being deactivated by default.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; Behavioral Advertising Cookies:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Behavioral advertising cookies allow us to obtain information based on
          the observation of your browsing habits and behaviors on the web, in
          order to be able to show you advertising content that best suits your
          personal tastes and interests.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          So that you understand it in a very simple way, we will give you a
          fictitious example: if your last searches on the web were related to
          suspense literature, we would show you advertising for suspense books.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Therefore, by activating this type of cookies, the advertising that we
          show you on our website will not be generic, but will be oriented to
          your searches, tastes and interests, therefore adjusting exclusively
          to you.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          You can activate or deactivate these cookies by checking the
          corresponding box, being deactivated by default.
        </span>
      </p>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <span style={{ color: "rgb(74, 74, 74)" }}>
                  2.- REGULATION OF COOKIES
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          In accordance with the requirements of the GDPR, with the different
          local regulations in force, with the requirements of the Resolutions
          of the Court of Justice of the European Communities and with the
          different Resolutions of the Data Protection authorities:
        </span>
      </p>
      <ol>
        <li>
          The user must have access to clear and detailed information about the
          data processing activities carried out through the use of
          cookies&nbsp;
        </li>
        <li>
          The user must have the possibility of i) rejecting all cookies, ii)
          enabling or rejecting them on a case-by-case basis through an express
          action.
        </li>
      </ol>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Therefore, DocuDoc provides you, as a user of our website and/or
          application, the following information:
        </span>
      </p>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <span style={{ color: "rgb(74, 74, 74)" }}>
                  3.- KNOW THE ACTIVITIES OF OUR COOKIES
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          1. Identity of our data and cookie controller, as well as our Data
          Protection Officer (DPD)
        </span>
      </p>
      <ul>
        <li>DocuDoc on your own (hereinafter “DocuDoc”)</li>
        <li>Responsible: DPO of DocuDoc, info@docudocapp.com</li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          2. Cookies we use and purposes
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc may use different types of cookies, which the user can freely
          verify and manage when accessing the Website. The cookies are
          described below:
        </span>
      </p>
      <ul>
        <li>Own cookies: Own cookies are those managed by DocuDoc.</li>
      </ul>
      <ul>
        <li>
          Third party cookies<em>:</em>
          those cookies that are managed by a computer or domain not managed by
          DocuDoc.
        </li>
      </ul>
      <ul>
        <li>
          Session cookies: They are a type of
          <em>cookies designed to</em>collect and store data while the user
          accesses a Web page. These cookies are not stored on the User's
          terminal when the session expires or the browser is closed.
        </li>
      </ul>
      <ul>
        <li>
          Functional cookies: They are a type of
          <em>cookies</em>in which the data continues to be stored in the
          terminal and can be accessed and processed for a period defined by the
          person responsible for the cookie, which can range from a few minutes
          to several years. Functional cookies allow the website to function
          properly and are therefore necessary and not optional. However, the
          user can delete them at any time. Furthermore, limiting its functions
          will depend on the browser used by the user.&nbsp;
        </li>
      </ul>
      <ul>
        <li>
          Technical cookies: These are those that allow the user to navigate
          through a Web page, platform or application and use the different
          options or services that exist there, such as, for example,
          controlling traffic and data communication, identifying the session,
          access restricted access parts, remember the elements that make up an
          order, carry out the purchase process of an order, make a request for
          registration or participation in an event, use security elements
          during navigation, store content for dissemination of videos or sound
          or share content through social networks.
        </li>
      </ul>
      <ul>
        <li>
          Personalization cookies: These are those that allow the user to access
          the service with some general characteristics predefined based on a
          series of criteria on the user's terminal, such as the language, the
          type of browser through which the user accesses the service. , the
          locale from where you access the service, etc.
        </li>
      </ul>
      <ul>
        <li>
          Analysis cookies: These are those that allow the person responsible
          for them to monitor and analyze the behavior of the users of the
          websites to which they are linked. The information collected through
          this type of cookies is used to measure the activity of the websites,
          applications or platforms and to prepare navigation profiles of the
          users of said sites, applications and platforms, in order to introduce
          improvements in based on the analysis of usage data made by users of
          the service.
        </li>
      </ul>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <span style={{ color: "rgb(74, 74, 74)" }}>
                  4.- GESTIONA TUS COOKIES
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          When browsing this website for the first time, a cookie banner will be
          displayed, which will allow the User to choose which cookies are saved
          in their browser:
        </span>
      </p>
      <ul>
        <li>
          To accept them all and continue browsing: the cookie banner will not
          be displayed again, and it will be assumed that the User accepts this
          Cookies Policy.
        </li>
      </ul>
      <ul>
        <li>
          Disable selected categories of cookies (except those that are
          essential for the website to function).
        </li>
      </ul>
      <ul>
        <li>
          To modify your settings by following the instructions below and
          agreeing to disable some of the cookies or simply learn more about
          cookies and consult our
          <span style={{ color: "rgb(0, 0, 0)" }}>tra Cookies Policy.</span>
        </li>
      </ul>
      <ul>
        <li>
          At any time, you can access and manage real cookie consent and verify
          the real cookies used by DocuDoc. The information can be accessed in
          our Cookie Privacy.
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Likewise, the reproduction of content that may redirect the user
          through links inserted in different formats on the website or media
          owned by DocuDoc may contain cookies from the owners of said
          redirected content. In this case, DocuDoc is not responsible for the
          information or policies provided to the user or for the cookies used
          by third parties.
        </span>
      </p>
      <ul>
        <li>
          If the user does not consent to the processing of said information,
          they can modify their browser settings, following for this purpose the
          information and advice included in the links below, in relation to
          cookie settings:
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          For websites, follow the links:&nbsp;
        </span>
      </p>
      <ul>
        <li>
          <a
            target="_blank"
            href="https://support.google.com/chrome/answer/95647?hl=es"
          >
            <span style={{ color: "rgb(17, 85, 204)" }}>Chrome</span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.apple.com/kb/ph21411?locale=es_ES"
          >
            <span style={{ color: "rgb(17, 85, 204)" }}>Safari</span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="http://help.opera.com/Windows/12.10/es-ES/cookies.html"
          >
            <span style={{ color: "rgb(17, 85, 204)" }}>Opera</span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
          >
            <span style={{ color: "rgb(17, 85, 204)" }}>Explorer</span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="https://support.mozilla.org/es/kb/configuracion-privacidad-historial-navegacion-funcion-no-quiero-ser-rastreado?redirectlocale=es&as=u&redirectslug=configuracion-de-la-privacidad-el-historial-de-nav"
          >
            <span style={{ color: "rgb(17, 85, 204)" }}>Firefox</span>
          </a>
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          For mobile phones, follow the links:&nbsp;
        </span>
      </p>
      <ul>
        <li>
          Para Android: Menu &gt; More &gt; Settings &gt; Privacy Settings
        </li>
        <li>
          For
          <a target="_blank" href="https://support.apple.com/es-es/HT201265">
            <span style={{ color: "rgb(17, 85, 204)" }}>Safari (IOS).</span>
          </a>
        </li>
        <li>
          For&nbsp;
          <a
            target="_blank"
            href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies"
          >
            <span style={{ color: "rgb(17, 85, 204)" }}>Windows</span>
          </a>
        </li>
        <li>
          If the user is using another browser, you can obtain more information
          about how to configure the installation of Cookies through the help or
          assistance section of that browser. In case the User needs help to
          configure cookies with the browser.
        </li>
      </ul>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <span style={{ color: "rgb(74, 74, 74)" }}>
                  5.- OTHER RELEVANT INFORMATION
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc is not done responsible of the use and use of cookies that
          third parties carry out outside the services offered by the channels
          and media owned by DocuDoc.
        </span>
      </p>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <span style={{ color: "rgb(74, 74, 74)" }}>
                  6.- TO GET MORE INFORMATION
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Users can contact DocuDoc at the following email address:
          info@DocuDocapp.com for more information about Cookies.
        </span>
      </p>
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <span style={{ color: "rgb(74, 74, 74)" }}>
                  7.- TO CHANGE THE COOKIE SETTINGS
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          To change the settings of the selected cookies, access the following
          link:
        </span>
      </p>
    </div>
  );
}
