import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { checkDevice } from "../config/Utility";
import { Images as Icon } from "../constant/images";
import { docudocAndroidUrl, docudociosdUrl } from "../constant";
import labels from "../config/Labels";

const ModalForAMobile = () => {
  const [modalShow, setModalShow] = useState(true);
  const isMobileApp = localStorage.getItem("isMobileApp");
  useEffect(() => {
    setModalShow([null, undefined]?.includes(isMobileApp) ? true : false);
  }, []);
  const handleClosModal = () => {
    localStorage.setItem("isMobileApp", false);
    setModalShow(false);
  };
  return checkDevice() ? (
    <>
      <Modal
        show={modalShow}
        onHide={() => handleClosModal()}
        aria-labelledby="contained-modal-title-vcenter bg-danger"
        centered
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <h4>{labels.docuDocMobileApp}</h4>
          <div>
            <p className="w-100 w-lg-25">{labels.downloadMobileAppModalText}</p>
          </div>
          <div className="">
            <div className="d-flex gap-2 mt-2 w-75">
              <div className="w-50">
                <a href={docudociosdUrl} target="_blank">
                  <img src={Icon.appstore.default} />
                </a>
              </div>
              <div className="w-50">
                <a href={docudocAndroidUrl} target="_blank">
                  <img src={Icon.playstore.default} />
                </a>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0"></Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default ModalForAMobile;
