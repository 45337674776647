import React from "react";
import labels from "../config/Labels";

const OurStorySpanish = () => {
  return (
    <div className="ins-float-icon-box-content">
      <h4 className="mb-20  ">{labels.ourstory}</h4>
      {/* <div className="ins-float-icon-box-title mt-2 mb-2 ">
      Legal services for everyone made easy.
    </div> */}
      <div className="last-paragraph-no-margin">
        <p className="ins-float-icon-box-text  default ">
          Tanto siendo estudiante, como estando empleado, siendo autónomo o
          empresario, me ha sorprendido la burocracia necesaria para hacer
          cualquier gestión o trámite legal siempre relacionado con:{" "}
        </p>
        <ul
          style={{
            color: "#888",
            fontWeight: "400",
          }}
        >
          <li className="">Gran pérdida de tiempo y traslados innecesarios</li>
          <li className="">Dificultad para encontrar información precisa.</li>
          <li className="">
            Falta de solución simple y rápida a un precio bajo y transparente
          </li>
        </ul>
        <p className="">
          DocuDoc es la solución perfecta para todos aquellos que necesitan
          cualquier tipo de gestión o servicio legal de una manera simple,
          rápida y al mejor precio.
        </p>
      </div>
    </div>
  );
};

export default OurStorySpanish;
