import React from "react";

function OurAppEnglish() {
  return (
    <div className="ins-float-icon-box-content">
      <h4 className=" mt-0 ">DocuDoc App&nbsp;-&nbsp;Legal Services</h4>
      <div className="last-paragraph-no-margin">
        <p className="ins-float-icon-box-text  default ">
          DocuDoc& is the first App & Web where you can hire management and
          legal services of Lawyers, Asesorías and Gestorías at the best price,
          quickly, transparently and safely. If you do not receive the legal
          service you contract, we will refund your money.
        </p>

        <p className="ins-float-icon-box-text  default ">
          Download the App DocuDoc and hire all kinds of legal services from
          Lawyers, Asesorías and Gestorías near you in a few clicks, without
          wasting time or traveling. You will only find professionals rated 5
          stars, thus guaranteeing the best quality of service.
        </p>
        <p className="ins-float-icon-box-text  default ">
          DocuDoc offers services to individuals, freelancers and companies and
          we specialize in the legal, tax, administrative, immigration and
          traffic areas, among others.
        </p>
      </div>

      <div className="last-paragraph-no-margin"></div>
    </div>
  );
}

export default OurAppEnglish;
