import React from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";


class Api {
  constructor() {
    this.baseURL = `${process.env.REACT_APP_BASE_URL}`;
  }

  async _post(End_Point, obj, success, failure) {
    const token = localStorage.getItem("usertoken");
    if (token === null) {
      this.instance = axios.create({
        baseURL: this.baseURL,
        headers: {
          Accept: "application/json",
        },
      });
    } else {
      this.instance = axios.create({
        baseURL: this.baseURL,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    }
    try {
      let res = await this.instance.post(End_Point, obj);
      if (res) {
        success(res);
      }
    } catch (error) {
      if(error?.response?.status == 401) {
        // alert("network error or unauthorized")
        localStorage?.removeItem("usertoken");
        window.location?.replace(window.location.origin);
      }
      if (error) {
        failure(error);
      }
    }
  }

  async _get(End_Point, success, failure) {
    const token = localStorage.getItem("usertoken");
    if (token === null) {
      this.instance = axios.create({
        baseURL: this.baseURL,
        headers: {
          Accept: "application/json",
        },
      });
    } else {
      this.instance = axios.create({
        baseURL: this.baseURL,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    }
    try {
      let res = await this.instance.get(End_Point);
      if (res) {
        success(res);
      }
    }  catch (error) {
      if(error?.response?.status == 401) {
        // alert("network error or unauthorized")
        localStorage?.removeItem("usertoken");
        window.location?.replace(window?.location?.origin);
      }
      if (error) {
    
     }
   }
  }

  async _delete(End_Point,success) {
    const token = localStorage.getItem("usertoken");
    if (token === null) {
      this.instance = axios.create({
        baseURL: this.baseURL,
        headers: {
          Accept: "application/json",
        },
      });
    } else {
      this.instance = axios.create({
        baseURL: this.baseURL,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    }
    try {
      let res = await this.instance.delete(End_Point);
      if (res) {
        success(res);
      }
    } catch (error) {
      
       if (error) {
       
      }
    }
  }
}

export default new Api();
