import React from "react";

export default function PrivacyPolicySpanish() {
  return (
    <div
      className="container"
      style={{ marginBottom: "160px", marginTop: "160px" }}
    >
      <h2 style={{ textAlign: "center" }}>
        Política Privacidad y Protección de Datos
      </h2>
      <p style={{ textAlign: "center" }}>
        Información en cumplimiento de la normativa de protección de datos
        personales
      </p>
      <p style={{ textAlign: "center" }}>
        <em>Fecha de la última modificación: 20 de junio de 2024</em>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          En Europa y en España existen normas de protección de datos pensadas
          para proteger su información personal de obligado cumplimiento para
          nuestra entidad.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Por ello, es muy importante para nosotros que entienda perfectamente
          qué vamos a hacer con los datos personales que le pedimos.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Así, seremos transparentes y le daremos el control de sus datos, con
          un lenguaje sencillo y opciones claras que le permitirán decidir qué
          haremos con su información personal.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Por favor, si una vez leída la presente información le queda alguna
          duda, no dude en preguntarnos.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Muchas gracias por su colaboración.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>¿Quiénes somos?</strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>o</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>Nuestra denominación:</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>DOCUDOC APP S.L.</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>o</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>Nuestro CIF / NIF:</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>B19738905</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>o</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Nuestra actividad principal:
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>Intermediación de prestación de servicios</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>o</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>Nuestra dirección:</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>CL RAMON Y CAJAL 5, 28100 - Madrid (Madrid)</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>o</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Nuestro teléfono de contacto:
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>684005810</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>o</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Nuestra dirección de correo electrónico de contacto:
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>info@docudocapp.com</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>o</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>Nuestra página web:</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>https://www.docudocapp.com/</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>o</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Para su confianza y seguridad, le informamos que somos una entidad
          inscrita en el siguiente Registro Mercantil /Registro Público:
          Registro Mercantil de Madrid.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>Estamos</span>a su
        disposición, no dude en contactar con nosotros.
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Tratamiento de datos de Usuarios y personas que contacten con DocuDoc
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>3.1 Datos tratados</span>
      </p>
      <ol>
        <li>a) Información los Usuarios nos facilitan directamente:</li>
      </ol>
      <ul>
        <li>
          <em>Datos de Registro</em>: la información que el Usuario nos facilita
          cuando se crea una cuenta en la Plataforma de DocuDoc: Nombres,
          apellidos y el correo electrónico del usuario.
        </li>
        <li>
          <em>Información del Perfil del Usuario</em>: la información que el
          Usuario añade en la Plataforma a efectos de poder utilizar el servicio
          de DocuDoc, esto es, el teléfono móvil, la dirección de entrega del
          Usuario y los datos de facturación del Usuario. El Usuario puede ver y
          editar los datos personales de su perfil cuando estime oportuno.
          DocuDoc no almacena los datos de la tarjeta de crédito del Usuario,
          pero éstos se facilitan a prestadores de servicios de pago electrónico
          con licencia, que recibirán directamente los datos incluidos y los
          almacenan para facilitar el proceso de pago al Usuario y gestionan el
          mismo en nombre de DocuDoc. En ningún caso estos datos se almacenan en
          los servidores de DocuDoc. El Usuario podrá, en cualquier momento,
          eliminar los datos de sus tarjetas vinculados a su cuenta. Con esa
          acción, el proveedor de servicios procederá al borrado de los datos y
          será necesario volver a introducirlos o seleccionarlos para poder
          realizar nuevos pedidos a través de la Plataforma. En cualquier
          momento el Usuario podrá solicitar las políticas de privacidad de
          estos proveedores.
        </li>
        <li>
          <em>
            Información adicional que el Usuario quiere compartir para llevar a
            cabo el servicio
          </em>
          : la información que podría facilitar el Usuario a DocuDoc con otros
          fines. Por ejemplo, una copia de su documento de identidad.
        </li>
        <li>
          <em>
            Información acerca de las comunicaciones realizadas a través de
            DocuDoc
          </em>
          : DocuDoc tendrá acceso a información que los Usuarios faciliten para
          la resolución de dudas o quejas sobre el uso de la plataforma, ya sea
          a través del formulario de contacto, mediante correo electrónico o a
          través del servicio de atención al cliente de forma telefónica.
        </li>
        <li>
          Información sobre accidentes que involucren a alguna de las partes que
          intervienen en la prestación de servicios a través de la Plataforma
          para la presentación de siniestros o otras actuaciones con las
          aseguradoras contratadas por DocuDoc.
        </li>
        <li>
          Transcripción y grabación de las conversaciones mantenidas por el
          Usuario con DocuDoc para la tramitación de incidencias, dudas u otras
          consultas que pudiera realizar.
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>
            Información sobre las comunicaciones entre usuarios y Prestadores de
            servicio
          </em>
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          : DocuDoc tendrá acceso a las comunicaciones que los Usuarios realicen
          con los Prestadores de servicio que colaboran con la Plataforma a
          través del chat previsto en la Plataforma.
        </span>
      </p>
      <ol>
        <li>b) Información que los Usuarios nos facilitan indirectamente:</li>
      </ol>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>-</span>
        <span style={{ color: "rgb(74, 74, 74)" }} />
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>Datos derivados del Uso de la Plataforma:</em>
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc recoge los datos derivados del Uso de la Plataforma por parte
          del Usuario cada vez que éste interactúa con la Plataforma.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>Datos de la aplicación y del dispositivo:</em>
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc almacena los datos del dispositivo y de la Aplicación que el
          Usuario utiliza para acceder a los servicios. Estos son:
        </span>
      </p>
      <ul>
        <li>
          Dirección IP de Internet que utiliza el Usuario para conectarse a
          Internet con su ordenador o móvil.
        </li>
        <li>
          Información de su ordenador o móvil, como su conexión a Internet, su
          tipo de navegador, la versión y el sistema operativo, y el tipo de
          dispositivo.
        </li>
        <li>
          El Clikstream completo de localizadores de recursos uniformes (URL),
          incluyendo la fecha y la hora.
        </li>
        <li>
          <em>Datos de la cuenta del Usuario</em>: la información de los pedidos
          realizados por parte del Usuario, así como las valoraciones y/o
          comentarios que realice acerca de los mismos.
        </li>
        <li>El historial de navegación y las preferencias del Usuario.</li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>Datos derivados del origen del Usuario:</em>
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          si el Usuario llega a la Plataforma de DocuDoc a través de una fuente
          externa (como sería por ejemplo un enlace de otra página web o de una
          red social), DocuDoc recopila los datos de la fuente de la que procede
          el Usuario de DocuDoc.- &nbsp;
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>Datos derivados de la gestión de incidencias</em>
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          : si el Usuario se dirige a la Plataforma de DocuDoc a través del
          Formulario de Contacto o a través del teléfono de DocuDoc, DocuDoc
          recopilará los mensajes recibidos en el formato utilizado por el
          Usuario y los podrá utilizar y almacenar para gestionar incidencias
          presentes o futuras.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>-&nbsp; &nbsp; &nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>Datos derivados de las “cookies”:</em>
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc utiliza cookies propias y de terceros para facilitar la
          navegación a sus usuarios y con fines estadísticos.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>Datos derivados de terceros externos:</em>
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc podría recopilar información o datos de carácter personal de
          terceros externos únicamente si el Usuario autoriza a esos terceros a
          compartir la información citada con DocuDoc. Por ejemplo, en caso de
          que el Usuario se cree una cuenta a través de su Facebook, Facebook
          podría cedernos datos de carácter personal de dicho Usuario en caso de
          que éste los tuviera en su perfil de Facebook (nombre, genero, edad…).
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }} />
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Del mismo modo, si el usuario accede a DocuDoc a través de los
          productos y servicios ofrecidos por Google, éste podrá enviar datos de
          navegación del Usuario a DocuDoc, con el acceso a la plataforma a
          través de los enlaces creados por Google.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          La información facilitada por el tercero externo podrá ser controlada
          por el Usuario según &nbsp; la propia política de privacidad del
          tercero externo.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>3.2 Finalidad</span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.1. Utilizar la Plataforma de DocuDoc
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc utiliza los datos que recopila de los Usuarios para que puedan
          acceder y comunicarse con la plataforma de DocuDoc, y para prestar los
          servicios que soliciten a través de su cuenta en la Plataforma
          DocuDoc, según el mecanismo descrito en las “Condiciones Generales de
          Uso y Contratación”.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.2. Envío de comunicaciones
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc utiliza los datos de los Usuarios para realizar comunicaciones
          vía correo electrónico y/o enviar SMS al Usuario acerca de la
          operativa del servicio.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc podrá enviar mensajes al móvil del Usuario con información
          relativa al estado del pedido solicitado, y una vez éste haya
          terminado, DocuDoc enviará un resumen/ recibo del pedido y del precio
          del mismo al correo electrónico del Usuario.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.3. Detectar e investigar fraudes y posibles comisiones de delitos
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc también utiliza la información para investigar y analizar cómo
          mejorar los servicios que presta a los Usuarios, así como para
          desarrollar y mejorar las características del servicio que ofrece.
          Internamente, DocuDoc utiliza la información con fines estadísticos a
          efectos de analizar el comportamiento y las tendencias de los
          Usuarios, de comprender cómo los Usuarios utilizan la Plataforma de
          DocuDoc, y de gestionar y mejorar los servicios ofrecidos, incluyendo
          la posibilidad de añadir nuevos distintivos a la Plataforma.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc podrá controlar todas las actuaciones que podrían derivar en
          fraude o en la comisión de algún delito relacionado con los elementos
          de pago utilizados por los usuarios, DocuDoc podrá solicitar al
          usuario una copia de su DNI, así como cierta información sobre la
          tarjeta bancaria utilizada para la solicitud del encargo. Todos los
          datos serán, en cualquier caso, tratados por DocuDoc con la única
          finalidad de cumplimiento de las funciones de vigilancia y prevención
          del fraude y serán almacenados en tanto la relación con el usuario se
          mantenga vigente, así como incluso en el periodo posterior en tanto no
          prescriba el derecho del usuario a presentar reclamaciones o acciones
          legales relativas al pago de los productos o servicios solicitados a
          través de DocuDoc. Los datos relativos a la tarjeta de crédito
          utilizada se conservarán mientras dure la resolución de la incidencia
          y durante los siguientes 120 días desde su fecha de resolución. En el
          supuesto que se detecten irregularidades en el uso de la misma que
          pudieran ser consideradas como actividades ilegales, DocuDoc se
          reserva el derecho a conservar los datos facilitados, así como podrá
          compartirlos con las autoridades competentes con la finalidad de
          investigar el supuesto. DocuDoc podrá compartir los datos con las
          autoridades basado en la obligación legal de perseguir conductas
          contrarias a la normativa de aplicación.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.4. Garantizar la seguridad y un entorno adecuado para la segura
          prestación de servicios
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc podrá utilizar los datos a efectos de velar por el buen uso de
          los productos solicitados en su Plataforma.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Cuando DocuDoc intermedie en la pestación de servicios, DocuDoc podrá
          facilitar los datos personales necesarios al prestador de servicios
          con la finalidad que el prestador de servicios pueda poner en contacto
          con el comprador si lo considera oportuno.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.5. Cumplir con la normativa, defensa e interposición de acciones
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc informa al usuario que las conversaciones que se lleven a cabo
          a través del chat para la comunicación con el Prestador de servicio
          podrán ser revisadas y utilizadas por DocuDoc para la interposición
          y/o defensa de reclamaciones y/o acciones legales necesarias, así como
          para la gestión de incidencias en los pedidos.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.6. Promoción y realización de ofertas comerciales (online y
          offline)
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc utiliza tecnología de terceros integrada en su Plataforma a
          los efectos de recabar sus datos y preferencias y utilizarlos con
          sistemas de CRM y tecnología avanzada en beneficio de los Usuarios.
          Así, mediante la información recabada se realizarán los siguientes
          tratamientos de sus datos:
        </span>
      </p>
      <ul>
        <li>
          DocuDoc, podrá enviar por correo electrónico mensajes promocionales
          y/o ofertas relativas al servicio que ofrece y que pudieran interesar
          al Usuario. DocuDoc podrá medir y personalizar dicha publicidad en
          función de las preferencias de los Usuarios de DocuDoc. Si el Usuario
          de DocuDoc desea no recibir la citada información y/o comunicaciones
          comerciales, podrá optar en cualquier momento a la opción de “Cancelar
          la suscripción” en el propio correo electrónico, y consecuentemente,
          DocuDoc cesará inmediatamente en el envío de la citada información.
        </li>
        <li>
          DocuDoc también podrá enviar al Usuario mensajes y/o ofertas relativas
          a dichos servicios mediante notificaciones “push” consistentes en el
          envío a su teléfono móvil de dichos mensajes promocionales y/o
          ofertas. Si el Usuario de DocuDoc desea no recibir las comunicaciones
          comerciales del presente punto y el anterior 3.1., el Usuario podrá
          eliminarlas de forma conjunta desactivando sus preferencias de
          privacidad en su perfil con un solo click.
        </li>
        <li>
          DocuDoc y/o los terceros asociados con DocuDoc, podrá utilizar la
          dirección de entrega del pedido introducida por el Usuario a efectos
          de realizar actividades promocionales para la entrega de muestras o
          productos gratuitos del servicio relacionado con DocuDoc (i.e. entrega
          de muestras gratuitas a domicilio o folletos publicitarios) junto a la
          entrega del pedido que puedan ser de interés del Usuario.
        </li>
        <li>
          Mediante el uso de la Plataforma de DocuDoc, los Usuarios también
          podrán recibir comunicaciones comerciales de terceros asociados con la
          Plataforma como Facebook y Google, todo ello en función de las
          preferencias de privacidad que el Usuario tenga en dichas Plataformas.
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Los Usuarios podrán hacer uso de su centro de gestión de privacidad a
          los efectos de dar de baja los servicios de “marketing” online o
          cancelar su cuenta que no estuviera de acuerdo en recibir muestras
          junto con los pedidos de DocuDoc.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.7. Fines estadísticos y análisis de servicios
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc utiliza la información con fines estadísticos a efectos de
          analizar el comportamiento y las tendencias de los Usuarios, de
          comprender cómo los Usuarios utilizan la Plataforma de DocuDoc, y de
          gestionar y mejorar los servicios ofrecidos, incluyendo la posibilidad
          de añadir nuevos distintivos a la Plataforma.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc también utiliza la información para investigar y analizar cómo
          mejorar los servicios que presta a los Usuarios, así como para
          desarrollar y mejorar las características del servicio que ofrece.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.8. Garantizar la seguridad y un entorno adecuado para la segura
          prestación de servicios
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc podrá utilizar los datos a efectos de velar por el buen uso de
          los productos solicitados en su Plataforma.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.9. Tramitar siniestros y reclamaciones con aseguradoras
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          En el supuesto que el Usuario contacte a DocuDoc para reportar algún
          daño o imprevisto que pueda quedar cubierto por el seguro contratado
          por DocDoc, éste tratará todos los datos relativos al siniestro con la
          finalidad de gestionar y dar respuesta a las solicitudes.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.3 Base legal
          del tratamiento
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          El tratamiento de los datos de los Usuarios se realiza en base a las
          siguientes bases legales:
        </span>
      </p>
      <ul>
        <li>
          Ejecutar la relación contractual una vez registrado en la Plataforma
          (por ejemplo, el tratamiento de tus datos para entregarte una orden
          solicitada).
        </li>
        <li>
          En base a nuestro interés legítimo (por ejemplo, para la realización
          de controles para evitar el fraude a través de la Plataforma).
        </li>
        <li>
          Cumplimiento de obligaciones legales (por ejemplo, cuando autoridades
          competentes soliciten datos en el marco de investigaciones judiciales
          y/o la interposición de las acciones necesarias para proteger los
          intereses de DocuDoc.
        </li>
        <li>
          Consentimiento explícito para la comunicación de datos de usuarios a
          terceros con la finalidad de realizar comunicaciones comerciales.
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.4 Destinatarios de los datos&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc garantiza que todos los socios comerciales, técnicos,
          proveedores, o terceros independientes están unidos mediante promesas
          contractualmente vinculantes para procesar información que se comparte
          con ellos siguiendo las indicaciones que DocuDoc le da, la presente
          Política de Privacidad, así como la legislación aplicable en materia
          de protección de datos. No cederemos tus datos personales a ningún
          tercero que no esté sometido a nuestras instrucciones y ninguna
          comunicación incluirá vender, alquilar, compartir o revelar de otro
          modo información personal de clientes con fines comerciales de modo
          contrario a los compromisos adquiridos en la presente Política de
          Privacidad.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.4.1. Durante la ejecución de un pedido los datos podrán compartirse
          con:
        </span>
      </p>
      <ul>
        <li>El Prestador de servicio, quién ejecuta el servicio.</li>
        <li>
          Por establecimientos de instituciones públicas, si es que es necesario
          para la prestación del servicio, con el único fin de prestar el
          servicio contratado
        </li>
        <li>
          Por los servicios de Atención al Cliente contratados por DocuDoc a los
          efectos de advertir al Usuario sobre posibles incidencias o
          solicitarle el porqué de una valoración negativa del servicio. DocuDoc
          podrá utilizar los datos facilitados a los efectos de gestionar las
          incidencias que pudieran producirse en la prestación de servicios.
        </li>
        <li>
          La Plataforma y proveedores de pago a los efectos de que estos puedan
          cargar el importe en su cuenta.
        </li>
        <li>
          Por prestadores de servicios de telecomunicaciones, cuando se utilicen
          para el envío de comunicaciones sobre órdenes o incidencias
          relacionadas con éstas
        </li>
        <li>
          Prestadores que ofrecen servicios de encuestas de satisfacción
          realizadas por DocuDoc
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.4.2. Compartir los datos de los Usuarios con terceros
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Para poder seguir prestando los servicios ofrecidos a través de la
          Plataforma, DocuDoc podrá compartir ciertos datos de carácter personal
          de los Usuario con:
        </span>
      </p>
      <ul>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Proveedores de servicios
          </span>
          . los terceros proveedores de servicios de DocuDoc que ejecutan
          pedidos y/o solucionan incidencias con los envíos tendrán acceso a la
          información personal de los Usuarios necesaria para realizar sus
          funciones, pero no podrán utilizarla para otros fines. Estos deberán
          tratar la información personal de conformidad con la presente Política
          de Privacidad y la legislación aplicable en la materia de protección
          de datos.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Proveedores de servicios de pago
          </span>
          . Cuando un Usuario introduzca en la Plataforma de DocuDoc su número
          de tarjeta, ésta se almacena directamente por las Plataformas de Pago
          contradas por DocuDoc, quienes permitirán que el cobro de la cuenta
          del Usuario. Los proveedores de servicios de pago han sido elegidos en
          base a sus medidas de seguridad y cumpliendo, en todo caso, las
          medidas de seguridad establecidas por la normativa de servicios de
          pago, siendo PC1 Compliant según el Estándar de Seguridad de Datos
          para la Industria de Tarjeta de Pago o PCI DSS. DocuDoc no almacena en
          ningún caso dichos datos.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Prestadores de servicios para el control del fraude
          </span>
          . DocuDoc compartirá los datos de los Usuarios con proveedores que
          ofrecen servicios de control del fraude, para estudiar el riesgo de
          las operaciones que se llevan a cabo.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Prestadores de servicio para la anonimización de algunos datos
          </span>
          . Para evitar el uso indebido de los datos de los Usuarios por parte
          de terceros prestadores de servicios, DocuDoc puede ceder los datos de
          los Usuarios a fin de que estos puedan ser anonimizados y utilizados
          únicamente para la prestación del servicio a los Usuarios. Por
          ejemplo, DocuDoc podrá ceder a terceros el número de teléfono de los
          Usuarios para anonimizarlos y facilitarlos en este formato a los
          prestadores utilizados para dar cumplimiento a los servicios
          contratados por los Usuarios.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Empresas de seguridad y Fuerzas y Cuerpos de Seguridad
          </span>
          . DocuDoc podrá divulgar información personal y datos de cuentas de
          nuestros clientes cuando crea que su divulgación es necesaria para el
          cumplimiento de la ley, para hacer cumplir o aplicar los “Términos de
          Uso” o para proteger los derechos, la propiedad o seguridad de
          DocuDoc, sus usuarios o terceros. Lo anterior incluye, por lo tanto,
          el intercambio de información con otras sociedades y organizaciones,
          así como con Fuerzas y Cuerpos de Seguridad para la protección contra
          el fraude y la reducción del riesgo de crédito. Previo requerimiento
          legal, DocuDoc podrá compartir información con organismos de
          autoridades ejecutivas y/o terceros con respecto a peticiones de
          información relativas a investigaciones de carácter penal y presuntas
          actividades ilegales.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>Servicios de</span>
          <span style={{ textDecoration: "underline" }}>
            <em>Call Center</em>
          </span>
          <span style={{ textDecoration: "underline" }}>
            y gestión de incidencias
          </span>
          . Para ofrecer un Servicio de Atención al cliente y centros de
          atención telefónica, acciones dirigidas a la medición del grado de
          satisfacción de los Usuarios y prestación de servicios de apoyo
          administrativo, DocuDoc podrá comunicar los datos de los Usuarios a
          compañías situadas en países fuera del EEE, siempre y cuando se le
          autorice y se cumplan los requisitos de seguridad mencionados en el
          punto anterior.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Servicios de telecomunicaciones
          </span>
          . Para poder ofrecer a los Usuarios servicios de contacto telefónico,
          DocuDoc podrá contactar con empresas de telecomunicaciones que
          facilitan líneas y sistemas seguros para contactar con los Usuarios.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Redes sociales conectadas por el Usuario
          </span>
          . En el supuesto que el Usuario conecte su cuenta de DocuDoc a otra
          red social o a la plataforma de un tercero, DocuDoc podría utilizar la
          información cedida a esa red social o tercero, siempre que dicha
          información se haya puesto a disposición de DocuDoc cumpliendo con la
          política de privacidad de dicha red social o plataforma de tercero.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Terceros asociados con DocuDoc para comunicaciones comerciales
          </span>
          . Con su consentimiento explícito, DocuDoc podrá transferir sus datos
          personales a terceros asociados con DocuDoc siempre y cuando el
          Usuario hubiera aceptado expresa, informada e inequívocamente dicha
          cesión de datos y conociera el propósito y destinatario de los mismos.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Supuestos de cambio de propiedad
          </span>
          . Si la propiedad de DocuDoc cambiara o la mayor parte de sus activos
          fueran adquiridos por un tercero, los Usuarios son informados que
          DocuDoc transferirá sus datos a las entidades del adquirente con la
          finalidad de seguir prestando los servicios objeto del tratamiento de
          datos. El nuevo responsable comunicará a los Usuarios sus datos de
          identificación. DocuDoc manifiesta que cumplirá con el deber de
          información a la Autoridad de Control pertinente en caso de que las
          situaciones citadas ocurrieran, y comunicará a los Usuarios el cambio
          de responsable en el momento en que se produzca. El presente
          tratamiento se realizará en virtud del contrato suscrito con DocuDoc.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Compañías aseguradoras
          </span>
          . DocuDoc podrá comunicar los datos de los usuarios a las compañías
          aseguradoras y corredurías de seguros con las que DocuDoc tengan un
          acuerdo para la gestión y tramitación de reclamaciones y siniestros
          derivados de la actividad de DocuDoc y sus colaboradores.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Gestorías y Asesorías.
          </span>
          DocuDoc podrá comunicar los datos de los usuarios a las compañías
          Gestoras y Asesoras con las que DocuDoc tengan un acuerdo para la
          gestión y tramitación fiscal, laboral y contable derivados de la
          actividad de DocuDoc y sus colaboradores.
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Los datos de los Usuarios de DocuDoc no se transmitirán a ningún
          tercero a excepción de que (i) sea necesario para los servicios
          solicitados, en caso de que DocuDoc colabore con terceros (ii) cuando
          DocuDoc tenga autorización expresa e inequívoca por parte del Usuario,
          (iii) cuando se solicite por parte de autoridad competente en el
          ejercicio de sus funciones (para investigar, prevenir o tomar acciones
          relativas a acciones ilegales) o (iv) por último, si lo requiere la
          ley.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.5 Borrar datos y cuenta del usuario
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          El Usuario podrá borrar su cuenta con todos sus datos accediendo al
          perfil y presionando “Eliminar mi cuenta y mis datos”. La cuenta y los
          datos del Usuario se borrarán de forma automática, a menos que el
          usuario tenga un pedido en curso. En el caso de que el usuario tenga
          un pedido en curso, tendrá que esperar que dicho pedido finalice, para
          poder borrar su cuenta y sus datos.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>¿Cómo vamos a proteger sus datos?</strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Protegeremos sus datos con medidas de seguridad eficaces en función de
          los riesgos que conlleve el uso de su información.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Para ello, nuestra entidad ha aprobado una Política de Protección de
          Datos y se realizan controles y auditorías anuales para verificar que
          sus datos personales están seguros en todo momento.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>¿Enviaremos sus datos a otros países?</strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          En el mundo hay países que son seguros para sus datos y otros que no
          lo son tanto. Así por ejemplo, la Unión Europea es un entorno seguro
          para sus datos. Nuestra política es no enviar su información personal
          a ningún país que no sea seguro desde el punto de vista de la
          protección de sus datos.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          En el caso de que, con motivo de prestarle el servicio, sea
          imprescindible enviar sus datos a un país que no sea tan seguro como
          España, siempre le solicitaremos previamente su permiso y aplicaremos
          medidas de seguridad eficaces que reduzcan los riesgos del envío de su
          información personal a otro país.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>¿Durante cuánto tiempo vamos a conservar sus datos?</strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Conservaremos sus datos durante nuestra relación y mientras nos
          obliguen las leyes. Una vez finalizados los plazos legales aplicables,
          procederemos a eliminarlos de forma segura y respetuosa con el medio
          ambiente.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>¿Cuáles son sus derechos de protección de datos?</strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          En cualquier momento puede dirigirse a nosotros para saber qué
          información tenemos sobre usted, rectificarla si fuese incorrecta y
          eliminarla una vez finalizada nuestra relación, en el caso de que ello
          sea legalmente posible.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          También tiene derecho a solicitar el traspaso de su información a otra
          entidad. Este derecho se llama “portabilidad” y puede ser útil en
          determinadas situaciones.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Para solicitar alguno de estos derechos, deberá realizar una solicitud
          escrita a nuestra dirección, junto con una fotocopia de su DNI, para
          poder identificarle.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          En las oficinas de nuestra entidad disponemos de formularios
          específicos para solicitar dichos derechos y le ofrecemos nuestra
          ayuda para su cumplimentación.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Para saber más sobre sus derechos de protección de datos, puede
          consultar la&nbsp; página web de la Agencia Española de Protección de
          Datos (
        </span>
        <a target="_blank" href="http://www.agpd.es/">
          <span style={{ color: "rgb(74, 74, 74)" }}>www.agpd.es</span>
        </a>
        <span style={{ color: "rgb(74, 74, 74)" }}>).</span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>
            ¿Puede retirar su consentimiento si cambia de opinión en un momento
            posterior?
          </strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Usted puede retirar su consentimiento si cambia de opinión sobre el
          uso de sus datos en cualquier momento.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Así por ejemplo, si usted en su día estuvo interesado/a en recibir
          publicidad de nuestros productos o servicios, pero ya no desea recibir
          más publicidad, puede hacérnoslo constar a través del formulario de
          oposición al tratamiento disponible en las oficinas de nuestra
          entidad.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>
            En caso de que entienda que sus derechos han sido desatendidos,
            ¿dónde puede formular una reclamación?
          </strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          En caso de que entienda que sus derechos han sido desatendidos por
          nuestra entidad, puede formular una reclamación en la Agencia Española
          de Protección de Datos, a través de alguno de los medios siguientes:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>o</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Sede electrónica: www.agpd.es
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>o</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>Dirección postal:</span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Agencia Española de Protección de Datos
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>C/ Jorge Juan, 6</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>28001-Madrid</span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>o</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>Vía telefónica:</span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>Telf. 901 100 099</span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>Telf. 91 266 35 17</span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Formular una reclamación en la Agencia Española de Protección de Datos
          no conlleva ningún coste y no es necesaria la asistencia de abogado ni
          procurador.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
        <span style={{ color: "rgb(0, 32, 96)" }}>
          &nbsp; &nbsp; &nbsp; &nbsp;
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>¿Elaboraremos perfiles sobre usted?</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Nuestra política es no elaborar perfiles sobre los usuarios de
          nuestros servicios.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          No obstante, pueden existir situaciones en las que, con fines de
          prestación del servicio, comerciales o de otro tipo, necesitemos
          elaborar perfiles de información sobre usted. Un ejemplo pudiera ser
          la utilización de su historial de compras o servicios para poder
          ofrecerle productos o servicios adaptados a sus gustos o necesidades.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          En tal caso, aplicaremos medidas de seguridad eficaces que protejan su
          información en todo momento de personas no autorizadas que pretendan
          utilizarla en su propio beneficio.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>¿Usaremos sus datos para otros fines?</strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Nuestra política es no usar sus datos para otras finalidades distintas
          a las que le hemos explicado. Si, no obstante, necesitaremos usar sus
          datos para actividades distintas, siempre le solicitaremos previamente
          su permiso a través de opciones claras que le permitirán decidir al
          respecto.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>Registro de actividad</strong>
        </li>
      </ol>
      <span style={{ color: "rgb(74, 74, 74)" }}>
        Si quiere consultar el registro de actividad puede consultarlo en este
        enlace:
      </span>
      <a
        target="_blank"
        href="https://drive.google.com/file/d/19NDHHQJjsWWaR1qw78NL5hqK4Q8vl9yc/view?usp=sharing"
      >
        <span style={{ color: "rgb(17, 85, 204)" }}>
          https://drive.google.com/file/d/19NDHHQJjsWWaR1qw78NL5hqK4Q8vl9yc/view?usp=sharing
        </span>
      </a>
    </div>
  );
}
