import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import { Images as Icon } from "../constant/images";
import CustomTooltip from "./tooltip";

export default function SpecficServiceComponent(props) {
  const { push } = useHistory();
  const target = useRef(null);
  const [show, setShow] = useState(false);
  
  return (
    <div className={`col-md-4 ${props.index === 1 ? " mt-5" : ""} px-0`}>
      <div
        className="ss-features mb-15 text-center bg-white animate animated fadeInTop"
        data-animation="fadeInTop"
      >
        <div 
          className=" lineHeight15px"
          style={{ cursor: "pointer"  }}
          onClick={() =>
            push({
              pathname: `/innerservice/${props.serviceArea.service_area_id}`,
              state: { serviceName: props.serviceArea.name, beneId: props.bId },
            })
          }
        >
          <div className="ssf-icon">
            <img src={props.serviceArea?.avatar} draggable="false" />
          </div>
          {props.serviceArea?.name}
        </div>

        <div className="infoIconModal">
          <img
            src={Icon.infoo.default}
            alt="info"
            style={{ cursor: "pointer" }}
            onClick={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            ref={target}
            // onClick={() => setTooltipOpen1(true)}
            width={"20px"}
          />
        </div>
      </div>
      <CustomTooltip
        target={target.current}
        show={show}
        info={props.serviceArea.info}
      />
    </div>
  );
}
