// import { Modal } from 'bootstrap';
import React, { useState, useEffect } from "react";
import { OrderItem } from "../../component";
import { Tab, Tabs } from "react-bootstrap";
import { Images } from "../../constant/images";
import ToastComponent from "../../component/toast";
import { RiAddLine } from "react-icons/ri";
import { Delete_Credit_Card, Get_Credit_Cards } from "../../config/api";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Modal, ModalBody } from "react-bootstrap";
import CreditCardScreen from "../creditCard/creditCard";
import labels from "../../config/Labels";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useToasts } from "react-toast-notifications";

const stripePromise = loadStripe(process.env.REACT_APP_API_KEY);

export default function Payment(props) {
  const history = useHistory();
  const [pendingOrders, setPendingOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [creditCards, setCreditCards] = useState([]);
  const [showSpinner1, setShowSpinner1] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [cardId, setCardId] = useState("");
  const [isPaymentModal, setIsPaymentModal] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const { addToast } = useToasts();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getCards();
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [isPaymentModal]);

  const getCards = () => {
    Get_Credit_Cards(
      (value) => {
        setShowSpinner(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
        if (value.length === 1) {
          let mappedCrads = value.map((card, i) => {
            return {
              ...card,
              checked: true,
            };
          });
          setCustomerId(value[0].customer_id);
          setCreditCards(mappedCrads);
        } else {
          let mappedCrads = value.map((card, i) => {
            return {
              ...card,
              checked: false,
            };
          });
          setCustomerId("");
          setCreditCards(mappedCrads);
        }
      }
    );
  };

  const deleteCreditCardMethod = () => {
    Delete_Credit_Card(
      cardId,
      (value) => {
        setShowSpinner1(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast1(value);
      },
      (value) => {
        setCreditCards(value);
      },
      () => {
        setIsConfirmationModal(false);
        getCards();
      },
      addToast
    );
  };

  const closeToast1 = () => {
    setShowToast1(false);
  };

  return (
    <>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <div
        className="d-flex justify-content-center "
        style={{ height: "100vh" }}
      >
        <div className="payment-container">
          <div className="d-flex align-self-baseline cursorPointer">
            <img
              src={Images.backArrow.default}
              style={{ width: "40px", height: "40px" }}
              className=""
              onClick={() => {
                history.goBack();
              }}
            />{" "}
          </div>
          <div className="payment-header d-flex justify-content-between w-100">
            <h5 className="text-white ProximaNovaBold ml-3">
              {labels.addPayment}
            </h5>
            <RiAddLine
              size={30}
              color="white"
              style={{ cursor: "pointer" }}
              onClick={() => setIsPaymentModal(true)}
              className="mr-4"
            />
          </div>

          <div className="payment-cards-container w-100">
            {showSpinner
              ? `${labels.loading}...`
              : creditCards.map((cardDetail, index) => {
                  return (
                    <div className="card-item-container mt-20">
                      <div className="card-item" key={index}>
                        <span className="card-no">{`*** *** *** ${cardDetail.last_4_digit}`}</span>
                      </div>
                      <img
                        src={Images.trash.default}
                        onClick={() => {
                          setIsConfirmationModal(true);
                          setCardId(cardDetail.card_id);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  );
                })}
          </div>
        </div>
        <Modal
          className="modelBackground"
          show={isConfirmationModal}
          onHide={() => setIsConfirmationModal(false)}
          size="lg"
        >
          <ModalBody className="bg-white border-radius-10 alignItemsCenter shadow textAlignCenter m-auto">
            <button
              type="button"
              className="close absolute"
              data-dismiss="modal"
              onClick={() => setIsConfirmationModal(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            {showToast1 === true ? (
              <ToastComponent
                message={message}
                toastClose={closeToast1}
                success={success}
              />
            ) : (
              ""
            )}
            <div className="p-3">
              <h3 className="textAlignCenter fa-2x">{labels.confirmation}</h3>
              <p className="mx-30">{labels.deleteCard}</p>
              <div className="conf-modal-footer ">
                <button
                  onClick={() => setIsConfirmationModal(false)}
                  className="mb-1 mt-3"
                  style={{ backgroundColor: "red", borderColor: "red" }}
                >
                  {labels.cancel}
                </button>{" "}
                <button
                  onClick={() => deleteCreditCardMethod()}
                  className="mb-1 mt-3 ml-1 w-100"
                >
                  {showSpinner1 ? `${labels.loading}...` : labels.ok}
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          className="modelBackground"
          show={isPaymentModal}
          onHide={() => setIsPaymentModal(false)}
          size="lg"
        >
          <ModalBody className="bg-white border-radius-10 alignItemsCenter shadow textAlignCenter m-auto  my-20">
            <button
              type="button"
              className="close absolute"
              data-dismiss="modal"
              onClick={() => setIsPaymentModal(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <h4 className="ProximaNovaBold mt-4">{labels.addPaymentCard}</h4>
            <div
              style={{
                backgroundColor: "white",
                width: "500px",
                height: "auto",

                paddingTop: "30px",
              }}
            >
              <Elements stripe={stripePromise}>
                <CreditCardScreen
                  modalClose={() => setIsPaymentModal(false)}
                  getCreditCards={getCards}
                />
                <div className="mx-4 pb-3 ">
                  <p
                    className="fontSize13px font-weight-normal"
                    style={{ lineHeight: "normal" }}
                  >
                    {labels?.freeServicePaymentMessage}
                  </p>
                </div>
              </Elements>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}
