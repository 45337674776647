import React from "react";
export const Context = React.createContext(null);

export class ThemeProvider extends React.Component {
  state = {
    user: "",
    updateUser: (value) => {
      this.setState({ user: value });
    },
    firstName:"",
    upDateFirstName:(value)=>{
      this.setState({firstName:value});
    },
    lastName:"",
    upDateLastName:(value)=>{
      this.setState({lastName:value});
    },
    phoneNumber:"",
    upDatePhoneNumber:(value)=>{
      this.setState({phoneNumber:value});
    },
    companyName:"",
    upDateCompanyName:(value)=>{
      this.setState({ companyName:value});
    },
    CIF:"",
    upDateCIF:(value)=>{
      this.setState({CIF:value});
    },
    address:"",
    upDateAddress:(value)=>{
      this.setState({address:value});
    },
  
    
    primaryLanguage: "spanish",
    updateLanguage: (value) => {
      this.setState({ primaryLanguage: value });
    },
  };

  render() {
    return (
      <Context.Provider value={this.state} theme={{}}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const useStoreItem = () => React.useContext(Context);

export default ThemeProvider;
