import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ServiceProviderCard from "../../component/serviceProviderCard";
import ToastComponent from "../../component/toast";
import labels from "../../config/Labels";
import { GetAllAdditionalBudgetOrders } from "../../store/actions/additionalBudgetOrders";
const Budgets = (props) => {
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  const ordersListing = useSelector(
    (state) => state?.additionalBudgetOrders?.orders
  );
  const orderLoading = useSelector(
    (state) => state?.additionalBudgetOrders?.loading
  );
  const { push } = useHistory();

  const closeToast = () => {
    setShowToast(false);
  };

  useEffect(() => {
    dispatch(
      GetAllAdditionalBudgetOrders((failure) => {
        setMessage(failure);
        setSuccess(false);
        setShowToast(true);
      })
    );
  }, []);
  useEffect(() => {
    setOrders(ordersListing);
  }, [ordersListing]);
  useEffect(() => {
    setShowSpinner(orderLoading);
  }, [orderLoading]);
  return (
    <Fragment>
      <div>{showSpinner ? labels.loading : null}</div>
      {orders?.map((item, index) => (
        <ServiceProviderCard
          key={index}
          item={item}
          orderId={null}
          showAlertUnreadCount={item?.additional_budgets_count}
          onClickFunction={() =>
            push({
              pathname: `/orderdetail/${item?.order_id}`,
              state: { dispute: false, allInfo: item, taber: "menu" },
            })
          }
        />
      ))}
      {showToast === true ? (
        <>
          <ToastComponent
            message={message}
            toastClose={closeToast}
            success={success}
          />
        </>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Budgets;
