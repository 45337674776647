import moment from "moment";
import React, { useRef, useState } from "react";
import { Col, Modal, ModalBody, Row, Tab, Tabs } from "react-bootstrap";
import { FaFileAlt } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory, useParams } from "react-router-dom";
import ToastComponent from "../../component/toast";
import {
  Disputed_Order_Message,
  Get_Dispute_Order_Messages,
  Get_File_Url_Method,
  Get_Message_by_Order_Id,
  Get_Notifications,
  Get_Order_By_Id,
  Send_Message_To_Provider_Method,
} from "../../config/api";
import { Images } from "../../constant/images";

import {
  child,
  onValue,
  push as fbPush,
  ref,
  runTransaction,
  serverTimestamp,
  update,
} from "firebase/database";
import { useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import InformationContainer from "../../component/informationContainer";
import { database } from "../../config/Firebase";
import labels from "../../config/Labels";
import "./specificordermessage.css";
import {
  extractContent,
  functionToConvertStringToCamelCase,
  regexToCheckIfTheStringContainsEmail,
  regexToCheckIfTheStringContainsPhoneNumber,
} from "../../config/Utility";
import { Helmet } from "react-helmet";
const { docuDocAdminChatLogo } = Images;
const urlArray = [];

export default function SpecficOrderMessage(props) {
  const messagesEndRef = useRef();

  const params = useParams();
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [value, setValue] = useState("");
  const [orderMessage, setOrderMessage] = useState("");
  const [docs, setDocs] = useState([]);
  const [muiltipleDocument, setMuiltipleDocument] = useState("");
  const [MyMessages, setMyMessages] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [fileArrayLength, setFileArrayLength] = useState("");
  const [messaageType, setMessageType] = useState(0);
  const [fileObject, setFileFileObject] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const { push } = useHistory();
  const [tab, setTab] = useState("home");
  let history = useHistory();
  let firstName = localStorage.getItem("firstName");
  let lastName = localStorage.getItem("lastName");

  const language = localStorage.getItem("primaryLanguage");

  const fireBaseListner = () => {
    const getData = ref(database, "orders/" + params?.id + "/messages");

    onValue(getData, (snapshot) => {
      const data = snapshot?.val();
      runTransaction(
        ref(database, "orders/" + params?.id + "/userUnread"),
        () => {
          return 0;
        }
      );
      setMyMessages(Object?.values(data || {}));
    });
  };

  useEffect(() => {
    fireBaseListner();
    scrollToBottom();
  }, []);


  function scrollToBottom() {
    messagesEndRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "end",
    });
  }
  useEffect(() => {
    scrollToBottom();
  }, [MyMessages]);
  useEffect(() => {
    if (!props?.location?.state?.dispute) {
      // getMessagesByOrderId(params.id);
    } else {
      // getDisputeOrderMessages(params.id);
    }
    getNotifications(params.id);
    //getNotifications
  }, []);
  const getNotifications = (id) => {
    Get_Notifications(
      id,
      (value) => {},
      (value) => {},
      (value) => {
        setNotifications(value);
      }
    );
  };

  useEffect(() => {
    getOrderStatusById(params.id);
    if (!props?.location?.state?.dispute) {
      getMessagesByOrderId(params.id);
      scrollToBottom();
    } else {
      getDisputeOrderMessages(params.id);
      scrollToBottom();
    }

    const interval = setInterval(() => {
      if (!props?.location?.state?.dispute) {
        getMessagesByOrderId(params.id);
      } else {
        getDisputeOrderMessages(params.id);
      }
    }, 20000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const ServiceAndServiceProviderName = ({ orderDetails }) => {
    return (
      <div className="px-3 ">
        <p className="mb-0">
          <b className="mb-0 text-black">{labels.serviceName} : </b>
          <span>
            {orderDetails?.service?.[
              language === "english" ? "name" : "name_sp"
            ] || ""}
          </span>
        </p>
      </div>
    );
  };

  const getMessagesByOrderId = (id) => {
    Get_Message_by_Order_Id(
      id,
      (value) => {
        setShowSpinner(value);
      },
      (value) => {
        setSuccess(value);
        if (value == true) {
          getMessagesByOrderId(params.id);
        }
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
        // setMyMessages(value.reverse());
      }
    );
  };
  // get Dispute order messages
  const getDisputeOrderMessages = (id) => {
    Get_Dispute_Order_Messages(
      id,
      (value) => {
        setShowSpinner(value);
      },
      (value) => {
        setSuccess(value);
        if (value == true) {
          getDisputeOrderMessages(params.id);
        }
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
        setMyMessages(value.reverse());
      }
    );
  };

  function WriteMessage(messages) {
    // A post entry.
    const postData = {
      is_provider_message: "0",
      service_provider_id: props.location?.state?.allInfo?.service_provider_id,
      service_provider: props.location?.state?.allInfo?.service_provider,
      admin_id: null,
      messaageType: messaageType,
      admin: null,
      is_admin_message: "0",
      user_id: props.location?.state?.allInfo?.user_id,
      user: { first_name: firstName, last_name: lastName },
      message: messages,
      file: fileObject,
      time: serverTimestamp(),
    };

    const newPostKey = fbPush(child(ref(database), "posts")).key;

    runTransaction(
      ref(database, "orders/" + params?.id + "/userUnread"),
      () => {
        return 0;
      }
    );

    runTransaction(
      ref(database, "orders/" + params?.id + "/providerUnread"),
      (post) => {
        if (post === null) return 1;
        return post + 1;
      }
    );

    runTransaction(
      ref(database, "orders/" + params?.id + "/adminUnread"),
      (post) => {
        if (post === null) return 1;
        return post + 1;
      }
    );
    // Write the new post's data simultaneously in the posts list and the user's post list.
    const updates = {};

    // updates['/posts/' + newPostKey] = postData;
    updates["/orders/" + params?.id + "/messages/" + newPostKey] = postData;

    return update(ref(database), updates);
  }

  const sendMessageToProvider = async () => {
    // if (
    //   regexToCheckIfTheStringContainsPhoneNumber(
    //     extractContent(orderMessage?.toLowerCase())
    //   )
    // ) {
    //   addToast(labels.messageToAvoidSharingEmailAndPhoneNumber, {
    //     appearance: "error",
    //     autoDismiss: "150",
    //   });
    //   return;
    // }
    if (
      regexToCheckIfTheStringContainsEmail(
        extractContent(orderMessage?.toLowerCase())
      )
    ) {
      addToast(labels.messageToAvoidSharingEmailAndPhoneNumber, {
        appearance: "error",
        autoDismiss: "150",
      });
      return;
    }

    // if (
    //   extractContent(orderMessage?.toLowerCase())?.includes("email") ||
    //   extractContent(orderMessage?.toLowerCase())?.includes(
    //     "correo electrónico"
    //   )
    // ) {
    //   addToast(labels.messageToAvoidSharingEmailAndPhoneNumber, {
    //     appearance: "error",
    //     autoDismiss: "150",
    //   });
    //   return;
    // }
    // if (
    //   extractContent(orderMessage?.toLowerCase())?.includes("phone") ||
    //   extractContent(orderMessage?.toLowerCase())?.includes("teléfono")
    // ) {
    //   addToast(labels.messageToAvoidSharingEmailAndPhoneNumber, {
    //     appearance: "error",
    //     autoDismiss: "150",
    //   });
    //   return;
    // }

    // if (extractContent(orderMessage?.toLowerCase())?.includes("whatsapp")) {
    //   addToast(labels.messageToAvoidSharingEmailAndPhoneNumber, {
    //     appearance: "error",
    //     autoDismiss: "150",
    //   });
    //   return;
    // }
    // if (extractContent(orderMessage?.toLowerCase())?.includes("linkedin")) {
    //   addToast(labels.messageToAvoidSharingEmailAndPhoneNumber, {
    //     appearance: "error",
    //     autoDismiss: "150",
    //   });
    //   return;
    // }
    // if (extractContent(orderMessage?.toLowerCase())?.includes("facebook")) {
    //   addToast(labels.messageToAvoidSharingEmailAndPhoneNumber, {
    //     appearance: "error",
    //     autoDismiss: "150",
    //   });
    //   return;
    // }
    if (orderMessage !== "" || docs?.length !== 0) {
      let obj = {
        order_id: params.id,
        is_provider_message: 0,
        message: JSON.stringify([
          {
            message: orderMessage,
            documents: docs?.length === 0 ? null : docs,
          },
        ]),
      };
      Send_Message_To_Provider_Method(
        (value) => {
          setShowSpinner(value);
        },
        obj,
        (value) => {
          setSuccess(value);
        },
        (value) => {
          setMessage(value);
          // addToast(value, { appearance: "info", autoDismiss: "150" });
        },
        (value) => {
          setShowToast(value);
        },
        () => {
          setShowMessagePopup(false);
          setOrderMessage("");
          setDocs([]);
          setMuiltipleDocument("");
        }
      );

      WriteMessage([
        {
          message: orderMessage === "" ? null : orderMessage,
          documents: docs?.length === 0 ? null : docs,
        },
      ]);
      document.getElementById("raised-button-file").value = "";
      setDocs([]);
      setOrderMessage("");
      setFileFileObject([]);

      setTimeout(() => {}, 4000);
    } else {
      setShowToast(true);
      setMessage(labels.allFieldsAreRequired);
    }
  };

  const sendDisputeOrderMessage = () => {
    if (orderMessage !== "" || muiltipleDocument !== "") {
      let obj = {
        order_id: params.id,
        is_provider_message: 0,
        is_admin_message: 0,
        message: JSON.stringify([
          {
            message: orderMessage,
            documents: docs?.length === 0 ? null : docs,
          },
        ]),
      };
      Disputed_Order_Message(
        (value) => {
          setShowSpinner(value);
        },
        obj,
        (value) => {
          setSuccess(value);
        },
        (value) => {
          setMessage(value);
          addToast(value, { appearance: "info", autoDismiss: "150" });
        },
        (value) => {
          setShowToast(value);
        },
        () => {
          setShowMessagePopup(false);
          setOrderMessage("");
          setDocs([]);
          setMuiltipleDocument("");
        }
      );
    } else {
      setShowToast(true);
      setMessage(labels.allFieldsAreRequired);
    }
  };

  const closeToast = () => {
    setShowToast(false);
  };

  const getUrl = (url, name, type) => {
    setDocs((prevurls) => [...prevurls, { url, name, type }]);
  };
  const selectMuiltipleDocument = async (valueArray) => {
    let muiltipleFileNames = "";
    valueArray?.map((r, i) => {
      muiltipleFileNames += `${r.name}, `;
    });

    valueArray?.map((file, i) => {
      let formData = new FormData();
      formData.append("file", file);
      Get_File_Url_Method(
        formData,
        (value) => {
          getUrl(value, file.name, file.type);

          let obj = {
            url: value,
            name: file?.name,
            type: file?.type,
          };

          setFileFileObject(obj);
          setMessageType(1);
        },
        (value) => {
          setSuccess(value);
        },
        (value) => {
          setMessage(value);
        },
        (value) => {
          setShowToast(value);
        }
      );
    });
    setMuiltipleDocument(muiltipleFileNames);
  };
  const getOrderStatusById = (id) => {
    Get_Order_By_Id(
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
        setSuccess(value);
      },
      id,
      (value) => {
        setOrderDetails(value);
      }
    );
  };

  const { addToast } = useToasts();
  return (
    <>
      {/* <div className="d-flex"></div> */}
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <div className="container-fluid px-6 marginTop100 d-flex justify-content-center chat-screen">
        <h4>{props?.location.state?.serviceName}</h4>
        <h4>{props?.location?.state?.providerName}</h4>

        <div
          className="d-flex ml-1 cursorPointer  h-100"
          onClick={() => {
            history.goBack();
          }}
        >
          <img
            src={Images.backArrow.default}
            style={{ width: "40px", height: "40px" }}
            className=" mt-30"
          />{" "}
        </div>

        <Row>
          <Col lg={12} sm={10} xs={10} md={10}>
            <div
              style={{ height: "380px", width: "800px" }}
              className="contentPadding"
            >
              <Tabs
                defaultActiveKey="home"
                activeKey={tab}
                onSelect={(e) => setTab(e)}
                id="uncontrolled-tab-example"
                className="nav nav-tabs customtab mb-10 d-flex justify-content-center mt-40"
              >
                <Tab eventKey="home" title={labels.messageOnly}>
                  <ServiceAndServiceProviderName orderDetails={orderDetails} />
                  <Col
                    lg={12}
                    md={12}
                    xs={12}
                    sm={12}
                    className="d-flex justify-content-center px-0 "
                  >
                    <Col
                      lg={12}
                      sm={12}
                      md={12}
                      xs={12}
                      id={"messagesArea"}
                      className="messageContainer py-3 scrollable-element chat-Container p-0  flex-column border-Radius mt-0"
                      style={{
                        overflowY: "scroll",
                        height: "250px",
                        width: "800px",
                      }}
                    >
                      <div className="">
                        {MyMessages?.map((item, index) =>
                          item?.is_provider_message == 1 ||
                          (item?.is_admin_message == 1 &&
                            item?.message[0]?.message?.length !== 0) ? (
                            <>
                              <div className="incoming_msg">
                                {item?.message[0]?.message !== "<p><br></p>" ? (
                                  <div className="received_msg p-1">
                                    <div className="received_withd_msg">
                                      <span className="d-flex justify-content-between">
                                        {item?.is_provider_message == "1" && (
                                          <div className="d-flex align-items-end mb-1 pb-1 border-bottom">
                                            <div>
                                              <img
                                                width="30"
                                                className="rounded-circle"
                                                src={
                                                  orderDetails?.service_provider
                                                    ?.avatar
                                                }
                                              />
                                            </div>
                                            <div className="ml-2">
                                              <div
                                                style={{
                                                  padding: 0,
                                                  margin: 0,
                                                  lineHeight: "15px",
                                                }}
                                              >
                                                <small className="font-weight-Regular text-black ">
                                                  {orderDetails
                                                    ?.service_provider?.name ||
                                                    labels.provider}
                                                </small>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        {item?.is_admin_message == "1" && (
                                          <div className="">
                                            <img
                                              width="80"
                                              src={
                                                docuDocAdminChatLogo?.default
                                              }
                                            />
                                          </div>
                                        )}
                                      </span>
                                      {typeof item?.message[0]?.message !==
                                        "undefined" && (
                                        <div className="">
                                          <p>
                                            <InformationContainer
                                              info={
                                                (item?.message[0] !=
                                                  "<p><br></p>" &&
                                                  item?.message[0]?.message) ||
                                                ""
                                              }
                                            />
                                          </p>
                                        </div>
                                      )}

                                      {(item?.is_provider_message == "1" ||
                                        item?.is_admin_message == 1) &&
                                        item?.message?.[0]?.documents?.map(
                                          (itemNumber) => itemNumber
                                        )?.length &&
                                        item?.message[0]?.documents?.map(
                                          (itemNumber, index) => {
                                            return (
                                              <a
                                                href={itemNumber.url}
                                                target="_blank"
                                                className="row alignItemsCenter ml-1 fileAttachment"
                                                download
                                              >
                                                <img
                                                  className="documentImageSize"
                                                  src={
                                                    item !== "null" &&
                                                    Images?.document?.default
                                                  }
                                                />
                                                {item?.length !== "null" && (
                                                  <h6 className="ml-1 mb-1 text-info">
                                                    {itemNumber?.name}
                                                  </h6>
                                                )}
                                              </a>
                                            );
                                          }
                                        )}
                                    </div>
                                    <span className="d-flex justify-content-end time_date">
                                      {`${moment(item?.timestamp).format(
                                        "H:mm"
                                      )} || ${moment(item?.timestamp).format(
                                        "DD-MM-YYYY"
                                      )}`}
                                    </span>
                                  </div>
                                ) : null}
                              </div>
                            </>
                          ) : (
                            <>
                              {(item?.message?.[0]?.message?.length !== 0 ||
                                item?.message?.[0]?.documents.length !== 0) && (
                                <div className="outgoing_msg p-1">
                                  {item?.message?.[0]?.message !== null &&
                                  item?.message?.[0]?.message !==
                                    "<p><br></p>" ? (
                                    <div className="sent_msg">
                                      {item?.message?.[0]?.message?.length !==
                                        0 &&
                                        typeof item?.message?.[0]?.message !==
                                          "undefined" && (
                                          <p>
                                            {" "}
                                            <InformationContainer
                                              info={
                                                item?.message?.[0]?.message ||
                                                ""
                                              }
                                            />{" "}
                                          </p>
                                        )}
                                      <div className="">
                                        {item.is_provider_message == "0" &&
                                          item?.message[0]?.documents?.map(
                                            (itemNumber) => itemNumber
                                          )?.length &&
                                          item?.message[0]?.documents?.map(
                                            (itemNumber, index) => {
                                              return (
                                                <a
                                                  href={itemNumber?.url}
                                                  target="_blank"
                                                  className="row alignItemsCenter ml-1 fileAttachment"
                                                  download
                                                >
                                                  <img
                                                    className="documentImageSize"
                                                    src={
                                                      item !== "null" &&
                                                      Images?.document?.default
                                                    }
                                                  />
                                                  {item?.length !== "null" && (
                                                    <h6 className="ml-1 mb-1 text-info">
                                                      {itemNumber?.name}
                                                    </h6>
                                                  )}
                                                </a>
                                              );
                                            }
                                          )}
                                      </div>
                                      <span className="time_date">{`${moment(
                                        item?.timestamp
                                      ).format("H:mm")} || ${moment(
                                        item?.timestamp
                                      ).format("DD-MM-YYYY")}`}</span>{" "}
                                    </div>
                                  ) : null}
                                </div>
                              )}
                              <></>
                            </>
                          )
                        )}
                        <div ref={messagesEndRef} />
                      </div>
                    </Col>
                  </Col>
                </Tab>
                <Tab
                  eventKey="menu1"
                  className="mb-5"
                  title={labels.orderNotification}
                >
                  <ServiceAndServiceProviderName orderDetails={orderDetails} />

                  <div
                    className="messageContainer chat-Container px-3"
                    style={{ overflowY: "scrollY", height: "380px" }}
                  >
                    <div
                      className="row Document-Container ql-editor2 mb-5"
                      style={{ overflowY: "scroll", height: "380px" }}
                    >
                      <div className="col-12 px-0">
                        {notifications
                          ?.filter((item) => item?.order_id == params.id)
                          ?.map((item, index) => {
                            return (
                              <div
                                className="notification-list notification-list--unread d-flex justify-content-start cursorPointer"
                                onClick={() => {
                                  if (item?.type == "placed") {
                                    localStorage.setItem(
                                      "orderCurrentActiveTab",
                                      "home"
                                    );
                                  }
                                  if (item?.type == "completed") {
                                    localStorage.setItem(
                                      "orderCurrentActiveTab",
                                      "menu1"
                                    );
                                  }
                                  if (item?.type == "canceled") {
                                    localStorage.setItem(
                                      "orderCurrentActiveTab",
                                      "menu2"
                                    );
                                  }
                                  if (item?.type == "canceled") {
                                    localStorage.setItem(
                                      "orderCurrentActiveTab",
                                      "menu3"
                                    );
                                  }
                                  push({
                                    pathname:
                                      item?.type == "placed" ||
                                      item?.type == "completed" ||
                                      item?.type == "canceled"
                                        ? "/1/myorder"
                                        : `/orderdetail/${params?.id}`,

                                    state: {
                                      taber:
                                        item?.type == "placed"
                                          ? "1"
                                          : item?.type == "canceled"
                                          ? "cancel"
                                          : item?.type == "completed"
                                          ? "completed"
                                          : item?.type == "further"
                                          ? " further"
                                          : " further",
                                    },
                                  });
                                }}
                              >
                                <div className="notification-list_img d-flex ">
                                  {item?.type == "further" && (
                                    <img src={Images.request.default} />
                                  )}
                                  {item?.type == "budget" && (
                                    <img src={Images?.additional.default} />
                                  )}
                                  {item?.type == "placed" && (
                                    <img src={Images.orderPlaced.default} />
                                  )}
                                  {item?.type == "completed" && (
                                    <img src={Images.orderPlaced.default} />
                                  )}
                                  {item?.type == "canceled" && (
                                    <img src={Images.cancel.default} />
                                  )}
                                </div>
                                <div className="notification-list_detail ">
                                  <p>
                                    <b>
                                      {
                                        labels?.[
                                          functionToConvertStringToCamelCase(
                                            item?.notification
                                          )
                                        ]
                                      }
                                    </b>
                                  </p>
                                  <p>
                                    <small>
                                      {moment(item?.created_at).format(
                                        "YYYY-MM-DD hh:mm:ss"
                                      )}
                                    </small>
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
            {tab === "home" ? (
              <>
                {["completed", "cancelled"]?.includes(
                  orderDetails?.status
                ) ? null : (
                  <>
                    <div
                      className="richTextContainer messageToProviderContainer"
                      style={{ width: "800px", marginTop: "0px" }}
                    >
                      <ReactQuill
                        style={{ marginTop: "0px" }}
                        className={"richTextBox mx-0 mb-0"}
                        theme="snow"
                        value={orderMessage}
                        onChange={setOrderMessage}
                      />
                    </div>
                    <Row className="mt-20 mb-20 ">
                      <Col>
                        <div className="d-flex items-center">
                          <span className="form-group  ">
                            <input
                              className="form-control cursorPointer "
                              type="file"
                              multiple
                              accept=".jpg, .jpeg, .png, .doc, .docx, .docs, .pdf, .xls, .csv, .xslx , .docx , .xlsx"
                              placeholder={
                                fileArrayLength
                                  ? `${fileArrayLength} files uploaded`
                                  : labels.uploadFile
                              }
                              style={{ width: "auto" }}
                              id="raised-button-file"
                              onChange={(e) => {
                                let f = e.target.files;
                                let fileArray = [];
                                for (var i = 0; i < f?.length; i++) {
                                  fileArray.push(f[i]);
                                }

                                selectMuiltipleDocument(fileArray);
                                setFileArrayLength(fileArray?.length);
                              }}
                            />
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div className="textAlignCenter ">
                          <a
                            className="btn btn-primary"
                            data-toggle="modal"
                            onClick={() => {
                              sendMessageToProvider();
                            }}
                          >
                            {labels.submit}
                          </a>
                        </div>
                      </Col>
                      <Col></Col>
                    </Row>
                  </>
                )}
              </>
            ) : (
              <div style={{ height: "50px" }}></div>
            )}
          </Col>
        </Row>

        <Modal
          show={showMessagePopup}
          onHide={() => setShowMessagePopup(false)}
          size="lg"
        >
          <ModalBody className="bg-white p-0 border-radius-10 alignItemsCenter">
            <button
              type="button"
              className="close absolute"
              data-dismiss="modal"
              onClick={() => setShowMessagePopup(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="richTextContainer">
              <ReactQuill
                className={"richTextBox"}
                theme="snow"
                value={orderMessage}
                onChange={setOrderMessage}
              />

              <div className="d-flex justify-content-center">
                <div className="form-group">
                  <div
                    className="select-input-style border-input-style d-flex justify-content-center align-items-center border-Radius"
                    style={{ bottom: 0, background: "transparent" }}
                  >
                    <FaFileAlt color="#46bcff" />
                    {muiltipleDocument !== "" ? (
                      <span className="ml-2">{muiltipleDocument}</span>
                    ) : (
                      <span className="ml-2">{labels.uploadFiles}</span>
                    )}
                  </div>
                  <input
                    className="form-control cursorPointer"
                    type="file"
                    multiple
                    // style={{ opacity: 0, cursor: "pointer" }}
                    value={""}
                    name="name"
                    placeholder=""
                    onChange={(e) => {
                      let f = e.target.files;
                      let fileArray = [];
                      for (var i = 0; i < f?.length; i++) {
                        fileArray.push(f[i]);
                      }
                      selectMuiltipleDocument(fileArray);
                    }}
                  />
                </div>
              </div>
              <div className="textAlignCenter mt-20 mb-20">
                <a
                  className="btn btn-primary"
                  data-toggle="modal"
                  onClick={() => {
                    sendMessageToProvider();
                    // WriteMessage()
                  }}
                >
                  {labels.submit}
                </a>
              </div>
            </div>
          </ModalBody>
          {showToast === true ? (
            <ToastComponent
              message={message}
              toastClose={closeToast}
              success={success}
            />
          ) : (
            ""
          )}
        </Modal>
      </div>
    </>
  );
}
