import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ServiceProviderCard from "../../component/serviceProviderCard";
import ToastComponent from "../../component/toast";
import { Get_Orders_Method } from "../../config/api";
import labels from "../../config/Labels";
import { GetAllOrders } from "../../store/actions/orders";
import moment from "moment";
import { Helmet } from "react-helmet";

export default function MyMessages(props) {
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [orderMessages, setOrderMessages] = useState([]);

  const { push } = useHistory();
  const dispatch = useDispatch();
  const ordersListing = useSelector((state) => state?.orders?.orders);
  const orderLoading = useSelector((state) => state?.orders?.loading);
  useEffect(() => {
    window.scrollTo(0, 0);
    // getOrderMessages();
    dispatch(
      GetAllOrders((failure) => {
        setMessage(failure);
        setSuccess(false);
        setShowToast(true);
      })
    );
  }, []);
  const getOrderMessages = () => {
    Get_Orders_Method(
      (value) => {
        setShowSpinner(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
        // let ordersArray = [...value.pending_orders];
        let ordersArray = [
          ...(value?.pending_orders || []),
          ...(value?.disputed_orders || []),
          ...(value?.dispute_orders || []),
          ...(value?.completed_orders || []),
          ...(value?.cancelled_orders || []),
        ];
        setOrderMessages(ordersArray);
      }
    );
  };

  const closeToast = () => {
    setShowToast(false);
  };

  useEffect(() => {
    let ordersArray = [
      ...(ordersListing?.pending_orders || []),
      ...(ordersListing?.disputed_orders || []),
      ...(ordersListing?.dispute_orders || []),
      ...(ordersListing?.completed_orders || []),
      ...(ordersListing?.cancelled_orders || []),
    ];
    //filter those item whose message array in not empty
    const filterMessagesData = ordersArray?.filter((item) => {
      return item?.order_chat?.messages.length;
    });

    //sorting Messages
    const sortedMessages = filterMessagesData.sort((a, b) => {
      return (
        moment(b?.order_chat?.messages[0]?.created_at).valueOf() -
        moment(a?.order_chat?.messages[0]?.created_at).valueOf()
      );
    });

    setOrderMessages(sortedMessages);
  }, [ordersListing]);
  useEffect(() => {
    setShowSpinner(orderLoading);
  }, [orderLoading]);

  return (
    <>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <div className="col-lg-12 col-md-12 mb-20">
        <Row>
          {showSpinner && labels.loading}
          {orderMessages
            .filter(
              (opt) =>
                opt.order_chat !== null && opt.order_chat.messages.length !== 0
            )
            ?.map((item, index) => (
              <ServiceProviderCard
                key={index}
                item={item}
                showMessageUnreadCount={true}
                onClickFunction={() =>
                  push({
                    pathname: `/specficordermessage/${item?.order_id}`,
                    state: { dispute: false, allInfo: item },
                  })
                }
              />
            ))}
        </Row>
        {showToast === true ? (
          <>
            <ToastComponent
              message={message}
              toastClose={closeToast}
              success={success}
            />
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
