// import { Modal } from 'bootstrap';
import React, { useEffect, useRef, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import { ServiceArea, ServiceItem } from "../../component";
import { Get_Profile_Method } from "../../config/api";
import { useStoreItem } from "../../config/Store";
import { Images } from "../../constant/images";

import { memo } from "react";
import "./intro.css";
import { Helmet } from "react-helmet";
const Introduction = (props) => {
  let language = localStorage.getItem("primaryLanguage");

  const [beneficiaries, setBeneficiaries] = useState([]);
  const [serviceAreas, setServiceAreas] = useState([
    {
      avatar: Images.abogado.default,

      created_at: "2021-12-27T09:13:50.000000Z",
      id: 1,
      info: null,
      name: language == "english" ? "Lawyer" : " Abogado",
      service_area_id: "bd4b5a26-521d-4904-9236-e1772ba63034",
      updated_at: "2021-12-27T09:13:50.000000Z",
    },

    {
      avatar: Images.gestoria.default,
      created_at: "2021-12-27T09:13:51.000000Z",
      id: 3,
      info: null,
      name: language == "english" ? "Agency" : " Gestor",
      service_area_id: "b9abec2b-53cb-428f-8d51-b0406c9ad4e0",
      updated_at: "2021-12-27T09:13:51.000000Z",
    },
    {
      avatar: Images.assesment.default,
      created_at: "2021-12-27T09:13:51.000000Z",
      id: 3,
      info: null,
      name: language == "english" ? "Assessor" : "Asesor",
      service_area_id: "e9673fd6-87a1-48be-8442-efbd8c6009c3",
      updated_at: "2021-12-27T09:13:51.000000Z",
    },
  ]);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [testCompo, settestCompo] = useState(null);
  const [AreaName, setAreaName] = useState("");
  const target = useRef(null);
  const messagesEndRef = useRef(null);
  const { updateUser } = useStoreItem();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    // Get_BeneFiciaries_Method(
    //   (value) => setMessage(value),
    //   (value) => setShowToast(value),
    //   (value) => setSuccess(value),
    //   (value) => {
    //     setBeneficiaries(value);
    //   }
    // );

    checkToken();
  }, []);
  useEffect(() => {
    setAreaName(props?.location.serviceAreaName);
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  React.useEffect(() => {
    if (props.location.state === "serviceArea") {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
  }, [props.location.state]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // useEffect(() => {
  //   if (beneficiaries?.length !== 0) {
  //     Get_Service_Areas_By_Beneficiary_Id(
  //       beneficiaries?.[0]?.beneficiary_id,
  //       (value) => setMessage(value),
  //       (value) => setShowToast(value),
  //       (value) => setSuccess(value),
  //       (value) => {
  //         setServiceAreas(value);
  //       }
  //     );
  //   }
  // }, [beneficiaries]);

  const checkToken = () => {
    let token = localStorage.getItem("usertoken");
    if (token !== null) {
      Get_Profile_Method(
        (value) => {
          setMessage(value);
        },
        (value) => {
          setShowToast(value);
        },
        (value) => {
          setSuccess(value);
        },
        (value) => {
          updateUser(value);
        }
      );
    }
  };

  const closeToast = () => {
    setShowToast(false);
  };

  useEffect(() => {
    var compo = { 1: [] };
    beneficiaries.map((item, index) => {
      // alert(JSON.stringify(item))
      if (index > 0 && index < 3) {
        compo[1]?.push(
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
            <ServiceItem
              name={item.name}
              image={item.avatar}
              beneId={item.beneficiary_id}
              info={item.info}
            />
          </ScrollAnimation>
        );
      } else {
        compo = {
          ...compo,
          [index]: (
            <div className="col-md-4 px-0">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                <ServiceItem
                  name={item.name}
                  image={item.avatar}
                  beneId={item.beneficiary_id}
                  info={item.info}
                />
              </ScrollAnimation>
            </div>
          ),
        };
      }
      settestCompo(compo);
    });
  }, [beneficiaries]);

  return (
    <Container className="" fluid>
      <>
        <Helmet>
          <title>DocuDoc App | Gestiones y Consultas Legales</title>
          <meta
            name="description"
            content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
          />
          {/* Add more meta tags, link tags, etc. as needed */}
        </Helmet>
        <ServiceArea
          serviceAreas={serviceAreas}
          beneId={beneficiaries?.[0]?.beneficiary_id}
          activeMenu={props?.location?.state}
          ServiceAreaName={AreaName}
        />

        {/* <Row>
          <Col xs="12">
            {" "}
            <div id="canvas" className="site">
              <div id="box_wrapper">
                <span className="toggle_menu_side header-slide">
                  <span></span>
                </span>

                <div className="py-60"></div>
              </div>
            </div>
          </Col>
        </Row> */}
      </>
    </Container>
  );
};

export default memo(Introduction);
