import React from "react";
import { Col, Row, Toast } from "react-bootstrap";

const ToastComponent = ({ showToast, toastClose, message, success }) => {
  return (
    <Toast
      onClose={toastClose}
      color="rgba(70, 188, 255, 1)"
      show={showToast}
      delay={1000}
      style={{
        backgroundColor: success === true ? "#5cb85c" : "red",
        color: "#ffffff",
        width: "300px",
        height: "50px",
        position: "absolute",
        top: "0",
        left: "35%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        zIndex: 2000,
      }}
      autohide
    >
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
};

export default ToastComponent;
