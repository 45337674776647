import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ServiceProviderCard from "../../component/serviceProviderCard";
import ToastComponent from "../../component/toast";
import { Get_Orders_Method } from "../../config/api";
import labels from "../../config/Labels";
import { GetAllOrders } from "../../store/actions/orders";
const Alerts = (props) => {
  const dispatch = useDispatch();
  const ordersListing = useSelector((state) => state?.orders?.orders);
  const orderLoading = useSelector((state) => state?.orders?.loading);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [orders, setOrders] = useState([]);
  const { push } = useHistory();

  const closeToast = () => {
    setShowToast(false);
  };

  useEffect(() => {
    dispatch(
      GetAllOrders((failure) => {
        setMessage(failure);
        setSuccess(false);
        setShowToast(true);
      })
    );
  }, []);
  useEffect(() => {
    let ordersArray = [
      ...(ordersListing?.pending_orders || []),
      ...(ordersListing?.disputed_orders || []),
      ...(ordersListing?.dispute_orders || []),
      ...(ordersListing?.completed_orders || []),
      ...(ordersListing?.cancelled_orders || []),
    ];
    setOrders(ordersArray);
  }, [ordersListing]);
  useEffect(() => {
    setShowSpinner(orderLoading);
  }, [orderLoading]);

  return (
    <Fragment>
      <div>{showSpinner ? labels.loading : null}</div>
      {orders?.map((item, index) => (
        <ServiceProviderCard
          key={index}
          item={item}
          showAlertUnreadCount={item?.notifications_count}
          onClickFunction={() =>
            push({
              pathname: `/order-notification/${item?.order_id}`,
              state: { dispute: false, allInfo: item },
            })
          }
        />
      ))}
      {showToast === true ? (
        <>
          <ToastComponent
            message={message}
            toastClose={closeToast}
            success={success}
          />
        </>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Alerts;
