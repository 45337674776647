import labels from "../../config/Labels";
import { Images } from "../../constant/images";
import MyOrder from "../order/myOrder";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
import { Modal, ModalBody } from "react-bootstrap";

const ThankYou = ({ location }) => {
  const { push } = useHistory();
  const  message  = location?.state?.message;
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  useEffect(() => {
    if(message) {
        setShowConfirmationPopup(true)
    } else {
        push("/1/myorder");
    }
  },[message])
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showConfirmationPopup]);

  return (
    <>
      <MyOrder />;
      <Modal
        className="modelBackground"
        show={showConfirmationPopup}
        // onHide={() => setShowConfirmationPopup(false)}
        size="lg"
      >
        <ModalBody className="bg-white border-radius-10 p-20 w-50 alignItemsCenter textAlignCenter m-auto height_72 my-20">
          <div>
            <img className="mt-20" src={Images.check.default} />
            <h5 className="mx-30 ">{message}</h5>
            <button
              onClick={() => {
                localStorage.setItem("orderCurrentActiveTab", "home");
                push("/1/myorder");
                setShowConfirmationPopup(false);
              }}
              className="mb-3  ml-1"
            >
              {labels.done}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ThankYou;
