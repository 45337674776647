import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { Delete_Draft_Order, Get_Draft_Order } from "../config/api";
import labels from "../config/Labels";
import { replaceDecimalWithComma } from "../config/Utility";
import { Images } from "../constant/images";
export default function DraftOrderItem({
  id,
  name,
  des,
  time,
  price,
  discount,
  path,
  image,
  promo,
  item,
  serviceId,
  draftList,
}) {
  const { push } = useHistory();
  const history = useHistory();
  const { addToast } = useToasts();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [Navigate, setNavigate] = useState(true);
  const [draftOrder, setDraftOrder] = useState([]);


  const getUpdatedDraftList = () => {
    Get_Draft_Order(
      (value) => {
        // setShowSpinner(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        // setShowToast(value);
      },
      (value) => {
        draftList(value);
      }
    );
  };
  const navigateFunction = () => {
    localStorage.setItem("storedId", serviceId);
    push({
      pathname: path,
      state: {
        draft: "draftorder",
        item: item,
        serviceId: serviceId,
        service_id: serviceId,
        name: name,
        image: image,
        avatar: image,
        price: price,
        selected_serviceItem: item?.data?.[0]?.selected_serviceItem,
      },
    });
  };
  return (
    <div className="pdpt-bg w-100 mb-2 cursorPointer save-draft-card position-relative">
      <Card className="position-relative">
        <Card.Body className="order-body10  ">
          <Row>
            <Col sm={2} onClick={() => navigateFunction()}>
              <img
                src={image ? image : ""}
                alt="Shop item in cart"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
            </Col>
            <Col sm={9} className="" onClick={() => navigateFunction()}>
              <div className="product-details d-flex h-100 flex-column justify-content-between align-content-between">
                <div>
                  <h4>{name || "Title"}</h4>
                  <div className="text-success">
                    € {replaceDecimalWithComma(price)}
                  </div>
                  <div></div>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/* <div
        onClick={() => {
          Navigate &&
            push({
              pathname: path,
              state: {
                draft: "draftorder",
                item: item,
                serviceId: serviceId,
                name: name,
                image: image,
                price: price,
              },
            });
        }}
      >
        <div className="order-body10 mb-2">
          <ul className="order-dtsll">
            <li>
              <div className="order-dt-img">
                <img
                  src={image}
                  style={{
                    objectFit: "contain",
                    width: "80px",
                    height: "80px",
                  }}
                />
              </div>
            </li>
            <li className=" ">
              <div className="order-dt47 pt-3 ">
                <div className="product-details">
                  <h4>{name}</h4>

                  <div className="d-flex">
                    <p className="text-success">{labels.price}: </p>
                    <p className="text-success ml-2">{price}</p>
                  </div>
                  <div className="d-flex">
                    <p className="">{labels.date}:</p>
                    <p className="ml-2">{des}</p>
                    <p className="ml-2">{time}</p>
                  </div>
                </div>
              </div>
            </li>
            <div
              onMouseOver={() => {
                setNavigate(false);
              }}
              onMouseLeave={() => {
                setNavigate(true);
              }}
              className="d-flex  justify-content-end cursorPointer "
              onClick={() => {
                Delete_Draft_Order(
                  id,
                  (value) => {
                    setSuccess(value);
                    if (value) {
                      getUpdatedDraftList();
                    }
                  },
                  (value) => {
                    setMessage(value);
                    addToast(value, {
                      autoDismiss: 1000,
                    });
                  }
                );
              }}
            >
              <Trash color="red" size={20} />
            </div>
          </ul>
        </div>
      </div> */}
      <img
        // onClick={() => setModalShow(true)}
        src={Images.infoo.default}
        className="position-absolute"
        style={{
          width: 20,
          height: 20,
          top: "35%",
          marginRight: "15px",
          right: 0,
          cursor: "pointer",
        }}
      />
      <div
        onClick={() => {
          Delete_Draft_Order(
            id,
            (value) => {
              setSuccess(value);
              if (value) {
                getUpdatedDraftList();
              }
            },
            (value) => {
              setMessage(value);
              addToast(value, {
                autoDismiss: 1000,
              });
            }
          );
        }}
        className="position-absolute"
        style={{ top: "75%", right: 0, marginRight: "15px" }}
      >
        <Trash color="red" size={20} />
      </div>
    </div>
  );
}

{
  /* <Card className="shadow-none border-0">
<Card.Body className="p-1">
  <Row>
    <Col
      sm={2}
      className="d-flex"
      onClick={() => onClickFunction()}
    >
      <img
        src={image ? image : ""}
        alt="Shop item in cart"
        style={{
          width: "100px",
          height: "100px",
          objectFit: "contain",
        }}
      />
    </Col>
    <Col sm={10} onClick={() => onClickFunction()}>
      <h3 className="mx-10 tdr-paragraph Proxima-Nova-Bold fontSize16px">
        {title}
      </h3>
      <h6
        className="text-success mb-0 mt-0 mx-10 ProximaNovaRegular"
        style={{ fontSize: "18px" }}
      >
        {price !== "" && path == "documentaion" && (
          <b>
            €
            {replaceDecimalWithComma(
              Number(price) + Number(supplementaryFees)
            )}
            {price !== "" &&
              servicePak !== "fixed price" &&
              `/ ${
                labels?.[
                  functionToConvertStringToCamelCase(servicePak)
                ]
              }`}
          </b>
        )}
      </h6>

      <p
        className="crossTextSpecialService mx-10 mb-0 ProximaNovaRegular"
        style={{ fontSize: "18px" }}
      >
        {price !== "" && path == "documentaion" && (
          <span className="text-danger">
            €
            {replaceDecimalWithComma(
              Number(discount) + Number(supplementaryFees)
            )}{" "}
            {replaceDecimalWithComma(
              ((price - discount) / price) * 100
            )}
            % {labels.discount}
          </span>
        )}
      </p>
      <p
         className="crossTextSpecialService mx-10 ProximaNovaRegular text-success"
        style={{ fontSize: "18px" }}
      >
        {servicePak === "monthly" ? (
          labels?.monthly
        ) : (
          <>
            {labels.estimatedTime} {item?.time}{" "}
            {languageTranslationFunctionForTheTime(
              item?.time,
              item?.time_type
            )}
          </>
        )}
      </p>
    </Col>
  </Row>
</Card.Body>
</Card>
<img
onClick={() => setModalShow(true)}
src={Images.infoo.default}
className="position-absolute"
style={{
  width: 20,
  height: 20,
  top: "40%",
  right: 0,
  cursor: "pointer",
}}
/> */
}
