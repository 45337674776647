import React from "react";

export default function PrivacyPolicy() {
  return (
    <div
      className="container"
      style={{ marginBottom: "160px", marginTop: "160px" }}
    >
      <h2 style={{ textAlign: "center" }}>
        Privacy and Data Protection Policy
      </h2>
      <p style={{ textAlign: "center" }}>
        Information in compliance with personal data protection regulations
      </p>
      <p style={{ textAlign: "center" }}>
        <em>Date of last modification: June 20, 2024</em>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          In Europe and Spain there are data protection regulations designed to
          protect your personal information that are mandatory for our entity.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Therefore, it is very important for us that you fully understand what
          we are going to do with the personal data we ask for.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          This way, we will be transparent and give you control of your data,
          with simple language and clear options that will allow you to decide
          what we will do with your personal information.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Please, if after reading this information you have any questions, do
          not hesitate to ask us.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Thank you very much for your help.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>About us?</strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>O</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>Our name:</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>DOCUDOC APP S.L.</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>O</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>Our CIF / NIF:</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>B19738905</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>O</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>Our main activity:</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>Service provision intermediation</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>O</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>Our address:</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>CL RAMON Y CAJAL 5, 28100 - Madrid (Madrid)</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>O</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Our contact telephone number:
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>684005810</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>O</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Our contact email address:
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>info@docudocapp.com</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>O</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>Our website:</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>https://www.docudocapp.com/</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>O</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          For your confidence and security, we inform you that we are an entity
          registered in the following Commercial Registry/Public Registry:
          Madrid Commercial Registry.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>We are</span>
        at your disposal, do not hesitate to contact us.
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Processing of data of Users and people who contact DocuDoc
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>3.1 Processed data</span>
      </p>
      <ol>
        <li>a) Information Users provide us directly:</li>
      </ol>
      <ul>
        <li>
          <em>Registration Data</em>: the information that the User provides us
          when an account is created on the DocuDoc Platform: Names, surnames
          and the user's email address.
        </li>
        <li>
          <em>User Profile Information</em>: the information that the User adds
          to the Platform in order to use the DocuDoc service, that is, the
          User's mobile phone number, the User's delivery address and the User's
          billing information. The User can view and edit the personal data of
          their profile whenever they deem appropriate. DocuDoc does not store
          the User's credit card data, but these are provided to licensed
          electronic payment service providers, who will directly receive the
          included data and store it to facilitate the payment process for the
          User and manage it on behalf of the User. by DocuDoc. Under no
          circumstances is this data stored on DocuDoc servers. The User may, at
          any time, delete their card data linked to their account. With this
          action, the service provider will proceed to delete the data and it
          will be necessary to re-enter or select it in order to place new
          orders through the Platform. At any time the User may request the
          privacy policies of these providers.
        </li>
        <li>
          <em>
            Additional information that the User wants to share to carry out the
            service
          </em>
          : the information that the User could provide to DocuDoc for other
          purposes. For example, a copy of your identification document.
        </li>
        <li>
          <em>Information about communications made through DocuDoc</em>:
          DocuDoc will have access to information that Users provide to resolve
          doubts or complaints about the use of the platform, either through the
          contact form, by email or through customer service by telephone.
        </li>
        <li>
          Information about accidents involving any of the parties involved in
          the provision of services through the Platform for the presentation of
          claims or other actions with the insurers contracted by DocuDoc.
        </li>
        <li>
          Transcription and recording of the conversations held by the User with
          DocuDoc for the processing of incidents, doubts or other queries that
          may be made.
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>
            Information about communications between users and service providers
          </em>
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          : DocuDoc will have access to the communications that Users make with
          the Service Providers that collaborate with the Platform through the
          chat provided on the Platform.
        </span>
      </p>
      <ol>
        <li>b) Information that Users provide us indirectly:</li>
      </ol>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>-</span>
        <span style={{ color: "rgb(74, 74, 74)" }} />
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>Data derived from the Use of the Platform:</em>
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc collects the data derived from the Use of the Platform by the
          User every time the User interacts with the Platform.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>App and device data:</em>
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc stores the data of the device and the Application that the
          User uses to access the services. These are:
        </span>
      </p>
      <ul>
        <li>
          Internet IP address that the User uses to connect to the Internet with
          their computer or mobile phone.
        </li>
        <li>
          Information from your computer or mobile, such as your Internet
          connection, your browser type, version and operating system, and
          device type.
        </li>
        <li>
          The complete Clikstream of uniform resource locators (URLs), including
          date and time.
        </li>
        <li>
          <em>User account data</em>: the information on the orders placed by
          the User, as well as the ratings and/or comments made about them.
        </li>
        <li>The User's browsing history and preferences.</li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>Data derived from the origin of the User:</em>
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          If the User reaches the DocuDoc Platform through an external source
          (such as a link from another website or a social network), DocuDoc
          collects the data from the source from which the DocuDoc User comes.
          &nbsp;
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>Data derived from incident management</em>
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          : If the User goes to the DocuDoc Platform through the Contact Form or
          through the DocuDoc telephone number, DocuDoc will collect the
          messages received in the format used by the User and may use and store
          them to manage current or future incidents.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>-&nbsp; &nbsp; &nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>Data derived from “cookies”:</em>
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc uses its own and third-party cookies to facilitate navigation
          for its users and for statistical purposes.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          -&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <em>Data derived from external third parties:</em>
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc may collect personal information or data from external third
          parties only if the User authorizes these third parties to share the
          aforementioned information with DocuDoc. For example, if the User
          creates an account through their Facebook, Facebook could transfer us
          personal data of said User if they had them in their Facebook profile
          (name, gender, age...).
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }} />
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Likewise, if the User accesses DocuDoc through the products and
          services offered by Google, the latter may send the User's navigation
          data to DocuDoc, with access to the platform through the links created
          by Google.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          The information provided by the external third party may be controlled
          by the User according to the external third party's own privacy
          policy.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>3.2 Purpose</span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.1. Use the DocuDoc Platform
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc uses the data it collects from Users so that they can access
          and communicate with the DocuDoc platform, and to provide the services
          they request through their account on the DocuDoc Platform, according
          to the mechanism described in the “General Conditions of Use and
          Hiring".
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.2. Sending communications
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc uses User data to communicate via email and/or send SMS to the
          User about the operation of the service.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc may send messages to the User's mobile phone with information
          regarding the status of the requested order, and once it has finished,
          DocuDoc will send a summary/receipt of the order and its price to the
          User's email.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.3. Detect and investigate fraud and possible crimes
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc also uses the information to research and analyze how to
          improve the services it provides to Users, as well as to develop and
          improve the features of the service it offers. Internally, DocuDoc
          uses the information for statistical purposes in order to analyze the
          behavior and trends of Users, to understand how Users use the DocuDoc
          Platform, and to manage and improve the services offered, including
          the possibility of adding new badges to the platform.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc may control all actions that could lead to fraud or the
          commission of a crime related to the payment elements used by users.
          DocuDoc may request the user for a copy of their ID, as well as
          certain information about the bank card used to the order request. All
          data will, in any case, be processed by DocuDoc for the sole purpose
          of fulfilling the surveillance and fraud prevention functions and will
          be stored as long as the relationship with the user remains valid, as
          well as even in the subsequent period as long as does not prescribe
          the user's right to file claims or legal actions regarding payment for
          the products or services requested through DocuDoc. The data related
          to the credit card used will be kept for the duration of the
          resolution of the incident and for the next 120 days from its
          resolution date. In the event that irregularities are detected in its
          use that could be considered illegal activities, DocuDoc reserves the
          right to retain the data provided, as well as may share it with the
          competent authorities in order to investigate the case. DocuDoc may
          share the data with the authorities based on the legal obligation to
          prosecute conduct contrary to the applicable regulations.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.4. Ensure security and an adequate environment for the safe
          provision of services
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc may use the data in order to ensure the proper use of the
          products requested on its Platform.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          When DocuDoc mediates the provision of services, DocuDoc may provide
          the necessary personal data to the service provider so that the
          service provider can contact the buyer if it deems appropriate.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.5. Comply with regulations, defense and filing of actions
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc informs the user that the conversations carried out through
          the chat for communication with the Service Provider may be reviewed
          and used by DocuDoc for the filing and/or defense of claims and/or
          necessary legal actions, as well as for managing incidents in orders.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.6. Promotion and realization of commercial offers (online and
          offline)
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc uses third-party technology integrated into its Platform in
          order to collect your data and preferences and use them with CRM
          systems and advanced technology for the benefit of Users. Thus, using
          the information collected, the following processing of your data will
          be carried out:
        </span>
      </p>
      <ul>
        <li>
          DocuDoc may send by email promotional messages and/or offers related
          to the service it offers and that may be of interest to the User.
          DocuDoc may measure and personalize such advertising based on the
          preferences of DocuDoc Users. If the DocuDoc User wishes not to
          receive the aforementioned information and/or commercial
          communications, they may at any time choose the option to
          “Unsubscribe” in the email itself, and consequently, DocuDoc will
          immediately cease sending the aforementioned information. .
        </li>
        <li>
          DocuDoc may also send the User messages and/or offers related to said
          services through “push” notifications consisting of the sending of
          said promotional messages and/or offers to their mobile phone. If the
          DocuDoc User wishes not to receive the commercial communications of
          this point and the previous 3.1., the User may delete them together by
          deactivating their privacy preferences in their profile with a single
          click.
        </li>
        <li>
          DocuDoc and/or the third parties associated with DocuDoc, may use the
          order delivery address entered by the User for the purposes of
          carrying out promotional activities for the delivery of free samples
          or products of the service related to DocuDoc (i.e. delivery of free
          samples at home or advertising brochures) together with the delivery
          of the order that may be of interest to the User.
        </li>
        <li>
          By using the DocuDoc Platform, Users may also receive commercial
          communications from third parties associated with the Platform such as
          Facebook and Google, all depending on the privacy preferences that the
          User has on said Platforms.
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Users may use their privacy management center to unsubscribe from
          online marketing services or cancel their account if they do not agree
          to receive samples along with DocuDoc orders.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.7. Statistical purposes and service analysis
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc uses the information for statistical purposes in order to
          analyze the behavior and trends of Users, to understand how Users use
          the DocuDoc Platform, and to manage and improve the services offered,
          including the possibility of adding new badges to the Platform. .
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc also uses the information to research and analyze how to
          improve the services it provides to Users, as well as to develop and
          improve the features of the service it offers.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.8. Ensure security and an adequate environment for the safe
          provision of services
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc may use the data in order to ensure the proper use of the
          products requested on its Platform.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.2.9. Process accidents and claims with insurers
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          In the event that the User contacts DocuDoc to report any damage or
          unforeseen event that may be covered by the insurance contracted by
          DocDoc, it will process all the data related to the incident in order
          to manage and respond to the requests.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.3 Legal basis
          of processing
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          The processing of User data is carried out based on the following
          legal bases:
        </span>
      </p>
      <ul>
        <li>
          Execute the contractual relationship once registered on the Platform
          (for example, the processing of your data to deliver a requested
          order).
        </li>
        <li>
          Based on our legitimate interest (for example, to carry out controls
          to prevent fraud through the Platform).
        </li>
        <li>
          Compliance with legal obligations (for example, when competent
          authorities request data within the framework of judicial
          investigations and/or the filing of actions necessary to protect the
          interests of DocuDoc.
        </li>
        <li>
          Explicit consent for the communication of user data to third parties
          for the purpose of commercial communications.
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.4 Data recipients&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          DocuDoc guarantees that all business partners, technical partners,
          suppliers, or independent third parties are bound by contractually
          binding promises to process information that is shared with them
          following the instructions that DocuDoc gives you, this Privacy
          Policy, as well as the applicable legislation on the matter. of data
          protection. We will not transfer your personal data to any third party
          who is not subject to our instructions and no communication will
          include selling, renting, sharing or otherwise disclosing personal
          information of clients for commercial purposes contrary to the
          commitments made in this Privacy Policy.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.4.1. During the execution of an order, the data may be shared with:
        </span>
      </p>
      <ul>
        <li>The service provider, who executes the service.</li>
        <li>
          By establishments of public institutions, if necessary for the
          provision of the service, with the sole purpose of providing the
          contracted service.
        </li>
        <li>
          For the Customer Service services contracted by DocuDoc for the
          purposes of warning the User about possible incidents or requesting
          the reason for a negative evaluation of the service. DocuDoc may use
          the data provided for the purposes of managing incidents that may
          occur in the provision of services.
        </li>
        <li>
          The Platform and payment providers so that they can charge the amount
          to your account.
        </li>
        <li>
          By telecommunications service providers, when used to send
          communications about orders or incidents related to them
        </li>
        <li>
          Providers that offer satisfaction survey services carried out by
          DocuDoc
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.4.2. Share User data with third parties
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          In order to continue providing the services offered through the
          Platform, DocuDoc may share certain personal data of the Users with:
        </span>
      </p>
      <ul>
        <li>
          <span style={{ textDecoration: "underline" }}>Service providers</span>
          . DocuDoc's third-party service providers who execute orders and/or
          resolve shipping issues will have access to Users' personal
          information necessary to perform their functions, but will not be able
          to use it for other purposes. They must process personal information
          in accordance with this Privacy Policy and applicable data protection
          legislation.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Payment service providers
          </span>
          . When a User enters their card number on the DocuDoc Platform, it is
          stored directly by the Payment Platforms contracted by DocuDoc, who
          will allow the User's account to be charged. The payment service
          providers have been chosen based on their security measures and
          complying, in any case, with the security measures established by the
          payment services regulations, being PC1 Compliant according to the
          Data Security Standard for the Payment Industry. Payment Card or PCI
          DSS. DocuDoc does not store said data in any case.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Fraud control service providers
          </span>
          . DocuDoc will share User data with providers that offer fraud control
          services, to study the risk of the operations carried out.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Service providers for the anonymization of some data
          </span>
          . To prevent misuse of User data by third-party service providers,
          DocuDoc may transfer User data so that it can be anonymized and used
          only to provide the service to Users. For example, DocuDoc may
          transfer the Users' telephone number to third parties to anonymize
          them and provide them in this format to the providers used to fulfill
          the services contracted by the Users.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Security companies and Security Forces and Corps
          </span>
          . DocuDoc may disclose personal information and account data of our
          customers when it believes that disclosure is necessary to comply with
          law, to enforce or apply the “Terms of Use” or to protect the rights,
          property or safety of DocuDoc, its users or third parties. The above
          includes, therefore, the exchange of information with other companies
          and organizations, as well as with Security Forces and Corps for
          protection against fraud and reduction of credit risk. Upon legal
          request, DocuDoc may share information with executive authority bodies
          and/or third parties with respect to requests for information related
          to criminal investigations and alleged illegal activities.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>Services of</span>
          <span style={{ textDecoration: "underline" }}>
            <em>Call Center</em>
          </span>
          <span style={{ textDecoration: "underline" }}>
            and incident management
          </span>
          . To offer Customer Service and call centers, actions aimed at
          measuring the degree of satisfaction of Users and provision of
          administrative support services, DocuDoc may communicate User data to
          companies located in countries outside the EEA. , as long as it is
          authorized and the security requirements mentioned in the previous
          point are met.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Telecommunications services
          </span>
          . In order to offer Users telephone contact services, DocuDoc may
          contact telecommunications companies that provide secure lines and
          systems to contact Users.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Social networks connected by the User
          </span>
          . In the event that the User connects their DocuDoc account to another
          social network or a third-party platform, DocuDoc could use the
          information transferred to that social network or third party,
          provided that said information has been made available to DocuDoc in
          compliance with the policy. privacy of said social network or
          third-party platform.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Third parties associated with DocuDoc for commercial communications
          </span>
          . With your explicit consent, DocuDoc may transfer your personal data
          to third parties associated with DocuDoc as long as the User has
          expressly, informed and unequivocally accepted said transfer of data
          and is aware of the purpose and recipient thereof.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Ownership change assumptions
          </span>
          . If the ownership of DocuDoc changes or most of its assets are
          acquired by a third party, Users are informed that DocuDoc will
          transfer their data to the acquirer's entities in order to continue
          providing the services subject to data processing. The new person in
          charge will inform the Users of their identification data. DocuDoc
          states that it will comply with the duty of information to the
          pertinent Control Authority in the event that the aforementioned
          situations occur, and will inform Users of the change in
          responsibility at the time it occurs. This processing will be carried
          out under the contract signed with DocuDoc.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Insurance companies
          </span>
          . DocuDoc may communicate user data to insurance companies and
          insurance brokerages with which DocuDoc has an agreement for the
          management and processing of claims and losses arising from the
          activity of DocuDoc and its collaborators.
        </li>
        <li>
          <span style={{ textDecoration: "underline" }}>
            Management and Consulting.
          </span>
          DocuDoc may communicate user data to the Management and Advisory
          companies with which DocuDoc has an agreement for the tax, labor and
          accounting management and processing derived from the activity of
          DocuDoc and its collaborators.
        </li>
      </ul>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          The data of DocuDoc Users will not be transmitted to any third party
          except when (i) it is necessary for the requested services, in the
          event that DocuDoc collaborates with third parties (ii) when DocuDoc
          has express and unequivocal authorization from the User, (iii) when
          requested by a competent authority in the exercise of its functions
          (to investigate, prevent or take action regarding illegal actions) or
          (iv) finally, if required by law.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          3.5 Delete user data and account
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          The User may delete their account with all their data by accessing the
          profile and pressing “Delete my account and my data”. The User's
          account and data will be automatically deleted, unless the User has an
          order in progress. In the event that the user has an order in
          progress, they will have to wait for said order to finish, in order to
          delete their account and data.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>How are we going to protect your data?</strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          We will protect your data with effective security measures based on
          the risks involved in the use of your information.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          To this end, our entity has approved a Data Protection Policy and
          annual controls and audits are carried out to verify that your
          personal data is safe at all times.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>Will we send your data to other countries?</strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          In the world there are countries that are safe for their data and
          others that are not so safe. For example, the European Union is a safe
          environment for your data. Our policy is not to send your personal
          information to any country that is not secure from a data protection
          point of view.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          In the event that, in order to provide you with the service, it is
          essential to send your data to a country that is not as safe as Spain,
          we will always request your permission in advance and apply effective
          security measures that reduce the risks of sending your information.
          personnel to another country.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>How long will we keep your data?</strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          We will retain your data for the duration of our relationship and for
          as long as we are required to do so by law. Once the applicable legal
          deadlines have expired, we will proceed to dispose of them in a safe
          and environmentally friendly manner.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>What are your data protection rights?</strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          You can contact us at any time to find out what information we have
          about you, rectify it if it is incorrect and delete it once our
          relationship ends, if this is legally possible.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          You also have the right to request the transfer of your information to
          another entity. This right is called “portability” and can be useful
          in certain situations.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          To request any of these rights, you must make a written request to our
          address, along with a photocopy of your ID, to be able to identify
          you.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          In the offices of our entity we have specific forms to request these
          rights and we offer our help in completing them.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          To learn more about your data protection rights, you can consult the
          website of the Spanish Data Protection Agency (
        </span>
        <a target="_blank" href="http://www.agpd.es/">
          <span style={{ color: "rgb(74, 74, 74)" }}>www.agpd.es</span>
        </a>
        <span style={{ color: "rgb(74, 74, 74)" }}>).</span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>
            Can you withdraw your consent if you change your mind at a later
            time?
          </strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          You can withdraw your consent if you change your mind about the use of
          your data at any time.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          For example, if you were once interested in receiving advertising for
          our products or services, but you no longer wish to receive further
          advertising, you can let us know using the opposition to processing
          form available at the offices of our entity.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>
            If you believe that your rights have been disregarded, where can you
            file a complaint?
          </strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          If you understand that your rights have been neglected by our entity,
          you can file a claim with the Spanish Data Protection Agency, through
          any of the following means:
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>O</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Electronic office: www.agpd.es
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>O</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>Postal address:</span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Spanish Data Protection Agency
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>C/ Jorge Juan, 6</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>28001-Madrid</span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>O</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
        <span style={{ color: "rgb(74, 74, 74)" }}>Via telephone:</span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>A loss. 901 100 099</span>
      </p>
      <p>
        <span style={{ color: "rgb(74, 74, 74)" }}>A loss. 91 266 35 17</span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Filing a claim with the Spanish Data Protection Agency does not entail
          any cost and the assistance of a lawyer or attorney is not necessary.
        </span>
      </p>
      <p>
        <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
        <span style={{ color: "rgb(0, 32, 96)" }}>
          &nbsp; &nbsp; &nbsp; &nbsp;
        </span>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          <strong>Will we profile you?</strong>
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Our policy is not to create profiles about the users of our services.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          However, there may be situations where, for service delivery,
          commercial or other purposes, we need to profile information about
          you. An example could be the use of your purchase or service history
          to be able to offer you products or services adapted to your tastes or
          needs.
        </span>
      </p>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          In this case, we will apply effective security measures that protect
          your information at all times from unauthorized persons who seek to
          use it for their own benefit.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>Will we use your data for other purposes?</strong>
        </li>
      </ol>
      <p style={{ textAlign: "justify" }}>
        <span style={{ color: "rgb(74, 74, 74)" }}>
          Our policy is not to use your data for purposes other than those we
          have explained to you. If, however, we will need to use your data for
          different activities, we will always request your permission in
          advance through clear options that will allow you to decide in this
          regard.
        </span>
      </p>
      <ol>
        <li>
          <span style={{ color: "rgb(0, 32, 96)" }}>·</span>
          <span style={{ color: "rgb(0, 32, 96)" }}>
            &nbsp; &nbsp; &nbsp; &nbsp;
          </span>
          <strong>Activity Register</strong>
        </li>
      </ol>
      <span style={{ color: "rgb(74, 74, 74)" }}>
        If you want to consult the activity log you can consult it at this link:
      </span>
      <a
        target="_blank"
        href="https://drive.google.com/file/d/19NDHHQJjsWWaR1qw78NL5hqK4Q8vl9yc/view?usp=sharing"
      >
        <span style={{ color: "rgb(17, 85, 204)" }}>
          https://drive.google.com/file/d/19NDHHQJjsWWaR1qw78NL5hqK4Q8vl9yc/view?usp=sharing
        </span>
      </a>
    </div>
  );
}
