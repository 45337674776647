import { onValue, ref } from "firebase/database";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useHistory } from "react-router-dom";
import { database } from "../config/Firebase";
import labels from "../config/Labels";
import {
  functionToConvertStringToCamelCase,
  replaceDecimalWithComma,
} from "../config/Utility";
import { Images } from "../constant/images";
import OrderServiceModal from "./orderServiceModal";
import ProviderInfoModal from "./providerInfoModal";

export default function OrderItem({
  id,
  name,
  des,
  price,
  discount,
  path,
  image,
  promo,
  servicePak,
  info,
  help,
  status,
  data,
  serviceId,
  service,
  serviceProvider,
  supplementory,
  dispute = false,
  item,
  providerName,
  serviceName,
  orderCard = false,
  notificationReadCount = null,
  date,
}) {
  const { push, location } = useHistory();
  const [orderUnreadCount, setOrderUnreadCount] = useState(null);
  const [show, setShow] = useState(false);
  const [clickAble, setClickAble] = useState(true);
  const orderDetail = {
    id,
    name,
    des,
    price,
    discount,
    image,
    promo,
    servicePak,
    info,
    help,
    status,
    data,
    serviceId,
    service,
    serviceProvider,
    supplementory,
  };
  useEffect(() => {
    const getData = ref(database, "orders/" + id + "/userUnread");
    onValue(getData, (snapshot) => {
      setOrderUnreadCount(snapshot?.val());
    });
  }, []);

  let convertedServicePak =
    servicePak !== "fixed price"
      ? labels?.[functionToConvertStringToCamelCase(servicePak)]?.toUpperCase()
      : null;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Row>
      <Col>
        <div className="pdpt-bg w-100 mb-2">
          {orderCard ? (
            <OrderServiceModal
              show={show}
              info={info}
              onHide={() => setShow(false)}
            />
          ) : (
            <ProviderInfoModal
              show={show}
              info={item?.service_provider}
              onHide={() => setShow(false)}
            />
          )}

          <div>
            <div className="order-body10 mb-2">
              <article
                className="shop-summary-item datalist element-emphasis-weak border-radius-10 position-relative mt-10 "
                style={{ cursor: "pointer" }}
              >
                <Card className="shadow-none border-0">
                  <Card.Body className="p-1">
                    <img
                      className="cursor-pointer m-1"
                      onClick={() =>
                        push({
                          pathname: `/${path}/${id}`,
                          state: {
                            dispute: dispute,
                            providerName: providerName,
                            serviceName: serviceName,
                            allInfo: item,
                          },
                        })
                      }
                      src={image}
                      alt="Shop item in cart"
                      style={{
                        // borderRadius: 10,
                        objectFit: "contain",
                        // background: "rgba(229, 229, 229, 0.29)",
                        // border: "1px solid rgb(229, 229, 229)",
                        width:
                          ![
                            "pending",
                            "completed",
                            // "cancelled",
                            // "dispute",
                            // "disputed",
                          ]?.includes(status) &&
                          path !== "specficordermessage" &&
                          path !== "order-notification"
                            ? "130px"
                            : "100px",
                        height:
                          ![
                            "pending",
                            "completed",
                            // "cancelled",
                            // "dispute",
                            // "disputed",
                          ]?.includes(status) &&
                          path !== "specficordermessage" &&
                          path !== "order-notification"
                            ? "130px"
                            : "100px",
                      }}
                    />
                    <header
                      className="item-info-name-features-price mb-10"
                      onClick={() =>
                        push({
                          pathname: `/${path}/${id}`,
                          state: { dispute: dispute, allInfo: item },
                        })
                      }
                    >
                      <h3
                        className="fontSize16px"
                        style={{ textAlign: "left" }}
                      >
                        <strong>
                          {/* <a href="04-shop-product-single.html"> */}
                          <span className="mx-3" style={{ fontSize: 20 }}>
                            {name}
                          </span>

                          {/* </a> */}

                          <p className="col-sm-8 d-flex py-0 m-0 ">
                            <span
                              className="col-sm-4 px-1 "
                              style={{ fontSize: 18 }}
                            >
                              {labels.orderId}:
                            </span>
                            <strong>
                              <span
                                className="col-sm-4"
                                style={{ fontSize: 15 }}
                              >
                                {id}
                              </span>
                            </strong>
                          </p>
                          <p className="col-sm-8 d-flex py-0 m-0">
                            <span
                              className="features col-sm-4 px-1"
                              style={{ color: "green", fontSize: 18 }}
                            >
                              {Number(price) + Number(supplementory) !== 0
                                ? "€" +
                                  replaceDecimalWithComma(
                                    Number(price) + Number(supplementory)
                                  )
                                : `€${replaceDecimalWithComma(price) === "0"}`
                                ? labels.free
                                : replaceDecimalWithComma(price)}{" "}
                            </span>
                            <strong>
                              <span
                                className="col-sm-4"
                                style={{ fontSize: 15, color: "green" }}
                              >
                                {convertedServicePak}
                              </span>
                            </strong>
                          </p>
                          {path == "orderdetail" || help == "123" ? (
                            ((Number(price) - Number(discount)) /
                              Number(price)) *
                              100 !==
                            0 ? (
                              <>
                                {Number(discount) + Number(supplementory) !==
                                0 ? (
                                  <p className="col-sm-8 d-flex py-0 m-0 ">
                                    <span
                                      className="text-danger col-sm-4 px-1"
                                      style={{ fontSize: 18 }}
                                    >
                                      {"€"}
                                      {replaceDecimalWithComma(
                                        Number(discount) + Number(supplementory)
                                      )}
                                    </span>

                                    <strong>
                                      <span
                                        className="text-danger col-sm-4"
                                        style={{ fontSize: 15 }}
                                      >
                                        {(
                                          ((Number(price) - Number(discount)) /
                                            Number(price)) *
                                          100
                                        ).toFixed(0) === "NaN"
                                          ? "0"
                                          : (
                                              ((Number(price) -
                                                Number(discount)) /
                                                Number(price)) *
                                              100
                                            ).toFixed(0)}
                                        % {labels.discount}
                                      </span>
                                    </strong>
                                  </p>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : null
                          ) : (
                            <>
                              <div className="row">
                                <p className="col-sm-8 d-flex py-0 m-0">
                                  <span
                                    className=" col-sm-4 px-1"
                                    style={{ fontSize: 18 }}
                                  >
                                    {labels.rating}
                                  </span>
                                  <span
                                    className=" col-sm-4 d-flex flex-wrap-reverse align-items-center  "
                                    style={{ fontSize: 18 }}
                                  >
                                    <span>{discount}</span>
                                    <span>
                                      <img
                                        className="partnerStarIconInfo ml-2"
                                        style={{ width: 20, height: 20 }}
                                        src={Images.shareWin.default}
                                        alt="info"
                                      />
                                    </span>
                                  </span>
                                </p>
                                {status !== "pending" &&
                                  path !== "specficordermessage" &&
                                  path !== "order-notification" && (
                                    <>
                                      <p className="d-flex justify-centent-between mb-0">
                                        <span className="pl-1 ml-3 w-25 mb-0">
                                          {labels.amount}
                                        </span>
                                        <span
                                          style={{ marginLeft: -4 }}
                                          className="w-25"
                                        >
                                          N/A
                                        </span>
                                      </p>
                                      <p className="d-flex justify-centent-between">
                                        <span className="pl-1 ml-3 w-25">
                                          {labels?.reason}
                                        </span>
                                        <span
                                          style={{ marginLeft: -4 }}
                                          className=""
                                        >
                                          N/A
                                        </span>
                                      </p>
                                    </>
                                  )}
                                <p className="col-sm-4 d-flex justify-content-end py-0 m-0 px-0 ">
                                  {notificationReadCount === null &&
                                  orderUnreadCount ? (
                                    <>
                                      {/* <div className="d-flex justify-content-center ">
                          <div>
                            <span
                              className="text-uppercase"
                              style={{ fontSize: 15 }}
                            >
                              unread Messages:
                            </span>
                          </div>
                          <div className="">
                            <strong>
                              <div
                                className="d-flex justify-content-center align-items-center  rounded-circle ml-2 text-white "
                                style={{
                                  fontSize: 12,
                                  background: "#46BCFF",
                                  width: "32px",
                                  height: "25px",
                                }}
                              >
                                {orderUnreadCount > 99
                                  ? `+${99}`
                                  : orderUnreadCount}
                              </div>
                            </strong>
                          </div>
                        </div> */}
                                      <div className="">
                                        <div className="">
                                          <strong>
                                            <div
                                              className="d-flex justify-content-center align-items-center rounded-circle ml-2 text-white"
                                              style={{
                                                fontSize: 12,
                                                background: "#ff6464ff",
                                                width: "30px",
                                                height: "30px",
                                              }}
                                            >
                                              {orderUnreadCount > 99
                                                ? `+${99}`
                                                : orderUnreadCount}
                                            </div>
                                          </strong>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}
                                  {notificationReadCount !== null &&
                                  notificationReadCount > 0 ? (
                                    <div className="">
                                      <div className="">
                                        <strong>
                                          <div
                                            className="d-flex justify-content-center align-items-center rounded-circle ml-2 text-white bg-danger"
                                            style={{
                                              fontSize: 12,

                                              width: "30px",
                                              height: "30px",
                                            }}
                                          >
                                            {notificationReadCount > 99
                                              ? `+${99}`
                                              : notificationReadCount}
                                          </div>
                                        </strong>
                                      </div>
                                    </div>
                                  ) : null}
                                  {/* <span
                          className=" col-sm-4 px-1"
                          style={{ fontSize: 18 }}
                        >
                         
                        </span>
                        <span
                          className=" col-sm-4 d-flex flex-wrap-reverse align-items-center  "
                          style={{ fontSize: 18 }}
                        >
                          <span>{discount}</span>
                          <span>
                            <img
                              className="partnerStarIconInfo ml-2"
                              style={{ width: 20, height: 20 }}
                              src={Images.shareWin.default}
                              alt="info"
                            />
                          </span>
                        </span> */}
                                </p>
                              </div>
                            </>
                          )}
                        </strong>
                        <p className="col-sm-8 d-flex py-0 ">
                          <span className="text-secondary ml-1">
                            {moment(date)?.format("DD-MMM-YYYY HH:mm")}
                          </span>
                        </p>
                      </h3>
                    </header>
                    <img
                      onClick={() => setShow(true)}
                      src={Images.infoo.default}
                      className="position-absolute cursor-pointer"
                      style={{
                        width: 20,
                        height: 20,
                        cursor: "pointer",
                        top:
                          status !== "pending" &&
                          path !== "specficordermessage" &&
                          path !== "order-notification"
                            ? 55
                            : 55,
                        right: 0,
                      }}
                    />
                    {status !== "pending" &&
                      path !== "specficordermessage" &&
                      path !== "order-notification" &&
                      path !== "order-notification" && (
                        <div
                          className="position-absolute"
                          style={{
                            bottom: 0,
                            right: 0,
                          }}
                        >
                          <span
                            onMouseOver={() => setClickAble(false)}
                            onClick={() => {
                              push({
                                pathname: "/documentaion",
                                state: {
                                  reOrder: "reOrder",
                                  data: item,
                                  serviceId: serviceId,
                                  service: service,
                                  serviceProvider: serviceProvider,
                                  price: price,
                                },
                              });
                            }}
                            style={{ color: "blue", cursor: "pointer" }}
                          >
                            <strong>{labels.reOrder}</strong>
                          </span>
                        </div>
                      )}
                  </Card.Body>
                </Card>
              </article>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
