import axios from "axios";
import labels from "./Labels";
import moment from "moment";

export const Utility = {
  validateEmail: function (email) {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },
  // validate password regex
  validatePassword: function (password) {
    var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return re.test(password);
  },

  validatePhone: function (phone) {
    var re = /^([+]\d{0})?\d{7,15}$/;
    return re.test(phone);
  },
  // validateText: function (text) {
  //   var re = /^[a-zA-Z\s\.]*$/;
  //   return re.test(text);
  // },
  validateNo: function (no) {
    var re = /^[0-9\.]+$/;
    return re.test(no);
  },
  validateName: function (name) {
    var re = /^[A-Za-zñáéíóúüç'.,?\/()\[\]!&\s@$*^#_]+$/i;
    return re.test(name);
  },
};
export const validateText = (string) => {
  return string.replace(/[0-9]/g, "");
};
export const functionToConvertStringToCamelCase = (str = "") => {
  return str
    ?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word?.toLowerCase() : word?.toUpperCase();
    })
    ?.replace(/\s+/g, "");
};

export const languageTranslationFunctionForTheTime = (time, time_type) => {
  return time === null && time_type === null
    ? "0"
    : `${
        Number(time) > 1
          ? labels[
              time_type === "Hours"
                ? time_type.toLowerCase()
                : time_type?.toLowerCase() + "s"
            ]
          : labels[time_type === "Hours" ? "hour" : time_type?.toLowerCase()]
      }`;
};
export const replaceDecimalWithComma = (amount = 0) => {
  let currencyFormat = Intl.NumberFormat("de-DE", {
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return `${currencyFormat.format(amount)}`;
};

export const functionToDownloadFile = (url, name) => {
  axios({
    url: url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link?.setAttribute("download", name);
      document.body.appendChild(link);
      link?.click();
      document.body.removeChild(link);
    })
    .catch((err) => {
      console.log("err", err);
    });
};
export const rating = (stars) => `★★★★★☆☆☆☆☆`?.slice(5 - stars, 10 - stars);
export const ratingStars = (stars) =>
  `★★★★★☆☆☆☆☆`?.slice(5 - stars, 10 - stars);

export const regexForFormatRating = (ratings = "") => {
  var regex = /(\d+\.\d)(\d+)/;
  return Number(String(ratings)?.replace(regex, "$1")).toFixed(1);
};
export const get_url_extension = (url) => {
  return url?.split(/[#?]/)?.[0]?.split(".")?.pop()?.trim();
};

export const date_difference_checker = (a, b, timeUnit = "days") => {
  return b.diff(a, timeUnit);
};

export const regexToCheckIfTheStringContainsEmail = (email) => {
  var re =
    /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  return re.test(email);
};

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const regexToCheckIfTheStringContainsPhoneNumber = (input) => {
  let matched = input.split(" ");
  let maxIndex = matched.length - 1;
  let filtered = matched.filter((s, i) => {
    if (i !== maxIndex && isNumeric(s) && isNumeric(matched[i + 1]))
      return true;
    if (isNumeric(s) && s.length >= 7 && s.length <= 15) return true; // Adjust the length range according to your needs
    return false;
  });
  return filtered.length > 0;
};

export const extractContent = (s) => {
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

export const checkDevice = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
