import { onValue, ref } from "firebase/database";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Col,
  Dropdown,
  ListGroup,
  Modal,
  ModalBody,
  Row,
} from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";
import { BiSearch } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Get_Orders_Method,
  Search_Services_and_branches,
} from "../config/api/index";
import { database } from "../config/Firebase";
import labels from "../config/Labels";
import { Context, useStoreItem } from "../config/Store";
import { Images } from "../constant/images";
import User_Login from "../screen/session/login";
import User_Registration from "../screen/session/signup";
import { GetAllOrders } from "../store/actions/orders";
import InformationContainer from "./informationContainer";
import {
  languageTranslationFunctionForTheTime,
  replaceDecimalWithComma,
} from "../config/Utility";

const { logo } = Images;

export default function NavBar(props) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const ordersListing = useSelector((state) => state?.orders?.orders);
  const location = useLocation();
  const { user } = useStoreItem();
  const inputElement = useRef();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [signIn, setSignIn] = useState(false);
  const [active, setActive] = useState({
    about: false,
    allservice: false,
    mobileApp: false,
  });
  const [expand, setExpand] = useState(true);
  const [search, setSearch] = useState([]);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [closeSearchList, setCloseSearchList] = useState(false);
  const [tab, setTab] = useState("home");
  const [info, setInfo] = useState("");
  const [infoModal, setInfoModal] = useState(false);
  const [orderMessages, setOrderMessages] = useState([]);
  const [alertCount, setAlertCount] = useState(0);
  const [additionalBudgetCount, setAdditionalBudgetCount] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showToast, setShowToast] = useState("");

  const currentLanguage = localStorage.getItem("primaryLanguage");
  const storeValue = useContext(Context);
  const handleClose = () => {
    setShow(false);
  };
  const changeForm = () => {
    setIsLoggedIn(!isLoggedIn);
  };

  const opneModal = () => {
    setShow(true);
    setIsLoggedIn(false);
  };

  const openLoginModal = () => {
    setShow(true);
    setIsLoggedIn(true);
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [show]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [infoModal]);

  useEffect(() => {
    if (searchValue.length >= 1) {
      searchBranchesAndService();
      setCloseSearchList(false);
    }
    if (searchValue.length <= 1) {
      setCloseSearchList(true);
    }
  }, [searchValue]);

  const searchBranchesAndService = () => {
    Search_Services_and_branches(
      { search: searchValue },
      (value) => {
        setSearch(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
      }
    );
  };
  const history = useHistory();
  const changeLanguage1 = () => {
    const lang = localStorage.getItem("primaryLanguage");
    if (lang == "spanish") {
      localStorage.setItem("primaryLanguage", "english");
      history.go(0);
    }

    // window.reload();
    history.go(0);
  };
  const changeLanguage2 = () => {
    const lang = localStorage.getItem("primaryLanguage");
    if (lang == "english") {
      localStorage.setItem("primaryLanguage", "spanish");
      history.go(0);
    }

    // window.reload();
    history.go(0);
  };

  // const filteredBranchByName = name => {
  //   return records => {
  //     return records?.name.toLowerCase().includes(name.toLowerCase()) || !name;
  //   };
  // };

  const mappedSearch = Object.values(search);

  const handleCloseInfoModal = () => {
    setInfoModal(false);
  };
  const getOrderMessages = () => {
    Get_Orders_Method(
      (value) => {
        setShowSpinner(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
        let orderUnreadCount = 0;
        setOrderMessages([]);
        let allOrdersArray = [
          ...(ordersListing?.pending_orders || []),
          ...(ordersListing?.disputed_orders || []),
          ...(ordersListing?.dispute_orders || []),
          ...(ordersListing?.completed_orders || []),
          ...(ordersListing?.cancelled_orders || []),
        ];

        let ordersArray = [
          [
            ...(value?.pending_orders || []),
            ...(value?.disputed_orders || []),
            ...(value?.dispute_orders || []),
            ...(value?.completed_orders || []),
            ...(value?.cancelled_orders || []),
          ]
            .filter(
              (opt) =>
                opt.order_chat !== null && opt.order_chat.messages.length !== 0
            )
            ?.map((item, index) => {
              const getData = ref(
                database,
                "orders/" + item?.order_id + "/userUnread"
              );
              const unReadCount = onValue(getData, (snapshot) => {
                if (snapshot.val() > 0) {
                  setOrderMessages((prev) => [...prev, item]);
                }
              });
              return {
                // ...item,
                userUnreadCount: orderUnreadCount,
              };
            }),
        ];

        setAlertCount(
          allOrdersArray.reduce(
            (accumulator, currentValue) =>
              accumulator + Number(currentValue?.notifications_count),
            0
          )
        );
        setAdditionalBudgetCount(
          allOrdersArray.reduce(
            (accumulator, currentValue) =>
              accumulator + Number(currentValue?.additional_budgets_count),
            0
          )
        );
      }
    );
  };
  useEffect(() => {
    if (localStorage.getItem("usertoken") !== null) {
      dispatch(
        GetAllOrders((failure) => {
          setMessage(failure);
          setSuccess(false);
          setShowToast(true);
        })
      );
    }
  }, [storeValue]);
  useEffect(() => {
    let orderUnreadCount = 0;
    setOrderMessages([]);
    let ordersArray = [
      [
        ...(ordersListing?.pending_orders || []),
        ...(ordersListing?.disputed_orders || []),
        ...(ordersListing?.dispute_orders || []),
        ...(ordersListing?.completed_orders || []),
        ...(ordersListing?.cancelled_orders || []),
      ]
        .filter(
          (opt) =>
            opt.order_chat !== null && opt.order_chat.messages.length !== 0
        )
        ?.map((item, index) => {
          const getData = ref(
            database,
            "orders/" + item?.order_id + "/userUnread"
          );
          const unReadCount = onValue(getData, (snapshot) => {
            if (snapshot.val() > 0) {
              setOrderMessages((prev) => [...prev, item]);
            }
          });
          return {
            // ...item,
            userUnreadCount: orderUnreadCount,
          };
        }),
    ];
    let allOrdersArray = [
      ...(ordersListing?.pending_orders || []),
      ...(ordersListing?.disputed_orders || []),
      ...(ordersListing?.dispute_orders || []),
      ...(ordersListing?.completed_orders || []),
      ...(ordersListing?.cancelled_orders || []),
    ];

    setAlertCount(
      allOrdersArray.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue?.notifications_count),
        0
      )
    );
  }, [ordersListing]);

  const navigateToMobileApp = () => {
    window.scrollTo({
      top:
        document
          .getElementById("our_docudoc_mobile_app")
          .getBoundingClientRect().top || 580,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="header_absolute s-pb-30 ">
      <>
        <Modal
          show={infoModal}
          onHide={handleCloseInfoModal}
          animation={true}
          size="md"
          aria-labelledby="contained-modal-title-vcenter "
        >
          <Modal.Header closeButton className="d-flex justify-content-center">
            <Modal.Title id="contained-modal-title-vcenter ">
              {labels.information}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            scrollable={true}
            style={{ width: "500px", height: "200px", overflowY: "scroll" }}
          >
            <InformationContainer info={info == null ? "" : info} />
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
      <Menu noOverlay fallDown className="d-lg-none d-xl-none">
        <div className="col-xl-2 col-lg-3 col-11 ">
          <Link to="/" className=" logo text-center ml-auto">
            <img src={logo.default} alt="logo" className="logo mb-20" />
          </Link>
        </div>
        <ul className="ml-3">
          <li>
            <Link
              style={{ color: "#46bcff", border: "1px" }}
              to={{ pathname: "/about", state: "about" }}
            >
              {labels.aboutUs}
            </Link>
          </li>
          <li>
            {" "}
            <Link
              style={{ color: "#46bcff" }}
              to={{ pathname: "/", state: "serviceArea" }}
            >
              {labels?.allServices}
            </Link>
          </li>
          <li>
            {location.pathname !== "/" ? (
              <Link
                style={{ color: "#46bcff" }}
                to={{ pathname: "/", state: "mobileApp" }}
              >
                {labels.mobileApp}
              </Link>
            ) : (
              <a
                style={{ color: "#46bcff" }}
                className="cursorPointer"
                onClick={navigateToMobileApp}
              >
                {labels.mobileApp}
              </a>
            )}
          </li>
          <li>
            <div className="">
              <Dropdown className="">
                <Dropdown.Toggle
                  as="h6"
                  className="   rounded"
                  id="dropdown-basic "
                  variant="transparent"
                >
                  <span className="" style={{ color: "#46bcff" }}>
                    {localStorage.getItem("primaryLanguage") == "english"
                      ? "English "
                      : "Spanish"}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      changeLanguage1();
                    }}
                  >
                    English
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={() => {
                      changeLanguage2();
                    }}
                  >
                    Spanish
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </li>
        </ul>

        <div className="align-items-baseline d-flex ">
          {" "}
          {localStorage.getItem("usertoken") === null ? (
            <>
              <ul className="" style={{ listStyle: "none" }}>
                <li>
                  <button
                    className="btn btn-primary btn-block  d-block"
                    onClick={opneModal}
                  >
                    {labels.SignUp}
                  </button>
                </li>
                <li>
                  <button
                    className="btn signInHome btn-block d-block mt-2"
                    onClick={openLoginModal}
                  >
                    {labels.Login}
                  </button>
                </li>
              </ul>
            </>
          ) : (
            <div
              onClick={() => push("/1/myaccount")}
              className="cursorPointer ml-3"
            >
              <img
                src={Images.u1.default}
                className={"userNavProfileImage onCardHover"}
              />
            </div>
          )}
        </div>

        {/* <a onClick={ this.showSettings } className="menu-item--small" href="">Settings</a> */}
      </Menu>
      <header className="page_header ds" fixed="top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3  col-11">
              <Link to="/" className="ml-auto  logo text-center">
                <img
                  src={logo.default}
                  alt="logo"
                  className="logo cursorPointer"
                />
              </Link>
            </div>
            <div className=" col-lg-6 text-sm-center">
              <nav className="top-nav">
                <ul className="nav sf-menu">
                  <li
                    className={location.pathname === "/about" ? "" : "active"}
                  >
                    <Link to={{ pathname: "/about", state: "about" }}>
                      {labels.aboutUs}
                    </Link>
                  </li>
                  <li className={location.pathname == "/" ? "" : "active"}>
                    <Link to={{ pathname: "/", state: "serviceArea" }}>
                      {labels?.allServices}
                    </Link>
                  </li>
                  <li className={location.pathname == "/" ? "" : "active"}>
                    {location.pathname !== "/" ? (
                      <Link to={{ pathname: "/", state: "mobileApp" }}>
                        {labels.mobileApp}
                      </Link>
                    ) : (
                      <a
                        className="cursorPointer"
                        onClick={navigateToMobileApp}
                      >
                        {labels.mobileApp}
                      </a>
                    )}
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-lg-3 text-lg-left text-xl-right d-none d-lg-block p-0 ">
              <div className="align-items-baseline justify-content-end d-flex ">
                {localStorage.getItem("usertoken") === null ? (
                  <>
                    <button
                      className="btn btn-primary btn-block"
                      onClick={opneModal}
                    >
                      {labels.SignUp}
                    </button>
                    <button
                      className="btn signInHome btn-block"
                      onClick={openLoginModal}
                    >
                      {labels.Login}
                    </button>
                  </>
                ) : (
                  <div
                    onClick={() => push("/1/myaccount")}
                    className="cursorPointer position-relative"
                  >
                    {orderMessages?.length ||
                    alertCount > 0 ||
                    additionalBudgetCount > 0 ? (
                      <div
                        className="position-absolute d-flex justify-content-center align-items-center rounded-circle  text-white "
                        style={{
                          background: "#ff6464ff",
                          fontSize: "10px",
                          width: "15px",
                          height: "15px",
                          transform: "translate(-90%, -10%)",
                          left: "100%",
                          zIndex: 1,
                          // left: "100%!important",
                        }}
                      ></div>
                    ) : null}

                    <img
                      src={Images.u1.default}
                      className={"userNavProfileImage onCardHover"}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className={expand ? "search-box-expand shadow-lg" : "search-box"}
              onClick={() => {
                setExpand(true);
                inputElement.current.disabled = false;
                inputElement.current.focus();
              }}
              onBlur={() => {
                setExpand(true);
                setSearchValue(
                  mappedSearch[0]?.length != 0 || mappedSearch[1]?.length != 0
                    ? searchValue
                    : ""
                );
                inputElement.current.blur();
              }}
            >
              <input
                placeholder={labels.whatManagementDoYouNeed}
                type="text"
                value={searchValue}
                name="search"
                className={`${
                  expand ? "search-text" : "search-text text-white"
                } ${!searchValue.length ? "text-center" : ""}`}
                autoComplete="off"
                onChange={(e) => {
                  setSearchValue(e.target.value.trimStart());
                  setExpand(true);
                }}
                onFocus={() => {
                  setExpand(true);
                }}
                ref={inputElement}
              />
              {!expand && (
                <span className="search-btn cursorPointer">
                  <BiSearch />
                </span>
              )}
            </div>

            {!closeSearchList && (
              <div
                className=" bg-white addclasshere border-Radius border"
                style={{
                  position: "absolute",
                  top: 115,
                  left: "25%",
                  right: "25%",
                  minWidth: "450px",
                }}
                onBlur={() => {
                  // setCloseSearchList(true);
                }}
              >
                <span className="d-flex justify-content-end  mt-1 mr-1 cursorPointer">
                  <img
                    style={{ width: "20px", objectFit: "cover" }}
                    src={Images.redCross.default}
                    onClick={() => {
                      setCloseSearchList(true);
                      setExpand(true);
                      setSearchValue("");
                    }}
                  />
                </span>

                {mappedSearch[0]?.length != 0 ||
                mappedSearch[1]?.length != 0 ? (
                  <Fragment>
                    <div className="search-scroll mx-2 px-1 py-3">
                      {mappedSearch[1]?.length ? (
                        <Row className=" mb-3">
                          <Col>
                            <Row className="">
                              <Col sm={12}>
                                <h5 className="theme-text-color">
                                  {labels.branches}
                                </h5>
                              </Col>
                            </Row>
                            <Row className="">
                              <Col sm={12}>
                                <ListGroup
                                  className="bg-white "
                                  variant="flush"
                                >
                                  {mappedSearch[1]?.map((item, index) => {
                                    return (
                                      <>
                                        <ListGroup.Item className="py-1">
                                          <Row>
                                            <Col
                                              sm={1}
                                              className="px-0 cursor-pointer-class"
                                              onClick={() => {
                                                localStorage.setItem(
                                                  "serviceAreaId",
                                                  item?.service_area_id
                                                );

                                                push({
                                                  pathname: `/service/${item?.branch_id}`,
                                                  state: {
                                                    acticeFilter: "relevance",
                                                    branch_id: item?.branch_id,
                                                    serviceAreaId:
                                                      item?.service_area
                                                        ?.service_area_id,
                                                    defaultselection: true,
                                                  },
                                                });
                                                setSearchValue("");
                                                setCloseSearchList(true);
                                              }}
                                            >
                                              <img
                                                style={{
                                                  width: "45px",
                                                  height: "45px",
                                                  objectFit: "cover",
                                                }}
                                                src={`${item?.avatar}`}
                                                loading="lazy"
                                              />
                                            </Col>
                                            <Col
                                              className="cursor-pointer-class d-flex align-items-center"
                                              onClick={() => {
                                                localStorage.setItem(
                                                  "serviceAreaId",
                                                  item?.service_area_id
                                                );
                                                push({
                                                  pathname: `/service/${item?.branch_id}`,
                                                  state: {
                                                    acticeFilter: "relevance",
                                                    branch_id: item?.branch_id,
                                                    serviceAreaId:
                                                      item?.service_area_id,
                                                    defaultselection: true,
                                                  },
                                                });
                                                setSearchValue("");
                                                setCloseSearchList(true);
                                              }}
                                            >
                                              <div className="d-flex flex-column">
                                                <div className="fw-bolder dark-text-secondary-color font-weight-bold">
                                                  {currentLanguage === "english"
                                                    ? item?.name
                                                    : item?.name_sp}
                                                </div>
                                              </div>
                                            </Col>
                                            <Col sm={1}>
                                              <img
                                                onClick={() => {
                                                  setInfoModal(true);
                                                  setInfo(
                                                    currentLanguage ===
                                                      "english"
                                                      ? item?.info
                                                      : item?.info_sp
                                                  );
                                                }}
                                                src={Images.infoo.default}
                                                className="position-absolute"
                                                style={{
                                                  width: 20,
                                                  height: 20,
                                                  top: 15,
                                                  right: 0,
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </Col>
                                          </Row>
                                        </ListGroup.Item>
                                      </>
                                    );
                                  })}
                                </ListGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ) : null}

                      {mappedSearch[0]?.length ? (
                        <>
                          <Row className=" ">
                            <Col>
                              <Row className="">
                                <Col sm={12}>
                                  <h5 className="theme-text-color">
                                    {labels.services}
                                  </h5>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <ListGroup
                                    className="bg-white  "
                                    variant="flush"
                                  >
                                    {mappedSearch[0]?.map((item, index) => {
                                      return (
                                        <>
                                          <ListGroup.Item className="py-2">
                                            <Row>
                                              <Col
                                                sm={1}
                                                className="cursor-pointer-class px-0"
                                                onClick={() => {
                                                  push({
                                                    pathname: `/documentaion/`,
                                                    state: {
                                                      acticeFilter:
                                                        item?.service_id,
                                                      price:
                                                        item?.original_price,
                                                      avatar: item?.avatar,
                                                      name: item?.name,
                                                      serviceAreaId:
                                                        item?.service_area_id,

                                                      branch_id:
                                                        item?.branch_id,
                                                      item: item,
                                                      supplementory:
                                                        item?.supplementory,
                                                      selected_serviceItem:
                                                        item,
                                                    },
                                                  });
                                                  setSearchValue("");
                                                  setCloseSearchList(true);
                                                }}
                                              >
                                                <img
                                                  style={{
                                                    width: "45px",
                                                    height: "45px",
                                                    objectFit: "cover",
                                                  }}
                                                  src={`${item?.avatar}`}
                                                  loading="lazy"
                                                />
                                              </Col>
                                              <Col
                                                className="cursor-pointer-class"
                                                onClick={() => {
                                                  push({
                                                    pathname: `/documentaion/`,
                                                    state: {
                                                      acticeFilter:
                                                        item?.service_id,
                                                      price:
                                                        item?.original_price,
                                                      avatar: item?.avatar,
                                                      name: item?.name,
                                                      serviceAreaId:
                                                        item?.service_area_id,

                                                      branch_id:
                                                        item?.branch_id,
                                                      item: item,
                                                      supplementory:
                                                        item?.supplementory,
                                                      selected_serviceItem:
                                                        item,
                                                    },
                                                  });
                                                  setSearchValue("");
                                                  setCloseSearchList(true);
                                                }}
                                              >
                                                <div className="d-flex flex-column">
                                                  <div className="fw-bolder dark-text-secondary-color font-weight-bold">
                                                    {currentLanguage ===
                                                    "english"
                                                      ? item?.name
                                                      : item?.name_sp}
                                                  </div>
                                                  <div
                                                    className="d-flex gap-3 text-success"
                                                    style={{ fontSize: "14px" }}
                                                  >
                                                    {Number(
                                                      item?.original_price
                                                    ) +
                                                      Number(
                                                        item?.supplementary_fees
                                                      ) !==
                                                    0 ? (
                                                      <div>
                                                        <span>
                                                          {"€" +
                                                            replaceDecimalWithComma(
                                                              Number(
                                                                item?.original_price
                                                              ) +
                                                                Number(
                                                                  item?.supplementary_fees
                                                                )
                                                            )}
                                                        </span>
                                                      </div>
                                                    ) : <div>
                                                        <span>
                                                          {`€${
                                                            replaceDecimalWithComma(
                                                              item?.original_price
                                                            ) === "0"
                                                          }`}
                                                        </span>
                                                      </div> ? (
                                                      <div>
                                                        <span>
                                                          {labels.free}
                                                        </span>
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <span>
                                                          {replaceDecimalWithComma(
                                                            item?.original_price
                                                          )}
                                                        </span>
                                                      </div>
                                                    )}{" "}
                                                    {Number(
                                                      item?.original_price
                                                    ) +
                                                      Number(
                                                        item?.supplementary_fees
                                                      ) !==
                                                    0 ? (
                                                      <>
                                                        {((item?.original_price -
                                                          item?.reduced_price) /
                                                          item?.original_price) *
                                                          100 !==
                                                          0 && (
                                                          <div>
                                                            <span className="text-danger">
                                                              {"€"}
                                                              {replaceDecimalWithComma(
                                                                Number(
                                                                  item?.reduced_price ||
                                                                    0
                                                                ) +
                                                                  Number(
                                                                    item?.supplementary_fees ||
                                                                      0
                                                                  )
                                                              )}{" "}
                                                              {(
                                                                ((item?.original_price -
                                                                  item?.reduced_price) /
                                                                  item?.original_price) *
                                                                100
                                                              )?.toFixed(0)}
                                                              %{" "}
                                                              {
                                                                labels.item
                                                                  ?.reduced_price
                                                              }
                                                            </span>
                                                          </div>
                                                        )}
                                                      </>
                                                    ) : null}
                                                    {item?.service_pack_type ===
                                                    "monthly" ? (
                                                      labels?.monthly
                                                    ) : (
                                                      <div>
                                                        <span>
                                                          {labels.estimatedTime}{" "}
                                                          {item?.time}{" "}
                                                          {languageTranslationFunctionForTheTime(
                                                            item?.time,
                                                            item?.time_type
                                                          )}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </Col>
                                              <Col sm={1}>
                                                <img
                                                  onClick={() => {
                                                    setInfoModal(true);
                                                    setInfo(
                                                      currentLanguage ===
                                                        "english"
                                                        ? item?.info
                                                        : item?.info_sp
                                                    );
                                                  }}
                                                  src={Images.infoo.default}
                                                  className="position-absolute"
                                                  style={{
                                                    width: 20,
                                                    height: 20,
                                                    top: 15,
                                                    right: 0,
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          </ListGroup.Item>
                                          {/* <div className="row  mt-1">
                              <div className="col-sm-3">
                                <img
                                  style={{ width: "60px", height: "60px" }}
                                  src={`${item?.avatar}`}
                                  loading="lazy"
                                />
                              </div>
                              <div className="col-sm-7">
                                <ListGroup.Item
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    push({
                                      pathname: `/documentaion/`,
                                      state: {
                                        acticeFilter: item?.service_id,
                                        price: item?.original_price,
                                        avatar: item?.avatar,
                                        name: item?.name,
                                        serviceAreaId: item?.service_area_id,

                                        branch_id: item?.branch_id,
                                        item: item,
                                        supplementory: item?.supplementory,
                                      },
                                    });
                                    setSearchValue("");
                                    setCloseSearchList(true);
                                  }}
                                  className="btn-link border-0 py-1"
                                >
                                  {currentLanguage === "english" ? item?.name_sp : item?.name}
                                </ListGroup.Item>
                              </div>
                              <div className="col-sm-2">
                                <img
                                  onClick={() => {
                                    setInfoModal(true);
                                                                           setInfo(currentLanguage === "english" ? item?.info_sp : item?.info);

                                  }}
                                  src={Images.infoo.default}
                                  className="position-absolute"
                                  style={{
                                    width: 20,
                                    height: 20,
                                    top: 15,
                                    right: 0,
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            </div> */}
                                        </>
                                      );
                                    })}
                                  </ListGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </>
                      ) : null}
                    </div>
                  </Fragment>
                ) : (
                  <div className="mb-20 ml-50">
                    <p>{labels.noRecordsFound}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {/* <Dropdown className="drop-down">
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="custom-toggle"
          >
            <span className="toggle_menu">
              <img src={Images.u1.default} className={"userNavProfileImage "} />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="/1/myaccount">Profile</Dropdown.Item>
            <Dropdown.Item href="#/action-2">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "120%",
                }}
              >
                <span style={{ marginRight: "5px" }}>Beneficiaries</span>
              </div>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-3">Services</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Special Prices</Dropdown.Item>
            <Dropdown.Item href="/about">About Us</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        {/* {burger menu} */}
      </header>
      <Modal onHide={() => {}} show={show} size="auto">
        <ModalBody className="bg-white p-0 border-radius-10">
          {isLoggedIn ? (
            <User_Login
              props={props}
              change={changeForm}
              hideModal={handleClose}
            />
          ) : (
            <User_Registration
              props={props}
              change={changeForm}
              hideModal={handleClose}
            />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}
