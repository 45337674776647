import React from 'react'

function NicolasIntroSpanish() {
  return (
    <div>
          <p className="">
          <p>Consultor de negocios y empresario.&nbsp;</p>
        <p>Se crió en Alemania siendo el padre alemán y la madre venezolana. Con 20 años se mudó a España para mejorar el español y estudiar Business Administration en la Universidad Complutense de Madrid.</p>
        <p>Empezó su carrera profesional en la aseguradora estadounidense Cigna. Poco después le hicieron una oferta en Accenture como Consultor de Negocio en el sector aeronáutico, especializándose en la reducción de costes y optimización de la cadena de producción y de suministro de aviones en España, Alemania y Francia.&nbsp;</p>
        <p>A principios de 2018 decide dedicar todo su esfuerzo a emprender, empezando por el sector del E-Commerce y Dropshipping, naciendo la idea de DucDoc en septiembre de 2019.</p>
        </p>
    </div>
  )
}

export default NicolasIntroSpanish