import React, { useRef, useState } from "react";
// import { Images } from "../../constant/images";

import { Images as Icon, Images } from "../../constant/images";
import { AllServices } from "../../component";
import ScrollAnimation from "react-animate-on-scroll";
import { Modal, ModalBody } from "react-bootstrap";
import { AlignCenter, Windows } from "react-bootstrap-icons";
import labels from "../../config/Labels";
import OurAppEnglish from "../../component/OurAppEnglish";
import OurAppSpanish from "../../component/OurAppSpanish";
import OurPartnerEnglish from "../../component/OurPartnerEnglish";
import OurPartnerSpanish from "../../component/OurPartnerSpanish";
import OurMissionEnglish from "../../component/OurMissionEnglish";
import OurMissionSpanish from "../../component/OurMissionSpanish";
import OurStoryEnglish from "../../component/OurStoryEnglish";
import OurStorySpanish from "../../component/OurStorySpanish";
import NicolasIntroEnglish from "./components/NicolasIntroEnglish";
import NicolasIntroSpanish from "./components/NicolasIntroSpanish";
import NataliaIntroEnglish from "./components/NataliaIntroEnglish";
import NataliaIntroSpanish from "./components/NataliaIntroSpanish";
import MyProfileModal from "../../component/MyProfileModal";
import { Helmet } from "react-helmet";

export default function About(props) {
  const [ourStoryModal, setOurStoryModal] = React.useState(false);
  const messagesEndRef = useRef(null);
  const [modalPic1, setModalPic1] = useState(false);

  React.useEffect(() => {
    if (props.location.state === "mobileApp") {
      scrollToBottom();
    } else {
      window.scrollTo(0, 0);
    }
  }, [props.location.state, modalPic1]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handlePageChange = () => {
    // window.open = "https://play.google.com/store/apps";
    window.open("https://play.google.com/store/apps", "_blank");
  };
  const handlePageChangeAppStore = () => {
    // window.open = "https://play.google.com/store/apps";
    window.open("https://www.apple.com/app-store/", "_blank");
  };
  return (
    <>
      <Helmet>
        <title>DocuDoc App | Abogados, Asesores y Gestores</title>
        <meta
          name="description"
          content="DocuDoc es la solución perfecta para todos aquellos que necesitan cualquier tipo de gestión o servicio legal de una manera simple, rápida y al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <div id="box_wrapper">
        <section className="page_slider d-md-block  ">
          <div className="flexslider" data-nav="true" data-dots="false">
            <ul className="slides">
              <li className=" text-center slide2">
                <span className="flexslider-overlay gradiant-theme-color "></span>
                {/* <img src={Images.slide02.default} className="h-75"  /> */}

                <div className="container mt-40 py-30 mr-10">
                  <div className="row ">
                    <div className="col-lg-12  intro_slider ">
                      <div className="intro_layers_wrapper justify-content-center">
                        <div
                          className="intro_layer topleftimage"
                          data-animation="fadeInLeft"
                        >
                          <ScrollAnimation
                            animateIn="slideInLeft"
                            animateOnce={true}
                          >
                            <img src={Icon.img.default} alt="leftimg" />
                          </ScrollAnimation>
                        </div>
                        <div className="">
                          <div className="intro_layers">
                            <div className="intro_layer">
                              <h2 className="text-uppercase intro_featured_word  logo text-center  ">
                                <ScrollAnimation
                                  animateIn="bounceIn"
                                  animateOnce={true}
                                >
                                  <img src={Icon.logob.default} alt="logo" />
                                </ScrollAnimation>
                              </h2>
                            </div>
                            <ScrollAnimation
                              animateIn="bounceIn"
                              animateOnce={true}
                            >
                              <div className="intro_layer width90">
                                {/* <h3 className=" intro_before_featured_word text-black mb-0 ">
                                    {labels.legalServices}
                                  </h3> */}
                                <span className="intro_layer_types_style ">
                                  {labels.welcomeScreenText}
                                </span>
                                <ol className="list-unstyled list-style-custom-align">
                                  <li>
                                    <span className="ml-10 ">
                                      {labels.inAFewClicks}{" "}
                                    </span>
                                    <span className="float-left imgFix">
                                      <img src={Icon.check.default} />
                                    </span>
                                  </li>
                                  <li>
                                    <span className="ml-10 ">
                                      {labels.theBestPrice}{" "}
                                    </span>
                                    <span className="float-left imgFix">
                                      <img src={Icon.check.default} />
                                    </span>
                                  </li>
                                  <li>
                                    <div className="ml-10 ">
                                      <span>
                                        {labels.withTheMostQualifiedPartners}{" "}
                                      </span>
                                    </div>
                                    <span className="float-left imgFix">
                                      <img src={Icon.check.default} />
                                    </span>
                                  </li>
                                </ol>
                              </div>
                            </ScrollAnimation>
                          </div>
                          <ScrollAnimation
                            animateIn="slideInUp"
                            animateOnce={true}
                          >
                            <div
                              className="intro_layer page-bottom "
                              data-animation="expandUp"
                            ></div>
                          </ScrollAnimation>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="flexslider-bottom d-xl-block"></div>
        </section>
      </div>
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
        <section className="mb-1" data-animation="fadeInUp">
          <div className="wrapper-main ">
            <div className="container">
              <div className="row marginNegative200">
                <div className="col-sm-12 col-lg-6 col-md-6">
                  <div
                    id="ins-icon-6046fc09aeded"
                    className="ins-float-icon-wrapper margin-20px-bottom ins-icon-box-align-left-basic fadeInUp"
                    data-animation-delay="200"
                  >
                    <div className="ins-float-icon-inner position-relative">
                      <i className="link-icon text-medium-gray icon-medium iconsmind-Target"></i>
                    </div>
                    {localStorage.getItem("primaryLanguage") == "english" ? (
                      <OurMissionEnglish />
                    ) : (
                      <OurMissionSpanish />
                    )}
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 col-md-6">
                  <div className="section-heading ins-heading text-left mt-5 pt-5">
                    <img
                      src={Images.mockUp.default}
                      className="img-responsive m-80 d-block mx-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="pt-0 pb-80 ls"
          id="services"
          data-animation="fadeInUp"
        >
          <div className="wrapper-main padd60 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-lg-6 col-md-6">
                  <div className="section-heading ins-heading text-left">
                    <div className="buttons d-flex flex-wrap">
                      <div
                        className="button-container text-center mr-2 cursorPointer"
                        onClick={() => {
                          setModalPic1(true);
                          setOurStoryModal(true);
                        }}
                      >
                        <span className="button__image-wrapper">
                          <img
                            src={Images.nicolas.default}
                            alt=""
                            className="button__image h-fit"
                          />
                        </span>
                        <span className="button__label">
                          {labels.founder}
                          <br />
                          Nicolas Adolph
                        </span>
                      </div>
                      <div
                        className="button-container text-center ml-2 mt-5 pt-5 cursorPointer"
                        onClick={() => {
                          setOurStoryModal(true);
                        }}
                      >
                        <span className="button__image-wrapper">
                          <img
                            src={Images.natalia.default}
                            alt=""
                            className="button__image h-fit"
                          />
                        </span>
                        <span className="button__label">
                          {labels.legalAdvisor}
                          <br />
                          Natalia Bustamante
                        </span>
                      </div>
                      <div className="button-companion">
                        <img src="/img/founders.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 col-md-6">
                  <div
                    id="ins-icon-6046fc09aeded"
                    className="ins-float-icon-wrapper margin-20px-bottom ins-icon-box-align-left-basic fadeInUp"
                    data-animation-delay="200"
                  >
                    <div className="ins-float-icon-inner position-relative">
                      <i className="link-icon text-medium-gray icon-medium iconsmind-Target"></i>
                    </div>

                    {localStorage.getItem("primaryLanguage") == "english" ? (
                      <OurStoryEnglish />
                    ) : (
                      <OurStorySpanish />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="s-pt-70  ls"
          id="services"
          data-animation="fadeInUp"
        >
          <div className="wrapper-main padd60 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-lg-6 col-md-6">
                  <div
                    id="ins-icon-6046fc09aeded"
                    className="ins-float-icon-wrapper margin-20px-bottom ins-icon-box-align-left-basic fadeInUp"
                    data-animation-delay="200"
                  >
                    <div className="ins-float-icon-inner position-relative">
                      <i className="link-icon text-medium-gray icon-medium iconsmind-Target"></i>
                    </div>
                    {/* our partner section */}
                    {localStorage.getItem("primaryLanguage") == "english" ? (
                      <OurPartnerEnglish />
                    ) : (
                      <OurPartnerSpanish />
                    )}
                    <div
                      className="ins-float-icon-box-content"
                      ref={messagesEndRef}
                    ></div>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 col-md-6">
                  <div className="section-heading ins-heading text-left ">
                    <img
                      src={Images.partner2.default}
                      className="img-responsive m-80 d-block mx-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="work-wrap wow fadeInUp">
          <div className="container">
            <h4 style={{ fontSize: "30px" }} className="mb-5 ">
              {labels.whyChooseUs}
            </h4>
            <ul className="fix text-center row">
              <li className="single-working-steps-box wow fadeInUp  col-lg-3 col-md-6">
                <div className="icon-holder">
                  <img className="mb-3 w-50" src={Images.user.default} />
                </div>
                <div className="text-holder">
                  <h4 className="">{labels.topQualityAndSatisfaction}</h4>
                  <p className="">{labels.topQualityAndSatisfactionDetails}</p>
                </div>
              </li>

              <li className="single-working-steps-box wow fadeInUp  col-lg-3 col-md-6">
                <div className="icon-holder">
                  <img className="mb-2 mr-3 w-50 " src={Images.euro.default} />
                </div>

                <div className="text-holder">
                  <h4 className="">{labels.atTheBetPrice}</h4>
                  <p className="">{labels.atTheBetPriceDetails}</p>
                </div>
              </li>

              <li className="single-working-steps-box wow fadeInUp  col-lg-3 col-md-6">
                <div className="icon-holder">
                  <img className="mr-3 mb-4 w-50" src={Images.click.default} />
                </div>
                <div className="text-holder">
                  <h4 className="">{labels.mostEffient}</h4>
                  <p className="">{labels.mostEffientDetails}</p>
                </div>
              </li>

              <li className="single-working-steps-box wow fadeInUp  col-lg-3 col-md-6">
                <div className="icon-holder">
                  <img className="mb-2 w-50" src={Images.refund.default} />
                </div>
                <div className="text-holder">
                  <h4 className="">{labels.sustainable}</h4>
                  <p className="">{labels.sustainableDetails}</p>
                </div>
              </li>
            </ul>
          </div>
          <Modal
            show={ourStoryModal}
            onHide={() => {
              setModalPic1(false);
              setOurStoryModal(false);
            }}
            size="md"
          >
            <ModalBody
              scrollable={true}
              style={{ width: "500px", height: "500px", overflowY: "scroll" }}
              className="bg-white p-0 border-radius-10 alignItemsCenter"
            >
              <div className="container p-30 ">
                <div className="row">
                  <div className="col-12 ml-1 align-self-center">
                    <img
                      src={
                        modalPic1
                          ? Images.nicolas.default
                          : Images.natalia.default
                      }
                      className="widthFillAvailable"
                    />
                    <div className="text-center">
                      <h5 className="mb-0 mt-2">
                        {modalPic1 ? "Nicolas Adolph" : "Natalia Bustamante"}
                      </h5>
                      <label>
                        {modalPic1
                          ? `CEO, ${labels.founder}`
                          : labels.legalAdvisor}
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    {modalPic1 &&
                      (localStorage.getItem("primaryLanguage") == "english" ? (
                        <NicolasIntroEnglish />
                      ) : (
                        <NicolasIntroSpanish />
                      ))}

                    {modalPic1 === false &&
                      (localStorage.getItem("primaryLanguage") == "english" ? (
                        <NataliaIntroEnglish />
                      ) : (
                        <NataliaIntroSpanish />
                      ))}
                    {/* <NataliaIntroSpanish /> */}
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </ScrollAnimation>
      {/* <AllServices /> */}
    </>
  );
}
