import React from "react";
import { useLocation } from "react-router-dom";
import TermsAndConditionEng from "../../component/termAndConditionEng";
import TermsAndConditionsSpanish from "../../component/termsAndConditionSpanish";
import { Helmet } from "react-helmet";

export default function ({}) {
  const search = useLocation().search;
  const key = new URLSearchParams(search).get("lang");
  let language = localStorage.getItem("primaryLanguage");
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <section className="pb-60  ls" id="services" data-animation="fadeInUp">
        {key === null ? (
          language === "english" ? (
            <TermsAndConditionEng />
          ) : (
            <TermsAndConditionsSpanish />
          )
        ) : key === "english" ? (
          <TermsAndConditionEng />
        ) : (
          <TermsAndConditionsSpanish />
        )}
      </section>
    </>
  );
}
