import React from "react";
import {
  Button,
  Accordion,
  Card,
} from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";


const FagEnglish=()=>{
    return (
        <section
          className="pt-150 pb-150 ls faq-section section-b-space"
          data-animation="fadeInUp"
        >
          <div className="container">
            <h4 className="mb-20 text-center">Frequently asked questions</h4>
            <div className="row">
              <div className="col-12 col-md-12">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                  <Accordion
                    defaultActiveKey="0"
                    className="accordion theme-accordion"
                  >
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        How can I contact my service provider?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <p>
                           "If you wish to contact the provider of the contracted service, you must go to your profile > 
                           My orders > select order and click on "Contact". You will be able to talk and exchange information with the service provider during the business hours indicated in the blue (i) button on the right side of the service provider button"
                          </p>

                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        How can I contact DocuDoc about a matter not related to a contracted service?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <p>If you wish to contact DocuDoc about a matter not related to a contracted service, you can do so through the following email: <a href="./info@docudocapp.com">info@docudocapp.com</a>.</p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        How do we guarantee secure payments?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <p>
                          Only if you pay through the platform, we guarantee that you receive the service correctly.
                          If you do not receive part or all of the service, we guarantee a refund for the part of the service not received. 
                          </p>
                           <br/>
                          
                          <p>
                          We do not recommend that you contract services with any service providers outside the platform,
                          so that you continue to enjoy this guarantee of secure payment and reimbursement in case you do not receive part or all of the service. 
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="3">
                        How can I claim part or all of the money paid for the service not received?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          <p>
                           If you want to claim part or all of the money paid for the service not received,
                           you must go to Your profile> My orders> select order and click on "Claim money". 
                           Once it is verified that you have not actually received part or all of the contracted service,
                           the corresponding part will be reimbursed. 
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="4">
                        When can I download my invoice?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="4">
                        <Card.Body>
                          <p>
                          The invoice for the contracted service can be downloaded 24 hours after completion and delivery of the contracted service in Your profile > My orders > select order and click "Download Invoice". 
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="5">
                        How can I access the information of each service, service branch, service area or service provider?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="5">
                        <Card.Body>
                          <p>
                          The complete information of each service, service branch,
                           service area or service provider can be found in the blue (i) button on the right side of each button of each service,
                            service branch, service area or service provider.

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="6">
                        Who are the service providers?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="6">
                        <Card.Body>
                          <p>
                          Service providers are independent professionals connected to our platform offering special prices to carry out the legal services that you need.
                         We try to get the best price-quality on the market with our service providers,
                         who will help you resolve your procedures in the fastest and most efficient way.

                          </p>Do you want to be service providers? Brilliant! You just have to write to the following email <p><a href="info@docudocapp.com">info@docudocapp.com!</a></p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="7">
                        How do I place an order?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="7">
                        <Card.Body>
                          <p>
                          Ordering your first DocuDoc is very easy. Simply follow these steps:
                          </p>
                          <ol>
                            <li>Go to the main screen and select the service area, service branch and end service.</li>
                            <li>Enter the necessary data to carry out the service.</li>
                            <li>Select the service provider that will perform the service.</li>
                          </ol>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="8">
                        Is DocuDoc coming to my neighborhood?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="8">
                        <Card.Body>
                          <p>
                          DocuDoc, at the moment, is only present in the Community of Madrid.

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="9">
                        When is DocuDoc open?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="9">
                        <Card.Body>
                          <p>
                          DocuDoc offer a 24/7 service. However, each service provider has its own schedule. To guide you,
                          the hours of the service providers are found on the blue (i) button on the right side of the service provider button.
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="10">
                        How can I change the details of my order?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="10">
                        <Card.Body>
                          <p>
                          If you wish to change the details of your order, 
                          you will need to go to your profile > My orders > select order and click on "Contact". 
                          You can talk and exchange information with the service provider. 

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="11">
                        How I can cancel my order?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="11">
                        <Card.Body>
                          <p>
                          If you want to change the details of your order or simply want to cancel it,
                           you will need to go to your profile > My orders > select order and click on "Cancel order". 
                           You will be charged for the expenses incurred by the service provider. 

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                   
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="12">
                        What can I ask for?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="12">
                        <Card.Body>
                          <p>
                          Use the DocuDoc app to request any legal services available.

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="13">
                        What is DocuDoc?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="13">
                        <Card.Body>
                          <p>
                          DocuDoc is the app that allows you to order all kinds of legal services in minutes.
                          A fiscal management, an advisor or a specialized lawyer.
                           Everything that is related to an Agency, Advice or a Lawyer.

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="14">
                        How can I find what I'm looking for?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="14">
                        <Card.Body>
                          <p>
                          At the top of the screen,
                           there is a search engine that will allow you to find any service or branch of services that we offer.

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="15">
                        How can I change my phone number?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="15">
                        <Card.Body>
                          <p>
                            You can change your contact number in your profile. Once the change is made,
                             you will receive a verification code. The phone number will be updated immediately after confirmation. 

                          
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="16">
                        How can I change my email address?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="16">
                        <Card.Body>
                          <p>
                          You can change the email address associated with your account in your profile information.
                           Once updated, you will receive a verification alert. After confirming, your e-mail will be changed.

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="17">
                        How can I change my password?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="17">
                        <Card.Body>
                          <p>
                          You can change the password associated with your account in your profile information.
                          Once updated, you will receive a notification. After confirming, your password will be changed.

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="18">
                        How can I change my payment method?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="18">
                        <Card.Body>
                          <p>
                            You can change your preferred payment method in your profile information, where you can add or remove cards. Once updated,
                            it will be changed immediately so you can continue ordering services in DocuDoc.
                            </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="19">
                        What kind of cards do you accept?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="19">
                        <Card.Body>
                          <p>
                          We accept Visa, MasterCard and American Express cards.

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="20">
                        What are the payment methods available?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="20">
                        <Card.Body>
                          <p>
                          DocuDoc accepts credit or debit cards, such as Visa, MasterCard or American Express.
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="21">
                        Is it safe to add my credit card?

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="21">
                        <Card.Body>
                          <p>
                          Our payment system is completely secure.
                           In order to guarantee your security we avoid saving any bank details.
                            We use the same payment platform as companies like Twitter, Shopify or Instacart.

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
    
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="22">
                          Why have I been asked to verify my identity?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="22">
                        <Card.Body>
                          <p>
                          We are committed to ensuring that our platform is reliable and secure for you and all DocuDoc members. For this reason, our agents may ask you for proof of identity if an unusual action is detected.
                          If this happens, you will need to attach the documents we request to continue using your DocuDoc account.
                          <br>
                          </br>
                           <br>
                          </br>
                          Security is critical to us, 
                          so we ask that you do not share your account or payment details with anyone other than an internal DocuDoc partner.
                          We would also like to remind you of the importance of having different passwords for each of the platforms you use.

                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                   
                    <Card className="card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="23">
                          How can I redeem my promo code?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="23">
                        <Card.Body>
                          <p>
                            Within your profile, you'll see a section called 'Promo
                            Codes'. Just enter the code* to activate it and you'll
                            be able to enjoy the discount.
                            <br />
                            <br />
                            *The validity and expiration of the code may be subject
                            to changes without prior notice on the part of DocuDoc,
                            according to requirements of promotional campaigns.
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>
      );
}
export default FagEnglish;