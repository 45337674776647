import { onValue, ref } from "firebase/database";
import { Fragment, useEffect, useState } from "react";

import { Card, Col, Row } from "react-bootstrap";
import { database } from "../config/Firebase";
import labels from "../config/Labels";
import { rating, regexForFormatRating } from "../config/Utility";
import { Images } from "../constant/images";
import ProviderInfoModal from "./providerInfoModal";

let lang = localStorage.getItem("primaryLanguage");

const ServiceProviderCard = ({
  item,
  showMessageUnreadCount = null,
  showAlertUnreadCount = null,
  onClickFunction = () => {},
}) => {
  const [orderUnreadCount, setOrderUnreadCount] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { order_id } = item;
  const { avatar, name, experience, fake_rating, average_rating, position } =
    item?.service_provider;
  useEffect(() => {
    if (showMessageUnreadCount) {
      const getData = ref(database, "orders/" + order_id + "/userUnread");
      onValue(getData, (snapshot) => {
        setOrderUnreadCount(snapshot?.val());
      });
    }
  }, []);
  return (
    <Fragment>
      <Col sm={12}>
        <div className="pdpt-bg w-100 mb-2">
          <div div className="order-body10 mb-2">
            <article
              className="shop-summary-item datalist element-emphasis-weak border-radius-10 position-relative mt-10 "
              style={{ cursor: "pointer" }}
            >
              <Card className="shadow-none border-0">
                <Card.Body className="p-1">
                  <Row>
                    <Col
                      sm={2}
                      className="d-flex"
                      onClick={() => onClickFunction()}
                    >
                      <img
                        src={avatar}
                        style={{
                          borderRadius: 10,
                          width: "100px",
                          height: "100px",
                          objectFit: "contain",
                          border: "1px solid #e5e5e5",
                          background: "rgb(229 229 229 / 29%)",
                        }}
                      />
                    </Col>
                    <Col sm={10} onClick={() => onClickFunction()}>
                      <div
                        className="fontSize16px mb-0 "
                        style={{ lineHeight: "25px" }}
                      >
                        <div>
                          <strong>{name}</strong>
                        </div>
                        <div>
                          <span>{labels.idOrder}:</span>{" "}
                          <strong>{order_id}</strong>
                        </div>
                        <div>
                          <span>{labels.position}:</span>{" "}
                          <strong>
                            {(item?.service_provider?.[lang === "english" ? "position" : "position_sp"] || "N/A")?.substring(0,35)}
                            {(item?.service_provider?.[lang === "english" ? "position" : "position_sp"] || "N/A")?.length > 35 ? "... " : ""}
                          </strong>
                        </div>
                        <div>
                          <span>{labels.experience}:</span>{" "}
                          <strong>
                            {experience} {labels.years}
                          </strong>
                        </div>
                        <div className=" d-flex justify-content-between">
                          <div>
                            <span>{labels.rating}:</span>{" "}
                            <span>
                              {regexForFormatRating(
                                fake_rating != null
                                  ? Number(fake_rating)
                                  : Number(
                                      !average_rating?.length
                                        ? "0"
                                        : average_rating
                                    )
                              )}
                              <span style={{ color: "#FED42D" }}>
                                {rating(
                                  fake_rating != null
                                    ? Number(fake_rating)
                                    : Number(
                                        !average_rating?.length
                                          ? "0"
                                          : average_rating
                                      )
                                )}
                              </span>
                            </span>
                          </div>
                          <div>
                            {orderUnreadCount > 0 && (
                              <strong>
                                <div
                                  className="d-flex justify-content-center align-items-center rounded-circle ml-2 text-white"
                                  style={{
                                    fontSize: 12,
                                    background: "#ff6464ff",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                >
                                  {orderUnreadCount > 99
                                    ? `+${99}`
                                    : orderUnreadCount}
                                </div>
                              </strong>
                            )}
                            {showAlertUnreadCount > 0 && (
                              <strong>
                                <div
                                  className="d-flex justify-content-center align-items-center rounded-circle ml-2 text-white "
                                  style={{
                                    fontSize: 12,
                                    background: "#ff6464ff",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                >
                                  {showAlertUnreadCount > 99
                                    ? `+${99}`
                                    : showAlertUnreadCount}
                                </div>
                              </strong>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {/* <div className="bg-danger ">x</div> */}
              <img
                onClick={() => setIsModalOpen(true)}
                src={Images.infoo.default}
                className="position-absolute"
                style={{
                  width: 20,
                  height: 20,
                  top: "40%",
                  right: 0,
                  cursor: "pointer",
                }}
              />
            </article>
          </div>
        </div>
      </Col>
      <ProviderInfoModal
        show={isModalOpen}
        info={{ ...item?.service_provider }}
        onHide={() => setIsModalOpen(false)}
      />
    </Fragment>
  );
};

export default ServiceProviderCard;
