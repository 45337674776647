import React, { useState } from "react";
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import OtpInput from "react-otp-input";
import { useToasts } from "react-toast-notifications";
import labels from "../../config/Labels";
import { useStoreItem } from "../../config/Store";
import { Utility } from "../../config/Utility";
import {
  Facebook_Login_Method,
  Forgot_Password,
  Google_Login_Method,
  Login_Signup_Verify_Code,
  Reset_password,
  User_Login_Method,
  Verify_Code,
} from "../../config/api";
import { Images } from "../../constant/images";

const { logo, Google, Facebook } = Images;
const successMessageLanguage = (responseMessage) => {
  const language = localStorage.getItem("primaryLanguage") === "english";
  return responseMessage[language ? "message" : "message_sp"];
};
const errorMessageLanguage = (responseMessage) => {
  const language = localStorage.getItem("primaryLanguage") === "english";
  return responseMessage[language ? "message" : "message_sp"];
};
const User_Login = (props) => {
  const [email, setEmail] = useState("");
  const [forgotPasswordEmail, setforgotPasswordEmail] = useState("");
  const [forgotpassword, setforgotpassword] = useState("");
  const [confirmForgotPassword, setConfirmForgotPassword] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [fieldIsRequired, setIsFieldRequired] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [visibleOtp, setVisibleOtp] = useState(false);
  const [otpPasswordFieldVisible, setOtpPasswordFieldVisible] = useState(false);
  const [showEmail, setShowEmail] = useState(true);
  const [Spinner, setSpinner] = useState(false);
  const { updateUser } = useStoreItem();

  const [show, setShow] = useState(false);
  const [otp, setOpt] = useState("");

  const [loginOtpShow, setLoginOtpShow] = useState(false);
  const [loginOtp, setLoginOtp] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { addToast } = useToasts();
  const [passwordConfirm, setPasswordConfirm] = useState(false);
  const [none, setnone] = useState(true);
  const [passtype, setpasstype] = useState("password");
  const [eye, seteye] = useState(true);
  const [passred, setpassred] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleConfirmedPassword = (e) => {
    setConfirmForgotPassword(e);
    if (e === forgotpassword) {
      setPasswordConfirm(true);
    } else {
      setPasswordConfirm(false);
    }
  };

  // if(password.length>0){
  //   setnone(false);
  //   }
  //   else{
  //   setnone(true);
  //   }
  const Eye = () => {
    if (passtype == "password") {
      setpasstype("text");
      seteye(false);
      setpassred(true);
    } else {
      setpasstype("password");
      seteye(true);
      setpassred(false);
    }
  };

  const handleChange = (otp) => setOpt(otp);
  const loginOtpHandleChange = (otp) => setLoginOtp(otp);

  const UserSignedIn = (e) => {
    e.preventDefault();
    let obj = {
      email: email,
      password: password,
    };
    if (email !== "" && password !== "") {
      setIsFieldRequired(false);
      setShowSpinner(true);
      User_Login_Method(
        obj,
        (success) => {
          setShowSpinner(false);
          if (success?.status === 200) {
            if (
              success?.data?.response?.detail?.user?.is_email_verified === 0
            ) {
              addToast(successMessageLanguage(success?.data?.response), {
                appearance: "info",
                autoDismiss: 1000,
              });
              setLoginOtpShow(true);
            } else {
              addToast(successMessageLanguage(success?.data?.response), {
                appearance: "success",
                autoDismiss: 1000,
              });

              updateUser(success?.data?.response?.detail?.user);
              localStorage.setItem(
                "usertoken",
                success.data.response.detail.token
              );

              localStorage.setItem(
                "firstName",
                success?.data?.response?.detail?.user?.first_name
              );
              localStorage.setItem(
                "lastName",
                success?.data?.response?.detail?.user?.last_name
              );
              localStorage.setItem(
                "emailAddress",
                success?.data?.response?.detail?.user?.email
              );
              // localStorage.setItem("CIF",success?.data?.response?.detail?.user?.CIF);
              localStorage.setItem(
                "phone_number",
                success?.data?.response?.detail?.user?.phone_number
              );
              localStorage.setItem(
                "loginInUserAccount",
                JSON.stringify(success?.data?.response?.detail?.user)
              );
              props.hideModal();
            }
          }
        },
        (error) => {
          setShowSpinner(false);
          if (error.response?.status === 422) {
            addToast(errorMessageLanguage(error?.response?.data?.error), {
              appearance: "error",
              autoDismiss: 1000,
            });
          } else if (error.response?.status === 500) {
            addToast(errorMessageLanguage(error?.response?.data?.error), {
              appearance: "error",
              autoDismiss: 1000,
            });
          } else {
            addToast("Network Error", {
              appearance: "error",
              autoDismiss: 1000,
            });
          }
        }
      );
    } else {
      setIsFieldRequired(true);
    }
    // if (email !== "" && password !== "") {
    //   if (Utility?.validateEmail(email)) {
    //     User_Signin_Method(
    //       obj,
    //       (value) => {
    //         updateUser(value);
    //       },
    //       (value) => setShowSpinner(value),
    //       (value) => {
    //         setMessage(value);
    //         addToast(value, {
    //           appearance: value == "Login Successfully" ? "success" : "info",
    //           autoDismiss: 1000,
    //         });
    //       },
    //       (value) => setShowToast(value),
    //       (value) => setSuccess(value),
    //       () => {
    //         // setTimeout(() => {
    //         props.hideModal();
    //         // }, 1000);
    //       }
    //     );
    //     setIsEmailValid(false);
    //   } else {
    //     setIsEmailValid(true);
    //   }
    // } else {
    //   setIsFieldRequired(true);
    // }
  };

  const loginVerificationCode = () => {
    if (loginOtp !== "") {
      setIsFieldRequired(false);
      setShowSpinner(true);
      let obj = {
        email: email,
        verification_code: loginOtp,
      };
      Login_Signup_Verify_Code(
        obj,
        (success) => {
          setShowSpinner(false);
          if (success?.data?.status) {
            // addToast(successMessageLanguage(success?.data?.response), {
            //   appearance: "success",
            //   autoDismiss: 1000,
            // });

            updateUser(success?.data?.response?.detail?.user);
            localStorage.setItem(
              "usertoken",
              success.data.response.detail.token
            );

            localStorage.setItem(
              "firstName",
              success?.data?.response?.detail?.user?.first_name
            );
            localStorage.setItem(
              "lastName",
              success?.data?.response?.detail?.user?.last_name
            );
            localStorage.setItem(
              "emailAddress",
              success?.data?.response?.detail?.user?.email
            );
            // localStorage.setItem("CIF",success?.data?.response?.detail?.user?.CIF);
            localStorage.setItem(
              "phone_number",
              success?.data?.response?.detail?.user?.phone_number
            );
          }
          props.hideModal();
        },
        (error) => {
          setShowSpinner(false);
          if (error.response?.status === 422) {
            // addToast(errorMessageLanguage(error?.response?.data?.error), {
            //   appearance: "error",
            //   autoDismiss: 1000,
            // });
          } else if (error.response?.status === 500) {
            // addToast(errorMessageLanguage(error?.response?.data?.error), {
            //   appearance: "error",
            //   autoDismiss: 1000,
            // });
          } else {
            // addToast("Network Error", {
            //   appearance: "error",
            //   autoDismiss: 1000,
            // });
          }
        },
        addToast
      );
    } else {
      setIsFieldRequired(true);
    }
  };

  const forgotPassword = () => {
    let obj = {
      email: forgotPasswordEmail,
    };
    if (forgotPasswordEmail !== "") {
      if (Utility.validateEmail(forgotPasswordEmail) === true) {
        Forgot_Password(
          obj,
          (value) => {
            setSuccess(value);

            if (value == true) {
              setOtpShow(!otpShow);
            }
          },
          (value) => {
            setMessage(value);

            addToast(value, {
              appearance:
                value == "Verification code sent successfully!"
                  ? "success"
                  : "info",
              autoDismiss: 1000,
            });
          }
        );
        setIsEmailValid(false);
      } else {
        setIsEmailValid(true);
      }
    } else {
      setIsFieldRequired(true);
    }
  };

  const VerificationCode = () => {
    if (otp !== "") {
      let obj = {
        email: forgotPasswordEmail,
        verification_code: otp,
      };
      Verify_Code(
        obj,
        (value) => {
          setSuccess(value);

          if (value == true) {
            setOtpPasswordFieldVisible(true);
            setOtpShow(!otpShow);
            setShowEmail(false);
          }
        },
        (value) => {
          setMessage(value);
          addToast(value, { appearance: "success", autoDismiss: "1500" });
        }
      );
    } else {
      setIsFieldRequired(true);
    }
  };

  const ResetPassword = () => {
    let obj = {
      email: forgotPasswordEmail,
      password: forgotpassword,
    };
    if (forgotpassword?.length && confirmForgotPassword?.length) {
      Reset_password(
        obj,
        (value) => {
          setSuccess(value);

          if (value == true) {
            setShow(false);
          }
        },
        (value) => {
          setMessage(value);
          addToast(value, { appearance: "success" });
        }
      );
      setIsFieldRequired(false);
    } else {
      setIsFieldRequired(true);
    }
  };

  const closeToast = () => {
    setShowToast(false);
  };

  const GoogleResponse = (response) => {
    let obj = {
      google_id: response.profileObj.googleId,
      first_name: response.profileObj.givenName,
      last_name: response.profileObj.familyName,
      email: response.profileObj.email,
    };

    Google_Login_Method(
      obj,
      (value) => {
        updateUser(value);
      },
      (value) => setShowSpinner(value),
      (value) => setMessage(value),
      (value) => setShowToast(value),
      (value) => setSuccess(value),
      () => {
        setTimeout(() => {
          props.hideModal();
        }, 1000);
      }
    );
  };

  const FailureResponse = (fail) => {};

  const responseFacebook = (response) => {
    let obj = {
      facebook_id: response.id,
      first_name: response.first_name,
      last_name: response.last_name,
      email: response.email,
    };

    Facebook_Login_Method(
      obj,
      (value) => {
        updateUser(value);
      },
      (value) => setShowSpinner(value),
      (value) => setMessage(value),
      (value) => setShowToast(value),
      (value) => setSuccess(value),
      () => {
        setTimeout(() => {
          props.hideModal();
        }, 1000);
      }
    );
  };

  return (
    <>
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <div className="mx-auto">
              <img src={Images.circleLogo.default} class="circlelogo" />
            </div>
          </Modal.Header>
          <Modal.Body>
            {!otpShow && showEmail && (
              <>
                <div class="form-group">
                  <label for="emailAddress">{labels.emailAddress}</label>
                  <input
                    type="email"
                    class="form-control"
                    id="emailAddress"
                    required=""
                    placeholder={labels.emailAddress}
                    value={forgotPasswordEmail}
                    onChange={(e) => {
                      setforgotPasswordEmail(e.target.value);
                    }}
                  />
                  {fieldIsRequired === true && forgotPasswordEmail === "" ? (
                    <p style={{ color: "red" }}>{labels.validateField}</p>
                  ) : (
                    forgotPasswordEmail?.trim()?.length !== 0 &&
                    !Utility?.validateEmail(forgotPasswordEmail) && (
                      <p style={{ color: "red" }}>
                        {labels.emailValidateField}
                      </p>
                    )
                  )}

                  {/* {isEmailValid === true && (
                    <p style={{ color: "red" }}>{labels.emailValidation}</p>
                  )} */}
                </div>
              </>
            )}

            {otpShow && (
              <>
                <div className="text-center">
                  <h5>{labels.enterTheCodeReceivedByEmail?.toUpperCase()}</h5>
                  <p>
                    {
                      labels.ifYouDontReceiveItInYourInboxItMayBeInYourSpamFolder
                    }
                  </p>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={4}
                    separator={<span>-</span>}
                    inputStyle={{
                      width: "3rem",
                      height: "3rem",
                      margin: "0 1rem",
                      fontSize: "1rem",
                      borderRadius: 10,
                      border: "1px solid rgba(0,0,0,0.3)",
                    }}
                  />
                </div>
              </>
            )}
            {otpPasswordFieldVisible && (
              <>
                <div class="form-group">
                  <label for="loginPassword">{labels.password}</label>
                  <input
                    type="password"
                    class="form-control"
                    id="ResetPassword"
                    required=""
                    placeholder={labels.password}
                    value={forgotpassword}
                    onChange={(e) => {
                      setforgotpassword(e.target.value);
                    }}
                  />
                  {fieldIsRequired === true && forgotpassword === "" ? (
                    <p style={{ color: "red" }}>{labels.passwordRequired}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div class="form-group">
                  <label for="loginPassword">{labels.confirmPassword}</label>
                  <input
                    type="password"
                    class="form-control"
                    id="resetpassword"
                    required=""
                    placeholder={labels.confirmPassword}
                    value={confirmForgotPassword}
                    onBlur={(e) => handleConfirmedPassword(e.target.value)}
                    onChange={(e) => {
                      setConfirmForgotPassword(e.target.value);
                    }}
                  />
                  {!passwordConfirm &&
                  confirmForgotPassword !== forgotpassword ? (
                    <p style={{ color: "red" }}>
                      {labels.confirmationPasswordValidation}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            {otpShow && (
              <div>
                <Button
                  variant="primary"
                  onClick={() => {
                    VerificationCode();
                  }}
                >
                  {labels.confirmation}
                </Button>
              </div>
            )}
            {!otpShow && showEmail && (
              <div>
                <Button
                  variant="primary"
                  onClick={() => {
                    forgotPassword();
                  }}
                >
                  {labels.send}
                </Button>
              </div>
            )}

            {otpPasswordFieldVisible && (
              <div>
                <Button variant="primary" onClick={ResetPassword}>
                  {labels.resetPassword}
                </Button>
              </div>
            )}
          </Modal.Footer>
        </Modal>
      </>
      <div class="row ">
        <div class="col-12 col-lg-11 mx-auto text-left mt-1">
          <div
            className="h-200 w-200"
            aria-hidden="true"
            type="button"
            data-dismiss="modal"
            class="close absolute"
            onClick={props.hideModal}
          >
            <span>&times;</span>
          </div>
          <div class="text-center crossButton">
            <img src={Images.circleLogo.default} class="circlelogo" />
          </div>

          {!loginOtpShow ? (
            <>
              <form onSubmit={UserSignedIn}>
                <div class="form-group">
                  <label for="emailAddress">{labels.emailAddress}</label>
                  <input
                    type="email"
                    class="form-control"
                    id="emailAddress"
                    required
                    placeholder={labels.emailAddress}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  {fieldIsRequired == true && !email.length ? (
                    <p style={{ color: "red" }}>{labels.validateField}</p>
                  ) : (
                    ""
                  )}

                  {email?.trim()?.length !== 0 &&
                    !Utility?.validateEmail(email) && (
                      <p style={{ color: "red" }}>
                        {labels.emailValidateField}
                      </p>
                    )}
                </div>
                <div class="">
                  <label for="loginPassword">{labels.Password}</label>
                  <InputGroup>
                    <FormControl
                      type={showPassword ? "text" : "password"}
                      class="form-control"
                      id="loginPassword"
                      required=""
                      placeholder={labels.Password}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />{" "}
                    <InputGroup.Text>
                      <svg
                        // style={{position:"absolute"}}
                        onMouseDown={() => {
                          setShowPassword(true);
                        }}
                        onMouseUp={() => {
                          setShowPassword(false);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-eye-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                      </svg>
                    </InputGroup.Text>
                  </InputGroup>
                  {fieldIsRequired === true && !password.length ? (
                    <p style={{ color: "red" }}>{labels.validateField}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div class="form-group d-flex justify-content-center mb-0 mt-15">
                  <button
                    class="btn btn-success btn-primary w-50 my-4"
                    type="submit"
                    onClick={UserSignedIn}
                    data-toggle="modal"
                    data-target="#terms"
                    data-dismiss="modal"
                  >
                    {showSpinner === true ? labels.loading : labels.Login}
                  </button>
                </div>
              </form>
              <p className="text-center  custom-font2">{labels.orSignWith}</p>
              <div className="d-flex justify-content-center mb-0">
                <GoogleLogin
                  className="google-btn"
                  clientId={
                    "971184058209-6l9ddm7t9cl2s1vqdpgd48s03bjohmrm.apps.googleusercontent.com"
                  }
                  cookiePolicy={"single_host_origin"}
                  onSuccess={GoogleResponse}
                  onFailure={FailureResponse}
                >
                  <img
                    style={{ width: "40px", height: "40px" }}
                    src={Google.default}
                    className="mt-1"
                  />
                </GoogleLogin>
                <FacebookLogin
                  className="mt-10"
                  appId="1215026149009208"
                  cssClass="facebook-btn"
                  fields="first_name,last_name,email"
                  callback={responseFacebook}
                  textButton={
                    <img
                      style={{ width: "40px", height: "40px" }}
                      src={Facebook.default}
                      className="mt-15"
                    />
                  }
                />
              </div>
              <p class="text-center text-muted mb-0  custom-font2 mt-10">
                {labels.Donthaveac}
                <span
                  onClick={props.change}
                  style={{
                    color: "rgba(70, 188, 255, 1)",
                    marginLeft: "2px",
                    cursor: "pointer",
                  }}
                >
                  {labels.SignUp}
                </span>
              </p>
              <div
                style={{
                  cursor: "pointer",
                }}
                className="d-flex justify-content-center mb-3  custom-font2  btn-link"
                onClick={() => {
                  setShow(true);
                  setOtpShow(false);
                }}
              >
                <span>{labels.ForgotPassword}</span>
              </div>
            </>
          ) : (
            <>
              <Row className="my-30 ">
                <Col>
                  <div className="text-center">
                    <h5>{labels.enterTheCodeReceivedByEmail?.toUpperCase()}</h5>
                    <p>
                      {
                        labels.ifYouDontReceiveItInYourInboxItMayBeInYourSpamFolder
                      }
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-5 mb-2 ">
                <Col className="">
                  <div className="d-flex justify-content-center">
                    <OtpInput
                      value={loginOtp}
                      onChange={loginOtpHandleChange}
                      numInputs={4}
                      separator={<span>-</span>}
                      inputStyle={{
                        width: "3rem",
                        height: "3rem",
                        margin: "0 1rem",
                        fontSize: "1rem",
                        borderRadius: 10,
                        border: "1px solid rgba(0,0,0,0.3)",
                      }}
                    />
                  </div>
                  {loginOtp === "" && fieldIsRequired === true ? (
                    <>
                      <div className="text-center mt-4">
                        <span className="text-danger">
                          {labels.otpRequired}
                        </span>
                      </div>
                    </>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="d-flex justify-content-center  mb-10">
                    <Button
                      className="btn btn-success btn-primary w-50 my-4"
                      type="submit"
                      onClick={loginVerificationCode}
                    >
                      {showSpinner === true
                        ? `${labels.loading}...`
                        : labels.send}
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>

        {/* {showToast === true ? (
          <ToastComponent
            message={message}
            toastClose={closeToast}
            success={success}
          />
        ) : (
          ""
        )} */}
      </div>
    </>
  );
};

export default User_Login;
