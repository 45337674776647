import React, { Fragment, useState } from "react";
import { Button, Col, FormControl, InputGroup, Row } from "react-bootstrap";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "react-google-login";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ToastComponent from "../../component/toast";
import {
  Check_email,
  Facebook_Login_Method,
  Google_Login_Method,
  Login_Signup_Verify_Code,
  SignUp_Api_Method,
} from "../../config/api";
import labels from "../../config/Labels";
import { useStoreItem } from "../../config/Store";
import { Utility } from "../../config/Utility";
import { Images } from "../../constant/images";
import Form from "react-bootstrap/Form";

const { logo, Google, Facebook } = Images;

const User_Registration = (props) => {
  const lang = localStorage.getItem("primaryLanguage");
  const { addToast } = useToasts();
  const { push } = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [fieldIsRequired, setIsFieldRequired] = useState(false);
  const [isFirstName, setIsFirstName] = useState(false);
  const [isLastName, setIsLastName] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [termsAndConditionPopup, setTermsAndConditionPopup] = useState(false);
  const [isEmailExistError, setIsEmailExistError] = useState({
    exist: false,
    message: "",
  });
  const [otpShow, setOtpShow] = useState(false);
  const [otp, setOpt] = useState("");

  const { updateUser } = useStoreItem();

  const createUsers = (e) => {
    e.preventDefault();
    let obj = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
    };

    if (
      firstName?.trim()?.length !== 0 &&
      lastName?.trim()?.length !== 0 &&
      email?.trim()?.length !== 0 &&
      password?.trim()?.length !== 0
    ) {
      if (
        Utility.validateEmail(email) === true &&
        Utility.validateName(firstName) === false &&
        Utility.validateName(lastName) === false
      ) {
        setIsEmailValid(false);
        setIsLastName(true);
        setIsFirstName(true);
      } else if (
        Utility.validateEmail(email) === false &&
        Utility.validateName(firstName) === false &&
        Utility.validateName(lastName) === true
      ) {
        setIsEmailValid(true);
        setIsLastName(true);
        setIsFirstName(true);
      } else if (
        Utility.validateEmail(email) === true &&
        Utility.validateName(firstName) === false &&
        Utility.validateName(lastName) === true
      ) {
        setIsEmailValid(false);
        setIsLastName(false);
        setIsFirstName(true);
      } else if (
        Utility.validateEmail(email) === false &&
        Utility.validateName(firstName) === true &&
        Utility.validateName(lastName) === true
      ) {
        setIsEmailValid(true);
        setIsLastName(false);
        setIsFirstName(false);
      } else if (
        Utility.validateEmail(email) === true &&
        Utility.validateName(firstName) === true &&
        Utility.validateName(lastName) === false
      ) {
        setIsEmailValid(false);
        setIsLastName(true);
        setIsFirstName(false);
      } else if (
        Utility.validateEmail(email) === false &&
        Utility.validateName(firstName) === true &&
        Utility.validateName(lastName) === false
      ) {
        setIsEmailValid(true);
        setIsLastName(true);
        setIsFirstName(false);
      } else {
        SignUp_Api_Method(
          obj,
          (value) => {
            updateUser(value);
          },
          (value) => setShowSpinner(value),
          (value) => {
            setMessage(value);
          },
          (value) => setShowToast(value),
          (value) => {
            if (value === false) {
              setTermsAndConditionPopup(false);
            }
            setSuccess(value);
          },
          () => {
            // props.hideModal();
            setTermsAndConditionPopup(false);
            setOtpShow(true);
            // setTimeout(() => {
            //   props.hideModal();
            //   setTimeout(() => {
            //     window.open("/termscondition");
            //   }, 100);
            // }, 1000);
          },
          addToast
        );
        setIsEmailValid(false);
        setIsLastName(false);
        setIsFirstName(false);
      }
    } else {
      setIsFieldRequired(true);
    }
  };
  const routeToPage = (route) => {
    window.open(route, "_blank");
  };
  const closeToast = () => {
    setShowToast(false);
  };
  const checkEmailExist = (e) => {
    setEmail(e.target.value.trim()?.toLowerCase());
    if (e.target.value?.trim()?.toLowerCase()?.length > 0) {
      Check_email(
        { email: e.target.value },
        (res) => {
          setIsEmailExistError({
            exist: false,
            message: "",
          });
        },
        (err) => {
          setIsEmailExistError({
            exist: true,
            message:
              err?.data?.error?.[lang === "english" ? "message" : "message_sp"],
          });
        }
      );
    }
  };
  const GoogleResponse = (response) => {
    let obj = {
      google_id: response.profileObj.googleId,
      first_name: response.profileObj.givenName,
      last_name: response.profileObj.familyName,
      email: response.profileObj.email,
    };

    Google_Login_Method(
      obj,
      (value) => {
        updateUser(value);
      },
      (value) => setShowSpinner(value),
      (value) => setMessage(value),
      (value) => setShowToast(value),
      (value) => setSuccess(value),
      () => {
        setTimeout(() => {
          props.hideModal();
        }, 1000);
      }
    );
    localStorage.setItem("firstName", response.profileObj.givenName);
    localStorage.setItem("lastName", response.profileObj.familyName);
    localStorage.setItem("email", response.profileObj.email);
    localStorage.removeItem("company");
    localStorage.removeItem("Cif");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("address");
  };

  const FailureResponse = (fail) => {};

  const responseFacebook = (response) => {
    let obj = {
      facebook_id: response.id,
      first_name: response.first_name,
      last_name: response.last_name,
      email: response.email,
    };

    Facebook_Login_Method(
      obj,
      (value) => {
        updateUser(value);
      },
      (value) => setShowSpinner(value),
      (value) => setMessage(value),
      (value) => setShowToast(value),
      (value) => {
        setSuccess(value);
        addToast(value, { appearance: "success" });
      },
      () => {
        setTimeout(() => {
          props.hideModal();
        }, 1000);
      }
    );
    localStorage.setItem("firstName", response.first_name);
    localStorage.setItem("lastName", response.last_name);
    localStorage.setItem("email", response.email);
    localStorage.removeItem("company");
    localStorage.removeItem("Cif");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("address");
  };

  const proceedToNextStepTermsAndCondition = (e) => {
    e.preventDefault();
    let obj = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
    };

    if (
      firstName?.trim()?.length !== 0 &&
      lastName?.trim()?.length !== 0 &&
      email?.trim()?.length !== 0 &&
      password?.trim()?.length !== 0 &&
      !isEmailExistError?.exist
    ) {
      if (
        Utility.validateEmail(email) === true &&
        Utility.validateName(firstName) === false &&
        Utility.validateName(lastName) === false
      ) {
        setIsEmailValid(false);
        setIsLastName(true);
        setIsFirstName(true);
      } else if (
        Utility.validateEmail(email) === false &&
        Utility.validateName(firstName) === false &&
        Utility.validateName(lastName) === true
      ) {
        setIsEmailValid(true);
        setIsLastName(true);
        setIsFirstName(true);
      } else if (
        Utility.validateEmail(email) === true &&
        Utility.validateName(firstName) === false &&
        Utility.validateName(lastName) === true
      ) {
        setIsEmailValid(false);
        setIsLastName(false);
        setIsFirstName(true);
      } else if (
        Utility.validateEmail(email) === false &&
        Utility.validateName(firstName) === true &&
        Utility.validateName(lastName) === true
      ) {
        setIsEmailValid(true);
        setIsLastName(false);
        setIsFirstName(false);
      } else if (
        Utility.validateEmail(email) === true &&
        Utility.validateName(firstName) === true &&
        Utility.validateName(lastName) === false
      ) {
        setIsEmailValid(false);
        setIsLastName(true);
        setIsFirstName(false);
      } else if (
        Utility.validateEmail(email) === false &&
        Utility.validateName(firstName) === true &&
        Utility.validateName(lastName) === false
      ) {
        setIsEmailValid(true);
        setIsLastName(true);
        setIsFirstName(false);
      } else {
        setTermsAndConditionPopup(true);

        // SignUp_Api_Method(
        //   obj,
        //   (value) => {
        //     updateUser(value);
        //   },
        //   (value) => setShowSpinner(value),
        //   (value) => setMessage(value),
        //   (value) => setShowToast(value),
        //   (value) => setSuccess(value),
        //   () => {
        //     // setTimeout(() => {
        //     //   props.hideModal();
        //     //   setTimeout(() => {
        //     //     window.open("/termscondition");
        //     //   }, 100);
        //     // }, 1000);
        //   }
        // );
        // setIsEmailValid(false);
        // setIsLastName(false);
        // setIsFirstName(false);
        // localStorage.setItem("firstName", firstName);
        // localStorage.setItem("lastName", lastName);
        // localStorage.setItem("email", email);
      }
    } else {
      setIsFieldRequired(true);
    }
  };
  const handleChange = (otp) => setOpt(otp);
  const verificationCode = () => {
    if (otp !== "") {
      let obj = {
        email: email,
        verification_code: otp,
      };
      Login_Signup_Verify_Code(
        obj,
        (success) => {
          setShowSpinner(false);
          if (success?.data?.status) {
            // addToast(success?.data?.response?.message, {
            //   appearance: "success",
            //   autoDismiss: 1000,
            // });

            updateUser(success?.data?.response?.detail?.user);
            localStorage.setItem(
              "usertoken",
              success.data.response.detail.token
            );

            localStorage.setItem(
              "firstName",
              success?.data?.response?.detail?.user?.first_name
            );
            localStorage.setItem(
              "lastName",
              success?.data?.response?.detail?.user?.last_name
            );
            localStorage.setItem(
              "emailAddress",
              success?.data?.response?.detail?.user?.email
            );
            // localStorage.setItem("CIF",success?.data?.response?.detail?.user?.CIF);
            localStorage.setItem(
              "phone_number",
              success?.data?.response?.detail?.user?.phone_number
            );
          }
          props.hideModal();
        },
        (error) => {
          setShowSpinner(false);
          // if (error.response?.status === 422) {
          //   addToast(error.response?.data?.error?.message, {
          //     appearance: "error",
          //     autoDismiss: 1000,
          //   });
          // } else if (error.response?.status === 500) {
          //   addToast(error?.response?.data?.error?.message, {
          //     appearance: "error",
          //     autoDismiss: 1000,
          //   });
          // } else {
          //   addToast("Network Error", {
          //     appearance: "error",
          //     autoDismiss: 1000,
          //   });
          // }
        },
        addToast
      );
      // Login_Signup_Verify_Code(
      //   obj,
      //   (value) => {
      //     setSuccess(value);
      //     localStorage.setItem("firstName", firstName);
      //     localStorage.setItem("lastName", lastName);
      //     localStorage.setItem("email", email);
      //     // if (value == true) {
      //     //   setOtpPasswordFieldVisible(true);
      //     //   setOtpShow(!otpShow);

      //     // }
      //     props.hideModal();
      //   },
      //   (value) => {
      //     addToast(value, { appearance: "success", autoDismiss: "1500" });
      //   }
      // );
    } else {
      setIsFieldRequired(true);
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-11 mx-auto text-left mt-1">
        <div className="text-center">
          <div
            className="h-200 w-200 mb-1 close absolute"
            aria-hidden="true"
            type="button"
            data-dismiss="modal"
            onClick={props.hideModal}
          >
            <span>&times;</span>
          </div>
          <img src={Images.circleLogo.default} className="circlelogo" />
        </div>

        {!termsAndConditionPopup && !otpShow ? (
          <Fragment>
            <form onSubmit={createUsers}>
              <div className="row">
                <div className="col-md-12 px-0">
                  <div className="d-flex w-full gap-2">
                    <div className="form-group w-100">
                      <label htmlFor="fullName">{labels.FirstName}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        required=""
                        placeholder={labels.FirstName}
                        value={firstName}
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                      />
                      {fieldIsRequired === true &&
                      firstName?.trim()?.length === 0 ? (
                        <p style={{ color: "red" }}>{labels.validateField}</p>
                      ) : (
                        ""
                        // firstName?.trim()?.length !== 0 &&
                        //   !Utility?.validateName(firstName) && (
                        //     <p style={{ color: "red" }}>Invalid name</p>
                        //   )
                      )}
                      {isFirstName === true && (
                        <p style={{ color: "red" }}>{labels.First_name}</p>
                      )}
                    </div>
                    <div className="form-group w-100">
                      <label htmlFor="fullName">{labels.LastName}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        required
                        placeholder={labels.LastName}
                        value={lastName}
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                      />
                      {fieldIsRequired === true &&
                      lastName?.trim()?.length === 0 ? (
                        <p style={{ color: "red" }}>{labels.validateField}</p>
                      ) : (
                        ""
                        // lastName?.trim()?.length !== 0 &&
                        //   !Utility?.validateName(lastName) && (
                        //     <p style={{ color: "red" }}>Invalid name</p>
                        //   )
                      )}
                      {isLastName === true && (
                        <p style={{ color: "red" }}>{labels.validateField}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="emailAddress">{labels.emailAddress}</label>
                <input
                  type="email"
                  className="form-control"
                  id="emailAddress"
                  required
                  placeholder={labels.emailAddress}
                  value={email}
                  onChange={(e) => checkEmailExist(e)}
                />
                {fieldIsRequired === true && !email.length ? (
                  <p style={{ color: "red" }}>{labels.validateField}</p>
                ) : (
                  ""
                )}
                {isEmailExistError?.exist && (
                  <p style={{ color: "red" }}>{isEmailExistError?.message}</p>
                )}
                {email?.trim()?.length !== 0 &&
                  !Utility?.validateEmail(email) && (
                    <p style={{ color: "red" }}>{labels.emailValidateField}</p>
                  )}
              </div>
              <div className="form-group">
                <label htmlFor="loginPassword">{labels.Password}</label>
                <InputGroup>
                  <FormControl
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="loginPassword"
                    required=""
                    placeholder={labels.Password}
                    value={password}
                    autoComplete="false"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />{" "}
                  <InputGroup.Text>
                    <svg
                      // style={{position:"absolute"}}
                      onMouseDown={() => {
                        setShowPassword(true);
                      }}
                      onMouseUp={() => {
                        setShowPassword(false);
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                  </InputGroup.Text>
                </InputGroup>
                {fieldIsRequired === true && password?.trim()?.length === 0 ? (
                  <p style={{ color: "red" }}>{labels.validateField}</p>
                ) : (
                  ""
                )}
                {password?.length !== 0 &&
                  !Utility?.validatePassword(password) && (
                    <p style={{ color: "red" }}>
                      {labels.passwordValidationText}
                    </p>
                  )}
              </div>
              <div className="form-group d-flex justify-content-center mb-0">
                <button
                  className="btn btn-success btn-primary w-50 my-4"
                  type="submit"
                  onClick={proceedToNextStepTermsAndCondition}
                >
                  {showSpinner === true
                    ? `${labels.loading}...`
                    : labels.continue}
                </button>
              </div>
            </form>
            <p className="text-center  mb-0  custom-font2 mb-25">
              {" "}
              {labels.orSignUpWith}{" "}
            </p>
            <div className=" d-flex justify-content-center ">
              <GoogleLogin
                className="google-btn"
                clientId={
                  "971184058209-6l9ddm7t9cl2s1vqdpgd48s03bjohmrm.apps.googleusercontent.com"
                }
                // isSignedIn={true}

                cookiePolicy={"single_host_origin"}
                onSuccess={(e) => GoogleResponse(e)}
                onFailure={(e) => FailureResponse(e)}
              >
                <img
                  src={Google.default}
                  style={{ width: "40px", height: "40px" }}
                  className="mt-1"
                />
              </GoogleLogin>
              <FacebookLogin
                appId="1215026149009208"
                autoLoad={false}
                cssClass="facebook-btn"
                fields="first_name,last_name,email"
                callback={responseFacebook}
                textButton={
                  <img
                    style={{ width: "40px", height: "40px" }}
                    src={Facebook.default}
                    className="mt-15"
                  />
                }
              />
            </div>
            <p className="text-center mb-3 mt-10 custom-font2">
              {labels.IhAcc}
              <span
                onClick={props.change}
                style={{
                  color: "rgba(70, 188, 255, 1)",
                  marginLeft: "2px",
                  cursor: "pointer",
                }}
              >
                {labels.Login}
              </span>
            </p>
          </Fragment>
        ) : !otpShow ? (
          <Fragment>
            <Row>
              <Col>
                <img
                  onClick={() => setTermsAndConditionPopup(false)}
                  className="cursorPointer"
                  src={Images.backArrow.default}
                />
              </Col>
            </Row>
            <Row className="my-30 ">
              <Col>
                <div className="text-center">
                  <h4>{labels.termsAndConditions?.toUpperCase()}</h4>
                </div>
              </Col>
            </Row>
            <Row className="my-5 ">
              <Col className="sdsdds">
                <div className="text-center">
                  <p className="text-center  custom-font2 text-black">
                    {labels.pleaseAcceptThe}
                    <u
                      className="text-secondary cursorPointer"
                      onClick={() => routeToPage("/termscondition")}
                    >
                      {labels.termsAndConditions}
                    </u>
                    {",  "}
                    <u
                      className="text-secondary cursorPointer"
                      onClick={() => routeToPage("/privacy")}
                    >
                      {labels.privacyAndDataPolicies}
                    </u>{" "}
                    {labels.and}{" "}
                    <u
                      className="text-secondary cursorPointer"
                      onClick={() => routeToPage("/cookies")}
                    >
                      {" "}
                      {labels.cookiesPolicies}
                    </u>{" "}
                    {labels.toContinue}
                  </p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="d-flex justify-content-center  mb-10">
                  <Button
                    className="btn btn-success btn-primary w-50 my-4"
                    type="submit"
                    onClick={createUsers}
                    data-toggle="modal"
                    data-target="#terms"
                    data-dismiss="modal"
                  >
                    {showSpinner === true
                      ? `${labels.loading}...`
                      : labels.accept}
                  </Button>
                </div>
              </Col>
            </Row>
          </Fragment>
        ) : (
          <Fragment>
            <Row className="my-30 ">
              <Col>
                <div className="text-center">
                  <h5>{labels.enterTheCodeReceivedByEmail?.toUpperCase()}</h5>
                  <p>
                    {
                      labels.ifYouDontReceiveItInYourInboxItMayBeInYourSpamFolder
                    }
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="my-5 ">
              <Col className="">
                <div className="d-flex justify-content-center">
                  <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={4}
                    separator={<span>-</span>}
                    inputStyle={{
                      width: "3rem",
                      height: "3rem",
                      margin: "0 1rem",
                      fontSize: "1rem",
                      borderRadius: 10,
                      border: "1px solid rgba(0,0,0,0.3)",
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex justify-content-center  mb-10">
                  <Button
                    className="btn btn-success btn-primary w-50 my-4"
                    type="submit"
                    onClick={verificationCode}
                  >
                    {showSpinner === true
                      ? `${labels.loading}...`
                      : labels.send}
                  </Button>
                </div>
              </Col>
            </Row>
          </Fragment>
        )}
      </div>
      {showToast === true ? (
        <ToastComponent
          message={message}
          toastClose={closeToast}
          success={success}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default User_Registration;
