export const CountryCodes = [
  {
    code: 'PL',
    unicode: 'U+1F1F5 U+1F1F1',
    name: 'Poland',
    emoji: '🇵🇱',
    phoneCode: '+48',
    nationality: 'Polish',
  },
  {
    code: 'US',
    unicode: 'U+1F1FA U+1F1F8',
    name: 'United States',
    emoji: '🇺🇸',
    phoneCode: '+1',
    nationality: 'American',
  },
  {
    code: 'CA',
    unicode: 'U+1F1E8 U+1F1E6',
    name: 'Canada',
    emoji: '🇨🇦',
    phoneCode: '+1',
    nationality: 'Canadian',
  },
  {
    code: 'PK',
    unicode: 'U+1F1F5 U+1F1F0',
    name: 'Pakistan',
    emoji: '🇵🇰',
    phoneCode: '+92',
    nationality: 'Pakistani',
  },
  {
    code: 'AC',
    unicode: 'U+1F1E6 U+1F1E8',
    name: 'Ascension Island',
    emoji: '🇦🇨',
    phoneCode: '+247',
    nationality: '',
  },
  {
    code: 'AD',
    unicode: 'U+1F1E6 U+1F1E9',
    name: 'Andorra',
    emoji: '🇦🇩',
    phoneCode: '+376',
    nationality: 'Andorran',
  },
  {
    code: 'AE',
    unicode: 'U+1F1E6 U+1F1EA',
    name: 'United Arab Emirates',
    emoji: '🇦🇪',
    phoneCode: '+971',
    nationality: 'Emirati, Emirian, Emiri',
  },
  {
    code: 'AF',
    unicode: 'U+1F1E6 U+1F1EB',
    name: 'Afghanistan',
    emoji: '🇦🇫',
    phoneCode: '+93',
    nationality: 'Afghan',
  },
  {
    code: 'AG',
    unicode: 'U+1F1E6 U+1F1EC',
    name: 'Antigua & Barbuda',
    emoji: '🇦🇬',
    phoneCode: '+1268',
    nationality: '',
  },
  {
    code: 'AI',
    unicode: 'U+1F1E6 U+1F1EE',
    name: 'Anguilla',
    emoji: '🇦🇮',
    phoneCode: '+1-264',
    nationality: 'Anguillan',
  },
  {
    code: 'AL',
    unicode: 'U+1F1E6 U+1F1F1',
    name: 'Albania',
    emoji: '🇦🇱',
    phoneCode: '+355',
    nationality: 'Albanian',
  },
  {
    code: 'AM',
    unicode: 'U+1F1E6 U+1F1F2',
    name: 'Armenia',
    emoji: '🇦🇲',
    phoneCode: '+374',
    nationality: 'Armenian',
  },
  {
    code: 'AO',
    unicode: 'U+1F1E6 U+1F1F4',
    name: 'Angola',
    emoji: '🇦🇴',
    phoneCode: '+244',
    nationality: 'Angolan',
  },
  {
    code: 'AR',
    unicode: 'U+1F1E6 U+1F1F7',
    name: 'Argentina',
    emoji: '🇦🇷',
    phoneCode: '+54',
    nationality: 'Argentine',
  },
  {
    code: 'AS',
    unicode: 'U+1F1E6 U+1F1F8',
    name: 'American Samoa',
    emoji: '🇦🇸',
    phoneCode: '+1-684',
    nationality: 'American Samoan',
  },
  {
    code: 'AT',
    unicode: 'U+1F1E6 U+1F1F9',
    name: 'Austria',
    emoji: '🇦🇹',
    phoneCode: '+43',
    nationality: 'Austrian',
  },
  {
    code: 'AU',
    unicode: 'U+1F1E6 U+1F1FA',
    name: 'Australia',
    emoji: '🇦🇺',
    phoneCode: '+61',
    nationality: 'Australian',
  },
  {
    code: 'AW',
    unicode: 'U+1F1E6 U+1F1FC',
    name: 'Aruba',
    emoji: '🇦🇼',
    phoneCode: '+297',
    nationality: 'Aruban',
  },
  {
    code: 'AX',
    unicode: 'U+1F1E6 U+1F1FD',
    name: 'Åland Islands',
    emoji: '🇦🇽',
    phoneCode: '+358',
    nationality: 'Åland Island',
  },
  {
    code: 'AZ',
    unicode: 'U+1F1E6 U+1F1FF',
    name: 'Azerbaijan',
    emoji: '🇦🇿',
    phoneCode: '+994',
    nationality: 'Azerbaijani, Azeri',
  },
  {
    code: 'BA',
    unicode: 'U+1F1E7 U+1F1E6',
    name: 'Bosnia & Herzegovina',
    emoji: '🇧🇦',
    phoneCode: '+387',
    nationality: '',
  },
  {
    code: 'BB',
    unicode: 'U+1F1E7 U+1F1E7',
    name: 'Barbados',
    emoji: '🇧🇧',
    phoneCode: '+1-246',
    nationality: 'Barbadian',
  },
  {
    code: 'BD',
    unicode: 'U+1F1E7 U+1F1E9',
    name: 'Bangladesh',
    emoji: '🇧🇩',
    phoneCode: '+880',
    nationality: 'Bangladeshi',
  },
  {
    code: 'BE',
    unicode: 'U+1F1E7 U+1F1EA',
    name: 'Belgium',
    emoji: '🇧🇪',
    phoneCode: '+32',
    nationality: 'Belgian',
  },
  {
    code: 'BF',
    unicode: 'U+1F1E7 U+1F1EB',
    name: 'Burkina Faso',
    emoji: '🇧🇫',
    phoneCode: '+226',
    nationality: 'Burkinabé',
  },
  {
    code: 'BG',
    unicode: 'U+1F1E7 U+1F1EC',
    name: 'Bulgaria',
    emoji: '🇧🇬',
    phoneCode: '+359',
    nationality: 'Bulgarian',
  },
  {
    code: 'BH',
    unicode: 'U+1F1E7 U+1F1ED',
    name: 'Bahrain',
    emoji: '🇧🇭',
    phoneCode: '+973',
    nationality: 'Bahraini',
  },
  {
    code: 'BI',
    unicode: 'U+1F1E7 U+1F1EE',
    name: 'Burundi',
    emoji: '🇧🇮',
    phoneCode: '+257',
    nationality: 'Burundian',
  },
  {
    code: 'BJ',
    unicode: 'U+1F1E7 U+1F1EF',
    name: 'Benin',
    emoji: '🇧🇯',
    phoneCode: '+229',
    nationality: 'Beninese, Beninois',
  },
  {
    code: 'BL',
    unicode: 'U+1F1E7 U+1F1F1',
    name: 'St. Barthélemy',
    emoji: '🇧🇱',
    phoneCode: '+590',
    nationality: '',
  },
  {
    code: 'BM',
    unicode: 'U+1F1E7 U+1F1F2',
    name: 'Bermuda',
    emoji: '🇧🇲',
    phoneCode: '+1-441',
    nationality: 'Bermudian, Bermudan',
  },
  {
    code: 'BN',
    unicode: 'U+1F1E7 U+1F1F3',
    name: 'Brunei',
    emoji: '🇧🇳',
    phoneCode: '+673',
    nationality: 'Bruneian',
  },
  {
    code: 'BO',
    unicode: 'U+1F1E7 U+1F1F4',
    name: 'Bolivia',
    emoji: '🇧🇴',
    phoneCode: '+591',
    nationality: 'Bolivian',
  },
  {
    code: 'BR',
    unicode: 'U+1F1E7 U+1F1F7',
    name: 'Brazil',
    emoji: '🇧🇷',
    phoneCode: '+55',
    nationality: 'Brazilian',
  },
  {
    code: 'BS',
    unicode: 'U+1F1E7 U+1F1F8',
    name: 'Bahamas',
    emoji: '🇧🇸',
    phoneCode: '+1-242',
    nationality: 'Bahamian',
  },
  {
    code: 'BT',
    unicode: 'U+1F1E7 U+1F1F9',
    name: 'Bhutan',
    emoji: '🇧🇹',
    phoneCode: '+975',
    nationality: 'Bhutanese',
  },
  {
    code: 'BV',
    unicode: 'U+1F1E7 U+1F1FB',
    name: 'Bouvet Island',
    emoji: '🇧🇻',
    phoneCode: '+55',
    nationality: 'Bouvet Island',
  },
  {
    code: 'BW',
    unicode: 'U+1F1E7 U+1F1FC',
    name: 'Botswana',
    emoji: '🇧🇼',
    phoneCode: '+267',
    nationality: 'Motswana, Botswanan',
  },
  {
    code: 'BY',
    unicode: 'U+1F1E7 U+1F1FE',
    name: 'Belarus',
    emoji: '🇧🇾',
    phoneCode: '+375',
    nationality: 'Belarusian',
  },
  {
    code: 'BZ',
    unicode: 'U+1F1E7 U+1F1FF',
    name: 'Belize',
    emoji: '🇧🇿',
    phoneCode: '+501',
    nationality: 'Belizean',
  },
  {
    code: 'CC',
    unicode: 'U+1F1E8 U+1F1E8',
    name: 'Cocos (Keeling) Islands',
    emoji: '🇨🇨',
    phoneCode: '+61891',
    nationality: 'Cocos Island',
  },
  {
    code: 'CD',
    unicode: 'U+1F1E8 U+1F1E9',
    name: 'Congo - Kinshasa',
    emoji: '🇨🇩',
    phoneCode: '+243',
    nationality: '',
  },
  {
    code: 'CF',
    unicode: 'U+1F1E8 U+1F1EB',
    name: 'Central African Republic',
    emoji: '🇨🇫',
    phoneCode: '+236',
    nationality: 'Central African',
  },
  {
    code: 'CG',
    unicode: 'U+1F1E8 U+1F1EC',
    name: 'Congo - Brazzaville',
    emoji: '🇨🇬',
    phoneCode: '+242',
    nationality: '',
  },
  {
    code: 'CH',
    unicode: 'U+1F1E8 U+1F1ED',
    name: 'Switzerland',
    emoji: '🇨🇭',
    phoneCode: '+41',
    nationality: 'Swiss',
  },
  {
    code: 'CI',
    unicode: 'U+1F1E8 U+1F1EE',
    name: 'Côte d’Ivoire',
    emoji: '🇨🇮',
    phoneCode: '+225',
    nationality: '',
  },
  {
    code: 'CK',
    unicode: 'U+1F1E8 U+1F1F0',
    name: 'Cook Islands',
    emoji: '🇨🇰',
    phoneCode: '+682',
    nationality: 'Cook Island',
  },
  {
    code: 'CL',
    unicode: 'U+1F1E8 U+1F1F1',
    name: 'Chile',
    emoji: '🇨🇱',
    phoneCode: '+56',
    nationality: 'Chilean',
  },
  {
    code: 'CM',
    unicode: 'U+1F1E8 U+1F1F2',
    name: 'Cameroon',
    emoji: '🇨🇲',
    phoneCode: '+237',
    nationality: 'Cameroonian',
  },
  {
    code: 'CN',
    unicode: 'U+1F1E8 U+1F1F3',
    name: 'China',
    emoji: '🇨🇳',
    phoneCode: '+86',
    nationality: 'Chinese',
  },
  {
    code: 'CO',
    unicode: 'U+1F1E8 U+1F1F4',
    name: 'Colombia',
    emoji: '🇨🇴',
    phoneCode: '+57',
    nationality: 'Colombian',
  },
  {
    code: 'CR',
    unicode: 'U+1F1E8 U+1F1F7',
    name: 'Costa Rica',
    emoji: '🇨🇷',
    phoneCode: '+506',
    nationality: 'Costa Rican',
  },
  {
    code: 'CU',
    unicode: 'U+1F1E8 U+1F1FA',
    name: 'Cuba',
    emoji: '🇨🇺',
    phoneCode: '+53',
    nationality: 'Cuban',
  },
  {
    code: 'CV',
    unicode: 'U+1F1E8 U+1F1FB',
    name: 'Cape Verde',
    emoji: '🇨🇻',
    phoneCode: '+238',
    nationality: '',
  },
  {
    code: 'CW',
    unicode: 'U+1F1E8 U+1F1FC',
    name: 'Curaçao',
    emoji: '🇨🇼',
    phoneCode: '+599',
    nationality: 'Curaçaoan',
  },
  {
    code: 'CX',
    unicode: 'U+1F1E8 U+1F1FD',
    name: 'Christmas Island',
    emoji: '🇨🇽',
    phoneCode: '+61',
    nationality: 'Christmas Island',
  },
  {
    code: 'CY',
    unicode: 'U+1F1E8 U+1F1FE',
    name: 'Cyprus',
    emoji: '🇨🇾',
    phoneCode: '+357',
    nationality: 'Cypriot',
  },
  {
    code: 'CZ',
    unicode: 'U+1F1E8 U+1F1FF',
    name: 'Czechia',
    emoji: '🇨🇿',
    phoneCode: '+420',
    nationality: '',
  },
  {
    code: 'DE',
    unicode: 'U+1F1E9 U+1F1EA',
    name: 'Germany',
    emoji: '🇩🇪',
    phoneCode: '+49',
    nationality: 'German',
  },
  {
    code: 'DJ',
    unicode: 'U+1F1E9 U+1F1EF',
    name: 'Djibouti',
    emoji: '🇩🇯',
    phoneCode: '+253',
    nationality: 'Djiboutian',
  },
  {
    code: 'DK',
    unicode: 'U+1F1E9 U+1F1F0',
    name: 'Denmark',
    emoji: '🇩🇰',
    phoneCode: '+45',
    nationality: 'Danish',
  },
  {
    code: 'DM',
    unicode: 'U+1F1E9 U+1F1F2',
    name: 'Dominica',
    emoji: '🇩🇲',
    phoneCode: '+1-767',
    nationality: 'Dominican',
  },
  {
    code: 'DO',
    unicode: 'U+1F1E9 U+1F1F4',
    name: 'Dominican Republic',
    emoji: '🇩🇴',
    phoneCode: '+1-809',
    nationality: 'Dominican',
  },
  {
    code: 'DZ',
    unicode: 'U+1F1E9 U+1F1FF',
    name: 'Algeria',
    emoji: '🇩🇿',
    phoneCode: '+213',
    nationality: 'Algerian',
  },
  {
    code: 'EC',
    unicode: 'U+1F1EA U+1F1E8',
    name: 'Ecuador',
    emoji: '🇪🇨',
    phoneCode: '+593',
    nationality: 'Ecuadorian',
  },
  {
    code: 'EE',
    unicode: 'U+1F1EA U+1F1EA',
    name: 'Estonia',
    emoji: '🇪🇪',
    phoneCode: '+372',
    nationality: 'Estonian',
  },
  {
    code: 'EG',
    unicode: 'U+1F1EA U+1F1EC',
    name: 'Egypt',
    emoji: '🇪🇬',
    phoneCode: '+20',
    nationality: 'Egyptian',
  },
  {
    code: 'ER',
    unicode: 'U+1F1EA U+1F1F7',
    name: 'Eritrea',
    emoji: '🇪🇷',
    phoneCode: '+291',
    nationality: 'Eritrean',
  },
  {
    code: 'ES',
    unicode: 'U+1F1EA U+1F1F8',
    name: 'Spain',
    emoji: '🇪🇸',
    phoneCode: '+34',
    nationality: 'Spanish',
  },
  {
    code: 'ET',
    unicode: 'U+1F1EA U+1F1F9',
    name: 'Ethiopia',
    emoji: '🇪🇹',
    phoneCode: '+251',
    nationality: 'Ethiopian',
  },
  {
    code: 'EU',
    unicode: 'U+1F1EA U+1F1FA',
    name: 'European Union',
    emoji: '🇪🇺',
    phoneCode: '+388',
    nationality: '',
  },
  {
    code: 'FI',
    unicode: 'U+1F1EB U+1F1EE',
    name: 'Finland',
    emoji: '🇫🇮',
    phoneCode: '+358',
    nationality: 'Finnish',
  },
  {
    code: 'FJ',
    unicode: 'U+1F1EB U+1F1EF',
    name: 'Fiji',
    emoji: '🇫🇯',
    phoneCode: '+679',
    nationality: 'Fijian',
  },
  {
    code: 'FK',
    unicode: 'U+1F1EB U+1F1F0',
    name: 'Falkland Islands',
    emoji: '🇫🇰',
    phoneCode: '+500',
    nationality: 'Falkland Island',
  },
  {
    code: 'FM',
    unicode: 'U+1F1EB U+1F1F2',
    name: 'Micronesia',
    emoji: '🇫🇲',
    phoneCode: '+691',
    nationality: 'Micronesian',
  },
  {
    code: 'FO',
    unicode: 'U+1F1EB U+1F1F4',
    name: 'Faroe Islands',
    emoji: '🇫🇴',
    phoneCode: '+298',
    nationality: 'Faroese',
  },
  {
    code: 'FR',
    unicode: 'U+1F1EB U+1F1F7',
    name: 'France',
    emoji: '🇫🇷',
    phoneCode: '+33',
    nationality: 'French',
  },
  {
    code: 'GA',
    unicode: 'U+1F1EC U+1F1E6',
    name: 'Gabon',
    emoji: '🇬🇦',
    phoneCode: '+241',
    nationality: 'Gabonese',
  },
  {
    code: 'GB',
    unicode: 'U+1F1EC U+1F1E7',
    name: 'United Kingdom',
    emoji: '🇬🇧',
    phoneCode: '+44',
    nationality: 'British, UK',
  },
  {
    code: 'GD',
    unicode: 'U+1F1EC U+1F1E9',
    name: 'Grenada',
    emoji: '🇬🇩',
    phoneCode: ' +1-473',
    nationality: 'Grenadian',
  },
  {
    code: 'GE',
    unicode: 'U+1F1EC U+1F1EA',
    name: 'Georgia',
    emoji: '🇬🇪',
    phoneCode: '+995',
    nationality: 'Georgian',
  },
  {
    code: 'GF',
    unicode: 'U+1F1EC U+1F1EB',
    name: 'French Guiana',
    emoji: '🇬🇫',
    phoneCode: '+594',
    nationality: 'French Guianese',
  },
  {
    code: 'GG',
    unicode: 'U+1F1EC U+1F1EC',
    name: 'Guernsey',
    emoji: '🇬🇬',
    phoneCode: '+44',
    nationality: 'Channel Island',
  },
  {
    code: 'GH',
    unicode: 'U+1F1EC U+1F1ED',
    name: 'Ghana',
    emoji: '🇬🇭',
    phoneCode: '+233',
    nationality: 'Ghanaian',
  },
  {
    code: 'GI',
    unicode: 'U+1F1EC U+1F1EE',
    name: 'Gibraltar',
    emoji: '🇬🇮',
    phoneCode: '+350',
    nationality: 'Gibraltar',
  },
  {
    code: 'GL',
    unicode: 'U+1F1EC U+1F1F1',
    name: 'Greenland',
    emoji: '🇬🇱',
    phoneCode: '+299',
    nationality: 'Greenlandic',
  },
  {
    code: 'GM',
    unicode: 'U+1F1EC U+1F1F2',
    name: 'Gambia',
    emoji: '🇬🇲',
    phoneCode: '+220',
    nationality: 'Gambian',
  },
  {
    code: 'GN',
    unicode: 'U+1F1EC U+1F1F3',
    name: 'Guinea',
    emoji: '🇬🇳',
    phoneCode: '+224',
    nationality: 'Equatorial Guinean, Equatoguinean',
  },
  {
    code: 'GP',
    unicode: 'U+1F1EC U+1F1F5',
    name: 'Guadeloupe',
    emoji: '🇬🇵',
    phoneCode: '+590',
    nationality: 'Guadeloupe',
  },
  {
    code: 'GQ',
    unicode: 'U+1F1EC U+1F1F6',
    name: 'Equatorial Guinea',
    emoji: '🇬🇶',
    phoneCode: '+240',
    nationality: 'Equatorial Guinean, Equatoguinean',
  },
  {
    code: 'GR',
    unicode: 'U+1F1EC U+1F1F7',
    name: 'Greece',
    emoji: '🇬🇷',
    phoneCode: '+30',
    nationality: 'Greek, Hellenic',
  },
  {
    code: 'GS',
    unicode: 'U+1F1EC U+1F1F8',
    name: 'South Georgia & South Sandwich Islands',
    emoji: '🇬🇸',
    phoneCode: '+500',
    nationality: '',
  },
  {
    code: 'GT',
    unicode: 'U+1F1EC U+1F1F9',
    name: 'Guatemala',
    emoji: '🇬🇹',
    phoneCode: '+502',
    nationality: 'Guatemalan',
  },
  {
    code: 'GU',
    unicode: 'U+1F1EC U+1F1FA',
    name: 'Guam',
    emoji: '🇬🇺',
    phoneCode: '+1-671',
    nationality: 'Guamanian, Guambat',
  },
  {
    code: 'GW',
    unicode: 'U+1F1EC U+1F1FC',
    name: 'Guinea-Bissau',
    emoji: '🇬🇼',
    phoneCode: '+245',
    nationality: 'Bissau-Guinean',
  },
  {
    code: 'GY',
    unicode: 'U+1F1EC U+1F1FE',
    name: 'Guyana',
    emoji: '🇬🇾',
    phoneCode: '+592',
    nationality: 'Guyanese',
  },
  {
    code: 'HK',
    unicode: 'U+1F1ED U+1F1F0',
    name: 'Hong Kong SAR China',
    emoji: '🇭🇰',
    phoneCode: '+852',
    nationality: '',
  },
  {
    code: 'HM',
    unicode: 'U+1F1ED U+1F1F2',
    name: 'Heard & McDonald Islands',
    emoji: '🇭🇲',
    phoneCode: '+6723',
    nationality: '',
  },
  {
    code: 'HN',
    unicode: 'U+1F1ED U+1F1F3',
    name: 'Honduras',
    emoji: '🇭🇳',
    phoneCode: '+504',
    nationality: 'Honduran',
  },
  {
    code: 'HR',
    unicode: 'U+1F1ED U+1F1F7',
    name: 'Croatia',
    emoji: '🇭🇷',
    phoneCode: '+385',
    nationality: 'Croatian',
  },
  {
    code: 'HT',
    unicode: 'U+1F1ED U+1F1F9',
    name: 'Haiti',
    emoji: '🇭🇹',
    phoneCode: '+509',
    nationality: 'Haitian',
  },
  {
    code: 'HU',
    unicode: 'U+1F1ED U+1F1FA',
    name: 'Hungary',
    emoji: '🇭🇺',
    phoneCode: '+36',
    nationality: 'Hungarian, Magyar',
  },
  {
    code: 'ID',
    unicode: 'U+1F1EE U+1F1E9',
    name: 'Indonesia',
    emoji: '🇮🇩',
    phoneCode: '+62',
    nationality: 'Indonesian',
  },
  {
    code: 'IE',
    unicode: 'U+1F1EE U+1F1EA',
    name: 'Ireland',
    emoji: '🇮🇪',
    phoneCode: '+353',
    nationality: 'Irish',
  },
  {
    code: 'IL',
    unicode: 'U+1F1EE U+1F1F1',
    name: 'Israel',
    emoji: '🇮🇱',
    phoneCode: '+972',
    nationality: 'Israeli',
  },
  {
    code: 'IM',
    unicode: 'U+1F1EE U+1F1F2',
    name: 'Isle of Man',
    emoji: '🇮🇲',
    phoneCode: '+44',
    nationality: 'Manx',
  },
  {
    code: 'IN',
    unicode: 'U+1F1EE U+1F1F3',
    name: 'India',
    emoji: '🇮🇳',
    phoneCode: '+91',
    nationality: 'Indian',
  },
  {
    code: 'IO',
    unicode: 'U+1F1EE U+1F1F4',
    name: 'British Indian Ocean Territory',
    emoji: '🇮🇴',
    phoneCode: '+246',
    nationality: 'BIOT',
  },
  {
    code: 'IQ',
    unicode: 'U+1F1EE U+1F1F6',
    name: 'Iraq',
    emoji: '🇮🇶',
    phoneCode: '+964',
    nationality: 'Iraqi',
  },
  {
    code: 'IR',
    unicode: 'U+1F1EE U+1F1F7',
    name: 'Iran',
    emoji: '🇮🇷',
    phoneCode: '+98',
    nationality: 'Iranian, Persian',
  },
  {
    code: 'IS',
    unicode: 'U+1F1EE U+1F1F8',
    name: 'Iceland',
    emoji: '🇮🇸',
    phoneCode: '+354',
    nationality: 'Icelandic',
  },
  {
    code: 'IT',
    unicode: 'U+1F1EE U+1F1F9',
    name: 'Italy',
    emoji: '🇮🇹',
    phoneCode: '+39',
    nationality: 'Italian',
  },
  {
    code: 'JE',
    unicode: 'U+1F1EF U+1F1EA',
    name: 'Jersey',
    emoji: '🇯🇪',
    phoneCode: '+44',
    nationality: 'Channel Island',
  },
  {
    code: 'JM',
    unicode: 'U+1F1EF U+1F1F2',
    name: 'Jamaica',
    emoji: '🇯🇲',
    phoneCode: '+1-876',
    nationality: 'Jamaican',
  },
  {
    code: 'JO',
    unicode: 'U+1F1EF U+1F1F4',
    name: 'Jordan',
    emoji: '🇯🇴',
    phoneCode: '+962',
    nationality: 'Jordanian',
  },
  {
    code: 'JP',
    unicode: 'U+1F1EF U+1F1F5',
    name: 'Japan',
    emoji: '🇯🇵',
    phoneCode: '+81',
    nationality: 'Japanese',
  },
  {
    code: 'KE',
    unicode: 'U+1F1F0 U+1F1EA',
    name: 'Kenya',
    emoji: '🇰🇪',
    phoneCode: '+254',
    nationality: 'Kenyan',
  },
  {
    code: 'KG',
    unicode: 'U+1F1F0 U+1F1EC',
    name: 'Kyrgyzstan',
    emoji: '🇰🇬',
    phoneCode: '+996',
    nationality: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
  },
  {
    code: 'KH',
    unicode: 'U+1F1F0 U+1F1ED',
    name: 'Cambodia',
    emoji: '🇰🇭',
    phoneCode: '+855',
    nationality: 'Cambodian',
  },
  {
    code: 'KI',
    unicode: 'U+1F1F0 U+1F1EE',
    name: 'Kiribati',
    emoji: '🇰🇮',
    phoneCode: '+686',
    nationality: 'I-Kiribati',
  },
  {
    code: 'KM',
    unicode: 'U+1F1F0 U+1F1F2',
    name: 'Comoros',
    emoji: '🇰🇲',
    phoneCode: '+269',
    nationality: 'Comoran, Comorian',
  },
  {
    code: 'KN',
    unicode: 'U+1F1F0 U+1F1F3',
    name: 'St. Kitts & Nevis',
    emoji: '🇰🇳',
    phoneCode: '+1869',
    nationality: '',
  },
  {
    code: 'KP',
    unicode: 'U+1F1F0 U+1F1F5',
    name: 'North Korea',
    emoji: '🇰🇵',
    phoneCode: '+850',
    nationality: '',
  },
  {
    code: 'KR',
    unicode: 'U+1F1F0 U+1F1F7',
    name: 'South Korea',
    emoji: '🇰🇷',
    phoneCode: '+82',
    nationality: '',
  },
  {
    code: 'KW',
    unicode: 'U+1F1F0 U+1F1FC',
    name: 'Kuwait',
    emoji: '🇰🇼',
    phoneCode: '+965',
    nationality: 'Kuwaiti',
  },
  {
    code: 'KY',
    unicode: 'U+1F1F0 U+1F1FE',
    name: 'Cayman Islands',
    emoji: '🇰🇾',
    phoneCode: '+1-345',
    nationality: 'Caymanian',
  },
  {
    code: 'KZ',
    unicode: 'U+1F1F0 U+1F1FF',
    name: 'Kazakhstan',
    emoji: '🇰🇿',
    phoneCode: '+7',
    nationality: 'Kazakhstani, Kazakh',
  },
  {
    code: 'LA',
    unicode: 'U+1F1F1 U+1F1E6',
    name: 'Laos',
    emoji: '🇱🇦',
    phoneCode: '+856',
    nationality: '',
  },
  {
    code: 'LB',
    unicode: 'U+1F1F1 U+1F1E7',
    name: 'Lebanon',
    emoji: '🇱🇧',
    phoneCode: '+961',
    nationality: 'Lebanese',
  },
  {
    code: 'LC',
    unicode: 'U+1F1F1 U+1F1E8',
    name: 'St. Lucia',
    emoji: '🇱🇨',
    phoneCode: '+1758',
    nationality: '',
  },
  {
    code: 'LI',
    unicode: 'U+1F1F1 U+1F1EE',
    name: 'Liechtenstein',
    emoji: '🇱🇮',
    phoneCode: '+423',
    nationality: 'Liechtenstein',
  },
  {
    code: 'LK',
    unicode: 'U+1F1F1 U+1F1F0',
    name: 'Sri Lanka',
    emoji: '🇱🇰',
    phoneCode: '+94',
    nationality: 'Sri Lankan',
  },
  {
    code: 'LR',
    unicode: 'U+1F1F1 U+1F1F7',
    name: 'Liberia',
    emoji: '🇱🇷',
    phoneCode: '+231',
    nationality: 'Liberian',
  },
  {
    code: 'LS',
    unicode: 'U+1F1F1 U+1F1F8',
    name: 'Lesotho',
    emoji: '🇱🇸',
    phoneCode: '+266',
    nationality: 'Basotho',
  },
  {
    code: 'LT',
    unicode: 'U+1F1F1 U+1F1F9',
    name: 'Lithuania',
    emoji: '🇱🇹',
    phoneCode: '+370',
    nationality: 'Lithuanian',
  },
  {
    code: 'LU',
    unicode: 'U+1F1F1 U+1F1FA',
    name: 'Luxembourg',
    emoji: '🇱🇺',
    phoneCode: '+352',
    nationality: 'Luxembourg, Luxembourgish',
  },
  {
    code: 'LV',
    unicode: 'U+1F1F1 U+1F1FB',
    name: 'Latvia',
    emoji: '🇱🇻',
    phoneCode: '+371',
    nationality: 'Latvian',
  },
  {
    code: 'LY',
    unicode: 'U+1F1F1 U+1F1FE',
    name: 'Libya',
    emoji: '🇱🇾',
    phoneCode: '+218',
    nationality: 'Libyan',
  },
  {
    code: 'MA',
    unicode: 'U+1F1F2 U+1F1E6',
    name: 'Morocco',
    emoji: '🇲🇦',
    phoneCode: '+212',
    nationality: 'Moroccan',
  },
  {
    code: 'MC',
    unicode: 'U+1F1F2 U+1F1E8',
    name: 'Monaco',
    emoji: '🇲🇨',
    phoneCode: '+377',
    nationality: 'Monégasque, Monacan',
  },
  {
    code: 'MD',
    unicode: 'U+1F1F2 U+1F1E9',
    name: 'Moldova',
    emoji: '🇲🇩',
    phoneCode: '+373',
    nationality: 'Moldovan',
  },
  {
    code: 'ME',
    unicode: 'U+1F1F2 U+1F1EA',
    name: 'Montenegro',
    emoji: '🇲🇪',
    phoneCode: '+382',
    nationality: 'Montenegrin',
  },
  {
    code: 'MF',
    unicode: 'U+1F1F2 U+1F1EB',
    name: 'St. Martin',
    emoji: '🇲🇫',
    phoneCode: '+590',
    nationality: '',
  },
  {
    code: 'MG',
    unicode: 'U+1F1F2 U+1F1EC',
    name: 'Madagascar',
    emoji: '🇲🇬',
    phoneCode: '+261',
    nationality: 'Malagasy',
  },
  {
    code: 'MH',
    unicode: 'U+1F1F2 U+1F1ED',
    name: 'Marshall Islands',
    emoji: '🇲🇭',
    phoneCode: '+692',
    nationality: 'Marshallese',
  },
  {
    code: 'MK',
    unicode: 'U+1F1F2 U+1F1F0',
    name: 'Macedonia',
    emoji: '🇲🇰',
    phoneCode: '+389',
    nationality: 'Macedonian',
  },
  {
    code: 'ML',
    unicode: 'U+1F1F2 U+1F1F1',
    name: 'Mali',
    emoji: '🇲🇱',
    phoneCode: '+223',
    nationality: 'Malian, Malinese',
  },
  {
    code: 'MM',
    unicode: 'U+1F1F2 U+1F1F2',
    name: 'Myanmar (Burma)',
    emoji: '🇲🇲',
    phoneCode: '+95',
    nationality: '',
  },
  {
    code: 'MN',
    unicode: 'U+1F1F2 U+1F1F3',
    name: 'Mongolia',
    emoji: '🇲🇳',
    phoneCode: '+976',
    nationality: 'Mongolian',
  },
  {
    code: 'MO',
    unicode: 'U+1F1F2 U+1F1F4',
    name: 'Macau SAR China',
    emoji: '🇲🇴',
    phoneCode: '+853',
    nationality: '',
  },
  {
    code: 'MP',
    unicode: 'U+1F1F2 U+1F1F5',
    name: 'Northern Mariana Islands',
    emoji: '🇲🇵',
    phoneCode: '+1-670',
    nationality: 'Northern Marianan',
  },
  {
    code: 'MQ',
    unicode: 'U+1F1F2 U+1F1F6',
    name: 'Martinique',
    emoji: '🇲🇶',
    phoneCode: '+596',
    nationality: 'Martiniquais, Martinican',
  },
  {
    code: 'MR',
    unicode: 'U+1F1F2 U+1F1F7',
    name: 'Mauritania',
    emoji: '🇲🇷',
    phoneCode: '+222',
    nationality: 'Mauritanian',
  },
  {
    code: 'MS',
    unicode: 'U+1F1F2 U+1F1F8',
    name: 'Montserrat',
    emoji: '🇲🇸',
    phoneCode: '+1-664',
    nationality: 'Montserratian',
  },
  {
    code: 'MT',
    unicode: 'U+1F1F2 U+1F1F9',
    name: 'Malta',
    emoji: '🇲🇹',
    phoneCode: '+356',
    nationality: 'Maltese',
  },
  {
    code: 'MU',
    unicode: 'U+1F1F2 U+1F1FA',
    name: 'Mauritius',
    emoji: '🇲🇺',
    phoneCode: '+230',
    nationality: 'Mauritian',
  },
  {
    code: 'MV',
    unicode: 'U+1F1F2 U+1F1FB',
    name: 'Maldives',
    emoji: '🇲🇻',
    phoneCode: '+960',
    nationality: 'Maldivian',
  },
  {
    code: 'MW',
    unicode: 'U+1F1F2 U+1F1FC',
    name: 'Malawi',
    emoji: '🇲🇼',
    phoneCode: '+265',
    nationality: 'Malawian',
  },
  {
    code: 'MX',
    unicode: 'U+1F1F2 U+1F1FD',
    name: 'Mexico',
    emoji: '🇲🇽',
    phoneCode: '+52',
    nationality: 'Mexican',
  },
  {
    code: 'MY',
    unicode: 'U+1F1F2 U+1F1FE',
    name: 'Malaysia',
    emoji: '🇲🇾',
    phoneCode: '+60',
    nationality: 'Malaysian',
  },
  {
    code: 'MZ',
    unicode: 'U+1F1F2 U+1F1FF',
    name: 'Mozambique',
    emoji: '🇲🇿',
    phoneCode: '+258',
    nationality: 'Mozambican',
  },
  {
    code: 'NA',
    unicode: 'U+1F1F3 U+1F1E6',
    name: 'Namibia',
    emoji: '🇳🇦',
    phoneCode: '+264',
    nationality: 'Namibian',
  },
  {
    code: 'NC',
    unicode: 'U+1F1F3 U+1F1E8',
    name: 'New Caledonia',
    emoji: '🇳🇨',
    phoneCode: '+687',
    nationality: 'New Caledonian',
  },
  {
    code: 'NE',
    unicode: 'U+1F1F3 U+1F1EA',
    name: 'Niger',
    emoji: '🇳🇪',
    phoneCode: '+227',
    nationality: 'Nigerien',
  },
  {
    code: 'NF',
    unicode: 'U+1F1F3 U+1F1EB',
    name: 'Norfolk Island',
    emoji: '🇳🇫',
    phoneCode: '+672',
    nationality: 'Norfolk Island',
  },
  {
    code: 'NG',
    unicode: 'U+1F1F3 U+1F1EC',
    name: 'Nigeria',
    emoji: '🇳🇬',
    phoneCode: '+234',
    nationality: 'Nigerian',
  },
  {
    code: 'NI',
    unicode: 'U+1F1F3 U+1F1EE',
    name: 'Nicaragua',
    emoji: '🇳🇮',
    phoneCode: '+505',
    nationality: 'Nicaraguan',
  },
  {
    code: 'NL',
    unicode: 'U+1F1F3 U+1F1F1',
    name: 'Netherlands',
    emoji: '🇳🇱',
    phoneCode: '+31',
    nationality: 'Dutch, Netherlandic',
  },
  {
    code: 'NO',
    unicode: 'U+1F1F3 U+1F1F4',
    name: 'Norway',
    emoji: '🇳🇴',
    phoneCode: '+47',
    nationality: 'Norwegian',
  },
  {
    code: 'NP',
    unicode: 'U+1F1F3 U+1F1F5',
    name: 'Nepal',
    emoji: '🇳🇵',
    phoneCode: '+977',
    nationality: 'Nepali, Nepalese',
  },
  {
    code: 'NR',
    unicode: 'U+1F1F3 U+1F1F7',
    name: 'Nauru',
    emoji: '🇳🇷',
    phoneCode: '+674',
    nationality: 'Nauruan',
  },
  {
    code: 'NU',
    unicode: 'U+1F1F3 U+1F1FA',
    name: 'Niue',
    emoji: '🇳🇺',
    phoneCode: '+683',
    nationality: 'Niuean',
  },
  {
    code: 'NZ',
    unicode: 'U+1F1F3 U+1F1FF',
    name: 'New Zealand',
    emoji: '🇳🇿',
    phoneCode: '+64',
    nationality: 'New Zealand, NZ',
  },
  {
    code: 'OM',
    unicode: 'U+1F1F4 U+1F1F2',
    name: 'Oman',
    emoji: '🇴🇲',
    phoneCode: '+968',
    nationality: 'Omani',
  },
  {
    code: 'PA',
    unicode: 'U+1F1F5 U+1F1E6',
    name: 'Panama',
    emoji: '🇵🇦',
    phoneCode: '+507',
    nationality: 'Panamanian',
  },
  {
    code: 'PE',
    unicode: 'U+1F1F5 U+1F1EA',
    name: 'Peru',
    emoji: '🇵🇪',
    phoneCode: '+51',
    nationality: 'Peruvian',
  },
  {
    code: 'PF',
    unicode: 'U+1F1F5 U+1F1EB',
    name: 'French Polynesia',
    emoji: '🇵🇫',
    phoneCode: '+689',
    nationality: 'French Polynesian',
  },
  {
    code: 'PG',
    unicode: 'U+1F1F5 U+1F1EC',
    name: 'Papua New Guinea',
    emoji: '🇵🇬',
    phoneCode: '+675',
    nationality: 'Papua New Guinean, Papuan',
  },
  {
    code: 'PH',
    unicode: 'U+1F1F5 U+1F1ED',
    name: 'Philippines',
    emoji: '🇵🇭',
    phoneCode: '+63',
    nationality: 'Philippine, Filipino',
  },
  {
    code: 'PM',
    unicode: 'U+1F1F5 U+1F1F2',
    name: 'St. Pierre & Miquelon',
    emoji: '🇵🇲',
    phoneCode: '+508',
    nationality: '',
  },
  {
    code: 'PN',
    unicode: 'U+1F1F5 U+1F1F3',
    name: 'Pitcairn Islands',
    emoji: '🇵🇳',
    phoneCode: '+64',
    nationality: '',
  },
  {
    code: 'PR',
    unicode: 'U+1F1F5 U+1F1F7',
    name: 'Puerto Rico',
    emoji: '🇵🇷',
    phoneCode: '+1-787',
    nationality: 'Puerto Rican',
  },
  {
    code: 'PS',
    unicode: 'U+1F1F5 U+1F1F8',
    name: 'Palestinian Territories',
    emoji: '🇵🇸',
    phoneCode: '+970',
    nationality: '',
  },
  {
    code: 'PT',
    unicode: 'U+1F1F5 U+1F1F9',
    name: 'Portugal',
    emoji: '🇵🇹',
    phoneCode: '+351',
    nationality: 'Portuguese',
  },
  {
    code: 'PW',
    unicode: 'U+1F1F5 U+1F1FC',
    name: 'Palau',
    emoji: '🇵🇼',
    phoneCode: '+680',
    nationality: 'Palauan',
  },
  {
    code: 'PY',
    unicode: 'U+1F1F5 U+1F1FE',
    name: 'Paraguay',
    emoji: '🇵🇾',
    phoneCode: '+595',
    nationality: 'Paraguayan',
  },
  {
    code: 'QA',
    unicode: 'U+1F1F6 U+1F1E6',
    name: 'Qatar',
    emoji: '🇶🇦',
    phoneCode: '+974',
    nationality: 'Qatari',
  },
  {
    code: 'RE',
    unicode: 'U+1F1F7 U+1F1EA',
    name: 'Réunion',
    emoji: '🇷🇪',
    phoneCode: '+262',
    nationality: 'Réunionese, Réunionnais',
  },
  {
    code: 'RO',
    unicode: 'U+1F1F7 U+1F1F4',
    name: 'Romania',
    emoji: '🇷🇴',
    phoneCode: '+40',
    nationality: 'Romanian',
  },
  {
    code: 'RS',
    unicode: 'U+1F1F7 U+1F1F8',
    name: 'Serbia',
    emoji: '🇷🇸',
    phoneCode: '+381',
    nationality: 'Serbian',
  },
  {
    code: 'RU',
    unicode: 'U+1F1F7 U+1F1FA',
    name: 'Russia',
    emoji: '🇷🇺',
    phoneCode: '+7',
    nationality: 'Russian',
  },
  {
    code: 'RW',
    unicode: 'U+1F1F7 U+1F1FC',
    name: 'Rwanda',
    emoji: '🇷🇼',
    phoneCode: '+250',
    nationality: 'Rwandan',
  },
  {
    code: 'SA',
    unicode: 'U+1F1F8 U+1F1E6',
    name: 'Saudi Arabia',
    emoji: '🇸🇦',
    phoneCode: '+966',
    nationality: 'Saudi, Saudi Arabian',
  },
  {
    code: 'SB',
    unicode: 'U+1F1F8 U+1F1E7',
    name: 'Solomon Islands',
    emoji: '🇸🇧',
    phoneCode: '+677',
    nationality: 'Solomon Island',
  },
  {
    code: 'SC',
    unicode: 'U+1F1F8 U+1F1E8',
    name: 'Seychelles',
    emoji: '🇸🇨',
    phoneCode: '+248',
    nationality: 'Seychellois',
  },
  {
    code: 'SD',
    unicode: 'U+1F1F8 U+1F1E9',
    name: 'Sudan',
    emoji: '🇸🇩',
    phoneCode: '+249',
    nationality: 'South Sudanese',
  },
  {
    code: 'SE',
    unicode: 'U+1F1F8 U+1F1EA',
    name: 'Sweden',
    emoji: '🇸🇪',
    phoneCode: '+46',
    nationality: 'Swedish',
  },
  {
    code: 'SG',
    unicode: 'U+1F1F8 U+1F1EC',
    name: 'Singapore',
    emoji: '🇸🇬',
    phoneCode: '+65',
    nationality: 'Singaporean',
  },
  {
    code: 'SH',
    unicode: 'U+1F1F8 U+1F1ED',
    name: 'St. Helena',
    emoji: '🇸🇭',
    phoneCode: '+290',
    nationality: '',
  },
  {
    code: 'SI',
    unicode: 'U+1F1F8 U+1F1EE',
    name: 'Slovenia',
    emoji: '🇸🇮',
    phoneCode: '+386',
    nationality: 'Slovenian, Slovene',
  },
  {
    code: 'SJ',
    unicode: 'U+1F1F8 U+1F1EF',
    name: 'Svalbard & Jan Mayen',
    emoji: '🇸🇯',
    phoneCode: '+47',
    nationality: '',
  },
  {
    code: 'SK',
    unicode: 'U+1F1F8 U+1F1F0',
    name: 'Slovakia',
    emoji: '🇸🇰',
    phoneCode: '+421',
    nationality: 'Slovak',
  },
  {
    code: 'SL',
    unicode: 'U+1F1F8 U+1F1F1',
    name: 'Sierra Leone',
    emoji: '🇸🇱',
    phoneCode: '+232',
    nationality: 'Sierra Leonean',
  },
  {
    code: 'SM',
    unicode: 'U+1F1F8 U+1F1F2',
    name: 'San Marino',
    emoji: '🇸🇲',
    phoneCode: '+378',
    nationality: 'Sammarinese',
  },
  {
    code: 'SN',
    unicode: 'U+1F1F8 U+1F1F3',
    name: 'Senegal',
    emoji: '🇸🇳',
    phoneCode: '+221',
    nationality: 'Senegalese',
  },
  {
    code: 'SO',
    unicode: 'U+1F1F8 U+1F1F4',
    name: 'Somalia',
    emoji: '🇸🇴',
    phoneCode: '+252',
    nationality: 'Somali, Somalian',
  },
  {
    code: 'SR',
    unicode: 'U+1F1F8 U+1F1F7',
    name: 'Suriname',
    emoji: '🇸🇷',
    phoneCode: '+597',
    nationality: 'Surinamese',
  },
  {
    code: 'SS',
    unicode: 'U+1F1F8 U+1F1F8',
    name: 'South Sudan',
    emoji: '🇸🇸',
    phoneCode: '+211',
    nationality: 'South Sudanese',
  },
  {
    code: 'ST',
    unicode: 'U+1F1F8 U+1F1F9',
    name: 'São Tomé & Príncipe',
    emoji: '🇸🇹',
    phoneCode: '+239',
    nationality: '',
  },
  {
    code: 'SV',
    unicode: 'U+1F1F8 U+1F1FB',
    name: 'El Salvador',
    emoji: '🇸🇻',
    phoneCode: '+503',
    nationality: 'Salvadoran',
  },
  {
    code: 'SX',
    unicode: 'U+1F1F8 U+1F1FD',
    name: 'Sint Maarten',
    emoji: '🇸🇽',
    phoneCode: '+1721',
    nationality: 'Sint Maarten',
  },
  {
    code: 'SY',
    unicode: 'U+1F1F8 U+1F1FE',
    name: 'Syria',
    emoji: '🇸🇾',
    phoneCode: '+963',
    nationality: 'Syrian',
  },
  {
    code: 'SZ',
    unicode: 'U+1F1F8 U+1F1FF',
    name: 'Swaziland',
    emoji: '🇸🇿',
    phoneCode: '+268',
    nationality: 'Swazi',
  },
  {
    code: 'TC',
    unicode: 'U+1F1F9 U+1F1E8',
    name: 'Turks & Caicos Islands',
    emoji: '🇹🇨',
    phoneCode: '+1',
    nationality: '',
  },
  {
    code: 'TD',
    unicode: 'U+1F1F9 U+1F1E9',
    name: 'Chad',
    emoji: '🇹🇩',
    phoneCode: '+235',
    nationality: 'Chadian',
  },
  {
    code: 'TF',
    unicode: 'U+1F1F9 U+1F1EB',
    name: 'French Southern Territories',
    emoji: '🇹🇫',
    phoneCode: '689',
    nationality: 'French Southern Territories',
  },
  {
    code: 'TG',
    unicode: 'U+1F1F9 U+1F1EC',
    name: 'Togo',
    emoji: '🇹🇬',
    phoneCode: '+228',
    nationality: 'Togolese',
  },
  {
    code: 'TH',
    unicode: 'U+1F1F9 U+1F1ED',
    name: 'Thailand',
    emoji: '🇹🇭',
    phoneCode: '+66',
    nationality: 'Thai',
  },
  {
    code: 'TJ',
    unicode: 'U+1F1F9 U+1F1EF',
    name: 'Tajikistan',
    emoji: '🇹🇯',
    phoneCode: '+992',
    nationality: 'Tajikistani',
  },
  {
    code: 'TK',
    unicode: 'U+1F1F9 U+1F1F0',
    name: 'Tokelau',
    emoji: '🇹🇰',
    phoneCode: '+690',
    nationality: 'Tokelauan',
  },
  {
    code: 'TL',
    unicode: 'U+1F1F9 U+1F1F1',
    name: 'Timor-Leste',
    emoji: '🇹🇱',
    phoneCode: '+670',
    nationality: 'Timorese',
  },
  {
    code: 'TM',
    unicode: 'U+1F1F9 U+1F1F2',
    name: 'Turkmenistan',
    emoji: '🇹🇲',
    phoneCode: '+993',
    nationality: 'Turkmen',
  },
  {
    code: 'TN',
    unicode: 'U+1F1F9 U+1F1F3',
    name: 'Tunisia',
    emoji: '🇹🇳',
    phoneCode: '+216',
    nationality: 'Tunisian',
  },
  {
    code: 'TO',
    unicode: 'U+1F1F9 U+1F1F4',
    name: 'Tonga',
    emoji: '🇹🇴',
    phoneCode: '+676',
    nationality: 'Tongan',
  },
  {
    code: 'TR',
    unicode: 'U+1F1F9 U+1F1F7',
    name: 'Turkey',
    emoji: '🇹🇷',
    phoneCode: '+90',
    nationality: 'Turkish',
  },
  {
    code: 'TT',
    unicode: 'U+1F1F9 U+1F1F9',
    name: 'Trinidad & Tobago',
    emoji: '🇹🇹',
    phoneCode: '+868',
    nationality: '',
  },
  {
    code: 'TV',
    unicode: 'U+1F1F9 U+1F1FB',
    name: 'Tuvalu',
    emoji: '🇹🇻',
    phoneCode: '+688',
    nationality: 'Tuvaluan',
  },
  {
    code: 'TW',
    unicode: 'U+1F1F9 U+1F1FC',
    name: 'Taiwan',
    emoji: '🇹🇼',
    phoneCode: '+886',
    nationality: 'Chinese, Taiwanese',
  },
  {
    code: 'TZ',
    unicode: 'U+1F1F9 U+1F1FF',
    name: 'Tanzania',
    emoji: '🇹🇿',
    phoneCode: '+255',
    nationality: 'Tanzanian',
  },
  {
    code: 'UA',
    unicode: 'U+1F1FA U+1F1E6',
    name: 'Ukraine',
    emoji: '🇺🇦',
    phoneCode: '+380',
    nationality: 'Ukrainian',
  },
  {
    code: 'UG',
    unicode: 'U+1F1FA U+1F1EC',
    name: 'Uganda',
    emoji: '🇺🇬',
    phoneCode: '+256',
    nationality: 'Ugandan',
  },
  {
    code: 'UM',
    unicode: 'U+1F1FA U+1F1F2',
    name: 'U.S. Outlying Islands',
    emoji: '🇺🇲',
    phoneCode: '+246',
    nationality: '',
  },
  {
    code: 'UY',
    unicode: 'U+1F1FA U+1F1FE',
    name: 'Uruguay',
    emoji: '🇺🇾',
    phoneCode: '+598',
    nationality: 'Uruguayan',
  },
  {
    code: 'UZ',
    unicode: 'U+1F1FA U+1F1FF',
    name: 'Uzbekistan',
    emoji: '🇺🇿',
    phoneCode: '+998',
    nationality: 'Uzbekistani, Uzbek',
  },
  {
    code: 'VA',
    unicode: 'U+1F1FB U+1F1E6',
    name: 'Vatican City',
    emoji: '🇻🇦',
    phoneCode: '+39',
    nationality: 'Vatican',
  },
  {
    code: 'VC',
    unicode: 'U+1F1FB U+1F1E8',
    name: 'St. Vincent & Grenadines',
    emoji: '🇻🇨',
    phoneCode: '+1784',
    nationality: '',
  },
  {
    code: 'VE',
    unicode: 'U+1F1FB U+1F1EA',
    name: 'Venezuela',
    emoji: '🇻🇪',
    phoneCode: '+58',
    nationality: 'Venezuelan',
  },
  {
    code: 'VG',
    unicode: 'U+1F1FB U+1F1EC',
    name: 'British Virgin Islands',
    emoji: '🇻🇬',
    phoneCode: '+1-284',
    nationality: '',
  },
  {
    code: 'VI',
    unicode: 'U+1F1FB U+1F1EE',
    name: 'U.S. Virgin Islands',
    emoji: '🇻🇮',
    phoneCode: '+1-340',
    nationality: '',
  },
  {
    code: 'VN',
    unicode: 'U+1F1FB U+1F1F3',
    name: 'Vietnam',
    emoji: '🇻🇳',
    phoneCode: '+84',
    nationality: 'Vietnamese',
  },
  {
    code: 'VU',
    unicode: 'U+1F1FB U+1F1FA',
    name: 'Vanuatu',
    emoji: '🇻🇺',
    phoneCode: '+678',
    nationality: 'Ni-Vanuatu, Vanuatuan',
  },
  {
    code: 'WF',
    unicode: 'U+1F1FC U+1F1EB',
    name: 'Wallis & Futuna',
    emoji: '🇼🇫',
    phoneCode: '+681',
    nationality: '',
  },
  {
    code: 'WS',
    unicode: 'U+1F1FC U+1F1F8',
    name: 'Samoa',
    emoji: '🇼🇸',
    phoneCode: '+685',
    nationality: 'American Samoan',
  },
  {
    code: 'XK',
    unicode: 'U+1F1FD U+1F1F0',
    name: 'Kosovo',
    emoji: '🇽🇰',
    phoneCode: '+383',
    nationality: '',
  },
  {
    code: 'YE',
    unicode: 'U+1F1FE U+1F1EA',
    name: 'Yemen',
    emoji: '🇾🇪',
    phoneCode: '+967',
    nationality: 'Yemeni',
  },
  {
    code: 'YT',
    unicode: 'U+1F1FE U+1F1F9',
    name: 'Mayotte',
    emoji: '🇾🇹',
    phoneCode: '+262',
    nationality: 'Mahoran',
  },
  {
    code: 'ZA',
    unicode: 'U+1F1FF U+1F1E6',
    name: 'South Africa',
    emoji: '🇿🇦',
    phoneCode: '+27',
    nationality: 'South African',
  },
  {
    code: 'ZM',
    unicode: 'U+1F1FF U+1F1F2',
    name: 'Zambia',
    emoji: '🇿🇲',
    phoneCode: '+260',
    nationality: 'Zambian',
  },
  {
    code: 'ZW',
    unicode: 'U+1F1FF U+1F1FC',
    name: 'Zimbabwe',
    emoji: '🇿🇼',
    phoneCode: '+263',
    nationality: 'Zimbabwean',
  },
];
