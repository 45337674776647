export const Images = {
  loginImage: require("../assets/images/imgdoc.png"),
  assesment: require("../assets/images/assessment.png"),
  abogado: require("../assets/images/abogado.png"),
  cross: require("../assets/images/cross.png"),
  redCross: require("../assets/images/redCross.png"),
  gestoria: require("../assets/images/gestoria.png"),
  doc: require("../assets/images/Doc.png"),
  additional: require("../assets/images/Additional Budget.png"),
  cancel: require("../assets/images/Order Canceled.png"),
  orderPlaced: require("../assets/images/Order Placed.png"),
  request: require("../assets/images/Request.png"),
  euro: require("../assets/images/euros.png"),
  click: require("../assets/images/Clicks.png"),
  refund: require("../assets/images/Refunds.png"),
  user: require("../assets/images/Userr.png"),
  pdf: require("../assets/images/PDF.png"),
  xls: require("../assets/images/XLS.png"),
  arrow: require("../assets/images/arrow-blue.svg"),
  backArrow: require("../assets/images/arrow-left.svg"),
  draft: require("../assets/images/diskette.png"),
  filter: require("../assets/images/Filter.svg"),
  image: require("../assets/images/Image.png"),
  about: require("../assets/images/about.png"),
  appstore: require("../assets/images/appstore.png"),
  appstore1: require("../assets/images/appstore1.png"),
  playstore1: require("../assets/images/playstore1.png"),
  appstore2: require("../assets/images/appstore2.png"),
  playstore2: require("../assets/images/playstore2.png"),
  app: require("../assets/images/app.png"),
  autonomo: require("../assets/images/autonomo.png"),
  certificados: require("../assets/images/certificados.png"),
  check: require("../assets/images/check.png"),
  circleLogo: require("../assets/images/circle-logo.png"),
  circle: require("../assets/images/circle.png"),
  civilLaw: require("../assets/images/civil-law.png"),
  codelab: require("../assets/images/codelab.png"),
  contable: require("../assets/images/contable.png"),
  derechoAdministrativo: require("../assets/images/DERECHO-ADMINISTRATIVO.png"),
  document: require("../assets/images/Document.svg"),
  downloadButtonNew: require("../assets/images/download-button-new.svg"),
  downloadButtonNew1: require("../assets/images/download-button-new1.svg"),
  earth: require("../assets/images/earth.png"),
  empresarial: require("../assets/images/empresarial.png"),
  extranjeria: require("../assets/images/extranjeria.png"),
  financiera: require("../assets/images/financiera.png"),
  financiero: require("../assets/images/Financiero.png"),
  help: require("../assets/images/help.svg"),
  workWithUs: require("../assets/images/workWithUsIcon.svg"),
  workWithUsActiveIcon: require("../assets/images/workWithUsActiveIcon.svg"),
  herencias: require("../assets/images/herencias.png"),
  hexa: require("../assets/images/hexa.png"),
  icon1: require("../assets/images/icon1.png"),
  icon2: require("../assets/images/icon2.png"),
  icon3: require("../assets/images/icon3.png"),
  icon4: require("../assets/images/icon4.svg"),
  img5: require("../assets/images/img-5.jpg"),
  nicolas: require("../assets/images/nicolas.jpg"),
  natalia: require("../assets/images/natalia.jpg"),
  img: require("../assets/images/img.png"),
  imgdoc: require("../assets/images/imgdoc.png"),
  impuestos: require("../assets/images/impuestos.png"),
  infoo: require("../assets/images/infoo.png"),
  informes: require("../assets/images/Informes.png"),
  inmobiliario: require("../assets/images/inmobiliario.png"),
  laborLaw: require("../assets/images/LABOR-LAW.png"),
  laboral: require("../assets/images/laboral.png"),
  laboralnew: require("../assets/images/laboralnew.png"),
  legal: require("../assets/images/legal.png"),
  lightai: require("../assets/images/lightai.png"),
  loginBg: require("../assets/images/login-bg.jpg"),
  logoLight: require("../assets/images/logo-light.png"),
  logob: require("../assets/images/logob.png"),
  logobb: require("../assets/images/logobb.png"),
  logout: require("../assets/images/logout.svg"),
  mensajeria: require("../assets/images/Mensajeria.png"),
  messages: require("../assets/images/messages.svg"),
  messagesActiveIcon: require("../assets/images/messagesActiveIcon.svg"),

  mouseIcon: require("../assets/images/mouse_icon.png"),
  myInformation: require("../assets/images/my-information.svg"),
  myorders: require("../assets/images/myorders.svg"),
  nirastate: require("../assets/images/nirastate.png"),
  order: require("../assets/images/order.png"),
  other: require("../assets/images/other.png"),
  partner1: require("../assets/images/partner1.png"),
  partner2: require("../assets/images/partner2.png"),
  pattern: require("../assets/images/pattern.png"),
  paypalLogo: require("../assets/images/paypal-logo.png"),
  playstore: require("../assets/images/playstore.png"),
  preloader: require("../assets/images/preloader.gif"),
  promotioncode: require("../assets/images/promotioncode.svg"),
  publicadmin: require("../assets/images/publicadmin.png"),
  registroDeLogoYMarca: require("../assets/images/Registro-de-logo-y-marca.png"),
  searchIconBg: require("../assets/images/search_icon_bg.png"),
  seguros: require("../assets/images/seguros.png"),
  serviceIcon11: require("../assets/images/service/service_icon_1-1.png"),
  serviceIcon21: require("../assets/images/service/service_icon_2-1.png"),
  serviceIcon31: require("../assets/images/service/service_icon_3-1.png"),
  shareWin: require("../assets/images/share_win.svg"),
  slide02: require("../assets/images/slide_02.jpg"),
  smallAuthor: require("../assets/images/small_author.png"),
  specialPrices: require("../assets/images/Special-Prices.png"),
  specialPrice: require("../assets/images/specialprice.svg"),
  step01: require("../assets/images/step01.png"),
  step02: require("../assets/images/step02.png"),
  step03: require("../assets/images/step03.png"),
  step04: require("../assets/images/step04.png"),
  sucesiones: require("../assets/images/sucesiones.png"),
  trafficAndTrans: require("../assets/images/traffic-and-trans.png"),
  transporte: require("../assets/images/transporte.png"),
  treva: require("../assets/images/treva.png"),
  u1: require("../assets/images/u1.png"),
  u2: require("../assets/images/u2.png"),
  u3: require("../assets/images/u3.png"),
  uniBachiEscol: require("../assets/images/uni-bachi-escol.png"),
  video: require("../assets/images/video.png"),
  zootv: require("../assets/images/zootv.png"),
  logo: require("../assets/images/logob.png"),
  promoGift: require("../assets/images/promoGift.png"),
  Punctual: require("../assets/images/service/Punctual.png"),
  ServicePack: require("../assets/images/service/ServicePack.png"),
  ServicePack2: require("../assets/images/service/ServicePack2.png"),
  addPayment: require("../assets/images/addPayment.png"),
  serviceimg: require("../assets/images/serviceimg.png"),
  assm: require("../assets/images/assm.png"),
  documentIcon: require("../assets/images/document.png"),
  lawyer: require("../assets/images/lawyer.png"),
  Business: require("../assets/images/Business.png"),
  family: require("../assets/images/family.png"),
  others: require("../assets/images/others.png"),
  SelfEmployed: require("../assets/images/SelfEmployed.png"),
  placeHolder: require("../assets/images/placeholder.jpg"),
  Google: require("../assets/images/Google.png"),
  Facebook: require("../assets/images/Facebook.png"),
  trash: require("../assets/images/trash.png"),
  mockUp: require("../assets/images/Mockups.png"),
  alertBellIcon: require("../assets/images/alertBellIcon.svg"),
  alertActiveBellIcon: require("../assets/images/alertActiveBellIcon.svg"),
  saveDraftIcon: require("../assets/images/saveDraftIcon.svg"),
  saveDraftACtiveIcon: require("../assets/images/saveDraftActiveIcon.svg"),
  additionalBudgetIcon: require("../assets/images/additionalBudgetIcon.svg"),
  additionalBudgetActiveIcon: require("../assets/images/additionalBudgetActiveIcon.svg"),
  docuDocAdminChatLogo: require("../assets/images/login-logo.png"),
};
