import React from "react";
import labels from "../config/Labels";

const OurStoryEnglish = () => {
  return (
    <div className="ins-float-icon-box-content">
      <h4 className="mb-20  ">{labels.ourstory}</h4>
      {/* <div className="ins-float-icon-box-title mt-2 mb-2 ">
      Legal services for everyone made easy.
    </div> */}
      <div className="last-paragraph-no-margin">
        <p className="ins-float-icon-box-text  default ">
          While being a particular as well as self-employed and now being an
          entrepreneur the bureaucracy necessary to carry out anything related
          to legal procedures surprised me. Always related to:{" "}
        </p>
        <ul
          style={{
            color: "#888",
            fontWeight: "400",
          }}
        >
          <li className="">Great waste of time</li>
          <li className="">Difficulty to find precise information</li>
          <li className="">Lack of a simple, fast, transparent solution</li>
        </ul>
        <p className="">
          DocuDoc is the perfect solution for all those who need any type of
          legal procedures in a simple and fast way.
        </p>
      </div>
    </div>
  );
};

export default OurStoryEnglish;
