import React, { useState, useEffect } from "react";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { Images } from "../../constant/images";
import { BiInfoCircle } from "react-icons/bi";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import Switch from "react-switch";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Container, Modal, ModalBody } from "react-bootstrap";
import { FaFileAlt } from "react-icons/fa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  GetServiceFormById,
  Get_File_Url_Method,
  Get_Service_Providers,
  Delete_Profile_Method,
  Edit_Profiles_Method,
  Add_Profile,
} from "../../config/api";
import ToastComponent from "../../component/toast";
import DynamciInput from "../../component/dynamicInput";
import PhoneInput from "react-phone-number-input";
import validator from "validator";
import labels from "../../config/Labels";
import { Phone } from "react-bootstrap-icons";
import { Helmet } from "react-helmet";

// import { Route } from "react-router-dom/cjs/react-router-dom.min";
let urlArray = [];
export default function NewAccount(props) {
  const location = useLocation();

  const { push } = useHistory();
  const history = useHistory();
  const [information, setInformation] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [profileId, setProfileId] = useState("");
  const [check, setCheck] = useState(false);
  const [selected, setSelected] = useState(0);
  const [showModal, setShowModal] = useState(false);
  //firstForm states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [CIF, setCIF] = useState("");
  const [address, setAddress] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [isBusiness, setIsBusiness] = useState(false);
  //secondForm States
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [city, setCity] = useState("");
  //thirdForm States
  const [serviceProviders, setServiceProviders] = useState([]);
  //fourthForm States
  const [serviceForm, setServiceForm] = useState([]);
  //finalObj states
  const [personal, setPersonal] = useState("");
  const [region, setRegion] = useState("");
  const [serviceProviderId, setServiceProviderId] = useState("");
  const [success, setSuccess] = useState(false);
  //   const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [singleDocument, setSingleDocument] = useState("");
  const [muiltipleDocument, setMuiltipleDocument] = useState("");
  const [message, setMessage] = useState("");
  const [profileTitle, setProfileTitle] = useState("");
  const [none, setNone] = useState("None");
  const [business, SetBusiness] = useState("");
  const [self, setSelf] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [validation, setValidation] = useState(false);
  const { addToast } = useToasts();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // setNone("None");
    window.scrollTo(0, 0);
    setProfileId(location?.state?.id);
    setValue(location?.state?.data[0]?.value);
    setFirstName(location?.state?.data[0].firstName);
    setCity(location?.state?.data[0]?.city);
    setProfileTitle(location?.state?.data[0]?.profileTitle);
    // setSelectedRegion(
    //   location?.state?.data[0]?.selectedRegion ??
    //     location?.state?.data[0]?.region
    // );
    setSelectedRegion(location?.state?.data[0]?.region);
    setLastName(location.state?.data[0].lastName);
    setEmailAddress(location.state?.data[0].emailAddress);
    setContactNo(location.state?.data[0].contactNo);
    setCompanyName(
      location.state?.data[0]?.companyName?.length
        ? location.state?.data[0]?.companyName
        : ""
    );
    setAddress(
      location.state?.data[0]?.address?.length
        ? location.state?.data[0]?.address
        : ""
    );
    setCIF(
      location.state?.data[0]?.CIF?.length ? location.state?.data[0]?.CIF : ""
    );
    setValue(location.state?.data[0]?.phone_number);
    setNone(
      typeof location.state?.data[0]?.none != "undefined"
        ? location.state?.data[0]?.none
        : "None"
    );
    SetBusiness(location.state?.data[0]?.business);
    setSelf(location.state?.data[0]?.setSelf);
    setIsDisabled(
      location.state?.data[0]?.setSelf?.length ||
        location.state?.data[0]?.business?.length
        ? false
        : true
    );
  }, []);
  // alert(none)

  const [emailValidation, setEmailvalidation] = useState(false);

  const [emailError, setEmailError] = useState("");
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
      setEmailvalidation(false);
    } else {
      setEmailError("Enter valid Email!");
      setEmailvalidation(true);
    }
  };

  const states = [
    {
      value: "Andalucia",
    },
    {
      value: "Aragon",
    },
    {
      value: "Cantabria",
    },
    {
      value: "Castilla Y Leon",
    },
    {
      value: "Castilla La Mancha ",
    },
    {
      value: "Cataluna",
    },
    {
      value: "Ceuta",
    },
    {
      value: "Comunidad De Madrid",
    },
    {
      value: "Comunidad Foral De Navarra",
    },
    {
      value: "Comunidad Valenciana",
    },
    {
      value: "Extremadura ",
    },
    {
      value: "Galicia",
    },
    {
      value: "Islas Baleares",
    },
    {
      value: "La Rioja",
    },
    {
      value: "Melilla ",
    },
    {
      value: "Pais Vasco",
    },
    {
      value: "Principado De Asturias",
    },
    {
      value: "Region De Murcia ",
    },
  ];

  const deleteProfileFunc = () => {
    let personal = {
      profileTitle,
      firstName,
      lastName,
      emailAddress,
      isBusiness,
      countryCode: countryCode,
      contactNo: contactNo,
      companyName,
      CIF,
      address,
      region: selectedRegion,
      city,
      none,
      business,
      self,
      value,
    };
    let data = [personal];
    Delete_Profile_Method(
      {
        profile_id: profileId,
        data: JSON?.stringify(data),
      },
      (value) => setServiceProviders(value),
      (value) => setShowSpinner(value),
      (value) => setSuccess(value),
      (value) => setShowToast(value),
      (value) => {
        setMessage(value);
        addToast(value, {
          appearance: "success",
          autoDismiss: 1000,
        });
      }
    );
    setTimeout(() => {
      push({ pathname: "./1/myaccount" });
    }, 1000);
  };

  return (
    <div className="tab-content bg-white">
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      {showToast === true ? (
        <ToastComponent
          message={message}
          toastClose={setShowToast(false)}
          success={success}
        />
      ) : (
        ""
      )}

      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{labels.deleteProfile}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{labels.areYouSureWantDeleteProfile}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {labels.no}
            </Button>
            <Button variant="danger" onClick={deleteProfileFunc}>
              {showSpinner ? `${labels.loading}...` : labels.yes}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <form className="login-box">
        <Container
          style={{
            paddingTop: "100px",
          }}
        >
          <div className="tab-content bg-white">
            {location.state && (
              <Row className="mb-4">
                <Col>
                  <div className="d-flex ">
                    <img
                      src={Images.backArrow.default}
                      style={{ width: "40px", height: "40px" }}
                      className="cursorPointer"
                      onClick={() => {
                        history.goBack();
                      }}
                    />
                    {!location.state && (
                      <h4 className="mx-3">{labels.addNewAccount}</h4>
                    )}
                  </div>
                </Col>
                <Col>
                  <h4 className="custom-font text-center">
                    {labels.editProfile}
                  </h4>
                </Col>
                <Col>
                  <div className="d-flex justify-content-end">
                    <span
                      className="text-danger border border-danger rounded-circle px-2 cursorPointer "
                      onClick={handleShow}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path
                          fill-rule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        />
                      </svg>
                    </span>
                  </div>
                </Col>
              </Row>
              // <div className="col-sm-12 d-flex justify-content-end mb-5 pr-0 ">

              //   <span
              //     className="text-danger border border-danger rounded-circle px-2 cursorPointer "
              //     onClick={handleShow}
              //   >
              //     <svg
              //       xmlns="http://www.w3.org/2000/svg"
              //       width="16"
              //       height="16"
              //       fill="currentColor"
              //       className="bi bi-trash"
              //       viewBox="0 0 16 16"
              //     >
              //       <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
              //       <path
              //         fill-rule="evenodd"
              //         d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              //       />
              //     </svg>
              //   </span>
              // </div>
            )}

            <div className="tab-pane active" role="tabpanel">
              <Row>
                <>
                  <Col md="4">
                    <div className="form-group">
                      <label className="d-block">
                        {labels.title}
                        <span className="float-right">
                          <BiInfoCircle
                            size={20}
                            color={
                              !profileTitle?.length && validation
                                ? "red"
                                : "black"
                            }
                          />
                        </span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="Title"
                        placeholder={labels.title}
                        value={profileTitle}
                        onChange={(e) => setProfileTitle(e.target.value)}
                      />
                      {!profileTitle?.length && validation && (
                        <p className="text-danger"> {labels.validateField}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="form-group">
                      <label className="d-block">
                        {labels.FirstName}
                        <span className="float-right">
                          <BiInfoCircle
                            size={20}
                            color={
                              !firstName?.length && validation ? "red" : "black"
                            }
                          />
                        </span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder={labels.FirstName}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      {!firstName?.length && validation && (
                        <p className="text-danger">{labels.validateField}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="form-group">
                      <label className="d-block">
                        {labels.LastName}
                        <span className="float-right">
                          <BiInfoCircle
                            size={20}
                            color={
                              !lastName?.length && validation ? "red" : "black"
                            }
                          />
                        </span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder={labels.LastName}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      {!lastName?.length && validation && (
                        <p className="text-danger">{labels.validateField}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="d-block">
                        {labels.contactNumber}
                        <span className="float-right">
                          <BiInfoCircle
                            size={20}
                            color={
                              !value?.length && validation ? "red" : "black"
                            }
                          />
                        </span>
                      </label>
                      <div className="d-flex">
                        <input
                          className="form-control"
                          maxLength={20}
                          autoComplete={false}
                          type="tel"
                          placeholder={labels.phoneNumber}
                          value={value}
                          onChange={(e) => {
                            const re = /^[0-9-+\b]+$/;

                            // if value is not blank, then test the regex

                            if (
                              e.target.value === "" ||
                              re.test(e.target.value)
                            ) {
                              setValue(e.target.value);
                            }
                          }}
                        />
                        {/* <PhoneInput
                          style={{ width: "100%", marginRight: "5px" }}
                          international
                          maxLength={20}
                          countryCallingCodeEditable={false}
                          defaultCountry="ES"
                          value={value}
                          onChange={setValue}
                        /> */}
                      </div>
                      {!value?.length && validation ? (
                        <p className="text-danger">{labels.validateField}</p>
                      ) : (
                        validation &&
                        value?.length < 7 && (
                          <p className="text-justify" style={{ color: "red" }}>
                            Invalid phone number{" "}
                          </p>
                        )
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="d-block">
                        {labels.emailAddress}
                        <span className="float-right">
                          <BiInfoCircle
                            size={20}
                            color={
                              !emailAddress?.length && validation
                                ? "red"
                                : "black"
                            }
                          />
                        </span>
                      </label>
                      <input
                        className="form-control text-lowercase"
                        type="email"
                        pattern=".+@globex\.com"
                        size="30"
                        required
                        name="name"
                        placeholder={labels.emailAddress}
                        value={emailAddress}
                        onBlur={(e) => {
                          validateEmail(e);
                        }}
                        onChange={(e) => setEmailAddress(e.target.value)}
                      />
                      {!emailAddress?.length && validation && (
                        <p className="text-danger">{labels.validateEmail}</p>
                      )}
                      {emailValidation && (
                        <p className="text-danger">{labels.validateEmail}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="d-block">
                        {labels.selectRegion}
                        <span className="float-right">
                          <BiInfoCircle
                            size={20}
                            color={
                              !selectedRegion?.length && validation
                                ? "red"
                                : "black"
                            }
                          />
                        </span>
                      </label>
                      <select
                        className="form-control"
                        type="text"
                        name="name"
                        value={selectedRegion}
                        onChange={(e) => setSelectedRegion(e.target.value)}
                      >
                        <option value="" disabled selected>
                          {labels.states}
                        </option>
                        {states.map((state, index) => {
                          return (
                            <option value={state.value}>{state.value}</option>
                          );
                        })}
                      </select>
                      {!selectedRegion?.length && validation && (
                        <p className="text-danger">{labels.validateField}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="d-block">
                        {labels.city}
                        <span className="float-right">
                          <BiInfoCircle
                            size={20}
                            color={
                              !city?.length && validation ? "red" : "black"
                            }
                          />
                        </span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder={labels.city}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                      {!city?.length && validation && (
                        <p className="text-danger">{labels.validateField}</p>
                      )}
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="d-flex justify-content-between h-100 align-items-center">
                      <h6 className="font-weight-bold">
                        {labels.selectUserTypeBillingInformation}
                      </h6>
                      <div>
                        <input
                          type="radio"
                          value={none}
                          defaultChecked={
                            !location?.state?.data[0]?.business?.length &&
                            !location?.state?.data[0]?.self
                          }
                          name="gender"
                          onClick={() => {
                            setNone("None");
                            SetBusiness("");
                            setSelf("");
                            setIsDisabled(true);
                          }}
                        />{" "}
                        {labels.none}{" "}
                        <input
                          type="radio"
                          value={business}
                          name="gender"
                          defaultChecked={
                            location?.state?.data[0]?.business?.length && true
                          }
                          onClick={() => {
                            SetBusiness(" Business");
                            setSelf("");
                            setNone("");
                            setIsDisabled(false);
                          }}
                        />{" "}
                        {labels.business}{" "}
                        <input
                          type="radio"
                          value={self}
                          name="gender"
                          defaultChecked={
                            location?.state?.data[0]?.self?.length && true
                          }
                          onClick={() => {
                            setSelf("Self Employed");
                            SetBusiness("");
                            setNone("");
                            setIsDisabled(false);
                          }}
                        />{" "}
                        {labels.selfEmployed}
                      </div>
                    </div>
                  </Col>
                </>
              </Row>

              <form className="login-box">
                {/* <Row>
                    <Col md="12" className="d-flex justify-content-between">
                      <h6 className="font-weight-bold">
                        {labels.selectUserType}
                      </h6>
                      <div>
                        <input
                          type="radio"
                          value={none}
                          defaultChecked={
                            !location?.state?.data[0]?.business?.length &&
                            !location?.state?.data[0]?.self
                          }
                          name="gender"
                          onClick={() => {
                            setNone("None");
                            SetBusiness("");
                            setSelf("");
                            setIsDisabled(true);
                          }}
                        />{" "}
                        {labels.none}{" "}
                        <input
                          type="radio"
                          value={business}
                          name="gender"
                          defaultChecked={
                            location?.state?.data[0]?.business?.length && true
                          }
                          onClick={() => {
                            SetBusiness(" Business");
                            setSelf("");
                            setNone("");
                            setIsDisabled(false);
                          }}
                        />{" "}
                        {labels.business}{" "}
                        <input
                          type="radio"
                          value={self}
                          name="gender"
                          defaultChecked={
                            location?.state?.data[0]?.self?.length && true
                          }
                          onClick={() => {
                            setSelf("Self Employed");
                            SetBusiness("");
                            setNone("");
                            setIsDisabled(false);
                          }}
                        />{" "}
                        {labels.selfEmployed}
                      </div>
                    </Col>
                  </Row> */}
                <Row>
                  <Col
                    md="4"
                    className="documentation-container"
                    hidden={isDisabled}
                  >
                    <div className="form-group">
                      <label className="d-block">
                        {labels.companyName}
                        <span className="float-right">
                          <BiInfoCircle size={20} color={"black"} />
                        </span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder={labels.companyName}
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                      {/* {!companyName?.length && validation && (
                          <p className="text-danger">{labels.validateField}</p>
                        )} */}
                    </div>
                  </Col>
                  <Col md="4" hidden={isDisabled}>
                    <div className="form-group">
                      <label className="d-block">
                        {labels.cif}
                        <span className="float-right">
                          <BiInfoCircle size={20} color={"black"} />
                        </span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder={labels.enterCIF}
                        value={CIF}
                        onChange={(e) => setCIF(e.target.value)}
                      />
                      {/* {!CIF.length && validation && (
                          <p className="text-danger">{labels.validateField}</p>
                        )} */}
                    </div>
                  </Col>
                  <Col md="4" hidden={isDisabled}>
                    <div className="form-group">
                      <label className="d-block">
                        {labels.address}
                        <span className="float-right">
                          <BiInfoCircle size={20} color={"black"} />
                        </span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder={labels.address}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                      {/* {!address.length && validation && (
                          <p className="text-danger">{labels.validateField}</p>
                        )} */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className="d-flex justify-content-center">
                    {!location?.state ? (
                      <button
                        onClick={() => {
                          setValidation(true);
                          if (
                            profileTitle !== "" &&
                            firstName &&
                            lastName !== "" &&
                            emailAddress !== "" &&
                            contactNo !== "" &&
                            value?.length >= 7 &&
                            city !== "" &&
                            typeof selectedRegion !== "undefined" &&
                            !emailValidation
                          ) {
                            // if (!none?.length) {
                            //   if (
                            //     companyName !== "" &&
                            //     address !== "" &&
                            //     CIF !== ""
                            //   ) {
                            //   } else {
                            //     return addToast(labels.allFieldsAreRequired, {
                            //       autoDismiss: 1000,
                            //     });
                            //     setValidation(true);
                            //   }
                            // }

                            let personal = {
                              profileTitle,
                              firstName,
                              lastName,
                              emailAddress,
                              isBusiness,
                              countryCode: countryCode,
                              contactNo: contactNo,
                              companyName,
                              CIF,
                              address,
                              region: selectedRegion,
                              city,
                              none,
                              business,
                              self,
                              phone_number: value,
                            };

                            let data = [personal];
                            const obj = {
                              is_default: "1",
                              data: JSON.stringify(data),
                            };
                            // Add_Profile(
                            //   {
                            //     is_default: "1",
                            //     data: JSON.stringify(data),
                            //   },
                            //   (value) => setServiceProviders(value),
                            //   (value) => {
                            //     setShowSpinner(value);
                            //   }
                            // );
                            Add_Profile(
                              obj,
                              (value) => {
                                setServiceProviders(value);
                              },
                              setShowSpinner,
                              (value) => {
                                setSuccess(value);
                              },
                              (value) => {
                                addToast(value, {
                                  autoDismiss: 1000,
                                });
                                setMessage(value);
                              },
                              (value) => {
                                setShowToast(value);
                              }
                            );
                            localStorage.setItem("firstName", firstName);
                            localStorage.setItem("lastName", lastName);
                            localStorage.setItem("email", emailAddress);
                            localStorage.setItem("company", companyName);
                            localStorage.setItem("Cif", CIF);
                            localStorage.setItem("phoneNumber", value);
                            localStorage.setItem("address", address);

                            setTimeout(() => {
                              push({ pathname: "./1/myaccount" });
                            }, 1000);
                          } else {
                            setValidation(true);

                            // addToast(
                            //   labels.allFieldsAreRequired,
                            //   { appearance: "info", autoDismiss: 1000 }
                            // );
                          }
                        }}
                        type="button"
                        className="btn-primary gradiant-theme-color "
                      >
                        {showSpinner ? `${labels.loading}...` : labels.save}
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setValidation(true);
                          if (
                            profileTitle !== "" &&
                            firstName &&
                            lastName !== "" &&
                            emailAddress !== "" &&
                            contactNo !== "" &&
                            value?.length !== "" &&
                            value?.length >= 7 &&
                            city !== "" &&
                            typeof selectedRegion !== "undefined" &&
                            !emailValidation
                          ) {
                            // if (!none?.length) {
                            //   if (
                            //     companyName !== "" &&
                            //     address !== "" &&
                            //     CIF !== ""
                            //   ) {
                            //   } else {
                            //     setValidation(true);
                            //     return addToast(labels.allFieldsAreRequired, {
                            //       autoDismiss: 1000,
                            //     });
                            //   }
                            // }

                            let personal = {
                              profileTitle,
                              firstName,
                              lastName,
                              emailAddress,
                              isBusiness,
                              phone_number: value,
                              contactNo: contactNo,
                              companyName,
                              CIF,
                              address,
                              region: selectedRegion,
                              city,
                              none,
                              business,
                              self,
                            };

                            let data = [personal];
                            Edit_Profiles_Method(
                              {
                                is_default: "1",
                                profile_id: location?.state?.id,
                                data: JSON.stringify(data),
                              },
                              (value) => setServiceProviders(value),
                              (value) => {
                                setShowSpinner(value);
                              }
                            );
                            localStorage.setItem("firstName", firstName);
                            localStorage.setItem("lastName", lastName);
                            localStorage.setItem("email", emailAddress);
                            localStorage.setItem("company", companyName);
                            localStorage.setItem("Cif", CIF);
                            localStorage.setItem("phoneNumber", value);
                            localStorage.setItem("address", address);

                            push({ pathname: "./1/myaccount" });
                          } else {
                            setValidation(true);

                            addToast(
                              "all fields are required with their valid format",
                              { appearance: "info", autoDismiss: 1000 }
                            );
                          }
                        }}
                        type="button"
                        className="btn btn-primary  gradiant-theme-color"
                      >
                        {showSpinner ? `${labels.loading}...` : labels.update}
                      </button>
                    )}
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </Container>
      </form>
    </div>
  );
}
