// import { Modal } from 'bootstrap';
import React from "react";
import { Modal, ModalBody } from "react-bootstrap";
import { Images } from "../../constant/images";
import { SpecificServiceButton } from "../../component";
import ScrollAnimation from "react-animate-on-scroll";
import { Helmet } from "react-helmet";

export default function Partners({}) {
  const [showConfirmationPopup, setShowConfirmationPopup] =
    React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    setShowConfirmationPopup(true);
    setTimeout(() => {
      setShowConfirmationPopup(false);
    }, 2000);
  }, []);
  const card = [
    {
      image: Images.icon1.default,
      title: "DocuDoc Partner T.",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "60 EUR/ mes",
      discount: "100 EUR/ mes",
      country: "Spain",
    },
    {
      image: Images.icon1.default,
      title: "DocuDoc Partner J.",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "Free!",
      discount: "",
      country: "Spain",
    },
    {
      image: Images.icon1.default,
      title: "DocuDoc Partner A.",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "20 EUR/ mes",
      discount: "40 EUR/ mes",
      country: "Spain",
    },
    {
      image: Images.icon1.default,
      title: "DocuDoc Partner T.",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "40 EUR/ mes",
      discount: "10 EUR/ mes",
      country: "Canada",
    },
    {
      image: Images.icon1.default,
      title: "DocuDoc Partner T.",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      price: "25 EUR/ mes",
      discount: "20 EUR/ mes",
      country: "Canada",
    },
  ];
  return (
    <>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <div className="container mtb50">
        <div className="row">
          <div className="col-md-12">
            <div className="subheadmain">
              <h3>DOCUDOC PARTNERS</h3>
            </div>
          </div>
        </div>
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <div className="tab-content mb-40">
            <div className="tab-pane active show" id="tabs-1" role="tabpanel">
              <div className="row">
                {card.map((item, index) => {
                  return (
                    <SpecificServiceButton
                      isPartner={true}
                      key={index}
                      path={"documentaion"}
                      title={item.title}
                      desc={item.desc}
                      country={item.country}
                      ratings={"4.3"}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <Modal
          className="modelBackground"
          show={showConfirmationPopup}
          onHide={() => setShowConfirmationPopup(false)}
          size="lg"
        >
          <ModalBody className="bg-white p-0 border-radius-10 alignItemsCenter textAlignCenter m-auto height_70 my-20">
            <button
              type="button"
              className="close absolute"
              data-dismiss="modal"
              onClick={() => setShowConfirmationPopup(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div>
              <img className="mt-20" src={Images.check.default} />
              <h5 className="mx-30">Your order has been added successfully</h5>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}
