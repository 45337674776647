// import { Modal } from 'bootstrap';
import React from "react";
import { useLocation } from "react-router-dom";
import CookiesPolicy from "../../component/CookiesPolicy";
import CookiesPolicySpanish from "../../component/CookiesPolicySpanish";
import { Helmet } from "react-helmet";

export default function Cookies({}) {
  const search = useLocation().search;
  const key = new URLSearchParams(search).get("lang");
  let language = localStorage.getItem("primaryLanguage");

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="p-30 pt-50">
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      {key === null ? (
        language === "english" ? (
          <CookiesPolicy />
        ) : (
          <CookiesPolicySpanish />
        )
      ) : key === "english" ? (
        <CookiesPolicy />
      ) : (
        <CookiesPolicySpanish />
      )}
    </div>
  );
}
