import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Get_Notifications, Read_Notification } from "../../config/api";
import labels from "../../config/Labels";
import { functionToConvertStringToCamelCase } from "../../config/Utility";
import { Images } from "../../constant/images";
import { Helmet } from "react-helmet";

const OrderNotifications = () => {
  const params = useParams();
  const { goBack } = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const getNotifications = (id) => {
    setSpinner(true);
    Get_Notifications(
      id,
      (value) => {},
      (value) => {},
      (value) => {
        setNotifications(value);
        setSpinner(false);
      }
    );
  };
  const ReadOrderNotification = (id) => {
    const obj = {
      order_id: id,
    };
    Read_Notification(obj);
  };
  useEffect(() => {
    getNotifications(params.id);
    ReadOrderNotification(params.id);
  }, []);
  return (
    <Fragment>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <Container
        fluid
        className="px-6 marginTop100 d-flex justify-content-center"
        style={{ minHeight: "60vh" }}
      >
        <div>{spinner ? labels.loading : null}</div>

        <Card className="w-50 shadow-none border-0">
          <Card.Body>
            <Row>
              <Col>
                <h3 className="fontSize16px">
                  <div className="d-flex mx-20 mb-20 align-items-center gap-2">
                    <img
                      src={Images.backArrow.default}
                      style={{ width: "50px", height: "50px" }}
                      className="cursorPointer mr-3"
                      onClick={() => {
                        goBack();
                      }}
                    />
                    <strong>{labels.orderNotification}</strong>
                  </div>
                </h3>
              </Col>
            </Row>
            <Card>
              <Card.Body>
                <Row>
                  {notifications?.map((item, index) => (
                    <Col key={index} sm={12}>
                      <div className="notification-list notification-list--unread d-flex justify-content-start cursorPointer">
                        <div className="notification-list_img d-flex ">
                          {item?.type == "further" && (
                            <img src={Images.request.default} />
                          )}
                          {item?.type == "budget" && (
                            <img src={Images?.additional.default} />
                          )}
                          {item?.type == "placed" && (
                            <img src={Images.orderPlaced.default} />
                          )}
                          {item?.type == "completed" && (
                            <img src={Images.orderPlaced.default} />
                          )}
                          {item?.type == "canceled" && (
                            <img src={Images.cancel.default} />
                          )}
                        </div>
                        <div className="notification-list_detail ">
                          <p>
                            <b>
                              {
                                labels?.[
                                  functionToConvertStringToCamelCase(
                                    item?.notification
                                  )
                                ]
                              }
                            </b>
                          </p>
                          <p>
                            <small>
                              {moment(item?.created_at).format(
                                "YYYY-MM-DD hh:mm:ss"
                              )}
                            </small>
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Card.Body>
        </Card>
      </Container>
      {/* <div
        className="container-fluid px-6 marginTop100 d-flex justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div>{spinner ? labels.loading : null}</div>

        <div
          className="messageContainer chat-Container px-3"
          style={{ overflowY: "scrollY", height: "380px" }}
        >
          <div
            className="row Document-Container ql-editor2"
            style={{ overflowY: "scroll", height: "380px" }}
          >
    
            <div className="col-12  px-0">
              {notifications?.map((item, index) => {
                return (
                  <div className="notification-list notification-list--unread d-flex justify-content-start cursorPointer">
                    <div className="notification-list_img d-flex ">
                      {item?.type == "further" && (
                        <img src={Images .request.default} />
                      )}
                      {item?.type == "budget" && (
                        <img src={Images?.additional.default} />
                      )}
                      {item?.type == "placed" && (
                        <img src={Images.orderPlaced.default} />
                      )}
                      {item?.type == "completed" && (
                        <img src={Images.orderPlaced.default} />
                      )}
                      {item?.type == "canceled" && (
                        <img src={Images.cancel.default} />
                      )}
                    </div>
                    <div className="notification-list_detail ">
                      <p>
                        <b>
                          {
                            labels?.[
                              functionToConvertStringToCamelCase(
                                item?.notification
                              )
                            ]
                          }
                        </b>
                      </p>
                      <p>
                        <small>
                          {moment(item?.created_at).format(
                            "YYYY-MM-DD hh:mm:ss"
                          )}
                        </small>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div> */}
    </Fragment>
  );
};

export default OrderNotifications;
