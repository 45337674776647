// import { Modal } from 'bootstrap';
import React, { useEffect, useRef, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useHistory } from "react-router-dom";
import { ServiceArea, ServiceItem } from "../../component";
import OurAppEnglish from "../../component/OurAppEnglish";
import OurAppSpanish from "../../component/OurAppSpanish";
import labels from "../../config/Labels";
import { useStoreItem } from "../../config/Store";
import { Get_Profile_Method } from "../../config/api";
import { Images } from "../../constant/images";
import { docudocAndroidUrl, docudociosdUrl } from "../../constant";
import { Helmet } from "react-helmet";

export default function Home(props) {
  let language = localStorage.getItem("primaryLanguage");

  const [beneficiaries, setBeneficiaries] = useState([]);

  const [serviceAreas, setServiceAreas] = useState([
    {
      avatar: Images.abogado.default,

      created_at: "2021-12-27T09:13:50.000000Z",
      id: 1,
      info: null,
      name: language == "english" ? "Lawyer" : " Abogado",
      service_area_id: "bd4b5a26-521d-4904-9236-e1772ba63034",
      updated_at: "2021-12-27T09:13:50.000000Z",
    },

    {
      avatar: Images.gestoria.default,
      created_at: "2021-12-27T09:13:51.000000Z",
      id: 3,
      info: null,
      name: language == "english" ? "Agency" : " Gestor",
      service_area_id: "b9abec2b-53cb-428f-8d51-b0406c9ad4e0",
      updated_at: "2021-12-27T09:13:51.000000Z",
    },
    {
      avatar: Images.assesment.default,
      created_at: "2021-12-27T09:13:51.000000Z",
      id: 3,
      info: null,
      name: language == "english" ? "Assessor" : "Asesor",
      service_area_id: "e9673fd6-87a1-48be-8442-efbd8c6009c3",
      updated_at: "2021-12-27T09:13:51.000000Z",
    },
  ]);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [testCompo, settestCompo] = useState(null);
  const [sortedAreas, setSortedAreas] = useState(serviceAreas);
  const target = useRef(null);
  const messagesEndRef = useRef(null);
  const { updateUser, primaryLanguage, updateLanguage } = useStoreItem();

  const history = useHistory();

  const changeLanguage1 = () => {
    const lang = localStorage.getItem("primaryLanguage");
    if (lang == "spanish") {
      localStorage.setItem("primaryLanguage", "english");
      history.go(0);
    }

    // window.reload();

    history.go(0);
  };
  let browser = window.navigator.userAgent.match(/chrome|chromium|crios/i);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    checkToken();
  }, []);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      if (props.location.state === "mobileApp") {
        window.scrollTo({
          top:
            document
              .getElementById("our_docudoc_mobile_app")
              .getBoundingClientRect().top || 580,
          left: 0,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    }, 2000);
  }, [props.location.state]);

  // const scrollToBottom = () => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // };

  const checkToken = () => {
    let token = localStorage.getItem("usertoken");
    if (token !== null) {
      Get_Profile_Method(
        (value) => {
          setMessage(value);
        },
        (value) => {
          setShowToast(value);
        },
        (value) => {
          setSuccess(value);
        },
        (value) => {
          updateUser(value);
        }
      );
    }
  };

  const closeToast = () => {
    setShowToast(false);
  };

  useEffect(() => {
    var compo = { 1: [] };
    beneficiaries.map((item, index) => {
      // alert(JSON.stringify(item))
      if (index > 0 && index < 3) {
        compo[1]?.push(
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
            <ServiceItem
              name={item.name}
              image={item.avatar}
              beneId={item.beneficiary_id}
              info={item.info}
            />
          </ScrollAnimation>
        );
      } else {
        compo = {
          ...compo,
          [index]: (
            <div className="col-md-4 px-0">
              <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                <ServiceItem
                  name={item.name}
                  image={item.avatar}
                  beneId={item.beneficiary_id}
                  info={item.info}
                />
              </ScrollAnimation>
            </div>
          ),
        };
      }
      settestCompo(compo);
    });
  }, [beneficiaries]);

  return (
    <>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <section>
        <div id="canvas" className="site">
          <span className="flexslider-overlay1 gradiant-theme-color"></span>
          <div id="box_wrapper">
            <span className="toggle_menu_side header-slide">
              <span></span>
            </span>
            <section className="page_slider main_slider ">
              <div
                className="flexslider-bottom d-none d-xl-block"
                style={{ backgroundColor: "yellow" }}
              ></div>
            </section>

            <div className="pt-90 home-tabs">
              <center>
                <ServiceArea />
              </center>
            </div>
            {/* <AllServices /> */}
          </div>
          <div className="overlay">
            <div className="overlay__ripple" ref={messagesEndRef}></div>
          </div>

          <div
            className="flexslider-bottom1 d-none d-xl-block"
            style={{ backgroundColor: "yellow" }}
          ></div>
        </div>
      </section>
      <section
        className="s-pt-70  ls position-relative"
        id="services"
        data-animation="fadeInUp"
      >
        <div id="our_docudoc_mobile_app" className="position-absolute"></div>
        <div className="wrapper-main padd60 pb-5 mt-50">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-lg-6 col-md-6">
                <div
                  id="ins-icon-6046fc09aeded"
                  className="ins-float-icon-wrapper margin-20px-bottom ins-icon-box-align-left-basic fadeInUp"
                  data-animation-delay="200"
                >
                  <div className="ins-float-icon-inner position-relative">
                    <i className="link-icon text-medium-gray icon-medium iconsmind-Target"></i>
                  </div>
                  <div className="ins-float-icon-box-content">
                    {localStorage.getItem("primaryLanguage") == "english" ? (
                      <OurAppEnglish className="mb-10" />
                    ) : (
                      <OurAppSpanish className="mb-10" />
                    )}
                  </div>
                  {/* <div className="ins-float-icon-box-content"></div> */}
                  <div
                    className={
                      localStorage.getItem("primaryLanguage") == "spanish" &&
                      "mt-20"
                    }
                  >
                    <a href={docudociosdUrl} target="_blank">
                      <img
                        src={Images.appstore.default}
                        style={{ width: "150px" }}
                      />
                    </a>

                    <a href={docudocAndroidUrl} target="_blank">
                      <img
                        src={Images.playstore.default}
                        style={{ width: "150px" }}
                        className="ml-10"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-lg-6 col-md-6">
                <div className="section-heading ins-heading text-left ">
                  <div style={{ marginBottom: 70 }}></div>
                  <img
                    src={Images.app.default}
                    className="img-responsive m-80 d-block mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="work-wrap wow fadeInUp mt-30">
        <div className="container">
          <h4 style={{ fontSize: "30px" }} className="mb-5 ">
            {labels.howDoesDocudocsWork}
          </h4>
          <ul className="fix text-center row">
            <li className="single-working-steps-box wow fadeInUp  col-lg-3 col-md-6">
              <div className="icon-holder">
                <img className="mr-3 mb-4 w-50" src={Images.click.default} />
              </div>
              <div className="text-holder text-center">
                <div
                  style={{ height: "100px", marginLeft: "32px" }}
                  className="w-75"
                >
                  <h4 className="">{labels.selectLegalService}</h4>
                </div>
                <p className="m-0 p-0">{labels.inAFewClicksDetails}</p>
              </div>
            </li>

            <li className="single-working-steps-box wow fadeInUp  col-lg-3 col-md-6">
              <div className="icon-holder">
                <img className="mb-2 mr-3 w-50 " src={Images.euro.default} />
              </div>
              <div className="text-holder text-center">
                <div
                  style={{ height: "100px", marginLeft: "67px" }}
                  className="w-50"
                >
                  <h4 className="">{labels.ViewFinalPrice}</h4>
                </div>
                <p className="m-0 p-0">{labels.ViewFinalPriceDetails}</p>
              </div>
            </li>

            <li className="single-working-steps-box wow fadeInUp  col-lg-3 col-md-6">
              <div className="icon-holder">
                <img className="mb-3 w-50" src={Images.user.default} />
              </div>
              <div className="text-holder text-center">
                <div style={{ height: "100px" }}>
                  <h4 className="">{labels.selectLawyerAssessmentGestor}</h4>
                </div>
                <p className="p-0 m-0">
                  {labels.selectLawyerAssessmentGestorDetails}
                </p>
              </div>
            </li>

            <li className="single-working-steps-box wow fadeInUp  col-lg-3 col-md-6">
              <div className="icon-holder">
                <img className="mb-2 w-50" src={Images.refund.default} />
              </div>
              <div className="text-holder text-center">
                <div
                  style={{
                    height: "100px",
                    width: "187px",
                    marginLeft: "43px",
                  }}
                >
                  <h4 className="">{labels.willRefund}</h4>
                </div>
                <p className="m-0 p-0">{labels.willRefundDetails}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
