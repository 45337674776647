import React from "react";
import labels from "../config/Labels";

function OurPartnerEnglish() {
  return (
    <div className="ins-float-icon-box-content">
      <h4 className=" mt-2 ">{labels.ourPartner}</h4>
      <div className="last-paragraph-no-margin">
        <p className="ins-float-icon-box-text  default">
          Our partners are the best valued legal professionals
          near you.
        </p>

        <p className="ins-float-icon-box-text  default ">
          Customer satisfaction is the most important factor for us,
          therefore, we constantly verify the quality of services to
          through customer evaluation. In case there is a partner
          who does not give an average rating of more than 5 stars, there will be a
          internal process to assess your continuity with us.
        </p>

        <p
          className="ins-float-icon-box-text  default "
          // ref={messagesEndRef}
        >
        If you do not receive the contracted service, we will refund your money.
        </p>
      </div>
    </div>
  );
}

export default OurPartnerEnglish;
