import React from "react";
import labels from "../config/Labels";

function OurPartnerSpanish() {
  return (
    <div className="ins-float-icon-box-content">
      <h4 className=" mt-2 ">{labels.ourPartner}</h4>
      <div className="last-paragraph-no-margin">
        <p className="ins-float-icon-box-text  default">
          <p className="">
            Nuestros partners son los profesionales jurídicos mejor valorados
            cerca de ti.
          </p>
        </p>

        <p className="ins-float-icon-box-text  default ">
          La satisfacción del cliente es el factor más importante para nosotros,
          por lo tanto, verificamos constantemente la calidad de los servicios a
          través de la evaluación del cliente. En caso de que haya algún partner
          que no realice una valoración media superior a 5 estrellas, habrá un
          proceso interno para valorar su continuidad con nosotros.
        </p>

        <p
          className="ins-float-icon-box-text  default "
          //  ref={messagesEndRef}
        >
          Si no recibes el servicio contratado, te devolvemos tu dinero.
        </p>
      </div>
    </div>
  );
}

export default OurPartnerSpanish;
