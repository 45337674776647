// import { Modal } from 'bootstrap';
import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { SpecificServiceButton } from "../../component";
import ToastComponent from "../../component/toast";
import { Get_Branches_By_Service_Area_Id_By_Beneficiary_Id } from "../../config/api";
import { Images } from "../../constant/images";
import { Helmet } from "react-helmet";

export default function InnerService(props) {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [branches, setBranches] = useState([]);
  React.useEffect(() => {
    window.scrollTo(0, 0);

    Get_Branches_By_Service_Area_Id_By_Beneficiary_Id(
      props.match.params.id,
      props.location.state.beneId,
      (value) => setMessage(value),
      (value) => setShowToast(value),
      (value) => setSuccess(value),
      (value) => {
        setBranches(value);
      }
    );
  }, []);

  const closeToast = () => {
    setShowToast(false);
  };

  return (
    <>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <div className="container mtb50">
        <div className="row justify-content-center">
          <div className="col-md-3 d-flex justify-content-center">
            <div className="subheadmainTags">
              <div className="item-media">
                <img src={Images.assm.default} alt="" style={{ width: 40 }} />
              </div>
              <h3>{props.location.state.serviceName}</h3>
            </div>
          </div>
        </div>
        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <div className="tab-content mb-40">
            <div className="tab-pane active show" id="tabs-1" role="tabpanel">
              <div className="row">
                {showToast === true ? (
                  <ToastComponent
                    message={message}
                    toastClose={closeToast}
                    success={success}
                  />
                ) : (
                  ""
                )}
                {branches.map((item, index) => {
                  return (
                    <SpecificServiceButton
                      path={"service"}
                      image={item.avatar}
                      title={item.name}
                      branch_id={item.branch_id}
                      desc={item.info}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </>
  );
}
