import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { BiInfoCircle } from "react-icons/bi";
import { useStoreItem } from "../config/Store";

import validator from "validator";
import labels from "../config/Labels";
import { Edit_Profiles_Method } from "../config/api";
import { Utility } from "../config/Utility";
function EditProfileModal(props) {
  let length = props?.data?.length - 1;

  const { push } = useHistory();
  const history = useHistory();

  const [information, setInformation] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [check, setCheck] = useState(false);
  const [selected, setSelected] = useState(0);
  const [showModal, setShowModal] = useState(false);
  //firstForm states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [CIF, setCIF] = useState("");
  const [address, setAddress] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [isBusiness, setIsBusiness] = useState(false);
  //secondForm States
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [city, setCity] = useState("");
  //thirdForm States
  const [serviceProviders, setServiceProviders] = useState([]);
  //fourthForm States
  const [serviceForm, setServiceForm] = useState([]);
  //finalObj states
  const [personal, setPersonal] = useState("");
  const [region, setRegion] = useState("");
  const [serviceProviderId, setServiceProviderId] = useState("");
  const [success, setSuccess] = useState(false);
  //   const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [singleDocument, setSingleDocument] = useState("");
  const [muiltipleDocument, setMuiltipleDocument] = useState("");
  const [message, setMessage] = useState("");
  const [profileTitle, setProfileTitle] = useState("");
  const [none, setNone] = useState("None");
  const [business, SetBusiness] = useState("");
  const [self, setSelf] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [validation, setValidation] = useState(false);
  const [Id, setId] = useState("");
  const { addToast } = useToasts();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [emailValidation, setEmailvalidation] = useState(false);
  const [emailError, setEmailError] = useState("");
  const {
    updateUser,
    upDateFirstName,
    upDateLastName,
    upDatePhoneNumber,
    upDateCompanyName,
    upDateCIF,
    upDateAddress,
  } = useStoreItem();
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
      setEmailvalidation(false);
    } else {
      setEmailError("Enter valid Email!");
      setEmailvalidation(true);
    }
  };

  const states = [
    {
      value: "Andalucia",
    },
    {
      value: "Aragon",
    },
    {
      value: "Cantabria",
    },
    {
      value: "Castilla Y Leon",
    },
    {
      value: "Castilla La Mancha ",
    },
    {
      value: "Cataluna",
    },
    {
      value: "Ceuta",
    },
    {
      value: "Comunidad De Madrid",
    },
    {
      value: "Comunidad Foral De Navarra",
    },
    {
      value: "Comunidad Valenciana",
    },
    {
      value: "Extremadura ",
    },
    {
      value: "Galicia",
    },
    {
      value: "Islas Baleares",
    },
    {
      value: "La Rioja",
    },
    {
      value: "Melilla ",
    },
    {
      value: "Pais Vasco",
    },
    {
      value: "Principado De Asturias",
    },
    {
      value: "Region De Murcia ",
    },
  ];

  // const {CIF,address,city,companyName,countryCode,emailAddress,firstName,isBusiness,lastName,none,phone_number,profileTitle,selectedRegion,self

  // }=props?.data?.data
  // const initialData = props.data.map(item=>item);

  // const fieldData = initialData[0]?.data.map(item=>item)

  useEffect(() => {
    upDateFirstName(props?.data[length]?.data?.[0]?.firstName);
    upDateLastName(props?.data[length]?.data?.[0]?.lastName);
    upDatePhoneNumber(props?.data[length]?.data?.[0]?.phoneNumber);
    upDateCompanyName(props?.data[length]?.data?.[0]?.companyName);
    upDateCIF(props?.data[length]?.data?.[0]?.CIF);
    upDateAddress(props?.data[length]?.data?.[0]?.address);

    setId(props?.data[length]?.id);

    setFirstName(props?.data[length]?.data?.[0]?.firstName);
    setLastName(props?.data[length]?.data?.[0]?.lastName);
    setProfileTitle(props?.data[length]?.data?.[0]?.profileTitle);
    setEmailAddress(props?.data[length]?.data?.[0]?.emailAddress);
    setValue(props?.data[length]?.data?.[0]?.phoneNumber);
    setSelectedRegion(props?.data[length]?.data?.[0]?.selectedRegion);

    // setValue(Object.values(fieldData?.map((item)=>item?.phone_number)));
    // setFirstName(location?.state?.data[0].firstName);
    setCity(props?.data[length]?.data?.[0]?.city);
    // setProfileTitle(location?.state?.data[0]?.profileTitle);
    //  setSelectedRegion(location?.state?.data[0]?.selectedRegion);
    // setLastName(location.state?.data[0].lastName);
    // setEmailAddress(location.state?.data[0].emailAddress);
    setContactNo(props?.data[length]?.data?.[0]?.phoneNumber);
    setCompanyName(props?.data[length]?.data?.[0]?.companyName);
    setAddress(props?.data[length]?.data?.[0]?.address);
    setCIF(props?.data[length]?.data?.[0]?.CIF);
    setValue(props?.data[length]?.data?.[0]?.phone_number);
    setNone(
      typeof props?.data[length]?.data?.[0]?.none != "undefined"
        ? props?.data[length]?.data?.[0]?.none
        : "None"
    );
    SetBusiness(props?.data[length]?.data?.[0]?.business);
    setSelf(props?.data[length]?.data?.[0]?.setSelf);
    setIsDisabled(
      props?.data[length]?.data?.[0]?.setSelf?.length ||
        props?.data[length]?.data?.[0]?.business?.length
        ? false
        : true
    );
  }, [props.show]);
  useEffect(() => {}, []);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      scrollable
      className="profileContainer"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Info</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ overflowY: "scroll", maxHeight: "650px" }}
        className="profileContainer"
      >
        <form role="form" action="index.html" className="login-box">
          <div className="tab-content bg-white mt-10 pt-0 pb-0">
            {{
              /* location.state  */
            } && (
              <div className="col-sm-12 d-flex justify-content-end mb-5 pr-0 ">
                {" "}
                {/* <span
                className="text-danger border border-danger rounded-circle px-2 cursorPointer "
                onClick={handleShow}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </span> */}
              </div>
            )}

            <div className="tab-pane active" role="tabpanel">
              <div className="row">
                {" "}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="d-block">
                      {labels.profileTitle}
                      <span className="float-right">
                        <BiInfoCircle size={20} />
                      </span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="Title"
                      placeholder={labels.profileTitle}
                      value={profileTitle}
                      onChange={(e) => setProfileTitle(e.target.value)}
                    />
                    {!profileTitle?.length && validation && (
                      <p className="text-danger"> {labels.validateField}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="d-block">
                      {labels.FirstName}
                      <span className="float-right">
                        <BiInfoCircle size={20} />
                      </span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder={labels.FirstName}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    {!firstName?.length && validation && (
                      <p className="text-danger">{labels.validateField}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="d-block">
                      {labels.LastName}
                      <span className="float-right">
                        <BiInfoCircle size={20} />
                      </span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder={labels.LastName}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    {!lastName?.length && validation && (
                      <p className="text-danger">{labels.validateField}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="d-block">
                      {labels.phoneNumber}
                      <span className="float-right">
                        <BiInfoCircle size={20} />
                      </span>
                    </label>
                    <div className="d-flex">
                      <input
                        className="form-control"
                        maxLength={20}
                        autoComplete={false}
                        type="tel"
                        placeholder={labels.phoneNumber}
                        value={value}
                        onChange={(e) => {
                          const re = /^[0-9-+\b]+$/;

                          // if value is not blank, then test the regex

                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setValue(e.target.value);
                          }
                        }}
                      />
                      {/* <PhoneInput
                        style={{ width: "100%", marginRight: "5px" }}
                        international
                        maxLength={20}
                        countryCallingCodeEditable={false}
                        defaultCountry="ES"
                        value={value}
                        onChange={setValue}
                      /> */}
                    </div>
                    {!value?.length && validation && (
                      <p className="text-danger">{labels.validateField}</p>
                    )}
                    {validation && !Utility?.validatePhone(value) && (
                      <p className="text-justify" style={{ color: "red" }}>
                        Invalid phone number{" "}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="d-block">
                      {labels.Email}
                      <span className="float-right">
                        <BiInfoCircle size={20} />
                      </span>
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      pattern=".+@globex\.com"
                      size="30"
                      required
                      name="name"
                      placeholder={labels.Email}
                      value={emailAddress}
                      onBlur={(e) => {
                        validateEmail(e);
                      }}
                      onChange={(e) => setEmailAddress(e.target.value)}
                    />
                    {emailAddress?.length <= 0 && validation && (
                      <p className="text-danger">{labels.validateEmail}</p>
                    )}
                    {emailValidation && (
                      <p className="text-danger">{labels.validateEmail}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="d-block">
                      {labels.selectRegion}
                      <span className="float-right">
                        <BiInfoCircle size={20} />
                      </span>
                    </label>
                    <select
                      className="form-control select-input-style"
                      type="text"
                      name="name"
                      value={selectedRegion}
                      onChange={(e) => setSelectedRegion(e.target.value)}
                    >
                      <option value="" disabled selected>
                        {labels.states}
                      </option>
                      {states.map((state, index) => {
                        return (
                          <option value={state.value}>{state.value}</option>
                        );
                      })}
                    </select>
                    {setSelectedRegion?.length <= 0 && validation && (
                      <p className="text-danger">{labels.validateField}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="d-block">
                      {labels.city}
                      <span className="float-right">
                        <BiInfoCircle size={20} />
                      </span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder={labels.city}
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                    {city?.length <= 0 && validation && (
                      <p className="text-danger">{labels.validateField}</p>
                    )}
                  </div>
                </div>
              </div>{" "}
              <form role="form" action="index.html" className="login-box">
                <div className="tab-content bg-white">
                  <div className="tab-pane active" role="tabpanel">
                    <div className="row">
                      <div className="col-md-12 d-flex justifyContentSB pr-3 height30px mt-3 mb-3">
                        <h6 className="font-weight-bold">
                          {labels.selectUserType}
                        </h6>
                        <div>
                          <input
                            type="radio"
                            value={none}
                            defaultChecked={
                              !props?.data[length]?.data?.[0]?.business
                                ?.length &&
                              !props?.data[length]?.data?.[0]?.business?.length
                            }
                            name="gender"
                            onClick={() => {
                              setNone("None");
                              SetBusiness("");
                              setSelf("");
                              setIsDisabled(true);
                            }}
                          />{" "}
                          {labels.none}{" "}
                          <input
                            type="radio"
                            value={business}
                            name="gender"
                            defaultChecked={
                              props?.data[length]?.data?.[0]?.business
                                ?.length && true
                            }
                            onClick={() => {
                              SetBusiness(" Business");
                              setSelf("");
                              setNone("");
                              setIsDisabled(false);
                            }}
                          />{" "}
                          {labels.business}{" "}
                          <input
                            type="radio"
                            value={self}
                            name="gender"
                            defaultChecked={
                              props?.data[length]?.data?.[0]?.self?.length &&
                              true
                            }
                            onClick={() => {
                              setSelf("Self Employed");
                              SetBusiness("");
                              setNone("");
                              setIsDisabled(false);
                            }}
                          />{" "}
                          {labels.selfEmployed}
                        </div>
                      </div>
                      <div
                        className="col-md-6 documentation-container"
                        hidden={isDisabled}
                      >
                        <div className="form-group">
                          <label className="d-block">
                            {labels.companyName}
                            <span className="float-right">
                              <BiInfoCircle size={20} />
                            </span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder={labels.companyName}
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                          />
                          {!companyName?.length && validation && (
                            <p className="text-danger">
                              {labels.validateField}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6" hidden={isDisabled}>
                        <div className="form-group">
                          <label className="d-block">
                            {labels.cif}
                            <span className="float-right">
                              <BiInfoCircle size={20} />
                            </span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder={labels.enterCIF}
                            value={CIF}
                            onChange={(e) => setCIF(e.target.value)}
                          />
                          {!CIF?.length && validation && (
                            <p className="text-danger">
                              {labels.validateField}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6" hidden={isDisabled}>
                        <div className="form-group">
                          <label className="d-block">
                            {labels.address}
                            <span className="float-right">
                              <BiInfoCircle size={20} />
                            </span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder={labels.address}
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                          {!address?.length && validation && (
                            <p className="text-danger">
                              {labels.validateField}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <ul className="list-inline text-right">
                      <li>
                        {{
                          /* !location?.state */
                        } ? (
                          <button
                            onClick={() => {
                              setValidation(true);
                              if (
                                profileTitle !== "" &&
                                firstName &&
                                lastName !== "" &&
                                emailAddress !== "" &&
                                contactNo !== "" &&
                                value?.trim()?.length >= 7 &&
                                value?.trim()?.length <= 15 &&
                                city !== "" &&
                                typeof selectedRegion !== "undefined" &&
                                !emailValidation
                              ) {
                                if (!none?.length) {
                                  if (
                                    companyName !== "" &&
                                    address !== "" &&
                                    CIF !== ""
                                  ) {
                                  } else {
                                    // return addToast(labels.allFieldsAreRequired, {
                                    //   autoDismiss: 1000,
                                    // });
                                    setValidation(true);
                                  }
                                }

                                upDateFirstName(firstName);
                                upDateLastName(lastName);
                                upDatePhoneNumber(contactNo);
                                upDateCompanyName(companyName);
                                upDateCIF(CIF);
                                upDateAddress(address);

                                let personal = {
                                  profileTitle,
                                  firstName,
                                  lastName,
                                  emailAddress,
                                  isBusiness,
                                  countryCode: countryCode,
                                  contactNo: contactNo,
                                  companyName,
                                  CIF,
                                  address,
                                  selectedRegion,
                                  city,
                                  none,
                                  business,
                                  self,
                                  phone_number: value,
                                };

                                let data = [personal];

                                Edit_Profiles_Method(
                                  {
                                    is_default: "1",
                                    profile_id: props?.id[length],
                                    data: JSON.stringify(data),
                                  },
                                  (value) => {
                                    setServiceProviders(value);
                                  },
                                  (value) => {
                                    setShowSpinner(value);
                                  }
                                );
                                localStorage.setItem("firstName", firstName);
                                localStorage.setItem("lastName", lastName);
                                localStorage.setItem("email", emailAddress);
                                localStorage.setItem("company", companyName);
                                localStorage.setItem("Cif", CIF);
                                localStorage.setItem("phoneNumber", value);
                                localStorage.setItem("address", address);

                                setTimeout(() => {
                                  // push({ pathname: "./1/myaccount" });
                                  props.onHide();
                                  setShowSpinner(false);
                                }, 1000);
                              } else {
                                setValidation(true);

                                // addToast(
                                //   labels.allFieldsAreRequired,
                                //   { appearance: "info", autoDismiss: 1000 }
                                // );
                              }
                            }}
                            type="button"
                            className="btn btn-primary gradiant-theme-color"
                          >
                            {showSpinner
                              ? `${labels.loading}...`
                              : labels.update}
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setValidation(true);
                              if (
                                profileTitle !== "" &&
                                firstName &&
                                lastName !== "" &&
                                emailAddress !== "" &&
                                contactNo !== "" &&
                                value?.length !== "" &&
                                value?.length >= 7 &&
                                city !== "" &&
                                typeof selectedRegion !== "undefined" &&
                                !emailValidation
                              ) {
                                if (!none?.length) {
                                  if (
                                    companyName !== "" &&
                                    address !== "" &&
                                    CIF !== ""
                                  ) {
                                  } else {
                                    setValidation(true);
                                    // return addToast(labels.allFieldsAreRequired, {
                                    //   autoDismiss: 1000,
                                    // });
                                  }
                                }

                                let personal = {
                                  profileTitle,
                                  firstName,
                                  lastName,
                                  emailAddress,
                                  isBusiness,
                                  phone_number: value,
                                  contactNo: contactNo,
                                  companyName,
                                  CIF,
                                  address,
                                  selectedRegion,
                                  city,
                                  none,
                                  business,
                                  self,
                                };

                                let data = [personal];
                                Edit_Profiles_Method(
                                  {
                                    profile_id: Id,
                                    data: JSON.stringify(data),
                                  },
                                  (value) => {
                                    setServiceProviders(value);
                                  },
                                  (value) => {
                                    setShowSpinner(value);
                                  }
                                );

                                props.hide();
                              } else {
                                setValidation(true);

                                // addToast(
                                //   "all fields are required with their valid format",
                                //   { appearance: "info", autoDismiss: 1000 }
                                // );
                              }
                            }}
                            type="button"
                            className="btn btn-primary gradiant-theme-color"
                          >
                            {showSpinner
                              ? `${labels.loading}...`
                              : labels.update}
                          </button>
                        )}
                      </li>
                      <li></li>
                    </ul>
                  </div>
                </div>
              </form>
              <ul className="list-inline text-right">
                <li></li>
              </ul>
            </div>
          </div>
        </form>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default EditProfileModal;
