import React, { useState } from "react";
import labels from "../../config/Labels";
import { Images } from "../../constant/images";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";

export default function ShareWin({}) {
  const [promoCode, setPromoCode] = useState(`New30`);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const copiedPromoCode = () => {
    addToast("promo code copied", {
      autoDismiss: 1000,
    });
  };
  const { addToast } = useToasts();

  return (
    <section className="pb-150 ls" id="services" data-animation="fadeInUp">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 p-0">
            {/* <div className="dashboard-right">
                            <div className="main-title-tab">
                                <h4>
                                    <i className="uil uil-apps"></i>Share & Win
                                </h4>
                            </div>
                        </div> */}
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-12 text-center">
                <img src={Images.promoGift.default} width={150} />
                <h5 className="mt-20">{labels.getPromo}</h5>
                <p>{labels.sharePromo}</p>
                <div className="divider-vetical" />
                {/* </div>
                            <div className='col-lg-12 col-md-12 text-center align-self-md-end'> */}

                <label className="px-40 py-15 code-container w-50 mt-30">
                  {promoCode}
                </label>
                <div className="textAlignCenter mt-20 mb-20">
                  <CopyToClipboard text={promoCode}>
                    <button
                      className="btn btn-primary onSelectionBackground w-50"
                      onClick={copiedPromoCode}
                    >
                      {labels.copy}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
