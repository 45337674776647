import React, { useState } from "react";
import { Button, Modal, ModalBody } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { default as labels } from "../../config/Labels";
import { Images } from "../../constant/images";
import { Delete_Account } from "../../config/api";

const DeleteAccount = (props) => {
  const { isDeleteAccountModal, setIsDeleteAccountModal } = props;
  const { addToast } = useToasts();

  const [showSpinner, setShowSpinner] = useState(false);

  const handleClickDeleteAccount = () => {
    setShowSpinner(true);
    Delete_Account(
      (res) => {},
      (err) => {
        setShowSpinner(false);
      },
      addToast
    );
  };

  return (
    <Modal
      className="modelBackground"
      show={isDeleteAccountModal}
      onHide={() => setIsDeleteAccountModal(false)}
      size="lg"
    >
      <ModalBody className="bg-white p-0 border-radius-10  textAlignCenter my-20 w-75 mx-auto ">
        <button
          type="button"
          className="close  absolute"
          data-dismiss="modal"
          onClick={() => setIsDeleteAccountModal(false)}
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <div className="px-5 py-3 w-100 ">
          <div className="row my-3 ">
            <div className="col-12 mx-auto text-left ">
              <div className="text-center">
                <img src={Images.circleLogo.default} className="circlelogo" />
              </div>
              <h4 className="font-weight-800 mb-4 text-center text-lowercase  textCapitilized">
                {labels.deleteAccount} ?
              </h4>
              <p className="text-center">{labels?.deleteAccountText}</p>
            </div>
          </div>
        </div>
        <div className="pb-5 px-5 d-flex gap-3">
          <Button
            className="w-100"
            variant="danger"
            onClick={() => setIsDeleteAccountModal(false)}
          >
            {labels.cancel}
          </Button>
          <Button
            onClick={handleClickDeleteAccount}
            disabled={showSpinner}
            className="w-100"
            variant="primary"
          >
            {showSpinner ? labels.loading : labels.yes}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteAccount;
