import React from "react";
import { Button, Modal } from "react-bootstrap";
import labels from "../config/Labels";
const OrderStepSaveProfileModal = ({
  show,
  onHide,
  saveAsNewProfileFunction,
  profileTitle,
  setProfileTitle,
  showSpinner,
}) => {
  const clickFunction = () => {
    if (profileTitle?.trim() !== 0) {
      saveAsNewProfileFunction();
    }
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      animation={true}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header
        closeButton
        className="d-flex justify-content-center border-0"
      >
        <Modal.Title id="contained-modal-title-vcenter ">
          {labels.profileSave}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body scrollable={true} style={{ width: "500px", height: "200px" }}>
        <div className="mx-3">
          <p className="">
            {labels.nameOfProfileWillBeSavedInMyInformationMyProfiles}
          </p>
          <div>
            <input
              className="form-control"
              type="text"
              name="Title"
              placeholder={labels.profileTitle}
              value={profileTitle}
              onChange={(e) => setProfileTitle(e.target.value)}
            />
          </div>
          <div className="d-flex justify-content-center mt-40">
            <Button
              onClick={() => clickFunction()}
              type="button"
              className="btn btn-primary  gradiant-theme-color w-50"
            >
              {showSpinner ? `${labels.loading}...` : labels.update}
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0"></Modal.Footer>
    </Modal>
  );
};

export default OrderStepSaveProfileModal;
