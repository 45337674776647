import React, { useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import ToastComponent from "../../component/toast";
import { default as Labels, default as labels } from "../../config/Labels";
import { Utility } from "../../config/Utility";
import { Change_Password_Method } from "../../config/api";
import { Images } from "../../constant/images";

const Change_Password_Page = (props) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const { addToast } = useToasts();
  const [fieldIsRequired, setIsFieldRequired] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const changePassedMethod = (e) => {
    e.preventDefault();
    if (oldPassword !== "" && newPassword !== "") {
      let obj = {
        old_password: oldPassword,
        new_password: newPassword,
      };
      Change_Password_Method(
        obj,
        (value) => {
          setShowSpinner(value);
        },
        (value) => {
          setSuccess(value);
        },
        (value) => {
          setMessage(value);
          addToast(value);
        },
        (value) => {
          // setShowToast(value);
        },
        () => {
          props.passwordModal();
        }
      );
    } else {
      setIsFieldRequired(true);
    }
  };

  const closeToast = () => {
    setShowToast(false);
  };

  return (
    <div className="row my-3 ">
      <div className="col-12 col-lg-11 mx-auto text-left ">
        <div className="text-center">
          <img src={Images.circleLogo.default} className="circlelogo" />
        </div>
        <h4 className="font-weight-800 mb-4 text-center text-lowercase  textCapitilized">
          {labels.createNewPassword}
        </h4>
        <form onSubmit={changePassedMethod}>
          <div className="form-group">
            <label
              for="emailAddress"
              className="text-lowercase  textCapitilized"
            >
              {labels.oldPass}
            </label>
            <InputGroup>
              <FormControl
                type={showOldPassword ? "text" : "password"}
                className="form-control"
                id="oldPassword"
                required=""
                placeholder={labels.oldPass}
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
              />{" "}
              <InputGroup.Text>
                <svg
                  // style={{position:"absolute"}}
                  onMouseDown={() => {
                    setShowOldPassword(true);
                  }}
                  onMouseUp={() => {
                    setShowOldPassword(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-eye-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
              </InputGroup.Text>
            </InputGroup>

            {fieldIsRequired === true && oldPassword === "" ? (
              <p style={{ color: "red" }}>{labels.validateField}</p>
            ) : (
              ""
            )}
          </div>
          <div className="form-group">
            <label
              for="loginPassword"
              className="text-lowercase  textCapitilized"
            >
              {labels.enterNewPass}
            </label>
            <InputGroup>
              <FormControl
                type={showPassword ? "text" : "password"}
                className="form-control"
                id="loginPassword"
                required=""
                placeholder={labels.enterNewPass}
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />{" "}
              <InputGroup.Text>
                <svg
                  // style={{position:"absolute"}}
                  onMouseDown={() => {
                    setShowPassword(true);
                  }}
                  onMouseUp={() => {
                    setShowPassword(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-eye-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
              </InputGroup.Text>
            </InputGroup>
            {fieldIsRequired === true && newPassword === "" ? (
              <p style={{ color: "red" }}>{Labels.validateField}</p>
            ) : (
              ""
            )}
            {newPassword?.length !== 0 &&
              !Utility?.validatePassword(newPassword) && (
                <p style={{ color: "red" }}>{labels.passwordValidationText}</p>
              )}
          </div>
          <div className="form-group">
            <label
              for="loginPassword"
              className="text-lowercase  textCapitilized"
            >
              {labels.confirmPassword}
            </label>
            <InputGroup>
              <FormControl
                type={showConfirmPassword ? "text" : "password"}
                className="form-control"
                id="ConfirmPassword"
                required=""
                placeholder={labels.enterConfirmPassword}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setIsFieldRequired(true);
                }}
              />{" "}
              <InputGroup.Text>
                <svg
                  onMouseDown={() => {
                    setShowConfirmPassword(true);
                  }}
                  onMouseUp={() => {
                    setShowConfirmPassword(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-eye-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
              </InputGroup.Text>
            </InputGroup>
            {fieldIsRequired === true && confirmPassword === "" ? (
              <p style={{ color: "red" }}>{labels.validateField}</p>
            ) : (
              fieldIsRequired === true &&
              newPassword !== confirmPassword && (
                <p style={{ color: "red" }}>
                  {labels.confirmationPasswordValidation}
                </p>
              )
            )}
          </div>
          <div className="form-group d-flex justify-content-center">
            <button
              className="btn btn-success btn-primary w-50 my-4"
              type="submit"
              onClick={changePassedMethod}
              data-toggle="modal"
              data-target="#terms"
              data-dismiss="modal"
            >
              {showSpinner === true ? `${labels.loading}...` : labels.update}
            </button>
          </div>
        </form>
      </div>
      {showToast === true ? (
        <ToastComponent
          message={message}
          toastClose={closeToast}
          success={success}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Change_Password_Page;
