import React, { useState } from "react";
import { useContext } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Dropdown, Form, Modal, ModalBody } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useToasts } from "react-toast-notifications";
import validator from "validator";
import { WorkWithUsModalContext } from "../App";
import { Work_With_Us } from "../config/api";
import labels from "../config/Labels";
import { Images as Icon } from "../constant/images";
import User_Login from "../screen/session/login";
import User_Registration from "../screen/session/signup";
import ToastComponent from "./toast";
import {
  docudocAndroidUrl,
  docudociosdUrl,
  facebookPageUrl,
  fiveBranches,
  instagramPageUrl,
  linkedInPageUrl,
} from "../constant";

export default function Footer({}) {
  const history = useHistory();
  const { push } = useHistory();

  const workWithUsModalVisible = useContext(WorkWithUsModalContext);
  const { modalDispatch } = workWithUsModalVisible;

  const [show, setShow] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [number, setnumber] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [validate, setValidate] = React.useState(false);
  const [showToast, setShowToast] = React.useState("");
  const [value, setValue] = useState("");
  const [showRegister, setShowRegister] = useState(false);
  const { addToast } = useToasts();
  const [register, setRegister] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const [emailValidation, setEmailvalidation] = useState(false);

  const [emailError, setEmailError] = useState("");
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
      setEmailvalidation(false);
    } else {
      setEmailError("Enter valid Email!");
      setEmailvalidation(true);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePageChange = () => {
    window.open(facebookPageUrl, "_blank");
  };
  const handlePageChangeLinkedIn = () => {
    window.open(linkedInPageUrl, "_blank");
  };
  const handlePageChangeIntagram = () => {
    window.open(instagramPageUrl, "_blank");
  };
  const handlePageChangeTwitter = () => {
    window.open("https://twitter.com/", "_blank");
  };
  const closeToast = () => {
    setShowToast(false);
  };

  let token = localStorage.getItem("usertoken");
  const changeForm = () => {
    setIsLogin(!isLogin);
  };

  const selectBranch = (branch = {}) => {
    const { branch_id } = branch;
    localStorage.setItem("branchId", branch_id);
    push({
      pathname: `/${"service"}/${branch_id}`,
      state: {
        branch_id: branch_id,
        item: branch,
        branches: fiveBranches,
      },
    });
  };

  return (
    <>
      {showToast === true && (
        <ToastComponent
          message={message}
          toastClose={closeToast}
          success={success}
        />
      )}
      <Modal onHide={() => {}} show={showRegister} size="auto">
        <ModalBody className="bg-white p-0 border-radius-10">
          {!isLogin ? (
            <User_Login
              change={changeForm}
              hideModal={() => {
                setShowRegister(false);
              }}
            />
          ) : (
            <User_Registration
              change={changeForm}
              hideModal={() => {
                setShowRegister(false);
              }}
            />
          )}
        </ModalBody>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton className="d-flex justify-content-center">
          <Modal.Title className="d-flex justify-content-around" id="">
            {labels.WorkWithUs}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form.Control
              type="text"
              placeholder={labels.enterfirstName}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            {/* {email.length > 0 && setValidate(false)} */}
            {name.length <= 0 && validate && (
              <p className="text-danger">{labels.validateField}</p>
            )}
            <br />
            <Form.Control
              type="email"
              value={email}
              placeholder={labels.emails}
              onChange={(e) => {
                validateEmail(e);
                setEmail(e.target.value.trim(""));
              }}
              required
            />
            {validate && (
              <p className="text-danger">{labels.emailValidation}</p>
            )}
            <br />
            <input
              className="form-control"
              maxLength={20}
              autoComplete={false}
              type="tel"
              placeholder={labels.phoneNumber}
              value={value}
              onChange={(e) => {
                const re = /^[0-9-+\b]+$/;

                // if value is not blank, then test the regex

                if (e.target.value === "" || re.test(e.target.value)) {
                  setValue(e.target.value);
                }
              }}
            />
            {/* <PhoneInput
              style={{ width: "100%", marginRight: "5px" }}
              international
              maxLength={20}
              countryCallingCodeEditable={false}
              defaultCountry="ES"
              value={value}
              onChange={setValue}
              autoComplete={false}
            /> */}
            {value?.length < 7 && validate && (
              <p className="text-danger">{labels.validateField}</p>
            )}

            <br />
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={labels.description}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              required
            />
            {!description.length && validate && (
              <p className="text-danger">{labels.validateField}</p>
            )}
          </>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around">
          <Button
            variant="primary"
            onClick={() => {
              if (
                name !== "" &&
                email !== "" &&
                value?.length >= 7 &&
                description !== ""
              ) {
                setValidate(false);
                Work_With_Us(
                  {
                    name: name,
                    email: email,
                    number: value,
                    description: description,
                  },
                  (value) => {
                    setSuccess(value);
                    setName("");
                    setEmail("");
                    setValue("");
                    setDescription("");
                  },
                  (value) => {
                    setMessage(value);
                    addToast(value, {
                      placement: "top-center",
                      autoDismiss: 1000,
                      appearance:
                        value ==
                        "Thanks for messaging us we will contact you soon"
                          ? "success"
                          : "error",
                    });
                    if (
                      value ===
                      "Thanks for messaging us we will contact you soon"
                    ) {
                      setTimeout(() => {
                        setShow(false);
                      }, 2000);
                    }
                  },
                  (value) => {
                    setShowToast(value);
                  }
                );

                setDescription("");
                setEmail("");
                setName("");
                setnumber("");
                setValidate(false);
              } else {
                setValidate(true);

                //  setName(name.length===0);
                //  setEmail(email===0);
                //  setnumber(number===0);
                //  setDescription(description===0)
              }
            }}
          >
            {showToast ? labels.loading : labels.sendRequest}
          </Button>
        </Modal.Footer>
      </Modal>
      <footer className="page_footer corner-footer s-parallax py-5">
        <div className="container">
          <ScrollAnimation
            initiallyVisible
            animateIn="fadeInUp"
            animateOnce={true}
          >
            <div className="row">
              <div className="col-lg-3 col-md-4 " data-animation="fadeInUp">
                <img
                  className="margin-negative logofooter cursorPointer"
                  src={Icon.logoLight.default}
                  alt="logo"
                  onClick={() => push("/")}
                />
                <ul className="px-0 pt-1">
                  <li>
                    <a className="links text-white" href="/about">
                      {labels.aboutUs}
                    </a>
                  </li>
                  <li onClick={() => modalDispatch("open")}>
                    <span className="links text-white cursor-pointer-class">
                      {labels.WorkWithUs}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-4  " data-animation="fadeInUp">
                <div>
                  <h4 className=" ml-3 text-white">{labels?.footerService}</h4>
                  <ul className="::">
                    {fiveBranches?.map((item) => {
                      return (
                        <li
                          key={item?.branch_id}
                          className="links"
                          onClick={() => selectBranch(item)}
                        >
                          <span
                            className="text-white"
                            style={{ cursor: "pointer" }}
                          >
                            {
                              item?.[
                                localStorage.getItem("primaryLanguage") ===
                                "english"
                                  ? "name"
                                  : "name_sp"
                              ]
                            }
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 " data-animation="fadeInUp">
                <div>
                  <h4 className="ml-3 text-white">{labels.LegalTerms}</h4>
                  <ul className="::">
                    <li className="links">
                      <Link to="/termscondition" className="text-white">
                        {labels.termConditionCapital}
                      </Link>
                    </li>
                    <li className="links">
                      <Link to="/privacy" className="text-white">
                        {labels.privacy}
                      </Link>
                    </li>
                    <li className="links">
                      <Link to="/cookies" className="text-white">
                        {labels?.cookiesPolicies}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 " data-animation="fadeInUp">
                <div>
                  <h4 className="ml-3 text-white">{labels.FollowUs}</h4>
                  <ul className=":: cursorPointer">
                    <li onClick={handlePageChange} className="links">
                      <a className="text-white">{labels.facebook}</a>
                    </li>
                    <li onClick={handlePageChangeIntagram} className="links">
                      <a className="text-white">{labels.instagram}</a>
                    </li>
                    <li onClick={handlePageChangeLinkedIn} className="links">
                      <a className="text-white">{labels.linkedIn}</a>
                    </li>
                    <li onClick={handlePageChangeTwitter} className="links">
                      <a className="text-white">{labels.twitter}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4" data-animation="fadeInUp">
                <div>
                  <ul className="nav sf-menu sf-js-enabled sf-arrows footer">
                    <li>
                      <a href={docudociosdUrl} target="_blank">
                        <img src={Icon.appstore.default} />
                      </a>
                    </li>
                    <li>
                      <a href={docudocAndroidUrl} target="_blank">
                        <img src={Icon.playstore.default} />
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <Dropdown className=" info-style p-0">
                    <Dropdown.Toggle
                      as="div"
                      className="mb-1  dummyText rounded"
                      id="dropdown-basic "
                      variant="transparent"
                    >
                      {labels.changeLanguage}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          localStorage.setItem("primaryLanguage", "english");
                          history.go(0);
                        }}
                      >
                        <small>English</small>
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          localStorage.setItem("primaryLanguage", "spanish");
                          history.go(0);
                        }}
                      >
                        <small>Spanish</small>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </footer>
    </>
  );
}
