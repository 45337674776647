import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Images } from "../constant/images";
import { useHistory } from "react-router-dom";
import { useStoreItem } from "../config/Store";
import { Form, Modal, Button } from "react-bootstrap";

const BranchesMenu = (props) => {
  const { push } = useHistory();
  const [show, setShow] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [number, setnumber] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [showToast, setShowToast] = React.useState("");
  const [validate, setValidate] = React.useState(false);
  const [value, setValue] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [activeMenu, setActiveMenu] = useState(props?.activeMenu);
  const [activeLawyer, setActiveLawyer] = useState(true);
  const [ActiveAsseement, setActiveAssesment] = useState(true);
  const [activeDocs, setActiveDocs] = useState(true);
  let Branch_id = localStorage.getItem("branchId");
  const currentLanguage = localStorage.getItem("primaryLanguage");

  return (
    <div>
      {props?.branches?.map((item, index) => (
        <div className="">
          <div className="dashboard-left-links ">
            <a
              style={{ cursor: "pointer", borderRadius: 0 }}
              onClick={() => {
                setActiveMenu("0");
                props?.BranchName(item?.name);
                localStorage.setItem("branchId", item?.branch_id);
                if (props.currentActiveFilter?.name == "alphabetically") {
                  props.GetServicesByBranchId(
                    item?.branch_id,
                    item?.service_area_id
                  );
                } else {
                  props.setActiveBranchId(item?.branch_id);
                  // props.GetServicebranchIdServiceIdBeneficiaryId(
                  //   item?.branch_id,
                  //   item?.service_area_id,
                  //   props.currentActiveFilter?.id,
                  // );

                  // activeBranchId,
                  // serviceAreaId,
                  // activeFilter.id
                }
              }}
              to={{ pathname: "/1/myorder", state: { label: "" } }}
              className={`${index === 0 ? "border-top" : ""} ${
                Branch_id == item?.branch_id ? "active" : ""
              } user-item border-bottom-0 `}
            >
              <img src={item?.avatar} className="bg-white" />
              {item?.[currentLanguage === "english" ? "name" : "name_sp"]}
            </a>
            <hr className="my-0 w-75"></hr>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BranchesMenu;
