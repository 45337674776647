import React, { useState } from "react";
import renderHTML from "react-render-html";
import { Modal, ModalBody, Overlay, Tooltip } from "react-bootstrap";

const CustomTooltip = (props) => {
  return (
    <Overlay target={props.target} show={props.show} placement="right">
      <Tooltip id="overlay-example">{renderHTML(props.info == null ? "": props.info)}</Tooltip>
    </Overlay>
  );
};

export default CustomTooltip;
