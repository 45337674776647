import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import SpecialServiceButton from "../../component/SpecialServiceButton";
import { Get_Special_Services } from "../../config/api";
import labels from "../../config/Labels";

const SpecialServices = () => {
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [specialPrices, setSpecialPrices] = useState([]);

  // get PrimaryLanguge
  let lang = localStorage.getItem("primaryLanguage");

  useEffect(() => {
    Get_Special_Services(
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setSpecialPrices(value);
      }
    );
  }, []);

  return (
    <Container className="w-100">
      <Row>
        {specialPrices?.map((item, index) => {
          return (
            <SpecialServiceButton
              key={index}
              path={`documentaion`}
              states={item}
              image={item?.avatar}
              title={lang == "english" ? item?.name : item?.name_sp}
              desc={lang == "english" ? item.info : item?.info_sp}
              assessment={true}
              price={item?.original_price}
              discount={item?.reduced_price}
              activeFilter={item?.service_id}
              avatar={item?.avatar}
              item={item}
              servicePak={item?.service_pack_type}
              supplementory={item?.service_supplementary_fees}
              supplementaryFees={item.supplementary_fees}
            />
          );
        })}
      </Row>
      {!specialPrices.length && (
        <h4 className="text-center  text-secondary ">{labels.noRecordsFound}</h4>
      )}

      {/* <Modal
        show={showMessagePopup}
        onHide={() => setShowMessagePopup(false)}
        size="lg"
      >
        <ModalBody className="bg-white p-0 border-radius-10 alignItemsCenter">
          <button
            type="button"
            class="close text-danger absolute"
            data-dismiss="modal"
            onClick={() => setShowMessagePopup(false)}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="richTextContainer">
            <div className="textAlignCenter mt-20 mb-20">
              <a
                className="btn btn-primary"
                data-toggle="modal"
                onClick={() => {}}
              >
                Submit
              </a>
            </div>
          </div>
        </ModalBody>
      </Modal> */}
    </Container>
  );
};

export default SpecialServices;
