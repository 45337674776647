// import { Modal } from 'bootstrap';
import React, { useState, useEffect } from "react";
import { OrderItem } from "../../component";
import { Tab, Tabs } from "react-bootstrap";
import { Images } from "../../constant/images";
import { Get_Orders_Method } from "../../config/api";
import ToastComponent from "../../component/toast";
import labels from "../../config/Labels";
import { useHistory } from "react-router-dom";
import { GetAllOrders } from "../../store/actions/orders";
import { useDispatch, useSelector } from "react-redux";

export default function MyOrder(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const ordersListing = useSelector((state) => state?.orders?.orders);
  const orderLoading = useSelector((state) => state?.orders?.loading);

  const [pendingOrders, setPendingOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [disputeOrder, setDisputeOrder] = useState([]);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [tab, setTab] = useState();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(
      GetAllOrders((failure) => {
        setMessage(failure);
        setSuccess(false);
        setShowToast(true);
      })
    );
  }, []);
  let lang = localStorage.getItem("primaryLanguage");

  useEffect(() => {
    // let ordersArray = [
    //   ...(ordersListing?.pending_orders || []),
    //   ...(ordersListing?.disputed_orders || []),
    //   ...(ordersListing?.dispute_orders || []),
    //   ...(ordersListing?.completed_orders || []),
    //   ...(ordersListing?.cancelled_orders || []),
    // ];
    // setOrders(ordersArray);

    setPendingOrders(ordersListing?.pending_orders);
    setCancelledOrders(ordersListing?.cancelled_orders);
    setCompletedOrders(ordersListing?.completed_orders);
    setDisputeOrder(ordersListing?.dispute_orders);
  }, [ordersListing]);
  useEffect(() => {
    setShowSpinner(orderLoading);
  }, [orderLoading]);
  // useEffect(() => {
  //   Get_Orders_Method(
  //     (value) => {
  //       setShowSpinner(value);
  //     },
  //     (value) => {
  //       setSuccess(value);
  //     },
  //     (value) => {
  //       setMessage(value);
  //     },
  //     (value) => {
  //       setShowToast(value);
  //     },
  //     (value) => {
  //       setPendingOrders(value.pending_orders);
  //       setCancelledOrders(value.cancelled_orders);
  //       setCompletedOrders(value.completed_orders);
  //       setDisputeOrder(value.dispute_orders);
  //     }
  //   );
  // }, []);

  const closeToast = () => {
    setShowToast(false);
  };

  useEffect(() => {
    const activeTab = localStorage.getItem("orderCurrentActiveTab");
    // setTab(
    //   props?.location?.state?.label == "back"
    //     ? "menu1"
    //     : props?.location?.state?.label == "cancel"
    //     ? "menu2"
    //     : props.location?.state?.taber == "completed"
    //     ? "menu1"
    //     : props.location?.state?.taber == "cancel"
    //     ? "menu2"
    //     : props.location?.state?.label == "dispute"
    //     ? "menu3"
    //     : activeTab || "home"
    // );
    setTab(activeTab)
  }, []);
  return (
    <section className="pb-150 ls" id="services" data-animation="fadeInUp">
      <div className="wrapper-main padd60 pb-5">
        <div className="container">
          {props?.location?.state?.label === "billing" && (
            <div className="cursorPointer">
              <img
                src={Images.backArrow.default}
                style={{ width: "40px", height: "40px" }}
                className=""
                onClick={() => {
                  history.goBack();
                }}
              />{" "}
            </div>
          )}

          <div className="dashboard-right pt-0">
            <div className="row">
              <div className="col-lg-12 col-md-12 ">
                <div className="tabs-order-component myOrder">
                  {showToast === true ? (
                    <ToastComponent
                      message={message}
                      toastClose={closeToast}
                      success={success}
                    />
                  ) : null}
                  {props?.location?.state?.label === "billing" && (
                    <h4>{labels.billingInformation}</h4>
                  )}
                  {props?.location?.state?.label === "billing" ? (
                    <div className="billing-info-container">
                      {showSpinner ? (
                        labels.loading
                      ) : completedOrders.length !== 0 ? (
                        completedOrders?.map((item, index) => {
                          return (
                            <OrderItem
                              key={index}
                              path={"orderdetail"}
                              image={item?.service?.avatar}
                              name={
                                item.service?.[
                                  lang === "english" ? "name" : "name_sp"
                                ]
                              }
                              id={item?.order_id}
                              des={item?.service?.info}
                              price={item?.service?.original_price}
                              discount={item.service?.reduced_price}
                              promo={item?.is_promo}
                              servicePak={item?.service?.service_pack_type}
                              info={item?.service?.info}
                              status={item?.status}
                              supplementory={item?.supplementary_fees}
                              item={item}
                              date={item?.updated_at}
                            />
                          );
                        })
                      ) : (
                        <p className="pb-10 textAlignCenter">
                          {labels.noOrdersCompleted}
                        </p>
                      )}
                    </div>
                  ) : (
                    <Tabs
                      defaultActiveKey="home"
                      activeKey={tab}
                      onSelect={(e) => {
                        setTab(e);
                        localStorage.setItem("orderCurrentActiveTab", e);
                      }}
                      id="uncontrolled-tab-example"
                      className="nav nav-tabs myOrder mb-10 d-flex justify-content-between"
                    >
                      <Tab eventKey="home" title={labels.inProgress}>
                        {showSpinner ? (
                          labels.loading
                        ) : pendingOrders.length !== 0 ? (
                          pendingOrders?.map((item, index) => {
                            return (
                              <OrderItem
                                key={index}
                                path={"orderdetail"}
                                image={item.service.avatar}
                                name={
                                  item.service?.[
                                    lang === "english" ? "name" : "name_sp"
                                  ]
                                }
                                id={item.order_id}
                                des={
                                  item?.service?.[
                                    lang === "english" ? "info" : "info_sp"
                                  ]
                                }
                                price={item?.service?.original_price}
                                discount={item.service?.reduced_price}
                                promo={item.is_promo}
                                servicePak={item?.service?.service_pack_type}
                                info={
                                  item?.service?.[
                                    lang === "english" ? "info" : "info_sp"
                                  ]
                                }
                                status={item?.status}
                                serviceId={item?.service_id}
                                service={item?.service}
                                serviceProvider={item?.service_provider}
                                supplementory={item?.supplementary_fees}
                                item={item}
                                orderCard={true}
                                date={item?.created_at}
                              />
                            );
                          })
                        ) : (
                          <p className="pb-10 textAlignCenter">
                            {labels.noOrdersPending}
                          </p>
                        )}
                      </Tab>
                      <Tab eventKey="menu1" title={labels.completed}>
                        {showSpinner ? (
                          labels.loading
                        ) : completedOrders.length !== 0 ? (
                          completedOrders?.map((item, index) => {
                            return (
                              <OrderItem
                                key={index}
                                path={"orderdetail"}
                                image={item.service.avatar}
                                name={
                                  item.service?.[
                                    lang === "english" ? "name" : "name_sp"
                                  ]
                                }
                                id={item.order_id}
                                des={
                                  item?.service?.[
                                    lang === "english" ? "info" : "info_sp"
                                  ]
                                }
                                price={item?.service?.original_price}
                                discount={item.service?.reduced_price}
                                promo={item.is_promo}
                                servicePak={item?.service?.service_pack_type}
                                info={
                                  item?.service?.[
                                    lang === "english" ? "info" : "info_sp"
                                  ]
                                }
                                status={item?.status}
                                data={item?.data}
                                serviceId={item?.service_id}
                                service={item?.service}
                                serviceProvider={item?.service_provider}
                                supplementory={item?.supplementary_fees}
                                item={item}
                                orderCard={true}
                                date={item?.updated_at}
                              />
                            );
                          })
                        ) : (
                          <p className="pb-10 textAlignCenter">
                            {labels.noOrdersCompleted}
                          </p>
                        )}
                      </Tab>
                      <Tab eventKey="menu2" title={labels.cancelled}>
                        {showSpinner ? (
                          labels.loading
                        ) : cancelledOrders.length !== 0 ? (
                          cancelledOrders.map((item, index) => {
                            {
                            }

                            return (
                              <OrderItem
                                key={index}
                                path={"orderdetail"}
                                image={item.service.avatar}
                                name={
                                  item.service?.[
                                    lang === "english" ? "name" : "name_sp"
                                  ]
                                }
                                id={item.order_id}
                                des={
                                  item?.service?.[
                                    lang === "english" ? "info" : "info_sp"
                                  ]
                                }
                                price={item?.service?.original_price}
                                discount={item.service?.reduced_price}
                                promo={item.is_promo}
                                servicePak={item?.service?.service_pack_type}
                                info={
                                  item?.service?.[
                                    lang === "english" ? "info" : "info_sp"
                                  ]
                                }
                                serviceId={item?.service_id}
                                status={item?.status}
                                data={item?.data}
                                service={item?.service}
                                serviceProvider={item?.service_provider}
                                supplementory={item?.supplementary_fees}
                                item={item}
                                orderCard={true}
                                date={item?.updated_at}
                              />
                            );
                          })
                        ) : (
                          <p className="pb-10 textAlignCenter">
                            {labels.noOrdersCancelled}
                          </p>
                        )}
                      </Tab>
                      <Tab eventKey="menu3" title={labels.dispute}>
                        {showSpinner ? (
                          labels.loading
                        ) : disputeOrder.length !== 0 ? (
                          disputeOrder.map((item, index) => {
                            return (
                              <OrderItem
                                key={index}
                                path={"orderdetail"}
                                image={item.service.avatar}
                                name={
                                  item.service?.[
                                    lang === "english" ? "name" : "name_sp"
                                  ]
                                }
                                id={item.order_id}
                                des={
                                  item?.service?.[
                                    lang === "english" ? "info" : "info_sp"
                                  ]
                                }
                                price={item?.service?.original_price}
                                discount={item.service?.reduced_price}
                                promo={item.is_promo}
                                servicePak={item?.service?.service_pack_type}
                                info={
                                  item?.service?.[
                                    lang === "english" ? "info" : "info_sp"
                                  ]
                                }
                                status={item?.status}
                                serviceId={item?.service_id}
                                service={item?.service}
                                serviceProvider={item?.service_provider}
                                supplementory={item?.supplementary_fees}
                                dispute={false}
                                item={item}
                                orderCard={true}
                              />
                            );
                          })
                        ) : (
                          <p className="pb-10 textAlignCenter">
                            {labels.no} {labels.dispute}
                          </p>
                        )}
                      </Tab>
                    </Tabs>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
