import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Dropdown, Row, Spinner } from "react-bootstrap";
import ToastComponent from "../component/toast";
import {
  Get_Branches_By_Service_Area_Id,
  Get_Branches_By_Service_Area_Id_By_Beneficiary_Id,
} from "../config/api/index";
import labels from "../config/Labels";
import "./component.css";
import ServiceAreasMenu from "./serviceAreasMenu";
import SpecificServiceButton from "./SpecificServiceButton";
import { Helmet } from "react-helmet";
////////secreen for first level branches
const dropdown = [
  {
    id: "70ead172-1c3e-424d-b250-061e4f2decd5",
    name: "relevance",
    type: "priority",
  },
  {
    id: "1",
    name: "alphabetically",
    type: "alphabet",
  },

  {
    id: "65e5f162-4af8-4442-a55a-e8cbbf09bb70",
    name: "relevanceForSelf",
    type: "priority",
  },
  {
    id: "0e6e1528-78bc-45cb-a222-278243f10810",
    name: "relevanceForBusiness",
    type: "priority",
  },
];

// const {ser} = Images;
export default function ServiceArea(props) {
  const [serviceAreaTabs, setServiceAreaTabs] = useState(1);
  const [branches, setBranches] = useState([]);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [dropdownName, setDropDownName] = useState("relevance");
  const [activeFilter, setActiveFilter] = useState({
    id: "70ead172-1c3e-424d-b250-061e4f2decd5",
    name: "relevance",
    type: "priority",
  });
  const [activeServiceAreaId, setActiveServiceAreaId] = useState(
    props?.serviceAreas?.[props.activeMenu]?.service_area_id
  );

  const [nameOfServiceArea, setNameOfServiceArea] = useState(
    props?.ServiceAreaName
  );
  let menukey = localStorage.getItem("menukey");
  let serviceAreaId =
    localStorage.getItem("serviceAreaId") ||
    "bd4b5a26-521d-4904-9236-e1772ba63034";

  const [activeBeneficiaryId, setActiveBeneficiaryId] = useState(
    "70ead172-1c3e-424d-b250-061e4f2decd5"
  );

  const sortList = ["n3", "n2", "n1"];

  const areasName = (value) => {
    setNameOfServiceArea(value);
  };
  useEffect(() => {
    if (activeFilter.type == dropdown[1].type) {
      setActiveBeneficiaryId("");
      GetBranchesByServiceAreaIdForDropDown(serviceAreaId);
    } else {
      setActiveBeneficiaryId(activeFilter?.id);
      GetBranchesByServiceAreaIdAndBeneficiaryId(
        serviceAreaId,
        activeFilter?.id
      );
    }
  }, [activeFilter]);

  useEffect(() => {
    if (props?.serviceAreas?.[0]?.service_area_id !== undefined) {
      setActiveServiceAreaId(
        props?.serviceAreas?.[props.activeMenu]?.service_area_id
      );
    } else {
      setActiveServiceAreaId(props?.serviceAreas?.[menukey]?.service_area_id);
    }
    GetBranchesByServiceAreaIdAndBeneficiaryId(
      props?.serviceAreas?.[menukey]?.service_area_id || serviceAreaId,
      activeBeneficiaryId,

      // props?.beneId,
      (value) => setMessage(value),
      (value) => setShowToast(value),
      (value) => setSuccess(value),
      (value) => {
        setBranches(value);
      }
    );
  }, [props?.serviceAreas]);

  const GetBranchesByServiceAreaId = async (id, beneficiary_id) => {
    await setActiveServiceAreaId(id);

    Get_Branches_By_Service_Area_Id_By_Beneficiary_Id(
      id,
      beneficiary_id,

      // props?.beneId,
      (value) => setMessage(value),
      (value) => setShowToast(value),
      (value) => setSuccess(value),
      (value) => {
        setBranches(value);
      }
    );
  };

  const GetBranchesByServiceAreaIdAndBeneficiaryId = (id, beneficiary_id) => {
    Get_Branches_By_Service_Area_Id_By_Beneficiary_Id(
      id,
      beneficiary_id,
      (value) => setMessage(value),
      (value) => setShowToast(value),
      (value) => setSuccess(value),
      (value) => {
        setBranches(value);
      }
    );
  };
  const GetBranchesByServiceAreaIdForDropDown = (id) => {
    Get_Branches_By_Service_Area_Id(
      id,
      (value) => setMessage(value),
      (value) => setShowToast(value),
      (value) => setSuccess(value),
      (value) => {
        setBranches(value);
      }
    );
  };

  const closeToast = () => {
    setShowToast(false);
  };

  let token = localStorage.getItem("usertoken");
  let branchName = localStorage.getItem("branchName");
  let lang = localStorage.getItem("primaryLanguage");

  return (
    <Container>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <Row
        className="s-pt-30 s-pb-3 service-item2"
        style={{ minHeight: "100vh" }}
        data-animation="fadeInUp"
      >
        <>
          <Col xs={12} lg={12} className=" pt-60">
            {/* col-12 col-lg-4 col-md-12 col-sm-12 */}
            <Col sm={12} className="">
              <div className=" d-flex justify-content-start justify-content-md-between flex-wrap flex-column flex-md-row">
                <div>
                  <h4 className="mt-4 custom-font text-white text-left">
                    {labels.fastSolutionToYourRequestsAndInquiries}
                  </h4>
                </div>
                <div className=" d-flex justify-content-end">
                  <div className="align-items-center d-flex justify-content-end serviceAreaDropdown">
                    <p style={{ color: "#ffff" }} className="mb-0">
                      {labels?.[dropdownName]}
                    </p>
                    <Dropdown className="beneficiary-filter">
                      <Dropdown.Toggle></Dropdown.Toggle>

                      <Dropdown.Menu>
                        {dropdown?.map((item) => (
                          <Dropdown.Item
                            onClick={() => {
                              setActiveFilter(item);
                              setDropDownName(item.name);
                            }}
                          >
                            {labels?.[item.name]}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </Col>
            <div className=" d-lg-block divider-20"></div>

            <div className=" mb-40 ">
              <div
                className="tab-pane active show "
                id="tabs-1"
                role="tabpanel"
              >
                <div className="row">
                  {showToast === true ? (
                    <ToastComponent
                      message={message}
                      toastClose={closeToast}
                      success={success}
                    />
                  ) : null}
                  {activeFilter.type == "alphabet" &&
                    branches
                      ?.sort(function (a, b) {
                        return a?.[
                          lang == "english" ? "name" : "name_sp"
                        ].localeCompare(
                          b?.[lang == "english" ? "name" : "name_sp"]
                        );
                      })
                      .filter((branch) => branch?.parent_branch_id === null)
                      .map((t) => {
                        return (
                          <SpecificServiceButton
                            className="shadow"
                            path={"service"}
                            image={t.avatar}
                            key={t.branch_id}
                            title={lang == "english" ? t.name : t?.name_sp}
                            desc={lang == "english" ? t.info : t?.info_sp}
                            branch_id={t.branch_id}
                            selectedTab={serviceAreaTabs}
                            activeFilter={dropdownName}
                            serviceAreaId={activeServiceAreaId}
                            benificiaryId={activeBeneficiaryId}
                            branches={branches}
                            level2={branches
                              ?.filter(
                                (branches) =>
                                  branches?.parent_branch_id === null &&
                                  t.branch_id == branches?.parent_branch_id
                              )
                              ?.map((item) => item)}
                          />
                        );
                      })}

                  {activeFilter.type != "alphabet" &&
                    branches
                      ?.sort(function (a, b) {
                        return a?.priority - b?.priority;
                      })
                      ?.filter((branch) => branch?.parent_branch_id === null)
                      ?.map((t) => {
                        return (
                          <SpecificServiceButton
                            path={"service"}
                            image={t.avatar}
                            title={lang == "english" ? t?.name : t?.name_sp}
                            desc={lang == "english" ? t?.info : t?.info_sp}
                            key={t.branch_id}
                            branch_id={t.branch_id}
                            selectedTab={serviceAreaTabs}
                            activeFilter={dropdownName}
                            serviceAreaId={t?.service_area_id}
                            benificiaryId={activeBeneficiaryId}
                            branches={branches}
                            level2={branches
                              ?.filter(
                                (branches) =>
                                  branches?.parent_branch_id !== null &&
                                  t?.branch_id == branches?.parent_branch_id
                              )
                              .map((item) => item)}
                          />
                        );
                      })}
                </div>
                {!branches.length && (
                  <h4 className="text-center text-secondary  mt-60 mx-50">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden text-white">
                        {labels.loading}
                      </span>
                    </Spinner>
                  </h4>
                )}
              </div>
            </div>
          </Col>
        </>
      </Row>
    </Container>
  );
}
