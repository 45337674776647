import React, { useState } from "react";
import {
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Add_Credit_Card_Method } from "../../config/api";
import ToastComponent from "../../component/toast";
import labels from "../../config/Labels";

const CreditCardScreen = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isValid, setIsValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const closeToast = () => {
    setShowToast(false);
  };

  const submitCardDetail = async () => {
    const cardNumber = elements.getElement("cardNumber");
    try {
      let token = await stripe.createToken(cardNumber);
      if (token.error) {
        alert(token.error.message);
      } else {
        let obj = {
          card_id: token.token.card.id,
          stripe_token: token.token.id,
          brand: token.token.card.brand,
          expiry_date: `${token.token.card.exp_month}/${token.token.card.exp_year}`,
          last_4_digit: token.token.card.last4,
        };
        Add_Credit_Card_Method(
          (value) => {
            setShowSpinner(value);
          },
          obj,
          (value) => {
            setSuccess(value);
          },
          (value) => {
            setMessage(value);
          },
          (value) => {
            setShowToast(value);
          },
          () => {
            props.modalClose();
            props.getCreditCards();
          }
        );
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleChange = (change) => {
    setIsValid(change.complete);
  };

  const iframeStyles = {
    base: {
      color: "#000000",
      fontSize: "16px",
      iconColor: "#CCCCCC",
      "::placeholder": {
        color: "#CCCCCC",
      },
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE",
    },
    complete: {
      iconColor: "#CCCCCC",
      color: "#87bbfd",
    },
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true,
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div>
        <h6 className="ProximaNovaRegular mb-0 mt-3" style={{ textAlign: "left", marginLeft: "10px", color: "#000000" }}>
          {labels.cardNumber}
        </h6>

        <CardNumberElement
          options={cardElementOpts}
          className="credit-card-style ml-10"
        />
      </div>
      <div>
        <h6  className="ProximaNovaRegular mb-0 mt-3" style={{ textAlign: "left", marginLeft: "10px", color: "#000000" }}>
        {labels.expirationDate}
        </h6>
        <CardExpiryElement
          options={cardElementOpts}
          className="credit-card-style"
        />
      </div>
      <div style={{}}>
        <h6  className="ProximaNovaRegular mb-0 mt-3" style={{ textAlign: "left", marginLeft: "10px", color: "#000000" }}>
         {labels.cvc}
        </h6>
        <CardCvcElement
          onChange={handleChange}
          options={cardElementOpts}
          className="credit-card-style"
        />
      </div>
      <div style={{ height: "30px" }}></div>

      {isValid ? (
        <>
          <button onClick={submitCardDetail} className="mb-4">
            {showSpinner ? `${labels.loading}...` : labels.addCreditCard}
          </button>
          {showToast === true ? (
            <ToastComponent
              message={message}
              toastClose={closeToast}
              success={success}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default CreditCardScreen;
