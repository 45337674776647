import React, { useEffect, useState } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import validator from "validator";
import { Images } from "../../constant/images";

import ScrollAnimation from "react-animate-on-scroll";
import { Button, Col, Dropdown, Modal, ModalBody, Row } from "react-bootstrap";
import { browserName } from "react-device-detect";
import { Helmet } from "react-helmet";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useToasts } from "react-toast-notifications";
import { CountryCodes } from "../../assets/flags/CountryCodes";
import DynamciInput from "../../component/dynamicInput";
import OrderStepSaveProfileModal from "../../component/orderStepSaveProfileModal";
import ProviderInfoModal from "../../component/providerInfoModal";
import ToastComponent from "../../component/toast";
import labels from "../../config/Labels";
import { useStoreItem } from "../../config/Store";
import { rating, regexForFormatRating } from "../../config/Utility";
import {
  Add_Draft,
  Add_Profile,
  Delete_Draft_Order,
  Edit_Draft_Order,
  GetServiceFormById,
  Get_File_Url_Method,
  Get_Profiles_Method,
  Get_Service_Providers,
} from "../../config/api";

let lang = localStorage.getItem("primaryLanguage");
let urlArray = [];
export default function Documentaion(props) {
  const { addToast } = useToasts();
  const [emailValidation, setEmailvalidation] = useState(false);
  const [emailError, setEmailError] = useState("");
  const validateEmail = (e) => {
    let email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError("");
      setEmailvalidation(false);
    } else {
      setEmailError("Enter valid Email!");
      setEmailvalidation(true);
    }
  };

  const { push } = useHistory();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(1);
  const [information, setInformation] = useState([]);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [check, setCheck] = useState(false);
  const [selected, setSelected] = useState(0);
  const [showModal, setShowModal] = useState(false);
  //firstForm states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [CIF, setCIF] = useState("");
  const [address, setAddress] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [isBusiness, setIsBusiness] = useState(false);
  const [none, setNone] = useState("");
  const [business, SetBusiness] = useState("");
  const [self, setSelf] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  //secondForm States
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [city, setCity] = useState("");
  //thirdForm States
  const [serviceProviders, setServiceProviders] = useState([]);
  //fourthForm States
  const [serviceForm, setServiceForm] = useState([]);
  //finalObj states
  const [personal, setPersonal] = useState("");
  const [region, setRegion] = useState("");
  const [serviceProviderId, setServiceProviderId] = useState("");
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [singleDocument, setSingleDocument] = useState("");
  const [muiltipleDocument, setMuiltipleDocument] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [profileTitle, setProfileTitle] = useState("");
  const { user, updateUser } = useStoreItem();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [validation1, setValidation1] = useState(false);
  const [validation2, setValidation2] = useState(false);
  const [validation3, setValidation3] = useState(false);
  const [validation4, setValidation4] = useState(false);
  const [serviceProviderInfo, setServiceProviderInfo] = useState("");
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  // Declare state variables
  const [focusTxt, setfocusTxt] = useState("");
  const [phoneNO, setPhoneNO] = useState("");

  /////////////////

  const [phoneCode, setPhoneCode] = useState(CountryCodes[0].phoneCode);
  const [countries, setCountries] = useState(CountryCodes);
  const [countriesList] = useState(CountryCodes);
  const [selectedCountry, setSelectedCountry] = useState(CountryCodes[0]);
  const [modalVisible, setModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [draftId, setDraftId] = useState(null);
  const [avatar, setAvatar] = useState(
    props?.location?.state?.draft === "draftorder"
      ? props?.location?.state?.image
      : props.location.state?.avatar
  );
  const [id, setId] = useState(
    props?.location?.state?.draft === "draftorder"
      ? props?.location?.state?.serviceId
      : props.location.state?.acticeFilter
  );
  const [serviceName, setServiceName] = useState(
    props?.location?.state?.draft === "draftorder"
      ? props?.location?.state?.name
      : props.location.state?.name
  );
  // Search Country filter: by country name
  const searchCountryFilter = (text) => {
    const newData = countriesList.filter((item) => {
      const itemData = item.name.toUpperCase();
      const textData = text.toUpperCase();
      // return itemData.indexOf(textData) > -Constants.One;
    });
    setCountries(newData);
  };
  const [isLoading, setIsLoading] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [countrymodalShow, setCountryModalShow] = useState(false);
  const handleCloseCountry = () => setShow(false);
  const handleShowCountry = () => setShow(true);
  const [clicked, setClicked] = useState(false);
  const [price, setPrice] = useState(props?.location.state?.price);
  const [userInfo, setUserInfo] = useState("");
  const visiblesClose = () => setShowOff(false);
  const visibles = () => setShowOff(true);
  const [backModal, setBackModal] = useState(false);
  const BackModalClose = () => setBackModal(false);
  const backModalShow = () => setBackModal(true);
  const [showOff, setShowOff] = useState(false);
  const [thirdTab, setThirdTab] = useState(false);
  const [backPopUp, setBackPopUp] = useState(true);
  const [goAhead, setGoAhead] = useState(true);
  const [storedServiceId, setStoredServiceId] = useState(
    props?.location?.state?.serviceId
  );

  const [providerDetailItem, setProviderDetailItem] = useState("");
  const [initialDetailsOfUser, setInitialDetailsOfUser] = useState([]);
  const [isSaveProfileModal, setIsSaveProfileModal] = useState(false);
  const [isBillRequired, setIsBillRequired] = useState(false);
  // initial selected personal info

  // const initialDetailsOfUser = user[user || []?.findIndex((obj) => obj?.is_default === "1")]?.data?.[0];
  //const initialDetailsOfUser = user?.filter((item) => item?.is_default === "1")[0]?.data?.[0]
  //initial information of user profile
  useEffect(() => {
    if (typeof props.location.state != "undefined") {
      if (props.location.state?.draft !== "draftorder") {
        localStorage.setItem(
          "storedId",
          props.location.state?.item?.service_id
        );
        localStorage.setItem(
          "serviceItem",
          JSON.stringify(props.location.state?.item)
        );
        localStorage.setItem("serviceName", props?.location?.state?.name);
      } else {
        localStorage.setItem(
          "storedId",
          props.location.state?.selected_serviceItem
        );
        localStorage.setItem(
          "serviceItem",
          JSON.stringify(props.location.state?.selected_serviceItem)
        );
        localStorage.setItem(
          "serviceName",
          props?.location?.state?.selected_serviceItem?.name
        );
      }
    }
  });
  let storeId = localStorage.getItem("storedId");
  let serviceItem = localStorage.getItem("serviceItem");
  let name = localStorage.getItem("serviceName");
  useEffect(() => {
    if (
      typeof initialDetailsOfUser !== "undefined" &&
      props?.location?.state?.draft !== "draftorder"
    ) {
      setFirstName(initialDetailsOfUser?.firstName || "");
      setLastName(initialDetailsOfUser?.lastName || "");
      setEmailAddress(initialDetailsOfUser?.emailAddress || "");
      setValue((initialDetailsOfUser?.phone_number || "")?.replaceAll("undefined","").replaceAll("null","") || "");
      setCIF(initialDetailsOfUser?.CIF || "");
      setCity(initialDetailsOfUser?.city || "");
      setSelectedRegion(initialDetailsOfUser?.region || "");
      setCompanyName(initialDetailsOfUser?.companyName || "");
      setAddress(initialDetailsOfUser?.address || "");

      {
        initialDetailsOfUser?.none?.length ? setNone("None") : setNone("");
      }
      {
        initialDetailsOfUser?.business?.length
          ? SetBusiness("Business")
          : SetBusiness("");
      }
      {
        initialDetailsOfUser?.self?.length ? setSelf("Self") : setSelf("");
      }

      setIsDisabled(
        initialDetailsOfUser?.business?.length ||
          initialDetailsOfUser?.self?.length
          ? false
          : true
      );
    }
  }, [initialDetailsOfUser]);

  const saveDraft = () => {
    setClicked(true);
    if (
      profileTitle !== "" ||
      firstName !== "" ||
      lastName !== "" ||
      emailAddress !== "" ||
      contactNo !== "" ||
      selectedRegion !== "" ||
      city !== ""
    ) {
      let personal = {
        profileTitle,
        firstName,
        lastName,
        emailAddress,
        isBusiness,
        countryCode: value,
        contactNo: value,
        companyName,
        CIF,
        address,
        selectedRegion,
        city,
        none,
        business,
        self,
        value,
        serviceName,
        id,
        avatar,
        selectedTab,
        stepindex: selectedTab - 1,
        serviceProviders,
        price,
        phone_number: value,
        serviceForm,
        serviceItem: props?.location?.state?.item?.data?.[0],
        // serviceItem:
        //   props?.location?.state?.draft === "draftorder"
        //     ? props?.location?.state?.item?.data?.[0]?.serviceItem?.data?.[0]
        //         ?.serviceItem
        //     : props?.location?.state?.item,
        selected_serviceItem: props?.location?.state?.selected_serviceItem,
      };
      let data = [personal];

      {
        props?.location?.state?.draft === "draftorder" || clicked
          ? Edit_Draft_Order(
              {
                draft_order_id: props?.location?.state?.item?.draft_order_id,
                data: JSON.stringify(data),
              },
              (value) => {
                setShowSpinner(value);
              },
              (value) => {
                setSuccess(value);
              },
              (value) => {
                setMessage(value);
                addToast(value, { autoDismiss: 2000 });
              },
              (value) => {
                setShowToast(value);
              }
            )
          : Add_Draft(
              { data: JSON.stringify(data) },
              (value) => setServiceProviders(value),
              (value) => setSuccess(value),
              (value) => {
                setMessage(value);
                addToast(value, {
                  appearance: "info",
                  autoDismiss: 1000,
                });
              }
            );
        setTimeout(() => {
          push({ pathname: "/1/mysavedorder" });
        }, 2000);
      }
    }
  };

  const continueProceed = () => {
    if (
      // firstName !== "" &&
      // lastName !== "" &&
      // emailAddress !== "" &&
      value !== "" &&
      selectedRegion !== "" &&
      // city !== "" &&
      // !emailValidation &&
      (isBillRequired ? companyName !== "" && CIF !== "" && address !== "" : true)
    ) {
      if (!none?.length) {
        if (true) {
          var personal = {
            firstName,
            lastName,
            emailAddress,
            none,
            business,
            self,
            countryCode: value,
            companyName,
            CIF,
            address,
            phone_number: value,
            isBillRequired,
          };
          let data = {
            region: selectedRegion,
            city,
          };

          if (selectedRegion !== "" && city !== "") {
            let obj = {
              service_id:
                props?.location.state?.reOrder == "reOrder"
                  ? props?.location?.state?.serviceId
                  : storeId,
              region: selectedRegion,
              region: selectedRegion,
            };
            Get_Service_Providers(
              obj,
              (value) => {
                setShowSpinner(value);
              },
              (value) => {
                setSuccess(value);
              },
              (value) => {
                setMessage(value);
              },
              (value) => {
                setShowToast(value);
              },
              (value) => {
                setServiceProviders(value);
              }
            );
            let region = {
              state: selectedRegion,
              region: selectedRegion,
              city,
            };
            setPersonal(personal);

            setRegion(region);

            setSelectedTab(3);
          } else {
            // addToast("kindly select state and enter your city", {
            //   autoDismiss: 1000,
            // });
          }
        } else {
          setValidation1(true);
          setValidation2(true);
          return addToast(labels.allFieldsAreRequired, {
            autoDismiss: 1000,
          });
        }
      }
      let data = {
        service_id:
          props?.location?.state?.reOrder == "reOrder"
            ? props?.location?.state?.serviceId
            : storeId,
        state: selectedRegion,
        region: selectedRegion,
      };
      Get_Service_Providers(
        data,
        (value) => {
          setShowSpinner(value);
        },
        (value) => {
          setSuccess(value);
        },
        (value) => {
          setMessage(value);
        },
        (value) => {
          setShowToast(value);
        },
        (value) => {
          setServiceProviders(value);
        }
      );
      personal = {
        firstName,
        lastName,
        emailAddress,
        none,
        business,
        self,
        CIF,
        address,
        companyName,
        countryCode: value,
        phone_number: value,
        isBillRequired,
      };

      setPersonal(personal);

      let region = {
        state: selectedRegion,
        region: selectedRegion,
        city,
      };

      setRegion(region);

      setSelectedTab(3);
    } else {
      setValidation1(true);
      setValidation2(true);
      addToast(labels.fillInTheRequiredFields, { autoDismiss: 1000 });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [backModal]);
  useEffect(() => {
    if (thirdTab) {
      if (goAhead) {
        goToFourthStep(providerDetailItem);
      }
    }
  }, [thirdTab]);

  //   useEffect(() => {
  // if(backPopUp){
  //   window.addEventListener("popstate", (event) => {
  //     if(backPopUp){
  //       setBackPopUp(false);
  //       let procceed = window.confirm("Do you really want to exit the order?");

  //       if (procceed) {
  //         saveDraft();
  //         // return window.removeEventListener("popstate",()=>{console.log("listner remove")})
  //       } else {
  //         push("/documentaion");
  //         // window.close();
  //       }
  //     }

  //   });
  // }

  //    return () =>
  //      window.removeEventListener("popstate", () => {
  //        console.log("listner remove");
  //      });
  //   }, []);

  const states = [
    {
      value: "Andalucia",
    },
    {
      value: "Aragon",
    },
    {
      value: "Cantabria",
    },
    {
      value: "Castilla Y Leon",
    },
    {
      value: "Castilla La Mancha ",
    },
    {
      value: "Cataluna",
    },
    {
      value: "Ceuta",
    },
    {
      value: "Comunidad De Madrid",
    },
    {
      value: "Comunidad Foral De Navarra",
    },
    {
      value: "Comunidad Valenciana",
    },
    {
      value: "Extremadura ",
    },
    {
      value: "Galicia",
    },
    {
      value: "Islas Baleares",
    },
    {
      value: "La Rioja",
    },
    {
      value: "Melilla ",
    },
    {
      value: "Pais Vasco",
    },
    {
      value: "Principado De Asturias",
    },
    {
      value: "Region De Murcia ",
    },
  ];

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedTab, showModal]);

  useEffect(() => {
    setNone("None");
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    Get_Profiles();
    setIsDisabled(true);

    if (props?.location?.state?.draft === "draftorder") {
      const {
        firstName = "",
        city = "",
        selectedRegion = "",
        lastName = "",
        emailAddress = "",
        contactNo = "",
        companyName = "",
        address = "",
        CIF = "",
        value = "",
        draft_order_id = "",
        business = "",
        none = "",
        self = "",
        selectedTab = 1,
        serviceProviders,
        serviceForm,
        serviceItem,
      } = props?.location?.state?.item?.data[0];

      setFirstName(firstName);
      setCity(city);
      setSelectedRegion(selectedRegion);
      setLastName(lastName);
      setEmailAddress(emailAddress);
      setContactNo(contactNo);
      setCompanyName(companyName);
      setAddress(address);
      setCIF(CIF);
      setValue((value || "")?.replaceAll("undefined","").replaceAll("null","") || "" );
      setServiceProviders(serviceProviders);
      setServiceForm(serviceForm);

      {
        none?.length ? setNone("None") : setNone("");
      }
      {
        business?.length ? SetBusiness("Business") : SetBusiness("");
      }
      {
        self?.length ? setSelf("Self") : setSelf("");
      }

      setIsDisabled(business?.length || self?.length ? false : true);
      setSelectedTab(selectedTab);

      setPersonal({
        firstName: firstName,
        city: city,
        selectedRegion: selectedRegion,
        region: selectedRegion,
        lastName: lastName,
        emailAddress: emailAddress,
        contactNo: contactNo,
        companyName: companyName,
        address: address,
        CIF: CIF,
        value: value,
        phone_number: value,
        serviceProviders: serviceProviders,
        isBillRequired,
      });

      let data = {
        region: selectedRegion,
        city,
      };
      if (selectedRegion !== "" && city !== "") {
        let obj = {
          service_id: storeId,
          state: selectedRegion,
        };
        Get_Service_Providers(
          obj,
          (value) => {
            setShowSpinner(value);
          },
          (value) => {
            setSuccess(value);
          },
          (value) => {
            setMessage(value);
          },
          (value) => {
            setShowToast(value);
          },
          (value) => {
            setServiceProviders(value);
          }
        );
        let region = {
          state: selectedRegion,
          city,
        };
        setRegion(region);
        // setSelectedTab(3);
      }
    }
  }, []);

  useEffect(() => {
    if (props?.location?.state?.reOrder == "reOrder") {
      const {
        CIF = "",
        address = "",
        business = "",
        companyName = "",
        phone_number = "",
        countryCode = "",
        emailAddress = "",
        firstName = "",
        lastName = "",
        none = "",
        self = "",
      } = props?.location?.state?.data?.data?.[0]?.personal;

      const { city = "", state = "" } =
        props?.location?.state?.data?.data[1]?.region;

      setFirstName(firstName);
      setCity(city);
      setSelectedRegion(selectedRegion);
      setLastName(lastName);
      setEmailAddress(emailAddress);
      setContactNo(contactNo);
      setCompanyName(companyName);
      setAddress(address);
      setCIF(CIF);
      setValue((countryCode || "")?.replaceAll("undefined","").replaceAll("null","") || "");
      setSelectedRegion(state);
      setServiceForm(props?.location?.state?.data[2]?.additionalInfo);

      {
        none?.length ? setNone("None") : setNone("");
      }
      {
        business?.length ? SetBusiness("Business") : SetBusiness("");
      }
      {
        self?.length ? setSelf("Self") : setSelf("");
      }

      setIsDisabled(business?.length || self?.length ? false : true);
    }
  }, []);
  const handleChange = (checked) => {
    setIsBusiness(checked);
  };

  const submitForm = () => {
    setShouldNavigate(true);
  };

  const closeToast = () => {
    setShowToast(false);
  };
  const Get_Profiles = () => {
    Get_Profiles_Method(
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        // const initialDetailsOfUser = user[user || []?.findIndex((obj) => obj?.is_default === "1")]?.data?.[0];
        //const initialDetailsOfUser = user?.filter((item) => item?.is_default === "1")[0]?.data?.[0]
        setInitialDetailsOfUser(
          value?.filter((item) => item?.is_default === "1")[0]?.data?.[0]
        );
        // setInitialDetailsOfUser(value)
        setUserDetails(value);
      }
    );
  };

  const fetchedData = userDetails.map((item) => {
    return {
      id: item?.profile_id,
      profileTitle: item?.data[0]?.profileTitle,
      data: item?.data,
    };
  });
  const profileTitles = fetchedData?.map((item) => {
    return {
      title: item?.profileTitle,
    };
  });
  const selectDocument = async (value, fieldId, formId, title) => {
    let formData = new FormData();
    formData.append("file", JSON?.stringify(value));
    Get_File_Url_Method(
      formData,
      (value) => {
        setSingleDocument("Doc is Selected");
        updatedFecthFormValue(value, fieldId, formId, title);
      },
      (value) => {
        setSuccess(value);
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      }
    );
  };

  const selectMuiltipleDocument = async (
    valueArray,
    fieldId,
    label,
    name,
    type
  ) => {
    let muiltipleFileNames = "";
    valueArray.map((r, i) => {
      muiltipleFileNames += `${r?.name}, `;
    });

    valueArray?.map((file, i) => {
      let formData = new FormData();
      formData.append("file", file?.url);
      Get_File_Url_Method(
        formData,
        (value) => {
          getUrl(value, fieldId, label, file?.name, file?.type);
        },
        (value) => {
          setSuccess(value);
        },
        (value) => {
          setMessage(value);
        },
        (value) => {
          // setShowToast(value);
        }
      );
    });
    setMuiltipleDocument(muiltipleFileNames);
  };

  const getUrl = (url, fieldId, label, name, type) => {
    urlArray.push({ url, name: name, type: type });
    updatedFecthFormValue(urlArray, fieldId, label);
  };
  const updatedFecthFormValue = (inputValue, fieldId, label) => {
    let mappedOut = serviceForm?.map((item, index) => {
      if (item?.fieldId === fieldId && item?.label === label) {
        return {
          ...item,
          value: inputValue,
        };
      } else {
        return { ...item };
      }
    });
    setServiceForm(mappedOut);
    // setServiceForm((prevState) => {
    //   let filetredArray = prevState.find((item) => item.formId === formId);
    //   let mappedArray = filetredArray.formStructure.map((item, index) => {
    //     if (item.fieldId === fieldId && item?.label === label) {
    //       return {
    //         ...item,
    //         value: value,
    //       };
    //     } else {
    //       return { ...item };
    //     }
    //   });
    //   let changeObject = {
    //     formId,
    //     formStructure: mappedArray,
    //     title,
    //   };

    //   return [changeObject];
    // });
  };
  const saveProfileButton = () => {
    if (
      initialDetailsOfUser?.firstName !== firstName ||
      initialDetailsOfUser?.lastName !== lastName ||
      initialDetailsOfUser?.emailAddress !== emailAddress ||
      initialDetailsOfUser?.region !== selectedRegion ||
      initialDetailsOfUser?.phone_number !== value ||
      initialDetailsOfUser?.city !== city ||
      (initialDetailsOfUser?.none?.length
        ? none === ""
        : initialDetailsOfUser?.business?.length
        ? business === ""
        : initialDetailsOfUser?.self?.length
        ? self === ""
        : true) ||
      initialDetailsOfUser?.companyName !== companyName ||
      initialDetailsOfUser?.CIF !== CIF ||
      initialDetailsOfUser?.address !== address
    ) {
      return (
        <div className="px-2 mt-3">
          <button
            className="w-100 font-weight-normal text-capitalize "
            onClick={() => setIsSaveProfileModal(true)}
          >
            {labels.saveDataInNewProfile}
          </button>
        </div>
      );
    }
  };
  const saveAsNewProfileFunction = () => {
    if (
      true
      // profileTitle !== "" &&
      // firstName &&
      // lastName !== "" &&
      // emailAddress !== "" &&
      // contactNo !== "" &&
      // value?.length >= 7 &&
      // city !== "" &&
      // typeof selectedRegion !== "undefined" &&
      // !emailValidation
    ) {
      // if (!none?.length) {
      //   if (
      //     companyName !== "" &&
      //     address !== "" &&
      //     CIF !== ""
      //   ) {
      //   } else {
      //     return addToast(labels.allFieldsAreRequired, {
      //       autoDismiss: 1000,
      //     });
      //     setValidation(true);
      //   }
      // }

      let personal = {
        profileTitle,
        firstName,
        lastName,
        emailAddress,
        isBusiness,
        countryCode: countryCode,
        contactNo: contactNo,
        companyName,
        CIF,
        address,
        region: selectedRegion,
        city,
        none,
        business,
        self,
        phone_number: value,
      };

      let data = [personal];

      // Add_Profile(
      //   {
      //     is_default: "1",
      //     data: JSON.stringify(data),
      //   },
      //   (value) => {
      //     console.log("setServiceProviders(value) 782", value);
      //   },
      //   (value) => {
      //     console.log("setServiceProviders(value)785", value);
      //     setShowSpinner(false);
      //   },
      //   (value) => {
      //     console.log("setServiceProviders(value) 789", value);
      //   },
      //   (value) => {
      //     console.log("setServiceProviders(value) 792", value);
      //   },
      //   (value) => {
      //     console.log("setServiceProviders(value) 795", value);
      //   }

      // );
      const obj = {
        is_default: "1",
        data: JSON.stringify(data),
      };
      setShowSpinner(true);
      Add_Profile(
        obj,
        (value) => {
          console.log("value", value);
        },
        setShowSpinner,
        (value) => {
          setSuccess(value);
          if (value) {
            Get_Profiles();
            setIsSaveProfileModal(false);
            setProfileTitle("");
          }
        },
        (value) => {
          addToast(value, {
            autoDismiss: 1000,
          });
          setMessage(value);
        },
        (value) => {
          setShowToast(value);
        }
      );
      localStorage.setItem("firstName", firstName);
      localStorage.setItem("lastName", lastName);
      localStorage.setItem("email", emailAddress);
      localStorage.setItem("company", companyName);
      localStorage.setItem("Cif", CIF);
      localStorage.setItem("phoneNumber", value);
      localStorage.setItem("address", address);
    }
  };
  const firstform = () => {
    return (
      <div role="form" action="index.html" className="login-box">
        <div className="d-flex">
          <img
            src={Images.backArrow.default}
            className="cursorPointer"
            style={{ width: "50px", height: "50px" }}
            onClick={() => {
              setBackModal(true);
            }}
          />

          <Dropdown>
            <Dropdown.Toggle
              className="mb-1 mx-3  rounded"
              id="dropdown-basic"
              variant="transparent"
            >
              {/* {fetchedData[0]?.profileTitle} */}
              {labels.myProfile}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {fetchedData?.map((item, index) => {
                // console.log(item,"item")
                return (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      setInitialDetailsOfUser(item?.data?.[0]);
                      // setFirstName(item?.data[0]?.firstName);
                      // setCity(item?.data[0]?.city);
                      // setSelectedRegion(
                      //   item?.data[0]?.selectedRegion ?? item?.data[0]?.region
                      // );
                      // setLastName(item.data[0]?.lastName);
                      // setEmailAddress(item?.data[0].emailAddress);
                      // setContactNo(item?.data[0].contactNo);
                      // setCompanyName(
                      //   item?.data[0]?.companyName?.length
                      //     ? item?.data[0]?.companyName
                      //     : ""
                      // );
                      // setAddress(
                      //   item?.data[0]?.address?.length
                      //     ? item?.data[0]?.address
                      //     : ""
                      // );
                      // setCIF(
                      //   item?.data[0]?.CIF?.length ? item?.data[0]?.CIF : ""
                      // );
                      // setValue(
                      //   item?.data[0]?.phone_number?.length
                      //     ? item?.data[0]?.phone_number
                      //     : ""
                      // );

                      // {
                      //   item?.data[0]?.none?.length
                      //     ? setNone("None")
                      //     : setNone("");
                      // }
                      // {
                      //   item?.data[0]?.business?.length
                      //     ? SetBusiness("Business")
                      //     : SetBusiness("");
                      // }
                      // {
                      //   item?.data[0]?.self?.length
                      //     ? setSelf("Self")
                      //     : setSelf("");
                      // }
                      // // manage inital user type selection.
                      // {
                      //   !item?.data[0]?.self?.length &&
                      //     !item?.data[0]?.business?.length &&
                      //     setNone("None");
                      // }

                      // setIsDisabled(
                      //   item?.data[0]?.business?.length ||
                      //     item?.data[0]?.self?.length
                      //     ? false
                      //     : true
                      // );
                    }}
                  >
                    {item.profileTitle?.length
                      ? item?.profileTitle
                      : labels.noSavedProfile}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="tab-content pb-4 bg-white pt-0">
          <div className="tab-pane active" role="tabpanel">
            <Row>
              {/* <Col md="6">
                <div className="form-group">
                  <label className="d-block">
                    {labels.enterfirstName}
                    <span className="float-right">
                      <BiInfoCircle size={20} />
                    </span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder={labels.enterfirstName}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  {firstName?.length <= 0 && validation1 && (
                    <p className="text-danger">{labels.validateField}</p>
                  )}
                </div>
              </Col> */}
              {/* <Col md="6">
                <div className="form-group">
                  <label className="d-block">
                    {labels.enterlastName}
                    <span className="float-right">
                      <BiInfoCircle size={20} />
                    </span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder={labels.enterlastName}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  {lastName?.length <= 0 && validation1 && (
                    <p className="text-danger">{labels.validateField}</p>
                  )}
                </div>
              </Col> */}

              {/* <Col md="4">
                <div className="form-group">
                  <small className="d-block fw-600">
                    {labels.Email}
                    <span className="float-right">
                      <BiInfoCircle size={20} />
                    </span>
                  </small>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder={labels.emails}
                    value={emailAddress}
                    onBlur={(e) => {
                      validateEmail(e);
                    }}
                    onChange={(e) => {
                      setEmailAddress(e.target.value);
                    }}
                  />
                  {emailAddress?.length <= 0 && validation1 && (
                    <p className="text-danger">{labels.emailValidation} </p>
                  )}
                  {emailValidation && (
                    <p className="text-danger"> {labels.emailValidation}</p>
                  )}
                </div>
              </Col> */}
              <Col md="6">
                <div className="form-group">
                  <small className="d-block fw-600">
                    {labels.selectRegion}
                    <span className="float-right">
                      <BiInfoCircle size={20} />
                    </span>
                  </small>
                  <select
                    className="form-control select-input-style h-100 p-2"
                    type="text"
                    name="name"
                    value={selectedRegion}
                    onChange={(e) => setSelectedRegion(e.target.value)}
                  >
                    <option value="" disabled selected>
                      {labels.states}
                    </option>
                    {states.map((state, index) => {
                      return <option value={state.value}>{state.value}</option>;
                    })}
                  </select>
                  {selectedRegion?.length <= 0 && validation2 && (
                    <p className="text-danger">{labels.validateField}</p>
                  )}
                </div>
              </Col>
              {/* <Col md="6">
                <div className="form-group">
                  <label className="d-block">
                    {labels.city}
                    <span className="float-right">
                      <BiInfoCircle size={20} />
                    </span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder={labels.city}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                  {city?.length <= 0 && validation2 && (
                    <p className="text-danger">{labels.validateField}</p>
                  )}
                </div>
              </Col> */}
              <Col md="6">
                <div className="form-group">
                  <small className="d-block fw-600">
                    {labels.contactNumber}
                    <span className="float-right">
                      <BiInfoCircle size={20} />
                    </span>
                  </small>
                  <div className="d-flex">
                    <input
                      className="form-control"
                      maxLength={20}
                      autoComplete={false}
                      type="tel"
                      placeholder={"-------------"}
                      value={value}
                      onChange={(e) => {
                        const re = /^[0-9-+\b]+$/;

                        // if value is not blank, then test the regex

                        if (e.target.value === "" || re.test(e.target.value)) {
                          setValue(e.target.value);
                        }
                      }}
                    />
                  </div>
                  {value?.trim()?.length <= 0 && validation2 && (
                    <p className="text-danger">{labels.validateField}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                md="12"
                className="d-flex flex-wrap justifyContentSB pr-3 height30px mt-3 mb-3"
              >
                <div>
                  <h6 className="font-weight-bold">
                    {labels.selectUserTypeBillingInformation}
                  </h6>
                  <div className="d-flex gap-2">
                    <h6 className="font-weight-bold">
                      {labels.doYouNeedABill}
                    </h6>
                    <div className="d-flex mx-3 gap-3">
                      <div className="d-flex gap-2 text-capitalize">
                        <div>{labels.yes}</div>
                        <div>
                          <input
                            className="cursor-pointer"
                            type="checkbox"
                            checked={isBillRequired}
                            onClick={() => setIsBillRequired(true)}
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-2 text-capitalize">
                        <div>{labels.no}</div>
                        <div>
                          <input
                            className="cursor-pointer"
                            type="checkbox"
                            checked={!isBillRequired}
                            onClick={() => setIsBillRequired(false)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="gap-3 d-flex">
                  <div>
                    <input
                      type="radio"
                      value={none}
                      checked={!business?.length && !self?.length}
                      name="gender"
                      onClick={() => {
                        setNone("None");
                        SetBusiness("");
                        setSelf("");
                        setIsDisabled(true);
                      }}
                    />{" "}
                    {labels.none}{" "}
                  </div>
                  <div>
                    <input
                      type="radio"
                      value={self}
                      name="gender"
                      checked={self?.length && true}
                      onClick={() => {
                        setSelf("Self Employed");
                        SetBusiness("");
                        setNone("");
                        setIsDisabled(false);
                      }}
                    />{" "}
                    {labels.selfEmployed}
                  </div>
                  <div>
                    <input
                      type="radio"
                      value={business}
                      name="gender"
                      checked={business?.length && true}
                      onClick={() => {
                        SetBusiness(" Business");
                        setSelf("");
                        setNone("");
                        setIsDisabled(false);
                      }}
                    />{" "}
                    {labels.business}{" "}
                  </div>
                </div>
              </Col>
              <Col
                md="4"
                className=" documentation-container"
                hidden={!isBillRequired}
              >
                {" "}
                <div className="form-group">
                  <small className="d-block fw-600">
                    {labels.companyName}
                    <span className="float-right">
                      <BiInfoCircle size={20} />
                    </span>
                  </small>

                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder={labels.companyName}
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  {companyName?.length <= 0 && validation1 && (
                    <p className="text-danger">{labels.validateField}</p>
                  )}
                </div>
              </Col>
              <Col md="4" hidden={!isBillRequired}>
                <div className="form-group">
                  <small className="d-block fw-600">
                    {labels.enterCIF}
                    <span className="float-right">
                      <BiInfoCircle size={20} />
                    </span>
                  </small>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder={labels.enterCIF}
                    value={CIF}
                    onChange={(e) => setCIF(e.target.value)}
                  />
                  {CIF?.length <= 0 && validation1 && (
                    <p className="text-danger">{labels.validateField}</p>
                  )}
                </div>
              </Col>
              <Col md="4" hidden={!isBillRequired}>
                <div className="form-group">
                  <small className="d-block fw-600">
                    {labels.address}
                    <span className="float-right">
                      <BiInfoCircle size={20} />
                    </span>
                  </small>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder={labels.address}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  {address?.length <= 0 && validation1 && (
                    <p className="text-danger">{labels.validateField}</p>
                  )}
                </div>
              </Col>
            </Row>

            {/* <Row>
              <Col
                md="12"
                className="d-flex flex-wrap justifyContentSB pr-3 height30px mt-3 mb-3"
              >
                <div className="d-flex gap-2">
                  <h6 className="font-weight-bold">{labels.doYouNeedABill}</h6>
                  <div>
                    <input type="checkbox" className="bg-danger" />
                  </div>
                </div>
              </Col>
            </Row> */}
          </div>
          <div className="d-flex justify-content-center flex-wrap flex-sm-row flex-column gap-1">
            <div className="px-2">
              <button
                className="w-100 font-weight-normal text-capitalize"
                onClick={() => {
                  saveDraft();
                }}
              >
                {labels.saveDraft}
              </button>
            </div>
            <div className="px-2">
              <button
                className="w-100 font-weight-normal text-capitalize"
                onClick={() => {
                  continueProceed();
                }}
              >
                {labels.continueProceed}
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-center flex-wrap flex-sm-row flex-column gap-1">
            {saveProfileButton()}
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{labels.profileTitle}</Modal.Title>
          </Modal.Header>
          <input
            className="form-control"
            type="text"
            name="Title"
            placeholder={labels.profileTitle}
            value={profileTitle}
            onChange={(e) => setProfileTitle(e.target.value)}
          />
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                if (
                  profileTitle !== "" &&
                  firstName !== "" &&
                  lastName !== "" &&
                  emailAddress !== "" &&
                  selectedRegion !== "" &&
                  city !== ""
                ) {
                  let personal = {
                    profileTitle,
                    firstName,
                    lastName,
                    emailAddress,
                    isBusiness,
                    countryCode: countryCode,
                    contactNo: contactNo,
                    companyName,
                    CIF,
                    address,
                    region: selectedRegion,
                    city,
                    none,
                    business,
                    self,
                    value,
                    selectedTab,
                    stepindex: selectedTab - 1,
                    phone_number: value,
                  };
                  let data = [personal];
                  Add_Profile({ data: JSON.stringify(data) }, (value) =>
                    setServiceProviders(value)
                  );
                } else {
                  addToast(labels.allFieldsAreRequired, {
                    autoDismiss: 1000,
                  });
                }

                setTimeout(() => {
                  push({ pathname: "/" });
                  setShow(false);
                }, 2000);
              }}
              type="button"
              className="btn btn-primary gradiant-theme-color"
            >
              {labels.save}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  const goToFourthStep = (item) => {
    if (serviceProviders?.length !== 0) {
      GetServiceFormById(
        props?.location?.state?.draft === "draftorder"
          ? props?.location?.state?.serviceId
          : props?.location?.state?.reOrder == "reOrder"
          ? props?.location?.state?.serviceId
          : storeId,
        (value) => {
          setShowSpinner(value);
        },
        (value) => {
          setSuccess(value);
        },
        (value) => {
          setMessage(value);
        },
        (value) => {
          setShowToast(value);
        },
        (value) => {
          return setServiceForm(value);
        }
      );

      setServiceProviderId(item);
      setServiceProviderInfo(item?.info);
      setUserInfo(item);
      setSelectedTab(4);
      setGoAhead(false);
    }
  };

  const functionToGoToFourthStep = (item) => {
    setThirdTab(true);
    setProviderDetailItem(item);
    thirdTab && goToFourthStep(item);
  };
  const thirdForm = () => {
    return (
      <div role="form" action="index.html" className="login-box">
        <div className="tab-content pb-4 bg-white pt-0">
          <div className="col-sm-12 d-flex justify-content-end mb-4 pr-0 "></div>

          <div className="tab-pane active" role="tabpanel">
            <div className="row">
              {showSpinner ? (
                <div>{labels.loading}</div>
              ) : serviceProviders?.length ? (
                serviceProviders?.map((item, index) => {
                  return (
                    <div
                      className="col-md-6 mb-30  position-relative service-provider-card"
                      key={index}
                    >
                      <article
                        className="shop-summary-item element-emphasis-weak border-radius-10 mt-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => functionToGoToFourthStep(item)}
                      >
                        <img
                          className="document-management-service-avatar"
                          src={item?.avatar}
                          alt="Shop item in cart"
                          style={{
                            borderRadius: 10,

                            objectFit: "contain",
                            border: "1px solid #e5e5e5",
                            background: "rgb(229 229 229 / 29%)",
                          }}
                        />
                        <header
                          className="item-info-name-features-price mb-13 "
                          onClick={() => functionToGoToFourthStep(item)}
                        >
                          <div className="fontSize16px mb-0 d-flex flex-column">
                            <div>
                              <strong>{item?.name}</strong>
                            </div>
                            <div className="">
                              <span>{labels.position}:</span>{" "}
                              <strong>
                                {(
                                  item?.[
                                    lang === "english"
                                      ? "position"
                                      : "position_sp"
                                  ] || "N/A"
                                )?.substring(0, 25)}
                                {(
                                  item?.[
                                    lang === "english"
                                      ? "position"
                                      : "position_sp"
                                  ] || "N/A"
                                )?.length > 25
                                  ? "... "
                                  : ""}
                              </strong>
                            </div>
                            <div>
                              <span>{labels.experience}:</span>{" "}
                              <strong>
                                {item?.experience} {labels.years}
                              </strong>
                            </div>
                            <div>
                              <span>{labels.rating}:</span>{" "}
                              <span>
                                {regexForFormatRating(
                                  item?.fake_rating != null
                                    ? Number(item?.fake_rating)
                                    : Number(
                                        !item?.average_rating?.length
                                          ? "0"
                                          : item?.average_rating
                                      )
                                )}
                                <span style={{ color: "#FED42D" }}>
                                  {rating(
                                    item?.fake_rating != null
                                      ? Number(item?.fake_rating)
                                      : Number(
                                          !item?.average_rating?.length
                                            ? "0"
                                            : item?.average_rating
                                        )
                                  )}
                                </span>
                              </span>
                              {/* <span>
                                {item?.fake_rating != null
                                  ? Number(item?.fake_rating).toFixed(0)
                                  : Number(
                                      !item?.average_rating?.length
                                        ? "0"
                                        : item?.average_rating
                                    ).toFixed(0)}
                                <span style={{ color: "#FED42D" }}>
                                  {rating(
                                    item?.fake_rating != null
                                      ? Number(item?.fake_rating).toFixed(0)
                                      : Number(
                                          !item?.average_rating?.length
                                            ? "0"
                                            : item?.average_rating
                                        ).toFixed(0)
                                  )}
                                </span>
                              </span> */}
                            </div>
                            {/* <strong>
                              {item?.name}

                              <br />
                              <span>
                                {labels.position}:{" "}
                                <strong>
                                  {item?.position !== null ||
                                  item?.position !== "null"
                                    ? item?.position?.substring(0, 30)
                                    : "N/A"}
                                  ...
                                </strong>
                              </span>
                              <br />
                              <span className="features text-black">
                                <span className="text-secondary fw-lighter">{labels.experience}</span>
                                {item?.experience} {labels.years}
                              </span>
                            </strong> */}
                          </div>

                          {/* <span
                            className="features d-flex justify-content-end flex-row-reverse"
                            style={{ color: "black", position: "top" }}
                          >
                            <span>
                              {" "}
                              <img
                                className=" m-0"
                                style={{ width: 20, height: 20 }}
                                src={Images.shareWin.default}
                                alt="info"
                              />
                            </span>
                            <strong className="m-0">
                              {item?.fake_rating != null
                                ? parseInt(item?.fake_rating).toFixed(1)
                                : parseInt(
                                    !item?.average_rating?.length
                                      ? "0"
                                      : item?.average_rating
                                  ).toFixed(1)}
                            </strong>
                          </span> */}
                        </header>
                      </article>
                      <div
                        className="p-2 d-flex justify-content-center align-items-center rounded-circle "
                        style={{ position: "absolute", top: 50, right: 20 }}
                      >
                        <img
                          className="m-0"
                          onClick={() => {
                            setInformation(item);
                            setModalShow(true);
                            setThirdTab(false);
                          }}
                          src={Images.infoo.default}
                          style={{ width: 20, height: 20, cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>{labels.noProviderExist}</div>
              )}
              <ProviderInfoModal
                show={modalShow}
                info={information}
                onHide={() => setModalShow(false)}
              />
            </div>

            <div className="d-flex px-2">
              <button
                onClick={() => setSelectedTab(1)}
                type="button"
                className="btn btn-primary mr-1 gradiant-theme-color"
              >
                {labels.back}
              </button>

              {/* <button
                onClick={() => saveDraft()}
                type="button"
                className="btn btn-primary mr-3 gradiant-theme-color"
              >
                {labels.saveDraft}
              </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const fourthForm = () => {
    return (
      <div className="login-box d-flex justify-content-center">
        <div className="tab-content bg-white pb-4" style={{ width: "55rem" }}>
          <div className="tab-pane active" role="tabpanel">
            <div className="all-info-container">
              <div className="row">
                {serviceForm?.map((form, index) => {
                  return (
                    <>
                      <div className="col-md-12" key={index}>
                        <div className="row " key={index}>
                          {form.hasOwnProperty("fieldId") && (
                            <>
                              <DynamciInput
                                field={form}
                                fieldIndex={index}
                                singleFile={singleDocument}
                                muiltipleFiles={muiltipleDocument}
                                openDocumentMultiple={selectMuiltipleDocument}
                                formId={form.formId}
                                title={form.title}
                                updatedFieldValue={updatedFecthFormValue}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>

              <div className="d-flex justify-content-center flex-wrap flex-sm-row flex-column gap-1">
                <div className="px-2">
                  <button
                    className="w-100"
                    onClick={() => {
                      setSelectedTab(3);
                    }}
                  >
                    {labels.back}
                  </button>
                </div>

                <div className="px-2">
                  <button
                    className="w-100"
                    onClick={() => {
                      let additionalInfo = serviceForm;

                      let isValueExist = [];
                      let isValueNotExist = [];
                      let isValueExistEmpty = [];
                      let filteredOut;

                      if (props?.location?.state?.draft === "draftorder") {
                        filteredOut = serviceForm?.filter(
                          (item) =>
                            item.fieldId !== 8 &&
                            item.fieldId !== 12 &&
                            item?.hasOwnProperty("sectionTitle") !== true
                        );
                      } else if (props?.location?.state?.reOrder == "reOrder") {
                        filteredOut =
                          props?.location?.state?.data[2]?.additionalInfo?.filter(
                            (item) =>
                              item.fieldId !== 8 &&
                              item.fieldId !== 12 &&
                              item?.hasOwnProperty("sectionTitle") !== true
                          );
                      } else {
                        filteredOut = serviceForm?.filter(
                          (item) =>
                            item.fieldId !== 8 &&
                            item.fieldId !== 12 &&
                            item?.hasOwnProperty("sectionTitle") !== true
                        );
                      }

                      filteredOut?.map((item) => {
                        if (item.hasOwnProperty("value")) {
                          if (item.value !== "") {
                            isValueExist.push(item);
                          } else {
                            isValueExistEmpty.push(item);
                          }
                        } else {
                          isValueNotExist.push(item);
                        }
                      });

                      let data = [];

                      data.push({ personal: personal });
                      data.push({ region: region });
                      data.push({ additionalInfo: additionalInfo });
                      if (personal?.length !== 0 && region?.length !== 0) {
                        // console.log(isValueExistEmpty, isValueExist, isValueNotExist, "missing field")
                        // if (props?.location?.state?.reOrder == "reOrder" ? true : isValueExist.length && isValueNotExist.length === 0 && isValueExistEmpty.length === 0
                        // if (props?.location?.state?.reOrder == "reOrder") {
                        push({
                          pathname: "/checkout",
                          state: {
                            service:
                              props?.location?.state?.draft === "draftorder" ||
                              props?.location?.state?.reOrder == "reOrder"
                                ? props?.location?.state?.serviceId
                                : props.location.state?.acticeFilter,
                            name:
                              props?.location?.state?.reOrder == "reOrder"
                                ? props.location.state?.service?.name
                                : name,
                            service_provider:
                              props?.location?.state?.draft === "draftorder"
                                ? serviceProviders[0]
                                : serviceProviderId,
                            data: data,
                            service_price:
                              props?.location?.state?.reOrder == "reOrder"
                                ? props.location.state?.price
                                : props?.location.state?.price,
                            avatar:
                              props?.location?.state?.draft === "draftorder"
                                ? props?.location?.state?.image
                                : props?.location?.state?.reOrder == "reOrder"
                                ? props?.location?.state?.service?.avatar
                                : props?.location?.state?.avatar,
                            serviceItem:
                              props?.location?.state?.draft === "draftorder"
                                ? props?.location?.state?.item?.data[0]
                                    ?.selected_serviceItem
                                : props?.location?.state?.reOrder == "reOrder"
                                ? props?.location?.state?.service
                                : JSON.parse(serviceItem),
                          },
                        });
                        // } else {
                        //   addToast("kindly fill all details given here", {
                        //     autoDismiss: 1000,
                        //   });
                        // }
                      }
                      {
                        props?.location?.state?.draft === "draftorder" &&
                          Delete_Draft_Order(
                            location?.state?.item?.draft_order_id,
                            (value) => {
                              setMessage(value);
                            }
                          );
                      }
                    }}
                  >
                    {labels.orderServices}
                  </button>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    );
  };
  // const setPopUpInfo = (fieldDetialObj) => {
  //   props.showFieldInfo(
  //     fieldDetialObj?.[
  //       language === "english" ? "description" : "descriptionEsp"
  //     ]
  //   );
  // };

  return (
    <>
      <Helmet>
        <title>DocuDoc App | Gestiones y Consultas Legales</title>
        <meta
          name="description"
          content="DocuDoc App ofrece la contratación de consultas y gestiones legales, fiscales, de extranjería, de tráfico y demás asuntos legales al mejor precio."
        />
        {/* Add more meta tags, link tags, etc. as needed */}
      </Helmet>
      <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
        <section
          className="pt-150 pb-150 ls signup-step-container"
          id="services"
          data-animation="fadeInUp"
        >
          <div className="container">
            <div className="d-flex justify-content-center pb-4">
              <h5 className="fw-bold text-center">
                {props?.location?.state?.name}
              </h5>
            </div>

            <div className="row d-flex justify-content-center  ">
              <div className="col-md-12">
                <div className="wizard ">
                  <div className="wizard-inner pb-20 ">
                    <div
                      className={
                        browserName == "Chrome"
                          ? "connecting-line-chrome "
                          : "connecting-line-mozilla"
                      }
                    ></div>

                    <ul className="nav nav-tabs" role="tablist">
                      <li
                        role="presentation"
                        className={selectedTab == 1 ? "active" : "disabled"}
                      >
                        <a
                          data-toggle="tab"
                          aria-controls="step1"
                          role="tab"
                          aria-expanded="true"
                        >
                          <span className="round-tab">1</span>{" "}
                          <i>{labels.stepPersonal}</i>
                        </a>
                      </li>

                      <li
                        role="presentation"
                        className={selectedTab == 3 ? "active" : "disabled"}
                      >
                        <a data-toggle="tab" aria-controls="step3" role="tab">
                          <span className="round-tab">2</span>{" "}
                          <i>{labels.stepServiceProvider}</i>
                        </a>
                      </li>
                      <li
                        role="presentation"
                        className={selectedTab == 4 ? "active" : "disabled"}
                      >
                        <a data-toggle="tab" aria-controls="step4" role="tab">
                          <span className="round-tab">3</span>{" "}
                          <i>{labels.stepAdditionalInfo}</i>
                        </a>
                      </li>
                    </ul>
                  </div>

                  {selectedTab === 1 ? firstform() : null}

                  {selectedTab === 3 ? thirdForm() : null}
                  {selectedTab === 4 ? fourthForm() : null}
                </div>
              </div>
            </div>
          </div>
          <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
            <ModalBody className="bg-white p-0 border-radius-10">
              <button
                type="button"
                className="close absolute"
                data-dismiss="modal"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="row my-3 p-10">
                <div className="col-12 col-lg-11 mx-auto text-center">
                  <div className="">
                    <h4>Alfonso Narvaez</h4>
                    <p>Analista</p>
                    <img src={Images.placeHolder.default} width={"150px"} />
                  </div>
                </div>
                <div className="col-12 col-lg-11 mx-auto text-center pt-20">
                  <p>
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the more obscure Latin words,
                    consectetur, from a Lorem Ipsum passage, and going through
                    the cites of the word in classical literature, discovered
                    the undoubtable source. Lorem Ipsum comes from sections
                    1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The
                    Extremes of Good and Evil) by Cicero, written in 45 BC. This
                    book is a treatise on the theory of ethics, very popular
                    during the Renaissance. The first line of Lorem Ipsum,
                    "Lorem ipsum dolor sit amet..", comes from a line in section
                    1.10.32. The standard chunk of Lorem Ipsum used since the
                    1500s is reproduced below for those interested. Sections
                    1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by
                    Cicero are also reproduced in their exact original form,
                    accompanied by English versions from the 1914 translation by
                    H. Rackham.
                  </p>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            show={backModal}
            onHide={BackModalClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>{labels.confirmation}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{labels.exitOrder}</Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={BackModalClose}>
                {labels.cancel}
              </Button>
              <Button onClick={() => history.goBack()} variant="primary">
                {labels.yes}
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
        <OrderStepSaveProfileModal
          show={isSaveProfileModal}
          onHide={() => setIsSaveProfileModal(false)}
          saveAsNewProfileFunction={saveAsNewProfileFunction}
          profileTitle={profileTitle}
          setProfileTitle={setProfileTitle}
          showSpinner={showSpinner}
        />
      </ScrollAnimation>
    </>
  );
}

//test condition
