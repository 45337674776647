import { Alert } from "react-bootstrap";
import { End_Points_Of_Api } from "../endpoints";
import Api from "./apiConfig";
import { ToastProvider, useToasts } from "react-toast-notifications";

const successMessageLanguage = (responseMessage) => {
  const language = localStorage.getItem("primaryLanguage") === "english";
  return responseMessage[language ? "message" : "message_sp"];
};
const errorMessageLanguage = (responseMessage) => {
  const language = localStorage.getItem("primaryLanguage") === "english";
  return responseMessage[language ? "message" : "message_sp"];
};
const netWorkErrorMessage = () => {
  const language = localStorage.getItem("primaryLanguage") === "english";
  return language ? "network error" : "error de red";
};
export const SignUp_Api_Method = (
  obj,
  userDetail,
  spinner,
  setMessage,
  setShowToast,
  setSuccess,
  closeModal,
  addToast
) => {
  spinner(true);
  Api._post(
    End_Points_Of_Api.User_Register,
    obj,
    (success) => {
      if (success?.status === 200) {
        userDetail(success?.data?.response?.detail?.user);
        // localStorage.setItem("usertoken", success?.data?.response?.detail.token);
        spinner(false);
        // setMessage(successMessageLanguage(success?.data?.response));
        addToast(successMessageLanguage(success?.data?.response), {
          appearance: "success",
          autoDismiss: 1000,
        });

        setSuccess(true);

        closeModal();
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        addToast(successMessageLanguage(error?.response?.data?.error), {
          appearance: "error",
          autoDismiss: 1000,
        });
        // setMessage(errorMessageLanguage(error?.response?.data?.error));

        setSuccess(false);
      } else {
        spinner(false);
        // setMessage(errorMessageLanguage(error?.response?.data?.error));
        addToast(successMessageLanguage(error?.response?.data?.error), {
          appearance: "error",
          autoDismiss: 1000,
        });

        setSuccess(false);
      }
    }
  );
};

export const User_Signin_Method = (
  obj,
  userDetail,
  spinner,
  setMessage,
  setShowToast,
  setSuccess,
  closeModal
) => {
  spinner(true);
  Api._post(
    End_Points_Of_Api.User_Login,
    obj,
    (success) => {
      if (success?.status === 200) {
        userDetail(success?.data?.response?.detail?.user);
        localStorage.setItem("usertoken", success?.data?.response?.detail.token);

        localStorage.setItem("firstName", success?.data?.response?.detail?.user?.first_name);
        localStorage.setItem("lastName", success?.data?.response?.detail?.user?.last_name);
        localStorage.setItem("emailAddress", success?.data?.response?.detail?.user?.email);
        // localStorage.setItem("CIF",success?.data?.response?.detail?.user?.CIF);
        localStorage.setItem("phone_number", success?.data?.response?.detail?.user?.phone_number);
        // localStorage.setItem("address",success?.data?.response?.detail?.user?.);
        // localStorage.setItem("companyName",success?.data?.response?.detail?.user?.);

        spinner(false);
        setMessage(successMessageLanguage(success?.data?.response));
        setShowToast(true);
        setSuccess(true);
        closeModal();
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        spinner(false);
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};
export const User_Login_Method = (obj, success, error) => {
  Api._post(
    End_Points_Of_Api.User_Login,
    obj,
    (res) => {
      success(res);
    },
    (err) => {
      error(err);
    }
  );
};
export const Get_BeneFiciaries_Method = (
  setMessage,
  setShowToast,
  setSuccess,
  setBeneficiaries
) => {
  Api._get(
    End_Points_Of_Api.Get_Beneficiaries,
    (success) => {
      if (success?.status === 200) {
        setBeneficiaries(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};

export const Get_Faqs = (setMessage, setShowToast, setSuccess, setFaqs) => {
  Api._get(
    End_Points_Of_Api.Get_Faqs,
    (success) => {
      if (success?.status === 200) {
        setFaqs(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};

export const Get_Special_Services = (setMessage, setShowToast, setSuccess, setSpecialPrices) => {
  Api._get(
    End_Points_Of_Api.Get_Special_Services,
    (success) => {
      if (success?.status === 200) {
        setSpecialPrices(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};

export const Get_Branches = (setMessage, setShowToast, setSuccess, setBranches) => {
  Api._get(
    End_Points_Of_Api.Get_Branches,
    (success) => {
      if (success?.status === 200) {
        setBranches(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};

export const Additional_Budget_By_Order_Id = (id, additionalBudget, setMessage, setSuccess) => {
  Api._get(
    `${End_Points_Of_Api.Additional_Budget_By_Order_Id}/${id}`,
    (success) => {
      if (success?.status === 200) {
        additionalBudget(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));

        setSuccess(false);
      } else {
        setMessage(netWorkErrorMessage);

        setSuccess(false);
      }
    }
  );
};

export const Additional_Budget_Decline_Accept = (
  obj,
  updateStateFunction,
  setMessage,
  setSuccess
) => {
  Api._post(
    End_Points_Of_Api.Additional_Budget_Decline_Accept,
    obj,
    (success) => {
      updateStateFunction(success?.data?.response?.detail);
      setSuccess(true);
    },
    (error) => {
      setMessage(error);
    }
  );
};

export const Add_Review = (obj, setSuccess, setMessage) => {
  Api._post(
    End_Points_Of_Api.Add_Review,
    obj,
    (success) => {
      setMessage(successMessageLanguage(success?.data?.response));
      setSuccess(true);
    },
    (error) => {
      setMessage(error);
      setSuccess(false);
    }
  );
};

export const Edit_Review = (obj, setSuccess, setMessage) => {
  Api._post(
    End_Points_Of_Api.Edit_Review,
    obj,
    (success) => {
      setMessage(successMessageLanguage(success?.data?.response));
      setSuccess(true);
    },
    (error) => {
      setMessage(error);
      setSuccess(false);
    }
  );
};

export const Forgot_Password = (obj, setSuccess, setMessage) => {
  Api._post(
    End_Points_Of_Api.Forgot_Password,
    obj,
    (success) => {
      //updateStateFunction(success?.data?.response?.detail)
      setSuccess(true);
      setMessage(successMessageLanguage(success?.data?.response));
    },
    (error) => {
      setMessage(errorMessageLanguage(error?.response?.data?.error));
      setSuccess(false);
    }
  );
};

export const Verify_Code = (obj, setSuccess, setMessage) => {
  Api._post(
    End_Points_Of_Api.Verify_Code,
    obj,
    (success) => {
      const data = success?.data?.response?.detail;
      if(data?.token) {
        localStorage.setItem("usertoken", data?.token);
      }

      if(data?.user?.first_name) {
        localStorage.setItem("firstName", data?.user?.first_name);
      }

      if(data?.user?.last_name) {
        localStorage.setItem("lastName", data?.user?.last_name);
      }

      if(data?.user?.email){
        localStorage.setItem("emailAddress", data?.user?.email);
      }

      if(data?.user?.phone_number) {
        localStorage.setItem("phone_number", data?.user?.phone_number);
      }

      if(data?.user) {
        localStorage.setItem("loginInUserAccount", JSON.stringify(data?.user));
      }
      // localStorage.setItem("CIF",res?.data?.response?.detail?.user?.CIF);
      //updateStateFunction(success?.data?.response?.detail)
      setSuccess(true);
      if(success?.data) {
        setMessage(successMessageLanguage(success?.data?.response));
      }
    },
    (error) => {
      setMessage(errorMessageLanguage(error?.response?.data?.error));
      setSuccess(false);
    }
  );
};

export const Login_Signup_Verify_Code = async (obj, success, failure, addToast = () => {}) => {
  Api._post(
    End_Points_Of_Api.Verify_Code,
    obj,
    (res) => {
      localStorage.setItem("usertoken", res.data.response.detail.token);

      localStorage.setItem("firstName", res?.data?.response?.detail?.user?.first_name);
      localStorage.setItem("lastName", res?.data?.response?.detail?.user?.last_name);
      localStorage.setItem("emailAddress", res?.data?.response?.detail?.user?.email);
      // localStorage.setItem("CIF",res?.data?.response?.detail?.user?.CIF);
      localStorage.setItem("phone_number", res?.data?.response?.detail?.user?.phone_number);

      addToast(successMessageLanguage(res?.data?.response), {
        appearance: "success",
        autoDismiss: 1000,
      });
      // localStorage.setItem(
      //   "loginInUserAccount",
      //   JSON.stringify(res?.data?.response?.detail?.user)
      // );
      success(res);
    },
    (error) => {
      addToast(successMessageLanguage(error?.response?.data?.error), {
        appearance: "error",
        autoDismiss: 1000,
      });
      failure(error);
    }
  );
};

export const Reset_password = (obj, setSuccess, setMessage) => {
  Api._post(
    End_Points_Of_Api.Reset_password,
    obj,
    (success) => {
      //updateStateFunction(success?.data?.response?.detail)
      setSuccess(true);
      setMessage(successMessageLanguage(success?.data?.response));
    },
    (error) => {
      setMessage(errorMessageLanguage(error?.response?.data?.error));
      setSuccess(false);
    }
  );
};

export const Check_email = (obj, success, error) => {
  Api._post(
    End_Points_Of_Api.Check_Email,
    obj,
    (res) => {
      success(res?.response);
    },
    (err) => {
      if (err?.response?.status === 422) {
        error(err?.response);
      }
    }
  );
};
export const Order_Cancel = (obj, setSuccess, setMessage, setSpinner) => {
  Api._post(
    End_Points_Of_Api.Order_Cancel,
    obj,
    (success) => {
      setSpinner(true);
      //updateStateFunction(success?.data?.response?.detail)
      setSuccess(true);
    },
    (error) => {
      setSpinner(true);
      setMessage(error);
      setSuccess(false);
    }
  );
};

export const Order_Dispute = (obj, setSuccess, setMessage, setSpinner) => {
  Api._post(
    End_Points_Of_Api.Order_Dispute,
    obj,
    (success) => {
      setSpinner(true);
      //updateStateFunction(success?.data?.response?.detail)
      setSuccess(true);
    },
    (error) => {
      setSpinner(true);
      setMessage(error);
      setSuccess(false);
    }
  );
};

export const Additional_Budget_Dispute = (obj, setSuccess, setMessage, setSpinner) => {
  Api._post(
    End_Points_Of_Api.Budget_Dispute,
    obj,
    (success) => {
      setSpinner(true);
      //updateStateFunction(success?.data?.response?.detail)
      setSuccess(true);
    },
    (error) => {
      setSpinner(true);
      setMessage(error);
      setSuccess(false);
    }
  );
};
export const Google_Login_Method = (
  obj,
  userDetail,
  spinner,
  setMessage,
  setShowToast,
  setSuccess,
  closeModal
) => {
  Api._post(
    End_Points_Of_Api.Google_Login,
    obj,
    (success) => {
      if (success?.status === 200) {
        userDetail(success?.data?.response?.detail?.user);
        localStorage.setItem("usertoken", success?.data?.response?.detail.token);
        spinner(false);
        setMessage(successMessageLanguage(success?.data?.response));
        setShowToast(true);
        setSuccess(true);
        closeModal();
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        spinner(false);
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};

export const Facebook_Login_Method = (
  obj,
  userDetail,
  spinner,
  setMessage,
  setShowToast,
  setSuccess,
  closeModal
) => {
  Api._post(
    End_Points_Of_Api.Facebook_Login,
    obj,
    (success) => {
      if (success?.status === 200) {
        userDetail(success?.data?.response?.detail?.user);
        localStorage.setItem("usertoken", success?.data?.response?.detail.token);
        spinner(false);
        setMessage(successMessageLanguage(success?.data?.response));
        setShowToast(true);
        setSuccess(true);
        closeModal();
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        spinner(false);
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};

export const Get_Service_Areas_By_Beneficiary_Id = (
  id,
  setMessage,
  setShowToast,
  setSuccess,
  serviceAreas
) => {
  Api._get(
    `${End_Points_Of_Api.Get_Service_Areas_By_Beneficiary_id}/${id}`,
    (success) => {
      if (success?.status === 200) {
        serviceAreas(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};

export const Get_Services_by_branchId_And_Services_Area_Id = (
  branch_id,
  service_area_id,
  setMessage,
  setShowToast,
  setSuccess,
  services,
  setIsLoading
) => {
  setIsLoading(true);
  Api._get(
    `${End_Points_Of_Api.Get_Services_by_branchId_And_Services_Area_Id}/${branch_id}/${service_area_id}`,
    (success) => {
      setIsLoading(false);
      if (success?.status === 200) {
        services(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setIsLoading(false);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        setIsLoading(false);
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};

export const Get_Services_by_Branch_id_And_Service_Area_id_and_Beneficiary_id = (
  branch_id,
  service_area_id,
  beneficiary_id,
  setMessage,
  setShowToast,
  setSuccess,
  services,
  setIsLoading
) => {
  setIsLoading(true);
  Api._get(
    `${End_Points_Of_Api.Get_Services_by_Branch_id_And_Service_Area_id_and_Beneficiary_id}/${branch_id}/bd4b5a26-521d-4904-9236-e1772ba63034/${beneficiary_id}`,
    (success) => {
      if (success?.status === 200) {
        setIsLoading(false);
        services(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setIsLoading(false);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        setIsLoading(false);
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};

export const Get_Branches_By_Service_Area_Id_By_Beneficiary_Id = (
  service_area_id,
  beneficiary_id,
  setMessage,
  setShowToast,
  setSuccess,
  branches
) => {
  Api._get(
    `${End_Points_Of_Api.Get_Branches_By_Service_Area_And_Beneficiary_Id}/bd4b5a26-521d-4904-9236-e1772ba63034/${beneficiary_id}`,
    (success) => {
      if (success?.status === 200) {
        branches(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};

export const Get_Branches_By_Service_Area_Id = (
  service_area_id,
  setMessage,
  setShowToast,
  setSuccess,
  branches
) => {
  Api._get(
    `${End_Points_Of_Api.Get_Branches_By_Service_Area_Id}/${service_area_id}`,
    (success) => {
      if (success?.status === 200) {
        branches(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};

export const Get_Services_By_Branch_Id = (id, setMessage, setShowToast, setSuccess, servces) => {
  Api._get(
    `${End_Points_Of_Api.Get_Services_By_Branch_Id}/${id}`,
    (success) => {
      if (success?.status === 200) {
        servces(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};

export const Get_Notifications = (id, setMessage, setSuccess, notifications) => {
  Api._get(
    `${End_Points_Of_Api.Get_Notifications_By_Order_Id}/${id}`,
    (success) => {
      if (success?.status === 200) {
        notifications(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setSuccess(false);
      } else {
        setMessage(netWorkErrorMessage);
        setSuccess(false);
      }
    }
  );
};
export const Read_Notification = (obj, setMessage, setSuccess) => {
  Api._post(
    End_Points_Of_Api.Read_Notification,
    obj,
    (success) => {
      if (success?.status === 200) {
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));

        setSuccess(false);
      } else {
        setMessage(netWorkErrorMessage);
        setSuccess(false);
      }
    }
  );
};
export const Read_Additional_Budget = (obj, setMessage, setSuccess) => {
  Api._post(
    End_Points_Of_Api.Additional_Budget_Read,
    obj,
    (success) => {
      if (success?.status === 200) {
        setSuccess(true);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));

        setSuccess(false);
      } else {
        setMessage(netWorkErrorMessage);
        setSuccess(false);
      }
    }
  );
};
export const Get_Service_Providers = (
  obj,
  spinner,
  setSuccess,
  setMessage,
  setShowToast,
  serviceProviders
) => {
  spinner(true);
  Api._post(
    End_Points_Of_Api.Search_Providers,
    obj,
    (success) => {
      if (success?.status === 200) {
        serviceProviders(success?.data?.response?.detail);
        spinner(false);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        spinner(false);
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};
export const Add_Profile = (
  data,
  serviceProviders,
  spinner,
  setSuccess,
  setMessage,
  setShowToast
) => {
  spinner(true);
  Api._post(
    End_Points_Of_Api.Add_Profile,
    data,
    (success) => {
      if (success?.status === 200) {
        serviceProviders(success?.data?.response?.detail);
        spinner(false);
        setSuccess(true);
        setMessage(successMessageLanguage(success?.data?.response));
        setShowToast(true);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        spinner(false);
        setMessage(netWorkErrorMessage);
        setShowToast(true);
        setSuccess(false);
      }
    }
  );
};
export const Add_Draft = (data, serviceProviders, setSuccess, setMessage, setShowToast) => {
  // spinner(true);
  Api._post(
    End_Points_Of_Api.Add_Draft,
    data,
    (success) => {
      if (success?.status === 200) {
        serviceProviders(success?.data?.response?.detail);
        setMessage(successMessageLanguage(success?.data?.response));
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setShowToast(true);
        setSuccess(false);
      } else {
        // setMessage(netWorkErrorMessage);
        // setShowToast(true);
        setSuccess(false);
      }
    }
  );
};

export const GetServiceFormById = (
  id,
  spinner,
  setSuccess,
  setMessage,
  setShowToast,
  serviceForms
) => {
  spinner(true);

  Api._get(
    `${End_Points_Of_Api.Get_Service_Form_By_Id}/${id}`,
    (success) => {
      if (success?.status === 200) {
        spinner(false);
        if (success?.data?.response?.detail !== null) {
          serviceForms(success?.data?.response?.detail?.data);
        }
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setSuccess(false);
        setShowToast(true);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        spinner(false);
        // setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};

export const Get_File_Url_Method = (formData, url, setSuccess, setMessage, setShowToast) => {
  Api._post(
    End_Points_Of_Api.Upload_File,
    formData,
    (success) => {
      if (success?.status === 200) {
        url(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setSuccess(false);
        setShowToast(true);
      } else {
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};

export const Search_Services_and_branches = (search, setSearch, setSuccess, setMessage) => {
  Api._post(
    End_Points_Of_Api.Search_Services_and_branches,
    search,
    (success) => {
      if (success?.status === 200) {
        setSearch(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setSuccess(false);
      } else {
        setMessage(netWorkErrorMessage);
        setSuccess(false);
      }
    }
  );
};

export const Add_Credit_Card_Method = (
  spinner,
  obj,
  setSuccess,
  setMessage,
  setShowToast,
  cloaseModal
) => {
  spinner(true);
  Api._post(
    End_Points_Of_Api.Add_Card,
    obj,
    (success) => {
      if (success?.status === 200) {
        setMessage(successMessageLanguage(success?.data?.response));
        spinner(false);
        setSuccess(true);
        setShowToast(true);
        cloaseModal();
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        spinner(false);
        setSuccess(false);
        setShowToast(true);
      } else {
        setMessage(netWorkErrorMessage);
        spinner(false);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};
export const Add_Primary_Card = (obj, setSuccess, setMessage, setShowToast) => {
  Api._post(
    End_Points_Of_Api.Add_Primary_Card,
    obj,
    (success) => {
      if (success?.status === 200) {
        setMessage(successMessageLanguage(success?.data?.response));
        setSuccess(success?.data?.response?.detail);
        setShowToast(true);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        setSuccess(false);
        setShowToast(true);
      } else {
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};

export const Send_Message_To_Provider_Method = (
  spinner,
  obj,
  setSuccess,
  setMessage,
  setShowToast,
  cloaseModal
) => {
  spinner(true);
  Api._post(
    End_Points_Of_Api.Send_Order_Message,
    obj,
    (success) => {
      if (success?.status === 200) {
        setMessage(successMessageLanguage(success?.data?.response));
        spinner(false);
        setSuccess(true);
        setShowToast(true);
        setTimeout(() => {
          cloaseModal();
        }, 1000);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        spinner(false);
        setSuccess(false);
        setShowToast(true);
      } else {
        setMessage(netWorkErrorMessage);
        spinner(false);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};
// send disputed message

export const Disputed_Order_Message = (
  spinner,
  obj,
  setSuccess,
  setMessage,
  setShowToast,
  cloaseModal
) => {
  spinner(true);
  Api._post(
    End_Points_Of_Api.Disputed_Order_Message,
    obj,
    (success) => {
      if (success?.status === 200) {
        setMessage(successMessageLanguage(success?.data?.response));
        spinner(false);
        setSuccess(true);
        setShowToast(true);
        setTimeout(() => {
          cloaseModal();
        }, 1000);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        spinner(false);
        setSuccess(false);
        setShowToast(true);
      } else {
        setMessage(netWorkErrorMessage);
        spinner(false);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};

export const Get_Credit_Cards = (spinner, setSuccess, setMessage, setShowToast, creditCards) => {
  spinner(true);

  Api._get(
    `${End_Points_Of_Api.Get_Credit_Cards}`,
    (success) => {
      if (success?.status === 200) {
        spinner(false);
        creditCards(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setSuccess(false);
        setShowToast(true);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        spinner(false);
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};

export const Delete_Credit_Card = (
  id,
  spinner,
  setSuccess,
  setMessage,
  setShowToast,
  creditCards,
  cloaseModal,
  addToast = () => {}
) => {
  spinner(true);

  Api._post(
    `${End_Points_Of_Api.Delete_Card}`,
    { card_id: id },
    (success) => {
      if (success?.status === 200) {
        spinner(false);
        setSuccess(true);
        setShowToast(false);
        addToast(successMessageLanguage(success?.data?.response), {
          appearance: "success",
          autoDismiss: 1000,
        });
        creditCards(success?.data?.response?.detail);
        setTimeout(() => {
          cloaseModal();
        }, 1000);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setSuccess(false);
        setShowToast(false);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
        addToast(successMessageLanguage(error?.response?.data?.error), {
          appearance: "error",
          autoDismiss: 1000,
        });
      } else {
        spinner(false);
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};

export const Add_Order_Log = (obj) => {
  Api._post(
    `${End_Points_Of_Api.Add_Bill_Log}`,
    obj,
    (success) => {
      if (success?.status === 200) {
        alert(1);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        alert(error?.response?.data?.error?.message);
      } else {
        alert("Network Error");
      }
    }
  );
};

export const Add_Order_Method = (obj, spinner, setSuccess, setMessage, setShowToast, showModal) => {
  spinner(true);

  Api._post(
    `${End_Points_Of_Api.Add_Order}`,
    obj,
    (success) => {
      if (success?.status === 200) {
        spinner(false);
        setSuccess(true);
        setMessage(successMessageLanguage(success?.data?.response));
        showModal();
        // let obj = {
        //   order_id: success?.data?.response?.details?.order_id,
        //   service_id: success?.data?.response?.details?.service_id,
        //   service_provider_id:
        //     success?.data?.response?.details?.service_provider_id,
        //   user_id: success?.data?.response?.details?.user_id,
        //   data: [{}],
        //   type: "order",
        // };
        // Add_Order_Log(obj);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setSuccess(false);
        setShowToast(true);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        // spinner(false);
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        // setShowToast(true);
      }
    }
  );
};

export const Add_New_Order_Method = (obj, spinner, success, error) => {
  spinner(true);
  Api._post(
    `${End_Points_Of_Api.Add_Order}`,
    obj,
    (res) => {
      success(successMessageLanguage(res?.data?.response));
      spinner(false);
    },
    (err) => {
      error(err);
      spinner(false);
    }
  );
};
export const Work_With_Us = (obj, setSuccess, setMessage, setIsLoading) => {
  setIsLoading(true);
  Api._post(
    `${End_Points_Of_Api.Work_With_Us}`,
    obj,
    (success) => {
      setIsLoading(false);
      if (success?.status === 200) {
        setSuccess(true);
        setMessage(successMessageLanguage(success?.data?.response));
      }
    },
    (error) => {
      setIsLoading(false);
      if (error?.response?.status === 422) {
        setSuccess(false);

        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        setMessage(netWorkErrorMessage);
        setSuccess(false);
      }
    }
  );
};

export const Help_Message = (
  obj,
  setSuccess,
  setMessage,
  setShowToast
  // setSpinner,
) => {
  Api._post(
    //  setSpinner(true)
    `${End_Points_Of_Api.Help_Message}`,
    obj,
    (success) => {
      if (success?.status === 200) {
        // setSpinner(false)

        setSuccess(true);
        setMessage(successMessageLanguage(success?.data?.response));
        setShowToast(true);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        // setSpinner(false)
        setSuccess(false);
        setShowToast(true);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        // setSpinner(false);
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};

export const Get_Orders_Method = (spinner, setSuccess, setMessage, setShowToast, Orders) => {
  spinner(true);

  Api._get(
    `${End_Points_Of_Api.Get_Orders}`,
    (success) => {
      if (success?.status === 200) {
        spinner(false);
        Orders(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setSuccess(false);
        setShowToast(true);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        spinner(false);
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};

export const Get_Draft_Order = (spinner, setSuccess, setMessage, setShowToast, Orders) => {
  spinner(true);

  Api._get(
    `${End_Points_Of_Api.Get_Draft_Order}`,
    (success) => {
      if (success?.status === 200) {
        spinner(false);
        Orders(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setSuccess(false);
        setShowToast(true);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        spinner(false);
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};
export const Get_Message_by_Order_Id = (
  id,
  spinner,
  setSuccess,
  setMessage,
  setShowToast,
  messages
) => {
  spinner(true);

  Api._get(
    `${End_Points_Of_Api.Get_Order_Message}/${id}`,
    (success) => {
      if (success?.status === 200) {
        spinner(false);
        messages(success?.data?.response?.detail?.messages);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setSuccess(false);
        setShowToast(true);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        spinner(false);
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};
// get dispute order message

export const Get_Dispute_Order_Messages = (
  id,
  spinner,
  setSuccess,
  setMessage,
  setShowToast,
  messages
) => {
  spinner(true);

  Api._get(
    `${End_Points_Of_Api.Get_Dispute_Order_Messages}/${id}`,

    (success) => {
      if (success?.status === 200) {
        spinner(false);
        messages(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setSuccess(false);
        setShowToast(true);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        spinner(false);
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};

export const Get_Profile_Method = (setMessage, setShowToast, setSuccess, userDetail) => {
  Api._get(
    End_Points_Of_Api.Get_Profile,
    (success) => {
      if (success?.status === 200) {
        userDetail(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setSuccess(false);
        setShowToast(true);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};

export const Get_Profiles_Method = (setMessage, setShowToast, setSuccess, userDetail) => {
  Api._get(
    End_Points_Of_Api.Get_Profiles,
    (success) => {
      if (success?.status === 200) {
        userDetail(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setSuccess(false);
        setShowToast(true);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};

export const Edit_Profile_Method = (
  obj,
  spinner,
  setSuccess,
  setMessage,
  setShowToast,
  showModal,
  callApiOFGetPofile
) => {
  spinner(true);

  Api._post(
    `${End_Points_Of_Api.Edit_Profile}`,
    obj,
    (success) => {
      if (success?.status === 200) {
        spinner(false);
        setSuccess(true);
        setShowToast(true);

        setMessage(successMessageLanguage(success?.data?.response));
        setTimeout(() => {
          showModal();
        }, 1000);
        callApiOFGetPofile();
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setSuccess(false);
        setShowToast(true);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        spinner(false);
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};
export const Edit_Draft_Order = (obj, spinner, setSuccess, setMessage, setShowToast) => {
  spinner(true);
  Api._post(
    `${End_Points_Of_Api.Edit_Draft_Order}`,
    obj,
    (success) => {
      if (success?.status === 200) {
        spinner(false);
        setSuccess(true);
        setShowToast(true);
        setMessage(successMessageLanguage(success?.data?.response));
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setSuccess(false);
        setShowToast(true);
        setMessage(error?.response?.data?.message);
      } else {
        spinner(false);
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};

export function Delete_Profile_Method(data, spinner, setSuccess, setMessage, setShowToast) {
  spinner(true);
  Api._post(
    `${End_Points_Of_Api.Delete_Profile}`,
    data,
    (success) => {
      if (success?.status === 200) {
        spinner(false);
        setSuccess(true);
        setShowToast(true);
        setMessage(successMessageLanguage(success?.data?.response));
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        // setSuccess(false);
        // setShowToast(true);
        // setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
}
export function Delete_Draft_Order(id, setSuccess, setMessage) {
  // spinner(true);

  Api._delete(
    `${End_Points_Of_Api.Delete_Draft_Order}/${id}`,

    (success) => {
      if (success?.status === 200) {
        setSuccess(true);
        // setShowToast(true);
        setMessage(successMessageLanguage(success?.data?.response));
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        // spinner(false);
        setSuccess(false);
        // setShowToast(true);
        // setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        // spinner(false);
        setMessage(netWorkErrorMessage);
        // setSuccess(false);
        // setShowToast(true);
      }
    }
  );
}
export function Edit_Profiles_Method(
  data,
  setSuccess,
  spinner,
  setMessage,
  setShowToast,
  showModal,
  callApiOFGetPofile
) {
  spinner(true);

  Api._post(
    `${End_Points_Of_Api.Edit_Profiles}`,
    data,
    (success) => {
      if (success?.status === 200) {
        spinner(false);
        // setSuccess(true);
        // setShowToast(true);
        // setMessage(successMessageLanguage(success?.data?.response));
        // setTimeout(() => {
        //   showModal();
        // }, 1000);
        // callApiOFGetPofile();
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        // spinner(false);
        setSuccess(false);
        setShowToast(true);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        // spinner(false);
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
}

export const Change_Password_Method = (
  obj,
  spinner,
  setSuccess,
  setMessage,
  setShowToast,
  showModal
) => {
  spinner(true);

  Api._post(
    `${End_Points_Of_Api.Change_Password}`,
    obj,
    (success) => {
      if (success?.status === 200) {
        spinner(false);
        setSuccess(true);
        setShowToast(true);
        setMessage(successMessageLanguage(success?.data?.response));
        setTimeout(() => {
          showModal();
        }, 1000);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        spinner(false);
        setSuccess(false);
        setShowToast(true);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        spinner(false);
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};

export const Get_Order_By_Id = (setMessage, setShowToast, setSuccess, id, order) => {
  Api._get(
    `${End_Points_Of_Api.Get_Order}/${id}`,
    (success) => {
      if (success?.status === 200) {
        order(success?.data?.response?.detail);
      }
    },
    (error) => {
      if (error?.response?.status === 422) {
        setSuccess(false);
        setShowToast(true);
        setMessage(errorMessageLanguage(error?.response?.data?.error));
      } else {
        setMessage(netWorkErrorMessage);
        setSuccess(false);
        setShowToast(true);
      }
    }
  );
};

// export const Check_Promo_Code_Method = (
//   obj,
//   setMessage,
//   setSuccess,
//   failure
// ) => {
//   Api._post(
//     `${End_Points_Of_Api.Check_Promo_Code}`,
//     obj,
//     (success) => {
//       if (success?.status === 200) {
//         setMessage(successMessageLanguage(success?.data?.response));
//         setSuccess(true);
//       }
//     },
//     (error) => {
//       if (error?.response?.status === 422) {
//         setSuccess(false);
//         setMessage(errorMessageLanguage(error?.response?.data?.error));
//       } else if (error?.response?.status === 409) {
//         setMessage(errorMessageLanguage(error?.response?.data?.error));
//       } else {
//         setSuccess(false);
//       }
//     }
//   );
// };
export const Check_Promo_Code_Method = (obj, success, error) => {
  Api._post(
    `${End_Points_Of_Api.Check_Promo_Code}`,
    obj,
    (res) => {
      success(res);
    },
    (err) => {
      error(err);
    }
  );
};

export const Delete_Account = async (success, failure, addToast = () => {}) => {
  Api._post(
    End_Points_Of_Api.Delete_Account,
    {},
    (res) => {
      localStorage.clear();
      window.location?.replace(window.location.origin);
      addToast(successMessageLanguage(res?.data?.response), {
        appearance: "success",
        autoDismiss: 1000,
      });

      success(res);
    },
    (error) => {
      addToast(successMessageLanguage(error?.response?.data?.error), {
        appearance: "error",
        autoDismiss: 1000,
      });
      failure(error);
    }
  );
};
