import React, { useRef, useState, useEffect } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { FaFileAlt } from "react-icons/fa";
import CustomTooltip from "./tooltip";
import { ListGroup, Form } from "react-bootstrap";
import axios from "axios";
import DownloadLink from "react-download-link";
import { Link } from "react-router-dom";
import { Images } from "../constant/images";
import labels from "../config/Labels";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Prev } from "react-bootstrap/esm/PageItem";
import ReactTooltip from "react-tooltip";

// import {fileDownload} from 'js-file-download';
const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Simple tooltip
  </Tooltip>
);

const fileDownload = (url, filename) => {
  axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res?.data, filename);
    });
};

let inputValue = "";
let checkBoxArray = [];
const DynamciInput = (props) => {
  const uploadButtonRef = useRef(null)
  const [FileName, setFileName] = useState([]);
  const [description, setDiscription] = useState("");
  const [description1, setDiscription1] = useState("");
  const [description2, setDiscription2] = useState("");
  const [description3, setDiscription3] = useState("");
  const [description4, setDiscription4] = useState("");
  const [description5, setDiscription5] = useState("");
  const [description6, setDiscription6] = useState("");
  const [description7, setDiscription7] = useState("");
  const [description8, setDiscription8] = useState("");

  const [description9, setDiscription9] = useState("");
  const [description10, setDiscription10] = useState("");

  const [label, setLabel] = useState("");
  const language = localStorage.getItem("primaryLanguage");

  // useEffect(()=>{
  //   setDiscription(description)

  // },[description])
  const labelText = (labelText) =>
    labelText[language === "english" ? "label" : "labelEsp"];
  const optionText = (optionValue) =>
    optionValue?.[language === "english" ? "option" : "optionEsp"];
  const descriptionInfo = (descriptionText) =>
    descriptionText[language === "english" ? "description" : "descriptionEsp"];
  if (props?.field?.fieldId === 1) {
    return (
      <div className="col-md-12">
        <ReactTooltip
          id={`registerTip_${String(props?.fieldIndex)}`}
          place="top"
          effect="solid"
        >
          {descriptionInfo(props?.field)}
        </ReactTooltip>
        <div className="form-group">
          <label className="d-block">
            {labelText(props?.field)}
            <span className="float-right">
              {descriptionInfo(props?.field)?.length ? (
                <BiInfoCircle
                  data-tip
                  data-for={`registerTip_${String(props?.fieldIndex)}`}
                  size={20}
                />
              ) : null}
            </span>
          </label>
          <input
            className="form-control"
            type="text"
            name="name"
            autoComplete="off"
            placeholder={descriptionInfo(props?.field)}
            onChange={(e) => {
              inputValue = e?.target?.value;
              if (props.field?.value !== undefined) {
                props?.updatedFieldValue(
                  inputValue,
                  props?.field?.fieldId,
                  props?.field?.label,
                  props?.field?.labelEsp
                );
                inputValue = "";
              }
            }}
            value={props?.field?.value && props?.field?.value}
            onBlur={() => {
              if (inputValue !== "") {
                props?.updatedFieldValue(
                  inputValue,
                  props?.field?.fieldId,
                  props?.field?.label,
                  props?.field?.labelEsp
                );
                inputValue = "";
              }
            }}
          />
        </div>
      </div>
    );
  } else if (props?.field?.fieldId === 2) {
    return (
      <div className="col-md-12">
        <ReactTooltip
          id={`registerTip1_${String(props?.fieldIndex)}`}
          place="top"
          effect="solid"
        >
          {descriptionInfo(props?.field)}
        </ReactTooltip>
        <div className="form-group">
          <label className="d-block">
            {labelText(props?.field)}
            <span className="float-right">
              {descriptionInfo(props?.field)?.length ? (
                <BiInfoCircle
                  size={20}
                  data-tip
                  data-for={`registerTip1_${String(props?.fieldIndex)}`}
                />
              ) : null}
            </span>
          </label>
          <textarea
            className="form-control"
            type="text"
            name="name"
            autoComplete="none"
            placeholder={descriptionInfo(props?.field)}
            value={props?.field?.value && props?.field?.value}
            onChange={(e) => {
              inputValue = e?.target?.value;
              if (props.field?.value !== undefined) {
                props?.updatedFieldValue(
                  inputValue,
                  props?.field?.fieldId,
                  props?.field?.label,
                  props?.field?.labelEsp
                );
                inputValue = "";
              }
            }}
            onBlur={() => {
              if (inputValue !== "") {
                props?.updatedFieldValue(
                  inputValue,
                  props?.field?.fieldId,
                  props?.field?.label,
                  props?.field?.labelEsp
                );
                inputValue = "";
              }
            }}
          />
        </div>
      </div>
    );
  } else if (props?.field?.fieldId === 3) {
    return (
      <div className="col-md-12">
        <ReactTooltip
          id={`registerTip2_${String(props?.fieldIndex)}`}
          place="top"
          effect="solid"
        >
          {descriptionInfo(props?.field)}
        </ReactTooltip>
        <div className="form-group">
          <label className="d-block">
            {labelText(props?.field)}
            <span className="float-right">
              {descriptionInfo(props?.field)?.length ? (
                <BiInfoCircle
                  size={20}
                  data-tip
                  data-for={`registerTip2_${String(props?.fieldIndex)}`}
                />
              ) : null}
            </span>
          </label>
          <select
            className="form-control select-input-style"
            type="text"
            name="name"
            value={props?.field?.value && props?.field?.value}
            onChange={(e) => {
              inputValue = e?.target?.value;
              if (props.field?.value !== undefined) {
                props?.updatedFieldValue(
                  inputValue,
                  props?.field?.fieldId,
                  props?.field?.label,
                  props?.field?.labelEsp
                );
                inputValue = "";
              }
            }}
            onBlur={() => {
              if (inputValue !== "") {
                props?.updatedFieldValue(
                  inputValue,
                  props?.field?.fieldId,
                  props?.field?.label,
                  props?.field?.labelEsp
                );
                inputValue = "";
              }
            }}
          >
            <option value="" disabled hidden selected>
              {" "}
            </option>
            {props.field.menu.map((m) => {
              return <option value={m.option}>{optionText(m)}</option>;
            })}
          </select>
        </div>
      </div>
    );
  } else if (props?.field?.fieldId === 4) {
    return (
      <div className="col-md-12">
        <ReactTooltip
          id={`registerTip3_${String(props?.fieldIndex)}`}
          place="top"
          effect="solid"
        >
          {descriptionInfo(props?.field)}
        </ReactTooltip>
        <div className="form-group">
          <label className="d-block">
            {labelText(props?.field)}
            <span className="float-right">
              {descriptionInfo(props?.field)?.length ? (
                <BiInfoCircle
                  size={20}
                  data-tip
                  data-for={`registerTip3_${String(props?.fieldIndex)}`}
                />
              ) : null}
            </span>
          </label>
          <div
            onClick={() => uploadButtonRef.current.click()}
            className="select-input-style h-50 border-input-style d-flex justify-content-center align-items-center"
            style={{ background: "transparent", minHeight:"70px", cursor: "pointer", }}
          >
            <FaFileAlt color="#46bcff" />
            <span className="">
              {props.muiltipleFiles !== "" && label == props?.field?.label ? (
                <span style={{ color: "#46bcff" }}>{FileName}</span>
              ) : (
                labels.uploadFile
              )}
            </span>
          </div>
          <input
            ref={uploadButtonRef}
            id="file"
            className="form-control z-index-10"
            type="file"
            multiple={true}
            accept=".jpg, .jpeg, .png, .doc, .docx, .docs, .pdf, .xls, .csv, .xslx , .docx , .xlsx"
            style={{ cursor: "pointer", display:"none" }}
            value={""}
            name="name"
            placeholder=""
            onChange={(e) => {
              setLabel(props?.field?.label);
              let fileArray = [];
              // fileArray.push(f)
              var filessss = e?.target?.files;
              for (let a = 0; a <= filessss.length; a++) {
                fileArray.push({
                  url: filessss[a],
                  name: filessss[a]?.name,
                  type: filessss[a]?.type,
                });
                //  setFileName((Prev)=>[Prev,...filessss[a]?.name])
                setFileName(a);
              }

              // setFileArray((Prev)=>[...Prev,{url:f,name:f?.name,type:f.type}])

              props?.openDocumentMultiple(
                fileArray,
                props?.field?.fieldId,
                props?.field.label,
                props?.field?.labelEsp
              );
            }}
          />
        </div>
      </div>
    );
  } else if (props?.field?.fieldId === 6) {
    return (
      <div className="col-md-12">
        <div className="form-group h-50">
          <ReactTooltip
            id={`registerTip333_${String(props?.fieldIndex)}`}
            place="top"
            effect="solid"
          >
            {descriptionInfo(props?.field)}
          </ReactTooltip>
          <label className="d-block">
            {props?.field?.label}
            <span className="float-right">
              {descriptionInfo(props?.field)?.length ? (
                <BiInfoCircle
                  size={20}
                  data-tip
                  data-for={`registerTip333_${String(props?.fieldIndex)}`}
                />
              ) : null}
            </span>
          </label>
          <input
            className="form-control "
            type="text"
            name="name"
            autoComplete="none"
            placeholder={labels.location}
            value={props?.field?.value && props?.field?.value}
            onChange={(e) => (inputValue = e?.target?.value)}
            onBlur={() => {
              if (inputValue !== "") {
                props?.updatedFieldValue(
                  inputValue,
                  props?.field?.fieldId,
                  props?.field?.label,
                  props?.field?.labelEsp
                );
                inputValue = "";
              }
            }}
          />
        </div>
      </div>
    );
  } else if (props?.field?.fieldId === 7) {
    return (
      <div className="col-md-12">
        <div className="form-group d-flex justify-content-between align-items-end">
          <ReactTooltip
            id={`registerTip135_${String(props?.fieldIndex)}`}
            place="top"
            effect="solid"
          >
            {descriptionInfo(props?.field)}
          </ReactTooltip>
          <h6 className="d-block font-weight-bold m-0">
            {labelText(props.field)}
          </h6>
          <span className="float-right width20 text-lg-right ">
            {descriptionInfo(props?.field)?.length ? (
              <BiInfoCircle
                size={20}
                data-tip
                data-for={`registerTip135_${String(props?.fieldIndex)}`}
              />
            ) : null}

            <input
              className="form-control"
              // style={{ width: '30%' }}

              type="text"
              name="name"
              placeholder=""
              onChange={(e) => (inputValue = e?.target?.value)}
              value={props?.field?.value && props?.field?.value}
              onBlur={() => {
                if (inputValue !== "") {
                  props?.updatedFieldValue(
                    inputValue,
                    props?.field?.fieldId,
                    props?.field?.label,
                    props?.field?.labelEsp
                  );
                  inputValue = "";
                }
              }}
            />
          </span>
        </div>
      </div>
    );
  } else if (props?.field?.fieldId === 8) {
    return (
      <div className="col-md-12">
        <ReactTooltip
          id={`registerTip20_${String(props?.fieldIndex)}`}
          place="top"
          effect="solid"
        >
          {descriptionInfo(props?.field)}
        </ReactTooltip>

        <div className="form-group d-flex flex-column justify-content-between align-items-end ">
          <div className="w-100">
            <label className="d-block">
              {labelText(props?.field)}
              <span className="float-right">
                {descriptionInfo(props?.field)?.length ? (
                  <BiInfoCircle
                    size={20}
                    data-tip
                    data-for={`registerTip20_${String(props?.fieldIndex)}`}
                  />
                ) : null}
              </span>
            </label>
          </div>
          <div className="d-flex flex-row flex-wrap">
            {props?.field?.files.map((item, index) => {
              return (
                <>
                  <a
                    key={index}
                    href={item?.url}
                    download
                    target="_blank"
                    className="btn1  border-1 border-secondary m-1  text-lowercase font-weight-light  text-info"
                  >
                    {" "}
                    {item.type === "application/msword" ? (
                      <img
                        src={Images.doc.default}
                        className="mr-2"
                        style={{ width: "18px", height: "18px" }}
                      />
                    ) : null}
                    {item.type === "application/vnd.ms-excel" ? (
                      <img
                        src={Images.xls.default}
                        className="mr-2"
                        style={{ width: "18px", height: "18px" }}
                      />
                    ) : null}
                    {item.type === "application/pdf" ? (
                      <img
                        src={Images.pdf.default}
                        className="mr-2"
                        style={{ width: "18px", height: "18px" }}
                      />
                    ) : null}
                    {item.type === "image/jpeg" ||
                    item.type === "image/png" ||
                    item.type === "image/jpg" ? (
                      <img
                        src={Images.image.default}
                        className="mr-2"
                        style={{ width: "18px", height: "18px" }}
                      />
                    ) : null}
                    {
                      <span className="fs-12">{`${item.name.substring(
                        0,
                        25
                      )}`}</span>
                    }
                  </a>
                  <br />
                </>
              );
            })}
          </div>
        </div>
      </div>
    );
  } else if (props?.field?.fieldId === 9) {
    return (
      <div className="col-md-12 mt-15">
        <ReactTooltip
          id={`registerTip1_${String(props?.fieldIndex)}`}
          place="top"
          effect="solid"
        >
          {descriptionInfo(props?.field)}
        </ReactTooltip>
        <div className="form-group">
          <div>
            <label className="d-block">
              {labelText(props?.field)}
              <span className="float-right">
                {descriptionInfo(props?.field)?.length ? (
                  <BiInfoCircle
                    size={20}
                    data-tip
                    data-for={`registerTip1_${String(props?.fieldIndex)}`}
                  />
                ) : null}
              </span>
            </label>
            <div>
              <ListGroup variant="flush">
                {props.field.menu.map((item) => {
                  return (
                    <ListGroup.Item className="border-0 p-1">
                      <input
                        defaultChecked={
                          props?.field?.value &&
                          props?.field?.value === item?.option
                            ? true
                            : false
                        }
                        type="radio"
                        value={item?.option}
                        name={props.field.label}
                        onChange={(e) => (inputValue = e?.target?.value)}
                        onBlur={() => {
                          if (inputValue !== "") {
                            props?.updatedFieldValue(
                              inputValue,
                              props?.field?.fieldId,
                              props?.field?.label,
                              props?.field?.labelEsp
                            );
                            inputValue = "";
                          }
                        }}
                      />
                      <span className="ml-2 fs-14">{optionText(item)}</span>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (props?.field?.fieldId === 10) {
    return (
      <div className=" col-md-12">
        <ReactTooltip
          id={`registerTip19_${String(props?.fieldIndex)}`}
          place="top"
          effect="solid"
        >
          {descriptionInfo(props?.field)}
        </ReactTooltip>

        <div className="form-group">
          <label className="d-block">
            {labelText(props?.field)}
            <span className="float-right">
              {descriptionInfo(props?.field)?.length ? (
                <BiInfoCircle
                  size={20}
                  data-tip
                  data-for={`registerTip19_${String(props?.fieldIndex)}`}
                />
              ) : null}
            </span>
          </label>
          <ListGroup variant="flush">
            {props.field.menu.map((item, index) => {
              return (
                <ListGroup.Item className="border-0 p-1" key={index}>
                  <input
                    type="checkbox"
                    className="bg-danger"
                    defaultChecked={
                      props?.field?.value &&
                      props?.field?.value?.includes(item?.option)
                        ? true
                        : false
                    }
                    value={item.option}
                    name={props.field.label}
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        let check = checkBoxArray?.includes(e.target.value);
                        if (check === false) {
                          checkBoxArray.push(e.target.value);
                        } else {
                          checkBoxArray.splice(index, 1);
                        }
                        props?.updatedFieldValue(
                          checkBoxArray,
                          props?.field?.fieldId,
                          props?.field?.label,
                          props?.field?.labelEsp
                        );
                        checkBoxArray = [];
                      }
                    }}
                  />
                  <span className="ml-2 fs-14">{optionText(item)}</span>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </div>
      </div>
    );
  } else if (props?.field?.fieldId === 11) {
    return (
      <div className="col-md-12">
        <ReactTooltip
          id={`registerTip119_${String(props?.fieldIndex)}`}
          place="top"
          effect="solid"
        >
          {descriptionInfo(props?.field)}
        </ReactTooltip>
        <div className="form-group">
          <label className="d-block">
            {labelText(props?.field)}
            <span className="float-right">
              {descriptionInfo(props?.field)?.length ? (
                <BiInfoCircle
                  size={20}
                  data-tip
                  data-for={`registerTip119_${String(props?.fieldIndex)}`}
                />
              ) : null}
            </span>
          </label>
          <div className="input-group date mt-15" data-provide="datepicker">
            <input
              type="date"
              className="form-control"
              defaultValue={props?.field?.value}
              onChange={(e) => (inputValue = e?.target?.value)}
              onBlur={() => {
                if (inputValue !== "") {
                  props?.updatedFieldValue(
                    inputValue,
                    props?.field?.fieldId,
                    props?.field?.label,
                    props?.field?.labelEsp
                  );
                  inputValue = "";
                }
              }}
            />
            <div className="input-group-addon">
              <span className="glyphicon glyphicon-th"></span>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (props?.field?.fieldId === 12) {
    return (
      <div className="col-md-12">
        <div className="py-3">
          <span className="fs-14">{descriptionInfo(props?.field)}</span>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default DynamciInput;
