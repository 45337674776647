import moment from "moment";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";

import labels from "../config/Labels";
import { rating, regexForFormatRating } from "../config/Utility";
import InformationContainer from "./informationContainer";

function ProviderInfoModal(props) {
  const lang = localStorage.getItem("primaryLanguage");
  const { ratings } = props?.info || {};
  return (
    <Modal
      target={props.target}
      show={props.show}
      onHide={props.onHide}
      animation={true}
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header
        closeButton
        className="d-flex justify-content-center border-0"
      >
        <Modal.Title id="contained-modal-title-vcenter ">
          {labels.information}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{
          height: "300px",
          overflowY: "auto",
        }}
      >
        <Row>
          <Col lg={3} className="pr-0">
            <img
              src={props?.info?.avatar}
              alt="Shop item in cart"
              style={{
                borderRadius: 10,
                width: "90px",
                height: "90px",
                objectFit: "contain",
              }}
            />
          </Col>

          <Col lg={9} className="px-0 ">
            <div className=" ">
              <div className="">
                <strong>{props?.info?.name}</strong>
              </div>
              <div className="fontSize14px  h-100 mt-1">
                <div style={{ lineHeight: 1.5 }} className="w-100">
                  {labels.position}:{" "}
                  <strong>
                  {(props?.info?.[lang === "english" ? "position" : "position_sp"] || "N/A")?.substring(0,35)}
                  {(props?.info?.[lang === "english" ? "position" : "position_sp"] || "N/A")?.length > 35 ? "... " : ""}
                  </strong>
                </div>
                <div style={{ lineHeight: 1.5 }}>
                  {labels.experience}:{" "}
                  <strong>
                    {props.info?.experience}
                    {labels.years}
                  </strong>
                </div>
                <div style={{ lineHeight: 1.5 }}>
                  {labels.rating}:{" "}
                  <>
                  <span>
                                {regexForFormatRating(
                                  props?.info?.fake_rating != null
                                    ? Number(props?.info?.fake_rating)
                                    : Number(
                                        !props?.info?.average_rating?.length
                                          ? "0"
                                          : props?.info?.average_rating
                                      )
                                )}
                                <span style={{ color: "#FED42D" }}>
                                  {rating(
                                    props?.info?.fake_rating != null
                                      ? Number(props?.info?.fake_rating)
                                      : Number(
                                          !props?.info?.average_rating?.length
                                            ? "0"
                                            : props?.info?.average_rating
                                        )
                                  )}
                                </span>
                              </span>
                  </>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <InformationContainer
          info={
            props?.info?.info == null
              ? ""
              : props?.info?.[lang === "english" ? "info" : "info_sp"]
          }
        />
        {ratings?.filter((item) => item?.status !== 0)?.length > 0 ? (
          <div>
            <div className="mb-2">
              <strong className="fontSize16px">{labels.reviews}</strong>
            </div>
            {ratings
              ?.filter((item) => item?.status !== 0)
              ?.map((item, index) => (
                <div className="media mt-2" key={index}>
                  <div
                    className="align-self-start mr-3 rounded-circle d-flex justify-content-center align-items-center shadow-sm"
                    style={{
                      width: 45,
                      height: 45,
                      backgroundColor: "#46bcff7a",
                    }}
                  >
                    <div className="text-secondary fw-bold ">
                      {item?.user?.first_name?.charAt(0)}
                    </div>
                  </div>
                  <div className="media-body fontSize14px">
                    <strong className="mb-0">
                      {item?.user?.first_name} {item?.user?.last_name}
                    </strong>
                    <div style={{ lineHeight: 0.4 }}>
                      <small className="">
                        {moment(item?.created_at).fromNow()}
                      </small>

                      <small
                        className="text-secondary"
                        style={{ opacity: 0.8 }}
                      >
                        {" | "}
                      </small>
                      <small style={{ color: "#FED42D" }}>
                        {rating(item?.rate)} {item?.rate}
                      </small>
                    </div>
                    <p className="mt-2">{item?.review}</p>
                  </div>
                </div>
              ))}
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer className="border-0"></Modal.Footer>
    </Modal>
  );
}
export default ProviderInfoModal;
