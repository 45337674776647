import React from "react";

function NataliaIntroEnglish() {
  return (
    <div>
      <p>Lawyer.</p>
      <p>
        Double degree in Law and Business Administration from the Complutense
        University of Madrid. While she worked at “ESTUDIO JURÍDICO BUSTAMANTE,
        S.L.P.”, she did a master's degree in “Business legal advice”.
      </p>
      <p>
        From September 2012 to the present, she is a lawyer at "GRUPO GRANSOLAR,
        S.L."
      </p>
      <p>
        Among others, he manages corporate management and operations, as well as
        the secretariat of the board of the companies that make up the group,
        and of the more than 250 companies that own plants whose management is
        the responsibility of Gransolar, including: preparation of formulation
        and approval minutes of annual accounts, appointment and removal of
        members of the administrative bodies, granting of powers, keeping up to
        date of corporate books, incorporation of companies, increases and
        reductions of capital, dissolution and liquidation of companies,
        coordination with notaries for elevation to public of different legal
        acts.
      </p>
    </div>
  );
}

export default NataliaIntroEnglish;
