import React, { useState,useRef } from "react";
import { Modal, ModalBody, Tab, Tabs, Row, Col } from "react-bootstrap";
import { Images } from "../../constant/images";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import renderHTML from "react-render-html";
import Moment from "react-moment";
import {
  Get_File_Url_Method,
  Get_Message_by_Order_Id,
  Send_Message_To_Provider_Method,
  Get_Notifications,
  Disputed_Order_Message,
  Get_Dispute_Order_Messages,
} from "../../config/api";
import moment from "moment";
import ToastComponent from "../../component/toast";
import { FaFileAlt } from "react-icons/fa";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import ChatBubble from "react-chat-bubble";

import "./specificordermessage.css";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { type } from "jquery";
import labels from "../../config/Labels";
const urlArray = [];
export default function DisputedOrderMessage(props) {
  
  const params = useParams();
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [value, setValue] = useState("");
  const [orderMessage, setOrderMessage] = useState("");
  const [docs, setDocs] = useState([]);
  const [muiltipleDocument, setMuiltipleDocument] = useState("");
  const [MyMessages, setMyMessages] = useState([]);
  const [providerMessages, setProviderMessages] = useState([]);
  const [count, setCount] = useState("");
  const [notifications, setNotifications] = useState([]);
  const { push } = useHistory();

  const [tab, setTab] = useState("home");

 
  function scrollToBottom() {
    document
      .getElementById("messagesArea")
      ?.scrollTo({ bottom: 0, behavior: "smooth" });
   
  }
  let history = useHistory();
  React.useEffect(() => {
    if (!props.location.state.dispute) {
      getMessagesByOrderId(params.id);
    } else {
      getDisputeOrderMessages(params.id);
    }
    getNotifications();
    //getNotifications
  }, []);
  const getNotifications = () => {
    Get_Notifications(
      (value) => {
      
      },
      (value) => {
       
      },
      (value) => {
        setNotifications(value);
      }
    );
  };

  React.useEffect(() => {
    if (!props.location.state.dispute) {
      getMessagesByOrderId(params.id);
      scrollToBottom();
    } else {
      getDisputeOrderMessages(params.id);
      scrollToBottom();
    }

    const interval = setInterval(() => {
      if (!props.location.state.dispute) {
        getMessagesByOrderId(params.id);
      } else {
        getDisputeOrderMessages(params.id);
      }
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {}, [messages?.length]);

  const getMessagesByOrderId = (id) => {
    Get_Message_by_Order_Id(
      id,
      (value) => {
        setShowSpinner(value);
      },
      (value) => {
        setSuccess(value);
        if (value == true) {
          getMessagesByOrderId(params.id);
        }
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
        setMyMessages(value.reverse());
      }
    );
  };
  // get Dispute order messages
  const getDisputeOrderMessages = (id) => {
    Get_Dispute_Order_Messages(
      id,
      (value) => {
        setShowSpinner(value);
      },
      (value) => {
        setSuccess(value);
        if (value) {
          getDisputeOrderMessages(params.id);
        }
      },
      (value) => {
        setMessage(value);
      },
      (value) => {
        setShowToast(value);
      },
      (value) => {
      

        setMyMessages(value);
      }
    );
  };

  const messagesEndRef = useRef();

  const sendMessageToProvider = () => {
    if (orderMessage !== "" || muiltipleDocument !== "") {
      let obj = {
        order_id: params.id,
        is_provider_message: 0,
        message: JSON.stringify([
          {
            message: orderMessage,
            documents: docs?.length === 0 ? null : docs,
          },
        ]),
      };
      Send_Message_To_Provider_Method(
        (value) => {
          setShowSpinner(value);
        },
        obj,
        (value) => {
          setSuccess(value);
        },
        (value) => {
          setMessage(value);
          addToast(value, { appearance: "info", autoDismiss: "150" });
        },
        (value) => {
          setShowToast(value);
        },
        () => {
          setShowMessagePopup(false);
          setOrderMessage("");
          setDocs([]);
          setMuiltipleDocument("");
        }
      );
      setTimeout(() => {
        // scrollToBottom();
      }, 4000);
    } else {
      setShowToast(true);
      setMessage(labels.allFieldsAreRequired);
    }
  };

  //send disputed message
  const sendDisputeOrderMessage = () => {
    if (orderMessage !== "" || muiltipleDocument !== "") {
      let obj = {
        order_id: params.id,
        is_provider_message: 0,
        is_admin_message: 0,
        message: JSON.stringify([
          {
            message: orderMessage,
            documents: docs?.length === 0 ? null : docs,
          },
        ]),
      };
      Disputed_Order_Message(
        (value) => {
          setShowSpinner(value);
        },
        obj,
        (value) => {
          setSuccess(value);
        },
        (value) => {
          setMessage(value);
          addToast(value, { appearance: "info", autoDismiss: "150" });
        },
        (value) => {
          setShowToast(value);
        },
        () => {
          setShowMessagePopup(false);
          setOrderMessage("");
          setDocs([]);
          setMuiltipleDocument("");
        }
      );
      setTimeout(() => {
        
      }, 4000);
    } else {
      setShowToast(true);
      setMessage(labels.allFieldsAreRequired);
    }
  };

  const closeToast = () => {
    setShowToast(false);
  };

  const getUrl = (url, name, type) => {
    setDocs((prevurls) => [...prevurls, { url, name, type }]);
  };
  const selectMuiltipleDocument = async (valueArray) => {
    let muiltipleFileNames = "";
    valueArray.map((r, i) => {
      muiltipleFileNames += `${r.name}, `;
    });

    valueArray?.map((file, i) => {
      let formData = new FormData();
      formData.append("file", file);
      Get_File_Url_Method(
        formData,
        (value) => {
          getUrl(value, file.name, file.type);
        },
        (value) => {
          setSuccess(value);
        },
        (value) => {
          setMessage(value);
        },
        (value) => {
          setShowToast(value);
        }
      );
    });
    setMuiltipleDocument(muiltipleFileNames);
  };
  const docsChecks = MyMessages?.map((item) => item?.message[0]?.documents);
  
  const messagess = [
    {
      type: 0,
      image: "cat.jpg",
      text: "Hello! Good Morning!",
    },
    {
      type: 0,
      image: "dog.jpg",
      text: "Hello! Good Afternoon!",
    },
  ];
  const { addToast } = useToasts();
  return (
    <div className="container-fluid px-6 marginTop100 d-flex justify-content-center">
     <div className="d-flex ml-1 cursorPointer"  onClick={() => {
                history.goBack();
              }}>
            <img
              src={Images.backArrow.default}
              style={{ width: "40px", height: "40px" }}
              className=" mt-30"
             
            />{" "}
          </div>
      <Row>
     
        <Col
          lg={6}
          md={10}
          xs={10}
          sm={10}
          className="d-flex justify-content-center mt-60"
        >
          <Col
            lg={12}
            sm={10}
            md={10}
            xs={10}
            id={"messagesArea"}
            ref={messagesEndRef}
            className="messageContainer py-3 scrollable-element chat-Container d-flex flex-column border-Radius"
            style={{ overflowY: "scroll", height: "700px", width: "800px" }}
          >
            <div className="mr-3">
              {MyMessages?.map((item, index) =>
                item.is_provider_message === "0" &&
                item.is_admin_message === "0" &&
                item?.message[0]?.message?.length !== 0 ? (
                  <>
                   
                    <div className="incoming_msg">
                      {item?.message[0]?.message !== "<p><br></p>" ? (
                        <div className="received_msg p-1">
                          <div className="received_withd_msg">
                            <p>
                              {" "}
                              {renderHTML(
                                item?.message[0] != "<p><br></p>" &&
                                  item?.message[0]?.message
                              )}
                            </p>
                            <span className="d-flex justify-content-between time_date">
                              <span>
                                {" "}
                                {`${moment(item?.created_at).format(
                                  "h:mm:ss a"
                                )} | ${moment(item?.created_at).format(
                                  "DD-MM-YYYY"
                                )}`}
                                <span />
                              </span>{" "}
                              <span>{labels.user}</span>
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : (
                  <>
                    {item?.message?.[0]?.message?.length !== 0 && (
                      <div className="outgoing_msg p-1">
                        {item?.message[0]?.message !== null &&
                        item?.message[0]?.message !== "<p><br></p>" ? (
                          <div className="sent_msg">
                            <p> {renderHTML(item?.message[0]?.message)}</p>
                            {/* <p>{item?.message[0]!==null && item?.message[0]?.message}</p> */}
                            <span className="d-flex justify-content-between time_date">
                              <span>
                                {" "}
                                {`${moment(item?.created_at).format(
                                  "h:mm:ss a"
                                )} | ${moment(item?.created_at).format(
                                  "DD-MM-YYYY"
                                )}`}
                                <span />
                              </span>{" "}
                              {item.is_provider_message == "1" && (
                                <span>{labels.provider}</span>
                              )}
                              {item?.is_admin_message == "1" && (
                                <span>{labels.admin}</span>
                              )}
                            </span>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </>
                )
              )}
            </div>

            <br />
          </Col>
        </Col>

        <Col lg={6} sm={10} xs={10} md={10}>
          <div
            style={{ height: "300px", width: "800px" }}
            className="contentPadding"
          >
            <Tabs
              defaultActiveKey="home"
              activeKey={tab}
              onSelect={(e) => setTab(e)}
              id="uncontrolled-tab-example"
              className="nav nav-tabs customtab mb-10 d-flex justify-content-end  "
            >
              <Tab eventKey="home" title={labels.myDocuments}>
                <div
                  className="messageContainer chat-Container mt-20 "
                  style={{ overflowY: "scrollY", height: "240px" }}
                >
                  <div
                    className="row Document-Container ql-editor2 "
                    style={{ overflowY: "scroll", height: "240px" }}
                  >
                    {MyMessages?.map((item, index) => {
                    
                      return item?.message?.[0]?.documents?.length !== "0" ? (
                        <div className="col-sm-10 ">
                          {item.is_provider_message === "0" &&
                            item.message[0]?.documents?.map((item) => item)
                              ?.length &&
                            item.message[0]?.documents?.map((item, index) => {
                              return (
                                <a
                                  href={item.url}
                                  target="_blank"
                                  className="row alignItemsCenter ml-1 p-2"
                                  download
                                >
                                  <img
                                    className="documentImageSize"
                                    src={
                                      item !== "null" &&
                                      Images?.document?.default
                                    }
                                  />
                                  {item?.length !== "null" && (
                                    <h6 className="ml-1 mb-1 text-info">{item.name}</h6>
                                  )}
                                </a>
                              );
                            })}
                        </div>
                      ) : (
                        <div>
                          <div className="d-flex justify-content-center mt-60 row ">
                            {labels.noDocuments}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="menu" title={labels.providerDocuments}>
                <div
                  className="messageContainer chat-Container mt-20"
                  style={{ overflowY: "scrollY", height: "240px" }}
                >
                  <div
                    className="row Document-Container ql-editor2"
                    style={{ overflowY: "scroll", height: "240px" }}
                  >
                    {MyMessages?.map((item, index) => {
                     
                      return item?.message?.[0]?.documents?.length !== "0" ? (
                        <div className="col-sm-10">
                        
                          {item.is_provider_message === "1" &&
                            item.message[0]?.documents?.map((item) => item)
                              ?.length &&
                            item.message[0]?.documents?.map((item, index) => {
                              return (
                                <a
                                  href={item.url}
                                  target="_blank"
                                  className="row alignItemsCenter ml-1"
                                  download
                                >
                                  <img
                                    className="documentImageSize"
                                    src={
                                      item !== "null" &&
                                      Images?.document?.default
                                    }
                                  />
                                  {item?.length !== "null" && (
                                    <h6 className="ml-1 mb-1 text-info">{item?.name}</h6>
                                  )}
                                </a>
                              );
                            })}
                        </div>
                      ) : (
                        <div>
                          <div className="d-flex justify-content-center mt-60 row ">
                            {labels.noDocuments}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Tab>
              <Tab eventKey="menu1" title={labels.adminDocuments}>
                <div
                  className="messageContainer chat-Container mt-20"
                  style={{ overflowY: "scrollY", height: "240px" }}
                >
                  <div
                    className="row Document-Container ql-editor2"
                    style={{ overflowY: "scroll", height: "240px" }}
                  >
                    {MyMessages?.map((item, index) => {
                     
                      return item?.message?.[0]?.documents?.length !== "0" ? (
                        <div className="col-sm-10">
                        
                          {item.is_admin_message === "1" &&
                            item.message[0]?.documents?.map((item) => item)
                              ?.length &&
                            item.message[0]?.documents?.map((item, index) => {
                              return (
                                <a
                                  href={item.url}
                                  target="_blank"
                                  className="row alignItemsCenter ml-1"
                                  download
                                >
                                  <img
                                    className="documentImageSize"
                                    src={
                                      item !== "null" &&
                                      Images?.document?.default
                                    }
                                  />
                                  {item?.length !== "null" && (
                                    <h6 className="ml-1 mb-1 text-info">{item?.name}</h6>
                                  )}
                                </a>
                              );
                            })}
                        </div>
                      ) : (
                        <div>
                          <div className="d-flex justify-content-center mt-60 row ">
                            {labels.noDocuments}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
          <div style={{height:"80px"}}>

          </div>

          <div
            className="richTextContainer messageToProviderContainer mb-0 "
            style={{ width: "800px" }}
          >
            <ReactQuill
              className={"richTextBox mx-0 mb-0"}
              theme="snow"
              value={orderMessage}
              onChange={setOrderMessage}
            />
          </div>
          <div>
            <div className="d-flex justify-content-end  ">
              <span></span>
              <span className="form-group  z-index-10 mx-1 ">
                <div
                  className="select-input-style border-input-style position-absolute d-flex justify-content-center align-items-center border-Radius"
                  style={{ bottom: 0, background: "transparent" }}
                >
                  <FaFileAlt color="#46bcff" />

                  {muiltipleDocument !== "" ? (
                    <span style={{ color: "#46bcff" }} className="ml-2">
                      {"file Uploaded"}
                    </span>
                  ) : (
                    <span className="ml-2">{labels.uploadFiles}</span>
                  )}
                </div>
                <input
                  className="form-control cursorPointer "
                  type="file"
                  multiple
                  accept=".jpg, .jpeg, .png, .doc, .docx, .docs, .pdf, .xls, .csv, .xslx , .docx , .xlsx"
                  style={{ opacity: 0, cursor: "pointer" }}
                  value={""}
                  name="name"
                  placeholder=""
                  onChange={(e) => {
                    let f = e.target.files;
                    let fileArray = [];
                    for (var i = 0; i < f?.length; i++) {
                      fileArray.push(f[i]);
                    }
                    selectMuiltipleDocument(fileArray);
                  }}
                />
              </span>

              <div className="textAlignCenter mt-20 mb-20 ">
                <a
                  className="btn btn-primary"
                  data-toggle="modal"
                  onClick={() => {
                    props.location.state.dispute == false
                      ? sendMessageToProvider()
                      : sendDisputeOrderMessage();
                  }}
                >
                  {labels.submit}
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        show={showMessagePopup}
        onHide={() => setShowMessagePopup(false)}
        size="lg"
      >
        <ModalBody className="bg-white p-0 border-radius-10 alignItemsCenter">
          <button
            type="button"
            className="close  absolute"
            data-dismiss="modal"
            onClick={() => setShowMessagePopup(false)}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="richTextContainer">
            <ReactQuill
              className={"richTextBox"}
              theme="snow"
              value={orderMessage}
              onChange={setOrderMessage}
            />

            <div className="d-flex justify-content-center">
              <div className="form-group">
                <div
                  className="select-input-style border-input-style  d-flex justify-content-center align-items-center border-Radius"
                  style={{ bottom: 0, background: "transparent" }}
                >
                  <FaFileAlt color="#46bcff" />
                  {muiltipleDocument !== "" ? (
                    <span className="ml-2">{muiltipleDocument}</span>
                  ) : (
                    <span className="ml-2">{labels.uploadFiles}</span>
                  )}
                </div>
                <input
                  className="form-control cursorPointer "
                  type="file"
                  multiple
                  // style={{ opacity: 0, cursor: "pointer" }}
                  value={""}
                  name="name"
                  placeholder=""
                  onChange={(e) => {
                    let f = e.target.files;
                    let fileArray = [];
                    for (var i = 0; i < f?.length; i++) {
                      fileArray.push(f[i]);
                    }
                    selectMuiltipleDocument(fileArray);
                  }}
                />
              </div>
            </div>
            <div className="textAlignCenter mt-20 mb-20">
              <a
                className="btn btn-primary"
                data-toggle="modal"
                onClick={() => sendMessageToProvider()}
              >
                {labels.submit}
              </a>
            </div>
          </div>
        </ModalBody>
        {showToast === true ? (
          <ToastComponent
            message={message}
            toastClose={closeToast}
            success={success}
          />
        ) : (
          ""
        )}
      </Modal>
    </div>
  );
}
