import React from 'react'

function NicolasIntroEnglish() {
  return (
    <> 
     <p>Business Consultant and Entrepreneur.&nbsp;</p>
        <p>He grew up in Germany being his father from Germany and his mother from Venezuela. At the age of 20 he moved to Spain to improve his Spanish and study Business Administration at the Complutense University of Madrid.</p>
        <p>He began his professional career at the American insurer Cigna. Shortly after, he was offered a job as Management Consultant at &nbsp;Accenture in the aeronautical sector, specializing in cost reduction and optimization of the aircraft production and supply chain in Spain, Germany and France.&nbsp;</p>
        <p>At the beginning of 2018, he decided to dedicate all his efforts to start his own businesses, starting with the E-Commerce and Dropshipping sector, the idea of DucDoc was born in September 2019.</p>
        <p><br /></p>
    </>
  )
}

export default NicolasIntroEnglish;