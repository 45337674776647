import React from "react";
import Modal from "react-bootstrap/Modal";
import labels from "../config/Labels";
import InformationContainer from "./informationContainer";

const OrderServiceModal = ({ show, onHide, info }) => {
  return (
    <Modal show={show} onHide={onHide} animation={true} >
      <Modal.Header closeButton className="d-flex justify-content-center border-0">
        <Modal.Title id="contained-modal-title-vcenter ">
          {labels.information}
        </Modal.Title>{" "}
      </Modal.Header>
      <Modal.Body
        style={{
          width: "500px",
          height: "300px",
          overflowY: "auto",
        }}
      >
        <InformationContainer info={info || ""}/>
      </Modal.Body>
      <Modal.Footer className="border-0"></Modal.Footer>
    </Modal>
  );
};

export default OrderServiceModal;
