import React from "react";

export default function TermsAndConditionsSpanish() {
  return (
    <div
      className="container"
      style={{ marginBottom: "160px", marginTop: "160px" }}
    >
      <div>
        <h2 style={{ textAlign: "center", lineHeight: "1em" }}>
          Términos y Condiciones Generales de Uso y Contratación
        </h2>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            <em>Última actualización: 4 de julio de 2024</em>
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Las presentes condiciones generales de uso e información legal (en
            adelante, Condiciones Generales) se aplican al sitio web de DocuDoc,
            cuyo dominio es&nbsp;
          </span>
          <a target="_blank" href="https://www.docudocapp.com/">
            <span style={{ color: "rgb(17, 85, 204)" }}>
              https://www.docudocapp.com/
            </span>
          </a>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            y a la correspondiente aplicación móvil, así como a todos sus sitios
            relacionados o vinculados desde
          </span>
          <a target="_blank" href="https://www.docudocapp.com/">
            <span style={{ color: "rgb(17, 85, 204)" }}>
              https://www.docudocapp.com/
            </span>
          </a>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            por DocuDoc (en adelante y de forma conjunta, el Sitio). El sitio es
            propiedad de DocuDoc App SL. Al utilizar el sitio, muestras tu
            conformidad y expresa aceptación a las presentes condiciones de uso.
            Si no estás de acuerdo, te rogamos que te abstengas de utilizarlo.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            A través de las presentes Condiciones Generales, DocuDoc App SL pone
            a disposición de los usuarios (en adelante Usuario o Usuarios) el
            sitio web y la aplicación móvil DocuDoc (en adelante denominadas
            conjuntamente, la Plataforma).
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En cumplimiento de lo establecido en la normativa reguladora, se
            exponen los siguientes datos identificativos del titular del sitio:
          </span>
        </p>
        <ul>
          <li>Denominación social: DocuDoc App SL</li>
          <li>
            Domicilio social: C/ Ramón y Cajal 5, 28100 Alcobendas, Madrid&nbsp;
          </li>
          <li>Número de Identificación Fiscal (NIF): B19738905</li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>1. Objeto</span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc App SL es una compañía tecnológica, cuya actividad principal
            es el desarrollo y gestión de una plataforma tecnológica mediante la
            que a través de una aplicación móvil o de una web (en adelante, la
            APP) permite a determinados prestadores de servicios en diferentes
            territorios ofertar sus servicios a través de la misma, y en su
            caso, si los Usuarios de la APP y consumidores de los citados
            prestadores de servicios así lo solicitan a través de la APP, de
            forma accesoria, intermedia en la entrega inmediata o planificada de
            los servicios.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc App SL ha desarrollado una Plataforma mediante la cual
            diferentes prestadores de servicios, con los que DocuDoc puede
            mantener un acuerdo comercial por el uso de la plataforma, ofrecen
            una serie de servicios. El Usuario tiene la posibilidad de solicitar
            la adquisición de servicios de estos prestadores de servicios
            mediante la solicitud de un pedido al prestador del servicio a
            través de la Plataforma, en cuyos casos DocuDoc App SL y su
            Plataforma actúa como mero intermediario y por lo tanto, no puede
            asumir ni asume responsabilidad alguna sobre la calidad de los
            servicios o la correcta prestación de los servicios ofrecidos
            directamente por los prestadores de servicios ni por dichos
            terceros.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Asimismo, DocuDoc es una Plataforma Tecnológica multicategoría de
            intermediación en la contratación telemática de servicios “on
            demand". La misma pretende facilitar que aquellas personas que
            necesiten ayuda con sus recados y/o servicios (en adelante,
            “Usuarios”), puedan realizar sus recados mediante y delegando en
            dichos prestadores de servicio dispuestos a llevar a cabo
            voluntariamente el mandato que le confieran los Usuarios.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Los prestadores de servicios por lo tanto, son una red de
            profesionales independientes que colaboran con DocuDoc, cuando éstos
            están interesados en realizar la prestación de sus servicios, se
            conectan a la Plataforma de DocuDoc y en un tiempo determinado se
            comprometen a realizar el servicio que le encomiende el Usuario
            mediante el mencionado mandato. Como consecuencia de ello, DocuDoc
            no puede asumir la responsabilidad de los plazos de entrega en tanto
            dependen del buen hacer de los prestadores de servicios, así como de
            la Administración Pública asociada al servicio, así como de la
            información facilitada por los propios Usuarios al realizar el
            pedido o de su disponibilidad y respuesta a la hora de su entrega. A
            estos efectos, los plazos de entrega indicados a través del Sitio
            son meramente orientativos.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Los requisitos de los servicios ofrecidos varían en relación con las
            circunstancias de cada Usuario, por lo que no se pueden garantizar
            los mismos plazos ni requisitos para cada Usuario en particular. Los
            Plazos indicados son los mínimos para establecer el primer contacto
            con el cliente o de la entrega de servicios básicos. Los detalles de
            los plazos indicados para cada servicio se detallan en la
            información de cada uno de los servicios. Se recomienda la
            contratación de una asesoría previa a la contratación de otros
            servicios, para saber los plazos y requisitos particulares.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En cualquier caso, la función de DocuDoc es la de un mero
            intermediario y, por lo tanto, su única responsabilidad y tarea es
            gestionar la APP que facilita el contacto entre el Cliente y el
            Prestador del Servicio. DocuDoc no es responsable en ningún caso y
            de ninguna manera por los productos y/o servicios ofrecidos a través
            de la APP, o por la realización de cualquier diligencia o entrega
            solicitada a través de la APP.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El prestador del servicio exime a DocuDoc App SL respecto cualquier
            responsabilidad que pueda surgir durante todo el proceso de la
            prestación del servicio, el propio servicio prestado y sus
            consecuencias. El prestador del servicio responderá de cualquier
            daño y perjuicio que pudiera sufrir el cliente o el DocuDoc App SL
            como consecuencia de que el servicio no se haya prestado de forma
            adecuada, o completa. El prestador del servicio es el único
            responsable de facilitar toda la información necesaria respecto a
            los detalles de los servicios incluidos en la App. Si dicha
            información es incompleta o incorrecta, por lo que no sea posible la
            correcta prestación del servicio, también será responsabilidad del
            prestador del servicio. El prestador del servicio es responsable de
            cualquier daño causado por mala praxis, mal asesoramiento o la mala
            gestión del Servicio contratado a través de la Plataforma y/o el
            tratamiento de los datos personales de los clientes correspondientes
            a dichos servicios.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            A lo largo de las presentes Condiciones Generales de Uso nos
            referiremos a Clientes y prestadores de servicios como Usuarios de
            la Plataforma.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>2. Términos de Uso</span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El acceso a la Plataforma, y la creación voluntaria de un perfil por
            parte del Usuario, implica el conocimiento y la aceptación expresa e
            inequívoca de las presentes Condiciones Generales de Uso y
            Contratación y la Política de Privacidad y la Política de Cookies
            por parte de todos los Usuarios.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            3. Acceso y Registro para Usuarios
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Para poder ser Usuario de la Plataforma es indispensable que se
            cumplan los siguientes requisitos:
          </span>
        </p>
        <ul>
          <li>Haber cumplido o ser mayor de 18 años de edad.</li>
          <li>
            Cumplimentar de manera veraz los campos obligatorios del formulario
            de registro, en el que se solicitan datos de carácter personal como
            nombre de Usuario, correo electrónico, número de teléfono y número
            de tarjeta bancaria, entre otros y dependiendo del servicio.
          </li>
          <li>Aceptar las presentes Condiciones de Uso y Contratación.</li>
          <li>Aceptar la Política de Privacidad y Protección de Datos.</li>
          <li>Aceptar la Política de Cookies.</li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario garantiza que todos los datos sobre su identidad y
            legitimidad facilitados a DocuDoc en sus formularios de registro de
            la Plataforma son veraces, exactos y completos. Asimismo, se
            compromete a mantener actualizados sus datos.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En el supuesto en que el Usuario facilite cualquier dato falso,
            inexacto o incompleto o si DocuDoc considera que existen motivos
            fundados para dudar sobre la veracidad, exactitud e integridad de
            los mismos, DocuDoc podrá denegarle el acceso y uso presente o
            futuro de la Plataforma o de cualquiera de sus contenidos y/o
            servicios.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Al darse de alta en la Plataforma, el Usuario ingresará su correo
            electrónico (username) y una clave de acceso (password). Estos datos
            son estrictamente confidenciales, personales e intransferibles.
            DocuDoc recomienda no utilizar las mismas credenciales de acceso que
            en otras plataformas para aumentar la seguridad de las cuentas. En
            el supuesto que el Usuario utilice las mismas credenciales de acceso
            que en otras plataformas, DocuDoc no podrá garantizar la seguridad
            de la cuenta ni asegurar que el Usuario es el único que accede a su
            perfil.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario se compromete a no divulgar los datos relativos a su
            cuenta ni hacerlos accesibles a terceros. El Usuario será el único
            responsable en caso de uso de dichos datos o de los servicios del
            Sitio por terceros, incluidas las manifestaciones y/o contenidos
            vertidos en la Plataforma, o cualquier otra actuación que se lleve a
            cabo mediante el uso del username y/o password.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario será el único propietario sobre el contenido vertido por
            el mismo en la Plataforma. Asimismo, a través del registro en la
            Plataforma y la aceptación de las presentes Condiciones, el Usuario
            concede a DocuDoc, en relación con el contenido que pueda proveer,
            una licencia mundial, irrevocable, transferible, gratuita, con
            derecho a sublicencia, uso, copia, modificación, creación de obras
            derivadas, distribución, comunicación pública así como su
            explotación en cualquier forma que DocuDoc considere apropiada, con
            o sin comunicación ulterior al Usuario y sin la necesidad de
            liquidar cantidad alguna por los mencionados usos.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc no puede garantizar la identidad de los Usuarios
            registrados, por lo tanto, no será responsable del uso de la
            identidad de un Usuario registrado por terceros. Los Usuarios se
            obligan a poner inmediatamente en conocimiento de DocuDoc la
            sustracción, divulgación o pérdida de sus credenciales,
            comunicándose a través de los canales de comunicación ofrecidos por
            DocuDoc.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.1
            Perfil
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Para poder completar el registro en la Plataforma el Usuario deberá
            proporcionar algunos datos como: nombres y apellidos de Usuario,
            dirección de correo electrónico, teléfono, datos de la tarjeta
            bancaria, etc. Una vez completado el registro, todo Usuario podrá
            acceder a su perfil y completarlo, editarlo según estime
            conveniente. DocuDoc no almacena los datos de pago de los usuarios,
            que serán tratados y almacenados por plataformas de pagos según
            descrito en las presentes Condiciones y en la Política de
            Privacidad.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.2.
            Robo o sustracción de la tarjeta de crédito
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En tanto DocuDoc no puede garantizar la identidad de los Usuarios
            registrados, los Usuarios tendrán la obligación de informar a
            DocuDoc en aquellos supuestos en que tengan constancia que la
            tarjeta de crédito asociada a su perfil de DocuDoc hubiera sido
            robada, y/o se estuviera utilizando por un tercero de forma
            fraudulenta. Por ello, toda vez que DocuDoc y su plataforma de pago
            velan proactivamente por la protección de los Usuarios con las
            correspondientes medidas de seguridad, para el caso en que el
            Usuario no informara a DocuDoc de dicha sustracción, DocuDoc no será
            responsable del uso fraudulento que puedan hacer terceros de la
            cuenta del Usuario. DocuDoc invita a los Usuarios, en caso de robo,
            sustracción o sospecha de un mal uso de su tarjeta de crédito a
            realizar la correspondiente denuncia ante la autoridad policial.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc adquiere el compromiso de asistir al Usuario y colaborar con
            las autoridades competentes, si así fuera necesario, para facilitar
            la prueba fehaciente del cargo indebido. En caso de fraude, DocuDoc
            se reserva el derecho a interponer las acciones que en su caso
            fueran menester cuando resulte perjudicado por el uso indebido del
            Sitio.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            4. Funcionamiento del servicio. Condiciones del Mandato.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Cuando un Usuario registrado como Usuario necesite ayuda con un
            recado, deberá dirigirse a la plataforma web o a la correspondiente
            aplicación de DocuDoc y solicitar el servicio mediante dichos medios
            telemáticos. El servicio de recadería básico consiste en la
            contratación de un servicio y su posterior entrega siempre que se
            encuentre exclusivamente dentro del territorio de actuación del
            prestador del servicio. Asimismo, el Usuario puede solicitar al
            prestador de servicios que contrate servicios de documentación en su
            nombre, los recoja y los entregue en las dirección consignada, a
            través de mandato el prestador de servicios se compromete a adquirir
            los servicios encomendados por el Usuario en su nombre, y según las
            indicaciones y especificaciones brindadas por este último.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario es el único responsable en la correcta consignación de
            los datos en la Plataforma, por lo que exime a DocuDoc y al
            prestador de servicios de cualquier negligencia o error en la
            prestación del servicio derivada de la consignación errónea de los
            datos indicados. Como consecuencia de ello, será el Usuario quien
            deba asumir el coste derivado de la incorrecta consignación de los
            datos indicados en la Plataforma.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario deberá proporcionar toda la información, lo más detallada
            posible, respecto al servicio objeto del recado, y en su caso, en
            relación con el servicio que solicite al prestador de servicios.
            Para ello, podrá introducir los comentarios que considere útiles
            durante el proceso de la contratación, así como en su caso, podrá
            compartir con el prestador de servicios una fotografía para
            identificar el pedido. El Usuario tendrá comunicación constante con
            el prestador de servicios, pudiéndose dirigir en todo momento al
            prestador de servicios a los efectos de que éste ejecute el mandato
            conferido según las propias indicaciones del Usuario.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            A efectos de facilitar la comunicación directa con el prestador de
            servicios y en el supuesto que exista alguna incidencia en la
            tramitación del encargo y/o para comunicar algún cambio en el mismo,
            DocuDoc pone a disposición de los Usuarios un chat interno que
            permitirá el contacto directo e inmediato entre Usuario y prestador
            de servicios durante la ejecución del pedido. El chat dejará de
            estar activo en el momento en que el Usuario haya recibido el
            servicio o se haya cancelado por cualesquiera de las causas
            previstas. En el supuesto que el Usuario quiera comunicarse con el
            prestador de servicios o con DocuDoc tras la finalización del
            encargo, deberá utilizar el formulario de contacto presente en la
            Plataforma y contactar con el servicio de atención al Usuario.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario exime a DocuDoc y al prestador de servicios de cualquier
            negligencia o error en las indicaciones que consigne para la compra
            del servicio que solicite. Como consecuencia de ello, será el
            Usuario quien deba asumir el coste derivado de la incorrecta
            consignación de las indicaciones de los servicios en la Plataforma
            (i.e. dirección incorrecta, servicio incorrecto, otro dato necesario
            para llevar a cabo el servicio incorrecto).
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En el caso de servicios en los que consta el precio del servicio en
            la Plataforma, los precios consignados en la Plataforma pueden ser
            orientativos, tal y como se establece en la cláusula 7 de estos
            Términos y Condiciones Generales de Uso de la Plataforma.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Si el servicio y/o el prestador de servicio no se encuentra
            disponible, el prestador de servicios deberá llamar al Usuario para
            exponerle las opciones. En caso de que el Usuario no se encuentre de
            acuerdo con ninguna de las opciones expuestas por el prestador de
            servicios y, consecuentemente, no esté interesado en las opciones
            alternativas, deberá asumir la política de cancelación expuesta en
            las presentes Condiciones Generales de Uso (en el apartado nueve).
            Si el Usuario no responde a las llamadas o no se encuentra en el
            lugar consignado para la entrega de los servicios, el prestador de
            servicios esperará 10 minutos antes de cancelar el servicio. Pasado
            este tiempo, el prestador de servicios procederá a la devolución del
            importe pagado por el Usuario restando todos los gastos que haya
            tenido el prestador del servicio relacionados con el servicio
            contratado.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En el caso de que el servicio no pueda cumplimentarse por causas
            imputables al Usuario, ésta parte deberá asumir el coste total
            (impuestos incluidos) de la tarifa de los servicios adquiridos, más
            los eventuales recargos.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Además, el Usuario deberá hacerse cargo del 100% del coste del
            precio del servicio y deberá pagar otro servicio para recibir los
            servicios no entregados. Ni DocuDoc, ni el prestador de servicios
            serán en ningún caso responsables del deterioro o caducidad del
            objeto del encargo.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Los usuarios aceptan que de acuerdo a los servicios contratados a
            través de la plataforma DocuDoc podrán recibir las facturas
            correspondientes al servicio por parte de los prestadores de
            servicios.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Los Usuarios podrán valorar los servicios de entrega prestados por
            el prestador de servicios. El Usuario puntuará al prestador de
            servicios de forma precisa y veraz, teniendo en cuenta
            exclusivamente la prestación de los servicios, y absteniéndose de
            incluir consideraciones que no sean relevantes para la valoración de
            los servicios de entrega prestados. Las valoraciones enviadas por
            los usuarios afectarán el sistema de Rating de los prestadores de
            servicios.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            A los efectos de los servicios contratados, el Usuario solicita a
            DocuDoc que la oferta de servicios del prestador de servicios
            responda a la mejor relación entre precio y calidad disponible en la
            APP en relación a un pedido específico.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Una vez finalizado el encargo y prestado el servicio, en el caso de
            que se haya solicitado un servicio de pago, el Usuario podrá
            descargar el recibo a través del Sitio en el apartado “Mis
            Pedidos”.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En el supuesto que el Usuario reciba confirmación de la realización
            del recado sin haber procedido a la ratificación del mandato por sí
            mismo o mediante un receptor autorizado por éste, deberá comunicarlo
            a DocuDoc de forma inmediata para que la Plataforma pueda tomar las
            medidas oportunas. DocuDoc se reserva el derecho a comprobar las
            comunicaciones realizadas por el Usuario a efectos de su
            comprobación y control.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            5.1 Devolución de servicios&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En caso de que el Usuario desee proceder a la devolución de un
            servicio&nbsp; o desee realizar una reclamación acerca de la
            realización de un servicio, sin perjuicio de que DocuDoc, como
            intermediario, asuma la gestión de la misma, el responsable final
            siempre será el prestador de servicio con el cual se realizó la
            compra. En caso de reembolso de la totalidad o de una parte del
            importe de la compra al Usuario, será el prestadores de servicio el
            que tendrá potestad para decidir la cantidad de devolución del
            servicio no prestado con independencia de que sea DocuDoc el que por
            cuenta del prestadores de servicio local proceda a la devolución.
            Por lo tanto, la devolución de un servicio adquirido por el
            prestador de servicios en ejecución del mandato conferido por el
            Usuario quedará sujeta en todo caso a la política de devoluciones
            del prestador de servicio.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Determinados establecimientos podrían no disponer de espacios
            abiertos al público, con lo que el Usuario no podría acceder a los
            mismos a efectos de tramitar la devolución o reclamación. En estos
            supuestos, el Usuario deberá contactar con el servicio de atención
            al Usuario de DocuDoc, a través de los canales presentes en la
            Plataforma, con la finalidad de obtener la ayuda y soporte
            necesarios.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En el caso que el Usuario quiera tramitar la devolución de algún
            servicio por no adecuarse a lo que ha solicitado a través de la
            plataforma, el Usuario deberá aportar un listado de los servicios
            incorrectos o que no se hayan prestado, así como otras evidencias
            que demuestren la no adecuación del servicio solicitado.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario deberá comprobar los servicios que el prestador de
            servicios le entregue y ratificar el mandato en un plazo máximo de 4
            días después de haber recibido la confirmación de entrega del
            servicio por correo electrónico. Si no se pone una reclamación en
            esos 4 días posteriores a la notificación de entrega del servicio,
            el Usuario confirma y ratifica la recepción del servicio. Asimismo,
            el Usuario manifiesta que un tercero podrá ratificar el mandato en
            su nombre, por ejemplo en aquellos supuestos en que el Usuario no se
            encuentre en la dirección final de entrega o haya designado a un
            tercero para la recogida y firma, en su caso. Así, recae sobre el
            Usuario y/o dicho tercero la responsabilidad de verificar la
            adecuación del servicio, así como en su caso, recabar las evidencias
            suficientes para justificar lo contrario.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En todo caso, será potestad del prestador de servicio determinar en
            cada caso la procedencia de la devolución por lo que, en caso de
            disputa, el Usuario deberá ponerlo en conocimiento de DocuDoc a
            través de los medios facilitados por DocuDoc a tal efecto.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            5.2 Política de reembolso
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Para ser elegible para una devolución, el cliente deberá solicitar
            el reembolso a través de la Plataforma (Mi perfil &gt; Mis pedidos
            &gt; Seleccionar pedido &gt; Reclamar importe). Se abrirá una
            disputa entre el Cliente, DocuDoc y el Proveedor para tratar de
            resolver cualquier duda o inconformidad, de referente al servicio,
            de que no se ha prestado parte o la totalidad del servicio por
            causas atribuibles al Proveedor, se procederá con la devolución de
            lo considerado, según alineamiento de estas políticas de devolución,
            que a continuación se exponen:
          </span>
        </p>
        <ul>
          <li>
            El Proveedor realizará un desglose de las actividades efectuados al
            momento de la solicitud y descontará de la devolución, lo siguiente,
            en el caso de que aplique:
          </li>
          <ul>
            <li>
              Cualquier gasto que haya realizado el Proveedor para la debida
              tramitación del servicio solicitado por el cliente, así como
              traslados.
            </li>
            <li>
              Costos de reuniones y consultoría, ya sean en persona, vía
              telemática, por mesnajes dentro de la Plataforma, por correo
              electrónico, o de cualquier tipo que se hayan realizado junto con
              el cliente.&nbsp;
            </li>
            <li>
              Gastos de transferencias bancarias o digitales de plataformas
              electrónicas.
            </li>
          </ul>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Una vez se haya procesado la devolución, se efectuará en el método
            original de pago en una cierta cantidad de días. Se debe tener en
            cuenta que los tiempos de acreditación del reembolso pueden variar
            desde la fecha de notificación de reembolso en dependencia de los
            tiempos de los bancos del servicio ocupado para la transferencia.
            Tenga en cuenta que el banco puede descontar comisiones bancarias
            ajenas a el Proveedor.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En el caso de considerar no haber recibido la devolución o considera
            que está perdido, puedes proceder de la siguiente forma:
          </span>
        </p>
        <ul>
          <li>Revisa la cuenta bancaria al cabo de dos días hábiles.</li>
          <li>
            Contacte con la compañía de tarjeta de crédito, Banco, sistema de
            pagos en línea o de remesa, recuerda que dicha acción puede tomar
            algún tiempo antes de que la devolución se refleje oficialmente.
          </li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Seguir todos estos pasos aún no se ha recibido la devolución,
            contáctanos al correo electrónico info@docudocapp.com
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Proveedor no realizará la devolución en los siguientes casos:
          </span>
        </p>
        <ul>
          <li>
            Aquellos en que el resultado del procedimiento ya terminado sea
            desfavorable o negativo para el cliente, si estos fueron realizados
            con la debida diligencia propia del servicio a brindar.
          </li>
          <li>
            Aquellos en el que el resultado del procedimiento ya terminado sea
            desfavorable o negativo para el cliente, si este resultado negativo
            ha sido causa de información, falsa, equivocada o incompleta, de
            parte del cliente.
          </li>
          <li>
            Aquellos en que, por motivos de falta de comunicación, entrega de
            documentos, o de fondos necesarios para la realización de un
            procedimiento, haya causado la terminación repentina del servicio
            realizado, siempre y cuando el cliente haya sido notificado sobre la
            necesidad importancia de dichos elementos y éste no haya hecho
            entrega de los mismos.
          </li>
          <li>
            Aquellos en que el cliente considera que no ha sido correctamente
            prestado el servicio, si el mismo se hizo en el plazo estipulado,
            debiendo alegar directamente con el Proveedor.
          </li>
          <li>
            Aquellos referentes al servicio de prueba de estudio de viabilidad
            de casos.
          </li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Proveedor, por cuenta propia, procederá a la devolución del
            importe ingresado en el caso de que la solicitud fuese imposible de
            atender, prohibido, incomprensible, o cualquier otra causa que
            considere, reservándose el derecho a la cancelación del servicio,
            sin necesidad de acreditar, causa alguna, obligándose a la
            devolución de la cantidad abonada en el plazo de 72 horas desde el
            momento de la solicitud del servicio.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El cliente tendrá derecho al reembolso de la cantidad abonada si en
            el plazo de dos semanas no se ha procedido a comunicar sobre el
            servicio solicitado de parte del Proveedor hacia el Cliente.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En el caso de que, para proceder a la prestación del servicio se
            necesitare, por su complejidad, más documentación, más datos,
            sucesivos contactos, obligación para el propietario de la web de
            prestar el servicio en un plazo estipulado, debiendo hacerlo constar
            así al Cliente.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            <strong>
              Intercambio de correos electrónicos entre el Proveedor y el
              cliente:
            </strong>
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Por normal general, no se cobran los mensajes intercambiados ni por
            WhatsApp ni por correo electrónico.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>Caso especial:</span>
        </p>
        <ul>
          <li>
            Cuando un cliente compra un servicio, y en el caso de devolución, no
            se cobrarán los WhatsApp enviados al cliente, pero si se descontarán
            DIEZ (10) euros por cada correo electrónico enviado al cliente desde
            el momento que contrató el servicio; así mismo, se descontarán todas
            las videollamadas de 15 minutos concretadas con el cliente que se
            efectuaron durante el tiempo que duró el proceso de tramitación y
            acompañamiento migratorio. En el caso de que, el presupuesto pagado
            por el cliente exceda del resultado total de los emails
            contabilizados, no se le cobrará al cliente importe extra, teniendo
            en cuenta que no le corresponderá ningún importe a devolver.
          </li>
          <li>
            En el caso de que el cliente, exija la devolución del dinero porque
            lo que se le explicó en la asesoría, el cliente lo considera
            erróneo, sólo procederá la devolución cuando lo dicho por el
            Proveedor no se encuentre en la Ley vigente. En el caso de que, lo
            dicho por el Proveedor si estuviese en la Ley, pero el cliente no lo
            entiende, no se procederá a devolver ningún importe.
          </li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            6. Tarifas de los servicios y facturación
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Las facturas se le enviarán al Usuario a la dirección de correo
            electrónico indicado cuándo se creó el perfil una vez haya
            finalizado la prestación del servicio. Dicha factura también se
            podrá descargar en “Mis pedidos” una vez se haya prestado el
            servicio.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El alta y uso de la Plataforma para los Usuarios no tiene coste
            asociado, pudiendo estos ser revisados por parte de DocuDoc en
            cualquier momento. Determinados servicios de la Plataforma pueden
            tener un coste para el Usuario, según indicado en el momento de la
            contratación.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario únicamente deberá pagar por cada servicio solicitado a
            través de la Plataforma en concepto del uso de la misma para la
            solicitud de servicios y comunicación a través de la Plataforma.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Adicionalmente, en aquellos servicios que incluyan la compra de un
            servicio, el Usuario deberá pagar el precio de dicho servicio. El
            Usuario, al registrarse a través de la Plataforma y proporcionar la
            información bancaria requerida, autoriza expresamente a DocuDoc a
            pasar los recibos correspondientes al pago de los servicios
            solicitados, incluyendo el precio y los suplementos de los servicios
            solicitados.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            De acuerdo con estas condiciones, el Usuario tendrá derecho a saber
            la tarifa aproximada de su servicio antes de la contratación del
            mismo y formalización del pago, exceptuando cuando el Usuario no
            especifique la dirección de recogida. La tarifa del servicio de
            envío podrá variar cuando se dieran circunstancias de fuerza mayor
            que estuvieran fuera del alcance de DocuDoc y que conlleven un
            aumento de dicha tarifa.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc se reserva el derecho de modificar precios en la plataforma
            en cualquier momento. Los cambios efectuados tendrán efecto
            inmediato tras su publicación. El Usuario autoriza expresamente a
            DocuDoc para que le remita por medios telemáticos, a la dirección de
            correo electrónico facilitada por el mismo durante el proceso de
            registro, el recibo de los servicios contratados. En caso de
            requerir una factura con datos fiscales, el Usuario deberá añadir
            los datos fiscales a la plataforma antes de hacer el pedido.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            La cancelación posterior del servicio por el Usuario, cuando ya haya
            habido gastos relacionados con el pedido por parte del prestadores
            de servicio y la misma haya sido comunicada al Usuario, facultará a
            DocuDoc a cargar al Usuario la tarifa que, en su caso, corresponda.
            Todo ello sin perjuicio de que el Usuario pueda solicitar un nuevo
            servicio. El Usuario podrá ejercitar su derecho de desistimiento
            ante el prestador de servicio que le ha vendido los servicios en el
            plazo establecido por la ley desde.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Los Usuarios seleccionan el Servicio que desean contratar y el
            prestador del servicio de dicho servicio a través de la Plataforma
            Al final de dicha selección, los Usuarios pagan a través de la
            Plataforma el precio indicado en la misma para dicho servicio, que
            podría denominarse una provisión de fondos, contratando de esa
            manera el servicio al prestador del servicio seleccionado. La
            totalidad del importe pagado por el Usuario permanecerá en la
            plataforma de pago hasta la entrega del servicio contratado.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En cualquier momento a partir de la contratación del servicio, el
            prestador del servicio podrá solicitar al Cliente una provisión de
            fondos por el importe estimado de los suplidos correspondientes al
            servicio contratado. El importe de dicha provisión será abonado
            directamente por el Cliente al&nbsp; prestador del servicio.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span style={{ color: "rgb(74, 74, 74)" }} />
          <span style={{ color: "rgb(74, 74, 74)" }}>&nbsp;</span>
          <span style={{ color: "rgb(74, 74, 74)" }} />
          <span style={{ color: "rgb(74, 74, 74)" }}>
            6.1. Plataformas de pago
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El pago de los servicios que se ofertan a través de la Plataforma y
            que se entregan a los Usuarios una vez llevado a cabo el servicio,
            se efectúa transitoriamente a DocuDoc y éste lo transmite a los
            prestadores de servicios con los que mantiene un acuerdo comercial.
            Los prestadores de servicio asociados autorizan a DocuDoc a aceptar
            el pago en su nombre, por lo que el pago del precio de cualquier
            servicio efectuado correctamente a favor de DocuDoc descargará al
            Usuario de las obligaciones de abonar dicho precio al prestador de
            servicios asociados.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El pago de los servicios y/o servicios realizado por los Usuarios se
            recibe en las cuentas de DocuDoc mediante una Entidad de Dinero
            Electrónico. Las Entidades de Dinero Electrónico están autorizadas
            para proporcionar, directa o indirectamente, servicios de pago
            regulados en todos los territorios en los que opera DocuDoc y
            cumplen con la normativa vigente en materia de servicios de pago
            para Plataformas como DocuDoc*.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            *En caso de no estar autorizadas dichas entidades, DocuDoc se exime
            de toda responsabilidad por dicha falta de autorización o licencia,
            siendo única y exclusivamente responsabilidad de las Entidades de
            Dinero Electrónico.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc, a través del proveedor de pagos que a tal efecto tiene
            contratado y con el único objeto de comprobar el medio de pago
            facilitado, se reserva el derecho, como medida antifraude, a
            solicitar la preautorización de cargo de los servicios encargados a
            través de la plataforma. La citada preautorización no supondrá el
            pago total del encargo al prestador del servicio. Este se realizará
            única y exclusivamente tras la puesta a disposición de los servicios
            al Usuario o bien, por las causas descritas en los presentes
            términos y condiciones.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Supuesto que exista alguna disputa, DocuDoc ofrecerá la primera
            línea de soporte y reembolsará al Usuario la parte del servicio que
            se considere apropiada.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Si el Usuario tiene algún problema con el desarrollo de su pedido,
            podrá contactar al servicio de atención al Usuario de DocuDoc a
            través de los medios puestos a disposición del mismo en la
            Plataforma.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            7. Precio de los servicios y/o servicios consignados en la
            Plataforma
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Todos los precios que se indican en la Plataforma incluyen los
            impuestos que pudieran ser aplicables en función del territorio
            desde el que opere el Usuario y en todo caso se expresarán en la
            moneda vigente en función del territorio desde el que opere el
            Usuario.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            De acuerdo con la Cláusula 6 anterior, los precios aplicables en
            cada servicio serán los publicados en la Plataforma sujetos a las
            particularidades expuestas y aplicados de forma automática en el
            proceso de contratación en la última fase del mismo.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            No obstante, los precios de los servicios de venta en prestadores de
            servicio mostrados en la Plataforma de DocuDoc podrán ser
            orientativos. En todo caso, dichos precios corresponden a los
            servicios de venta en prestadores de servicio y son exclusivamente
            fijados por ellos.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Precio incluye exclusivamente el coste del servicio
            correspondiente para la correcta y completa prestación del servicio
            distinta de las correspondientes a los Suplidos. Los costes, tasas y
            demás suplidos necesarios para llevar a cabo el servicio se los
            pagará el Usuario directamente al prestador del servicio de la forma
            que les indique el prestador del servicio (Por ejemplo, por
            transferencia o Bizum), por lo que los Usuarios no lo pagan en el
            momento de la contratación del servicio.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Los suplidos serán liquidados por el prestador del servicio y se
            facturan a nombre del cliente. Dichas facturas de suplidos se le
            adjuntan a la factura emitida por el prestador del servicio al
            Usuario garantizando así una única factura.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario asume que en todo caso la valoración económica de algunos
            de los suplidos relacionados con el servicio podrá variar en tiempo
            real en función del establecimiento asociado al suplido. Para
            cualquier información sobre el pedido solicitado, el Usuario podrá
            contactar con DocuDoc.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            De acuerdo con lo anterior, el Usuario mediante la solicitud de
            compra a través de la Plataforma confiere al prestador de servicios
            un mandato para prestar el servicio por el precio fijado por los
            prestadores de servicios. El Usuario podrá estar en contacto directo
            con el prestador de servicios durante la realización del pedido.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            8. Códigos promocionales y/o otras ofertas o descuentos
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc podrá facilitar, en cualquier momento y de forma unilateral,
            créditos para utilizar dentro de la Plataforma o servicios gratis a
            ciertos Usuarios. El Usuario reconoce y acepta que los créditos y
            servicios gratis deberán ser utilizados dentro del plazo establecido
            a disposición del Usuario en la Plataforma.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Los códigos promocionales y/o otras ofertas o descuentos ofertados a
            través de la Plataforma deberán ser correctamente consignados en la
            aplicación con anterioridad a la realización del pedido, en caso
            contrario su uso no será efectivo para el Usuario y no podrá
            disfrutar de los mismos.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Siempre que una cancelación sea instada por DocuDoc en los términos
            descritos el Usuario mantendrá la vigencia del código promocional
            y/o otras ofertas o descuentos para su uso futuro.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc se reserva el derecho a cancelar los códigos promocionales
            y/o otras ofertas o descuentos ofrecidos de forma unilateral y se
            reserva el derecho a aplicar sanciones a los Usuarios por el importe
            del uso defraudado a DocuDoc.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc no asume ninguna responsabilidad si, por fuerza mayor o
            sucesos que escapen de su voluntad o cuya necesidad sea justificada,
            se viera obligado a anular, acortar, prorrogar o modificar las
            condiciones de las promociones.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En particular, DocuDoc no asume ninguna responsabilidad en caso de
            que la página web no estuviese disponible en algún momento durante
            las promociones o de un mal funcionamiento del sistema automatizado
            de las promociones.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Los códigos promocionales no son acumulables. El usuario solo puede
            beneficiarse de 1 (un) código promocional por pedido. La aplicación
            seleccionará automáticamente el código promocional que se aplicará.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            A su vez, los códigos promocionales no se pueden utilizar en las
            promociones ofrecidas en la APP.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Está prohibido que un mismo usuario se registre varias veces para
            obtener beneficios ilícitos que no sean de buena fe, maliciosos o
            que contradigan nuestros términos y condiciones.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            9. Derecho de desistimiento y cancelación de pedidos
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            De acuerdo con la propia naturaleza del servicio que ofrece DocuDoc,
            el Usuario es consciente de que una vez que haya efectuado un
            pedido, se considera que la ejecución del mandato de compra ha
            comenzado y por tanto el Usuario podría no tener derecho a desistir
            en la solicitud del servicio de forma gratuita.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Sin perjuicio de lo anterior, los costes de cancelación podrían
            depender de los siguientes factores;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            - Si el prestador de servicios ha empezado a trabajar en el pedido,
            se cobrará el importe de los servicios. El prestador del servicio
            indica el comienzo de la prestación del servicio mediante un
            mensaje&nbsp; al Usuario o la actualización del estado del pedido a
            través de la Plataforma y/o a través del envío de correo electrónico
            o una llamada telefónica a los datos registrada por el
            Usuario.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            - Si el Usuario cancela el pedido una vez que el prestador de
            servicios haya empezado a trabajar en el servicio, se le cobrará el
            importe considerado por el prestador del servicio, conforme con el
            trabajo que haya incurrido hasta el momento de la cancelación, más
            una posible tasa de cancelación al Usuario. La tasa de cancelación
            del pedido puede suponer un 3% del precio total del servicio
            contratado a través de la plataforma. El prestador del servicio le
            comunicará al Usuario el coste de la cancelación. En caso de que el
            pedido sea cancelado por parte de DocuDoc, los agentes se pondrán en
            contacto con el Usuario para comunicarle el costo de cancelación si
            lo hubiera.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En caso de servicios, en el momento de cancelación del servicio, si
            el prestador de servicios ya hubiese realizado el servicio, el
            Usuario deberá satisfacer el coste total de adquisición de los
            servicios y los costes de entrega.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En el caso que el Usuario haya indicado de forma incorrecta los
            datos para poder llevar a cabo los servicios, el encargo se
            cancelará, debiendo el Usuario asumir los costes generados según se
            establece en esta cláusula.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc se reserva el derecho a cancelar un pedido sin necesidad de
            alegar causa justa.&nbsp; En caso de cancelación instada por DocuDoc
            sin causa justa, el Usuario tendrá derecho al reembolso de la
            cantidad abonada.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc dispone de hojas de reclamación oficiales a disposición del
            Usuario, por el objeto del servicio ofrecido por DocuDoc, en los
            idiomas oficiales de los países dónde DocuDoc opera. Las mencionadas
            hojas de reclamación podrán solicitarse por parte del Usuario a
            través del servicio de atención al Usuario de DocuDoc. El Usuario
            deberá especificar en el correo electrónico y la ubicación exacta
            desde la que realiza la solicitud que deberá coincidir con el lugar
            de realización del servicio, en caso de duda, será este último el
            lugar dónde deberá instalarse la
            reclamación.&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            10. Condiciones especiales del servicio de encargo de compra de
            servicios a través de DocuDoc
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario tiene la opción de solicitar a través de la Plataforma la
            adquisición presencial por parte del prestador de servicios de una
            serie de servicios que son ofrecidos por establecimientos con los
            que DocuDoc puede mantener o no un acuerdo comercial. El Usuario
            puede seleccionar mediante un menú desplegable dentro de la
            Plataforma, una serie de opciones donde se puede indicar de forma
            orientativa las características, el precio, así como otras
            características del servicio.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Una vez el Usuario seleccione una de las opciones, podrá completar
            además el pedido mediante un espacio de texto libre para incorporar
            información más detallada o instrucciones para el prestador de
            servicios que se encargue de llevar a cabo dicho pedido.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario es consciente y acepta que las descripciones y, en su
            caso, los precios o las fotografías de los servicios ofertados en la
            Plataforma se realizan en base a la información y documentación
            proporcionada por los establecimientos por lo que DocuDoc no puede
            ofrecer garantías frente a posibles inexactitudes en dichas
            descripciones, precios y/o fotografías.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Asimismo, el Usuario acepta que todos los servicios reflejados en la
            plataforma de DocuDoc están sujetos a la disponibilidad de los
            mismos y en este sentido asume la posibilidad de que durante la
            realización del pedido se encuentre con la no disponibilidad del
            servicio y/o servicio en el establecimiento . Adicionalmente, el
            precio del servicio puede variar ligeramente a causa de
            modificaciones en el punto de venta. DocuDoc se reserva el derecho
            de proceder con la compra ante variaciones en el precio de hasta un
            30%, si supera el citado 30%, el prestador de servicios se pondrá en
            contacto con el Usuario para transmitirle la situación.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc se reserva el derecho a retirar cualquier servicio de su
            plataforma, así como de modificar el contenido de su ficha en
            cualquier momento, sin que le pueda ser atribuido ningún tipo de
            responsabilidad.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.1
            Entregas&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc proporciona al Usuario un servicio de entrega de los
            servicios adquiridos por el prestador de servicios en determinadas
            ciudades. El pedido se podrá realizar siempre que se cumplan las
            siguientes condiciones en el momento de pago del pedido:
          </span>
        </p>
        <ul>
          <li>
            El servicio se encuentre operativo para la franja horaria escogida.
          </li>
          <li>
            El servicio a entregar debe encontrarse dentro de alguna de las
            ciudades y dentro del espacio habilitado en las que opere DocuDoc.
          </li>
          <li>
            El lugar de destino debe estar situado en la misma ciudad en la que
            se encuentre el servicio.
          </li>
          <li>
            Las autoridades públicas necesarias para llevar a cabo el servicio
            puedan operar en el plazo establecido
          </li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10.2
            Precio y método de pago
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El precio del servicio y/o servicio será el indicado a través de la
            Plataforma. Sin embargo, el Usuario es conocedor que en todo caso la
            valoración económica de algunos de los servicios podrá variar en
            tiempo real, debido a los posibles suplementos de terceras partes.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Cualquier cambio o variación será comunicada por el prestador de
            servicios para la aprobación del Usuario. En caso de querer realizar
            alguna apreciación sobre el pedido encargado, el Usuario tendrá
            siempre la posibilidad de contactar directamente con el prestador de
            servicios que ejecuta el mandato otorgado.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario podrá realizar el pago de los servicios a través de su
            tarjeta de crédito. Para el pago con tarjeta, el Usuario deberá
            facilitar los datos de las mismas a través de la plataforma como
            método de pago asociado a su cuenta. DocuDoc no almacena el número
            de la tarjeta en sus servidores, y sólo podrá visualizar los cuatro
            últimos dígitos de ésta. La información completa será almacenada en
            los servidores del prestador de servicios de pago que realiza los
            pagos en nombre de DocuDoc. El pago con tarjeta de crédito no
            supondrá ningún coste extra para el Usuario. Todo ello de acuerdo
            con los términos de la plataforma de pago expuestos a continuación.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Cuando el cobro del servicio al Usuario no pudiera realizarse por
            cualquier motivo, la cuenta del Usuario quedará bloqueada en tanto
            no se regularice y abone la deuda.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            11. Obligaciones del Usuario
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Los Usuarios son completamente responsables del acceso y correcto
            uso de su perfil y demás contenidos de la Plataforma con sujeción a
            la legalidad vigente, sea nacional o internacional del País desde el
            que hace uso de la Plataforma, así como a los principios de buena
            fe, a la moral, buenas costumbres y orden público. Y
            específicamente, adquiere el compromiso de observar diligentemente
            las presentes Condiciones Generales de Uso.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Los Usuarios son responsables de consignar correctamente nombres de
            Usuario y contraseñas individuales, y demás datos para la correcta
            prestación del servicio no transferibles y lo suficientemente
            complejas, así como no usar el mismo nombre de Usuario y contraseña
            que en otras plataformas, todo ello con la finalidad de proteger su
            cuenta del uso fraudulento por parte de terceros ajenos a la
            Plataforma.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Los Usuarios se abstendrán de usar su perfil y el resto de
            contenidos de la Plataforma con fines o efectos ilícitos y que sean
            lesivos de los derechos e intereses de terceros, o que de cualquier
            forma puedan dañar, inutilizar, afectar o deteriorar la Plataforma,
            sus contenidos y sus servicios. Asimismo, queda prohibido impedir la
            normal utilización o disfrute de la Plataforma a otros Usuarios.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc no podrá ser considerada responsable editorial, y declara
            expresamente que no se identifica con ninguna de las opiniones que
            puedan emitir los Usuarios de la Plataforma, de cuyas consecuencias
            se hace enteramente responsable el emisor de las mismas.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Quienes incumplan tales obligaciones responderán de cualquier
            perjuicio o daño que ocasionen. DocuDoc no responderá de ninguna
            consecuencia, daño o perjuicio que pudiera derivarse de dicho acceso
            o uso ilícito por parte de terceros.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            En general, los Usuarios se comprometen, a título enunciativo y no
            taxativo, a:
          </span>
        </p>
        <ul>
          <li>
            No alterar o modificar, total o parcialmente la Plataforma,
            eludiendo, desactivando o manipulando de cualquier otra las
            funciones o servicios de la misma;
          </li>
          <li>
            No infringir los derechos de propiedad industrial e intelectual o
            las normas reguladoras de la protección de datos de carácter
            personal;
          </li>
          <li>
            No usar la Plataforma para injuriar, difamar, intimidar, violar la
            propia imagen o acosar a otros Usuarios; - No acceder a las cuentas
            de correo electrónico de otros Usuarios;
          </li>
          <li>
            No introducir virus informáticos, archivos defectuosos, o cualquier
            otro programa informático que pueda provocar daños o alteraciones en
            los contenidos o sistemas de DocuDoc o terceras personas;
          </li>
          <li>
            No remitir correos electrónicos con carácter masivo y/o repetitivo a
            una pluralidad de personas, ni enviar direcciones de correo
            electrónico de terceros sin su consentimiento;
          </li>
          <li>
            No realizar acciones publicitarias de bienes o servicios sin el
            previo consentimiento de DocuDoc.
          </li>
          <li>No contratar servicios con identidades falsas</li>
          <li>No cometer actos fraudulentos</li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Cualquier Usuario podrá reportar a otro Usuario cuando considere que
            está incumpliendo las presentes Condiciones Generales de Uso,
            asimismo todos los Usuarios pueden informar a DocuDoc de cualquier
            abuso o vulneración de las presentes condiciones, a través del
            Formulario de Contacto. DocuDoc verificará este reporte, a la mayor
            brevedad posible, y adoptará las medidas que considere oportunas,
            reservándose el derecho a retirar y/o suspender a cualquier Usuario
            de la Plataforma por el incumplimiento de las presentes Condiciones
            Generales de Uso. Asimismo DocuDoc se reserva el derecho a retirar
            y/o suspender cualquier mensaje con contenido ilegal u ofensivo, sin
            necesidad de previo aviso o posterior notificación.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>19. Baja del Usuario</span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario podrá darse de baja de la Plataforma a través de su
            perfil e clickeando en “Borrar mi perfil”..
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            20. Responsabilidades
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario es responsable de contar con los servicios y equipos
            necesarios para la navegación por Internet y para acceder a la
            Plataforma. En caso de cualquier incidencia o dificultad para
            acceder a la Plataforma, el Usuario puede informar a DocuDoc a
            través de los canales de contacto puestos a disposición del Usuario,
            que procederá a analizar la incidencia y dará indicaciones al
            Usuario acerca de cómo resolverla en el plazo más breve posible.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc no controla ni es responsable de los contenidos vertidos por
            los Usuarios a través de la Plataforma, siendo estos el único
            responsable de la adecuación legal de dichos contenidos.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc no responderá en caso de interrupciones del servicio,
            errores de conexión, falta de disponibilidad o deficiencias en el
            servicio de acceso a Internet, ni por interrupciones de la red de
            Internet o por cualquier otra razón ajena a su control.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc no se hace responsable de los errores de seguridad que se
            puedan producir ni de los daños que puedan causarse al sistema
            informático del Usuario (hardware y software), a los ficheros o
            documentos almacenados en el mismo, como consecuencia de:
          </span>
        </p>
        <ul>
          <li>
            La presencia de un virus en el sistema informático o terminal móvil
            del Usuario que sea utilizado para la conexión a los servicios y
            contenidos de la Plataforma;
          </li>
          <li>Un mal funcionamiento del navegador;</li>
          <li>Del uso de versiones no actualizadas del mismo.</li>
        </ul>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc no es responsable de los daños causados por mala praxis, el
            mal asesoramiento o la mala gestión de los prestadores de servicios
            contratados. En el caso de que la mala praxis, el mal asesoramiento
            o la mala gestión por parte de un prestador de servicio contratado a
            través de DocuDoc cause cualquier daño, dicho daño se le repercutirá
            en exclusiva al prestador de servicio responsable y en ningún caso a
            DocuDoc.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            21. Responsabilidad por contenidos
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc no tiene obligación de controlar y no controla la
            utilización que los Usuarios hacen de la Plataforma y, por
            consiguiente, no garantiza que los Usuarios utilicen la Plataforma
            de conformidad con lo establecido en las presentes Condiciones
            Generales de Uso, ni que hagan un uso diligente y/o prudente de la
            misma. DocuDoc no tiene la obligación de verificar y no verifica la
            identidad de los Usuarios, ni la veracidad, vigencia, exhaustividad
            y/o autenticidad de los datos que los mismos proporcionan.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc no verificará los servicios que puedan recogerse o enviarse
            a través de los pedidos realizados a través de la Plataforma. Por
            esta razón, tanto el Usuario como el prestador de servicios eximen a
            DocuDoc de cualquier responsabilidad que pudiera derivarse de la
            puesta a disposición y/o transporte de servicios que requieran
            permisos o licencias específicos, así como servicios prohibidos por
            cualquier normativa de aplicación, descritos, sin carácter
            limitativo, en la cláusula 15 precedente. En el supuesto que DocuDoc
            tenga conocimiento que el Usuario y/o el prestador de servicios
            utilizan la Plataforma para el transporte de servicios prohibidos,
            DocuDoc podrá iniciar una investigación interna para esclarecer los
            hechos, interponer las medidas legales necesarias y/o bloquear al
            Usuario o prestador de servicios correspondiente hasta la
            finalización de la posible investigación. Entre las medidas a
            disposición de DocuDoc se encuentra el poner a disposición de las
            autoridades los datos e informaciones de los Usuarios y prestadores
            de servicios.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc excluye cualquier responsabilidad por los daños y perjuicios
            de toda naturaleza que pudieran deberse a la utilización ilícita de
            la Plataforma por parte de los Usuarios o que puedan deberse a la
            falta de veracidad, vigencia, exhaustividad y/o autenticidad de la
            información que los Usuarios proporcionan a otros Usuarios acerca de
            sí mismos y, en particular, aunque no de forma exclusiva, por los
            daños y perjuicios de toda naturaleza que puedan deberse a la
            suplantación de la personalidad de un tercero efectuada por un
            Usuario en cualquier clase de comunicación realizada a través de la
            Plataforma.&nbsp; En particular, DocuDoc no se hace responsable del
            uso de la Plataforma así como de los pedidos que pudiera realizar un
            tercero desde la cuenta del Usuario.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Sin perjuicio de lo anterior, DocuDoc se reserva la facultad de
            limitar, total o parcialmente, el acceso a la Plataforma a
            determinados Usuarios, así como de cancelar, suspender, bloquear o
            eliminar determinado tipo de contenidos, mediante la utilización de
            instrumentos tecnológicos aptos al efecto, si tuviese conocimiento
            efectivo o existiesen indicios de que la actividad o la información
            almacenada es ilícita o de que lesiona bienes o derechos de un
            tercero. En este sentido, DocuDoc podrá establecer los filtros
            necesarios a fin de evitar que a través del servicio puedan verterse
            en la red contenidos ilícitos o nocivos. La puesta a disposición por
            parte de los Usuarios de contenidos a través de la Plataforma
            supondrá la cesión a favor de DocuDoc de todos los derechos de
            explotación derivados de los contenidos suministrados en la
            Plataforma.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            22. Cláusula de "no garantía" en los recados ni en los pagos
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc no ofrece ninguna garantía con respecto a la autenticidad,
            la exactitud, la novedad, la fiabilidad, legalidad o no violación de
            derechos de terceros por parte de los prestadores de servicios. En
            este sentido, los Usuarios manifiestan que comprenden que DocuDoc es
            una plataforma tecnológica, cuya principal actividad es la
            intermediación, esto es, pone en contacto Usuarios y prestadores de
            servicios, por lo que no asume responsabilidad alguna derivada de la
            información proporcionada por los prestadores de servicios ni por
            los daños o pérdidas que eventualmente pudieran sufrir por un
            incumplimiento de las presentes Condiciones Generales de Uso. Al
            realizar el pedido a través de la Plataforma y su aceptación por
            parte del prestador de servicios al iniciar la prestación del
            servicio, se establecerá una relación directa entre el Usuario y el
            prestador de servicios, siendo DocuDoc ajena a la misma, facilitando
            únicamente la Plataforma para que las Partes puedan comunicarse y
            realizar cuantos pedidos sean necesarios. Por ello DocuDoc no será
            nunca responsable ni de la disponibilidad de los prestadores de
            servicios ni del cumplimiento adecuado y satisfactorio de los
            servicios por parte de éstos.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc no realiza confirmación alguna, ni valida ningún prestador
            de servicios ni su identidad ni antecedentes. Sin perjuicio de lo
            anterior, DocuDoc puede eventualmente realizar comprobaciones
            adicionales y poner en marcha procedimientos destinados a ayudar a
            verificar o comprobar las identidades de los prestadores de
            servicios. En este sentido, cuando un Usuario alcanza el estatus de
            prestador de servicios, simplemente indica que dicho Usuario ha
            completado el proceso de alta correspondiente y ha aceptado las
            presentes Condiciones Generales de Uso y cualquier otra condición
            particular que le resultara aplicable. No implica ni certificación
            ni aval acerca de su fiabilidad, idoneidad y/o seguridad por parte
            de DocuDoc.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Por ello DocuDoc les recomienda a los Usuarios que empleen siempre
            el sentido común y toda su atención a la hora de delegar un recado a
            un determinado prestador de servicios.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Al ser miembros de la Plataforma, los Usuarios aceptan que cualquier
            responsabilidad legal que pretendan ejercitar como consecuencia de
            acciones u omisiones de otros Usuarios de la Plataforma o terceros
            se limitará a dichos Usuarios o terceros, no procediendo acción de
            responsabilidad alguna contra DocuDoc.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            23. Actualización y Modificación de la Plataforma
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc se reserva el derecho de modificar, en cualquier momento y
            sin previo aviso, las presentes Condiciones Generales de Uso, la
            Política de Privacidad y la Política de Cookies. Los Usuarios
            deberán leer atentamente estas Condiciones Generales al acceder a la
            Plataforma. En cualquier caso, la aceptación de las Condiciones
            Generales será un paso previo e indispensable al acceso de los
            servicios y contenidos disponibles a través de la Plataforma
            DocuDoc.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Asimismo, DocuDoc se reserva la facultad de efectuar, en cualquier
            momento y sin necesidad de previo aviso, actualizaciones,
            modificaciones o eliminación de información contenida en su
            Plataforma en la configuración y presentación de ésta y de las
            condiciones de acceso, sin asumir responsabilidad alguna por ello.
            DocuDoc no garantiza la inexistencia de interrupciones o errores en
            el acceso de la Plataforma o a su contenido, ni que ésta se
            encuentre siempre actualizada, no obstante, DocuDoc llevará a cabo,
            siempre que no concurran causas que lo hagan imposible o de difícil
            ejecución, y tan pronto tenga noticia de los errores, desconexiones
            o falta de actualización en los contenidos, todas aquellas labores
            tendentes a subsanar los errores, restablecer la comunicación y
            actualizar los contenidos.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            24. Propiedad Intelectual
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            DocuDoc es titular o licenciataria de todos los derechos de
            propiedad intelectual e industrial incluidos en la Plataforma así
            como sobre los contenidos accesibles a través de la misma. Los
            derechos de propiedad intelectual de la Plataforma, así como:
            textos, imágenes, diseño gráfico, estructura de navegación,
            información y contenidos que se recogen en ella son titularidad de
            DocuDoc, menos los que están legalmente adquiridos al público a
            través de internet.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Aún cuanto descrito anteriormente, DocuDoc podría no ser titular ni
            licenciatario de contenidos como nombres o imágenes, entre otros, de
            compañías con las cuales DocuDoc no tiene relación comercial. En
            esos supuestos, DocuDoc adquiere los contenidos de fuentes
            accesibles al público y en ningún caso se entenderá que DocuDoc
            tenga relación con ningún derecho titularidad de DocuDoc.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            La autorización al Usuario para el acceso a la Plataforma no supone
            renuncia, transmisión, licencia o cesión total ni parcial sobre
            derechos de propiedad intelectual o industrial por parte de DocuDoc.
            No está permitido suprimir, eludir o manipular de ningún modo los
            contenidos de la Plataforma de DocuDoc. Asimismo está prohibido
            modificar, copiar, reutilizar, explotar, reproducir, comunicar
            públicamente, hacer segundas o posteriores publicaciones, cargar
            archivos, enviar por correo, transmitir, usar, tratar o distribuir
            de cualquier forma la totalidad o parte de los contenidos incluidos
            en la Plataforma de DocuDoc para propósitos públicos o comerciales,
            si no se cuenta con la autorización expresa y por escrito de DocuDoc
            o, en su caso, del titular de los derechos a que corresponda.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            El Usuario que proceda a compartir cualquier tipo de contenido a
            través de la Plataforma, asegura que ostenta los derechos necesarios
            para hacerlo, quedando exento DocuDoc de cualquier responsabilidad
            sobre el contenido y legalidad de la información ofrecida. La
            facilitación de contenidos por parte de los Usuarios a través de la
            Plataforma supondrá la cesión a DocuDoc, con carácter gratuito, y
            con la máxima amplitud permitida por la legislación vigente, de los
            derechos de explotación de propiedad intelectual o industrial
            derivados de tales contenidos.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            25. Independencia de las Cláusulas
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            Si cualquiera de las cláusulas de las presentes Condiciones
            Generales fuera nula de pleno derecho o anulable, se tendrá por no
            puesta. Dicha declaración de nulidad no invalidará el resto de
            cláusulas, que mantendrá su vigencia y eficacia entre las Partes.
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            26. Legislación Aplicable
          </span>
        </p>
        <p>
          <span style={{ color: "rgb(74, 74, 74)" }}>
            La relación entre DocuDoc y el Usuario, se regirá e interpretará de
            conformidad con las Condiciones Generales que en materia de
            interpretación, validez y ejecución se regirán por la legislación
            española; y cualquier controversia se someterá a los Tribunales de
            Madrid.
          </span>
        </p>
      </div>
    </div>
  );
}
