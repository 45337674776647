import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Images } from "../constant/images";
import MyModal from "./MyModal";
import CustomTooltip from "./tooltip";
// import { SpecificServiceButton } from ".";
import { useToasts } from "react-toast-notifications";
import labels from "../config/Labels";
import {
  functionToConvertStringToCamelCase,
  languageTranslationFunctionForTheTime,
  replaceDecimalWithComma,
} from "../config/Utility";
import { Card, Col, Row } from "react-bootstrap";

const SpecialServiceButton = ({
  image,
  title,
  desc,
  assessment,
  price = false,
  discount = false,
  path,
  states,
  isPartner = false,
  country,
  ratings,
  branch_id = "",
  activeFilter = "",
  servicePrice = "",
  avatar = false,
  serviceAreaId = "",
  benificiaryId = "",
  item = "",
  servicePak = "",
  supplementory = "",
  supplementaryFees = "",
}) => {
  // alert(path)
  const { push } = useHistory();
  const target = useRef(null);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [register, setRegister] = useState(false);
  let token = localStorage.getItem("usertoken");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [modalShow]);
  const { addToast } = useToasts();
  const onClickFunction = () => {
    if (token !== null || path == "service") {
      push({
        pathname: `/${path}/${branch_id}`,
        state: {
          acticeFilter: item?.service_id,
          price: price,
          avatar: avatar,
          name: title,
          serviceAreaId: serviceAreaId,
          benificiaryId: benificiaryId,
          branch_id: branch_id,
          item: item,
          supplementory: supplementory,
          supplementaryFees: supplementaryFees,
        },
      });
    } else {
      addToast("You need to login first", { autoDismiss: 1000 });
    }
  };
  return (
    <Col sm={12}>
      <div className="pdpt-bg w-100 mb-2">
        <div div className="order-body10 mb-2">
          <article
            className="shop-summary-item datalist element-emphasis-weak border-radius-10 position-relative mt-10 "
            style={{ cursor: "pointer" }}
          >
            <Card className="shadow-none border-0">
              <Card.Body className="p-1">
                <div className="d-flex align-items-center">
                  <div className="d-flex" onClick={() => onClickFunction()}>
                    <img
                      src={image ? image : ""}
                      alt="Shop item in cart"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div onClick={() => onClickFunction()}>
                    <h4 className="tdr-paragraph custom-font1 ml-2">{title}</h4>
                    <h6 className="text-success mb-10 mt-0 mx-10 custom-font2">
                      {price !== "" && path == "documentaion" && (
                        <b>
                          {Number(price) + Number(supplementaryFees) < 1 ? (
                            labels.free
                          ) : (
                            <>
                              €
                              {replaceDecimalWithComma(
                                Number(price) + Number(supplementaryFees)
                              )}
                            </>
                          )}
                          {/* {price !== "" &&
                            servicePak !== "fixed price" &&
                            `/ ${
                              labels?.[
                                functionToConvertStringToCamelCase(servicePak)
                              ]
                            }`} */}
                        </b>
                      )}
                    </h6>
                    {(
                      ((Number(price) - Number(discount)) / Number(price)) *
                      100
                    ).toFixed(0) > 0 ? (
                      <p className="crossTextSpecialService mx-10 mb-0 custom-font2 text-danger ">
                        {price !== "" && path == "documentaion" && (
                          <span className="text-danger">
                            €
                            {replaceDecimalWithComma(
                              Number(discount) + Number(supplementaryFees)
                            )}{" "}
                            <span className="mx-5">
                              {(
                                ((Number(price) - Number(discount)) /
                                  Number(price)) *
                                100
                              ).toFixed(0)}
                              % {labels.discount}
                            </span>
                          </span>
                        )}
                      </p>
                    ) : null}

                    <h6 className="text-success mb-10 mt-0 mx-10 custom-font2">
                      <b>
                        {servicePak === "monthly" ? (
                          labels?.monthly
                        ) : (
                          <>
                            {labels.estimatedTime}
                            <span className="mx-5">
                              {item?.time}{" "}
                              {languageTranslationFunctionForTheTime(
                                item?.time,
                                item?.time_type
                              )}
                            </span>
                          </>
                        )}
                      </b>
                    </h6>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <img
              onClick={() => setModalShow(true)}
              src={Images.infoo.default}
              className="position-absolute"
              style={{
                width: 20,
                height: 20,
                top: "40%",
                right: 0,
                cursor: "pointer",
              }}
            />
          </article>
        </div>
      </div>
      <MyModal
        target={target.current}
        show={modalShow}
        info={desc}
        onHide={() => setModalShow(false)}
      />
    </Col>
    // <Col
    //   sm="12"
    //   className=" datalist d-flex justify-content-between align-items-center mx-10 mt-10 cursorPointer"
    //   style={{ width: "47%", height: "100%" }}
    // >
    //   <div className="onCardHover">
    //     <div
    //       className="d-flex align-items-center "
    //       onClick={() => {
    //         if (token !== null || path == "service") {
    //           push({
    //             pathname: `/${path}/${branch_id}`,
    //             state: {
    //               acticeFilter: item?.service_id,
    //               price: price,
    //               avatar: avatar,
    //               name: title,
    //               serviceAreaId: serviceAreaId,
    //               benificiaryId: benificiaryId,
    //               branch_id: branch_id,
    //               item: item,
    //               supplementory: supplementory,
    //               supplementaryFees: supplementaryFees,
    //             },
    //           });
    //         } else {
    //           addToast("You need to login first", { autoDismiss: 1000 });
    //         }
    //       }}
    //     >
    //       {image ? (
    //         <img
    //           src={image ? image : ""}
    //           alt="Shop item in cart"
    //           style={{
    //             // borderRadius: 10,
    //             width: "80px",
    //             height: "80px",
    //             // background: "rgba(229, 229, 229, 0.29)",
    //             // border: "1px solid rgb(229, 229, 229)",
    //             objectFit: "contain",
    //           }}
    //         />
    //       ) : null}
    //       <div>
    //         <h4 className="mx-10 tdr-paragraph Proxima-Nova-Bold">{title}</h4>
    //         <h6 className="text-success mb-0 mt-0 mx-10 ProximaNovaBold">
    //           {price !== "" && path == "documentaion" && (
    //             <b>
    //               € {Number(price) + Number(supplementaryFees)}{" "}
    //               {price !== "" &&
    //                 `/ ${
    //                   labels?.[functionToConvertStringToCamelCase(servicePak)]
    //                 }`}
    //             </b>
    //           )}
    //         </h6>
    //         <p className="crossTextSpecialService mx-10 ProximaNovaBold">
    //           {price !== "" && path == "documentaion" && (
    //             <span className="text-danger">
    //               €{Number(discount) + Number(supplementaryFees)}{" "}
    //               {((price - discount) / price) * 100}% {labels.discount}
    //             </span>
    //           )}
    //         </p>
    //       </div>
    //     </div>
    //     <div
    //       className="partnersRightContainer"
    //       style={{ width: 30, height: 30 }}
    //     >
    //       <img
    //         className="partnerStarIconInfo"
    //         src={Images.infoo.default}
    //         style={{ cursor: "pointer" }}
    //         onClick={() => setModalShow(true)}
    //         onMouseLeave={() => setShow(false)}
    //         ref={target}
    //         alt="info"
    //       />
    //       <CustomTooltip target={target.current} show={show} info={desc} />
    //       <MyModal
    //         target={target.current}
    //         show={modalShow}
    //         info={desc}
    //         onHide={() => setModalShow(false)}
    //       />
    //     </div>
    //   </div>
    // </Col>
  );
};
export default SpecialServiceButton;
