import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";

import { Button, Form } from "react-bootstrap";
import { Work_With_Us } from "../config/api";
import { Utility } from "../config/Utility";
import labels from "../config/Labels";
import { useToasts } from "react-toast-notifications";
import { WorkWithUsModalContext } from "../App";

const WorkWithUsGlobalModal = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [success, setSuccess] = useState("");
  const [message, setMessage] = useState("");
  const [showToast, setShowToast] = useState("");
  const [validate, setValidate] = useState(false);
  const [value, setValue] = useState("");
  const { addToast } = useToasts();

  const workWithUsModalVisible = useContext(WorkWithUsModalContext);
  const { modalState, modalDispatch } = workWithUsModalVisible;
  const WorkWithUsPostAPI = () => {
    if (
      name !== "" &&
      email !== "" &&
      value?.length >= 7 &&
      description !== ""
    ) {
      setValidate(false);
      Work_With_Us(
        {
          name: name,
          email: email,
          number: value,
          description: description,
        },
        (value) => {
          setSuccess(value);
          setName("");
          setEmail("");
          setValue("");
          setDescription("");
          setValidate(false);
        },
        (value) => {
          setMessage(value);
          addToast(value, {
            placement: "top-center",
            autoDismiss: 1000,
            appearance: labels.workWithUsToastMessage ? "success" : "error",
          });
        },
        (value) => {
          setShowToast(value);
        }
      );
      //   modalDispatch("close")
      setDescription("");
      setEmail("");
      setName("");
    } else {
      setValidate(true);
    }
  };

  return (
    <Modal
      show={modalState}
      backdrop="static"
      onHide={() => modalDispatch("close")}
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="d-flex justify-content-center">
        <Modal.Title className="d-flex justify-content-around" id="">
          {labels.WorkWithUs}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Form.Control
            type="text"
            placeholder={labels?.enterfirstName}
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
          {/* {email.length > 0 && setValidate(false)} */}
          {!name.length && validate && (
            <p className="text-danger">{labels.validateField}</p>
          )}
          <br />
          <Form.Control
            type="email"
            placeholder={labels.Email}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value.trim(""));
            }}
          />

          {validate && !email.length ? (
            <p style={{ color: "red" }}>{labels.validateField}</p>
          ) : (
            email?.trim()?.length !== 0 &&
            !Utility?.validateEmail(email) && (
              <p style={{ color: "red" }}>{labels.emailValidateField}</p>
            )
          )}
          <br />
          <input
            className="form-control"
            maxLength={20}
            autoComplete={false}
            type="tel"
            placeholder={labels.phoneNumber}
            value={value}
            onChange={(e) => {
              const re = /^[0-9-+\b]+$/;

              // if value is not blank, then test the regex

              if (e.target.value === "" || re.test(e.target.value)) {
                setValue(e.target.value);
              }
            }}
          />
          {/* <PhoneInput
            style={{ width: "100%", marginRight: "5px" }}
            international
            countryCallingCodeEditable={false}
            defaultCountry="ES"
            value={value}
            maxLength={20}
            onChange={setValue}
          /> */}
          {validate && !value?.trim().length ? (
            <p className="text-justify" style={{ color: "red" }}>
              {labels.validateField}
            </p>
          ) : (
            validate &&
            !Utility?.validatePhone(value) && (
              <p className="text-justify" style={{ color: "red" }}>
                {labels.invalidPhoneNumber}{" "}
              </p>
            )
          )}

          <br />
          <Form.Control
            as="textarea"
            rows={3}
            placeholder={labels.reasonAndSpecialization}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            required
          />
          {!description.length && validate && (
            <p className="text-danger">{labels.validateField}</p>
          )}
        </>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-around">
        <Button variant="primary" onClick={WorkWithUsPostAPI}>
          {showToast ? labels.loading : labels.sendRequest}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WorkWithUsGlobalModal;
