import React from "react";
import labels from "../config/Labels";

function OurMissionEnglish() {
  return (
    <div className="ins-float-icon-box-content">
      <h4 className=" mt-0 ">{labels.ourMission}</h4>
      <div className="last-paragraph-no-margin">
        <p className="ins-float-icon-box-text  default ">
          DocuDoc is the first app and web that offers legal services such as
          Assessment, Document Management and Specialized Lawyers in a simple,
          fast, transparent way at the best price.
        </p>

        <p className="ins-float-icon-box-text  default ">
          With our partners, the best valued Law Firms, Consultancies and
          Agencies in Spain, we manage for you and advise you in a few clicks
          without wasting time and ensuring fair prices in a transparent way.
        </p>

        <p className="ins-float-icon-box-text  default ">
          DocuDoc offers the services of specialized Lawyers, Advice and
          Management both to Freelancers and Companies, as well as to
          individuals in the following branches.
        </p>
      </div>
      <div className="last-paragraph-no-margin"></div>
    </div>
  );
}

export default OurMissionEnglish;
